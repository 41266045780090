import './AdminXDashboard.scss';
import {useSelector} from 'react-redux';
import {DefaultPage} from '../../../libs/DefaultPage/DefaultPage';
import {TabbedPanel} from '../../../libs/TabbedPanel/TabbedPanel';
import {EditSchools} from '../../profiles/EditSchools/EditSchools';
import {Accounts} from '../../admin_x/Accounts/Accounts';
import {EditDistricts} from '../../profiles/EditDistricts/EditDistricts';
import {AdminStatus} from './AdminStatus';
import {RootState} from '../../../store/store';

enum ADMIN_TAB {
  STATUS,
  ACCOUNTS,
  SCHOOLS,
  DISTRICTS,
}

export function AdminXDashboard() {
  const userX = useSelector((state: RootState) => state.auth.user);

  if (!userX) {
    return <></>;
  }

  return (
    <>
      <DefaultPage title="Admin Dashboard">
        <TabbedPanel
          tabKeyEnum={ADMIN_TAB}
          defaultTabKey={ADMIN_TAB.STATUS}
          tabs={[
            {
              key: ADMIN_TAB.STATUS,
              label: 'Status',
              content: <AdminStatus />,
            },
            {
              key: ADMIN_TAB.ACCOUNTS,
              label: 'Account',
              content: <Accounts />,
            },
            {
              key: ADMIN_TAB.SCHOOLS,
              label: 'Schools',
              content: <EditSchools />,
            },
            {
              key: ADMIN_TAB.DISTRICTS,
              label: 'Districts',
              content: <EditDistricts />,
            },
          ]}
        />
      </DefaultPage>
    </>
  );
}
