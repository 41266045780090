import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {pl_types} from '../../generated/protobuf-js';
import {login as apiLogin, logout as apiLogout} from '../../services/api';
import {APIStateStatusEnum} from '../../shared/enums/apiStateStatus.enum';

export interface LoginResponse {
  user: pl_types.IUserX | null;
  sessionExpirationTimeMs: number | null;
}

export interface AuthState {
  user: pl_types.IUserX | null;
  isLoggedIn: boolean;
  status: APIStateStatusEnum;
  error: string | null | undefined;
  sessionExpirationTimeMs: number | null;
  sessionExpirationTimeEnabled: boolean;
}

function getInitialUserState() {
  const userJson = localStorage.getItem('user');
  return userJson ? JSON.parse(userJson) : null;
}

function getSessionExpirationTimeMs() {
  const data = localStorage.getItem('sessionExpirationTimeMs');
  return data ? JSON.parse(data) : null;
}

const initialState: AuthState = {
  user: getInitialUserState(),
  isLoggedIn: !!localStorage.getItem('user'),
  status: APIStateStatusEnum.IDLE,
  error: null,
  sessionExpirationTimeMs: getSessionExpirationTimeMs(),
  sessionExpirationTimeEnabled: !!getSessionExpirationTimeMs(),
};

export const login = createAsyncThunk(
  'auth/login',
  async ({username, password}: {username: string; password: string}) => {
    return await apiLogin(username, password);
  }
);

export const logout = createAsyncThunk('auth/logout', async () => {
  await apiLogout();
  return null;
});

export const resetStatus = createAsyncThunk('auth/resetStatus', async () => {
  return Promise.resolve(APIStateStatusEnum.IDLE);
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserAndSession: (state, action) => {
      state.user = action.payload.user;
      state.isLoggedIn = !!action.payload.user;
      state.sessionExpirationTimeMs = action.payload.sessionExpirationTimeMs;
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      localStorage.setItem(
        'sessionExpirationTimeMs',
        JSON.stringify(action.payload.sessionExpirationTimeMs)
      );
    },
    login: (state, action) => {
      state.user = action.payload.user;
      state.isLoggedIn = true;
      state.sessionExpirationTimeMs = action.payload.sessionExpirationTimeMs;
      state.sessionExpirationTimeEnabled = true;
    },
    logout: state => {
      state.user = null;
      state.isLoggedIn = false;
      state.sessionExpirationTimeMs = null;
      state.sessionExpirationTimeEnabled = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        state.status = APIStateStatusEnum.LOADING;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = APIStateStatusEnum.SUCCEEDED;
        state.user = action.payload.user;
        state.sessionExpirationTimeMs = action.payload.sessionExpirationTimeMs;
        state.isLoggedIn = true;
        localStorage.setItem('user', JSON.stringify(action.payload.user));
        localStorage.setItem(
          'sessionExpirationTimeMs',
          JSON.stringify(action.payload.sessionExpirationTimeMs)
        );
      })
      .addCase(login.rejected, (state, action) => {
        state.status = APIStateStatusEnum.FAILED;
        state.error = action?.error?.message || '';
      })
      .addCase(logout.pending, state => {
        state.status = APIStateStatusEnum.LOADING;
      })
      .addCase(logout.fulfilled, state => {
        state.status = APIStateStatusEnum.SUCCEEDED;
        state.user = null;
        state.isLoggedIn = false;
        state.sessionExpirationTimeMs = null;
        state.sessionExpirationTimeEnabled = false;
        localStorage.removeItem('user');
        localStorage.removeItem('sessionExpirationTimeMs');
      })
      .addCase(logout.rejected, state => {
        state.status = APIStateStatusEnum.FAILED;
        state.error = 'Failed to logout';
      })
      .addCase(resetStatus.fulfilled, (state, action) => {
        state.status = action.payload;
      });
  },
});

export const {setUserAndSession} = authSlice.actions;

export default authSlice.reducer;
