import {Button, Card, Grid} from '@mui/material';
import {CSSProperties, forwardRef, useEffect, useState} from 'react';
import {ProjectDetailCardMilestone} from './SortableMilestones/ProjectDetailCardMilestone';
import {pl_types} from '../../generated/protobuf-js';
import {DeepReadOnly} from '../misc';
import {HtmlEditor} from '../HtmlEditor/HtmlEditor';
import {ProjectCardHeader} from './ProjectCardHeader';
import {useFormFields} from '../form_utils/forms';
import {DynamicAssignmentAutocomplete} from '../common_fields/DynamicAssignmentAutocomplete';
import {ProjectInputs} from '../ProjectInputCard/ProjectInputSummaryCard';
import {EditablePlainText} from '../EditablePlainText';
import IProject = pl_types.IProject;
import IAssignment = pl_types.IAssignment;
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import ThumbsState = pl_types.Project.ThumbsState;
import {ThumbReasonText} from './ThumbReasonText';

export const ProjectDetailsCard = forwardRef<
  HTMLDivElement,
  DeepReadOnly<{
    project: IProject;
    loading?: boolean;
    hideActive?: boolean;
    hideThumbsState?: boolean;
    updateProject?: (project: DeepReadOnly<IProject>) => void;
    style?: CSSProperties;
    onClose: () => void;
  }>
>((props, ref) => {
  const userX = useSelector((state: RootState) => state.auth.user);

  const [project, _setProject] = useState<DeepReadOnly<IProject>>({});
  const [modified, setModified] = useState(false);

  const filterForm = useFormFields();
  const assignments = filterForm.useSingleAutocompleteFormField<
    DeepReadOnly<IAssignment>
  >('assignmentFilter', {
    disabled: props.updateProject == null,
    onChange: field => {
      if (project.assignment !== field.getValue()) {
        setProject({
          ...project,
          assignment: field.getValue(),
        });
      }
    },
  });

  useEffect(() => {
    setProject(props.project);
    assignments.setValue(props.project?.assignment || undefined);
    setModified(false);
  }, [props.project]);

  function setProject(project: DeepReadOnly<IProject>) {
    _setProject(project);
    setModified(true);
  }

  function saveProject() {
    props.updateProject?.(project);
    setModified(false);
  }

  function checkModifiedBeforeClose() {
    if (modified) {
      if (
        confirm(
          'You have unsaved changes. Are you sure you want to discard them?'
        )
      ) {
        props.onClose?.();
      }
    } else {
      props.onClose?.();
    }
  }

  return props.loading ? (
    <div>Loading...</div>
  ) : (
    <Card
      id={project.id?.toString?.() ?? ''}
      ref={ref}
      elevation={6}
      sx={{
        height: '100%',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
      }}
      style={props.style}
    >
      {props.loading ? (
        <div>Loading...</div>
      ) : (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
          <ProjectCardHeader
            project={project}
            hideActive={props.hideActive}
            hideThumbsState={props.hideThumbsState}
            style={{paddingBottom: '1rem'}}
            updateProject={setProject}
          />
          <Grid
            container
            padding={2}
            spacing={2}
            style={{height: '100%', flex: '1 1 100%', overflowY: 'auto'}}
          >
            <Grid item xs={12} md={6}>
              <div>
                {!props.hideThumbsState &&
                  (project?.thumbsState === ThumbsState.THUMBS_UP ||
                    project?.thumbsState === ThumbsState.THUMBS_DOWN) && (
                    <div style={{marginBottom: '1rem'}}>
                      <ThumbReasonText
                        inputText={project.thumbsStateReason}
                        onChange={text =>
                          setProject({...project, thumbsStateReason: text})
                        }
                        thumbsState={project.thumbsState}
                      />
                    </div>
                  )}
                <div style={{fontWeight: 'bold', marginBottom: '1rem'}}>
                  Summary:
                </div>
                <div style={{paddingLeft: '1rem', paddingBottom: '1rem'}}>
                  <EditablePlainText
                    value={project?.shortDescr ?? 'None.'}
                    onChange={
                      props.updateProject == null
                        ? undefined
                        : value => setProject({...project, shortDescr: value})
                    }
                    viewingPlaceholder="Click to edit description."
                    editingPlaceholder="Enter description here."
                  />
                </div>
              </div>
              <div>
                <div style={{fontWeight: 'bold', paddingBottom: '1rem'}}>
                  Details:
                </div>
                <div style={{paddingLeft: '1rem', paddingBottom: '1rem'}}>
                  <HtmlEditor
                    readOnly={props.updateProject == null}
                    value={project?.longDescrHtml ?? 'None.'}
                    onChange={value =>
                      setProject({
                        ...project,
                        longDescrHtml: value,
                      })
                    }
                  />
                </div>
              </div>
              <div>
                <div style={{fontWeight: 'bold', paddingBottom: '1rem'}}>
                  Assignment:
                </div>
                <DynamicAssignmentAutocomplete
                  label="label"
                  baseRequest={{
                    studentId: userX?.studentId,
                  }}
                  assignmentField={assignments}
                  style={{paddingLeft: '1rem', paddingBottom: '1rem'}}
                />
              </div>
              {props.project.projectDefinition && (
                <>
                  <div style={{fontWeight: 'bold', paddingBottom: '1rem'}}>
                    Project Input:
                  </div>
                  <div>
                    <ProjectInputs
                      projectInput={props.project.projectDefinition}
                      categoryStyle={{fontStyle: 'italic'}}
                    />
                  </div>
                </>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <ProjectDetailCardMilestone
                project={project}
                onProjectChanged={setProject}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '1rem',
              justifyContent: 'flex-end',
            }}
          >
            {props.updateProject && (
              <>
                <Button
                  variant="contained"
                  onClick={() => {
                    saveProject();
                    props.onClose();
                  }}
                >
                  <span style={{width: '10rem'}}>Save</span>
                </Button>
                <Button variant="outlined" onClick={checkModifiedBeforeClose}>
                  <span style={{width: '10rem'}}>Cancel</span>
                </Button>
              </>
            )}
            {!props.updateProject && (
              <Button variant="contained" onClick={props.onClose}>
                <span style={{width: '10rem'}}>Close</span>
              </Button>
            )}
          </div>
        </div>
      )}
    </Card>
  );
});
