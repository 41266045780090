import '../../ProjectPage/ProjectPage.scss';
import './style.scss';
import {ConfirmModal} from '../../Modals/ConfirmModal';
import {Button} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import {useState, useEffect} from 'react';
import {
  DndContext,
  PointerSensor,
  KeyboardSensor,
  useSensor,
  useSensors,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import type {DragEndEvent, DragOverEvent} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import {MilestoneContainer} from './MilestoneContainer';
import {pl_types} from '../../../generated/protobuf-js';
import {
  DeepReadOnly,
  modifyIndexInDeepReadOnlyArray,
  removeIndexFromDeepReadOnlyArray,
  removeFromDeepReadOnlyArray,
} from '../../misc';
import IProject = pl_types.IProject;
import IMilestone = pl_types.Project.IMilestone;

type Props = {
  project: DeepReadOnly<IProject>;
  onProjectChanged: (project: DeepReadOnly<IProject>) => void;
};

export type StepWithSortIndex = {
  id?: number;
  name: string;
  sortIndex: number;
  milestoneId: string;
};

export const ProjectDetailCardMilestone = ({
  project,
  onProjectChanged,
}: DeepReadOnly<Props>) => {
  const [steps, setSteps] = useState<StepWithSortIndex[]>([]);
  const [openConfirmDailog, setOpenConfirmDailog] = useState<number | null>(
    null
  );

  useEffect(() => {
    function mappingSteps() {
      let sortIndex = 0;
      let id = 0;
      const stepsArray =
        project?.milestones?.flatMap((milestone, milestoneIndex) =>
          (milestone.steps ?? []).map(step => {
            if (!step.id) id = id - 1;
            return {
              id: step.id ?? id,
              name: step.name ?? '',
              sortIndex: sortIndex++,
              milestoneId: JSON.stringify({
                milestoneIndex,
              }),
            };
          })
        ) ?? [];
      return stepsArray;
    }
    setSteps(mappingSteps());
  }, [project]);

  function onAddNewMilestone() {
    onProjectChanged({
      ...project,
      milestones: [...(project?.milestones ?? []), {name: ''}],
    });
  }

  function onAddNewStep(milestoneIndex: number, newName: string) {
    onProjectChanged({
      ...project,
      milestones: modifyIndexInDeepReadOnlyArray(
        project.milestones,
        milestoneIndex,
        m => ({
          ...m,
          steps: [...(m?.steps ?? []), {name: newName}],
        })
      ),
    });
  }

  function updateMilestoneName(newName: string, milestoneIndex: number) {
    onProjectChanged({
      ...project,
      milestones: modifyIndexInDeepReadOnlyArray(
        project.milestones,
        milestoneIndex,
        m => ({
          ...m,
          name: newName,
        })
      ),
    });
  }

  function updateStepName(
    newName: string,
    milestoneIndex: number,
    stepIndex: number
  ) {
    onProjectChanged({
      ...project,
      milestones: modifyIndexInDeepReadOnlyArray(
        project.milestones,
        milestoneIndex,
        m => ({
          ...m,
          steps: modifyIndexInDeepReadOnlyArray(m.steps, stepIndex, s => ({
            ...s,
            name: newName,
          })),
        })
      ),
    });
  }

  function onDragOver(event: DragOverEvent) {
    const {active, over} = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveAStep = active.data.current?.type === 'Step';
    const isOverAStep = over.data.current?.type === 'Step';

    if (!isActiveAStep) return;

    const isOverAMilestone = over.data.current?.type === 'Milestone';

    if (isActiveAStep && isOverAStep) {
      const activeIndex = steps.findIndex(step => step.id === activeId);
      const overIndex = steps.findIndex(step => step.id === overId);

      if (steps[activeIndex].milestoneId !== steps[overIndex].milestoneId) {
        const updatedSteps = [...steps];
        updatedSteps[activeIndex].milestoneId =
          updatedSteps[overIndex].milestoneId;
        setSteps(arrayMove(updatedSteps, activeIndex, overIndex - 1));
      }
      const updatedSteps = [...steps];
      [updatedSteps[activeIndex].sortIndex, updatedSteps[overIndex].sortIndex] =
        [
          updatedSteps[overIndex].sortIndex,
          updatedSteps[activeIndex].sortIndex,
        ];
      setSteps(updatedSteps.sort((a, b) => a.sortIndex - b.sortIndex));
    }

    // Im dropping a Step over a Milestones\
    if (isActiveAStep && isOverAMilestone) {
      const activeIndex = steps.findIndex(step => step.id === activeId);
      const updatedSteps = [...steps];
      steps[activeIndex].milestoneId = overId as string;

      setSteps(updatedSteps.sort((a, b) => a.sortIndex - b.sortIndex));
    }
    return;
  }

  function onDragEnd(event: DragEndEvent) {
    const {active, over} = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    const isActiveMilestone = active.data.current?.type === 'Milestone';

    if (activeId === overId && isActiveMilestone) return;

    if (isActiveMilestone) {
      const activeMilestoneIndex = JSON.parse(
        active.id as string
      ).milestoneIndex;
      const overMilestoneIndex = JSON.parse(over.id as string).milestoneIndex;

      if (activeMilestoneIndex < 0 || overMilestoneIndex < 0) return;

      onProjectChanged({
        ...project,
        milestones: arrayMove(
          project?.milestones as IMilestone[],
          activeMilestoneIndex,
          overMilestoneIndex
        ),
      });
      return;
    }
    onProjectChanged({
      ...project,
      milestones: project?.milestones?.map((m, milestoneIndex) => {
        return {
          ...m,
          steps: filterAndRemoveProperties(
            steps.map(step => {
              if (step.id && step.id < 0) {
                delete step.id;
                return step;
              }
              return step;
            }),
            (step: StepWithSortIndex) =>
              JSON.parse(step.milestoneId).milestoneIndex === milestoneIndex,
            ['sortIndex', 'milestoneId']
          ),
        };
      }),
    });
  }

  /**
   * This Function will filter flatend steps and remove the extra properties and return steps for saving
   */
  function filterAndRemoveProperties(
    array: StepWithSortIndex[],
    criteria: (obj: StepWithSortIndex) => boolean,
    propertiesToRemove: string[]
  ): Partial<StepWithSortIndex>[] {
    return array
      .filter(criteria) // Filter the array based on the criteria
      .map(obj => {
        const newObj = {...obj};
        propertiesToRemove.forEach(
          property => delete newObj[property as keyof typeof newObj]
        );
        return newObj;
      });
  }

  const milestoneItems = (project.milestones ?? []).map((_, milestoneIndex) =>
    JSON.stringify({milestoneIndex})
  );

  const stepItems = steps.map(step => step.id);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  return (
    <div className="milestone-container">
      <DndContext
        sensors={sensors}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
        modifiers={[restrictToVerticalAxis]}
      >
        <div>
          <SortableContext items={milestoneItems}>
            {project?.milestones?.map((milestone, milestoneIndex) => (
              <MilestoneContainer
                milestoneId={
                  JSON.stringify({
                    milestoneIndex,
                  }) as string
                }
                key={milestone.id}
                onAddNewStep={onAddNewStep}
                removeMilestone={(milestoneIndex: number) =>
                  setOpenConfirmDailog(milestoneIndex)
                }
                removeStep={(stepId: number, milestoneIndex: number) => {
                  onProjectChanged({
                    ...project,
                    milestones: modifyIndexInDeepReadOnlyArray(
                      project.milestones,
                      milestoneIndex,
                      m => ({
                        ...m,
                        steps: removeFromDeepReadOnlyArray(
                          m.steps,
                          s => s.id,
                          stepId
                        ),
                      })
                    ),
                  });
                }}
                milestone={milestone as IMilestone}
                updateMilestone={updateMilestoneName}
                updateStep={updateStepName}
                stepItems={stepItems as UniqueIdentifier[]}
                steps={steps.filter(
                  step =>
                    step.milestoneId ===
                    JSON.stringify({
                      milestoneIndex,
                    })
                )}
              />
            ))}
          </SortableContext>
          <div className="add-milestone">
            <Button
              onClick={() => onAddNewMilestone()}
              color="primary"
              size="small"
              startIcon={<AddBoxIcon />}
            >
              Add New Milestone
            </Button>
          </div>
        </div>
      </DndContext>
      <ConfirmModal
        open={openConfirmDailog !== null}
        title="Delete Milestone"
        description="Are you sure to delete this milestone"
        handleClose={(isConfirmed: boolean) => {
          if (isConfirmed) {
            onProjectChanged({
              ...project,
              milestones: removeIndexFromDeepReadOnlyArray(
                project.milestones,
                openConfirmDailog as number
              ),
            });
          }
          setOpenConfirmDailog(null);
        }}
      />
    </div>
  );
};
