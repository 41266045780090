import {Modal} from '@mui/material';
import {DeepReadOnly} from '../misc';
import React, {useState} from 'react';
import {PsychologyTwoTone} from '@mui/icons-material';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';

interface AiInfoProps {
  aiPrompt?: string | null | undefined;
  aiResponse?: string | null | undefined;
}

export function AiInfo(
  props: DeepReadOnly<{
    aiInfo?: {} | null | undefined;
  }>
) {
  const userX = useSelector((state: RootState) => state.auth.user);

  const [showAiInfo, setShowAiInfo] = useState(false);

  return (
    <>
      {userX?.viewAiPrompts &&
      props.aiInfo != null &&
      (((props.aiInfo as AiInfoProps)?.aiPrompt ?? '') !== '' ||
        ((props.aiInfo as AiInfoProps)?.aiResponse ?? '') !== '') ? (
        <>
          <PsychologyTwoTone
            htmlColor="#cc66cc"
            onClick={() => setShowAiInfo(true)}
            cursor="pointer"
          />
          {showAiInfo && (
            <Modal open={showAiInfo} onClose={() => setShowAiInfo(false)}>
              <textarea
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '90%',
                  height: '90%',
                  overflow: 'auto',
                  padding: '2em',
                }}
              >
                {((props.aiInfo as AiInfoProps)?.aiPrompt ?? '') +
                  '\n\n-----\n\n' +
                  ((props.aiInfo as AiInfoProps)?.aiResponse ?? '')}
              </textarea>
            </Modal>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
