import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../../store/store';
import {useState} from 'react';
import {DeepReadOnly} from '../../../libs/misc';
import {pl_types} from '../../../generated/protobuf-js';
import {TermAutocomplete} from '../../../shared/components/forms/TermAutocomplete';
import {setTerms} from '../../../features/selection/selectionSlice';
import ITerm = pl_types.ITerm;

export function ClassesTab() {
  const dispatch: AppDispatch = useDispatch();
  const userX = useSelector((state: RootState) => state.auth.user);

  const initialTerms = useSelector((state: RootState) => state.selection.terms);
  const [term, internalSetTerm] = useState<DeepReadOnly<ITerm | null>>(
    initialTerms.length > 0 ? initialTerms[0] : null
  );

  function setTerm(term: DeepReadOnly<ITerm | null>) {
    dispatch(setTerms(term ? [term] : []));
    internalSetTerm(term);
  }

  return (
    <>
      <TermAutocomplete
        label="Select Term"
        multiple={false}
        request={{
          userXIds: [userX?.id ?? 0],
        }}
        value={term}
        onChange={setTerm}
      />
    </>
  );
}
