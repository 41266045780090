import {Link, useLocation} from 'react-router-dom';
import {Box, Button, Divider, Drawer, IconButton, styled} from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import './index.scss';
import {MouseEvent, useState, useContext} from 'react';
import {ThemeContext} from '../../../theme/ThemeProvider';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/store';
import {UserHeaderPopup} from '../../../shared/components/UserHeaderPopup';

const NavLinkStyle = styled(Link)(({theme}) => ({
  color: theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    borderRadius: '15px',
  },
}));

export function Header() {
  const user = useSelector((state: RootState) => state.auth.user);
  const {mode, toggleColorMode} = useContext(ThemeContext);
  const [open, setState] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname?.replace('/', '');

  const toggleDrawer =
    (open: boolean) =>
    (event: React.KeyboardEvent<HTMLInputElement> | MouseEvent) => {
      if (
        event instanceof KeyboardEvent &&
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
      setState(open);
    };

  return (
    <>
      <div className="main">
        <div className="left-section">
          <div className="brand-logo">
            <Link to="/">
              <img src="/images/logo-orange-on-white.svg" />
            </Link>
          </div>
          <Box
            component="div"
            sx={{
              display: {
                xs: 'none',
                md: 'block',
              },
            }}
          >
            <div className="page-tabs">
              <NavLinkStyle
                className={`${currentPath === 'about' ? 'link-active' : ''} link`}
                to="/about"
              >
                About Us
              </NavLinkStyle>
              <NavLinkStyle
                className={`${currentPath === 'project_leo' ? 'link-active' : ''} link`}
                to="/project_leo"
              >
                How Project Leo Works
              </NavLinkStyle>
              <NavLinkStyle
                className="link"
                to="https://projectleo.substack.com"
              >
                Blog
              </NavLinkStyle>
              <NavLinkStyle className="link" to="mailto:admin@projectleo.com">
                Contact Us
              </NavLinkStyle>
            </div>
          </Box>
        </div>
        <Box
          component="div"
          sx={{
            display: {
              xs: 'none',
              md: 'block',
            },
          }}
        >
          <div className="right-section">
            {user ? (
              <>
                <Link to="/users/login.html">
                  <Button variant="outlined">Go to Dashboard</Button>
                </Link>
                <UserHeaderPopup></UserHeaderPopup>
              </>
            ) : (
              <Link to="/users/login.html">
                <Button variant="outlined">Log In / Sign Up</Button>
              </Link>
            )}

            {mode === 'dark' ? (
              <IconButton onClick={() => toggleColorMode()}>
                <LightModeIcon color="primary" />
              </IconButton>
            ) : (
              <IconButton>
                <DarkModeIcon onClick={() => toggleColorMode()} />
              </IconButton>
            )}
          </div>
        </Box>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer(true)}
          sx={{
            display: {
              xs: 'block',
              md: 'none',
            },
          }}
        >
          <MenuIcon style={{color: '#FF9800'}} />
        </IconButton>
      </div>

      <Drawer
        style={{width: '-webkit-fill-available'}}
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{
            p: 2,
            height: 1,
            backgroundColor: '#fff',
          }}
          className="drawer-content"
        >
          <div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div className="brand-logo">
                <img src="/images/logo-orange-on-white.svg" />
              </div>
              <IconButton sx={{mb: 2}}>
                <CloseIcon
                  style={{color: '#FF9800'}}
                  onClick={toggleDrawer(false)}
                />
              </IconButton>
            </div>
            <Divider sx={{mb: 2, backgroundColor: '#bcbcbc'}} />
          </div>
          <div className="drawer-body">
            <Box sx={{mb: 2}} className="links">
              <Link className="link" to="/about">
                About Us
              </Link>
              <Link className="link" to="/project_leo">
                How Project Leo Works
              </Link>
              <Link className="link" to="https://projectleo.substack.com">
                Blog
              </Link>
              <Link className="link" to="mailto:admin@projectleo.com">
                Contact Us
              </Link>
            </Box>
            <div className="action-buttons">
              <Link to="/users/login.html">
                <Button variant="outlined">Log In / Sign Up</Button>
              </Link>
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  );
}
