import {createTheme} from '@mui/material/styles';
import {AppTheme} from '../index';

const {palette} = createTheme();
export const theme: AppTheme = {
  dark: {
    palette: {
      mode: 'dark',

      primary: palette.augmentColor({
        color: {
          main: '#f8c49d',
        },
      }),
      secondary: palette.augmentColor({
        color: {
          main: '#cac3dc',
        },
      }),
      text: {
        // pick the onBackground colour OF DARK and paste it here
        primary: '#fff',
        // pick the onSurface colour OF DARK and paste it here
        secondary: '#e6e1e6',
      },
      background: {
        default: '#1c1b1e',
        paper: '#1c1b1e',
      },

      // I put the outline colour here
      divider: '#938f99',
    },
  },
  // REPEAT FOR LIGHT. instead of picking colours from dark palette, pick colours from the light one and repeat as above
  light: {
    palette: {
      mode: 'light',
      primary: palette.augmentColor({
        color: {
          main: '#FF9800',
          contrastText: '#FFFFFF',
        },
      }),
      secondary: {
        main: '#1677FF',
      },
      text: {
        primary: '#1c1b1e',
        secondary: '#1c1b1e',
      },
      background: {
        default: '#fffbff',
        paper: '#fffbff',
      },

      divider: '#79757f',
    },
  },
};
