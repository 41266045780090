import Typography from '@mui/material/Typography';
import './Unauthorized.scss';
import {useNavigate} from 'react-router';
import {Button} from '@mui/material';

export function Unauthorized() {
  const navigate = useNavigate();
  return (
    <div className="unauthorized">
      <Typography variant="h1" component="h1">
        403 - ACCESS DENIED
      </Typography>
      <Typography variant="h2" component="h2">
        You don't have permission to access this page.
      </Typography>
      <Button onClick={() => navigate('/')} variant="outlined" size="large">
        Go to HomePage
      </Button>
      <div></div>
    </div>
  );
}
