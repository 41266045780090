import {DeepReadOnly, toLong, writableForProto} from '../../../../libs/misc';
import {district_service, pl_types} from '../../../../generated/protobuf-js';
import {DynamicLoadAutocomplete} from '../DynamicLoadAutocomplete';
import {createService} from '../../../../libs/protos';
import IGetTermsRequest = district_service.IGetTermsRequest;
import DistrictService = district_service.DistrictService;
import ITerm = pl_types.ITerm;

export function TermAutocomplete<Multiple extends boolean | undefined = false>(
  props: DeepReadOnly<{
    label: string;
    multiple: Multiple;
    request: IGetTermsRequest;
    value: Multiple extends true ? ITerm[] : ITerm | null;
    onChange: (
      value: DeepReadOnly<Multiple extends true ? ITerm[] : ITerm | null>
    ) => void;
  }>
) {
  function loadMoreOptions(page: number) {
    if (page > 0) {
      return Promise.resolve([]);
    }

    return createService(DistrictService, 'DistrictService')
      .getTerms(writableForProto(props.request))
      .then(response => response.terms ?? []);
  }

  function getTermYear(term: ITerm) {
    return String(
      new Date(toLong(term?.startTimeMs ?? 0).toNumber()).getFullYear()
    );
  }

  return (
    <DynamicLoadAutocomplete<ITerm, Multiple>
      label={props.label}
      multiple={props.multiple}
      placeholder="Search by Term Name / Year"
      getId={term => term.id}
      groupBy={getTermYear}
      getLabel={term => term.name}
      loadMoreOptions={loadMoreOptions}
      value={props.value}
      onChange={props.onChange}
    />
  );
}
