import './Post.scss';
import {RemoveCircleTwoTone} from '@mui/icons-material';
import {DeepReadOnly, toLong} from '../misc';
import {pl_types} from '../../generated/protobuf-js';
import {AiInfo} from '../AiInfo/AiInfo';
import IProjectPostComment = pl_types.IProjectPostComment;

export function PostCommentHeader(
  props: DeepReadOnly<{
    comment: IProjectPostComment;
    saveStatus?: string | null | undefined;
    readOnly?: boolean | undefined;

    deleteIconClicked?: () => void;
  }>
) {
  return (
    <>
      <div className="post-header">
        <div className="post-user-name">
          {props.comment.userX?.firstName ?? ''}{' '}
          {props.comment.userX?.lastName ?? ''}
        </div>
        <div className="post-save-status">&nbsp;{props.saveStatus}&nbsp;</div>
        <div className="post-date">
          {new Date(
            toLong(props.comment.postTimeMs)?.toNumber() ?? 0
          ).toLocaleDateString()}
        </div>
        {!props.readOnly && !!props.deleteIconClicked && (
          <RemoveCircleTwoTone
            className="global-two-tone-delete-color"
            onClick={props.deleteIconClicked}
            cursor="pointer"
          />
        )}
        <AiInfo aiInfo={props.comment} />
      </div>
    </>
  );
}
