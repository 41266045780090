import {useNavigate} from 'react-router';
import {useEffect} from 'react';
import {TAB_PARAM_NAME} from '../../libs/TabbedPanel/TabbedPanel';
import {ProjectsTab} from '../projects/ProjectsDashboard';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';

export function RedirectToDashboard() {
  const userX = useSelector((state: RootState) => state.auth.user);

  const navigate = useNavigate();

  useEffect(() => {
    if (userX != null) {
      if (userX?.isAdminX) {
        navigate('/dashboards/admin-dashboard.html');
      } else if (userX?.isTeacher) {
        navigate('/dashboards/teacher-dashboard.html');
      } else if (userX?.isStudent) {
        navigate('/dashboards/student-dashboard.html');
      } else {
        navigate(
          `/projects/projects.html?${TAB_PARAM_NAME}=${
            ProjectsTab[ProjectsTab.ALL_PROJECTS]
          }`
        );
      }
    }
  });

  return <>Redirecting...</>;
}
