import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {pl_types} from '../../generated/protobuf-js';
import {DeepReadOnly} from '../../libs/misc';
import IClassX = pl_types.IClassX;
import ISection = pl_types.ISection;
import IAssignment = pl_types.IAssignment;
import ITerm = pl_types.ITerm;

export type SelectionState = DeepReadOnly<{
  terms: ITerm[];
  classXs: IClassX[];
  sections: ISection[];
  assignments: IAssignment[];
}>;

function getInitialJsonState<T>(name: string, orElse: T) {
  const json = localStorage.getItem(name);
  return json ? (JSON.parse(json) as T) : orElse;
}

const initialState: SelectionState = {
  terms: getInitialJsonState('terms', [] as ITerm[]),
  classXs: getInitialJsonState('classXs', [] as IClassX[]),
  sections: getInitialJsonState('sections', [] as ISection[]),
  assignments: getInitialJsonState('assignments', [] as IAssignment[]),
};

const selectionSlice = createSlice({
  name: 'selection',
  initialState,
  reducers: {
    setSelection: (_, action: PayloadAction<SelectionState>) => {
      for (const [key, value] of Object.entries(action.payload)) {
        localStorage.setItem(key, JSON.stringify(value));
      }
      return {...action.payload};
    },
    setTerms: (state, action: PayloadAction<DeepReadOnly<ITerm[]>>) => {
      Object.assign(state.terms, action.payload);
      localStorage.setItem('terms', JSON.stringify(action.payload));
    },
    setClassXs: (state, action: PayloadAction<DeepReadOnly<IClassX[]>>) => {
      Object.assign(state.classXs, action.payload);
      localStorage.setItem('classXs', JSON.stringify(action.payload));
    },
    setSections: (state, action: PayloadAction<DeepReadOnly<ISection[]>>) => {
      Object.assign(state.sections, action.payload);
      localStorage.setItem('sections', JSON.stringify(action.payload));
    },
    setAssignments: (
      state,
      action: PayloadAction<DeepReadOnly<IAssignment[]>>
    ) => {
      Object.assign(state.assignments, action.payload);
      localStorage.setItem('assignments', JSON.stringify(action.payload));
    },
  },
});

export const {setSelection, setTerms, setClassXs, setSections} =
  selectionSlice.actions;
export default selectionSlice.reducer;
