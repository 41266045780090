import {Grid} from '@mui/material';
import {Link} from 'react-router-dom';
import './index.scss';

export function Footer() {
  return (
    <div className="footer">
      <Grid container>
        <Grid item md={6} sm={12} style={{display: 'flex', gap: '1vw'}}>
          <div>
            <img src="/images/landing/logo-footer.svg" />
          </div>
          <div className="project">
            <div className="project-title">PROJECT LEO</div>
            <div className="project-links">
              <Link className="link" to="/about">
                About Us
              </Link>
              <Link className="link" to="/project_leo">
                How Project Leo Works
              </Link>
              <Link className="link" to="https://projectleo.substack.com">
                Blog
              </Link>
              <Link className="link" to="mailto:admin@projectleo.com">
                Contact Us
              </Link>
            </div>
          </div>
        </Grid>
        {/* <Grid item md={6} sm={12} className="news-letter">
          <div className="email-input-header">Newsletter</div>
          <div className="email-input-description">
            Get the latest updates about Project Leo
          </div>
          <div style={{display: 'flex', width: '-webkit-fill-available'}}>
            <input type="text" placeholder="Enter your email" />
            <Button variant="contained" style={{marginLeft: '-1vw'}}>
              Subscribe
            </Button>
          </div>
        </Grid> */}
      </Grid>
    </div>
  );
}
