import './index.scss';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../../store/store';
import {useState} from 'react';
import {UserXAvatar} from '../../../libs/UserXAvatar/UserXAvatar';
import {Popover} from '@mui/material';
import {logout} from '../../../features/auth/authSlice';
import {useNavigate} from 'react-router';
export function UserHeaderPopup() {
  const dispatch: AppDispatch = useDispatch();
  const userX = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClickAccountPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const signOut = () => {
    dispatch(logout());
  };

  const avatarPanel =
    userX != null ? (
      <div className="avatar-panel">
        <div className="avatar-icon">
          <UserXAvatar userX={userX} size="7rem" />
        </div>
        <div className="name">
          <span style={{whiteSpace: 'nowrap'}}>
            {userX?.firstName ?? ''} {userX?.lastName ?? ''}
          </span>
        </div>
        <div className="email">{userX?.emailAddress ?? ''}</div>
        <div className="section-div" />
        <div className="menu">
          <div
            className="link"
            onClick={() => navigate('/users/my-account.html')}
          >
            My Account
          </div>
        </div>
        <div className="menu">
          <div className="link" onClick={() => signOut()}>
            Log out
          </div>
        </div>
      </div>
    ) : (
      <></>
    );

  return (
    <>
      <HelpOutlineIcon />
      <NotificationsActiveIcon />
      <div className="page-account">
        <span className="avatar-icon" onClick={handleClickAccountPopover}>
          <UserXAvatar userX={userX} size="2rem" />
        </span>
        {userX?.firstName ?? ''} {userX?.lastName ?? ''}
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {avatarPanel}
      </Popover>
    </>
  );
}
