import './DefaultPage.scss';
import {useContext} from 'react';
import {CSSProperties, PropsWithChildren, ReactNode} from 'react';
import {IconButton} from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import {ThemeContext} from '../../theme/ThemeProvider';
import {UserHeaderPopup} from '../../shared/components/UserHeaderPopup';

export function DefaultPage(
  props: PropsWithChildren<{
    title: ReactNode;
    bodyStyle?: CSSProperties;
  }>
) {
  const {mode, toggleColorMode} = useContext(ThemeContext);

  return (
    <>
      <div className="page">
        <div className="page-header">
          <div className="page-title">{props.title}</div>
          <div className="page-account">
            <UserHeaderPopup />
            {mode === 'dark' ? (
              <IconButton onClick={() => toggleColorMode()}>
                <LightModeIcon color="primary" />
              </IconButton>
            ) : (
              <IconButton>
                <DarkModeIcon onClick={() => toggleColorMode()} />
              </IconButton>
            )}
          </div>
        </div>
        <div className="page-body" style={props.bodyStyle}>
          {props.children}
        </div>
      </div>
    </>
  );
}
