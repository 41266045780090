import '../../../../libs/global.scss';
import {
  CSSProperties,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  AutocompleteRenderGetTagProps,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete/Autocomplete';
import {
  Autocomplete,
  AutocompleteValue,
  Checkbox,
  Chip,
  TextField,
} from '@mui/material';
import {useNotification} from '../../../hooks/Notification';
import {DeepReadOnly, removeFromDeepReadOnlyArray} from '../../../../libs/misc';

const PAGE_SIZE = 10;

export function DynamicLoadAutocomplete<
  T,
  Multiple extends boolean | undefined = false,
>(
  props: DeepReadOnly<{
    label: string;
    placeholder?: string;
    multiple: Multiple;

    value: Multiple extends true ? T[] : T | null;
    onChange: (
      value: DeepReadOnly<Multiple extends true ? T[] : T | null>
    ) => void;
    sort?: (a: DeepReadOnly<T>, b: DeepReadOnly<T>) => number;

    getId: (option: DeepReadOnly<T>) => number | string | null | undefined;
    groupBy?: (option: DeepReadOnly<T>) => string | null | undefined;
    getLabel: (option: DeepReadOnly<T>) => string | null | undefined;
    context?: unknown;

    loadMoreOptions: (
      page: number,
      pageSize: number,
      searchText: string
    ) => Promise<DeepReadOnly<T[]>>;

    style?: CSSProperties;

    renderOption?: (
      props: HTMLAttributes<HTMLLIElement>,
      option: DeepReadOnly<T>,
      state: AutocompleteRenderOptionState
    ) => ReactNode;
    renderTags?: (
      selection: DeepReadOnly<T[]>,
      getTagProps: AutocompleteRenderGetTagProps
    ) => ReactNode;
    renderTagLabel?: (option: DeepReadOnly<T>) => ReactNode;
    renderTagStyle?: (option: DeepReadOnly<T>) => CSSProperties | undefined;
  }>
) {
  const [options, setOptions] = useState<DeepReadOnly<T[]>>([]);
  const [pageToLoad, setPageToLoad] = useState(0);
  const pagesLoaded = useRef(new Map<number, DeepReadOnly<T[]>>());
  const [allPagesLoaded, setAllPagesLoaded] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const notification = useNotification();

  useEffect(() => {
    setOptions([]);
    setPageToLoad(0);
    pagesLoaded.current.clear();
    setAllPagesLoaded(false);
    loadNextPage(0);
    setLoading(true);
  }, [props.context]);

  useEffect(() => {
    setPageToLoad(0);
    pagesLoaded.current.clear();
    setAllPagesLoaded(false);
    loadNextPage(0);
  }, [searchText]);

  function loadNextPage(page: number) {
    if (allPagesLoaded) {
      loading || setLoading(false);
      return;
    } else if (!pagesLoaded.current.has(page)) {
      pagesLoaded.current.set(page, []);
      setPageToLoad(page + 1);
      props
        .loadMoreOptions(page, PAGE_SIZE, searchText)
        .then(newOptions => {
          if (newOptions.length < PAGE_SIZE) {
            setAllPagesLoaded(true);
          }
          pagesLoaded.current.set(page, newOptions);
          setOptions(
            Array.from(
              new Map(
                [
                  ...((props.multiple
                    ? props.value
                    : [props.value]) as DeepReadOnly<T[]>),
                  ...Array.from(pagesLoaded.current.values()).flatMap(v => v),
                ]
                  .filter(v => v != null)
                  .map(v => [props.getId(v), v])
              ).values()
            ).sort(
              props.sort
                ? props.sort
                : (a, b) =>
                    `${props.groupBy?.(a) ?? ''}\0${props.getLabel(a) ?? ''}`.localeCompare(
                      `${props.groupBy?.(b) ?? ''}\0${props.getLabel(b) ?? ''}`
                    )
            )
          );
        })
        .catch(notification.handleError('Failed to add additional content'))
        .finally(() => setLoading(false));
    }
  }

  return (
    <Autocomplete<DeepReadOnly<T>, Multiple, false, false>
      renderInput={params => (
        <TextField
          {...params}
          label={props.label}
          placeholder={props.placeholder}
        />
      )}
      // TextField value.
      inputValue={searchText}
      onInputChange={(e, value) => setSearchText(value)}
      // Autocomplete value.
      value={
        props.value as AutocompleteValue<
          DeepReadOnly<T>,
          Multiple,
          false,
          false
        >
      }
      onChange={(e, value) => {
        props.onChange(
          value as DeepReadOnly<Multiple extends true ? T[] : T | null>
        );
      }}
      options={options}
      isOptionEqualToValue={(option, value) =>
        props.getId(option) === props.getId(value)
      }
      groupBy={option => props.groupBy?.(option) ?? ''}
      getOptionLabel={option => (option ? props.getLabel(option) ?? '' : '')}
      disableCloseOnSelect={props.multiple}
      style={props.style}
      autoHighlight
      openOnFocus={true}
      size="small"
      renderOption={
        props.renderOption
          ? props.renderOption
          : (params, option, {selected}) => (
              <li
                // TODO(PLI-36): Remove "key" from spread.
                {...params}
                className={
                  !props.multiple ? 'global-flex-row' : params.className
                }
                style={{
                  cursor: 'pointer',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {props.multiple && (
                  <Checkbox style={{marginRight: 8}} checked={selected} />
                )}
                <span
                  style={{
                    cursor: 'pointer',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {props.getLabel(option) ?? ''}
                </span>
              </li>
            )
      }
      renderTags={
        props.renderTags
          ? props.renderTags
          : !props.multiple
            ? undefined
            : (options, getTagProps) =>
                options.map(option => (
                  <Chip
                    {...getTagProps}
                    key={props.getId(option)}
                    label={
                      props.renderTagLabel?.(option) ??
                      props.getLabel(option) ??
                      ''
                    }
                    size="small"
                    onDelete={() => {
                      if (props.multiple) {
                        props.onChange(
                          removeFromDeepReadOnlyArray(
                            props.value as DeepReadOnly<T[]>,
                            props.getId,
                            props.getId(option)
                          ) as DeepReadOnly<
                            Multiple extends true ? T[] : T | null
                          >
                        );
                      }
                    }}
                    style={props.renderTagStyle?.(option)}
                  />
                ))
      }
      filterOptions={(options, state) => {
        if (state.inputValue.length === 0) {
          return options;
        }
        const lowerCaseState = state.inputValue.toLowerCase();
        return options.filter(option => {
          return (props.getLabel(option) ?? '')
            .toLowerCase()
            .includes(lowerCaseState);
        });
      }}
      ListboxProps={{
        onScroll: e => {
          const listboxNode = e.currentTarget;
          if (
            listboxNode.scrollTop + listboxNode.clientHeight >=
            listboxNode.scrollHeight
          ) {
            loadNextPage(pageToLoad);
          }
        },
      }}
      loading={loading}
    />
  );
}
