import {Card} from '@mui/material';
import {CSSProperties} from 'react';
import {DeepReadOnly} from '../misc';
import {pl_types} from '../../generated/protobuf-js';
import {ProjectInputCardHeader} from './ProjectInputCardHeader';
import IProjectDefinition = pl_types.IProjectDefinition;
import ValueType = pl_types.ProjectInputCategory.ValueType;
import State = pl_types.ProjectDefinition.State;

const stateToColors = new Map<State | null | undefined, string>([
  [State.PROCESSING, '#eeffee'],
  [State.COMPLETED, '#eeeeee'],
  [State.FAILED, '#ffeeee'],
]);

export function ProjectInputSummaryCard(
  props: DeepReadOnly<{
    projectInput: IProjectDefinition;
    includeRefreshNotice?: boolean;
  }>
) {
  return (
    <Card
      id={props.projectInput.id?.toString?.() ?? ''}
      elevation={6}
      sx={{
        height: '100%',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
      }}
      className="global-black-color"
      style={{backgroundColor: stateToColors.get(props.projectInput.state)}}
    >
      <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
        <ProjectInputCardHeader {...props} />
        <div
          style={{
            cursor: 'pointer',
            flex: '1 1 auto',
            overflowY: 'auto',
          }}
        >
          {props.projectInput.state === State.PROCESSING && (
            <div style={{fontStyle: 'italic', paddingBottom: '1rem'}}>
              Still generating projects for the following input:
            </div>
          )}
          {props.projectInput.state === State.FAILED && (
            <div style={{fontStyle: 'italic', paddingBottom: '1rem'}}>
              Failed to generate projects for the following input:
            </div>
          )}
          <ProjectInputs
            projectInput={props.projectInput}
            categoryStyle={{fontWeight: 'bold'}}
          />
        </div>
        {props.includeRefreshNotice &&
          props.projectInput.state === State.PROCESSING && (
            <div style={{fontStyle: 'italic'}}>
              Refresh the page to check for these projects.
            </div>
          )}
      </div>
    </Card>
  );
}

export function ProjectInputs(
  props: DeepReadOnly<{
    projectInput: IProjectDefinition | null | undefined;
    categoryStyle?: CSSProperties;
  }>
) {
  if (!props.projectInput) {
    return <></>;
  }

  return (
    <>
      {props.projectInput.inputs?.map(input => (
        <div key={input.category?.id?.toString?.() ?? ''}>
          <div
            style={{
              paddingBottom: 0,
              paddingLeft: '1rem',
              ...props.categoryStyle,
            }}
          >
            {input.category?.name}:
          </div>
          <ul style={{marginTop: 0}}>
            {input?.category?.valueType === ValueType.FREE_TEXT
              ? input.freeTexts?.map?.((text, i) => <li key={i}>{text}</li>)
              : input.selectedIds?.map?.((id, i) => {
                  const option = input.category?.options?.find?.(
                    o => o.id === id
                  );
                  return (
                    <li key={i} style={{}}>
                      {option?.name}
                    </li>
                  );
                })}
          </ul>
        </div>
      ))}
    </>
  );
}
