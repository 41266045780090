import {pl_types} from '../../generated/protobuf-js';
import {DeepReadOnly} from '../../libs/misc';
import {Grid} from '@mui/material';
import {ProjectSummaryCard} from '../../libs/ProjectCard/ProjectSummaryCard';
import IProject = pl_types.IProject;

export function MyProjectsTab(
  props: DeepReadOnly<{
    projects: IProject[];
    updateProject?: (project: DeepReadOnly<IProject>) => void;
    loading?: boolean;
    onShowProjectDetails?: (project: DeepReadOnly<IProject>) => void;
  }>
) {
  return props.loading ? (
    <div>Loading...</div>
  ) : props.projects.filter(p => p.active === true).length === 0 ? (
    <div
      style={{
        fontSize: '1.2rem',
        paddingTop: '1rem',
      }}
    >
      To see projects here, select projects on the ALL PROJECTS tab.
    </div>
  ) : (
    <Grid container spacing={2} paddingTop={2}>
      {props.projects
        .filter(p => p.active === true)
        .map(project => (
          <Grid item key={project.id!} xs={6} md={4} xl={3}>
            <ProjectSummaryCard
              project={project}
              updateProject={props.updateProject}
              onShowProjectDetails={props.onShowProjectDetails}
            />
          </Grid>
        ))}
    </Grid>
  );
}
