import {pl_types} from '../../generated/protobuf-js';
import {Grid} from '@mui/material';
import {ProjectSummaryCard} from '../../libs/ProjectCard/ProjectSummaryCard';
import {DeepReadOnly} from '../../libs/misc';
import {ProjectInputSummaryCard} from '../../libs/ProjectInputCard/ProjectInputSummaryCard';
import IProject = pl_types.IProject;
import State = pl_types.ProjectDefinition.State;
import IProjectDefinition = pl_types.IProjectDefinition;

export function AllProjectsTab(
  props: DeepReadOnly<{
    projects: IProject[];
    projectInputs: IProjectDefinition[];
    updateProject?: (project: DeepReadOnly<IProject>) => void;
    loading?: boolean;
    onShowProjectDetails?: (project: DeepReadOnly<IProject>) => void;
  }>
) {
  return props.loading ? (
    <div>Loading...</div>
  ) : props.projects.length === 0 && props.projectInputs.length === 0 ? (
    <div
      style={{
        fontSize: '1.2rem',
        paddingTop: '1rem',
      }}
    >
      To see projects here, generate them on the BUILDER tab.
      <br />
      Refresh this page to check for new projects.
    </div>
  ) : (
    <>
      <Grid container spacing={2} paddingTop={2}>
        {props.projectInputs
          .filter(e => e.state === State.FAILED)
          .map(projectInput => (
            <Grid key={projectInput.id!} item xs={6} md={4} xl={3}>
              <ProjectInputSummaryCard projectInput={projectInput} />
            </Grid>
          ))}
        {props.projectInputs
          .filter(e => e.state === State.PROCESSING)
          .map(projectInput => (
            <Grid item key={projectInput.id!} xs={6} md={4} xl={3}>
              <ProjectInputSummaryCard
                projectInput={projectInput}
                includeRefreshNotice={true}
              />
            </Grid>
          ))}
      </Grid>
      <Grid container spacing={2} paddingTop={2}>
        {props.projects.map(project => (
          <Grid key={project.id!} item xs={6} md={4} xl={3}>
            <ProjectSummaryCard
              project={project}
              updateProject={props.updateProject}
              onShowProjectDetails={props.onShowProjectDetails}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
