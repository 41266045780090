import {useToast} from '../../../toast/ToastProvider';
// import {error_service} from '../../../generated/protobuf-js';
// import ErrorService = error_service.ErrorService;
// import {createService} from '../../../libs/protos';

export function useNotification() {
  const {showToast} = useToast();

  function handleError(message: string, additionalDetails?: unknown) {
    return (error: unknown) => {
      console.log(error);
      showToast(message, 'error');
      console.log(additionalDetails);
      return Promise.resolve();
      // return createService(ErrorService, 'ErrorService')
      //   .reportError({})
      //   .then(console.log)
      //   .catch(console.error);
    };
  }

  function showSuccess(message: string) {
    return () => {
      showToast(message, 'success');
    };
  }

  return {handleError, showSuccess};
}
