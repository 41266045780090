import './ClassXManagement.scss';
import {DefaultPage} from '../../libs/DefaultPage/DefaultPage';
import {TabbedPanel} from '../../libs/TabbedPanel/TabbedPanel';
import {EditClassXsTab} from './EditClassXsTab';
import {EditAssignmentsTab} from './EditAssignmentsTab';

enum ClassXManagementTab {
  // OVERVIEW,
  EDIT_CLASSES,
  EDIT_ASSIGNMENTS,
}

export function ClassXManagement() {
  return (
    <>
      <DefaultPage title="Class Management">
        <div style={{height: '100%'}}>
          <TabbedPanel
            tabKeyEnum={ClassXManagementTab}
            defaultTabKey={ClassXManagementTab.EDIT_CLASSES}
            tabs={[
              // {
              //   key: ClassXManagementTab.OVERVIEW,
              //   label: 'Overview',
              //   content: <OverviewTab />,
              // },
              {
                key: ClassXManagementTab.EDIT_CLASSES,
                label: 'Edit Classes',
                content: <EditClassXsTab />,
              },
              {
                key: ClassXManagementTab.EDIT_ASSIGNMENTS,
                label: 'Edit Assignments',
                content: <EditAssignmentsTab />,
              },
            ]}
          />
        </div>
      </DefaultPage>
    </>
  );
}
