import '../../../libs/global.scss';
import {useEffect, useState} from 'react';
import {useFormFields} from '../../../libs/form_utils/forms';
import {useDelayedAction} from '../../../libs/delayed_action';
import {
  EditorProfile,
  ProfileEditor,
} from '../../../libs/ProfileEditor/ProfileEditor';
import {createService} from '../../../libs/protos';
import {user_x_service} from '../../../generated/protobuf-js';
import {DynamicUserXAutocomplete} from '../../../libs/common_fields/DynamicUserXAutocomplete';
import {DeepReadOnly} from '../../../libs/misc';
import UserXService = user_x_service.UserXService;
import IFullUserXDetails = user_x_service.IFullUserXDetails;
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/store';
import {useNotification} from '../../../shared/hooks/Notification';

export function Accounts() {
  const userX = useSelector((state: RootState) => state.auth.user);

  const formFields = useFormFields();
  const editingUserX =
    formFields.useSingleAutocompleteFormField<DeepReadOnly<IFullUserXDetails>>(
      'userX'
    );
  const disabled = editingUserX.getValue() == null;

  const profileForm = useFormFields({
    onChange: () => autoSave.trigger(),
    disabled,
  });
  const [profileSaveStatus, setProfileSaveStatus] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const notification = useNotification();
  const autoSave = useDelayedAction(
    () => {
      if (disabled) {
        setProfileSaveStatus('');
      } else {
        setProfileSaveStatus('Modified');
      }
    },
    () => {
      if (disabled) {
        setProfileSaveStatus('');
        return;
      }

      setProfileSaveStatus('Saving...');
      const fullProfile: EditorProfile = profileForm.getValuesObject(true);
      createService(UserXService, 'UserXService')
        .upsertUserX(
          // TODO: Get rid of this hack.
          Object.assign({}, fullProfile, {
            userX: Object.assign(
              {},
              fullProfile as unknown as IFullUserXDetails,
              {
                userX: fullProfile as unknown as IFullUserXDetails,
              }
            ),
          })
        )
        .then(response => {
          if (response.error != null) {
            setErrorMessage(response.error);
            setProfileSaveStatus('Save Error');
          } else {
            setProfileSaveStatus('Saved');
          }
        })
        .catch(notification.handleError('Failed to update user'));
    },
    1500
  );

  const clearError = useDelayedAction(
    () => {},
    () => {
      setErrorMessage('');
    },
    5000
  );

  useEffect(() => {
    if (errorMessage !== '') {
      clearError.trigger();
    }
  }, [errorMessage]);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setErrorMessage(''), 5000);
    }
  }, [errorMessage]);

  return (
    <div style={{padding: '15px'}}>
      <form>
        <div className="global-flex-column">
          <div className="global-flex-column" style={{height: '5em'}}>
            <DynamicUserXAutocomplete
              label="Search by Name or Email Address"
              baseRequest={{
                inDistrictIds: userX?.isAdminX
                  ? undefined
                  : userX?.districtId != null
                    ? [userX?.districtId]
                    : undefined,
              }}
              userXField={editingUserX}
            />
            <div
              className="global-error-notice"
              style={{display: errorMessage ? undefined : 'none'}}
            >
              {errorMessage}
            </div>
          </div>
          <ProfileEditor
            userXId={editingUserX.getValue?.()?.userX?.id ?? null}
            profileForm={profileForm}
            profileSaveStatus={profileSaveStatus}
          />
        </div>
      </form>
    </div>
  );
}
