import {Post} from '../Post/Post';
import {pl_types, post_service} from '../../generated/protobuf-js';
import {CSSProperties, useEffect, useRef, useState} from 'react';
import {createService} from '../protos';
import {
  DeepReadOnly,
  replaceInDeepReadOnlyArray,
  writableForProto,
} from '../misc';
import IProjectPost = pl_types.IProjectPost;
import PostService = post_service.PostService;
import IGetProjectPostsRequest = post_service.IGetProjectPostsRequest;
import IUserX = pl_types.IUserX;
import {useNotification} from '../../shared/hooks/Notification';

const PAGE_SIZE = 2;

export function PostsFeed(
  props: DeepReadOnly<{
    posts?: IProjectPost[];
    request?: IGetProjectPostsRequest;
    paged?: boolean;
    getUserXHighlightStyle?: (
      userX?: DeepReadOnly<IUserX> | null | undefined
    ) => CSSProperties | undefined;
  }>
) {
  console.assert(
    props.posts || props.request,
    'PostsFeed: requires posts or request.'
  );

  const [posts, setPosts] = useState<DeepReadOnly<IProjectPost[]>>([]);
  const lastGetProjectsPostRequest = useRef('');
  const [pageToLoad, setPageToLoad] = useState(0);
  const pagesLoaded = useRef(new Set<number>());
  const [allPagesLoaded, setAllPagesLoaded] = useState(false);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const loadMoreRef = useRef<HTMLDivElement | null>(null);
  const notification = useNotification();

  useEffect(() => {
    if (props.posts != null) {
      lastGetProjectsPostRequest.current = '';
      setPosts([...(props.posts ?? [])]);
    } else if (props.request != null) {
      const newGetProjectsPostRequest = JSON.stringify(props.request);
      if (lastGetProjectsPostRequest.current !== newGetProjectsPostRequest) {
        lastGetProjectsPostRequest.current = newGetProjectsPostRequest;
        if (props.paged) {
          setPosts([]);
          setPageToLoad(0);
          pagesLoaded.current.clear();
          setAllPagesLoaded(false);
          loadNextPage(0);
        } else {
          createService(PostService, 'PostService')
            .getProjectPosts(Object.assign(props.request))
            .then(response => {
              if (
                lastGetProjectsPostRequest.current === newGetProjectsPostRequest
              ) {
                setPosts(response.projectPosts ?? []);
              }
            })
            .catch(notification.handleError('Failed to add comment'));
        }
      }
    }
  }, [props.posts, props.request]);

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && !allPagesLoaded) {
          loadNextPage(pageToLoad);
        }
      },
      {threshold: 1.0}
    );

    if (loadMoreRef.current) {
      observerRef.current.observe(loadMoreRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [pageToLoad]);

  function loadNextPage(pageToLoad: number) {
    console.assert(props.request);
    if (!pagesLoaded.current.has(pageToLoad)) {
      pagesLoaded.current.add(pageToLoad);
      setPageToLoad(page => page + 1);
      createService(PostService, 'PostService')
        .getProjectPosts(
          Object.assign({}, props.request, {
            page: pageToLoad,
            pageSize: PAGE_SIZE,
          } as IGetProjectPostsRequest)
        )
        .then(response => {
          if (response.projectPosts.length)
            setPosts(posts => [...posts, ...response.projectPosts]);

          setAllPagesLoaded(response.projectPosts.length < PAGE_SIZE);
        })
        .catch(notification.handleError('Failed to add comment'));
    }
  }

  function savePost(post: DeepReadOnly<pl_types.IProjectPost>) {
    createService(PostService, 'PostService')
      .upsertProjectPost({projectPost: writableForProto(post)})
      .catch(notification.handleError('Failed to saved post'));
  }

  return (
    <>
      <div className="post-feed">
        {posts.map(post => (
          <Post
            key={post.id ?? 0}
            post={post}
            postUpdated={post => {
              savePost(post);
              setPosts(replaceInDeepReadOnlyArray(posts, p => p.id, post));
            }}
            hideComments={!props.request?.includeComments}
            hideRatings={!props.request?.includeRatings}
            getUserXHighlightStyle={props.getUserXHighlightStyle}
          />
        ))}
        <div
          className="post-feed-load-more"
          ref={loadMoreRef}
          style={{
            display: props.paged && posts.length > 0 ? undefined : 'none',
          }}
        >
          {!allPagesLoaded && <p>Loading more posts...</p>}
        </div>
      </div>
    </>
  );
}
