import {pl_types} from '../../../generated/protobuf-js';
import ThumbsState = pl_types.Project.ThumbsState;
import {ChangeEvent, useEffect, useState} from 'react';
import {debounce} from 'lodash';
import './index.scss';

type Props = {
  inputText: string | null | undefined;
  thumbsState: ThumbsState | undefined | null;
  onChangeDebounced?: (newText: string) => void;
  onChange?: (newText: string) => void;
};

const debouncedThumbsStateReasonCall = debounce(
  (
    newText: string,
    prevFeedback: string,
    onChangeDebounced?: (newText: string) => void
  ) => {
    if (newText !== prevFeedback) {
      if (onChangeDebounced) {
        onChangeDebounced(newText);
      }
    }
  },
  1000
);

export const ThumbReasonText = ({
  inputText,
  thumbsState,
  onChangeDebounced,
  onChange,
}: Props) => {
  const [feedback, setFeedback] = useState(inputText || '');
  const [prevFeedback, setPrevFeedback] = useState(inputText || '');

  useEffect(() => {
    if (inputText) {
      setFeedback(inputText);
    }
  }, [inputText]);

  // Handle textarea change
  const onFeedbackChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newText = event?.target?.value;
    if (onChange) {
      onChange(newText);
    }
    setFeedback(newText);
    debouncedThumbsStateReasonCall(newText, prevFeedback, onChangeDebounced);
    setPrevFeedback(newText);
  };
  return (
    <textarea
      className={
        'react ' +
        (thumbsState === ThumbsState.THUMBS_UP ? 'up-react' : 'down-react')
      }
      value={feedback}
      onChange={onFeedbackChange}
      placeholder={`What do you ${thumbsState === ThumbsState.THUMBS_DOWN ? 'dis' : ''}like about this project?`}
    />
  );
};
