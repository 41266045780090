import './EditDistricts.scss';

import {ChangeEvent, useEffect, useState} from 'react';
import {
  Display,
  SelectFromList,
} from '../../../libs/SelectFromList/SelectFromList';
import {createService} from '../../../libs/protos';
import {district_service, pl_types} from '../../../generated/protobuf-js';
import {DISTRICT_SORTER} from '../../../libs/sorters';
import DistrictInformationResponse = district_service.DistrictInformationResponse;
import DistrictService = district_service.DistrictService;
import IDistrict = pl_types.IDistrict;
import {useNotification} from '../../../shared/hooks/Notification';

export function SelectDistrictFromList(props: {
  id: string;
  display: Display;
  districts: Map<number, IDistrict>;
  districtId: number;
  onSelect: (key: number) => void;
  defaultText: string;
}) {
  return SelectFromList<number, IDistrict>({
    id: props.id,
    display: props.display,
    values: props.districts,
    selectedKey: props.districtId,
    getKey: district => (district != null ? district.id! : -1),
    stringToKey: Number,
    compareValues: DISTRICT_SORTER,
    onSelect: props.onSelect,
    renderValue: key => (
      <>{(props.districts.get(key) || {name: props.defaultText}).name!}</>
    ),
  });
}

export function EditDistricts() {
  const [districts, setDistricts] = useState(new Map<number, IDistrict>());
  const [districtId, setDistrictId] = useState(-1);
  const [districtName, setDistrictName] = useState('');
  const notification = useNotification();

  const districtService = createService(DistrictService, 'DistrictService');

  function addDistrict() {
    districtService
      .addDistrict({district: {name: districtName}})
      .then(processDistrictInformationResponse)
      .catch(notification.handleError('Failed to add district'));
  }

  function updateDistrict() {
    districtService
      .updateDistrict({district: {id: districtId, name: districtName}})
      .then(processDistrictInformationResponse)
      .catch(notification.handleError('Failed to update district'));
  }

  function removeDistrict() {
    districtService
      .removeDistrict({
        districtId: districtId,
      })
      .then(processDistrictInformationResponse)
      .catch(notification.handleError('Failed to remove district'));
  }

  function processDistrictInformationResponse(
    response: DistrictInformationResponse
  ) {
    setDistricts(new Map(response.districts.map(v => [v.id!, v!])));
    setDistrictId(response.modifiedDistrictId!);
  }

  useEffect(() => {
    districtService
      .getDistricts({})
      .then(processDistrictInformationResponse)
      .catch(notification.handleError('Failed to remove district'));
  }, []);

  return (
    <>
      <table className="form-table">
        <tbody>
          <tr>
            <th>District:</th>
            <td>
              <SelectDistrictFromList
                id="districts"
                display={Display.RADIO_BUTTONS}
                districts={districts}
                districtId={districtId}
                onSelect={districtId => {
                  setDistrictId(districtId);
                  setDistrictName(
                    (districts.get(districtId) || {name: ''}).name!
                  );
                }}
                defaultText="[Create New District]"
              />
            </td>
          </tr>
          <tr>
            <th>Name:</th>
            <td>
              <input
                type="text"
                placeholder="New District Name"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setDistrictName(e.target.value);
                }}
                value={districtName}
              />
            </td>
          </tr>
          <tr>
            <th></th>
            <td className="form-buttons">
              <div hidden={districtId !== -1} onClick={addDistrict}>
                Add
              </div>
              <div hidden={districtId === -1} onClick={updateDistrict}>
                Update
              </div>
              <div
                className="delete-button"
                hidden={districtId === -1}
                onClick={removeDistrict}
              >
                Delete
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
