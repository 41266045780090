import './EditablePlainText.scss';
import React, {useEffect, useRef, useState, ReactNode} from 'react';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {DeepReadOnly} from '../misc';
import {Divider, IconButton} from '@mui/material';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import {ThemeEnum} from '../../theme/theme.enum';

type Props = {
  value: string | null | undefined;
  onChange?: (newText: string) => void;
  getCurrentMode?: (isEditing: boolean) => void;
  viewingStyle?: React.CSSProperties;
  viewingPlaceholder?: ReactNode | string | null | undefined;
  editingStyle?: React.CSSProperties;
  editingPlaceholder?: string | null | undefined;
  style?: React.CSSProperties;
};
export const EditablePlainText = ({
  value,
  onChange,
  getCurrentMode,
  viewingStyle,
  viewingPlaceholder,
  editingStyle,
  editingPlaceholder,
  style,
}: DeepReadOnly<Props>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [newText, setNewText] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const themeMode = useSelector((state: RootState) => state.theme.theme);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef?.current?.focus();
    }
    getCurrentMode?.(isEditing);
  }, [isEditing]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    } else if (event.key === 'Escape') {
      handleCancel();
    }
  };

  function handleSave() {
    setIsEditing(false);
    onChange?.(newText ?? '');
  }

  function handleCancel() {
    setNewText(value);
    setIsEditing(false);
  }

  return (
    <div className="editable_input_container">
      {!isEditing ? (
        <div
          className="editable_input_text"
          onClick={() => {
            setNewText(value);
            setIsEditing(!!onChange);
          }}
          style={{...style, ...viewingStyle}}
        >
          {(value ?? '').trim().length > 0
            ? value
            : viewingPlaceholder ?? 'Click to edit'}
        </div>
      ) : (
        <div onBlur={handleSave} className="input_main">
          <input
            autoFocus
            className="editable_input_field"
            ref={inputRef}
            value={newText ?? ''}
            onKeyDown={handleKeyDown}
            onChange={event => setNewText(event.target.value)}
            placeholder={editingPlaceholder ?? undefined}
            style={{
              ...style,
              ...editingStyle,
              color: themeMode === ThemeEnum.DARK ? 'white' : '#000',
            }}
          />
          <Divider orientation="vertical" />
          <IconButton
            color="success"
            size="small"
            onMouseDown={event => event.preventDefault()}
            onClick={handleSave}
          >
            <CheckCircleOutlineOutlinedIcon />
          </IconButton>
          <Divider orientation="vertical" />
          <IconButton
            color="error"
            size="small"
            sx={{
              marginRight: '4px',
            }}
            onMouseDown={event => event.preventDefault()}
            onClick={handleCancel}
          >
            <CancelOutlinedIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};
