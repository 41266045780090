import {useNavigate} from 'react-router';
import '../../../libs/LoginForm/LoginForm.scss';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../../store/store';
import {useNotification} from '../../../shared/hooks/Notification';
import {useTheme} from '@mui/material/styles';
import {
  DEFAULT_FORWARD_URL,
  LOAD_CREDENTIALS_PARAM,
} from '../../../libs/authentication';
import {LoginForm} from '../../../libs/LoginForm/LoginForm';
import {useEffect} from 'react';
import {createService} from '../../../libs/protos';
import {user_x_service} from '../../../generated/protobuf-js';
import {setUserAndSession} from '../../../features/auth/authSlice';
import {Link} from 'react-router-dom';
import UserXService = user_x_service.UserXService;

export function Login() {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const userX = useSelector((state: RootState) => state.auth.user);
  const notification = useNotification();
  const theme = useTheme();
  const queryParameters = new URLSearchParams(window.location.search);
  const loadCredentials = queryParameters.get(LOAD_CREDENTIALS_PARAM) != null;

  if (loadCredentials) {
    createService(UserXService, 'UserXService')
      .getUserXs({ofSelf: true})
      .then(response => {
        if (response.userXs.length === 1) {
          dispatch(
            setUserAndSession({
              user: response.userXs?.[0]?.userX,
              sessionExpirationTimeMs: null,
            })
          );
        }
      })
      .then(() => navigate(DEFAULT_FORWARD_URL))
      .catch(error => {
        notification.handleError('Error loading user.')(error);
        navigate('/');
      });
  }

  useEffect(() => {
    if (userX !== null) {
      navigate(DEFAULT_FORWARD_URL);
    }
  }, [userX]);

  return (
    <>
      <div
        style={{
          background: theme.palette.background.default,
          color: theme?.palette?.text?.primary,
          height: '-webkit-fill-available',
        }}
        className="login-login-form"
      >
        <div className="space-filler" />
        {loadCredentials ? (
          <>
            <div className="logo">
              <Link to="/">
                <img src="/images/logo-orange-on-white.svg" />
              </Link>
            </div>
            <div className="space-filler" />
            <div className="logout">Logging in...</div>
          </>
        ) : (
          <LoginForm
            onSuccess={() => {}}
            onFailure={() => {
              // TODO: Don't do anything after 3 attempts for now.
            }}
            onError={notification.handleError('Failed to login')}
            onCancel={() => navigate('/')}
            className="login-form-modal"
          />
        )}
      </div>
    </>
  );
}
