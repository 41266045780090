import {pl_types} from '../generated/protobuf-js';

export function login(username: string, password: string) {
  return fetch(
    new Request('/api/login.html', {
      method: 'POST',
      body: new URLSearchParams({
        username,
        password,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  ).then(async response => {
    const expirationTime = new Date(
      response.headers.get('x-session-timeout-ms') ||
        new Date(new Date().getTime() + 60 * 60 * 1000).toISOString()
    );

    return {
      user: pl_types.UserX.decode(new Uint8Array(await response.arrayBuffer())),
      sessionExpirationTimeMs: expirationTime.getTime(),
    };
  });
}

export function logout() {
  return fetch(new Request('/api/logout.html', {method: 'POST'}));
}
