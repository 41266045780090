import './Post.scss';
import {
  EditTwoTone,
  EmojiEventsOutlined,
  EmojiEventsTwoTone,
  RemoveCircleTwoTone,
} from '@mui/icons-material';
import {DeepReadOnly, toLong} from '../misc';
import {pl_types} from '../../generated/protobuf-js';
import {AiInfo} from '../AiInfo/AiInfo';
import IProjectPost = pl_types.IProjectPost;

export function PostHeader(
  props: DeepReadOnly<{
    post: IProjectPost;
    saveStatus?: string | null | undefined;
    readOnly?: boolean | undefined;

    editIconClicked?: () => void;
    deleteIconClicked?: () => void;
    portfolioIconClicked?: () => void;
  }>
) {
  return (
    <>
      <div className="post-header">
        <div className="post-user-name">
          {props.post.userX?.firstName ?? ''} {props.post.userX?.lastName ?? ''}
        </div>
        {!props.readOnly && !!props.editIconClicked && (
          <EditTwoTone
            className="global-two-tone-pencil-color"
            onClick={props.editIconClicked}
            cursor="pointer"
          />
        )}
        <div className="post-save-status">&nbsp;{props.saveStatus}&nbsp;</div>
        {props.portfolioIconClicked && (
          <>
            {!!props.post?.includeInPortfolio && (
              <EmojiEventsTwoTone
                className="global-two-tone-portfolio-color"
                onClick={props.portfolioIconClicked}
                cursor="pointer"
              />
            )}
            {!props.post?.includeInPortfolio && (
              <EmojiEventsOutlined
                className="global-outlined-unselected-color"
                onClick={props.portfolioIconClicked}
                cursor="pointer"
              />
            )}
          </>
        )}
        <div className="post-date">
          {new Date(
            toLong(props.post.postTimeMs)?.toNumber() ?? 0
          ).toLocaleDateString()}
        </div>
        {!props.readOnly && !!props.deleteIconClicked && (
          <RemoveCircleTwoTone
            className="global-two-tone-delete-color"
            onClick={props.deleteIconClicked}
            cursor="pointer"
          />
        )}
        <AiInfo aiInfo={props.post} />
      </div>
    </>
  );
}
