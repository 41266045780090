import './DefaultPage.scss';
import {Link} from 'react-router-dom';
import {Outlet, useNavigate} from 'react-router';
import {ReactNode, useState, CSSProperties} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import {
  Home,
  AccountCircle,
  RocketLaunch,
  ChevronRight,
  Group,
} from '@mui/icons-material';
import {useLocation} from 'react-router-dom';
import {styled, Theme, CSSObject} from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

enum MenuKeys {
  ADMIN,
  ADMIN_ACCOUNTS,
  ADMIN_DISTRICTS,
  ADMIN_SCHOOLS,
  CLASS_MANAGEMENT,
  DASHBOARD_ADMIN,
  DASHBOARD_STUDENT,
  DASHBOARD_TEACHER,
  MY_ACCOUNT,
  // PORTFOLIOS,
  PROJECTS,
}

interface IMenuList {
  label: string;
  key: MenuKeys;
  link?: string;
  icon?: ReactNode;
  style?: CSSProperties;
}
interface IMenuListWithChild extends IMenuList {
  children?: IMenuList[];
}

const drawerWidth = 200;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({theme, open}) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export function DefaultPageNav() {
  const userX = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const {pathname} = useLocation();
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setOpenMenu(!openMenu);
  };

  const topMenuList: IMenuListWithChild[] = [
    {
      label: 'Admin Dashboard',
      key: MenuKeys.DASHBOARD_ADMIN,
      link: '/dashboards/admin-dashboard.html',
      icon: <Home />,
      style: {
        display: userX?.isAdminX ? 'block' : 'none',
      },
    },
    {
      label:
        (userX?.isAdminX || userX?.isTeacher ? 'Student ' : '') + 'Dashboard',
      key: MenuKeys.DASHBOARD_STUDENT,
      link: '/dashboards/student-dashboard.html',
      icon: <Home />,
      style: {
        display: userX?.isAdminX || userX?.isStudent ? 'block' : 'none',
      },
    },
    {
      label:
        (userX?.isAdminX || userX?.isStudent ? 'Teacher ' : '') + 'Dashboard',
      key: MenuKeys.DASHBOARD_TEACHER,
      icon: <Home />,
      link: '/dashboards/teacher-dashboard.html',
      style: {
        display: userX?.isAdminX || userX?.isTeacher ? 'block' : 'none',
      },
    },
    {
      label: 'Class Management',
      key: MenuKeys.CLASS_MANAGEMENT,
      icon: <Group />,
      link: '/class-management/class-management.html',
      style: {
        display: userX?.isAdminX || userX?.isTeacher ? 'block' : 'none',
      },
    },
    {
      label: 'Projects',
      key: MenuKeys.PROJECTS,
      icon: <RocketLaunch />,
      link: '/projects/projects.html',
    },
    // {
    //   label: 'Portfolios',
    //   key: MenuKeys.PORTFOLIOS,
    //   icon: <BookOutlined />,
    //   style: {
    //     display: userX?.isAdminX || userX?.isStudent ? 'block' : 'none',
    //   },
    // },
    // {
    //   label: 'Administration',
    //   key: MenuKeys.ADMIN,
    //   icon: <Settings />,
    //   style: {
    //     display: userX?.isAdminX ? 'block' : 'none',
    //   },
    //   children: [
    //     {
    //       label: 'Accounts',
    //       key: MenuKeys.ADMIN_ACCOUNTS,
    //       link: '/admin/accounts.html',
    //     },
    //     {
    //       label: 'Schools',
    //       key: MenuKeys.ADMIN_SCHOOLS,
    //       link: '/profiles/edit-schools.html',
    //     },
    //     {
    //       label: 'Districts',
    //       key: MenuKeys.ADMIN_DISTRICTS,
    //       link: '/profiles/edit-districts.html',
    //     },
    //   ],
    // },
  ];

  const bottomMenuList = [
    {
      label: 'My Account',
      key: MenuKeys.MY_ACCOUNT,
      icon: <AccountCircle />,
      link: '/users/my-account.html',
    },
  ];

  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline />
      <Drawer variant="permanent" open={openMenu}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '8px 16px',
          }}
        >
          <Link to="/">
            <img
              src={
                !openMenu
                  ? '/images/logo-orange-on-white-circles.svg'
                  : '/images/logo-orange-on-white.svg'
              }
              width={!openMenu ? 24 : 150}
              alt="Project Leo Logo | Go Home"
            />
          </Link>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <List>
            {topMenuList.map((menuItem: IMenuListWithChild, index) => (
              <ListItem
                key={`${menuItem.key}-${index}`}
                disablePadding
                sx={menuItem.style && {...menuItem.style}}
                selected={
                  menuItem?.link ? pathname.includes(menuItem?.link) : false
                }
              >
                <Tooltip title={openMenu ? '' : menuItem.label}>
                  <ListItemButton
                    sx={{
                      justifyContent: openMenu ? 'initial' : 'center',
                      flexDirection: openMenu ? 'row' : 'column',
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      menuItem.link && navigate(menuItem.link);
                      menuItem.children && handlePopoverOpen(e);
                      return;
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 1,
                        justifyContent: 'center',
                      }}
                    >
                      {menuItem.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={menuItem.label}
                      sx={{
                        display: openMenu ? 'block' : 'none',
                        '& .MuiListItemText-primary': {
                          fontSize: '14px !important',
                        },
                      }}
                    />

                    {menuItem.children && (
                      <ChevronRight
                        sx={{
                          display: openMenu ? 'block' : 'none',
                        }}
                      />
                    )}
                  </ListItemButton>
                </Tooltip>
                {menuItem.children && (
                  <Popover
                    id="menu_child_list"
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    onClose={handlePopoverClose}
                  >
                    <List>
                      {menuItem.children.map(
                        (subMenu: IMenuList, index: number) => (
                          <ListItem
                            selected={
                              subMenu?.link
                                ? pathname.includes(subMenu?.link)
                                : false
                            }
                            disablePadding
                            key={`${subMenu.key}-${index}`}
                            onClick={() =>
                              subMenu.link && navigate(subMenu.link)
                            }
                          >
                            <ListItemButton>
                              <ListItemText primary={subMenu.label} />
                            </ListItemButton>
                          </ListItem>
                        )
                      )}
                    </List>
                  </Popover>
                )}
              </ListItem>
            ))}
          </List>

          <List>
            {bottomMenuList.map((menuItem, index) => (
              <ListItem
                key={`${menuItem.key}-${index}`}
                disablePadding
                sx={{display: 'block'}}
                onClick={() => navigate(menuItem.link)}
                selected={pathname.includes(menuItem.link)}
              >
                <Tooltip title={openMenu ? '' : menuItem.label}>
                  <ListItemButton
                    sx={{
                      justifyContent: openMenu ? 'initial' : 'center',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 1,
                        justifyContent: 'center',
                      }}
                    >
                      {menuItem.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={menuItem.label}
                      sx={{
                        display: openMenu ? 'block' : 'none',
                        '& .MuiListItemText-primary': {
                          fontSize: '14px !important',
                        },
                      }}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            ))}
            <Divider />
            <IconButton onClick={handleDrawerToggle}>
              {!openMenu ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </List>
        </Box>
      </Drawer>
      <Box component="main" style={{height: '100vh'}}>
        <Outlet />
      </Box>
    </Box>
  );
}
