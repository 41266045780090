import {Container, Grid, Typography} from '@mui/material';
import {LandingPageLayout} from '../../components/Layouts/LandingPageLayout';
import './ProjectLeo.scss';

export function ProjectLeo() {
  return (
    <LandingPageLayout>
      <Container maxWidth="xl" className="container">
        <Grid container spacing={4} className="main-card">
          <Grid item xs={12} md={7} className="card-header">
            <Typography variant="h2" className="">
              Project Leo uses Large Language Models (LLMs) to streamline lesson
              planning by creating personalized projects for each student and
              fostering a collaborative classroom community.
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} className="card-image">
            <img
              className="image-alignment"
              src="/images/landing/projects_daigram1.png"
            />
          </Grid>
        </Grid>
      </Container>
      <div className="sepration-line"></div>
      <Container maxWidth="xl">
        <div className="project-working-section">
          <Typography variant="h2">How Project Leo Works</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={7} className="content-left-section">
              <div className="sub-content">
                <Typography className="sub-header">
                  Students Create Unique Projects
                </Typography>
                <div className="sub-body">
                  Project Leo empowers students to direct their learning by
                  creating personalized projects aligned with their passions and
                  a teacher’s learning objective.
                </div>
              </div>
              <div className="sub-content">
                <div className="sub-header">Students Share Their Progress</div>
                <div className="sub-body">
                  Students reflect upon their progress in the Community Posts
                  section of our platform. Here students track milestones and
                  practice surfacing setbacks with their teachers and peers.
                </div>
              </div>
              <div className="sub-content">
                <div className="sub-header">
                  Teachers and Peers Provide Feedback
                </div>
                <div className="sub-body">
                  Using Project Leo allows teachers and peers the opportunity to
                  review projects and offering insights and feedback. This
                  collaborative atmosphere allows students to benefit from a
                  communal learning environment.
                </div>
              </div>
              <div className="sub-content">
                <div className="sub-header">Automated Student Portfolio</div>
                <div className="sub-body">
                  Project Leo automatically creates a portfolio from a student’s
                  projects and posts, highlighting their accomplishments for
                  external audiences such as prospective employers or colleges.
                  It also provides valuable insights for teachers when
                  evaluating educational achievements.
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={5} className="image-right-section">
              <img
                className="image-alignment"
                src="/images/landing/projects/projects_flow.png"
              />
            </Grid>
          </Grid>
        </div>
      </Container>
      <div className="sepration-line"></div>
      <Container maxWidth="xl">
        <div className="project-working-section">
          <Grid container spacing={4}>
            <Grid item xs={12} md={7} className="content-left-section">
              <div className="sub-content">
                <div className="sub-header">Project Leo Supports Students</div>
                <div className="sub-body">
                  Project Leo creates custom milestones for every project and
                  crafts personalized to-do lists, providing clear starting
                  points and a structured paths forward. Our AI-powered “Coach
                  Leo” responds to every post with targeted feedback,
                  constructive criticism, and thought-provoking questions.
                </div>
              </div>
              <div className="sub-content">
                <div className="sub-header">Project Leo Supports Teachers</div>
                <div className="sub-body">
                  Supporting dozens of students each working on individual
                  projects can prove daunting for even the most seasoned
                  teacher. Our dashboard tracks every student’s work, allowing
                  teachers to effectively target those students who need the
                  most help.
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={5} className="image-right-section">
              <img
                className="image-alignment"
                src="/images/landing/projects/undraw_educator.png"
              />
            </Grid>
          </Grid>
        </div>
      </Container>
      <div className="sepration-line"></div>
      <Container maxWidth="xl">
        <div className="project-working-section">
          <h2 className="large-title">Why Ikigai?</h2>
          <Grid container spacing={4}>
            <Grid item xs={12} md={7} className="content-left-section">
              <div className="sub-content">
                <div className="sub-body-large">
                  At Project Leo, we believe that every student has a unique
                  purpose and passion. Our approach is centered around the
                  ikigai framework, which helps students discover their
                  strengths, interests, and values. By leveraging this powerful
                  tool, we empower students to:
                </div>
              </div>
              <ul className="sub-content">
                <li className="sub-body-large">
                  Explore and identify their passions and goals
                </li>
                <li className="sub-body-large">
                  Develop a deeper understanding of their interests and
                  motivations
                </li>
                <li className="sub-body-large">
                  Pursue projects that align with their ikigai and career
                  aspirations
                </li>
              </ul>
              <div className="sub-content">
                <div className="sub-body-large">
                  By adopting the ikigai approach, Project Leo provides a
                  personalized, reflective, and engaging educational experience
                  that aligns student passions with skill development and career
                  preparation.
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={5} className="image-right-section">
              <img
                className="ikigai-image image-alignment"
                src="/images/landing/projects/ikigai1.png"
              />
            </Grid>
          </Grid>
        </div>
      </Container>
    </LandingPageLayout>
  );
}
