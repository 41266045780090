import './TeacherDashboard.scss';
import {DefaultPage} from '../../../libs/DefaultPage/DefaultPage';
import {TabbedPanel} from '../../../libs/TabbedPanel/TabbedPanel';
import {PostsTab} from './PostsTab';
import {useEffect} from 'react';
import {useFormFields} from '../../../libs/form_utils/forms';
import {defaultEducationFilters} from '../../../libs/EducationFilter/EducationFilters';
import {AssignmentsTab} from './AssignmentsTab';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/store';
import {HomeTab} from './HomeTab';

export enum TeacherTab {
  HOME,
  ASSIGNMENTS,
  POSTS,
}

export function TeacherDashboard() {
  const userX = useSelector((state: RootState) => state.auth.user);

  const filterForm = useFormFields();
  const educationFilters = defaultEducationFilters(userX, filterForm);

  useEffect(() => {
    if (!userX) {
      filterForm.setValuesObject({});
      return;
    }
  }, [userX]);

  return (
    <>
      <DefaultPage title={(userX?.isAdminX ? 'Teacher ' : '') + 'Dashboard'}>
        <div style={{height: '100%'}}>
          <TabbedPanel
            tabKeyEnum={TeacherTab}
            defaultTabKey={TeacherTab.HOME}
            tabs={[
              {
                key: TeacherTab.HOME,
                label: 'Home',
                content: <HomeTab />,
              },
              {
                key: TeacherTab.POSTS,
                label: 'Posts',
                content: <PostsTab educationFilters={educationFilters} />,
              },
              {
                key: TeacherTab.ASSIGNMENTS,
                label: 'Assignments',
                content: <AssignmentsTab educationFilters={educationFilters} />,
              },
            ]}
          />
        </div>
      </DefaultPage>
    </>
  );
}
