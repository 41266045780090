import './ProjectsDashboard.scss';
import {useEffect, useState} from 'react';
import {DefaultPage} from '../../libs/DefaultPage/DefaultPage';
import {TabbedPanel} from '../../libs/TabbedPanel/TabbedPanel';
import {ProjectBuilderTab} from './ProjectBuilderTab';
import {AllProjectsTab} from './AllProjectsTab';
import {MyProjectsTab} from './MyProjectsTab';
import {createService} from '../../libs/protos';
import {
  PROJECT_DEFINITION_SORTER,
  REVERSE_DATE_THEN_PROJECT_SORTER,
} from '../../libs/sorters';
import {
  DeepReadOnly,
  replaceInDeepReadOnlyArray,
  writableForProto,
} from '../../libs/misc';
import {pl_types, project_service} from '../../generated/protobuf-js';
import {ProjectDetailsCardModal} from '../../libs/ProjectCard/ProjectDetailsCardModal';
import ProjectService = project_service.ProjectService;
import IProject = pl_types.IProject;
import IProjectDefinition = pl_types.IProjectDefinition;
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import {useNotification} from '../../shared/hooks/Notification';

export enum ProjectsTab {
  // OVERVIEW,
  MY_PROJECTS,
  PROJECT_BUILDER,
  ALL_PROJECTS,
}

export function ProjectsDashboard() {
  const userX = useSelector((state: RootState) => state.auth.user);

  const [projects, setProjects] = useState<DeepReadOnly<IProject[]>>([]);
  const [projectInputs, setProjectInputs] = useState<
    DeepReadOnly<IProjectDefinition[]>
  >([]);

  const [loadingProjects, setLoadingProjects] = useState(true);
  const [loadingProjectInputs, setLoadingProjectInputs] = useState(true);

  const [projectDetailsId, setProjectDetailsId] = useState<
    number | null | undefined
  >();
  const notification = useNotification();

  useEffect(() => {
    if (userX == null) {
      setProjects([]);
      setProjectInputs([]);
      setLoadingProjects(true);
      setLoadingProjectInputs(true);
      return;
    }

    createService(ProjectService, 'ProjectService')
      .getProjects({
        userXIds: [userX.id ?? 0],
        includeInactive: true,
      })
      .then(response => {
        setProjects(response.projects.sort(REVERSE_DATE_THEN_PROJECT_SORTER));
      })
      .catch(notification.handleError('Failed to get projects'))
      .finally(() => setLoadingProjects(false));

    createService(ProjectService, 'ProjectService')
      .getProjectInputs({
        userXIds: [userX.id ?? 0],
        includeProcessing: true,
      })
      .then(response => {
        setProjectInputs(
          (response.projects ?? []).sort(PROJECT_DEFINITION_SORTER)
        );
      })
      .catch(notification.handleError('Failed to get project inputs'))
      .finally(() => setLoadingProjectInputs(false));
  }, [userX]);

  function updateProject(project: DeepReadOnly<IProject>) {
    setProjects(replaceInDeepReadOnlyArray(projects, p => p.id, project));
    createService(ProjectService, 'ProjectService')
      .updateProject({
        project: writableForProto(project),
        includeAssignments: true,
        includeMilestones: true,
      })
      .catch(notification.handleError('Failed to update project'));
  }

  return (
    <>
      <DefaultPage title="Projects">
        <div style={{height: '100%'}}>
          <TabbedPanel
            tabKeyEnum={ProjectsTab}
            defaultTabKey={ProjectsTab.MY_PROJECTS}
            tabs={[
              {
                key: ProjectsTab.MY_PROJECTS,
                label: 'My Projects',
                content: (
                  <MyProjectsTab
                    projects={projects}
                    updateProject={updateProject}
                    loading={loadingProjects || loadingProjectInputs}
                    onShowProjectDetails={project =>
                      setProjectDetailsId(project.id)
                    }
                  />
                ),
              },
              {
                key: ProjectsTab.PROJECT_BUILDER,
                label: 'Builder',
                content: <ProjectBuilderTab />,
              },
              {
                key: ProjectsTab.ALL_PROJECTS,
                label: 'All Projects',
                content: (
                  <AllProjectsTab
                    projects={projects}
                    projectInputs={projectInputs}
                    updateProject={updateProject}
                    loading={loadingProjects || loadingProjectInputs}
                    onShowProjectDetails={project =>
                      setProjectDetailsId(project.id)
                    }
                  />
                ),
              },
            ]}
          />
        </div>
        <ProjectDetailsCardModal
          projectId={projectDetailsId}
          onClose={() => setProjectDetailsId(undefined)}
          updateProject={updateProject}
          showActive={true}
          showThumbsState={true}
        />
      </DefaultPage>
    </>
  );
}
