import {Container, Grid, Typography} from '@mui/material';
import {LandingPageLayout} from '../../components/Layouts/LandingPageLayout';
import './About.scss';

export function About() {
  return (
    <LandingPageLayout>
      <Container maxWidth="xl" className="container">
        <Grid container spacing={4} className="main-card">
          <Grid item xs={12} md={6} className="card-header">
            <Typography className="xlarge-title">
              How can AI scale conversations between humans?
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className="card-image">
            <img className="image-alignment" src="/images/about/home.png" />
          </Grid>
        </Grid>
      </Container>
      <div className="sepration-line"></div>
      <Container maxWidth="xl">
        <div className="mission-section">
          <Typography className="base-title">
            Our mission is to leverage AI to boost student engagement, reduce
            teacher burnout, and create classrooms where collaboration and
            communication thrive.
          </Typography>
          <div>
            <Typography className="base-title">
              Project Leo's goals are:
            </Typography>
            <ul>
              <li className="base-title">
                To build the next-generation classroom software that enhances
                student engagement through AI-generated customized projects
                tailored to a student’s interests and abilities, while aligning
                with the teacher's stated learning objectives.
              </li>
              <li className="base-title">
                To increase teacher retention by alleviating creative and
                administrative burdens, thus allowing teachers to focus on
                mentoring and inspiring students.
              </li>
              <li className="base-title">
                To streamline the recruitment and hiring process for
                administrators with the data and evidence that accurately
                reflects the value of each candidate.
              </li>
            </ul>
          </div>
        </div>
      </Container>
      <div className="sepration-line"></div>
      <div className="team-section">
        <div className="profiles-section">
          <Typography className="user-name">Our Team</Typography>
          <div className="profile">
            <Grid container spacing={4}>
              <Grid item sm={12} md={4} className="profile-image">
                <img src="/images/about/image1.png"></img>
              </Grid>
              <Grid item sm={12} md={8} className="profile-content">
                <Typography className="user-name">Steven Eno</Typography>
                <Typography className="user-title">
                  Co-founder & Chief Product Officer
                </Typography>
                <Typography className="user-info">
                  Steven Eno has been an educator for 12 years. He launched the
                  engineering program at El Segundo High School and was the
                  Teacher of the Year in 2014-2015. After moving to Maryland, he
                  launched the SEED (Social Entrepreneurship, Engineering, and
                  Design) Program at the McDonogh School. Steven then joined
                  multiple education startups including Spike Lab, Synthesis,
                  and 2Sigma School. Steven joined Da Vinci Schools as the
                  mechanical engineering pathway coordinator and led the effort
                  to create Project Leo. Beginning in the fall of 2024, Steven
                  will teach calculus and lead the further development of
                  Project Leo at Pacifica Christian High School.
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className="profile">
            <Grid container spacing={4}>
              <Grid item sm={12} md={8} className="profile-content">
                <div className="user-name">Scott Hendrickson</div>
                <div className="user-title">
                  Co-founder & Chief Technology Officer
                </div>
                <div className="user-info">
                  Scott is an experienced EdTech Software Engineer, having
                  previously worked at Google. Currently, he leads the
                  development of Project Leo, a dynamic project-based learning
                  platform at Da Vinci Schools in El Segundo, CA. With a Ph.D.
                  from UC Irvine, Scott values education, personal growth, and
                  helping others achieve their personal goals.
                </div>
              </Grid>
              <Grid item sm={12} md={4} className="profile-image">
                <img
                  src="/images/about/image2.png"
                  className="object-fit"
                ></img>
              </Grid>
            </Grid>
          </div>
          <div className="profile">
            <Grid container spacing={4}>
              <Grid item sm={12} md={4} className="profile-image">
                <img src="/images/about/image3.jpg"></img>
              </Grid>
              <Grid item sm={12} md={8} className="profile-content">
                <div className="user-name">Matt Plunkett</div>
                <div className="user-title">Chief Executive Officer</div>
                <div className="user-info">
                  Matt Plunkett began his career teaching at the East Palo Alto
                  Charter School, before working in various teaching and
                  administrative roles in schools across New York City and Los
                  Angeles. Matt’s path then took him to Synthesis, an online
                  collaborative, problem-solving program, for a three-year stint
                  where he served in a number of capacities, including as the
                  Director of Faculty and Students. While at Synthesis he helped
                  scale the program from 20 to more than 2000 students. Matt
                  views the global community of nearly 500 teachers he hired and
                  managed as among the notable successes of his tenure. (That,
                  and finally getting comfortable using Notion.) In January
                  2024, Matt joined Project Leo as CEO.
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </LandingPageLayout>
  );
}
