import './Post.scss';

import {
  pl_types,
  post_service,
  project_service,
} from '../../generated/protobuf-js';
import {
  AddCommentTwoTone,
  AssessmentTwoTone,
  CommentTwoTone,
  North,
  South,
} from '@mui/icons-material';
import {CSSProperties, useEffect, useState} from 'react';
import {
  deepClone,
  DeepReadOnly,
  formatAsTag,
  isTextEmpty,
  objPropertyDeepMerge,
  removeFromDeepReadOnlyArray,
  replaceInDeepReadOnlyArray,
  textOrEmpty,
} from '../misc';
import {PostHeader} from './PostHeader';
import {
  PROJECT_POST_COMMENT_SORTER,
  TAG_SORTER,
  USER_X_SORTER,
} from '../sorters';
import {
  Card,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from '@mui/material';
import {linearProgressClasses} from '@mui/material/LinearProgress';
import {createService} from '../protos';
import {styled} from '@mui/material/styles';
import {HtmlEditor} from '../HtmlEditor/HtmlEditor';
import {UserXAvatar} from '../UserXAvatar/UserXAvatar';
import {ProjectDetailsCardModal} from '../ProjectCard/ProjectDetailsCardModal';
import {PostCommentHeader} from './PostCommentHeader';
import IProjectPostRating = pl_types.IProjectPostRating;
import IProjectPost = pl_types.IProjectPost;
import IUserX = pl_types.IUserX;
import IProjectPostComment = pl_types.IProjectPostComment;
import ITag = pl_types.ITag;
import PostService = post_service.PostService;
import IProjectPostRatingCategory = pl_types.IProjectPostRatingCategory;
import ProjectPostRating = pl_types.ProjectPostRating;
import IProject = pl_types.IProject;
import ProjectService = project_service.ProjectService;
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import {useNotification} from '../../shared/hooks/Notification';

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

interface RatingColumn {
  userX: IUserX;
  ratingType: ProjectPostRating.RatingType;
}

interface RatingKey {
  ratingColumn: RatingColumn;
  projectInputFulfillmentId: number;
}

function toRatingColumn(
  rating: DeepReadOnly<IProjectPostRating>
): RatingColumn {
  return {
    userX: rating.userX ?? {},
    ratingType:
      rating.ratingType ?? ProjectPostRating.RatingType.UNSET_RATING_TYPE,
  };
}

function ratingToRatingKey(
  rating: DeepReadOnly<IProjectPostRating>
): RatingKey {
  return {
    ratingColumn: toRatingColumn(rating),
    projectInputFulfillmentId: rating.projectInputFulfillmentId ?? 0,
  };
}

function cellToRatingKey(
  column: RatingColumn,
  category: IProjectPostRatingCategory
): RatingKey {
  return {
    ratingColumn: column,
    projectInputFulfillmentId: category.projectInputFulfillmentId ?? 0,
  };
}

const RATING_COLUMN_SORTER = (a: RatingColumn, b: RatingColumn) =>
  USER_X_SORTER(a.userX, b.userX) ||
  (a.ratingType ?? ProjectPostRating.RatingType.UNSET_RATING_TYPE) -
    (b.ratingType ?? ProjectPostRating.RatingType.UNSET_RATING_TYPE);

const RATING_CATEGORY_SORTER = (
  a: pl_types.IProjectPostRatingCategory,
  b: pl_types.IProjectPostRatingCategory
) =>
  (a.category ?? '').localeCompare(b.category ?? '') ||
  (a.value ?? '').localeCompare(b.value ?? '');

export function Post(
  props: DeepReadOnly<{
    post: IProjectPost;
    postUpdated: (post: DeepReadOnly<IProjectPost>) => void;
    hideComments?: boolean | null | undefined;
    hideRatings?: boolean | null | undefined;
    hidePortfolioIcon?: boolean | null | undefined;
    getUserXHighlightStyle?: (
      userX?: DeepReadOnly<IUserX> | null | undefined
    ) => CSSProperties | undefined;
  }>
) {
  const userX = useSelector((state: RootState) => state.auth.user);
  const hasHighlightedComment = props.post.comments
    ?.map(c => props.getUserXHighlightStyle?.(c.userX) != null)
    .includes(true);

  const [sortedRatingColumns, setSortedRatingColumns] = useState<
    DeepReadOnly<RatingColumn[]>
  >([]);
  const [sortedRatingCategories, setSortedRatingCategories] = useState<
    DeepReadOnly<IProjectPostRatingCategory[]>
  >([]);
  const [sortedTags, setSortedTags] = useState<DeepReadOnly<ITag[]>>([]);
  const [sortedComments, setSortedComments] = useState<
    DeepReadOnly<IProjectPostComment>[]
  >([]);

  const [ratings, setRatings] = useState<
    DeepReadOnly<
      Map</* JSON.stringify(RatingKey)= */ string, IProjectPostRating>
    >
  >(new Map());

  const [commentBeingEdited, setCommentBeingEdited] = useState<
    DeepReadOnly<IProjectPostComment> | undefined
  >(undefined);
  const [newCommentContent, setNewCommentContent] = useState('');

  const [expandComments, setExpandComments] = useState<boolean>(true);
  const [expandRatings, setExpandRatings] = useState<boolean>(false);

  const [detailsProjectId, setDetailsProjectId] = useState<
    number | undefined
  >();
  const [detailsProject, setDetailsProject] =
    useState<DeepReadOnly<IProject>>();
  const notification = useNotification();

  useEffect(() => {
    setSortedRatingColumns(
      [
        ...new Set(
          (props.post.ratings ?? []).map(rating =>
            JSON.stringify(toRatingColumn(rating))
          )
        ),
      ]
        .map(jsonRatingColumn => JSON.parse(jsonRatingColumn))
        .sort(RATING_COLUMN_SORTER)
    );
    setSortedRatingCategories(
      (props.post?.ratingCategories ?? []).slice().sort(RATING_CATEGORY_SORTER)
    );
    setRatings(
      new Map(
        (props.post.ratings ?? []).map(rating => [
          JSON.stringify(ratingToRatingKey(rating)),
          rating,
        ])
      )
    );

    setSortedTags((props.post?.tags ?? []).slice().sort(TAG_SORTER));

    setSortedComments(
      (props.post?.comments ?? []).slice().sort(PROJECT_POST_COMMENT_SORTER)
    );
  }, [props.post]);

  useEffect(() => {
    if (detailsProjectId == null) {
      setDetailsProject(undefined);
    } else {
      createService(ProjectService, 'ProjectService')
        .getProjects({
          projectIds: [detailsProjectId],
          includeInactive: true,
          includeInputs: true,
          includeAssignment: true,
          includeMilestones: true,
        })
        .then(response => setDetailsProject(response.projects[0]));
    }
  }, [detailsProjectId]);

  function saveCommentBeingEdited(): Promise<IProjectPostComment | undefined> {
    if (commentBeingEdited == null) {
      return Promise.resolve(undefined);
    }

    const newComment = deepClone(
      commentBeingEdited,
      c => (c.longDescrHtml = newCommentContent)
    );

    return createService(PostService, 'PostService')
      .upsertProjectPostComment({
        projectPostComment: newComment,
      })
      .then(() => {
        const newSortedComments = replaceInDeepReadOnlyArray(
          sortedComments,
          c => c.id,
          newComment
        );

        setSortedComments(newSortedComments);
        setCommentBeingEdited(undefined);
        props.postUpdated(
          deepClone(
            props.post,
            p => (p.comments = deepClone(newSortedComments))
          )
        );

        return newComment;
      });
  }

  function addComment() {
    setExpandComments(true);
    saveCommentBeingEdited()
      .then(() =>
        createService(PostService, 'PostService').upsertProjectPostComment({
          projectPostComment: {
            longDescrHtml: '',
            projectPost: {id: props.post.id},
          },
        })
      )
      .then(response => {
        const newSortedComments = [
          response.projectPostComment!,
          ...sortedComments,
        ];

        setSortedComments(newSortedComments);
        setCommentBeingEdited(newSortedComments[0]);
        setNewCommentContent('');
        props.postUpdated(
          deepClone(
            props.post,
            p => (p.comments = deepClone(newSortedComments))
          )
        );
      })
      .catch(notification.handleError('Failed to add comment'));
  }

  function deleteComment(comment: DeepReadOnly<IProjectPostComment>) {
    saveCommentBeingEdited();

    const newSortedComments = removeFromDeepReadOnlyArray(
      sortedComments,
      c => c.id,
      comment.id
    );
    setSortedComments(newSortedComments);

    props.postUpdated(
      deepClone(props.post, p => (p.comments = deepClone(newSortedComments)))
    );

    createService(PostService, 'PostService')
      .deleteProjectPostComment({
        projectPostCommentId: comment.id ?? 0,
      })
      .catch(notification.handleError('Failed to delete a post comment'));
  }

  function onPortfolioClick() {
    props.postUpdated(
      deepClone(
        props.post,
        p => (p.includeInPortfolio = !props.post.includeInPortfolio)
      )
    );
  }

  return (
    <>
      <div className="post">
        <div className="global-flex-row">
          <UserXAvatar userX={props.post?.userX} size="3rem" />
          <div
            className="global-flex-column"
            style={{
              flexGrow: 1,
              gap: 0,
              ...props.getUserXHighlightStyle?.(props.post?.userX),
            }}
          >
            <PostHeader
              post={props.post}
              portfolioIconClicked={
                props.hidePortfolioIcon ? undefined : onPortfolioClick
              }
            />
            <div className="post-title">
              {textOrEmpty(props.post?.name, 'Untitled Post')}
            </div>
            <div
              className="post-project-name"
              style={{cursor: 'pointer'}}
              onClick={() =>
                setDetailsProjectId(props.post?.project?.id ?? undefined)
              }
            >
              Project:&nbsp;
              {textOrEmpty(props.post?.project?.name, 'Untitled Project')}
            </div>
          </div>
        </div>
        <div className="post-content">
          {!props.hidePortfolioIcon && props.post.includeInPortfolio && (
            <>
              <Card
                elevation={5}
                style={{
                  padding: '1rem',
                  backgroundColor: '#f0f0ff',
                  width: '50%',
                  float: 'right',
                }}
              >
                <div
                  style={{
                    textAlign: 'center',
                    paddingBottom: '1rem',
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    fontStyle: 'italic',
                  }}
                >
                  Reflection
                </div>
                <HtmlEditor
                  value={props.post.portfolioNoteHtml}
                  onChange={value =>
                    props.postUpdated(
                      objPropertyDeepMerge(props.post, {
                        portfolioNoteHtml: value,
                      })
                    )
                  }
                />
              </Card>
            </>
          )}
          <HtmlEditor
            id={props.post.id}
            value={props.post.longDescrHtml}
            placeholder="No Post Content"
            readOnly={true}
          />
        </div>
        <div
          className="post-tags"
          style={{
            display: (sortedTags.length ?? 0) > 0 ? undefined : 'none',
          }}
        >
          {sortedTags.map(tag => (
            <span key={tag.text}>{formatAsTag(tag.text)}&nbsp;&nbsp;</span>
          ))}
        </div>
        <div
          style={{
            display: isTextEmpty(props.post?.desiredFeedback)
              ? 'none'
              : undefined,
          }}
        >
          <span className="post-feedback-label">
            Feedback I'm looking for:&nbsp;
          </span>
          {textOrEmpty(props.post?.desiredFeedback, 'No Feedback Desired')}
        </div>
        {(!props.hideComments || !props.hideRatings) && (
          <div className="post-footer">
            {!props.hideComments && (
              <>
                <div
                  className="post-footer-group"
                  onClick={() => setExpandComments(!expandComments)}
                  style={{cursor: 'pointer'}}
                >
                  {expandComments ? <South /> : <North />}
                  <CommentTwoTone className="global-two-tone-chat-color" />
                  <span>{sortedComments.length ?? 0}</span>
                </div>
                <div
                  className="post-footer-group"
                  onClick={addComment}
                  style={{cursor: 'pointer'}}
                >
                  <AddCommentTwoTone className="global-two-tone-chat-color" />
                </div>
              </>
            )}
            {!props.hideRatings && (
              <>
                <div
                  className="post-footer-group"
                  onClick={() => {
                    setExpandRatings(!expandRatings);
                  }}
                  style={{
                    cursor: 'pointer',
                    display:
                      userX?.isAdminX || userX?.isTeacher ? undefined : 'none',
                  }}
                >
                  {expandRatings ? <South /> : <North />}
                  <AssessmentTwoTone className="global-two-tone-rating-color" />
                  <span>{props.post?.ratings?.length ?? 0}</span>
                </div>
              </>
            )}
          </div>
        )}
        {!props.hideRatings && expandRatings && (
          <div className="post-ratings">
            <table width="fit-content">
              <thead>
                <tr>
                  <th key={-2}></th>
                  <th key={-1}></th>
                  <th align="center">
                    {sortedRatingColumns.map(column => (
                      <UserXAvatar userX={column.userX} size="3rem" />
                    ))}
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedRatingCategories.map(ratingCategory => (
                  <tr key={ratingCategory.projectInputFulfillmentId ?? 0}>
                    <th
                      key={-2}
                      scope="row"
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      {ratingCategory.category}
                    </th>
                    <th
                      key={-1}
                      scope="row"
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      {ratingCategory.value}
                    </th>
                    {sortedRatingColumns.map(ratingColumn => (
                      <td key={ratingColumn.userX?.id ?? 0}>
                        <FormControl fullWidth>
                          {ratingColumn.ratingType ===
                            ProjectPostRating.RatingType.INITIAL_1_TO_5 && (
                            <>
                              <InputLabel
                                id="demo-simple-select-label"
                                size="small"
                              >
                                Rating
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                disabled={
                                  (ratingColumn.userX?.id ?? 0) !==
                                  (userX?.id ?? 0)
                                }
                                value={
                                  ratings.get(
                                    JSON.stringify(
                                      cellToRatingKey(
                                        ratingColumn,
                                        ratingCategory
                                      )
                                    )
                                  )?.rating ?? 0
                                }
                                label="Rating"
                                onChange={e => {
                                  const ratingKey = cellToRatingKey(
                                    ratingColumn,
                                    ratingCategory
                                  );
                                  const value =
                                    e.target.value === ''
                                      ? 0
                                      : parseInt(String(e.target.value));
                                  const newRating = deepClone(
                                    ratings.get(JSON.stringify(ratingKey)) ??
                                      {},
                                    r => {
                                      r.userX = ratingColumn.userX;
                                      r.rating = value;
                                      r.ratingType = ratingColumn.ratingType;
                                      r.projectInputFulfillmentId =
                                        ratingCategory.projectInputFulfillmentId;
                                    }
                                  );
                                  createService(PostService, 'PostService')
                                    .upsertProjectPostRating({
                                      projectPostRating: newRating,
                                    })
                                    .then(response => {
                                      newRating.id = response.id ?? 0;
                                      const newRatings = new Map(ratings).set(
                                        JSON.stringify(ratingKey),
                                        newRating
                                      );
                                      setRatings(newRatings);
                                      props.postUpdated(
                                        deepClone(
                                          props.post,
                                          p =>
                                            (p.ratings = deepClone(
                                              Array.from(newRatings.values())
                                            ))
                                        )
                                      );
                                    })
                                    .catch(
                                      notification.handleError(
                                        'Failed to update post rating'
                                      )
                                    );
                                }}
                              >
                                <MenuItem value={1}>1 - No Evidence</MenuItem>
                                <MenuItem value={2}>2 - Attempted</MenuItem>
                                <MenuItem value={3}>3 - Emerging</MenuItem>
                                <MenuItem value={4}>4 - Proficient</MenuItem>
                                <MenuItem value={5}>5 - Advanced</MenuItem>
                              </Select>
                            </>
                          )}
                          {ratingColumn.ratingType ===
                            ProjectPostRating.RatingType.GOAL_COMPLETE_PCT && (
                            <BorderLinearProgress
                              variant="determinate"
                              value={
                                ratings.get(
                                  JSON.stringify(
                                    cellToRatingKey(
                                      ratingColumn,
                                      ratingCategory
                                    )
                                  )
                                )?.rating ?? 0
                              }
                              style={{height: '1em', verticalAlign: 'bottom'}}
                            />
                          )}{' '}
                        </FormControl>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {!props.hideComments && (expandComments || hasHighlightedComment) && (
          <div className="post-comments">
            {sortedComments.length === 0 && (
              <span className="post-empty-post">No Comments</span>
            )}
            {sortedComments.map(comment => {
              const readOnly =
                comment.userX?.id !== userX?.id &&
                !userX?.isTeacher &&
                !userX?.isAdminX;
              return (
                <div key={comment.id ?? 0} className="post-comment">
                  <UserXAvatar userX={comment?.userX} />
                  <div className="global-flex-column" style={{gap: 0}}>
                    <div style={props?.getUserXHighlightStyle?.(comment.userX)}>
                      <PostCommentHeader
                        comment={comment}
                        deleteIconClicked={
                          comment.userX?.id === userX?.id
                            ? () => deleteComment(comment)
                            : undefined
                        }
                        readOnly={readOnly}
                      />
                    </div>
                    <HtmlEditor
                      id={comment.id}
                      value={
                        comment.id === commentBeingEdited?.id
                          ? newCommentContent
                          : comment.longDescrHtml
                      }
                      readOnly={readOnly}
                      placeholder="No comment. Click to edit."
                      editingPlaceholder="Type your comment here..."
                      editingStarted={() => {
                        if (comment.id !== commentBeingEdited?.id) {
                          saveCommentBeingEdited()
                            .then(() => {
                              setCommentBeingEdited(comment);
                              setNewCommentContent(comment.longDescrHtml ?? '');
                            })
                            .catch(
                              notification.handleError('Failed to edit comment')
                            );
                        }
                      }}
                      editingFinished={() => {
                        saveCommentBeingEdited().catch(
                          notification.handleError('Failed to edit comment')
                        );
                      }}
                      onChange={value => {
                        if (comment.id === commentBeingEdited?.id) {
                          setNewCommentContent(value);
                        }
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {detailsProject && (
        <ProjectDetailsCardModal
          project={detailsProject}
          onClose={() => setDetailsProjectId(undefined)}
        />
      )}
    </>
  );
}
