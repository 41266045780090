import './PrivacyPolicy.scss';
import {DefaultPage} from '../../libs/DefaultPage/DefaultPage';

export function PrivacyPolicy() {
  return (
    <>
      <DefaultPage title="Privacy Policy">TBD</DefaultPage>
    </>
  );
}
