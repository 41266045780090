import {PropsWithChildren} from 'react';
import type {UniqueIdentifier} from '@dnd-kit/core';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {Tooltip} from '@mui/material';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {DragIcon} from '../../common';
import {StepWithSortIndex} from './ProjectDetailCardMilestone';
interface Props {
  step: StepWithSortIndex;
  stepId: UniqueIdentifier;
  removeStep: (stepId: number, milestoneIndex: number) => void;
}

export function SortableItem({
  children,
  step,
  stepId,
  removeStep,
}: PropsWithChildren<Props>) {
  const {attributes, listeners, setNodeRef, transform, transition, isDragging} =
    useSortable({
      id: stepId,
      data: {
        type: 'Step',
        step,
        stepId,
      },
    });
  const style = {
    transform: CSS.Translate.toString(transform),
    zIndex: isDragging ? 100 : 'auto',
    transition,
  };

  return (
    <div ref={setNodeRef} style={{...style, position: 'relative'}}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
        className="milestone_step_item step_item sortable-item"
      >
        {children}
        <div className="delete-step">
          <Tooltip title="Remove step">
            <DeleteOutlineIcon
              onClick={() =>
                removeStep(
                  step.id ?? 0,
                  JSON.parse(step.milestoneId).milestoneIndex
                )
              }
              color="error"
            />
          </Tooltip>
        </div>
        <div
          className="milestone_step_dragicon"
          style={{
            cursor: 'grab',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          {...attributes}
          {...listeners}
        >
          <DragIcon />
        </div>
      </div>
    </div>
  );
}
