import './index.scss';
import {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import MyThemeProvider from './theme/ThemeProvider';
import {MetricType} from 'web-vitals';
import {LicenseInfo} from '@mui/x-license';
import {Provider} from 'react-redux';
import {store} from './store/store';
import {router} from './routes';
import {ToastProvider} from './toast/ToastProvider';
import {AuthWatcher} from './components/AuthWatcher';

function sendToGoogleAnalytics({name, delta, value, id}: MetricType) {
  if ('gtag' in window && typeof window.gtag === 'function') {
    window.gtag('event', name, {
      // Built-in params:
      value: delta, // Use `delta` so the value can be summed.
      // Custom params:
      metric_id: id, // Needed to aggregate events.
      metric_value: value, // Optional.
      metric_delta: delta, // Optional.
    });
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

LicenseInfo.setLicenseKey(
  'a1d3fae48d0d8fe4dc2fe62cf6db220dTz04MzI2MSxFPTE3MzgyOTA3NzgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

root.render(
  <StrictMode>
    <Provider store={store}>
      <ToastProvider>
        <MyThemeProvider>
          <AuthWatcher />
          <RouterProvider router={router} />
        </MyThemeProvider>
      </ToastProvider>
    </Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToGoogleAnalytics);
