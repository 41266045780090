/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.assignment_service = (function() {

    /**
     * Namespace assignment_service.
     * @exports assignment_service
     * @namespace
     */
    var assignment_service = {};

    assignment_service.AssignmentService = (function() {

        /**
         * Constructs a new AssignmentService service.
         * @memberof assignment_service
         * @classdesc Represents an AssignmentService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function AssignmentService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (AssignmentService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AssignmentService;

        /**
         * Creates new AssignmentService service using the specified rpc implementation.
         * @function create
         * @memberof assignment_service.AssignmentService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {AssignmentService} RPC service. Useful where requests and/or responses are streamed.
         */
        AssignmentService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link assignment_service.AssignmentService#getAssignments}.
         * @memberof assignment_service.AssignmentService
         * @typedef GetAssignmentsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {assignment_service.GetAssignmentsResponse} [response] GetAssignmentsResponse
         */

        /**
         * Calls GetAssignments.
         * @function getAssignments
         * @memberof assignment_service.AssignmentService
         * @instance
         * @param {assignment_service.IGetAssignmentsRequest} request GetAssignmentsRequest message or plain object
         * @param {assignment_service.AssignmentService.GetAssignmentsCallback} callback Node-style callback called with the error, if any, and GetAssignmentsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AssignmentService.prototype.getAssignments = function getAssignments(request, callback) {
            return this.rpcCall(getAssignments, $root.assignment_service.GetAssignmentsRequest, $root.assignment_service.GetAssignmentsResponse, request, callback);
        }, "name", { value: "GetAssignments" });

        /**
         * Calls GetAssignments.
         * @function getAssignments
         * @memberof assignment_service.AssignmentService
         * @instance
         * @param {assignment_service.IGetAssignmentsRequest} request GetAssignmentsRequest message or plain object
         * @returns {Promise<assignment_service.GetAssignmentsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link assignment_service.AssignmentService#createAssignment}.
         * @memberof assignment_service.AssignmentService
         * @typedef CreateAssignmentCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {assignment_service.CreateAssignmentResponse} [response] CreateAssignmentResponse
         */

        /**
         * Calls CreateAssignment.
         * @function createAssignment
         * @memberof assignment_service.AssignmentService
         * @instance
         * @param {assignment_service.ICreateAssignmentRequest} request CreateAssignmentRequest message or plain object
         * @param {assignment_service.AssignmentService.CreateAssignmentCallback} callback Node-style callback called with the error, if any, and CreateAssignmentResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AssignmentService.prototype.createAssignment = function createAssignment(request, callback) {
            return this.rpcCall(createAssignment, $root.assignment_service.CreateAssignmentRequest, $root.assignment_service.CreateAssignmentResponse, request, callback);
        }, "name", { value: "CreateAssignment" });

        /**
         * Calls CreateAssignment.
         * @function createAssignment
         * @memberof assignment_service.AssignmentService
         * @instance
         * @param {assignment_service.ICreateAssignmentRequest} request CreateAssignmentRequest message or plain object
         * @returns {Promise<assignment_service.CreateAssignmentResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link assignment_service.AssignmentService#saveAssignment}.
         * @memberof assignment_service.AssignmentService
         * @typedef SaveAssignmentCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {assignment_service.SaveAssignmentResponse} [response] SaveAssignmentResponse
         */

        /**
         * Calls SaveAssignment.
         * @function saveAssignment
         * @memberof assignment_service.AssignmentService
         * @instance
         * @param {assignment_service.ISaveAssignmentRequest} request SaveAssignmentRequest message or plain object
         * @param {assignment_service.AssignmentService.SaveAssignmentCallback} callback Node-style callback called with the error, if any, and SaveAssignmentResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AssignmentService.prototype.saveAssignment = function saveAssignment(request, callback) {
            return this.rpcCall(saveAssignment, $root.assignment_service.SaveAssignmentRequest, $root.assignment_service.SaveAssignmentResponse, request, callback);
        }, "name", { value: "SaveAssignment" });

        /**
         * Calls SaveAssignment.
         * @function saveAssignment
         * @memberof assignment_service.AssignmentService
         * @instance
         * @param {assignment_service.ISaveAssignmentRequest} request SaveAssignmentRequest message or plain object
         * @returns {Promise<assignment_service.SaveAssignmentResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link assignment_service.AssignmentService#deleteAssignment}.
         * @memberof assignment_service.AssignmentService
         * @typedef DeleteAssignmentCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {assignment_service.DeleteAssignmentResponse} [response] DeleteAssignmentResponse
         */

        /**
         * Calls DeleteAssignment.
         * @function deleteAssignment
         * @memberof assignment_service.AssignmentService
         * @instance
         * @param {assignment_service.IDeleteAssignmentRequest} request DeleteAssignmentRequest message or plain object
         * @param {assignment_service.AssignmentService.DeleteAssignmentCallback} callback Node-style callback called with the error, if any, and DeleteAssignmentResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AssignmentService.prototype.deleteAssignment = function deleteAssignment(request, callback) {
            return this.rpcCall(deleteAssignment, $root.assignment_service.DeleteAssignmentRequest, $root.assignment_service.DeleteAssignmentResponse, request, callback);
        }, "name", { value: "DeleteAssignment" });

        /**
         * Calls DeleteAssignment.
         * @function deleteAssignment
         * @memberof assignment_service.AssignmentService
         * @instance
         * @param {assignment_service.IDeleteAssignmentRequest} request DeleteAssignmentRequest message or plain object
         * @returns {Promise<assignment_service.DeleteAssignmentResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link assignment_service.AssignmentService#getClassXAssignmentStatus}.
         * @memberof assignment_service.AssignmentService
         * @typedef GetClassXAssignmentStatusCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {assignment_service.GetClassXAssignmentStatusResponse} [response] GetClassXAssignmentStatusResponse
         */

        /**
         * Calls GetClassXAssignmentStatus.
         * @function getClassXAssignmentStatus
         * @memberof assignment_service.AssignmentService
         * @instance
         * @param {assignment_service.IGetClassXAssignmentStatusRequest} request GetClassXAssignmentStatusRequest message or plain object
         * @param {assignment_service.AssignmentService.GetClassXAssignmentStatusCallback} callback Node-style callback called with the error, if any, and GetClassXAssignmentStatusResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AssignmentService.prototype.getClassXAssignmentStatus = function getClassXAssignmentStatus(request, callback) {
            return this.rpcCall(getClassXAssignmentStatus, $root.assignment_service.GetClassXAssignmentStatusRequest, $root.assignment_service.GetClassXAssignmentStatusResponse, request, callback);
        }, "name", { value: "GetClassXAssignmentStatus" });

        /**
         * Calls GetClassXAssignmentStatus.
         * @function getClassXAssignmentStatus
         * @memberof assignment_service.AssignmentService
         * @instance
         * @param {assignment_service.IGetClassXAssignmentStatusRequest} request GetClassXAssignmentStatusRequest message or plain object
         * @returns {Promise<assignment_service.GetClassXAssignmentStatusResponse>} Promise
         * @variation 2
         */

        return AssignmentService;
    })();

    assignment_service.GetAssignmentsRequest = (function() {

        /**
         * Properties of a GetAssignmentsRequest.
         * @memberof assignment_service
         * @interface IGetAssignmentsRequest
         * @property {number|null} [teacherId] GetAssignmentsRequest teacherId
         * @property {number|null} [studentId] GetAssignmentsRequest studentId
         * @property {Array.<number>|null} [schoolIds] GetAssignmentsRequest schoolIds
         * @property {Array.<number>|null} [classXIds] GetAssignmentsRequest classXIds
         * @property {Array.<number>|null} [assignmentIds] GetAssignmentsRequest assignmentIds
         * @property {boolean|null} [includeProjectDefinitions] GetAssignmentsRequest includeProjectDefinitions
         */

        /**
         * Constructs a new GetAssignmentsRequest.
         * @memberof assignment_service
         * @classdesc Represents a GetAssignmentsRequest.
         * @implements IGetAssignmentsRequest
         * @constructor
         * @param {assignment_service.IGetAssignmentsRequest=} [properties] Properties to set
         */
        function GetAssignmentsRequest(properties) {
            this.schoolIds = [];
            this.classXIds = [];
            this.assignmentIds = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAssignmentsRequest teacherId.
         * @member {number|null|undefined} teacherId
         * @memberof assignment_service.GetAssignmentsRequest
         * @instance
         */
        GetAssignmentsRequest.prototype.teacherId = null;

        /**
         * GetAssignmentsRequest studentId.
         * @member {number|null|undefined} studentId
         * @memberof assignment_service.GetAssignmentsRequest
         * @instance
         */
        GetAssignmentsRequest.prototype.studentId = null;

        /**
         * GetAssignmentsRequest schoolIds.
         * @member {Array.<number>} schoolIds
         * @memberof assignment_service.GetAssignmentsRequest
         * @instance
         */
        GetAssignmentsRequest.prototype.schoolIds = $util.emptyArray;

        /**
         * GetAssignmentsRequest classXIds.
         * @member {Array.<number>} classXIds
         * @memberof assignment_service.GetAssignmentsRequest
         * @instance
         */
        GetAssignmentsRequest.prototype.classXIds = $util.emptyArray;

        /**
         * GetAssignmentsRequest assignmentIds.
         * @member {Array.<number>} assignmentIds
         * @memberof assignment_service.GetAssignmentsRequest
         * @instance
         */
        GetAssignmentsRequest.prototype.assignmentIds = $util.emptyArray;

        /**
         * GetAssignmentsRequest includeProjectDefinitions.
         * @member {boolean|null|undefined} includeProjectDefinitions
         * @memberof assignment_service.GetAssignmentsRequest
         * @instance
         */
        GetAssignmentsRequest.prototype.includeProjectDefinitions = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GetAssignmentsRequest _teacherId.
         * @member {"teacherId"|undefined} _teacherId
         * @memberof assignment_service.GetAssignmentsRequest
         * @instance
         */
        Object.defineProperty(GetAssignmentsRequest.prototype, "_teacherId", {
            get: $util.oneOfGetter($oneOfFields = ["teacherId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetAssignmentsRequest _studentId.
         * @member {"studentId"|undefined} _studentId
         * @memberof assignment_service.GetAssignmentsRequest
         * @instance
         */
        Object.defineProperty(GetAssignmentsRequest.prototype, "_studentId", {
            get: $util.oneOfGetter($oneOfFields = ["studentId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetAssignmentsRequest _includeProjectDefinitions.
         * @member {"includeProjectDefinitions"|undefined} _includeProjectDefinitions
         * @memberof assignment_service.GetAssignmentsRequest
         * @instance
         */
        Object.defineProperty(GetAssignmentsRequest.prototype, "_includeProjectDefinitions", {
            get: $util.oneOfGetter($oneOfFields = ["includeProjectDefinitions"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetAssignmentsRequest instance using the specified properties.
         * @function create
         * @memberof assignment_service.GetAssignmentsRequest
         * @static
         * @param {assignment_service.IGetAssignmentsRequest=} [properties] Properties to set
         * @returns {assignment_service.GetAssignmentsRequest} GetAssignmentsRequest instance
         */
        GetAssignmentsRequest.create = function create(properties) {
            return new GetAssignmentsRequest(properties);
        };

        /**
         * Encodes the specified GetAssignmentsRequest message. Does not implicitly {@link assignment_service.GetAssignmentsRequest.verify|verify} messages.
         * @function encode
         * @memberof assignment_service.GetAssignmentsRequest
         * @static
         * @param {assignment_service.IGetAssignmentsRequest} message GetAssignmentsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAssignmentsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.teacherId != null && Object.hasOwnProperty.call(message, "teacherId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.teacherId);
            if (message.studentId != null && Object.hasOwnProperty.call(message, "studentId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.studentId);
            if (message.assignmentIds != null && message.assignmentIds.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (var i = 0; i < message.assignmentIds.length; ++i)
                    writer.int32(message.assignmentIds[i]);
                writer.ldelim();
            }
            if (message.includeProjectDefinitions != null && Object.hasOwnProperty.call(message, "includeProjectDefinitions"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includeProjectDefinitions);
            if (message.schoolIds != null && message.schoolIds.length) {
                writer.uint32(/* id 5, wireType 2 =*/42).fork();
                for (var i = 0; i < message.schoolIds.length; ++i)
                    writer.int32(message.schoolIds[i]);
                writer.ldelim();
            }
            if (message.classXIds != null && message.classXIds.length) {
                writer.uint32(/* id 6, wireType 2 =*/50).fork();
                for (var i = 0; i < message.classXIds.length; ++i)
                    writer.int32(message.classXIds[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified GetAssignmentsRequest message, length delimited. Does not implicitly {@link assignment_service.GetAssignmentsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof assignment_service.GetAssignmentsRequest
         * @static
         * @param {assignment_service.IGetAssignmentsRequest} message GetAssignmentsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAssignmentsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetAssignmentsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof assignment_service.GetAssignmentsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {assignment_service.GetAssignmentsRequest} GetAssignmentsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAssignmentsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.assignment_service.GetAssignmentsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.teacherId = reader.int32();
                        break;
                    }
                case 2: {
                        message.studentId = reader.int32();
                        break;
                    }
                case 5: {
                        if (!(message.schoolIds && message.schoolIds.length))
                            message.schoolIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.schoolIds.push(reader.int32());
                        } else
                            message.schoolIds.push(reader.int32());
                        break;
                    }
                case 6: {
                        if (!(message.classXIds && message.classXIds.length))
                            message.classXIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.classXIds.push(reader.int32());
                        } else
                            message.classXIds.push(reader.int32());
                        break;
                    }
                case 3: {
                        if (!(message.assignmentIds && message.assignmentIds.length))
                            message.assignmentIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.assignmentIds.push(reader.int32());
                        } else
                            message.assignmentIds.push(reader.int32());
                        break;
                    }
                case 4: {
                        message.includeProjectDefinitions = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetAssignmentsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof assignment_service.GetAssignmentsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {assignment_service.GetAssignmentsRequest} GetAssignmentsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAssignmentsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetAssignmentsRequest message.
         * @function verify
         * @memberof assignment_service.GetAssignmentsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetAssignmentsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.teacherId != null && message.hasOwnProperty("teacherId")) {
                properties._teacherId = 1;
                if (!$util.isInteger(message.teacherId))
                    return "teacherId: integer expected";
            }
            if (message.studentId != null && message.hasOwnProperty("studentId")) {
                properties._studentId = 1;
                if (!$util.isInteger(message.studentId))
                    return "studentId: integer expected";
            }
            if (message.schoolIds != null && message.hasOwnProperty("schoolIds")) {
                if (!Array.isArray(message.schoolIds))
                    return "schoolIds: array expected";
                for (var i = 0; i < message.schoolIds.length; ++i)
                    if (!$util.isInteger(message.schoolIds[i]))
                        return "schoolIds: integer[] expected";
            }
            if (message.classXIds != null && message.hasOwnProperty("classXIds")) {
                if (!Array.isArray(message.classXIds))
                    return "classXIds: array expected";
                for (var i = 0; i < message.classXIds.length; ++i)
                    if (!$util.isInteger(message.classXIds[i]))
                        return "classXIds: integer[] expected";
            }
            if (message.assignmentIds != null && message.hasOwnProperty("assignmentIds")) {
                if (!Array.isArray(message.assignmentIds))
                    return "assignmentIds: array expected";
                for (var i = 0; i < message.assignmentIds.length; ++i)
                    if (!$util.isInteger(message.assignmentIds[i]))
                        return "assignmentIds: integer[] expected";
            }
            if (message.includeProjectDefinitions != null && message.hasOwnProperty("includeProjectDefinitions")) {
                properties._includeProjectDefinitions = 1;
                if (typeof message.includeProjectDefinitions !== "boolean")
                    return "includeProjectDefinitions: boolean expected";
            }
            return null;
        };

        /**
         * Creates a GetAssignmentsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof assignment_service.GetAssignmentsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {assignment_service.GetAssignmentsRequest} GetAssignmentsRequest
         */
        GetAssignmentsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.assignment_service.GetAssignmentsRequest)
                return object;
            var message = new $root.assignment_service.GetAssignmentsRequest();
            if (object.teacherId != null)
                message.teacherId = object.teacherId | 0;
            if (object.studentId != null)
                message.studentId = object.studentId | 0;
            if (object.schoolIds) {
                if (!Array.isArray(object.schoolIds))
                    throw TypeError(".assignment_service.GetAssignmentsRequest.schoolIds: array expected");
                message.schoolIds = [];
                for (var i = 0; i < object.schoolIds.length; ++i)
                    message.schoolIds[i] = object.schoolIds[i] | 0;
            }
            if (object.classXIds) {
                if (!Array.isArray(object.classXIds))
                    throw TypeError(".assignment_service.GetAssignmentsRequest.classXIds: array expected");
                message.classXIds = [];
                for (var i = 0; i < object.classXIds.length; ++i)
                    message.classXIds[i] = object.classXIds[i] | 0;
            }
            if (object.assignmentIds) {
                if (!Array.isArray(object.assignmentIds))
                    throw TypeError(".assignment_service.GetAssignmentsRequest.assignmentIds: array expected");
                message.assignmentIds = [];
                for (var i = 0; i < object.assignmentIds.length; ++i)
                    message.assignmentIds[i] = object.assignmentIds[i] | 0;
            }
            if (object.includeProjectDefinitions != null)
                message.includeProjectDefinitions = Boolean(object.includeProjectDefinitions);
            return message;
        };

        /**
         * Creates a plain object from a GetAssignmentsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof assignment_service.GetAssignmentsRequest
         * @static
         * @param {assignment_service.GetAssignmentsRequest} message GetAssignmentsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetAssignmentsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.assignmentIds = [];
                object.schoolIds = [];
                object.classXIds = [];
            }
            if (message.teacherId != null && message.hasOwnProperty("teacherId")) {
                object.teacherId = message.teacherId;
                if (options.oneofs)
                    object._teacherId = "teacherId";
            }
            if (message.studentId != null && message.hasOwnProperty("studentId")) {
                object.studentId = message.studentId;
                if (options.oneofs)
                    object._studentId = "studentId";
            }
            if (message.assignmentIds && message.assignmentIds.length) {
                object.assignmentIds = [];
                for (var j = 0; j < message.assignmentIds.length; ++j)
                    object.assignmentIds[j] = message.assignmentIds[j];
            }
            if (message.includeProjectDefinitions != null && message.hasOwnProperty("includeProjectDefinitions")) {
                object.includeProjectDefinitions = message.includeProjectDefinitions;
                if (options.oneofs)
                    object._includeProjectDefinitions = "includeProjectDefinitions";
            }
            if (message.schoolIds && message.schoolIds.length) {
                object.schoolIds = [];
                for (var j = 0; j < message.schoolIds.length; ++j)
                    object.schoolIds[j] = message.schoolIds[j];
            }
            if (message.classXIds && message.classXIds.length) {
                object.classXIds = [];
                for (var j = 0; j < message.classXIds.length; ++j)
                    object.classXIds[j] = message.classXIds[j];
            }
            return object;
        };

        /**
         * Converts this GetAssignmentsRequest to JSON.
         * @function toJSON
         * @memberof assignment_service.GetAssignmentsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetAssignmentsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetAssignmentsRequest
         * @function getTypeUrl
         * @memberof assignment_service.GetAssignmentsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetAssignmentsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/assignment_service.GetAssignmentsRequest";
        };

        return GetAssignmentsRequest;
    })();

    assignment_service.GetAssignmentsResponse = (function() {

        /**
         * Properties of a GetAssignmentsResponse.
         * @memberof assignment_service
         * @interface IGetAssignmentsResponse
         * @property {Array.<pl_types.IClassX>|null} [classXs] GetAssignmentsResponse classXs
         * @property {Array.<pl_types.IAssignment>|null} [assignments] GetAssignmentsResponse assignments
         */

        /**
         * Constructs a new GetAssignmentsResponse.
         * @memberof assignment_service
         * @classdesc Represents a GetAssignmentsResponse.
         * @implements IGetAssignmentsResponse
         * @constructor
         * @param {assignment_service.IGetAssignmentsResponse=} [properties] Properties to set
         */
        function GetAssignmentsResponse(properties) {
            this.classXs = [];
            this.assignments = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAssignmentsResponse classXs.
         * @member {Array.<pl_types.IClassX>} classXs
         * @memberof assignment_service.GetAssignmentsResponse
         * @instance
         */
        GetAssignmentsResponse.prototype.classXs = $util.emptyArray;

        /**
         * GetAssignmentsResponse assignments.
         * @member {Array.<pl_types.IAssignment>} assignments
         * @memberof assignment_service.GetAssignmentsResponse
         * @instance
         */
        GetAssignmentsResponse.prototype.assignments = $util.emptyArray;

        /**
         * Creates a new GetAssignmentsResponse instance using the specified properties.
         * @function create
         * @memberof assignment_service.GetAssignmentsResponse
         * @static
         * @param {assignment_service.IGetAssignmentsResponse=} [properties] Properties to set
         * @returns {assignment_service.GetAssignmentsResponse} GetAssignmentsResponse instance
         */
        GetAssignmentsResponse.create = function create(properties) {
            return new GetAssignmentsResponse(properties);
        };

        /**
         * Encodes the specified GetAssignmentsResponse message. Does not implicitly {@link assignment_service.GetAssignmentsResponse.verify|verify} messages.
         * @function encode
         * @memberof assignment_service.GetAssignmentsResponse
         * @static
         * @param {assignment_service.IGetAssignmentsResponse} message GetAssignmentsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAssignmentsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.classXs != null && message.classXs.length)
                for (var i = 0; i < message.classXs.length; ++i)
                    $root.pl_types.ClassX.encode(message.classXs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.assignments != null && message.assignments.length)
                for (var i = 0; i < message.assignments.length; ++i)
                    $root.pl_types.Assignment.encode(message.assignments[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetAssignmentsResponse message, length delimited. Does not implicitly {@link assignment_service.GetAssignmentsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof assignment_service.GetAssignmentsResponse
         * @static
         * @param {assignment_service.IGetAssignmentsResponse} message GetAssignmentsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAssignmentsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetAssignmentsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof assignment_service.GetAssignmentsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {assignment_service.GetAssignmentsResponse} GetAssignmentsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAssignmentsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.assignment_service.GetAssignmentsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.classXs && message.classXs.length))
                            message.classXs = [];
                        message.classXs.push($root.pl_types.ClassX.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        if (!(message.assignments && message.assignments.length))
                            message.assignments = [];
                        message.assignments.push($root.pl_types.Assignment.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetAssignmentsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof assignment_service.GetAssignmentsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {assignment_service.GetAssignmentsResponse} GetAssignmentsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAssignmentsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetAssignmentsResponse message.
         * @function verify
         * @memberof assignment_service.GetAssignmentsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetAssignmentsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.classXs != null && message.hasOwnProperty("classXs")) {
                if (!Array.isArray(message.classXs))
                    return "classXs: array expected";
                for (var i = 0; i < message.classXs.length; ++i) {
                    var error = $root.pl_types.ClassX.verify(message.classXs[i]);
                    if (error)
                        return "classXs." + error;
                }
            }
            if (message.assignments != null && message.hasOwnProperty("assignments")) {
                if (!Array.isArray(message.assignments))
                    return "assignments: array expected";
                for (var i = 0; i < message.assignments.length; ++i) {
                    var error = $root.pl_types.Assignment.verify(message.assignments[i]);
                    if (error)
                        return "assignments." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetAssignmentsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof assignment_service.GetAssignmentsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {assignment_service.GetAssignmentsResponse} GetAssignmentsResponse
         */
        GetAssignmentsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.assignment_service.GetAssignmentsResponse)
                return object;
            var message = new $root.assignment_service.GetAssignmentsResponse();
            if (object.classXs) {
                if (!Array.isArray(object.classXs))
                    throw TypeError(".assignment_service.GetAssignmentsResponse.classXs: array expected");
                message.classXs = [];
                for (var i = 0; i < object.classXs.length; ++i) {
                    if (typeof object.classXs[i] !== "object")
                        throw TypeError(".assignment_service.GetAssignmentsResponse.classXs: object expected");
                    message.classXs[i] = $root.pl_types.ClassX.fromObject(object.classXs[i]);
                }
            }
            if (object.assignments) {
                if (!Array.isArray(object.assignments))
                    throw TypeError(".assignment_service.GetAssignmentsResponse.assignments: array expected");
                message.assignments = [];
                for (var i = 0; i < object.assignments.length; ++i) {
                    if (typeof object.assignments[i] !== "object")
                        throw TypeError(".assignment_service.GetAssignmentsResponse.assignments: object expected");
                    message.assignments[i] = $root.pl_types.Assignment.fromObject(object.assignments[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetAssignmentsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof assignment_service.GetAssignmentsResponse
         * @static
         * @param {assignment_service.GetAssignmentsResponse} message GetAssignmentsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetAssignmentsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.classXs = [];
                object.assignments = [];
            }
            if (message.classXs && message.classXs.length) {
                object.classXs = [];
                for (var j = 0; j < message.classXs.length; ++j)
                    object.classXs[j] = $root.pl_types.ClassX.toObject(message.classXs[j], options);
            }
            if (message.assignments && message.assignments.length) {
                object.assignments = [];
                for (var j = 0; j < message.assignments.length; ++j)
                    object.assignments[j] = $root.pl_types.Assignment.toObject(message.assignments[j], options);
            }
            return object;
        };

        /**
         * Converts this GetAssignmentsResponse to JSON.
         * @function toJSON
         * @memberof assignment_service.GetAssignmentsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetAssignmentsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetAssignmentsResponse
         * @function getTypeUrl
         * @memberof assignment_service.GetAssignmentsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetAssignmentsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/assignment_service.GetAssignmentsResponse";
        };

        return GetAssignmentsResponse;
    })();

    assignment_service.CreateAssignmentRequest = (function() {

        /**
         * Properties of a CreateAssignmentRequest.
         * @memberof assignment_service
         * @interface ICreateAssignmentRequest
         * @property {number|null} [classXId] CreateAssignmentRequest classXId
         */

        /**
         * Constructs a new CreateAssignmentRequest.
         * @memberof assignment_service
         * @classdesc Represents a CreateAssignmentRequest.
         * @implements ICreateAssignmentRequest
         * @constructor
         * @param {assignment_service.ICreateAssignmentRequest=} [properties] Properties to set
         */
        function CreateAssignmentRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateAssignmentRequest classXId.
         * @member {number|null|undefined} classXId
         * @memberof assignment_service.CreateAssignmentRequest
         * @instance
         */
        CreateAssignmentRequest.prototype.classXId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * CreateAssignmentRequest _classXId.
         * @member {"classXId"|undefined} _classXId
         * @memberof assignment_service.CreateAssignmentRequest
         * @instance
         */
        Object.defineProperty(CreateAssignmentRequest.prototype, "_classXId", {
            get: $util.oneOfGetter($oneOfFields = ["classXId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CreateAssignmentRequest instance using the specified properties.
         * @function create
         * @memberof assignment_service.CreateAssignmentRequest
         * @static
         * @param {assignment_service.ICreateAssignmentRequest=} [properties] Properties to set
         * @returns {assignment_service.CreateAssignmentRequest} CreateAssignmentRequest instance
         */
        CreateAssignmentRequest.create = function create(properties) {
            return new CreateAssignmentRequest(properties);
        };

        /**
         * Encodes the specified CreateAssignmentRequest message. Does not implicitly {@link assignment_service.CreateAssignmentRequest.verify|verify} messages.
         * @function encode
         * @memberof assignment_service.CreateAssignmentRequest
         * @static
         * @param {assignment_service.ICreateAssignmentRequest} message CreateAssignmentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateAssignmentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.classXId != null && Object.hasOwnProperty.call(message, "classXId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.classXId);
            return writer;
        };

        /**
         * Encodes the specified CreateAssignmentRequest message, length delimited. Does not implicitly {@link assignment_service.CreateAssignmentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof assignment_service.CreateAssignmentRequest
         * @static
         * @param {assignment_service.ICreateAssignmentRequest} message CreateAssignmentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateAssignmentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateAssignmentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof assignment_service.CreateAssignmentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {assignment_service.CreateAssignmentRequest} CreateAssignmentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateAssignmentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.assignment_service.CreateAssignmentRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.classXId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateAssignmentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof assignment_service.CreateAssignmentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {assignment_service.CreateAssignmentRequest} CreateAssignmentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateAssignmentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateAssignmentRequest message.
         * @function verify
         * @memberof assignment_service.CreateAssignmentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateAssignmentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.classXId != null && message.hasOwnProperty("classXId")) {
                properties._classXId = 1;
                if (!$util.isInteger(message.classXId))
                    return "classXId: integer expected";
            }
            return null;
        };

        /**
         * Creates a CreateAssignmentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof assignment_service.CreateAssignmentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {assignment_service.CreateAssignmentRequest} CreateAssignmentRequest
         */
        CreateAssignmentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.assignment_service.CreateAssignmentRequest)
                return object;
            var message = new $root.assignment_service.CreateAssignmentRequest();
            if (object.classXId != null)
                message.classXId = object.classXId | 0;
            return message;
        };

        /**
         * Creates a plain object from a CreateAssignmentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof assignment_service.CreateAssignmentRequest
         * @static
         * @param {assignment_service.CreateAssignmentRequest} message CreateAssignmentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateAssignmentRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.classXId != null && message.hasOwnProperty("classXId")) {
                object.classXId = message.classXId;
                if (options.oneofs)
                    object._classXId = "classXId";
            }
            return object;
        };

        /**
         * Converts this CreateAssignmentRequest to JSON.
         * @function toJSON
         * @memberof assignment_service.CreateAssignmentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateAssignmentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateAssignmentRequest
         * @function getTypeUrl
         * @memberof assignment_service.CreateAssignmentRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateAssignmentRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/assignment_service.CreateAssignmentRequest";
        };

        return CreateAssignmentRequest;
    })();

    assignment_service.CreateAssignmentResponse = (function() {

        /**
         * Properties of a CreateAssignmentResponse.
         * @memberof assignment_service
         * @interface ICreateAssignmentResponse
         * @property {pl_types.IAssignment|null} [assignment] CreateAssignmentResponse assignment
         */

        /**
         * Constructs a new CreateAssignmentResponse.
         * @memberof assignment_service
         * @classdesc Represents a CreateAssignmentResponse.
         * @implements ICreateAssignmentResponse
         * @constructor
         * @param {assignment_service.ICreateAssignmentResponse=} [properties] Properties to set
         */
        function CreateAssignmentResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateAssignmentResponse assignment.
         * @member {pl_types.IAssignment|null|undefined} assignment
         * @memberof assignment_service.CreateAssignmentResponse
         * @instance
         */
        CreateAssignmentResponse.prototype.assignment = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * CreateAssignmentResponse _assignment.
         * @member {"assignment"|undefined} _assignment
         * @memberof assignment_service.CreateAssignmentResponse
         * @instance
         */
        Object.defineProperty(CreateAssignmentResponse.prototype, "_assignment", {
            get: $util.oneOfGetter($oneOfFields = ["assignment"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CreateAssignmentResponse instance using the specified properties.
         * @function create
         * @memberof assignment_service.CreateAssignmentResponse
         * @static
         * @param {assignment_service.ICreateAssignmentResponse=} [properties] Properties to set
         * @returns {assignment_service.CreateAssignmentResponse} CreateAssignmentResponse instance
         */
        CreateAssignmentResponse.create = function create(properties) {
            return new CreateAssignmentResponse(properties);
        };

        /**
         * Encodes the specified CreateAssignmentResponse message. Does not implicitly {@link assignment_service.CreateAssignmentResponse.verify|verify} messages.
         * @function encode
         * @memberof assignment_service.CreateAssignmentResponse
         * @static
         * @param {assignment_service.ICreateAssignmentResponse} message CreateAssignmentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateAssignmentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.assignment != null && Object.hasOwnProperty.call(message, "assignment"))
                $root.pl_types.Assignment.encode(message.assignment, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateAssignmentResponse message, length delimited. Does not implicitly {@link assignment_service.CreateAssignmentResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof assignment_service.CreateAssignmentResponse
         * @static
         * @param {assignment_service.ICreateAssignmentResponse} message CreateAssignmentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateAssignmentResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateAssignmentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof assignment_service.CreateAssignmentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {assignment_service.CreateAssignmentResponse} CreateAssignmentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateAssignmentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.assignment_service.CreateAssignmentResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.assignment = $root.pl_types.Assignment.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateAssignmentResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof assignment_service.CreateAssignmentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {assignment_service.CreateAssignmentResponse} CreateAssignmentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateAssignmentResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateAssignmentResponse message.
         * @function verify
         * @memberof assignment_service.CreateAssignmentResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateAssignmentResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.assignment != null && message.hasOwnProperty("assignment")) {
                properties._assignment = 1;
                {
                    var error = $root.pl_types.Assignment.verify(message.assignment);
                    if (error)
                        return "assignment." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CreateAssignmentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof assignment_service.CreateAssignmentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {assignment_service.CreateAssignmentResponse} CreateAssignmentResponse
         */
        CreateAssignmentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.assignment_service.CreateAssignmentResponse)
                return object;
            var message = new $root.assignment_service.CreateAssignmentResponse();
            if (object.assignment != null) {
                if (typeof object.assignment !== "object")
                    throw TypeError(".assignment_service.CreateAssignmentResponse.assignment: object expected");
                message.assignment = $root.pl_types.Assignment.fromObject(object.assignment);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateAssignmentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof assignment_service.CreateAssignmentResponse
         * @static
         * @param {assignment_service.CreateAssignmentResponse} message CreateAssignmentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateAssignmentResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.assignment != null && message.hasOwnProperty("assignment")) {
                object.assignment = $root.pl_types.Assignment.toObject(message.assignment, options);
                if (options.oneofs)
                    object._assignment = "assignment";
            }
            return object;
        };

        /**
         * Converts this CreateAssignmentResponse to JSON.
         * @function toJSON
         * @memberof assignment_service.CreateAssignmentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateAssignmentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateAssignmentResponse
         * @function getTypeUrl
         * @memberof assignment_service.CreateAssignmentResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateAssignmentResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/assignment_service.CreateAssignmentResponse";
        };

        return CreateAssignmentResponse;
    })();

    assignment_service.SaveAssignmentRequest = (function() {

        /**
         * Properties of a SaveAssignmentRequest.
         * @memberof assignment_service
         * @interface ISaveAssignmentRequest
         * @property {pl_types.IAssignment|null} [assignment] SaveAssignmentRequest assignment
         */

        /**
         * Constructs a new SaveAssignmentRequest.
         * @memberof assignment_service
         * @classdesc Represents a SaveAssignmentRequest.
         * @implements ISaveAssignmentRequest
         * @constructor
         * @param {assignment_service.ISaveAssignmentRequest=} [properties] Properties to set
         */
        function SaveAssignmentRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SaveAssignmentRequest assignment.
         * @member {pl_types.IAssignment|null|undefined} assignment
         * @memberof assignment_service.SaveAssignmentRequest
         * @instance
         */
        SaveAssignmentRequest.prototype.assignment = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * SaveAssignmentRequest _assignment.
         * @member {"assignment"|undefined} _assignment
         * @memberof assignment_service.SaveAssignmentRequest
         * @instance
         */
        Object.defineProperty(SaveAssignmentRequest.prototype, "_assignment", {
            get: $util.oneOfGetter($oneOfFields = ["assignment"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SaveAssignmentRequest instance using the specified properties.
         * @function create
         * @memberof assignment_service.SaveAssignmentRequest
         * @static
         * @param {assignment_service.ISaveAssignmentRequest=} [properties] Properties to set
         * @returns {assignment_service.SaveAssignmentRequest} SaveAssignmentRequest instance
         */
        SaveAssignmentRequest.create = function create(properties) {
            return new SaveAssignmentRequest(properties);
        };

        /**
         * Encodes the specified SaveAssignmentRequest message. Does not implicitly {@link assignment_service.SaveAssignmentRequest.verify|verify} messages.
         * @function encode
         * @memberof assignment_service.SaveAssignmentRequest
         * @static
         * @param {assignment_service.ISaveAssignmentRequest} message SaveAssignmentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveAssignmentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.assignment != null && Object.hasOwnProperty.call(message, "assignment"))
                $root.pl_types.Assignment.encode(message.assignment, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SaveAssignmentRequest message, length delimited. Does not implicitly {@link assignment_service.SaveAssignmentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof assignment_service.SaveAssignmentRequest
         * @static
         * @param {assignment_service.ISaveAssignmentRequest} message SaveAssignmentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveAssignmentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SaveAssignmentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof assignment_service.SaveAssignmentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {assignment_service.SaveAssignmentRequest} SaveAssignmentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveAssignmentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.assignment_service.SaveAssignmentRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.assignment = $root.pl_types.Assignment.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SaveAssignmentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof assignment_service.SaveAssignmentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {assignment_service.SaveAssignmentRequest} SaveAssignmentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveAssignmentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SaveAssignmentRequest message.
         * @function verify
         * @memberof assignment_service.SaveAssignmentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SaveAssignmentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.assignment != null && message.hasOwnProperty("assignment")) {
                properties._assignment = 1;
                {
                    var error = $root.pl_types.Assignment.verify(message.assignment);
                    if (error)
                        return "assignment." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SaveAssignmentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof assignment_service.SaveAssignmentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {assignment_service.SaveAssignmentRequest} SaveAssignmentRequest
         */
        SaveAssignmentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.assignment_service.SaveAssignmentRequest)
                return object;
            var message = new $root.assignment_service.SaveAssignmentRequest();
            if (object.assignment != null) {
                if (typeof object.assignment !== "object")
                    throw TypeError(".assignment_service.SaveAssignmentRequest.assignment: object expected");
                message.assignment = $root.pl_types.Assignment.fromObject(object.assignment);
            }
            return message;
        };

        /**
         * Creates a plain object from a SaveAssignmentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof assignment_service.SaveAssignmentRequest
         * @static
         * @param {assignment_service.SaveAssignmentRequest} message SaveAssignmentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SaveAssignmentRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.assignment != null && message.hasOwnProperty("assignment")) {
                object.assignment = $root.pl_types.Assignment.toObject(message.assignment, options);
                if (options.oneofs)
                    object._assignment = "assignment";
            }
            return object;
        };

        /**
         * Converts this SaveAssignmentRequest to JSON.
         * @function toJSON
         * @memberof assignment_service.SaveAssignmentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SaveAssignmentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SaveAssignmentRequest
         * @function getTypeUrl
         * @memberof assignment_service.SaveAssignmentRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SaveAssignmentRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/assignment_service.SaveAssignmentRequest";
        };

        return SaveAssignmentRequest;
    })();

    assignment_service.SaveAssignmentResponse = (function() {

        /**
         * Properties of a SaveAssignmentResponse.
         * @memberof assignment_service
         * @interface ISaveAssignmentResponse
         */

        /**
         * Constructs a new SaveAssignmentResponse.
         * @memberof assignment_service
         * @classdesc Represents a SaveAssignmentResponse.
         * @implements ISaveAssignmentResponse
         * @constructor
         * @param {assignment_service.ISaveAssignmentResponse=} [properties] Properties to set
         */
        function SaveAssignmentResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SaveAssignmentResponse instance using the specified properties.
         * @function create
         * @memberof assignment_service.SaveAssignmentResponse
         * @static
         * @param {assignment_service.ISaveAssignmentResponse=} [properties] Properties to set
         * @returns {assignment_service.SaveAssignmentResponse} SaveAssignmentResponse instance
         */
        SaveAssignmentResponse.create = function create(properties) {
            return new SaveAssignmentResponse(properties);
        };

        /**
         * Encodes the specified SaveAssignmentResponse message. Does not implicitly {@link assignment_service.SaveAssignmentResponse.verify|verify} messages.
         * @function encode
         * @memberof assignment_service.SaveAssignmentResponse
         * @static
         * @param {assignment_service.ISaveAssignmentResponse} message SaveAssignmentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveAssignmentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SaveAssignmentResponse message, length delimited. Does not implicitly {@link assignment_service.SaveAssignmentResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof assignment_service.SaveAssignmentResponse
         * @static
         * @param {assignment_service.ISaveAssignmentResponse} message SaveAssignmentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveAssignmentResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SaveAssignmentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof assignment_service.SaveAssignmentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {assignment_service.SaveAssignmentResponse} SaveAssignmentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveAssignmentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.assignment_service.SaveAssignmentResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SaveAssignmentResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof assignment_service.SaveAssignmentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {assignment_service.SaveAssignmentResponse} SaveAssignmentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveAssignmentResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SaveAssignmentResponse message.
         * @function verify
         * @memberof assignment_service.SaveAssignmentResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SaveAssignmentResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SaveAssignmentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof assignment_service.SaveAssignmentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {assignment_service.SaveAssignmentResponse} SaveAssignmentResponse
         */
        SaveAssignmentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.assignment_service.SaveAssignmentResponse)
                return object;
            return new $root.assignment_service.SaveAssignmentResponse();
        };

        /**
         * Creates a plain object from a SaveAssignmentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof assignment_service.SaveAssignmentResponse
         * @static
         * @param {assignment_service.SaveAssignmentResponse} message SaveAssignmentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SaveAssignmentResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SaveAssignmentResponse to JSON.
         * @function toJSON
         * @memberof assignment_service.SaveAssignmentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SaveAssignmentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SaveAssignmentResponse
         * @function getTypeUrl
         * @memberof assignment_service.SaveAssignmentResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SaveAssignmentResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/assignment_service.SaveAssignmentResponse";
        };

        return SaveAssignmentResponse;
    })();

    assignment_service.DeleteAssignmentRequest = (function() {

        /**
         * Properties of a DeleteAssignmentRequest.
         * @memberof assignment_service
         * @interface IDeleteAssignmentRequest
         * @property {number|null} [assignmentId] DeleteAssignmentRequest assignmentId
         */

        /**
         * Constructs a new DeleteAssignmentRequest.
         * @memberof assignment_service
         * @classdesc Represents a DeleteAssignmentRequest.
         * @implements IDeleteAssignmentRequest
         * @constructor
         * @param {assignment_service.IDeleteAssignmentRequest=} [properties] Properties to set
         */
        function DeleteAssignmentRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteAssignmentRequest assignmentId.
         * @member {number|null|undefined} assignmentId
         * @memberof assignment_service.DeleteAssignmentRequest
         * @instance
         */
        DeleteAssignmentRequest.prototype.assignmentId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * DeleteAssignmentRequest _assignmentId.
         * @member {"assignmentId"|undefined} _assignmentId
         * @memberof assignment_service.DeleteAssignmentRequest
         * @instance
         */
        Object.defineProperty(DeleteAssignmentRequest.prototype, "_assignmentId", {
            get: $util.oneOfGetter($oneOfFields = ["assignmentId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new DeleteAssignmentRequest instance using the specified properties.
         * @function create
         * @memberof assignment_service.DeleteAssignmentRequest
         * @static
         * @param {assignment_service.IDeleteAssignmentRequest=} [properties] Properties to set
         * @returns {assignment_service.DeleteAssignmentRequest} DeleteAssignmentRequest instance
         */
        DeleteAssignmentRequest.create = function create(properties) {
            return new DeleteAssignmentRequest(properties);
        };

        /**
         * Encodes the specified DeleteAssignmentRequest message. Does not implicitly {@link assignment_service.DeleteAssignmentRequest.verify|verify} messages.
         * @function encode
         * @memberof assignment_service.DeleteAssignmentRequest
         * @static
         * @param {assignment_service.IDeleteAssignmentRequest} message DeleteAssignmentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteAssignmentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.assignmentId != null && Object.hasOwnProperty.call(message, "assignmentId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.assignmentId);
            return writer;
        };

        /**
         * Encodes the specified DeleteAssignmentRequest message, length delimited. Does not implicitly {@link assignment_service.DeleteAssignmentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof assignment_service.DeleteAssignmentRequest
         * @static
         * @param {assignment_service.IDeleteAssignmentRequest} message DeleteAssignmentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteAssignmentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteAssignmentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof assignment_service.DeleteAssignmentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {assignment_service.DeleteAssignmentRequest} DeleteAssignmentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteAssignmentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.assignment_service.DeleteAssignmentRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.assignmentId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteAssignmentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof assignment_service.DeleteAssignmentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {assignment_service.DeleteAssignmentRequest} DeleteAssignmentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteAssignmentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteAssignmentRequest message.
         * @function verify
         * @memberof assignment_service.DeleteAssignmentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteAssignmentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.assignmentId != null && message.hasOwnProperty("assignmentId")) {
                properties._assignmentId = 1;
                if (!$util.isInteger(message.assignmentId))
                    return "assignmentId: integer expected";
            }
            return null;
        };

        /**
         * Creates a DeleteAssignmentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof assignment_service.DeleteAssignmentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {assignment_service.DeleteAssignmentRequest} DeleteAssignmentRequest
         */
        DeleteAssignmentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.assignment_service.DeleteAssignmentRequest)
                return object;
            var message = new $root.assignment_service.DeleteAssignmentRequest();
            if (object.assignmentId != null)
                message.assignmentId = object.assignmentId | 0;
            return message;
        };

        /**
         * Creates a plain object from a DeleteAssignmentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof assignment_service.DeleteAssignmentRequest
         * @static
         * @param {assignment_service.DeleteAssignmentRequest} message DeleteAssignmentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteAssignmentRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.assignmentId != null && message.hasOwnProperty("assignmentId")) {
                object.assignmentId = message.assignmentId;
                if (options.oneofs)
                    object._assignmentId = "assignmentId";
            }
            return object;
        };

        /**
         * Converts this DeleteAssignmentRequest to JSON.
         * @function toJSON
         * @memberof assignment_service.DeleteAssignmentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteAssignmentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteAssignmentRequest
         * @function getTypeUrl
         * @memberof assignment_service.DeleteAssignmentRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteAssignmentRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/assignment_service.DeleteAssignmentRequest";
        };

        return DeleteAssignmentRequest;
    })();

    assignment_service.DeleteAssignmentResponse = (function() {

        /**
         * Properties of a DeleteAssignmentResponse.
         * @memberof assignment_service
         * @interface IDeleteAssignmentResponse
         */

        /**
         * Constructs a new DeleteAssignmentResponse.
         * @memberof assignment_service
         * @classdesc Represents a DeleteAssignmentResponse.
         * @implements IDeleteAssignmentResponse
         * @constructor
         * @param {assignment_service.IDeleteAssignmentResponse=} [properties] Properties to set
         */
        function DeleteAssignmentResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteAssignmentResponse instance using the specified properties.
         * @function create
         * @memberof assignment_service.DeleteAssignmentResponse
         * @static
         * @param {assignment_service.IDeleteAssignmentResponse=} [properties] Properties to set
         * @returns {assignment_service.DeleteAssignmentResponse} DeleteAssignmentResponse instance
         */
        DeleteAssignmentResponse.create = function create(properties) {
            return new DeleteAssignmentResponse(properties);
        };

        /**
         * Encodes the specified DeleteAssignmentResponse message. Does not implicitly {@link assignment_service.DeleteAssignmentResponse.verify|verify} messages.
         * @function encode
         * @memberof assignment_service.DeleteAssignmentResponse
         * @static
         * @param {assignment_service.IDeleteAssignmentResponse} message DeleteAssignmentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteAssignmentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteAssignmentResponse message, length delimited. Does not implicitly {@link assignment_service.DeleteAssignmentResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof assignment_service.DeleteAssignmentResponse
         * @static
         * @param {assignment_service.IDeleteAssignmentResponse} message DeleteAssignmentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteAssignmentResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteAssignmentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof assignment_service.DeleteAssignmentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {assignment_service.DeleteAssignmentResponse} DeleteAssignmentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteAssignmentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.assignment_service.DeleteAssignmentResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteAssignmentResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof assignment_service.DeleteAssignmentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {assignment_service.DeleteAssignmentResponse} DeleteAssignmentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteAssignmentResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteAssignmentResponse message.
         * @function verify
         * @memberof assignment_service.DeleteAssignmentResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteAssignmentResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteAssignmentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof assignment_service.DeleteAssignmentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {assignment_service.DeleteAssignmentResponse} DeleteAssignmentResponse
         */
        DeleteAssignmentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.assignment_service.DeleteAssignmentResponse)
                return object;
            return new $root.assignment_service.DeleteAssignmentResponse();
        };

        /**
         * Creates a plain object from a DeleteAssignmentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof assignment_service.DeleteAssignmentResponse
         * @static
         * @param {assignment_service.DeleteAssignmentResponse} message DeleteAssignmentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteAssignmentResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteAssignmentResponse to JSON.
         * @function toJSON
         * @memberof assignment_service.DeleteAssignmentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteAssignmentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteAssignmentResponse
         * @function getTypeUrl
         * @memberof assignment_service.DeleteAssignmentResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteAssignmentResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/assignment_service.DeleteAssignmentResponse";
        };

        return DeleteAssignmentResponse;
    })();

    assignment_service.GetClassXAssignmentStatusRequest = (function() {

        /**
         * Properties of a GetClassXAssignmentStatusRequest.
         * @memberof assignment_service
         * @interface IGetClassXAssignmentStatusRequest
         * @property {number|null} [assignmentId] GetClassXAssignmentStatusRequest assignmentId
         */

        /**
         * Constructs a new GetClassXAssignmentStatusRequest.
         * @memberof assignment_service
         * @classdesc Represents a GetClassXAssignmentStatusRequest.
         * @implements IGetClassXAssignmentStatusRequest
         * @constructor
         * @param {assignment_service.IGetClassXAssignmentStatusRequest=} [properties] Properties to set
         */
        function GetClassXAssignmentStatusRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetClassXAssignmentStatusRequest assignmentId.
         * @member {number|null|undefined} assignmentId
         * @memberof assignment_service.GetClassXAssignmentStatusRequest
         * @instance
         */
        GetClassXAssignmentStatusRequest.prototype.assignmentId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GetClassXAssignmentStatusRequest _assignmentId.
         * @member {"assignmentId"|undefined} _assignmentId
         * @memberof assignment_service.GetClassXAssignmentStatusRequest
         * @instance
         */
        Object.defineProperty(GetClassXAssignmentStatusRequest.prototype, "_assignmentId", {
            get: $util.oneOfGetter($oneOfFields = ["assignmentId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetClassXAssignmentStatusRequest instance using the specified properties.
         * @function create
         * @memberof assignment_service.GetClassXAssignmentStatusRequest
         * @static
         * @param {assignment_service.IGetClassXAssignmentStatusRequest=} [properties] Properties to set
         * @returns {assignment_service.GetClassXAssignmentStatusRequest} GetClassXAssignmentStatusRequest instance
         */
        GetClassXAssignmentStatusRequest.create = function create(properties) {
            return new GetClassXAssignmentStatusRequest(properties);
        };

        /**
         * Encodes the specified GetClassXAssignmentStatusRequest message. Does not implicitly {@link assignment_service.GetClassXAssignmentStatusRequest.verify|verify} messages.
         * @function encode
         * @memberof assignment_service.GetClassXAssignmentStatusRequest
         * @static
         * @param {assignment_service.IGetClassXAssignmentStatusRequest} message GetClassXAssignmentStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetClassXAssignmentStatusRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.assignmentId != null && Object.hasOwnProperty.call(message, "assignmentId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.assignmentId);
            return writer;
        };

        /**
         * Encodes the specified GetClassXAssignmentStatusRequest message, length delimited. Does not implicitly {@link assignment_service.GetClassXAssignmentStatusRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof assignment_service.GetClassXAssignmentStatusRequest
         * @static
         * @param {assignment_service.IGetClassXAssignmentStatusRequest} message GetClassXAssignmentStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetClassXAssignmentStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetClassXAssignmentStatusRequest message from the specified reader or buffer.
         * @function decode
         * @memberof assignment_service.GetClassXAssignmentStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {assignment_service.GetClassXAssignmentStatusRequest} GetClassXAssignmentStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetClassXAssignmentStatusRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.assignment_service.GetClassXAssignmentStatusRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.assignmentId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetClassXAssignmentStatusRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof assignment_service.GetClassXAssignmentStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {assignment_service.GetClassXAssignmentStatusRequest} GetClassXAssignmentStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetClassXAssignmentStatusRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetClassXAssignmentStatusRequest message.
         * @function verify
         * @memberof assignment_service.GetClassXAssignmentStatusRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetClassXAssignmentStatusRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.assignmentId != null && message.hasOwnProperty("assignmentId")) {
                properties._assignmentId = 1;
                if (!$util.isInteger(message.assignmentId))
                    return "assignmentId: integer expected";
            }
            return null;
        };

        /**
         * Creates a GetClassXAssignmentStatusRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof assignment_service.GetClassXAssignmentStatusRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {assignment_service.GetClassXAssignmentStatusRequest} GetClassXAssignmentStatusRequest
         */
        GetClassXAssignmentStatusRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.assignment_service.GetClassXAssignmentStatusRequest)
                return object;
            var message = new $root.assignment_service.GetClassXAssignmentStatusRequest();
            if (object.assignmentId != null)
                message.assignmentId = object.assignmentId | 0;
            return message;
        };

        /**
         * Creates a plain object from a GetClassXAssignmentStatusRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof assignment_service.GetClassXAssignmentStatusRequest
         * @static
         * @param {assignment_service.GetClassXAssignmentStatusRequest} message GetClassXAssignmentStatusRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetClassXAssignmentStatusRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.assignmentId != null && message.hasOwnProperty("assignmentId")) {
                object.assignmentId = message.assignmentId;
                if (options.oneofs)
                    object._assignmentId = "assignmentId";
            }
            return object;
        };

        /**
         * Converts this GetClassXAssignmentStatusRequest to JSON.
         * @function toJSON
         * @memberof assignment_service.GetClassXAssignmentStatusRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetClassXAssignmentStatusRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetClassXAssignmentStatusRequest
         * @function getTypeUrl
         * @memberof assignment_service.GetClassXAssignmentStatusRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetClassXAssignmentStatusRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/assignment_service.GetClassXAssignmentStatusRequest";
        };

        return GetClassXAssignmentStatusRequest;
    })();

    assignment_service.GetClassXAssignmentStatusResponse = (function() {

        /**
         * Properties of a GetClassXAssignmentStatusResponse.
         * @memberof assignment_service
         * @interface IGetClassXAssignmentStatusResponse
         * @property {pl_types.IAssignment|null} [assignment] GetClassXAssignmentStatusResponse assignment
         * @property {Array.<assignment_service.GetClassXAssignmentStatusResponse.IUserXStatus>|null} [userXStatuses] GetClassXAssignmentStatusResponse userXStatuses
         */

        /**
         * Constructs a new GetClassXAssignmentStatusResponse.
         * @memberof assignment_service
         * @classdesc Represents a GetClassXAssignmentStatusResponse.
         * @implements IGetClassXAssignmentStatusResponse
         * @constructor
         * @param {assignment_service.IGetClassXAssignmentStatusResponse=} [properties] Properties to set
         */
        function GetClassXAssignmentStatusResponse(properties) {
            this.userXStatuses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetClassXAssignmentStatusResponse assignment.
         * @member {pl_types.IAssignment|null|undefined} assignment
         * @memberof assignment_service.GetClassXAssignmentStatusResponse
         * @instance
         */
        GetClassXAssignmentStatusResponse.prototype.assignment = null;

        /**
         * GetClassXAssignmentStatusResponse userXStatuses.
         * @member {Array.<assignment_service.GetClassXAssignmentStatusResponse.IUserXStatus>} userXStatuses
         * @memberof assignment_service.GetClassXAssignmentStatusResponse
         * @instance
         */
        GetClassXAssignmentStatusResponse.prototype.userXStatuses = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GetClassXAssignmentStatusResponse _assignment.
         * @member {"assignment"|undefined} _assignment
         * @memberof assignment_service.GetClassXAssignmentStatusResponse
         * @instance
         */
        Object.defineProperty(GetClassXAssignmentStatusResponse.prototype, "_assignment", {
            get: $util.oneOfGetter($oneOfFields = ["assignment"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetClassXAssignmentStatusResponse instance using the specified properties.
         * @function create
         * @memberof assignment_service.GetClassXAssignmentStatusResponse
         * @static
         * @param {assignment_service.IGetClassXAssignmentStatusResponse=} [properties] Properties to set
         * @returns {assignment_service.GetClassXAssignmentStatusResponse} GetClassXAssignmentStatusResponse instance
         */
        GetClassXAssignmentStatusResponse.create = function create(properties) {
            return new GetClassXAssignmentStatusResponse(properties);
        };

        /**
         * Encodes the specified GetClassXAssignmentStatusResponse message. Does not implicitly {@link assignment_service.GetClassXAssignmentStatusResponse.verify|verify} messages.
         * @function encode
         * @memberof assignment_service.GetClassXAssignmentStatusResponse
         * @static
         * @param {assignment_service.IGetClassXAssignmentStatusResponse} message GetClassXAssignmentStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetClassXAssignmentStatusResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.assignment != null && Object.hasOwnProperty.call(message, "assignment"))
                $root.pl_types.Assignment.encode(message.assignment, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.userXStatuses != null && message.userXStatuses.length)
                for (var i = 0; i < message.userXStatuses.length; ++i)
                    $root.assignment_service.GetClassXAssignmentStatusResponse.UserXStatus.encode(message.userXStatuses[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetClassXAssignmentStatusResponse message, length delimited. Does not implicitly {@link assignment_service.GetClassXAssignmentStatusResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof assignment_service.GetClassXAssignmentStatusResponse
         * @static
         * @param {assignment_service.IGetClassXAssignmentStatusResponse} message GetClassXAssignmentStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetClassXAssignmentStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetClassXAssignmentStatusResponse message from the specified reader or buffer.
         * @function decode
         * @memberof assignment_service.GetClassXAssignmentStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {assignment_service.GetClassXAssignmentStatusResponse} GetClassXAssignmentStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetClassXAssignmentStatusResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.assignment_service.GetClassXAssignmentStatusResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.assignment = $root.pl_types.Assignment.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        if (!(message.userXStatuses && message.userXStatuses.length))
                            message.userXStatuses = [];
                        message.userXStatuses.push($root.assignment_service.GetClassXAssignmentStatusResponse.UserXStatus.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetClassXAssignmentStatusResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof assignment_service.GetClassXAssignmentStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {assignment_service.GetClassXAssignmentStatusResponse} GetClassXAssignmentStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetClassXAssignmentStatusResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetClassXAssignmentStatusResponse message.
         * @function verify
         * @memberof assignment_service.GetClassXAssignmentStatusResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetClassXAssignmentStatusResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.assignment != null && message.hasOwnProperty("assignment")) {
                properties._assignment = 1;
                {
                    var error = $root.pl_types.Assignment.verify(message.assignment);
                    if (error)
                        return "assignment." + error;
                }
            }
            if (message.userXStatuses != null && message.hasOwnProperty("userXStatuses")) {
                if (!Array.isArray(message.userXStatuses))
                    return "userXStatuses: array expected";
                for (var i = 0; i < message.userXStatuses.length; ++i) {
                    var error = $root.assignment_service.GetClassXAssignmentStatusResponse.UserXStatus.verify(message.userXStatuses[i]);
                    if (error)
                        return "userXStatuses." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetClassXAssignmentStatusResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof assignment_service.GetClassXAssignmentStatusResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {assignment_service.GetClassXAssignmentStatusResponse} GetClassXAssignmentStatusResponse
         */
        GetClassXAssignmentStatusResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.assignment_service.GetClassXAssignmentStatusResponse)
                return object;
            var message = new $root.assignment_service.GetClassXAssignmentStatusResponse();
            if (object.assignment != null) {
                if (typeof object.assignment !== "object")
                    throw TypeError(".assignment_service.GetClassXAssignmentStatusResponse.assignment: object expected");
                message.assignment = $root.pl_types.Assignment.fromObject(object.assignment);
            }
            if (object.userXStatuses) {
                if (!Array.isArray(object.userXStatuses))
                    throw TypeError(".assignment_service.GetClassXAssignmentStatusResponse.userXStatuses: array expected");
                message.userXStatuses = [];
                for (var i = 0; i < object.userXStatuses.length; ++i) {
                    if (typeof object.userXStatuses[i] !== "object")
                        throw TypeError(".assignment_service.GetClassXAssignmentStatusResponse.userXStatuses: object expected");
                    message.userXStatuses[i] = $root.assignment_service.GetClassXAssignmentStatusResponse.UserXStatus.fromObject(object.userXStatuses[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetClassXAssignmentStatusResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof assignment_service.GetClassXAssignmentStatusResponse
         * @static
         * @param {assignment_service.GetClassXAssignmentStatusResponse} message GetClassXAssignmentStatusResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetClassXAssignmentStatusResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.userXStatuses = [];
            if (message.assignment != null && message.hasOwnProperty("assignment")) {
                object.assignment = $root.pl_types.Assignment.toObject(message.assignment, options);
                if (options.oneofs)
                    object._assignment = "assignment";
            }
            if (message.userXStatuses && message.userXStatuses.length) {
                object.userXStatuses = [];
                for (var j = 0; j < message.userXStatuses.length; ++j)
                    object.userXStatuses[j] = $root.assignment_service.GetClassXAssignmentStatusResponse.UserXStatus.toObject(message.userXStatuses[j], options);
            }
            return object;
        };

        /**
         * Converts this GetClassXAssignmentStatusResponse to JSON.
         * @function toJSON
         * @memberof assignment_service.GetClassXAssignmentStatusResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetClassXAssignmentStatusResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetClassXAssignmentStatusResponse
         * @function getTypeUrl
         * @memberof assignment_service.GetClassXAssignmentStatusResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetClassXAssignmentStatusResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/assignment_service.GetClassXAssignmentStatusResponse";
        };

        GetClassXAssignmentStatusResponse.UserXStatus = (function() {

            /**
             * Properties of a UserXStatus.
             * @memberof assignment_service.GetClassXAssignmentStatusResponse
             * @interface IUserXStatus
             * @property {pl_types.IUserX|null} [userX] UserXStatus userX
             * @property {pl_types.IProject|null} [project] UserXStatus project
             * @property {boolean|null} [isAwaitingFeedback] UserXStatus isAwaitingFeedback
             * @property {Long|null} [lastPostTimeMs] UserXStatus lastPostTimeMs
             */

            /**
             * Constructs a new UserXStatus.
             * @memberof assignment_service.GetClassXAssignmentStatusResponse
             * @classdesc Represents a UserXStatus.
             * @implements IUserXStatus
             * @constructor
             * @param {assignment_service.GetClassXAssignmentStatusResponse.IUserXStatus=} [properties] Properties to set
             */
            function UserXStatus(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UserXStatus userX.
             * @member {pl_types.IUserX|null|undefined} userX
             * @memberof assignment_service.GetClassXAssignmentStatusResponse.UserXStatus
             * @instance
             */
            UserXStatus.prototype.userX = null;

            /**
             * UserXStatus project.
             * @member {pl_types.IProject|null|undefined} project
             * @memberof assignment_service.GetClassXAssignmentStatusResponse.UserXStatus
             * @instance
             */
            UserXStatus.prototype.project = null;

            /**
             * UserXStatus isAwaitingFeedback.
             * @member {boolean|null|undefined} isAwaitingFeedback
             * @memberof assignment_service.GetClassXAssignmentStatusResponse.UserXStatus
             * @instance
             */
            UserXStatus.prototype.isAwaitingFeedback = null;

            /**
             * UserXStatus lastPostTimeMs.
             * @member {Long|null|undefined} lastPostTimeMs
             * @memberof assignment_service.GetClassXAssignmentStatusResponse.UserXStatus
             * @instance
             */
            UserXStatus.prototype.lastPostTimeMs = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * UserXStatus _userX.
             * @member {"userX"|undefined} _userX
             * @memberof assignment_service.GetClassXAssignmentStatusResponse.UserXStatus
             * @instance
             */
            Object.defineProperty(UserXStatus.prototype, "_userX", {
                get: $util.oneOfGetter($oneOfFields = ["userX"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * UserXStatus _project.
             * @member {"project"|undefined} _project
             * @memberof assignment_service.GetClassXAssignmentStatusResponse.UserXStatus
             * @instance
             */
            Object.defineProperty(UserXStatus.prototype, "_project", {
                get: $util.oneOfGetter($oneOfFields = ["project"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * UserXStatus _isAwaitingFeedback.
             * @member {"isAwaitingFeedback"|undefined} _isAwaitingFeedback
             * @memberof assignment_service.GetClassXAssignmentStatusResponse.UserXStatus
             * @instance
             */
            Object.defineProperty(UserXStatus.prototype, "_isAwaitingFeedback", {
                get: $util.oneOfGetter($oneOfFields = ["isAwaitingFeedback"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * UserXStatus _lastPostTimeMs.
             * @member {"lastPostTimeMs"|undefined} _lastPostTimeMs
             * @memberof assignment_service.GetClassXAssignmentStatusResponse.UserXStatus
             * @instance
             */
            Object.defineProperty(UserXStatus.prototype, "_lastPostTimeMs", {
                get: $util.oneOfGetter($oneOfFields = ["lastPostTimeMs"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new UserXStatus instance using the specified properties.
             * @function create
             * @memberof assignment_service.GetClassXAssignmentStatusResponse.UserXStatus
             * @static
             * @param {assignment_service.GetClassXAssignmentStatusResponse.IUserXStatus=} [properties] Properties to set
             * @returns {assignment_service.GetClassXAssignmentStatusResponse.UserXStatus} UserXStatus instance
             */
            UserXStatus.create = function create(properties) {
                return new UserXStatus(properties);
            };

            /**
             * Encodes the specified UserXStatus message. Does not implicitly {@link assignment_service.GetClassXAssignmentStatusResponse.UserXStatus.verify|verify} messages.
             * @function encode
             * @memberof assignment_service.GetClassXAssignmentStatusResponse.UserXStatus
             * @static
             * @param {assignment_service.GetClassXAssignmentStatusResponse.IUserXStatus} message UserXStatus message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserXStatus.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.userX != null && Object.hasOwnProperty.call(message, "userX"))
                    $root.pl_types.UserX.encode(message.userX, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.project != null && Object.hasOwnProperty.call(message, "project"))
                    $root.pl_types.Project.encode(message.project, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.isAwaitingFeedback != null && Object.hasOwnProperty.call(message, "isAwaitingFeedback"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isAwaitingFeedback);
                if (message.lastPostTimeMs != null && Object.hasOwnProperty.call(message, "lastPostTimeMs"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.lastPostTimeMs);
                return writer;
            };

            /**
             * Encodes the specified UserXStatus message, length delimited. Does not implicitly {@link assignment_service.GetClassXAssignmentStatusResponse.UserXStatus.verify|verify} messages.
             * @function encodeDelimited
             * @memberof assignment_service.GetClassXAssignmentStatusResponse.UserXStatus
             * @static
             * @param {assignment_service.GetClassXAssignmentStatusResponse.IUserXStatus} message UserXStatus message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserXStatus.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a UserXStatus message from the specified reader or buffer.
             * @function decode
             * @memberof assignment_service.GetClassXAssignmentStatusResponse.UserXStatus
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {assignment_service.GetClassXAssignmentStatusResponse.UserXStatus} UserXStatus
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserXStatus.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.assignment_service.GetClassXAssignmentStatusResponse.UserXStatus();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.userX = $root.pl_types.UserX.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.project = $root.pl_types.Project.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.isAwaitingFeedback = reader.bool();
                            break;
                        }
                    case 4: {
                            message.lastPostTimeMs = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a UserXStatus message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof assignment_service.GetClassXAssignmentStatusResponse.UserXStatus
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {assignment_service.GetClassXAssignmentStatusResponse.UserXStatus} UserXStatus
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserXStatus.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a UserXStatus message.
             * @function verify
             * @memberof assignment_service.GetClassXAssignmentStatusResponse.UserXStatus
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UserXStatus.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.userX != null && message.hasOwnProperty("userX")) {
                    properties._userX = 1;
                    {
                        var error = $root.pl_types.UserX.verify(message.userX);
                        if (error)
                            return "userX." + error;
                    }
                }
                if (message.project != null && message.hasOwnProperty("project")) {
                    properties._project = 1;
                    {
                        var error = $root.pl_types.Project.verify(message.project);
                        if (error)
                            return "project." + error;
                    }
                }
                if (message.isAwaitingFeedback != null && message.hasOwnProperty("isAwaitingFeedback")) {
                    properties._isAwaitingFeedback = 1;
                    if (typeof message.isAwaitingFeedback !== "boolean")
                        return "isAwaitingFeedback: boolean expected";
                }
                if (message.lastPostTimeMs != null && message.hasOwnProperty("lastPostTimeMs")) {
                    properties._lastPostTimeMs = 1;
                    if (!$util.isInteger(message.lastPostTimeMs) && !(message.lastPostTimeMs && $util.isInteger(message.lastPostTimeMs.low) && $util.isInteger(message.lastPostTimeMs.high)))
                        return "lastPostTimeMs: integer|Long expected";
                }
                return null;
            };

            /**
             * Creates a UserXStatus message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof assignment_service.GetClassXAssignmentStatusResponse.UserXStatus
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {assignment_service.GetClassXAssignmentStatusResponse.UserXStatus} UserXStatus
             */
            UserXStatus.fromObject = function fromObject(object) {
                if (object instanceof $root.assignment_service.GetClassXAssignmentStatusResponse.UserXStatus)
                    return object;
                var message = new $root.assignment_service.GetClassXAssignmentStatusResponse.UserXStatus();
                if (object.userX != null) {
                    if (typeof object.userX !== "object")
                        throw TypeError(".assignment_service.GetClassXAssignmentStatusResponse.UserXStatus.userX: object expected");
                    message.userX = $root.pl_types.UserX.fromObject(object.userX);
                }
                if (object.project != null) {
                    if (typeof object.project !== "object")
                        throw TypeError(".assignment_service.GetClassXAssignmentStatusResponse.UserXStatus.project: object expected");
                    message.project = $root.pl_types.Project.fromObject(object.project);
                }
                if (object.isAwaitingFeedback != null)
                    message.isAwaitingFeedback = Boolean(object.isAwaitingFeedback);
                if (object.lastPostTimeMs != null)
                    if ($util.Long)
                        (message.lastPostTimeMs = $util.Long.fromValue(object.lastPostTimeMs)).unsigned = false;
                    else if (typeof object.lastPostTimeMs === "string")
                        message.lastPostTimeMs = parseInt(object.lastPostTimeMs, 10);
                    else if (typeof object.lastPostTimeMs === "number")
                        message.lastPostTimeMs = object.lastPostTimeMs;
                    else if (typeof object.lastPostTimeMs === "object")
                        message.lastPostTimeMs = new $util.LongBits(object.lastPostTimeMs.low >>> 0, object.lastPostTimeMs.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a UserXStatus message. Also converts values to other types if specified.
             * @function toObject
             * @memberof assignment_service.GetClassXAssignmentStatusResponse.UserXStatus
             * @static
             * @param {assignment_service.GetClassXAssignmentStatusResponse.UserXStatus} message UserXStatus
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserXStatus.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.userX != null && message.hasOwnProperty("userX")) {
                    object.userX = $root.pl_types.UserX.toObject(message.userX, options);
                    if (options.oneofs)
                        object._userX = "userX";
                }
                if (message.project != null && message.hasOwnProperty("project")) {
                    object.project = $root.pl_types.Project.toObject(message.project, options);
                    if (options.oneofs)
                        object._project = "project";
                }
                if (message.isAwaitingFeedback != null && message.hasOwnProperty("isAwaitingFeedback")) {
                    object.isAwaitingFeedback = message.isAwaitingFeedback;
                    if (options.oneofs)
                        object._isAwaitingFeedback = "isAwaitingFeedback";
                }
                if (message.lastPostTimeMs != null && message.hasOwnProperty("lastPostTimeMs")) {
                    if (typeof message.lastPostTimeMs === "number")
                        object.lastPostTimeMs = options.longs === String ? String(message.lastPostTimeMs) : message.lastPostTimeMs;
                    else
                        object.lastPostTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.lastPostTimeMs) : options.longs === Number ? new $util.LongBits(message.lastPostTimeMs.low >>> 0, message.lastPostTimeMs.high >>> 0).toNumber() : message.lastPostTimeMs;
                    if (options.oneofs)
                        object._lastPostTimeMs = "lastPostTimeMs";
                }
                return object;
            };

            /**
             * Converts this UserXStatus to JSON.
             * @function toJSON
             * @memberof assignment_service.GetClassXAssignmentStatusResponse.UserXStatus
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserXStatus.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UserXStatus
             * @function getTypeUrl
             * @memberof assignment_service.GetClassXAssignmentStatusResponse.UserXStatus
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UserXStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/assignment_service.GetClassXAssignmentStatusResponse.UserXStatus";
            };

            return UserXStatus;
        })();

        return GetClassXAssignmentStatusResponse;
    })();

    return assignment_service;
})();

$root.pl_types = (function() {

    /**
     * Namespace pl_types.
     * @exports pl_types
     * @namespace
     */
    var pl_types = {};

    pl_types.Assignment = (function() {

        /**
         * Properties of an Assignment.
         * @memberof pl_types
         * @interface IAssignment
         * @property {number|null} [id] Assignment id
         * @property {string|null} [name] Assignment name
         * @property {string|null} [nickname] Assignment nickname
         * @property {string|null} [shortDescr] Assignment shortDescr
         * @property {string|null} [longDescrHtml] Assignment longDescrHtml
         * @property {pl_types.IClassX|null} [classX] Assignment classX
         * @property {Array.<pl_types.IKnowledgeAndSkill>|null} [knowledgeAndSkills] Assignment knowledgeAndSkills
         * @property {Array.<pl_types.IProjectDefinition>|null} [projectDefinitions] Assignment projectDefinitions
         */

        /**
         * Constructs a new Assignment.
         * @memberof pl_types
         * @classdesc Represents an Assignment.
         * @implements IAssignment
         * @constructor
         * @param {pl_types.IAssignment=} [properties] Properties to set
         */
        function Assignment(properties) {
            this.knowledgeAndSkills = [];
            this.projectDefinitions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Assignment id.
         * @member {number|null|undefined} id
         * @memberof pl_types.Assignment
         * @instance
         */
        Assignment.prototype.id = null;

        /**
         * Assignment name.
         * @member {string|null|undefined} name
         * @memberof pl_types.Assignment
         * @instance
         */
        Assignment.prototype.name = null;

        /**
         * Assignment nickname.
         * @member {string|null|undefined} nickname
         * @memberof pl_types.Assignment
         * @instance
         */
        Assignment.prototype.nickname = null;

        /**
         * Assignment shortDescr.
         * @member {string|null|undefined} shortDescr
         * @memberof pl_types.Assignment
         * @instance
         */
        Assignment.prototype.shortDescr = null;

        /**
         * Assignment longDescrHtml.
         * @member {string|null|undefined} longDescrHtml
         * @memberof pl_types.Assignment
         * @instance
         */
        Assignment.prototype.longDescrHtml = null;

        /**
         * Assignment classX.
         * @member {pl_types.IClassX|null|undefined} classX
         * @memberof pl_types.Assignment
         * @instance
         */
        Assignment.prototype.classX = null;

        /**
         * Assignment knowledgeAndSkills.
         * @member {Array.<pl_types.IKnowledgeAndSkill>} knowledgeAndSkills
         * @memberof pl_types.Assignment
         * @instance
         */
        Assignment.prototype.knowledgeAndSkills = $util.emptyArray;

        /**
         * Assignment projectDefinitions.
         * @member {Array.<pl_types.IProjectDefinition>} projectDefinitions
         * @memberof pl_types.Assignment
         * @instance
         */
        Assignment.prototype.projectDefinitions = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Assignment _id.
         * @member {"id"|undefined} _id
         * @memberof pl_types.Assignment
         * @instance
         */
        Object.defineProperty(Assignment.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Assignment _name.
         * @member {"name"|undefined} _name
         * @memberof pl_types.Assignment
         * @instance
         */
        Object.defineProperty(Assignment.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Assignment _nickname.
         * @member {"nickname"|undefined} _nickname
         * @memberof pl_types.Assignment
         * @instance
         */
        Object.defineProperty(Assignment.prototype, "_nickname", {
            get: $util.oneOfGetter($oneOfFields = ["nickname"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Assignment _shortDescr.
         * @member {"shortDescr"|undefined} _shortDescr
         * @memberof pl_types.Assignment
         * @instance
         */
        Object.defineProperty(Assignment.prototype, "_shortDescr", {
            get: $util.oneOfGetter($oneOfFields = ["shortDescr"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Assignment _longDescrHtml.
         * @member {"longDescrHtml"|undefined} _longDescrHtml
         * @memberof pl_types.Assignment
         * @instance
         */
        Object.defineProperty(Assignment.prototype, "_longDescrHtml", {
            get: $util.oneOfGetter($oneOfFields = ["longDescrHtml"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Assignment _classX.
         * @member {"classX"|undefined} _classX
         * @memberof pl_types.Assignment
         * @instance
         */
        Object.defineProperty(Assignment.prototype, "_classX", {
            get: $util.oneOfGetter($oneOfFields = ["classX"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Assignment instance using the specified properties.
         * @function create
         * @memberof pl_types.Assignment
         * @static
         * @param {pl_types.IAssignment=} [properties] Properties to set
         * @returns {pl_types.Assignment} Assignment instance
         */
        Assignment.create = function create(properties) {
            return new Assignment(properties);
        };

        /**
         * Encodes the specified Assignment message. Does not implicitly {@link pl_types.Assignment.verify|verify} messages.
         * @function encode
         * @memberof pl_types.Assignment
         * @static
         * @param {pl_types.IAssignment} message Assignment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Assignment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.classX != null && Object.hasOwnProperty.call(message, "classX"))
                $root.pl_types.ClassX.encode(message.classX, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.shortDescr != null && Object.hasOwnProperty.call(message, "shortDescr"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.shortDescr);
            if (message.longDescrHtml != null && Object.hasOwnProperty.call(message, "longDescrHtml"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.longDescrHtml);
            if (message.nickname != null && Object.hasOwnProperty.call(message, "nickname"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.nickname);
            if (message.knowledgeAndSkills != null && message.knowledgeAndSkills.length)
                for (var i = 0; i < message.knowledgeAndSkills.length; ++i)
                    $root.pl_types.KnowledgeAndSkill.encode(message.knowledgeAndSkills[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.projectDefinitions != null && message.projectDefinitions.length)
                for (var i = 0; i < message.projectDefinitions.length; ++i)
                    $root.pl_types.ProjectDefinition.encode(message.projectDefinitions[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Assignment message, length delimited. Does not implicitly {@link pl_types.Assignment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pl_types.Assignment
         * @static
         * @param {pl_types.IAssignment} message Assignment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Assignment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Assignment message from the specified reader or buffer.
         * @function decode
         * @memberof pl_types.Assignment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pl_types.Assignment} Assignment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Assignment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.Assignment();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 6: {
                        message.nickname = reader.string();
                        break;
                    }
                case 4: {
                        message.shortDescr = reader.string();
                        break;
                    }
                case 5: {
                        message.longDescrHtml = reader.string();
                        break;
                    }
                case 2: {
                        message.classX = $root.pl_types.ClassX.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        if (!(message.knowledgeAndSkills && message.knowledgeAndSkills.length))
                            message.knowledgeAndSkills = [];
                        message.knowledgeAndSkills.push($root.pl_types.KnowledgeAndSkill.decode(reader, reader.uint32()));
                        break;
                    }
                case 8: {
                        if (!(message.projectDefinitions && message.projectDefinitions.length))
                            message.projectDefinitions = [];
                        message.projectDefinitions.push($root.pl_types.ProjectDefinition.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Assignment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pl_types.Assignment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pl_types.Assignment} Assignment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Assignment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Assignment message.
         * @function verify
         * @memberof pl_types.Assignment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Assignment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            if (message.nickname != null && message.hasOwnProperty("nickname")) {
                properties._nickname = 1;
                if (!$util.isString(message.nickname))
                    return "nickname: string expected";
            }
            if (message.shortDescr != null && message.hasOwnProperty("shortDescr")) {
                properties._shortDescr = 1;
                if (!$util.isString(message.shortDescr))
                    return "shortDescr: string expected";
            }
            if (message.longDescrHtml != null && message.hasOwnProperty("longDescrHtml")) {
                properties._longDescrHtml = 1;
                if (!$util.isString(message.longDescrHtml))
                    return "longDescrHtml: string expected";
            }
            if (message.classX != null && message.hasOwnProperty("classX")) {
                properties._classX = 1;
                {
                    var error = $root.pl_types.ClassX.verify(message.classX);
                    if (error)
                        return "classX." + error;
                }
            }
            if (message.knowledgeAndSkills != null && message.hasOwnProperty("knowledgeAndSkills")) {
                if (!Array.isArray(message.knowledgeAndSkills))
                    return "knowledgeAndSkills: array expected";
                for (var i = 0; i < message.knowledgeAndSkills.length; ++i) {
                    var error = $root.pl_types.KnowledgeAndSkill.verify(message.knowledgeAndSkills[i]);
                    if (error)
                        return "knowledgeAndSkills." + error;
                }
            }
            if (message.projectDefinitions != null && message.hasOwnProperty("projectDefinitions")) {
                if (!Array.isArray(message.projectDefinitions))
                    return "projectDefinitions: array expected";
                for (var i = 0; i < message.projectDefinitions.length; ++i) {
                    var error = $root.pl_types.ProjectDefinition.verify(message.projectDefinitions[i]);
                    if (error)
                        return "projectDefinitions." + error;
                }
            }
            return null;
        };

        /**
         * Creates an Assignment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pl_types.Assignment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pl_types.Assignment} Assignment
         */
        Assignment.fromObject = function fromObject(object) {
            if (object instanceof $root.pl_types.Assignment)
                return object;
            var message = new $root.pl_types.Assignment();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.name != null)
                message.name = String(object.name);
            if (object.nickname != null)
                message.nickname = String(object.nickname);
            if (object.shortDescr != null)
                message.shortDescr = String(object.shortDescr);
            if (object.longDescrHtml != null)
                message.longDescrHtml = String(object.longDescrHtml);
            if (object.classX != null) {
                if (typeof object.classX !== "object")
                    throw TypeError(".pl_types.Assignment.classX: object expected");
                message.classX = $root.pl_types.ClassX.fromObject(object.classX);
            }
            if (object.knowledgeAndSkills) {
                if (!Array.isArray(object.knowledgeAndSkills))
                    throw TypeError(".pl_types.Assignment.knowledgeAndSkills: array expected");
                message.knowledgeAndSkills = [];
                for (var i = 0; i < object.knowledgeAndSkills.length; ++i) {
                    if (typeof object.knowledgeAndSkills[i] !== "object")
                        throw TypeError(".pl_types.Assignment.knowledgeAndSkills: object expected");
                    message.knowledgeAndSkills[i] = $root.pl_types.KnowledgeAndSkill.fromObject(object.knowledgeAndSkills[i]);
                }
            }
            if (object.projectDefinitions) {
                if (!Array.isArray(object.projectDefinitions))
                    throw TypeError(".pl_types.Assignment.projectDefinitions: array expected");
                message.projectDefinitions = [];
                for (var i = 0; i < object.projectDefinitions.length; ++i) {
                    if (typeof object.projectDefinitions[i] !== "object")
                        throw TypeError(".pl_types.Assignment.projectDefinitions: object expected");
                    message.projectDefinitions[i] = $root.pl_types.ProjectDefinition.fromObject(object.projectDefinitions[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an Assignment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pl_types.Assignment
         * @static
         * @param {pl_types.Assignment} message Assignment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Assignment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.knowledgeAndSkills = [];
                object.projectDefinitions = [];
            }
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.classX != null && message.hasOwnProperty("classX")) {
                object.classX = $root.pl_types.ClassX.toObject(message.classX, options);
                if (options.oneofs)
                    object._classX = "classX";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            if (message.shortDescr != null && message.hasOwnProperty("shortDescr")) {
                object.shortDescr = message.shortDescr;
                if (options.oneofs)
                    object._shortDescr = "shortDescr";
            }
            if (message.longDescrHtml != null && message.hasOwnProperty("longDescrHtml")) {
                object.longDescrHtml = message.longDescrHtml;
                if (options.oneofs)
                    object._longDescrHtml = "longDescrHtml";
            }
            if (message.nickname != null && message.hasOwnProperty("nickname")) {
                object.nickname = message.nickname;
                if (options.oneofs)
                    object._nickname = "nickname";
            }
            if (message.knowledgeAndSkills && message.knowledgeAndSkills.length) {
                object.knowledgeAndSkills = [];
                for (var j = 0; j < message.knowledgeAndSkills.length; ++j)
                    object.knowledgeAndSkills[j] = $root.pl_types.KnowledgeAndSkill.toObject(message.knowledgeAndSkills[j], options);
            }
            if (message.projectDefinitions && message.projectDefinitions.length) {
                object.projectDefinitions = [];
                for (var j = 0; j < message.projectDefinitions.length; ++j)
                    object.projectDefinitions[j] = $root.pl_types.ProjectDefinition.toObject(message.projectDefinitions[j], options);
            }
            return object;
        };

        /**
         * Converts this Assignment to JSON.
         * @function toJSON
         * @memberof pl_types.Assignment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Assignment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Assignment
         * @function getTypeUrl
         * @memberof pl_types.Assignment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Assignment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pl_types.Assignment";
        };

        return Assignment;
    })();

    pl_types.Section = (function() {

        /**
         * Properties of a Section.
         * @memberof pl_types
         * @interface ISection
         * @property {number|null} [id] Section id
         * @property {string|null} [name] Section name
         */

        /**
         * Constructs a new Section.
         * @memberof pl_types
         * @classdesc Represents a Section.
         * @implements ISection
         * @constructor
         * @param {pl_types.ISection=} [properties] Properties to set
         */
        function Section(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Section id.
         * @member {number|null|undefined} id
         * @memberof pl_types.Section
         * @instance
         */
        Section.prototype.id = null;

        /**
         * Section name.
         * @member {string|null|undefined} name
         * @memberof pl_types.Section
         * @instance
         */
        Section.prototype.name = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Section _id.
         * @member {"id"|undefined} _id
         * @memberof pl_types.Section
         * @instance
         */
        Object.defineProperty(Section.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Section _name.
         * @member {"name"|undefined} _name
         * @memberof pl_types.Section
         * @instance
         */
        Object.defineProperty(Section.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Section instance using the specified properties.
         * @function create
         * @memberof pl_types.Section
         * @static
         * @param {pl_types.ISection=} [properties] Properties to set
         * @returns {pl_types.Section} Section instance
         */
        Section.create = function create(properties) {
            return new Section(properties);
        };

        /**
         * Encodes the specified Section message. Does not implicitly {@link pl_types.Section.verify|verify} messages.
         * @function encode
         * @memberof pl_types.Section
         * @static
         * @param {pl_types.ISection} message Section message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Section.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified Section message, length delimited. Does not implicitly {@link pl_types.Section.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pl_types.Section
         * @static
         * @param {pl_types.ISection} message Section message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Section.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Section message from the specified reader or buffer.
         * @function decode
         * @memberof pl_types.Section
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pl_types.Section} Section
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Section.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.Section();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Section message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pl_types.Section
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pl_types.Section} Section
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Section.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Section message.
         * @function verify
         * @memberof pl_types.Section
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Section.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            return null;
        };

        /**
         * Creates a Section message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pl_types.Section
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pl_types.Section} Section
         */
        Section.fromObject = function fromObject(object) {
            if (object instanceof $root.pl_types.Section)
                return object;
            var message = new $root.pl_types.Section();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a Section message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pl_types.Section
         * @static
         * @param {pl_types.Section} message Section
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Section.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            return object;
        };

        /**
         * Converts this Section to JSON.
         * @function toJSON
         * @memberof pl_types.Section
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Section.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Section
         * @function getTypeUrl
         * @memberof pl_types.Section
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Section.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pl_types.Section";
        };

        return Section;
    })();

    pl_types.ClassX = (function() {

        /**
         * Properties of a ClassX.
         * @memberof pl_types
         * @interface IClassX
         * @property {number|null} [id] ClassX id
         * @property {pl_types.ISchool|null} [school] ClassX school
         * @property {string|null} [name] ClassX name
         * @property {string|null} [number] ClassX number
         * @property {string|null} [period] ClassX period
         * @property {string|null} [grade] ClassX grade
         * @property {string|null} [shortDescr] ClassX shortDescr
         * @property {string|null} [longDescrHtml] ClassX longDescrHtml
         * @property {Array.<pl_types.ISection>|null} [sections] ClassX sections
         * @property {Array.<pl_types.IAssignment>|null} [assignments] ClassX assignments
         * @property {Array.<pl_types.IKnowledgeAndSkill>|null} [knowledgeAndSkills] ClassX knowledgeAndSkills
         */

        /**
         * Constructs a new ClassX.
         * @memberof pl_types
         * @classdesc Represents a ClassX.
         * @implements IClassX
         * @constructor
         * @param {pl_types.IClassX=} [properties] Properties to set
         */
        function ClassX(properties) {
            this.sections = [];
            this.assignments = [];
            this.knowledgeAndSkills = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ClassX id.
         * @member {number|null|undefined} id
         * @memberof pl_types.ClassX
         * @instance
         */
        ClassX.prototype.id = null;

        /**
         * ClassX school.
         * @member {pl_types.ISchool|null|undefined} school
         * @memberof pl_types.ClassX
         * @instance
         */
        ClassX.prototype.school = null;

        /**
         * ClassX name.
         * @member {string|null|undefined} name
         * @memberof pl_types.ClassX
         * @instance
         */
        ClassX.prototype.name = null;

        /**
         * ClassX number.
         * @member {string|null|undefined} number
         * @memberof pl_types.ClassX
         * @instance
         */
        ClassX.prototype.number = null;

        /**
         * ClassX period.
         * @member {string|null|undefined} period
         * @memberof pl_types.ClassX
         * @instance
         */
        ClassX.prototype.period = null;

        /**
         * ClassX grade.
         * @member {string|null|undefined} grade
         * @memberof pl_types.ClassX
         * @instance
         */
        ClassX.prototype.grade = null;

        /**
         * ClassX shortDescr.
         * @member {string|null|undefined} shortDescr
         * @memberof pl_types.ClassX
         * @instance
         */
        ClassX.prototype.shortDescr = null;

        /**
         * ClassX longDescrHtml.
         * @member {string|null|undefined} longDescrHtml
         * @memberof pl_types.ClassX
         * @instance
         */
        ClassX.prototype.longDescrHtml = null;

        /**
         * ClassX sections.
         * @member {Array.<pl_types.ISection>} sections
         * @memberof pl_types.ClassX
         * @instance
         */
        ClassX.prototype.sections = $util.emptyArray;

        /**
         * ClassX assignments.
         * @member {Array.<pl_types.IAssignment>} assignments
         * @memberof pl_types.ClassX
         * @instance
         */
        ClassX.prototype.assignments = $util.emptyArray;

        /**
         * ClassX knowledgeAndSkills.
         * @member {Array.<pl_types.IKnowledgeAndSkill>} knowledgeAndSkills
         * @memberof pl_types.ClassX
         * @instance
         */
        ClassX.prototype.knowledgeAndSkills = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ClassX _id.
         * @member {"id"|undefined} _id
         * @memberof pl_types.ClassX
         * @instance
         */
        Object.defineProperty(ClassX.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ClassX _school.
         * @member {"school"|undefined} _school
         * @memberof pl_types.ClassX
         * @instance
         */
        Object.defineProperty(ClassX.prototype, "_school", {
            get: $util.oneOfGetter($oneOfFields = ["school"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ClassX _name.
         * @member {"name"|undefined} _name
         * @memberof pl_types.ClassX
         * @instance
         */
        Object.defineProperty(ClassX.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ClassX _number.
         * @member {"number"|undefined} _number
         * @memberof pl_types.ClassX
         * @instance
         */
        Object.defineProperty(ClassX.prototype, "_number", {
            get: $util.oneOfGetter($oneOfFields = ["number"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ClassX _period.
         * @member {"period"|undefined} _period
         * @memberof pl_types.ClassX
         * @instance
         */
        Object.defineProperty(ClassX.prototype, "_period", {
            get: $util.oneOfGetter($oneOfFields = ["period"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ClassX _grade.
         * @member {"grade"|undefined} _grade
         * @memberof pl_types.ClassX
         * @instance
         */
        Object.defineProperty(ClassX.prototype, "_grade", {
            get: $util.oneOfGetter($oneOfFields = ["grade"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ClassX _shortDescr.
         * @member {"shortDescr"|undefined} _shortDescr
         * @memberof pl_types.ClassX
         * @instance
         */
        Object.defineProperty(ClassX.prototype, "_shortDescr", {
            get: $util.oneOfGetter($oneOfFields = ["shortDescr"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ClassX _longDescrHtml.
         * @member {"longDescrHtml"|undefined} _longDescrHtml
         * @memberof pl_types.ClassX
         * @instance
         */
        Object.defineProperty(ClassX.prototype, "_longDescrHtml", {
            get: $util.oneOfGetter($oneOfFields = ["longDescrHtml"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ClassX instance using the specified properties.
         * @function create
         * @memberof pl_types.ClassX
         * @static
         * @param {pl_types.IClassX=} [properties] Properties to set
         * @returns {pl_types.ClassX} ClassX instance
         */
        ClassX.create = function create(properties) {
            return new ClassX(properties);
        };

        /**
         * Encodes the specified ClassX message. Does not implicitly {@link pl_types.ClassX.verify|verify} messages.
         * @function encode
         * @memberof pl_types.ClassX
         * @static
         * @param {pl_types.IClassX} message ClassX message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClassX.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.shortDescr != null && Object.hasOwnProperty.call(message, "shortDescr"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.shortDescr);
            if (message.longDescrHtml != null && Object.hasOwnProperty.call(message, "longDescrHtml"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.longDescrHtml);
            if (message.knowledgeAndSkills != null && message.knowledgeAndSkills.length)
                for (var i = 0; i < message.knowledgeAndSkills.length; ++i)
                    $root.pl_types.KnowledgeAndSkill.encode(message.knowledgeAndSkills[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.number);
            if (message.period != null && Object.hasOwnProperty.call(message, "period"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.period);
            if (message.grade != null && Object.hasOwnProperty.call(message, "grade"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.grade);
            if (message.school != null && Object.hasOwnProperty.call(message, "school"))
                $root.pl_types.School.encode(message.school, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.assignments != null && message.assignments.length)
                for (var i = 0; i < message.assignments.length; ++i)
                    $root.pl_types.Assignment.encode(message.assignments[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.sections != null && message.sections.length)
                for (var i = 0; i < message.sections.length; ++i)
                    $root.pl_types.Section.encode(message.sections[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ClassX message, length delimited. Does not implicitly {@link pl_types.ClassX.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pl_types.ClassX
         * @static
         * @param {pl_types.IClassX} message ClassX message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClassX.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ClassX message from the specified reader or buffer.
         * @function decode
         * @memberof pl_types.ClassX
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pl_types.ClassX} ClassX
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClassX.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.ClassX();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 9: {
                        message.school = $root.pl_types.School.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 6: {
                        message.number = reader.string();
                        break;
                    }
                case 7: {
                        message.period = reader.string();
                        break;
                    }
                case 8: {
                        message.grade = reader.string();
                        break;
                    }
                case 3: {
                        message.shortDescr = reader.string();
                        break;
                    }
                case 4: {
                        message.longDescrHtml = reader.string();
                        break;
                    }
                case 12: {
                        if (!(message.sections && message.sections.length))
                            message.sections = [];
                        message.sections.push($root.pl_types.Section.decode(reader, reader.uint32()));
                        break;
                    }
                case 11: {
                        if (!(message.assignments && message.assignments.length))
                            message.assignments = [];
                        message.assignments.push($root.pl_types.Assignment.decode(reader, reader.uint32()));
                        break;
                    }
                case 5: {
                        if (!(message.knowledgeAndSkills && message.knowledgeAndSkills.length))
                            message.knowledgeAndSkills = [];
                        message.knowledgeAndSkills.push($root.pl_types.KnowledgeAndSkill.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ClassX message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pl_types.ClassX
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pl_types.ClassX} ClassX
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClassX.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ClassX message.
         * @function verify
         * @memberof pl_types.ClassX
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ClassX.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            }
            if (message.school != null && message.hasOwnProperty("school")) {
                properties._school = 1;
                {
                    var error = $root.pl_types.School.verify(message.school);
                    if (error)
                        return "school." + error;
                }
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            if (message.number != null && message.hasOwnProperty("number")) {
                properties._number = 1;
                if (!$util.isString(message.number))
                    return "number: string expected";
            }
            if (message.period != null && message.hasOwnProperty("period")) {
                properties._period = 1;
                if (!$util.isString(message.period))
                    return "period: string expected";
            }
            if (message.grade != null && message.hasOwnProperty("grade")) {
                properties._grade = 1;
                if (!$util.isString(message.grade))
                    return "grade: string expected";
            }
            if (message.shortDescr != null && message.hasOwnProperty("shortDescr")) {
                properties._shortDescr = 1;
                if (!$util.isString(message.shortDescr))
                    return "shortDescr: string expected";
            }
            if (message.longDescrHtml != null && message.hasOwnProperty("longDescrHtml")) {
                properties._longDescrHtml = 1;
                if (!$util.isString(message.longDescrHtml))
                    return "longDescrHtml: string expected";
            }
            if (message.sections != null && message.hasOwnProperty("sections")) {
                if (!Array.isArray(message.sections))
                    return "sections: array expected";
                for (var i = 0; i < message.sections.length; ++i) {
                    var error = $root.pl_types.Section.verify(message.sections[i]);
                    if (error)
                        return "sections." + error;
                }
            }
            if (message.assignments != null && message.hasOwnProperty("assignments")) {
                if (!Array.isArray(message.assignments))
                    return "assignments: array expected";
                for (var i = 0; i < message.assignments.length; ++i) {
                    var error = $root.pl_types.Assignment.verify(message.assignments[i]);
                    if (error)
                        return "assignments." + error;
                }
            }
            if (message.knowledgeAndSkills != null && message.hasOwnProperty("knowledgeAndSkills")) {
                if (!Array.isArray(message.knowledgeAndSkills))
                    return "knowledgeAndSkills: array expected";
                for (var i = 0; i < message.knowledgeAndSkills.length; ++i) {
                    var error = $root.pl_types.KnowledgeAndSkill.verify(message.knowledgeAndSkills[i]);
                    if (error)
                        return "knowledgeAndSkills." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ClassX message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pl_types.ClassX
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pl_types.ClassX} ClassX
         */
        ClassX.fromObject = function fromObject(object) {
            if (object instanceof $root.pl_types.ClassX)
                return object;
            var message = new $root.pl_types.ClassX();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.school != null) {
                if (typeof object.school !== "object")
                    throw TypeError(".pl_types.ClassX.school: object expected");
                message.school = $root.pl_types.School.fromObject(object.school);
            }
            if (object.name != null)
                message.name = String(object.name);
            if (object.number != null)
                message.number = String(object.number);
            if (object.period != null)
                message.period = String(object.period);
            if (object.grade != null)
                message.grade = String(object.grade);
            if (object.shortDescr != null)
                message.shortDescr = String(object.shortDescr);
            if (object.longDescrHtml != null)
                message.longDescrHtml = String(object.longDescrHtml);
            if (object.sections) {
                if (!Array.isArray(object.sections))
                    throw TypeError(".pl_types.ClassX.sections: array expected");
                message.sections = [];
                for (var i = 0; i < object.sections.length; ++i) {
                    if (typeof object.sections[i] !== "object")
                        throw TypeError(".pl_types.ClassX.sections: object expected");
                    message.sections[i] = $root.pl_types.Section.fromObject(object.sections[i]);
                }
            }
            if (object.assignments) {
                if (!Array.isArray(object.assignments))
                    throw TypeError(".pl_types.ClassX.assignments: array expected");
                message.assignments = [];
                for (var i = 0; i < object.assignments.length; ++i) {
                    if (typeof object.assignments[i] !== "object")
                        throw TypeError(".pl_types.ClassX.assignments: object expected");
                    message.assignments[i] = $root.pl_types.Assignment.fromObject(object.assignments[i]);
                }
            }
            if (object.knowledgeAndSkills) {
                if (!Array.isArray(object.knowledgeAndSkills))
                    throw TypeError(".pl_types.ClassX.knowledgeAndSkills: array expected");
                message.knowledgeAndSkills = [];
                for (var i = 0; i < object.knowledgeAndSkills.length; ++i) {
                    if (typeof object.knowledgeAndSkills[i] !== "object")
                        throw TypeError(".pl_types.ClassX.knowledgeAndSkills: object expected");
                    message.knowledgeAndSkills[i] = $root.pl_types.KnowledgeAndSkill.fromObject(object.knowledgeAndSkills[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ClassX message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pl_types.ClassX
         * @static
         * @param {pl_types.ClassX} message ClassX
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ClassX.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.knowledgeAndSkills = [];
                object.assignments = [];
                object.sections = [];
            }
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            if (message.shortDescr != null && message.hasOwnProperty("shortDescr")) {
                object.shortDescr = message.shortDescr;
                if (options.oneofs)
                    object._shortDescr = "shortDescr";
            }
            if (message.longDescrHtml != null && message.hasOwnProperty("longDescrHtml")) {
                object.longDescrHtml = message.longDescrHtml;
                if (options.oneofs)
                    object._longDescrHtml = "longDescrHtml";
            }
            if (message.knowledgeAndSkills && message.knowledgeAndSkills.length) {
                object.knowledgeAndSkills = [];
                for (var j = 0; j < message.knowledgeAndSkills.length; ++j)
                    object.knowledgeAndSkills[j] = $root.pl_types.KnowledgeAndSkill.toObject(message.knowledgeAndSkills[j], options);
            }
            if (message.number != null && message.hasOwnProperty("number")) {
                object.number = message.number;
                if (options.oneofs)
                    object._number = "number";
            }
            if (message.period != null && message.hasOwnProperty("period")) {
                object.period = message.period;
                if (options.oneofs)
                    object._period = "period";
            }
            if (message.grade != null && message.hasOwnProperty("grade")) {
                object.grade = message.grade;
                if (options.oneofs)
                    object._grade = "grade";
            }
            if (message.school != null && message.hasOwnProperty("school")) {
                object.school = $root.pl_types.School.toObject(message.school, options);
                if (options.oneofs)
                    object._school = "school";
            }
            if (message.assignments && message.assignments.length) {
                object.assignments = [];
                for (var j = 0; j < message.assignments.length; ++j)
                    object.assignments[j] = $root.pl_types.Assignment.toObject(message.assignments[j], options);
            }
            if (message.sections && message.sections.length) {
                object.sections = [];
                for (var j = 0; j < message.sections.length; ++j)
                    object.sections[j] = $root.pl_types.Section.toObject(message.sections[j], options);
            }
            return object;
        };

        /**
         * Converts this ClassX to JSON.
         * @function toJSON
         * @memberof pl_types.ClassX
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ClassX.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ClassX
         * @function getTypeUrl
         * @memberof pl_types.ClassX
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ClassX.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pl_types.ClassX";
        };

        return ClassX;
    })();

    pl_types.District = (function() {

        /**
         * Properties of a District.
         * @memberof pl_types
         * @interface IDistrict
         * @property {number|null} [id] District id
         * @property {string|null} [name] District name
         * @property {boolean|null} [isDemo] District isDemo
         */

        /**
         * Constructs a new District.
         * @memberof pl_types
         * @classdesc Represents a District.
         * @implements IDistrict
         * @constructor
         * @param {pl_types.IDistrict=} [properties] Properties to set
         */
        function District(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * District id.
         * @member {number|null|undefined} id
         * @memberof pl_types.District
         * @instance
         */
        District.prototype.id = null;

        /**
         * District name.
         * @member {string|null|undefined} name
         * @memberof pl_types.District
         * @instance
         */
        District.prototype.name = null;

        /**
         * District isDemo.
         * @member {boolean|null|undefined} isDemo
         * @memberof pl_types.District
         * @instance
         */
        District.prototype.isDemo = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * District _id.
         * @member {"id"|undefined} _id
         * @memberof pl_types.District
         * @instance
         */
        Object.defineProperty(District.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * District _name.
         * @member {"name"|undefined} _name
         * @memberof pl_types.District
         * @instance
         */
        Object.defineProperty(District.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * District _isDemo.
         * @member {"isDemo"|undefined} _isDemo
         * @memberof pl_types.District
         * @instance
         */
        Object.defineProperty(District.prototype, "_isDemo", {
            get: $util.oneOfGetter($oneOfFields = ["isDemo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new District instance using the specified properties.
         * @function create
         * @memberof pl_types.District
         * @static
         * @param {pl_types.IDistrict=} [properties] Properties to set
         * @returns {pl_types.District} District instance
         */
        District.create = function create(properties) {
            return new District(properties);
        };

        /**
         * Encodes the specified District message. Does not implicitly {@link pl_types.District.verify|verify} messages.
         * @function encode
         * @memberof pl_types.District
         * @static
         * @param {pl_types.IDistrict} message District message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        District.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.isDemo != null && Object.hasOwnProperty.call(message, "isDemo"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isDemo);
            return writer;
        };

        /**
         * Encodes the specified District message, length delimited. Does not implicitly {@link pl_types.District.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pl_types.District
         * @static
         * @param {pl_types.IDistrict} message District message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        District.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a District message from the specified reader or buffer.
         * @function decode
         * @memberof pl_types.District
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pl_types.District} District
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        District.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.District();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.isDemo = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a District message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pl_types.District
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pl_types.District} District
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        District.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a District message.
         * @function verify
         * @memberof pl_types.District
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        District.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            if (message.isDemo != null && message.hasOwnProperty("isDemo")) {
                properties._isDemo = 1;
                if (typeof message.isDemo !== "boolean")
                    return "isDemo: boolean expected";
            }
            return null;
        };

        /**
         * Creates a District message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pl_types.District
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pl_types.District} District
         */
        District.fromObject = function fromObject(object) {
            if (object instanceof $root.pl_types.District)
                return object;
            var message = new $root.pl_types.District();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.name != null)
                message.name = String(object.name);
            if (object.isDemo != null)
                message.isDemo = Boolean(object.isDemo);
            return message;
        };

        /**
         * Creates a plain object from a District message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pl_types.District
         * @static
         * @param {pl_types.District} message District
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        District.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            if (message.isDemo != null && message.hasOwnProperty("isDemo")) {
                object.isDemo = message.isDemo;
                if (options.oneofs)
                    object._isDemo = "isDemo";
            }
            return object;
        };

        /**
         * Converts this District to JSON.
         * @function toJSON
         * @memberof pl_types.District
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        District.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for District
         * @function getTypeUrl
         * @memberof pl_types.District
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        District.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pl_types.District";
        };

        return District;
    })();

    pl_types.KnowledgeAndSkill = (function() {

        /**
         * Properties of a KnowledgeAndSkill.
         * @memberof pl_types
         * @interface IKnowledgeAndSkill
         * @property {number|null} [id] KnowledgeAndSkill id
         * @property {pl_types.KnowledgeAndSkill.Type|null} [type] KnowledgeAndSkill type
         * @property {string|null} [name] KnowledgeAndSkill name
         * @property {string|null} [category] KnowledgeAndSkill category
         * @property {string|null} [shortDescr] KnowledgeAndSkill shortDescr
         * @property {string|null} [longDescrHtml] KnowledgeAndSkill longDescrHtml
         * @property {boolean|null} [global] KnowledgeAndSkill global
         * @property {pl_types.IUserX|null} [userX] KnowledgeAndSkill userX
         */

        /**
         * Constructs a new KnowledgeAndSkill.
         * @memberof pl_types
         * @classdesc Represents a KnowledgeAndSkill.
         * @implements IKnowledgeAndSkill
         * @constructor
         * @param {pl_types.IKnowledgeAndSkill=} [properties] Properties to set
         */
        function KnowledgeAndSkill(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * KnowledgeAndSkill id.
         * @member {number|null|undefined} id
         * @memberof pl_types.KnowledgeAndSkill
         * @instance
         */
        KnowledgeAndSkill.prototype.id = null;

        /**
         * KnowledgeAndSkill type.
         * @member {pl_types.KnowledgeAndSkill.Type|null|undefined} type
         * @memberof pl_types.KnowledgeAndSkill
         * @instance
         */
        KnowledgeAndSkill.prototype.type = null;

        /**
         * KnowledgeAndSkill name.
         * @member {string|null|undefined} name
         * @memberof pl_types.KnowledgeAndSkill
         * @instance
         */
        KnowledgeAndSkill.prototype.name = null;

        /**
         * KnowledgeAndSkill category.
         * @member {string|null|undefined} category
         * @memberof pl_types.KnowledgeAndSkill
         * @instance
         */
        KnowledgeAndSkill.prototype.category = null;

        /**
         * KnowledgeAndSkill shortDescr.
         * @member {string|null|undefined} shortDescr
         * @memberof pl_types.KnowledgeAndSkill
         * @instance
         */
        KnowledgeAndSkill.prototype.shortDescr = null;

        /**
         * KnowledgeAndSkill longDescrHtml.
         * @member {string|null|undefined} longDescrHtml
         * @memberof pl_types.KnowledgeAndSkill
         * @instance
         */
        KnowledgeAndSkill.prototype.longDescrHtml = null;

        /**
         * KnowledgeAndSkill global.
         * @member {boolean|null|undefined} global
         * @memberof pl_types.KnowledgeAndSkill
         * @instance
         */
        KnowledgeAndSkill.prototype.global = null;

        /**
         * KnowledgeAndSkill userX.
         * @member {pl_types.IUserX|null|undefined} userX
         * @memberof pl_types.KnowledgeAndSkill
         * @instance
         */
        KnowledgeAndSkill.prototype.userX = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * KnowledgeAndSkill _id.
         * @member {"id"|undefined} _id
         * @memberof pl_types.KnowledgeAndSkill
         * @instance
         */
        Object.defineProperty(KnowledgeAndSkill.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * KnowledgeAndSkill _type.
         * @member {"type"|undefined} _type
         * @memberof pl_types.KnowledgeAndSkill
         * @instance
         */
        Object.defineProperty(KnowledgeAndSkill.prototype, "_type", {
            get: $util.oneOfGetter($oneOfFields = ["type"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * KnowledgeAndSkill _name.
         * @member {"name"|undefined} _name
         * @memberof pl_types.KnowledgeAndSkill
         * @instance
         */
        Object.defineProperty(KnowledgeAndSkill.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * KnowledgeAndSkill _category.
         * @member {"category"|undefined} _category
         * @memberof pl_types.KnowledgeAndSkill
         * @instance
         */
        Object.defineProperty(KnowledgeAndSkill.prototype, "_category", {
            get: $util.oneOfGetter($oneOfFields = ["category"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * KnowledgeAndSkill _shortDescr.
         * @member {"shortDescr"|undefined} _shortDescr
         * @memberof pl_types.KnowledgeAndSkill
         * @instance
         */
        Object.defineProperty(KnowledgeAndSkill.prototype, "_shortDescr", {
            get: $util.oneOfGetter($oneOfFields = ["shortDescr"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * KnowledgeAndSkill _longDescrHtml.
         * @member {"longDescrHtml"|undefined} _longDescrHtml
         * @memberof pl_types.KnowledgeAndSkill
         * @instance
         */
        Object.defineProperty(KnowledgeAndSkill.prototype, "_longDescrHtml", {
            get: $util.oneOfGetter($oneOfFields = ["longDescrHtml"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * KnowledgeAndSkill _global.
         * @member {"global"|undefined} _global
         * @memberof pl_types.KnowledgeAndSkill
         * @instance
         */
        Object.defineProperty(KnowledgeAndSkill.prototype, "_global", {
            get: $util.oneOfGetter($oneOfFields = ["global"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * KnowledgeAndSkill _userX.
         * @member {"userX"|undefined} _userX
         * @memberof pl_types.KnowledgeAndSkill
         * @instance
         */
        Object.defineProperty(KnowledgeAndSkill.prototype, "_userX", {
            get: $util.oneOfGetter($oneOfFields = ["userX"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new KnowledgeAndSkill instance using the specified properties.
         * @function create
         * @memberof pl_types.KnowledgeAndSkill
         * @static
         * @param {pl_types.IKnowledgeAndSkill=} [properties] Properties to set
         * @returns {pl_types.KnowledgeAndSkill} KnowledgeAndSkill instance
         */
        KnowledgeAndSkill.create = function create(properties) {
            return new KnowledgeAndSkill(properties);
        };

        /**
         * Encodes the specified KnowledgeAndSkill message. Does not implicitly {@link pl_types.KnowledgeAndSkill.verify|verify} messages.
         * @function encode
         * @memberof pl_types.KnowledgeAndSkill
         * @static
         * @param {pl_types.IKnowledgeAndSkill} message KnowledgeAndSkill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KnowledgeAndSkill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.shortDescr != null && Object.hasOwnProperty.call(message, "shortDescr"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.shortDescr);
            if (message.longDescrHtml != null && Object.hasOwnProperty.call(message, "longDescrHtml"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.longDescrHtml);
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.category);
            if (message.global != null && Object.hasOwnProperty.call(message, "global"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.global);
            if (message.userX != null && Object.hasOwnProperty.call(message, "userX"))
                $root.pl_types.UserX.encode(message.userX, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified KnowledgeAndSkill message, length delimited. Does not implicitly {@link pl_types.KnowledgeAndSkill.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pl_types.KnowledgeAndSkill
         * @static
         * @param {pl_types.IKnowledgeAndSkill} message KnowledgeAndSkill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KnowledgeAndSkill.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a KnowledgeAndSkill message from the specified reader or buffer.
         * @function decode
         * @memberof pl_types.KnowledgeAndSkill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pl_types.KnowledgeAndSkill} KnowledgeAndSkill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KnowledgeAndSkill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.KnowledgeAndSkill();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.type = reader.int32();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 6: {
                        message.category = reader.string();
                        break;
                    }
                case 4: {
                        message.shortDescr = reader.string();
                        break;
                    }
                case 5: {
                        message.longDescrHtml = reader.string();
                        break;
                    }
                case 7: {
                        message.global = reader.bool();
                        break;
                    }
                case 9: {
                        message.userX = $root.pl_types.UserX.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a KnowledgeAndSkill message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pl_types.KnowledgeAndSkill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pl_types.KnowledgeAndSkill} KnowledgeAndSkill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KnowledgeAndSkill.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a KnowledgeAndSkill message.
         * @function verify
         * @memberof pl_types.KnowledgeAndSkill
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        KnowledgeAndSkill.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            }
            if (message.type != null && message.hasOwnProperty("type")) {
                properties._type = 1;
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            if (message.category != null && message.hasOwnProperty("category")) {
                properties._category = 1;
                if (!$util.isString(message.category))
                    return "category: string expected";
            }
            if (message.shortDescr != null && message.hasOwnProperty("shortDescr")) {
                properties._shortDescr = 1;
                if (!$util.isString(message.shortDescr))
                    return "shortDescr: string expected";
            }
            if (message.longDescrHtml != null && message.hasOwnProperty("longDescrHtml")) {
                properties._longDescrHtml = 1;
                if (!$util.isString(message.longDescrHtml))
                    return "longDescrHtml: string expected";
            }
            if (message.global != null && message.hasOwnProperty("global")) {
                properties._global = 1;
                if (typeof message.global !== "boolean")
                    return "global: boolean expected";
            }
            if (message.userX != null && message.hasOwnProperty("userX")) {
                properties._userX = 1;
                {
                    var error = $root.pl_types.UserX.verify(message.userX);
                    if (error)
                        return "userX." + error;
                }
            }
            return null;
        };

        /**
         * Creates a KnowledgeAndSkill message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pl_types.KnowledgeAndSkill
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pl_types.KnowledgeAndSkill} KnowledgeAndSkill
         */
        KnowledgeAndSkill.fromObject = function fromObject(object) {
            if (object instanceof $root.pl_types.KnowledgeAndSkill)
                return object;
            var message = new $root.pl_types.KnowledgeAndSkill();
            if (object.id != null)
                message.id = object.id | 0;
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "UNSET_TYPE":
            case 0:
                message.type = 0;
                break;
            case "EKS":
            case 1:
                message.type = 1;
                break;
            case "XQ_COMPETENCY":
            case 2:
                message.type = 2;
                break;
            case "CTE":
            case 3:
                message.type = 3;
                break;
            }
            if (object.name != null)
                message.name = String(object.name);
            if (object.category != null)
                message.category = String(object.category);
            if (object.shortDescr != null)
                message.shortDescr = String(object.shortDescr);
            if (object.longDescrHtml != null)
                message.longDescrHtml = String(object.longDescrHtml);
            if (object.global != null)
                message.global = Boolean(object.global);
            if (object.userX != null) {
                if (typeof object.userX !== "object")
                    throw TypeError(".pl_types.KnowledgeAndSkill.userX: object expected");
                message.userX = $root.pl_types.UserX.fromObject(object.userX);
            }
            return message;
        };

        /**
         * Creates a plain object from a KnowledgeAndSkill message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pl_types.KnowledgeAndSkill
         * @static
         * @param {pl_types.KnowledgeAndSkill} message KnowledgeAndSkill
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        KnowledgeAndSkill.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.type != null && message.hasOwnProperty("type")) {
                object.type = options.enums === String ? $root.pl_types.KnowledgeAndSkill.Type[message.type] === undefined ? message.type : $root.pl_types.KnowledgeAndSkill.Type[message.type] : message.type;
                if (options.oneofs)
                    object._type = "type";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            if (message.shortDescr != null && message.hasOwnProperty("shortDescr")) {
                object.shortDescr = message.shortDescr;
                if (options.oneofs)
                    object._shortDescr = "shortDescr";
            }
            if (message.longDescrHtml != null && message.hasOwnProperty("longDescrHtml")) {
                object.longDescrHtml = message.longDescrHtml;
                if (options.oneofs)
                    object._longDescrHtml = "longDescrHtml";
            }
            if (message.category != null && message.hasOwnProperty("category")) {
                object.category = message.category;
                if (options.oneofs)
                    object._category = "category";
            }
            if (message.global != null && message.hasOwnProperty("global")) {
                object.global = message.global;
                if (options.oneofs)
                    object._global = "global";
            }
            if (message.userX != null && message.hasOwnProperty("userX")) {
                object.userX = $root.pl_types.UserX.toObject(message.userX, options);
                if (options.oneofs)
                    object._userX = "userX";
            }
            return object;
        };

        /**
         * Converts this KnowledgeAndSkill to JSON.
         * @function toJSON
         * @memberof pl_types.KnowledgeAndSkill
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        KnowledgeAndSkill.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for KnowledgeAndSkill
         * @function getTypeUrl
         * @memberof pl_types.KnowledgeAndSkill
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        KnowledgeAndSkill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pl_types.KnowledgeAndSkill";
        };

        /**
         * Type enum.
         * @name pl_types.KnowledgeAndSkill.Type
         * @enum {number}
         * @property {number} UNSET_TYPE=0 UNSET_TYPE value
         * @property {number} EKS=1 EKS value
         * @property {number} XQ_COMPETENCY=2 XQ_COMPETENCY value
         * @property {number} CTE=3 CTE value
         */
        KnowledgeAndSkill.Type = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNSET_TYPE"] = 0;
            values[valuesById[1] = "EKS"] = 1;
            values[valuesById[2] = "XQ_COMPETENCY"] = 2;
            values[valuesById[3] = "CTE"] = 3;
            return values;
        })();

        return KnowledgeAndSkill;
    })();

    pl_types.Project = (function() {

        /**
         * Properties of a Project.
         * @memberof pl_types
         * @interface IProject
         * @property {number|null} [id] Project id
         * @property {string|null} [name] Project name
         * @property {string|null} [shortDescr] Project shortDescr
         * @property {string|null} [longDescrHtml] Project longDescrHtml
         * @property {boolean|null} [favorite] Project favorite
         * @property {pl_types.Project.ThumbsState|null} [thumbsState] Project thumbsState
         * @property {string|null} [thumbsStateReason] Project thumbsStateReason
         * @property {boolean|null} [archived] Project archived
         * @property {boolean|null} [active] Project active
         * @property {pl_types.IAssignment|null} [assignment] Project assignment
         * @property {pl_types.IProjectDefinition|null} [projectDefinition] Project projectDefinition
         * @property {string|null} [aiPrompt] Project aiPrompt
         * @property {string|null} [aiResponse] Project aiResponse
         * @property {Array.<pl_types.IProjectPost>|null} [projectPosts] Project projectPosts
         * @property {Array.<pl_types.Project.IMilestone>|null} [milestones] Project milestones
         */

        /**
         * Constructs a new Project.
         * @memberof pl_types
         * @classdesc Represents a Project.
         * @implements IProject
         * @constructor
         * @param {pl_types.IProject=} [properties] Properties to set
         */
        function Project(properties) {
            this.projectPosts = [];
            this.milestones = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Project id.
         * @member {number|null|undefined} id
         * @memberof pl_types.Project
         * @instance
         */
        Project.prototype.id = null;

        /**
         * Project name.
         * @member {string|null|undefined} name
         * @memberof pl_types.Project
         * @instance
         */
        Project.prototype.name = null;

        /**
         * Project shortDescr.
         * @member {string|null|undefined} shortDescr
         * @memberof pl_types.Project
         * @instance
         */
        Project.prototype.shortDescr = null;

        /**
         * Project longDescrHtml.
         * @member {string|null|undefined} longDescrHtml
         * @memberof pl_types.Project
         * @instance
         */
        Project.prototype.longDescrHtml = null;

        /**
         * Project favorite.
         * @member {boolean|null|undefined} favorite
         * @memberof pl_types.Project
         * @instance
         */
        Project.prototype.favorite = null;

        /**
         * Project thumbsState.
         * @member {pl_types.Project.ThumbsState|null|undefined} thumbsState
         * @memberof pl_types.Project
         * @instance
         */
        Project.prototype.thumbsState = null;

        /**
         * Project thumbsStateReason.
         * @member {string|null|undefined} thumbsStateReason
         * @memberof pl_types.Project
         * @instance
         */
        Project.prototype.thumbsStateReason = null;

        /**
         * Project archived.
         * @member {boolean|null|undefined} archived
         * @memberof pl_types.Project
         * @instance
         */
        Project.prototype.archived = null;

        /**
         * Project active.
         * @member {boolean|null|undefined} active
         * @memberof pl_types.Project
         * @instance
         */
        Project.prototype.active = null;

        /**
         * Project assignment.
         * @member {pl_types.IAssignment|null|undefined} assignment
         * @memberof pl_types.Project
         * @instance
         */
        Project.prototype.assignment = null;

        /**
         * Project projectDefinition.
         * @member {pl_types.IProjectDefinition|null|undefined} projectDefinition
         * @memberof pl_types.Project
         * @instance
         */
        Project.prototype.projectDefinition = null;

        /**
         * Project aiPrompt.
         * @member {string|null|undefined} aiPrompt
         * @memberof pl_types.Project
         * @instance
         */
        Project.prototype.aiPrompt = null;

        /**
         * Project aiResponse.
         * @member {string|null|undefined} aiResponse
         * @memberof pl_types.Project
         * @instance
         */
        Project.prototype.aiResponse = null;

        /**
         * Project projectPosts.
         * @member {Array.<pl_types.IProjectPost>} projectPosts
         * @memberof pl_types.Project
         * @instance
         */
        Project.prototype.projectPosts = $util.emptyArray;

        /**
         * Project milestones.
         * @member {Array.<pl_types.Project.IMilestone>} milestones
         * @memberof pl_types.Project
         * @instance
         */
        Project.prototype.milestones = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Project _id.
         * @member {"id"|undefined} _id
         * @memberof pl_types.Project
         * @instance
         */
        Object.defineProperty(Project.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Project _name.
         * @member {"name"|undefined} _name
         * @memberof pl_types.Project
         * @instance
         */
        Object.defineProperty(Project.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Project _shortDescr.
         * @member {"shortDescr"|undefined} _shortDescr
         * @memberof pl_types.Project
         * @instance
         */
        Object.defineProperty(Project.prototype, "_shortDescr", {
            get: $util.oneOfGetter($oneOfFields = ["shortDescr"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Project _longDescrHtml.
         * @member {"longDescrHtml"|undefined} _longDescrHtml
         * @memberof pl_types.Project
         * @instance
         */
        Object.defineProperty(Project.prototype, "_longDescrHtml", {
            get: $util.oneOfGetter($oneOfFields = ["longDescrHtml"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Project _favorite.
         * @member {"favorite"|undefined} _favorite
         * @memberof pl_types.Project
         * @instance
         */
        Object.defineProperty(Project.prototype, "_favorite", {
            get: $util.oneOfGetter($oneOfFields = ["favorite"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Project _thumbsState.
         * @member {"thumbsState"|undefined} _thumbsState
         * @memberof pl_types.Project
         * @instance
         */
        Object.defineProperty(Project.prototype, "_thumbsState", {
            get: $util.oneOfGetter($oneOfFields = ["thumbsState"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Project _thumbsStateReason.
         * @member {"thumbsStateReason"|undefined} _thumbsStateReason
         * @memberof pl_types.Project
         * @instance
         */
        Object.defineProperty(Project.prototype, "_thumbsStateReason", {
            get: $util.oneOfGetter($oneOfFields = ["thumbsStateReason"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Project _archived.
         * @member {"archived"|undefined} _archived
         * @memberof pl_types.Project
         * @instance
         */
        Object.defineProperty(Project.prototype, "_archived", {
            get: $util.oneOfGetter($oneOfFields = ["archived"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Project _active.
         * @member {"active"|undefined} _active
         * @memberof pl_types.Project
         * @instance
         */
        Object.defineProperty(Project.prototype, "_active", {
            get: $util.oneOfGetter($oneOfFields = ["active"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Project _assignment.
         * @member {"assignment"|undefined} _assignment
         * @memberof pl_types.Project
         * @instance
         */
        Object.defineProperty(Project.prototype, "_assignment", {
            get: $util.oneOfGetter($oneOfFields = ["assignment"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Project _projectDefinition.
         * @member {"projectDefinition"|undefined} _projectDefinition
         * @memberof pl_types.Project
         * @instance
         */
        Object.defineProperty(Project.prototype, "_projectDefinition", {
            get: $util.oneOfGetter($oneOfFields = ["projectDefinition"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Project _aiPrompt.
         * @member {"aiPrompt"|undefined} _aiPrompt
         * @memberof pl_types.Project
         * @instance
         */
        Object.defineProperty(Project.prototype, "_aiPrompt", {
            get: $util.oneOfGetter($oneOfFields = ["aiPrompt"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Project _aiResponse.
         * @member {"aiResponse"|undefined} _aiResponse
         * @memberof pl_types.Project
         * @instance
         */
        Object.defineProperty(Project.prototype, "_aiResponse", {
            get: $util.oneOfGetter($oneOfFields = ["aiResponse"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Project instance using the specified properties.
         * @function create
         * @memberof pl_types.Project
         * @static
         * @param {pl_types.IProject=} [properties] Properties to set
         * @returns {pl_types.Project} Project instance
         */
        Project.create = function create(properties) {
            return new Project(properties);
        };

        /**
         * Encodes the specified Project message. Does not implicitly {@link pl_types.Project.verify|verify} messages.
         * @function encode
         * @memberof pl_types.Project
         * @static
         * @param {pl_types.IProject} message Project message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Project.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.shortDescr != null && Object.hasOwnProperty.call(message, "shortDescr"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.shortDescr);
            if (message.longDescrHtml != null && Object.hasOwnProperty.call(message, "longDescrHtml"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.longDescrHtml);
            if (message.favorite != null && Object.hasOwnProperty.call(message, "favorite"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.favorite);
            if (message.thumbsState != null && Object.hasOwnProperty.call(message, "thumbsState"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.thumbsState);
            if (message.thumbsStateReason != null && Object.hasOwnProperty.call(message, "thumbsStateReason"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.thumbsStateReason);
            if (message.archived != null && Object.hasOwnProperty.call(message, "archived"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.archived);
            if (message.active != null && Object.hasOwnProperty.call(message, "active"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.active);
            if (message.assignment != null && Object.hasOwnProperty.call(message, "assignment"))
                $root.pl_types.Assignment.encode(message.assignment, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.milestones != null && message.milestones.length)
                for (var i = 0; i < message.milestones.length; ++i)
                    $root.pl_types.Project.Milestone.encode(message.milestones[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.projectDefinition != null && Object.hasOwnProperty.call(message, "projectDefinition"))
                $root.pl_types.ProjectDefinition.encode(message.projectDefinition, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.aiPrompt != null && Object.hasOwnProperty.call(message, "aiPrompt"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.aiPrompt);
            if (message.aiResponse != null && Object.hasOwnProperty.call(message, "aiResponse"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.aiResponse);
            if (message.projectPosts != null && message.projectPosts.length)
                for (var i = 0; i < message.projectPosts.length; ++i)
                    $root.pl_types.ProjectPost.encode(message.projectPosts[i], writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Project message, length delimited. Does not implicitly {@link pl_types.Project.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pl_types.Project
         * @static
         * @param {pl_types.IProject} message Project message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Project.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Project message from the specified reader or buffer.
         * @function decode
         * @memberof pl_types.Project
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pl_types.Project} Project
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Project.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.Project();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.shortDescr = reader.string();
                        break;
                    }
                case 4: {
                        message.longDescrHtml = reader.string();
                        break;
                    }
                case 5: {
                        message.favorite = reader.bool();
                        break;
                    }
                case 6: {
                        message.thumbsState = reader.int32();
                        break;
                    }
                case 7: {
                        message.thumbsStateReason = reader.string();
                        break;
                    }
                case 8: {
                        message.archived = reader.bool();
                        break;
                    }
                case 10: {
                        message.active = reader.bool();
                        break;
                    }
                case 11: {
                        message.assignment = $root.pl_types.Assignment.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.projectDefinition = $root.pl_types.ProjectDefinition.decode(reader, reader.uint32());
                        break;
                    }
                case 14: {
                        message.aiPrompt = reader.string();
                        break;
                    }
                case 15: {
                        message.aiResponse = reader.string();
                        break;
                    }
                case 16: {
                        if (!(message.projectPosts && message.projectPosts.length))
                            message.projectPosts = [];
                        message.projectPosts.push($root.pl_types.ProjectPost.decode(reader, reader.uint32()));
                        break;
                    }
                case 12: {
                        if (!(message.milestones && message.milestones.length))
                            message.milestones = [];
                        message.milestones.push($root.pl_types.Project.Milestone.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Project message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pl_types.Project
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pl_types.Project} Project
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Project.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Project message.
         * @function verify
         * @memberof pl_types.Project
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Project.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            if (message.shortDescr != null && message.hasOwnProperty("shortDescr")) {
                properties._shortDescr = 1;
                if (!$util.isString(message.shortDescr))
                    return "shortDescr: string expected";
            }
            if (message.longDescrHtml != null && message.hasOwnProperty("longDescrHtml")) {
                properties._longDescrHtml = 1;
                if (!$util.isString(message.longDescrHtml))
                    return "longDescrHtml: string expected";
            }
            if (message.favorite != null && message.hasOwnProperty("favorite")) {
                properties._favorite = 1;
                if (typeof message.favorite !== "boolean")
                    return "favorite: boolean expected";
            }
            if (message.thumbsState != null && message.hasOwnProperty("thumbsState")) {
                properties._thumbsState = 1;
                switch (message.thumbsState) {
                default:
                    return "thumbsState: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            }
            if (message.thumbsStateReason != null && message.hasOwnProperty("thumbsStateReason")) {
                properties._thumbsStateReason = 1;
                if (!$util.isString(message.thumbsStateReason))
                    return "thumbsStateReason: string expected";
            }
            if (message.archived != null && message.hasOwnProperty("archived")) {
                properties._archived = 1;
                if (typeof message.archived !== "boolean")
                    return "archived: boolean expected";
            }
            if (message.active != null && message.hasOwnProperty("active")) {
                properties._active = 1;
                if (typeof message.active !== "boolean")
                    return "active: boolean expected";
            }
            if (message.assignment != null && message.hasOwnProperty("assignment")) {
                properties._assignment = 1;
                {
                    var error = $root.pl_types.Assignment.verify(message.assignment);
                    if (error)
                        return "assignment." + error;
                }
            }
            if (message.projectDefinition != null && message.hasOwnProperty("projectDefinition")) {
                properties._projectDefinition = 1;
                {
                    var error = $root.pl_types.ProjectDefinition.verify(message.projectDefinition);
                    if (error)
                        return "projectDefinition." + error;
                }
            }
            if (message.aiPrompt != null && message.hasOwnProperty("aiPrompt")) {
                properties._aiPrompt = 1;
                if (!$util.isString(message.aiPrompt))
                    return "aiPrompt: string expected";
            }
            if (message.aiResponse != null && message.hasOwnProperty("aiResponse")) {
                properties._aiResponse = 1;
                if (!$util.isString(message.aiResponse))
                    return "aiResponse: string expected";
            }
            if (message.projectPosts != null && message.hasOwnProperty("projectPosts")) {
                if (!Array.isArray(message.projectPosts))
                    return "projectPosts: array expected";
                for (var i = 0; i < message.projectPosts.length; ++i) {
                    var error = $root.pl_types.ProjectPost.verify(message.projectPosts[i]);
                    if (error)
                        return "projectPosts." + error;
                }
            }
            if (message.milestones != null && message.hasOwnProperty("milestones")) {
                if (!Array.isArray(message.milestones))
                    return "milestones: array expected";
                for (var i = 0; i < message.milestones.length; ++i) {
                    var error = $root.pl_types.Project.Milestone.verify(message.milestones[i]);
                    if (error)
                        return "milestones." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Project message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pl_types.Project
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pl_types.Project} Project
         */
        Project.fromObject = function fromObject(object) {
            if (object instanceof $root.pl_types.Project)
                return object;
            var message = new $root.pl_types.Project();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.name != null)
                message.name = String(object.name);
            if (object.shortDescr != null)
                message.shortDescr = String(object.shortDescr);
            if (object.longDescrHtml != null)
                message.longDescrHtml = String(object.longDescrHtml);
            if (object.favorite != null)
                message.favorite = Boolean(object.favorite);
            switch (object.thumbsState) {
            default:
                if (typeof object.thumbsState === "number") {
                    message.thumbsState = object.thumbsState;
                    break;
                }
                break;
            case "UNSET_THUMBS_STATE":
            case 0:
                message.thumbsState = 0;
                break;
            case "THUMBS_UP":
            case 1:
                message.thumbsState = 1;
                break;
            case "THUMBS_DOWN":
            case 2:
                message.thumbsState = 2;
                break;
            }
            if (object.thumbsStateReason != null)
                message.thumbsStateReason = String(object.thumbsStateReason);
            if (object.archived != null)
                message.archived = Boolean(object.archived);
            if (object.active != null)
                message.active = Boolean(object.active);
            if (object.assignment != null) {
                if (typeof object.assignment !== "object")
                    throw TypeError(".pl_types.Project.assignment: object expected");
                message.assignment = $root.pl_types.Assignment.fromObject(object.assignment);
            }
            if (object.projectDefinition != null) {
                if (typeof object.projectDefinition !== "object")
                    throw TypeError(".pl_types.Project.projectDefinition: object expected");
                message.projectDefinition = $root.pl_types.ProjectDefinition.fromObject(object.projectDefinition);
            }
            if (object.aiPrompt != null)
                message.aiPrompt = String(object.aiPrompt);
            if (object.aiResponse != null)
                message.aiResponse = String(object.aiResponse);
            if (object.projectPosts) {
                if (!Array.isArray(object.projectPosts))
                    throw TypeError(".pl_types.Project.projectPosts: array expected");
                message.projectPosts = [];
                for (var i = 0; i < object.projectPosts.length; ++i) {
                    if (typeof object.projectPosts[i] !== "object")
                        throw TypeError(".pl_types.Project.projectPosts: object expected");
                    message.projectPosts[i] = $root.pl_types.ProjectPost.fromObject(object.projectPosts[i]);
                }
            }
            if (object.milestones) {
                if (!Array.isArray(object.milestones))
                    throw TypeError(".pl_types.Project.milestones: array expected");
                message.milestones = [];
                for (var i = 0; i < object.milestones.length; ++i) {
                    if (typeof object.milestones[i] !== "object")
                        throw TypeError(".pl_types.Project.milestones: object expected");
                    message.milestones[i] = $root.pl_types.Project.Milestone.fromObject(object.milestones[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Project message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pl_types.Project
         * @static
         * @param {pl_types.Project} message Project
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Project.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.milestones = [];
                object.projectPosts = [];
            }
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            if (message.shortDescr != null && message.hasOwnProperty("shortDescr")) {
                object.shortDescr = message.shortDescr;
                if (options.oneofs)
                    object._shortDescr = "shortDescr";
            }
            if (message.longDescrHtml != null && message.hasOwnProperty("longDescrHtml")) {
                object.longDescrHtml = message.longDescrHtml;
                if (options.oneofs)
                    object._longDescrHtml = "longDescrHtml";
            }
            if (message.favorite != null && message.hasOwnProperty("favorite")) {
                object.favorite = message.favorite;
                if (options.oneofs)
                    object._favorite = "favorite";
            }
            if (message.thumbsState != null && message.hasOwnProperty("thumbsState")) {
                object.thumbsState = options.enums === String ? $root.pl_types.Project.ThumbsState[message.thumbsState] === undefined ? message.thumbsState : $root.pl_types.Project.ThumbsState[message.thumbsState] : message.thumbsState;
                if (options.oneofs)
                    object._thumbsState = "thumbsState";
            }
            if (message.thumbsStateReason != null && message.hasOwnProperty("thumbsStateReason")) {
                object.thumbsStateReason = message.thumbsStateReason;
                if (options.oneofs)
                    object._thumbsStateReason = "thumbsStateReason";
            }
            if (message.archived != null && message.hasOwnProperty("archived")) {
                object.archived = message.archived;
                if (options.oneofs)
                    object._archived = "archived";
            }
            if (message.active != null && message.hasOwnProperty("active")) {
                object.active = message.active;
                if (options.oneofs)
                    object._active = "active";
            }
            if (message.assignment != null && message.hasOwnProperty("assignment")) {
                object.assignment = $root.pl_types.Assignment.toObject(message.assignment, options);
                if (options.oneofs)
                    object._assignment = "assignment";
            }
            if (message.milestones && message.milestones.length) {
                object.milestones = [];
                for (var j = 0; j < message.milestones.length; ++j)
                    object.milestones[j] = $root.pl_types.Project.Milestone.toObject(message.milestones[j], options);
            }
            if (message.projectDefinition != null && message.hasOwnProperty("projectDefinition")) {
                object.projectDefinition = $root.pl_types.ProjectDefinition.toObject(message.projectDefinition, options);
                if (options.oneofs)
                    object._projectDefinition = "projectDefinition";
            }
            if (message.aiPrompt != null && message.hasOwnProperty("aiPrompt")) {
                object.aiPrompt = message.aiPrompt;
                if (options.oneofs)
                    object._aiPrompt = "aiPrompt";
            }
            if (message.aiResponse != null && message.hasOwnProperty("aiResponse")) {
                object.aiResponse = message.aiResponse;
                if (options.oneofs)
                    object._aiResponse = "aiResponse";
            }
            if (message.projectPosts && message.projectPosts.length) {
                object.projectPosts = [];
                for (var j = 0; j < message.projectPosts.length; ++j)
                    object.projectPosts[j] = $root.pl_types.ProjectPost.toObject(message.projectPosts[j], options);
            }
            return object;
        };

        /**
         * Converts this Project to JSON.
         * @function toJSON
         * @memberof pl_types.Project
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Project.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Project
         * @function getTypeUrl
         * @memberof pl_types.Project
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Project.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pl_types.Project";
        };

        /**
         * ThumbsState enum.
         * @name pl_types.Project.ThumbsState
         * @enum {number}
         * @property {number} UNSET_THUMBS_STATE=0 UNSET_THUMBS_STATE value
         * @property {number} THUMBS_UP=1 THUMBS_UP value
         * @property {number} THUMBS_DOWN=2 THUMBS_DOWN value
         */
        Project.ThumbsState = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNSET_THUMBS_STATE"] = 0;
            values[valuesById[1] = "THUMBS_UP"] = 1;
            values[valuesById[2] = "THUMBS_DOWN"] = 2;
            return values;
        })();

        Project.Milestone = (function() {

            /**
             * Properties of a Milestone.
             * @memberof pl_types.Project
             * @interface IMilestone
             * @property {number|null} [id] Milestone id
             * @property {string|null} [name] Milestone name
             * @property {Array.<pl_types.Project.Milestone.IStep>|null} [steps] Milestone steps
             */

            /**
             * Constructs a new Milestone.
             * @memberof pl_types.Project
             * @classdesc Represents a Milestone.
             * @implements IMilestone
             * @constructor
             * @param {pl_types.Project.IMilestone=} [properties] Properties to set
             */
            function Milestone(properties) {
                this.steps = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Milestone id.
             * @member {number|null|undefined} id
             * @memberof pl_types.Project.Milestone
             * @instance
             */
            Milestone.prototype.id = null;

            /**
             * Milestone name.
             * @member {string|null|undefined} name
             * @memberof pl_types.Project.Milestone
             * @instance
             */
            Milestone.prototype.name = null;

            /**
             * Milestone steps.
             * @member {Array.<pl_types.Project.Milestone.IStep>} steps
             * @memberof pl_types.Project.Milestone
             * @instance
             */
            Milestone.prototype.steps = $util.emptyArray;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * Milestone _id.
             * @member {"id"|undefined} _id
             * @memberof pl_types.Project.Milestone
             * @instance
             */
            Object.defineProperty(Milestone.prototype, "_id", {
                get: $util.oneOfGetter($oneOfFields = ["id"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Milestone _name.
             * @member {"name"|undefined} _name
             * @memberof pl_types.Project.Milestone
             * @instance
             */
            Object.defineProperty(Milestone.prototype, "_name", {
                get: $util.oneOfGetter($oneOfFields = ["name"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new Milestone instance using the specified properties.
             * @function create
             * @memberof pl_types.Project.Milestone
             * @static
             * @param {pl_types.Project.IMilestone=} [properties] Properties to set
             * @returns {pl_types.Project.Milestone} Milestone instance
             */
            Milestone.create = function create(properties) {
                return new Milestone(properties);
            };

            /**
             * Encodes the specified Milestone message. Does not implicitly {@link pl_types.Project.Milestone.verify|verify} messages.
             * @function encode
             * @memberof pl_types.Project.Milestone
             * @static
             * @param {pl_types.Project.IMilestone} message Milestone message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Milestone.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.steps != null && message.steps.length)
                    for (var i = 0; i < message.steps.length; ++i)
                        $root.pl_types.Project.Milestone.Step.encode(message.steps[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Milestone message, length delimited. Does not implicitly {@link pl_types.Project.Milestone.verify|verify} messages.
             * @function encodeDelimited
             * @memberof pl_types.Project.Milestone
             * @static
             * @param {pl_types.Project.IMilestone} message Milestone message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Milestone.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Milestone message from the specified reader or buffer.
             * @function decode
             * @memberof pl_types.Project.Milestone
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {pl_types.Project.Milestone} Milestone
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Milestone.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.Project.Milestone();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            if (!(message.steps && message.steps.length))
                                message.steps = [];
                            message.steps.push($root.pl_types.Project.Milestone.Step.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Milestone message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof pl_types.Project.Milestone
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {pl_types.Project.Milestone} Milestone
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Milestone.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Milestone message.
             * @function verify
             * @memberof pl_types.Project.Milestone
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Milestone.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.id != null && message.hasOwnProperty("id")) {
                    properties._id = 1;
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                }
                if (message.name != null && message.hasOwnProperty("name")) {
                    properties._name = 1;
                    if (!$util.isString(message.name))
                        return "name: string expected";
                }
                if (message.steps != null && message.hasOwnProperty("steps")) {
                    if (!Array.isArray(message.steps))
                        return "steps: array expected";
                    for (var i = 0; i < message.steps.length; ++i) {
                        var error = $root.pl_types.Project.Milestone.Step.verify(message.steps[i]);
                        if (error)
                            return "steps." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Milestone message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof pl_types.Project.Milestone
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {pl_types.Project.Milestone} Milestone
             */
            Milestone.fromObject = function fromObject(object) {
                if (object instanceof $root.pl_types.Project.Milestone)
                    return object;
                var message = new $root.pl_types.Project.Milestone();
                if (object.id != null)
                    message.id = object.id | 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.steps) {
                    if (!Array.isArray(object.steps))
                        throw TypeError(".pl_types.Project.Milestone.steps: array expected");
                    message.steps = [];
                    for (var i = 0; i < object.steps.length; ++i) {
                        if (typeof object.steps[i] !== "object")
                            throw TypeError(".pl_types.Project.Milestone.steps: object expected");
                        message.steps[i] = $root.pl_types.Project.Milestone.Step.fromObject(object.steps[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a Milestone message. Also converts values to other types if specified.
             * @function toObject
             * @memberof pl_types.Project.Milestone
             * @static
             * @param {pl_types.Project.Milestone} message Milestone
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Milestone.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.steps = [];
                if (message.id != null && message.hasOwnProperty("id")) {
                    object.id = message.id;
                    if (options.oneofs)
                        object._id = "id";
                }
                if (message.name != null && message.hasOwnProperty("name")) {
                    object.name = message.name;
                    if (options.oneofs)
                        object._name = "name";
                }
                if (message.steps && message.steps.length) {
                    object.steps = [];
                    for (var j = 0; j < message.steps.length; ++j)
                        object.steps[j] = $root.pl_types.Project.Milestone.Step.toObject(message.steps[j], options);
                }
                return object;
            };

            /**
             * Converts this Milestone to JSON.
             * @function toJSON
             * @memberof pl_types.Project.Milestone
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Milestone.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Milestone
             * @function getTypeUrl
             * @memberof pl_types.Project.Milestone
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Milestone.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/pl_types.Project.Milestone";
            };

            Milestone.Step = (function() {

                /**
                 * Properties of a Step.
                 * @memberof pl_types.Project.Milestone
                 * @interface IStep
                 * @property {number|null} [id] Step id
                 * @property {string|null} [name] Step name
                 */

                /**
                 * Constructs a new Step.
                 * @memberof pl_types.Project.Milestone
                 * @classdesc Represents a Step.
                 * @implements IStep
                 * @constructor
                 * @param {pl_types.Project.Milestone.IStep=} [properties] Properties to set
                 */
                function Step(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Step id.
                 * @member {number|null|undefined} id
                 * @memberof pl_types.Project.Milestone.Step
                 * @instance
                 */
                Step.prototype.id = null;

                /**
                 * Step name.
                 * @member {string|null|undefined} name
                 * @memberof pl_types.Project.Milestone.Step
                 * @instance
                 */
                Step.prototype.name = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Step _id.
                 * @member {"id"|undefined} _id
                 * @memberof pl_types.Project.Milestone.Step
                 * @instance
                 */
                Object.defineProperty(Step.prototype, "_id", {
                    get: $util.oneOfGetter($oneOfFields = ["id"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Step _name.
                 * @member {"name"|undefined} _name
                 * @memberof pl_types.Project.Milestone.Step
                 * @instance
                 */
                Object.defineProperty(Step.prototype, "_name", {
                    get: $util.oneOfGetter($oneOfFields = ["name"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Step instance using the specified properties.
                 * @function create
                 * @memberof pl_types.Project.Milestone.Step
                 * @static
                 * @param {pl_types.Project.Milestone.IStep=} [properties] Properties to set
                 * @returns {pl_types.Project.Milestone.Step} Step instance
                 */
                Step.create = function create(properties) {
                    return new Step(properties);
                };

                /**
                 * Encodes the specified Step message. Does not implicitly {@link pl_types.Project.Milestone.Step.verify|verify} messages.
                 * @function encode
                 * @memberof pl_types.Project.Milestone.Step
                 * @static
                 * @param {pl_types.Project.Milestone.IStep} message Step message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Step.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    return writer;
                };

                /**
                 * Encodes the specified Step message, length delimited. Does not implicitly {@link pl_types.Project.Milestone.Step.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof pl_types.Project.Milestone.Step
                 * @static
                 * @param {pl_types.Project.Milestone.IStep} message Step message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Step.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Step message from the specified reader or buffer.
                 * @function decode
                 * @memberof pl_types.Project.Milestone.Step
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {pl_types.Project.Milestone.Step} Step
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Step.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.Project.Milestone.Step();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.int32();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Step message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof pl_types.Project.Milestone.Step
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {pl_types.Project.Milestone.Step} Step
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Step.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Step message.
                 * @function verify
                 * @memberof pl_types.Project.Milestone.Step
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Step.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.id != null && message.hasOwnProperty("id")) {
                        properties._id = 1;
                        if (!$util.isInteger(message.id))
                            return "id: integer expected";
                    }
                    if (message.name != null && message.hasOwnProperty("name")) {
                        properties._name = 1;
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    }
                    return null;
                };

                /**
                 * Creates a Step message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof pl_types.Project.Milestone.Step
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {pl_types.Project.Milestone.Step} Step
                 */
                Step.fromObject = function fromObject(object) {
                    if (object instanceof $root.pl_types.Project.Milestone.Step)
                        return object;
                    var message = new $root.pl_types.Project.Milestone.Step();
                    if (object.id != null)
                        message.id = object.id | 0;
                    if (object.name != null)
                        message.name = String(object.name);
                    return message;
                };

                /**
                 * Creates a plain object from a Step message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof pl_types.Project.Milestone.Step
                 * @static
                 * @param {pl_types.Project.Milestone.Step} message Step
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Step.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.id != null && message.hasOwnProperty("id")) {
                        object.id = message.id;
                        if (options.oneofs)
                            object._id = "id";
                    }
                    if (message.name != null && message.hasOwnProperty("name")) {
                        object.name = message.name;
                        if (options.oneofs)
                            object._name = "name";
                    }
                    return object;
                };

                /**
                 * Converts this Step to JSON.
                 * @function toJSON
                 * @memberof pl_types.Project.Milestone.Step
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Step.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Step
                 * @function getTypeUrl
                 * @memberof pl_types.Project.Milestone.Step
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Step.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/pl_types.Project.Milestone.Step";
                };

                return Step;
            })();

            return Milestone;
        })();

        return Project;
    })();

    pl_types.School = (function() {

        /**
         * Properties of a School.
         * @memberof pl_types
         * @interface ISchool
         * @property {number|null} [id] School id
         * @property {pl_types.IDistrict|null} [district] School district
         * @property {string|null} [name] School name
         * @property {string|null} [nickname] School nickname
         * @property {string|null} [address] School address
         */

        /**
         * Constructs a new School.
         * @memberof pl_types
         * @classdesc Represents a School.
         * @implements ISchool
         * @constructor
         * @param {pl_types.ISchool=} [properties] Properties to set
         */
        function School(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * School id.
         * @member {number|null|undefined} id
         * @memberof pl_types.School
         * @instance
         */
        School.prototype.id = null;

        /**
         * School district.
         * @member {pl_types.IDistrict|null|undefined} district
         * @memberof pl_types.School
         * @instance
         */
        School.prototype.district = null;

        /**
         * School name.
         * @member {string|null|undefined} name
         * @memberof pl_types.School
         * @instance
         */
        School.prototype.name = null;

        /**
         * School nickname.
         * @member {string|null|undefined} nickname
         * @memberof pl_types.School
         * @instance
         */
        School.prototype.nickname = null;

        /**
         * School address.
         * @member {string|null|undefined} address
         * @memberof pl_types.School
         * @instance
         */
        School.prototype.address = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * School _id.
         * @member {"id"|undefined} _id
         * @memberof pl_types.School
         * @instance
         */
        Object.defineProperty(School.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * School _district.
         * @member {"district"|undefined} _district
         * @memberof pl_types.School
         * @instance
         */
        Object.defineProperty(School.prototype, "_district", {
            get: $util.oneOfGetter($oneOfFields = ["district"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * School _name.
         * @member {"name"|undefined} _name
         * @memberof pl_types.School
         * @instance
         */
        Object.defineProperty(School.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * School _nickname.
         * @member {"nickname"|undefined} _nickname
         * @memberof pl_types.School
         * @instance
         */
        Object.defineProperty(School.prototype, "_nickname", {
            get: $util.oneOfGetter($oneOfFields = ["nickname"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * School _address.
         * @member {"address"|undefined} _address
         * @memberof pl_types.School
         * @instance
         */
        Object.defineProperty(School.prototype, "_address", {
            get: $util.oneOfGetter($oneOfFields = ["address"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new School instance using the specified properties.
         * @function create
         * @memberof pl_types.School
         * @static
         * @param {pl_types.ISchool=} [properties] Properties to set
         * @returns {pl_types.School} School instance
         */
        School.create = function create(properties) {
            return new School(properties);
        };

        /**
         * Encodes the specified School message. Does not implicitly {@link pl_types.School.verify|verify} messages.
         * @function encode
         * @memberof pl_types.School
         * @static
         * @param {pl_types.ISchool} message School message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        School.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.nickname != null && Object.hasOwnProperty.call(message, "nickname"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.nickname);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.address);
            if (message.district != null && Object.hasOwnProperty.call(message, "district"))
                $root.pl_types.District.encode(message.district, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified School message, length delimited. Does not implicitly {@link pl_types.School.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pl_types.School
         * @static
         * @param {pl_types.ISchool} message School message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        School.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a School message from the specified reader or buffer.
         * @function decode
         * @memberof pl_types.School
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pl_types.School} School
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        School.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.School();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 6: {
                        message.district = $root.pl_types.District.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.nickname = reader.string();
                        break;
                    }
                case 5: {
                        message.address = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a School message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pl_types.School
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pl_types.School} School
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        School.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a School message.
         * @function verify
         * @memberof pl_types.School
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        School.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            }
            if (message.district != null && message.hasOwnProperty("district")) {
                properties._district = 1;
                {
                    var error = $root.pl_types.District.verify(message.district);
                    if (error)
                        return "district." + error;
                }
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            if (message.nickname != null && message.hasOwnProperty("nickname")) {
                properties._nickname = 1;
                if (!$util.isString(message.nickname))
                    return "nickname: string expected";
            }
            if (message.address != null && message.hasOwnProperty("address")) {
                properties._address = 1;
                if (!$util.isString(message.address))
                    return "address: string expected";
            }
            return null;
        };

        /**
         * Creates a School message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pl_types.School
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pl_types.School} School
         */
        School.fromObject = function fromObject(object) {
            if (object instanceof $root.pl_types.School)
                return object;
            var message = new $root.pl_types.School();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.district != null) {
                if (typeof object.district !== "object")
                    throw TypeError(".pl_types.School.district: object expected");
                message.district = $root.pl_types.District.fromObject(object.district);
            }
            if (object.name != null)
                message.name = String(object.name);
            if (object.nickname != null)
                message.nickname = String(object.nickname);
            if (object.address != null)
                message.address = String(object.address);
            return message;
        };

        /**
         * Creates a plain object from a School message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pl_types.School
         * @static
         * @param {pl_types.School} message School
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        School.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            if (message.nickname != null && message.hasOwnProperty("nickname")) {
                object.nickname = message.nickname;
                if (options.oneofs)
                    object._nickname = "nickname";
            }
            if (message.address != null && message.hasOwnProperty("address")) {
                object.address = message.address;
                if (options.oneofs)
                    object._address = "address";
            }
            if (message.district != null && message.hasOwnProperty("district")) {
                object.district = $root.pl_types.District.toObject(message.district, options);
                if (options.oneofs)
                    object._district = "district";
            }
            return object;
        };

        /**
         * Converts this School to JSON.
         * @function toJSON
         * @memberof pl_types.School
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        School.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for School
         * @function getTypeUrl
         * @memberof pl_types.School
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        School.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pl_types.School";
        };

        return School;
    })();

    pl_types.UserX = (function() {

        /**
         * Properties of a UserX.
         * @memberof pl_types
         * @interface IUserX
         * @property {number|null} [id] UserX id
         * @property {number|null} [districtId] UserX districtId
         * @property {string|null} [firstName] UserX firstName
         * @property {string|null} [lastName] UserX lastName
         * @property {string|null} [emailAddress] UserX emailAddress
         * @property {string|null} [avatarImageUrl] UserX avatarImageUrl
         * @property {boolean|null} [isAdminX] UserX isAdminX
         * @property {boolean|null} [isCrossDistrictAdminX] UserX isCrossDistrictAdminX
         * @property {number|null} [adminXId] UserX adminXId
         * @property {boolean|null} [isTeacher] UserX isTeacher
         * @property {number|null} [teacherId] UserX teacherId
         * @property {boolean|null} [isStudent] UserX isStudent
         * @property {number|null} [studentId] UserX studentId
         * @property {boolean|null} [isDemo] UserX isDemo
         * @property {boolean|null} [isAuthenticated] UserX isAuthenticated
         * @property {boolean|null} [viewAiPrompts] UserX viewAiPrompts
         * @property {boolean|null} [viewIds] UserX viewIds
         */

        /**
         * Constructs a new UserX.
         * @memberof pl_types
         * @classdesc Represents a UserX.
         * @implements IUserX
         * @constructor
         * @param {pl_types.IUserX=} [properties] Properties to set
         */
        function UserX(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserX id.
         * @member {number|null|undefined} id
         * @memberof pl_types.UserX
         * @instance
         */
        UserX.prototype.id = null;

        /**
         * UserX districtId.
         * @member {number|null|undefined} districtId
         * @memberof pl_types.UserX
         * @instance
         */
        UserX.prototype.districtId = null;

        /**
         * UserX firstName.
         * @member {string|null|undefined} firstName
         * @memberof pl_types.UserX
         * @instance
         */
        UserX.prototype.firstName = null;

        /**
         * UserX lastName.
         * @member {string|null|undefined} lastName
         * @memberof pl_types.UserX
         * @instance
         */
        UserX.prototype.lastName = null;

        /**
         * UserX emailAddress.
         * @member {string|null|undefined} emailAddress
         * @memberof pl_types.UserX
         * @instance
         */
        UserX.prototype.emailAddress = null;

        /**
         * UserX avatarImageUrl.
         * @member {string|null|undefined} avatarImageUrl
         * @memberof pl_types.UserX
         * @instance
         */
        UserX.prototype.avatarImageUrl = null;

        /**
         * UserX isAdminX.
         * @member {boolean|null|undefined} isAdminX
         * @memberof pl_types.UserX
         * @instance
         */
        UserX.prototype.isAdminX = null;

        /**
         * UserX isCrossDistrictAdminX.
         * @member {boolean|null|undefined} isCrossDistrictAdminX
         * @memberof pl_types.UserX
         * @instance
         */
        UserX.prototype.isCrossDistrictAdminX = null;

        /**
         * UserX adminXId.
         * @member {number|null|undefined} adminXId
         * @memberof pl_types.UserX
         * @instance
         */
        UserX.prototype.adminXId = null;

        /**
         * UserX isTeacher.
         * @member {boolean|null|undefined} isTeacher
         * @memberof pl_types.UserX
         * @instance
         */
        UserX.prototype.isTeacher = null;

        /**
         * UserX teacherId.
         * @member {number|null|undefined} teacherId
         * @memberof pl_types.UserX
         * @instance
         */
        UserX.prototype.teacherId = null;

        /**
         * UserX isStudent.
         * @member {boolean|null|undefined} isStudent
         * @memberof pl_types.UserX
         * @instance
         */
        UserX.prototype.isStudent = null;

        /**
         * UserX studentId.
         * @member {number|null|undefined} studentId
         * @memberof pl_types.UserX
         * @instance
         */
        UserX.prototype.studentId = null;

        /**
         * UserX isDemo.
         * @member {boolean|null|undefined} isDemo
         * @memberof pl_types.UserX
         * @instance
         */
        UserX.prototype.isDemo = null;

        /**
         * UserX isAuthenticated.
         * @member {boolean|null|undefined} isAuthenticated
         * @memberof pl_types.UserX
         * @instance
         */
        UserX.prototype.isAuthenticated = null;

        /**
         * UserX viewAiPrompts.
         * @member {boolean|null|undefined} viewAiPrompts
         * @memberof pl_types.UserX
         * @instance
         */
        UserX.prototype.viewAiPrompts = null;

        /**
         * UserX viewIds.
         * @member {boolean|null|undefined} viewIds
         * @memberof pl_types.UserX
         * @instance
         */
        UserX.prototype.viewIds = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UserX _id.
         * @member {"id"|undefined} _id
         * @memberof pl_types.UserX
         * @instance
         */
        Object.defineProperty(UserX.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserX _districtId.
         * @member {"districtId"|undefined} _districtId
         * @memberof pl_types.UserX
         * @instance
         */
        Object.defineProperty(UserX.prototype, "_districtId", {
            get: $util.oneOfGetter($oneOfFields = ["districtId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserX _firstName.
         * @member {"firstName"|undefined} _firstName
         * @memberof pl_types.UserX
         * @instance
         */
        Object.defineProperty(UserX.prototype, "_firstName", {
            get: $util.oneOfGetter($oneOfFields = ["firstName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserX _lastName.
         * @member {"lastName"|undefined} _lastName
         * @memberof pl_types.UserX
         * @instance
         */
        Object.defineProperty(UserX.prototype, "_lastName", {
            get: $util.oneOfGetter($oneOfFields = ["lastName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserX _emailAddress.
         * @member {"emailAddress"|undefined} _emailAddress
         * @memberof pl_types.UserX
         * @instance
         */
        Object.defineProperty(UserX.prototype, "_emailAddress", {
            get: $util.oneOfGetter($oneOfFields = ["emailAddress"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserX _avatarImageUrl.
         * @member {"avatarImageUrl"|undefined} _avatarImageUrl
         * @memberof pl_types.UserX
         * @instance
         */
        Object.defineProperty(UserX.prototype, "_avatarImageUrl", {
            get: $util.oneOfGetter($oneOfFields = ["avatarImageUrl"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserX _isAdminX.
         * @member {"isAdminX"|undefined} _isAdminX
         * @memberof pl_types.UserX
         * @instance
         */
        Object.defineProperty(UserX.prototype, "_isAdminX", {
            get: $util.oneOfGetter($oneOfFields = ["isAdminX"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserX _isCrossDistrictAdminX.
         * @member {"isCrossDistrictAdminX"|undefined} _isCrossDistrictAdminX
         * @memberof pl_types.UserX
         * @instance
         */
        Object.defineProperty(UserX.prototype, "_isCrossDistrictAdminX", {
            get: $util.oneOfGetter($oneOfFields = ["isCrossDistrictAdminX"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserX _adminXId.
         * @member {"adminXId"|undefined} _adminXId
         * @memberof pl_types.UserX
         * @instance
         */
        Object.defineProperty(UserX.prototype, "_adminXId", {
            get: $util.oneOfGetter($oneOfFields = ["adminXId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserX _isTeacher.
         * @member {"isTeacher"|undefined} _isTeacher
         * @memberof pl_types.UserX
         * @instance
         */
        Object.defineProperty(UserX.prototype, "_isTeacher", {
            get: $util.oneOfGetter($oneOfFields = ["isTeacher"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserX _teacherId.
         * @member {"teacherId"|undefined} _teacherId
         * @memberof pl_types.UserX
         * @instance
         */
        Object.defineProperty(UserX.prototype, "_teacherId", {
            get: $util.oneOfGetter($oneOfFields = ["teacherId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserX _isStudent.
         * @member {"isStudent"|undefined} _isStudent
         * @memberof pl_types.UserX
         * @instance
         */
        Object.defineProperty(UserX.prototype, "_isStudent", {
            get: $util.oneOfGetter($oneOfFields = ["isStudent"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserX _studentId.
         * @member {"studentId"|undefined} _studentId
         * @memberof pl_types.UserX
         * @instance
         */
        Object.defineProperty(UserX.prototype, "_studentId", {
            get: $util.oneOfGetter($oneOfFields = ["studentId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserX _isDemo.
         * @member {"isDemo"|undefined} _isDemo
         * @memberof pl_types.UserX
         * @instance
         */
        Object.defineProperty(UserX.prototype, "_isDemo", {
            get: $util.oneOfGetter($oneOfFields = ["isDemo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserX _isAuthenticated.
         * @member {"isAuthenticated"|undefined} _isAuthenticated
         * @memberof pl_types.UserX
         * @instance
         */
        Object.defineProperty(UserX.prototype, "_isAuthenticated", {
            get: $util.oneOfGetter($oneOfFields = ["isAuthenticated"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserX _viewAiPrompts.
         * @member {"viewAiPrompts"|undefined} _viewAiPrompts
         * @memberof pl_types.UserX
         * @instance
         */
        Object.defineProperty(UserX.prototype, "_viewAiPrompts", {
            get: $util.oneOfGetter($oneOfFields = ["viewAiPrompts"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserX _viewIds.
         * @member {"viewIds"|undefined} _viewIds
         * @memberof pl_types.UserX
         * @instance
         */
        Object.defineProperty(UserX.prototype, "_viewIds", {
            get: $util.oneOfGetter($oneOfFields = ["viewIds"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UserX instance using the specified properties.
         * @function create
         * @memberof pl_types.UserX
         * @static
         * @param {pl_types.IUserX=} [properties] Properties to set
         * @returns {pl_types.UserX} UserX instance
         */
        UserX.create = function create(properties) {
            return new UserX(properties);
        };

        /**
         * Encodes the specified UserX message. Does not implicitly {@link pl_types.UserX.verify|verify} messages.
         * @function encode
         * @memberof pl_types.UserX
         * @static
         * @param {pl_types.IUserX} message UserX message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserX.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.districtId != null && Object.hasOwnProperty.call(message, "districtId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.districtId);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.emailAddress);
            if (message.isAdminX != null && Object.hasOwnProperty.call(message, "isAdminX"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isAdminX);
            if (message.adminXId != null && Object.hasOwnProperty.call(message, "adminXId"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.adminXId);
            if (message.isTeacher != null && Object.hasOwnProperty.call(message, "isTeacher"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isTeacher);
            if (message.teacherId != null && Object.hasOwnProperty.call(message, "teacherId"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.teacherId);
            if (message.isStudent != null && Object.hasOwnProperty.call(message, "isStudent"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isStudent);
            if (message.studentId != null && Object.hasOwnProperty.call(message, "studentId"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.studentId);
            if (message.isDemo != null && Object.hasOwnProperty.call(message, "isDemo"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.isDemo);
            if (message.isAuthenticated != null && Object.hasOwnProperty.call(message, "isAuthenticated"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.isAuthenticated);
            if (message.avatarImageUrl != null && Object.hasOwnProperty.call(message, "avatarImageUrl"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.avatarImageUrl);
            if (message.isCrossDistrictAdminX != null && Object.hasOwnProperty.call(message, "isCrossDistrictAdminX"))
                writer.uint32(/* id 15, wireType 0 =*/120).bool(message.isCrossDistrictAdminX);
            if (message.viewAiPrompts != null && Object.hasOwnProperty.call(message, "viewAiPrompts"))
                writer.uint32(/* id 16, wireType 0 =*/128).bool(message.viewAiPrompts);
            if (message.viewIds != null && Object.hasOwnProperty.call(message, "viewIds"))
                writer.uint32(/* id 17, wireType 0 =*/136).bool(message.viewIds);
            return writer;
        };

        /**
         * Encodes the specified UserX message, length delimited. Does not implicitly {@link pl_types.UserX.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pl_types.UserX
         * @static
         * @param {pl_types.IUserX} message UserX message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserX.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserX message from the specified reader or buffer.
         * @function decode
         * @memberof pl_types.UserX
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pl_types.UserX} UserX
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserX.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.UserX();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.districtId = reader.int32();
                        break;
                    }
                case 3: {
                        message.firstName = reader.string();
                        break;
                    }
                case 4: {
                        message.lastName = reader.string();
                        break;
                    }
                case 5: {
                        message.emailAddress = reader.string();
                        break;
                    }
                case 14: {
                        message.avatarImageUrl = reader.string();
                        break;
                    }
                case 6: {
                        message.isAdminX = reader.bool();
                        break;
                    }
                case 15: {
                        message.isCrossDistrictAdminX = reader.bool();
                        break;
                    }
                case 7: {
                        message.adminXId = reader.int32();
                        break;
                    }
                case 8: {
                        message.isTeacher = reader.bool();
                        break;
                    }
                case 9: {
                        message.teacherId = reader.int32();
                        break;
                    }
                case 10: {
                        message.isStudent = reader.bool();
                        break;
                    }
                case 11: {
                        message.studentId = reader.int32();
                        break;
                    }
                case 12: {
                        message.isDemo = reader.bool();
                        break;
                    }
                case 13: {
                        message.isAuthenticated = reader.bool();
                        break;
                    }
                case 16: {
                        message.viewAiPrompts = reader.bool();
                        break;
                    }
                case 17: {
                        message.viewIds = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserX message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pl_types.UserX
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pl_types.UserX} UserX
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserX.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserX message.
         * @function verify
         * @memberof pl_types.UserX
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserX.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            }
            if (message.districtId != null && message.hasOwnProperty("districtId")) {
                properties._districtId = 1;
                if (!$util.isInteger(message.districtId))
                    return "districtId: integer expected";
            }
            if (message.firstName != null && message.hasOwnProperty("firstName")) {
                properties._firstName = 1;
                if (!$util.isString(message.firstName))
                    return "firstName: string expected";
            }
            if (message.lastName != null && message.hasOwnProperty("lastName")) {
                properties._lastName = 1;
                if (!$util.isString(message.lastName))
                    return "lastName: string expected";
            }
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress")) {
                properties._emailAddress = 1;
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            }
            if (message.avatarImageUrl != null && message.hasOwnProperty("avatarImageUrl")) {
                properties._avatarImageUrl = 1;
                if (!$util.isString(message.avatarImageUrl))
                    return "avatarImageUrl: string expected";
            }
            if (message.isAdminX != null && message.hasOwnProperty("isAdminX")) {
                properties._isAdminX = 1;
                if (typeof message.isAdminX !== "boolean")
                    return "isAdminX: boolean expected";
            }
            if (message.isCrossDistrictAdminX != null && message.hasOwnProperty("isCrossDistrictAdminX")) {
                properties._isCrossDistrictAdminX = 1;
                if (typeof message.isCrossDistrictAdminX !== "boolean")
                    return "isCrossDistrictAdminX: boolean expected";
            }
            if (message.adminXId != null && message.hasOwnProperty("adminXId")) {
                properties._adminXId = 1;
                if (!$util.isInteger(message.adminXId))
                    return "adminXId: integer expected";
            }
            if (message.isTeacher != null && message.hasOwnProperty("isTeacher")) {
                properties._isTeacher = 1;
                if (typeof message.isTeacher !== "boolean")
                    return "isTeacher: boolean expected";
            }
            if (message.teacherId != null && message.hasOwnProperty("teacherId")) {
                properties._teacherId = 1;
                if (!$util.isInteger(message.teacherId))
                    return "teacherId: integer expected";
            }
            if (message.isStudent != null && message.hasOwnProperty("isStudent")) {
                properties._isStudent = 1;
                if (typeof message.isStudent !== "boolean")
                    return "isStudent: boolean expected";
            }
            if (message.studentId != null && message.hasOwnProperty("studentId")) {
                properties._studentId = 1;
                if (!$util.isInteger(message.studentId))
                    return "studentId: integer expected";
            }
            if (message.isDemo != null && message.hasOwnProperty("isDemo")) {
                properties._isDemo = 1;
                if (typeof message.isDemo !== "boolean")
                    return "isDemo: boolean expected";
            }
            if (message.isAuthenticated != null && message.hasOwnProperty("isAuthenticated")) {
                properties._isAuthenticated = 1;
                if (typeof message.isAuthenticated !== "boolean")
                    return "isAuthenticated: boolean expected";
            }
            if (message.viewAiPrompts != null && message.hasOwnProperty("viewAiPrompts")) {
                properties._viewAiPrompts = 1;
                if (typeof message.viewAiPrompts !== "boolean")
                    return "viewAiPrompts: boolean expected";
            }
            if (message.viewIds != null && message.hasOwnProperty("viewIds")) {
                properties._viewIds = 1;
                if (typeof message.viewIds !== "boolean")
                    return "viewIds: boolean expected";
            }
            return null;
        };

        /**
         * Creates a UserX message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pl_types.UserX
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pl_types.UserX} UserX
         */
        UserX.fromObject = function fromObject(object) {
            if (object instanceof $root.pl_types.UserX)
                return object;
            var message = new $root.pl_types.UserX();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.districtId != null)
                message.districtId = object.districtId | 0;
            if (object.firstName != null)
                message.firstName = String(object.firstName);
            if (object.lastName != null)
                message.lastName = String(object.lastName);
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            if (object.avatarImageUrl != null)
                message.avatarImageUrl = String(object.avatarImageUrl);
            if (object.isAdminX != null)
                message.isAdminX = Boolean(object.isAdminX);
            if (object.isCrossDistrictAdminX != null)
                message.isCrossDistrictAdminX = Boolean(object.isCrossDistrictAdminX);
            if (object.adminXId != null)
                message.adminXId = object.adminXId | 0;
            if (object.isTeacher != null)
                message.isTeacher = Boolean(object.isTeacher);
            if (object.teacherId != null)
                message.teacherId = object.teacherId | 0;
            if (object.isStudent != null)
                message.isStudent = Boolean(object.isStudent);
            if (object.studentId != null)
                message.studentId = object.studentId | 0;
            if (object.isDemo != null)
                message.isDemo = Boolean(object.isDemo);
            if (object.isAuthenticated != null)
                message.isAuthenticated = Boolean(object.isAuthenticated);
            if (object.viewAiPrompts != null)
                message.viewAiPrompts = Boolean(object.viewAiPrompts);
            if (object.viewIds != null)
                message.viewIds = Boolean(object.viewIds);
            return message;
        };

        /**
         * Creates a plain object from a UserX message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pl_types.UserX
         * @static
         * @param {pl_types.UserX} message UserX
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserX.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.districtId != null && message.hasOwnProperty("districtId")) {
                object.districtId = message.districtId;
                if (options.oneofs)
                    object._districtId = "districtId";
            }
            if (message.firstName != null && message.hasOwnProperty("firstName")) {
                object.firstName = message.firstName;
                if (options.oneofs)
                    object._firstName = "firstName";
            }
            if (message.lastName != null && message.hasOwnProperty("lastName")) {
                object.lastName = message.lastName;
                if (options.oneofs)
                    object._lastName = "lastName";
            }
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress")) {
                object.emailAddress = message.emailAddress;
                if (options.oneofs)
                    object._emailAddress = "emailAddress";
            }
            if (message.isAdminX != null && message.hasOwnProperty("isAdminX")) {
                object.isAdminX = message.isAdminX;
                if (options.oneofs)
                    object._isAdminX = "isAdminX";
            }
            if (message.adminXId != null && message.hasOwnProperty("adminXId")) {
                object.adminXId = message.adminXId;
                if (options.oneofs)
                    object._adminXId = "adminXId";
            }
            if (message.isTeacher != null && message.hasOwnProperty("isTeacher")) {
                object.isTeacher = message.isTeacher;
                if (options.oneofs)
                    object._isTeacher = "isTeacher";
            }
            if (message.teacherId != null && message.hasOwnProperty("teacherId")) {
                object.teacherId = message.teacherId;
                if (options.oneofs)
                    object._teacherId = "teacherId";
            }
            if (message.isStudent != null && message.hasOwnProperty("isStudent")) {
                object.isStudent = message.isStudent;
                if (options.oneofs)
                    object._isStudent = "isStudent";
            }
            if (message.studentId != null && message.hasOwnProperty("studentId")) {
                object.studentId = message.studentId;
                if (options.oneofs)
                    object._studentId = "studentId";
            }
            if (message.isDemo != null && message.hasOwnProperty("isDemo")) {
                object.isDemo = message.isDemo;
                if (options.oneofs)
                    object._isDemo = "isDemo";
            }
            if (message.isAuthenticated != null && message.hasOwnProperty("isAuthenticated")) {
                object.isAuthenticated = message.isAuthenticated;
                if (options.oneofs)
                    object._isAuthenticated = "isAuthenticated";
            }
            if (message.avatarImageUrl != null && message.hasOwnProperty("avatarImageUrl")) {
                object.avatarImageUrl = message.avatarImageUrl;
                if (options.oneofs)
                    object._avatarImageUrl = "avatarImageUrl";
            }
            if (message.isCrossDistrictAdminX != null && message.hasOwnProperty("isCrossDistrictAdminX")) {
                object.isCrossDistrictAdminX = message.isCrossDistrictAdminX;
                if (options.oneofs)
                    object._isCrossDistrictAdminX = "isCrossDistrictAdminX";
            }
            if (message.viewAiPrompts != null && message.hasOwnProperty("viewAiPrompts")) {
                object.viewAiPrompts = message.viewAiPrompts;
                if (options.oneofs)
                    object._viewAiPrompts = "viewAiPrompts";
            }
            if (message.viewIds != null && message.hasOwnProperty("viewIds")) {
                object.viewIds = message.viewIds;
                if (options.oneofs)
                    object._viewIds = "viewIds";
            }
            return object;
        };

        /**
         * Converts this UserX to JSON.
         * @function toJSON
         * @memberof pl_types.UserX
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserX.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserX
         * @function getTypeUrl
         * @memberof pl_types.UserX
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserX.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pl_types.UserX";
        };

        return UserX;
    })();

    pl_types.ProjectDefinition = (function() {

        /**
         * Properties of a ProjectDefinition.
         * @memberof pl_types
         * @interface IProjectDefinition
         * @property {number|null} [id] ProjectDefinition id
         * @property {string|null} [name] ProjectDefinition name
         * @property {number|null} [inputId] ProjectDefinition inputId
         * @property {boolean|null} [template] ProjectDefinition template
         * @property {boolean|null} [selected] ProjectDefinition selected
         * @property {pl_types.ProjectDefinition.State|null} [state] ProjectDefinition state
         * @property {pl_types.IProject|null} [existingProject] ProjectDefinition existingProject
         * @property {pl_types.ProjectDefinition.ExistingProjectUseType|null} [existingProjectUseType] ProjectDefinition existingProjectUseType
         * @property {Array.<pl_types.IProjectInputValue>|null} [inputs] ProjectDefinition inputs
         * @property {pl_types.IAssignment|null} [assignment] ProjectDefinition assignment
         * @property {string|null} [aiPrompt] ProjectDefinition aiPrompt
         * @property {string|null} [aiResponse] ProjectDefinition aiResponse
         */

        /**
         * Constructs a new ProjectDefinition.
         * @memberof pl_types
         * @classdesc Represents a ProjectDefinition.
         * @implements IProjectDefinition
         * @constructor
         * @param {pl_types.IProjectDefinition=} [properties] Properties to set
         */
        function ProjectDefinition(properties) {
            this.inputs = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProjectDefinition id.
         * @member {number|null|undefined} id
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        ProjectDefinition.prototype.id = null;

        /**
         * ProjectDefinition name.
         * @member {string|null|undefined} name
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        ProjectDefinition.prototype.name = null;

        /**
         * ProjectDefinition inputId.
         * @member {number|null|undefined} inputId
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        ProjectDefinition.prototype.inputId = null;

        /**
         * ProjectDefinition template.
         * @member {boolean|null|undefined} template
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        ProjectDefinition.prototype.template = null;

        /**
         * ProjectDefinition selected.
         * @member {boolean|null|undefined} selected
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        ProjectDefinition.prototype.selected = null;

        /**
         * ProjectDefinition state.
         * @member {pl_types.ProjectDefinition.State|null|undefined} state
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        ProjectDefinition.prototype.state = null;

        /**
         * ProjectDefinition existingProject.
         * @member {pl_types.IProject|null|undefined} existingProject
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        ProjectDefinition.prototype.existingProject = null;

        /**
         * ProjectDefinition existingProjectUseType.
         * @member {pl_types.ProjectDefinition.ExistingProjectUseType|null|undefined} existingProjectUseType
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        ProjectDefinition.prototype.existingProjectUseType = null;

        /**
         * ProjectDefinition inputs.
         * @member {Array.<pl_types.IProjectInputValue>} inputs
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        ProjectDefinition.prototype.inputs = $util.emptyArray;

        /**
         * ProjectDefinition assignment.
         * @member {pl_types.IAssignment|null|undefined} assignment
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        ProjectDefinition.prototype.assignment = null;

        /**
         * ProjectDefinition aiPrompt.
         * @member {string|null|undefined} aiPrompt
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        ProjectDefinition.prototype.aiPrompt = null;

        /**
         * ProjectDefinition aiResponse.
         * @member {string|null|undefined} aiResponse
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        ProjectDefinition.prototype.aiResponse = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ProjectDefinition _id.
         * @member {"id"|undefined} _id
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        Object.defineProperty(ProjectDefinition.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectDefinition _name.
         * @member {"name"|undefined} _name
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        Object.defineProperty(ProjectDefinition.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectDefinition _inputId.
         * @member {"inputId"|undefined} _inputId
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        Object.defineProperty(ProjectDefinition.prototype, "_inputId", {
            get: $util.oneOfGetter($oneOfFields = ["inputId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectDefinition _template.
         * @member {"template"|undefined} _template
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        Object.defineProperty(ProjectDefinition.prototype, "_template", {
            get: $util.oneOfGetter($oneOfFields = ["template"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectDefinition _selected.
         * @member {"selected"|undefined} _selected
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        Object.defineProperty(ProjectDefinition.prototype, "_selected", {
            get: $util.oneOfGetter($oneOfFields = ["selected"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectDefinition _state.
         * @member {"state"|undefined} _state
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        Object.defineProperty(ProjectDefinition.prototype, "_state", {
            get: $util.oneOfGetter($oneOfFields = ["state"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectDefinition _existingProject.
         * @member {"existingProject"|undefined} _existingProject
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        Object.defineProperty(ProjectDefinition.prototype, "_existingProject", {
            get: $util.oneOfGetter($oneOfFields = ["existingProject"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectDefinition _existingProjectUseType.
         * @member {"existingProjectUseType"|undefined} _existingProjectUseType
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        Object.defineProperty(ProjectDefinition.prototype, "_existingProjectUseType", {
            get: $util.oneOfGetter($oneOfFields = ["existingProjectUseType"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectDefinition _assignment.
         * @member {"assignment"|undefined} _assignment
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        Object.defineProperty(ProjectDefinition.prototype, "_assignment", {
            get: $util.oneOfGetter($oneOfFields = ["assignment"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectDefinition _aiPrompt.
         * @member {"aiPrompt"|undefined} _aiPrompt
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        Object.defineProperty(ProjectDefinition.prototype, "_aiPrompt", {
            get: $util.oneOfGetter($oneOfFields = ["aiPrompt"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectDefinition _aiResponse.
         * @member {"aiResponse"|undefined} _aiResponse
         * @memberof pl_types.ProjectDefinition
         * @instance
         */
        Object.defineProperty(ProjectDefinition.prototype, "_aiResponse", {
            get: $util.oneOfGetter($oneOfFields = ["aiResponse"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ProjectDefinition instance using the specified properties.
         * @function create
         * @memberof pl_types.ProjectDefinition
         * @static
         * @param {pl_types.IProjectDefinition=} [properties] Properties to set
         * @returns {pl_types.ProjectDefinition} ProjectDefinition instance
         */
        ProjectDefinition.create = function create(properties) {
            return new ProjectDefinition(properties);
        };

        /**
         * Encodes the specified ProjectDefinition message. Does not implicitly {@link pl_types.ProjectDefinition.verify|verify} messages.
         * @function encode
         * @memberof pl_types.ProjectDefinition
         * @static
         * @param {pl_types.IProjectDefinition} message ProjectDefinition message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectDefinition.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.template != null && Object.hasOwnProperty.call(message, "template"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.template);
            if (message.selected != null && Object.hasOwnProperty.call(message, "selected"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.selected);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.state);
            if (message.inputs != null && message.inputs.length)
                for (var i = 0; i < message.inputs.length; ++i)
                    $root.pl_types.ProjectInputValue.encode(message.inputs[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.inputId != null && Object.hasOwnProperty.call(message, "inputId"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.inputId);
            if (message.assignment != null && Object.hasOwnProperty.call(message, "assignment"))
                $root.pl_types.Assignment.encode(message.assignment, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.existingProject != null && Object.hasOwnProperty.call(message, "existingProject"))
                $root.pl_types.Project.encode(message.existingProject, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.existingProjectUseType != null && Object.hasOwnProperty.call(message, "existingProjectUseType"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.existingProjectUseType);
            if (message.aiPrompt != null && Object.hasOwnProperty.call(message, "aiPrompt"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.aiPrompt);
            if (message.aiResponse != null && Object.hasOwnProperty.call(message, "aiResponse"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.aiResponse);
            return writer;
        };

        /**
         * Encodes the specified ProjectDefinition message, length delimited. Does not implicitly {@link pl_types.ProjectDefinition.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pl_types.ProjectDefinition
         * @static
         * @param {pl_types.IProjectDefinition} message ProjectDefinition message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectDefinition.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProjectDefinition message from the specified reader or buffer.
         * @function decode
         * @memberof pl_types.ProjectDefinition
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pl_types.ProjectDefinition} ProjectDefinition
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectDefinition.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.ProjectDefinition();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 7: {
                        message.inputId = reader.int32();
                        break;
                    }
                case 3: {
                        message.template = reader.bool();
                        break;
                    }
                case 4: {
                        message.selected = reader.bool();
                        break;
                    }
                case 5: {
                        message.state = reader.int32();
                        break;
                    }
                case 9: {
                        message.existingProject = $root.pl_types.Project.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.existingProjectUseType = reader.int32();
                        break;
                    }
                case 6: {
                        if (!(message.inputs && message.inputs.length))
                            message.inputs = [];
                        message.inputs.push($root.pl_types.ProjectInputValue.decode(reader, reader.uint32()));
                        break;
                    }
                case 8: {
                        message.assignment = $root.pl_types.Assignment.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.aiPrompt = reader.string();
                        break;
                    }
                case 12: {
                        message.aiResponse = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProjectDefinition message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pl_types.ProjectDefinition
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pl_types.ProjectDefinition} ProjectDefinition
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectDefinition.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProjectDefinition message.
         * @function verify
         * @memberof pl_types.ProjectDefinition
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProjectDefinition.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            if (message.inputId != null && message.hasOwnProperty("inputId")) {
                properties._inputId = 1;
                if (!$util.isInteger(message.inputId))
                    return "inputId: integer expected";
            }
            if (message.template != null && message.hasOwnProperty("template")) {
                properties._template = 1;
                if (typeof message.template !== "boolean")
                    return "template: boolean expected";
            }
            if (message.selected != null && message.hasOwnProperty("selected")) {
                properties._selected = 1;
                if (typeof message.selected !== "boolean")
                    return "selected: boolean expected";
            }
            if (message.state != null && message.hasOwnProperty("state")) {
                properties._state = 1;
                switch (message.state) {
                default:
                    return "state: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            }
            if (message.existingProject != null && message.hasOwnProperty("existingProject")) {
                properties._existingProject = 1;
                {
                    var error = $root.pl_types.Project.verify(message.existingProject);
                    if (error)
                        return "existingProject." + error;
                }
            }
            if (message.existingProjectUseType != null && message.hasOwnProperty("existingProjectUseType")) {
                properties._existingProjectUseType = 1;
                switch (message.existingProjectUseType) {
                default:
                    return "existingProjectUseType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            }
            if (message.inputs != null && message.hasOwnProperty("inputs")) {
                if (!Array.isArray(message.inputs))
                    return "inputs: array expected";
                for (var i = 0; i < message.inputs.length; ++i) {
                    var error = $root.pl_types.ProjectInputValue.verify(message.inputs[i]);
                    if (error)
                        return "inputs." + error;
                }
            }
            if (message.assignment != null && message.hasOwnProperty("assignment")) {
                properties._assignment = 1;
                {
                    var error = $root.pl_types.Assignment.verify(message.assignment);
                    if (error)
                        return "assignment." + error;
                }
            }
            if (message.aiPrompt != null && message.hasOwnProperty("aiPrompt")) {
                properties._aiPrompt = 1;
                if (!$util.isString(message.aiPrompt))
                    return "aiPrompt: string expected";
            }
            if (message.aiResponse != null && message.hasOwnProperty("aiResponse")) {
                properties._aiResponse = 1;
                if (!$util.isString(message.aiResponse))
                    return "aiResponse: string expected";
            }
            return null;
        };

        /**
         * Creates a ProjectDefinition message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pl_types.ProjectDefinition
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pl_types.ProjectDefinition} ProjectDefinition
         */
        ProjectDefinition.fromObject = function fromObject(object) {
            if (object instanceof $root.pl_types.ProjectDefinition)
                return object;
            var message = new $root.pl_types.ProjectDefinition();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.name != null)
                message.name = String(object.name);
            if (object.inputId != null)
                message.inputId = object.inputId | 0;
            if (object.template != null)
                message.template = Boolean(object.template);
            if (object.selected != null)
                message.selected = Boolean(object.selected);
            switch (object.state) {
            default:
                if (typeof object.state === "number") {
                    message.state = object.state;
                    break;
                }
                break;
            case "UNSET_STATE":
            case 0:
                message.state = 0;
                break;
            case "PROCESSING":
            case 1:
                message.state = 1;
                break;
            case "COMPLETED":
            case 2:
                message.state = 2;
                break;
            case "FAILED":
            case 3:
                message.state = 3;
                break;
            }
            if (object.existingProject != null) {
                if (typeof object.existingProject !== "object")
                    throw TypeError(".pl_types.ProjectDefinition.existingProject: object expected");
                message.existingProject = $root.pl_types.Project.fromObject(object.existingProject);
            }
            switch (object.existingProjectUseType) {
            default:
                if (typeof object.existingProjectUseType === "number") {
                    message.existingProjectUseType = object.existingProjectUseType;
                    break;
                }
                break;
            case "UNSET_EXISTING_PROJECT_CONFIGURATION_TYPE":
            case 0:
                message.existingProjectUseType = 0;
                break;
            case "USE_CONFIGURATION":
            case 1:
                message.existingProjectUseType = 1;
                break;
            case "MORE_LIKE_THIS":
            case 2:
                message.existingProjectUseType = 2;
                break;
            case "SUB_PROJECTS":
            case 3:
                message.existingProjectUseType = 3;
                break;
            }
            if (object.inputs) {
                if (!Array.isArray(object.inputs))
                    throw TypeError(".pl_types.ProjectDefinition.inputs: array expected");
                message.inputs = [];
                for (var i = 0; i < object.inputs.length; ++i) {
                    if (typeof object.inputs[i] !== "object")
                        throw TypeError(".pl_types.ProjectDefinition.inputs: object expected");
                    message.inputs[i] = $root.pl_types.ProjectInputValue.fromObject(object.inputs[i]);
                }
            }
            if (object.assignment != null) {
                if (typeof object.assignment !== "object")
                    throw TypeError(".pl_types.ProjectDefinition.assignment: object expected");
                message.assignment = $root.pl_types.Assignment.fromObject(object.assignment);
            }
            if (object.aiPrompt != null)
                message.aiPrompt = String(object.aiPrompt);
            if (object.aiResponse != null)
                message.aiResponse = String(object.aiResponse);
            return message;
        };

        /**
         * Creates a plain object from a ProjectDefinition message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pl_types.ProjectDefinition
         * @static
         * @param {pl_types.ProjectDefinition} message ProjectDefinition
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProjectDefinition.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.inputs = [];
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            if (message.template != null && message.hasOwnProperty("template")) {
                object.template = message.template;
                if (options.oneofs)
                    object._template = "template";
            }
            if (message.selected != null && message.hasOwnProperty("selected")) {
                object.selected = message.selected;
                if (options.oneofs)
                    object._selected = "selected";
            }
            if (message.state != null && message.hasOwnProperty("state")) {
                object.state = options.enums === String ? $root.pl_types.ProjectDefinition.State[message.state] === undefined ? message.state : $root.pl_types.ProjectDefinition.State[message.state] : message.state;
                if (options.oneofs)
                    object._state = "state";
            }
            if (message.inputs && message.inputs.length) {
                object.inputs = [];
                for (var j = 0; j < message.inputs.length; ++j)
                    object.inputs[j] = $root.pl_types.ProjectInputValue.toObject(message.inputs[j], options);
            }
            if (message.inputId != null && message.hasOwnProperty("inputId")) {
                object.inputId = message.inputId;
                if (options.oneofs)
                    object._inputId = "inputId";
            }
            if (message.assignment != null && message.hasOwnProperty("assignment")) {
                object.assignment = $root.pl_types.Assignment.toObject(message.assignment, options);
                if (options.oneofs)
                    object._assignment = "assignment";
            }
            if (message.existingProject != null && message.hasOwnProperty("existingProject")) {
                object.existingProject = $root.pl_types.Project.toObject(message.existingProject, options);
                if (options.oneofs)
                    object._existingProject = "existingProject";
            }
            if (message.existingProjectUseType != null && message.hasOwnProperty("existingProjectUseType")) {
                object.existingProjectUseType = options.enums === String ? $root.pl_types.ProjectDefinition.ExistingProjectUseType[message.existingProjectUseType] === undefined ? message.existingProjectUseType : $root.pl_types.ProjectDefinition.ExistingProjectUseType[message.existingProjectUseType] : message.existingProjectUseType;
                if (options.oneofs)
                    object._existingProjectUseType = "existingProjectUseType";
            }
            if (message.aiPrompt != null && message.hasOwnProperty("aiPrompt")) {
                object.aiPrompt = message.aiPrompt;
                if (options.oneofs)
                    object._aiPrompt = "aiPrompt";
            }
            if (message.aiResponse != null && message.hasOwnProperty("aiResponse")) {
                object.aiResponse = message.aiResponse;
                if (options.oneofs)
                    object._aiResponse = "aiResponse";
            }
            return object;
        };

        /**
         * Converts this ProjectDefinition to JSON.
         * @function toJSON
         * @memberof pl_types.ProjectDefinition
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProjectDefinition.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProjectDefinition
         * @function getTypeUrl
         * @memberof pl_types.ProjectDefinition
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProjectDefinition.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pl_types.ProjectDefinition";
        };

        /**
         * State enum.
         * @name pl_types.ProjectDefinition.State
         * @enum {number}
         * @property {number} UNSET_STATE=0 UNSET_STATE value
         * @property {number} PROCESSING=1 PROCESSING value
         * @property {number} COMPLETED=2 COMPLETED value
         * @property {number} FAILED=3 FAILED value
         */
        ProjectDefinition.State = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNSET_STATE"] = 0;
            values[valuesById[1] = "PROCESSING"] = 1;
            values[valuesById[2] = "COMPLETED"] = 2;
            values[valuesById[3] = "FAILED"] = 3;
            return values;
        })();

        /**
         * ExistingProjectUseType enum.
         * @name pl_types.ProjectDefinition.ExistingProjectUseType
         * @enum {number}
         * @property {number} UNSET_EXISTING_PROJECT_CONFIGURATION_TYPE=0 UNSET_EXISTING_PROJECT_CONFIGURATION_TYPE value
         * @property {number} USE_CONFIGURATION=1 USE_CONFIGURATION value
         * @property {number} MORE_LIKE_THIS=2 MORE_LIKE_THIS value
         * @property {number} SUB_PROJECTS=3 SUB_PROJECTS value
         */
        ProjectDefinition.ExistingProjectUseType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNSET_EXISTING_PROJECT_CONFIGURATION_TYPE"] = 0;
            values[valuesById[1] = "USE_CONFIGURATION"] = 1;
            values[valuesById[2] = "MORE_LIKE_THIS"] = 2;
            values[valuesById[3] = "SUB_PROJECTS"] = 3;
            return values;
        })();

        return ProjectDefinition;
    })();

    pl_types.ProjectInputValue = (function() {

        /**
         * Properties of a ProjectInputValue.
         * @memberof pl_types
         * @interface IProjectInputValue
         * @property {pl_types.IProjectInputCategory|null} [category] ProjectInputValue category
         * @property {Array.<string>|null} [freeTexts] ProjectInputValue freeTexts
         * @property {Array.<number>|null} [selectedIds] ProjectInputValue selectedIds
         */

        /**
         * Constructs a new ProjectInputValue.
         * @memberof pl_types
         * @classdesc Represents a ProjectInputValue.
         * @implements IProjectInputValue
         * @constructor
         * @param {pl_types.IProjectInputValue=} [properties] Properties to set
         */
        function ProjectInputValue(properties) {
            this.freeTexts = [];
            this.selectedIds = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProjectInputValue category.
         * @member {pl_types.IProjectInputCategory|null|undefined} category
         * @memberof pl_types.ProjectInputValue
         * @instance
         */
        ProjectInputValue.prototype.category = null;

        /**
         * ProjectInputValue freeTexts.
         * @member {Array.<string>} freeTexts
         * @memberof pl_types.ProjectInputValue
         * @instance
         */
        ProjectInputValue.prototype.freeTexts = $util.emptyArray;

        /**
         * ProjectInputValue selectedIds.
         * @member {Array.<number>} selectedIds
         * @memberof pl_types.ProjectInputValue
         * @instance
         */
        ProjectInputValue.prototype.selectedIds = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ProjectInputValue _category.
         * @member {"category"|undefined} _category
         * @memberof pl_types.ProjectInputValue
         * @instance
         */
        Object.defineProperty(ProjectInputValue.prototype, "_category", {
            get: $util.oneOfGetter($oneOfFields = ["category"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ProjectInputValue instance using the specified properties.
         * @function create
         * @memberof pl_types.ProjectInputValue
         * @static
         * @param {pl_types.IProjectInputValue=} [properties] Properties to set
         * @returns {pl_types.ProjectInputValue} ProjectInputValue instance
         */
        ProjectInputValue.create = function create(properties) {
            return new ProjectInputValue(properties);
        };

        /**
         * Encodes the specified ProjectInputValue message. Does not implicitly {@link pl_types.ProjectInputValue.verify|verify} messages.
         * @function encode
         * @memberof pl_types.ProjectInputValue
         * @static
         * @param {pl_types.IProjectInputValue} message ProjectInputValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectInputValue.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                $root.pl_types.ProjectInputCategory.encode(message.category, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.freeTexts != null && message.freeTexts.length)
                for (var i = 0; i < message.freeTexts.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.freeTexts[i]);
            if (message.selectedIds != null && message.selectedIds.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (var i = 0; i < message.selectedIds.length; ++i)
                    writer.int32(message.selectedIds[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified ProjectInputValue message, length delimited. Does not implicitly {@link pl_types.ProjectInputValue.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pl_types.ProjectInputValue
         * @static
         * @param {pl_types.IProjectInputValue} message ProjectInputValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectInputValue.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProjectInputValue message from the specified reader or buffer.
         * @function decode
         * @memberof pl_types.ProjectInputValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pl_types.ProjectInputValue} ProjectInputValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectInputValue.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.ProjectInputValue();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.category = $root.pl_types.ProjectInputCategory.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        if (!(message.freeTexts && message.freeTexts.length))
                            message.freeTexts = [];
                        message.freeTexts.push(reader.string());
                        break;
                    }
                case 4: {
                        if (!(message.selectedIds && message.selectedIds.length))
                            message.selectedIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.selectedIds.push(reader.int32());
                        } else
                            message.selectedIds.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProjectInputValue message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pl_types.ProjectInputValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pl_types.ProjectInputValue} ProjectInputValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectInputValue.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProjectInputValue message.
         * @function verify
         * @memberof pl_types.ProjectInputValue
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProjectInputValue.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.category != null && message.hasOwnProperty("category")) {
                properties._category = 1;
                {
                    var error = $root.pl_types.ProjectInputCategory.verify(message.category);
                    if (error)
                        return "category." + error;
                }
            }
            if (message.freeTexts != null && message.hasOwnProperty("freeTexts")) {
                if (!Array.isArray(message.freeTexts))
                    return "freeTexts: array expected";
                for (var i = 0; i < message.freeTexts.length; ++i)
                    if (!$util.isString(message.freeTexts[i]))
                        return "freeTexts: string[] expected";
            }
            if (message.selectedIds != null && message.hasOwnProperty("selectedIds")) {
                if (!Array.isArray(message.selectedIds))
                    return "selectedIds: array expected";
                for (var i = 0; i < message.selectedIds.length; ++i)
                    if (!$util.isInteger(message.selectedIds[i]))
                        return "selectedIds: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a ProjectInputValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pl_types.ProjectInputValue
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pl_types.ProjectInputValue} ProjectInputValue
         */
        ProjectInputValue.fromObject = function fromObject(object) {
            if (object instanceof $root.pl_types.ProjectInputValue)
                return object;
            var message = new $root.pl_types.ProjectInputValue();
            if (object.category != null) {
                if (typeof object.category !== "object")
                    throw TypeError(".pl_types.ProjectInputValue.category: object expected");
                message.category = $root.pl_types.ProjectInputCategory.fromObject(object.category);
            }
            if (object.freeTexts) {
                if (!Array.isArray(object.freeTexts))
                    throw TypeError(".pl_types.ProjectInputValue.freeTexts: array expected");
                message.freeTexts = [];
                for (var i = 0; i < object.freeTexts.length; ++i)
                    message.freeTexts[i] = String(object.freeTexts[i]);
            }
            if (object.selectedIds) {
                if (!Array.isArray(object.selectedIds))
                    throw TypeError(".pl_types.ProjectInputValue.selectedIds: array expected");
                message.selectedIds = [];
                for (var i = 0; i < object.selectedIds.length; ++i)
                    message.selectedIds[i] = object.selectedIds[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a ProjectInputValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pl_types.ProjectInputValue
         * @static
         * @param {pl_types.ProjectInputValue} message ProjectInputValue
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProjectInputValue.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.freeTexts = [];
                object.selectedIds = [];
            }
            if (message.category != null && message.hasOwnProperty("category")) {
                object.category = $root.pl_types.ProjectInputCategory.toObject(message.category, options);
                if (options.oneofs)
                    object._category = "category";
            }
            if (message.freeTexts && message.freeTexts.length) {
                object.freeTexts = [];
                for (var j = 0; j < message.freeTexts.length; ++j)
                    object.freeTexts[j] = message.freeTexts[j];
            }
            if (message.selectedIds && message.selectedIds.length) {
                object.selectedIds = [];
                for (var j = 0; j < message.selectedIds.length; ++j)
                    object.selectedIds[j] = message.selectedIds[j];
            }
            return object;
        };

        /**
         * Converts this ProjectInputValue to JSON.
         * @function toJSON
         * @memberof pl_types.ProjectInputValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProjectInputValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProjectInputValue
         * @function getTypeUrl
         * @memberof pl_types.ProjectInputValue
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProjectInputValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pl_types.ProjectInputValue";
        };

        return ProjectInputValue;
    })();

    pl_types.ProjectInputCategory = (function() {

        /**
         * Properties of a ProjectInputCategory.
         * @memberof pl_types
         * @interface IProjectInputCategory
         * @property {number|null} [id] ProjectInputCategory id
         * @property {number|null} [typeId] ProjectInputCategory typeId
         * @property {string|null} [name] ProjectInputCategory name
         * @property {string|null} [shortDescr] ProjectInputCategory shortDescr
         * @property {string|null} [inputDescr] ProjectInputCategory inputDescr
         * @property {string|null} [hint] ProjectInputCategory hint
         * @property {string|null} [placeholder] ProjectInputCategory placeholder
         * @property {pl_types.ProjectInputCategory.ValueType|null} [valueType] ProjectInputCategory valueType
         * @property {number|null} [maxNumValues] ProjectInputCategory maxNumValues
         * @property {Array.<pl_types.ProjectInputCategory.IOption>|null} [options] ProjectInputCategory options
         */

        /**
         * Constructs a new ProjectInputCategory.
         * @memberof pl_types
         * @classdesc Represents a ProjectInputCategory.
         * @implements IProjectInputCategory
         * @constructor
         * @param {pl_types.IProjectInputCategory=} [properties] Properties to set
         */
        function ProjectInputCategory(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProjectInputCategory id.
         * @member {number|null|undefined} id
         * @memberof pl_types.ProjectInputCategory
         * @instance
         */
        ProjectInputCategory.prototype.id = null;

        /**
         * ProjectInputCategory typeId.
         * @member {number|null|undefined} typeId
         * @memberof pl_types.ProjectInputCategory
         * @instance
         */
        ProjectInputCategory.prototype.typeId = null;

        /**
         * ProjectInputCategory name.
         * @member {string|null|undefined} name
         * @memberof pl_types.ProjectInputCategory
         * @instance
         */
        ProjectInputCategory.prototype.name = null;

        /**
         * ProjectInputCategory shortDescr.
         * @member {string|null|undefined} shortDescr
         * @memberof pl_types.ProjectInputCategory
         * @instance
         */
        ProjectInputCategory.prototype.shortDescr = null;

        /**
         * ProjectInputCategory inputDescr.
         * @member {string|null|undefined} inputDescr
         * @memberof pl_types.ProjectInputCategory
         * @instance
         */
        ProjectInputCategory.prototype.inputDescr = null;

        /**
         * ProjectInputCategory hint.
         * @member {string|null|undefined} hint
         * @memberof pl_types.ProjectInputCategory
         * @instance
         */
        ProjectInputCategory.prototype.hint = null;

        /**
         * ProjectInputCategory placeholder.
         * @member {string|null|undefined} placeholder
         * @memberof pl_types.ProjectInputCategory
         * @instance
         */
        ProjectInputCategory.prototype.placeholder = null;

        /**
         * ProjectInputCategory valueType.
         * @member {pl_types.ProjectInputCategory.ValueType|null|undefined} valueType
         * @memberof pl_types.ProjectInputCategory
         * @instance
         */
        ProjectInputCategory.prototype.valueType = null;

        /**
         * ProjectInputCategory maxNumValues.
         * @member {number|null|undefined} maxNumValues
         * @memberof pl_types.ProjectInputCategory
         * @instance
         */
        ProjectInputCategory.prototype.maxNumValues = null;

        /**
         * ProjectInputCategory options.
         * @member {Array.<pl_types.ProjectInputCategory.IOption>} options
         * @memberof pl_types.ProjectInputCategory
         * @instance
         */
        ProjectInputCategory.prototype.options = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ProjectInputCategory _id.
         * @member {"id"|undefined} _id
         * @memberof pl_types.ProjectInputCategory
         * @instance
         */
        Object.defineProperty(ProjectInputCategory.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectInputCategory _typeId.
         * @member {"typeId"|undefined} _typeId
         * @memberof pl_types.ProjectInputCategory
         * @instance
         */
        Object.defineProperty(ProjectInputCategory.prototype, "_typeId", {
            get: $util.oneOfGetter($oneOfFields = ["typeId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectInputCategory _name.
         * @member {"name"|undefined} _name
         * @memberof pl_types.ProjectInputCategory
         * @instance
         */
        Object.defineProperty(ProjectInputCategory.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectInputCategory _shortDescr.
         * @member {"shortDescr"|undefined} _shortDescr
         * @memberof pl_types.ProjectInputCategory
         * @instance
         */
        Object.defineProperty(ProjectInputCategory.prototype, "_shortDescr", {
            get: $util.oneOfGetter($oneOfFields = ["shortDescr"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectInputCategory _inputDescr.
         * @member {"inputDescr"|undefined} _inputDescr
         * @memberof pl_types.ProjectInputCategory
         * @instance
         */
        Object.defineProperty(ProjectInputCategory.prototype, "_inputDescr", {
            get: $util.oneOfGetter($oneOfFields = ["inputDescr"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectInputCategory _hint.
         * @member {"hint"|undefined} _hint
         * @memberof pl_types.ProjectInputCategory
         * @instance
         */
        Object.defineProperty(ProjectInputCategory.prototype, "_hint", {
            get: $util.oneOfGetter($oneOfFields = ["hint"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectInputCategory _placeholder.
         * @member {"placeholder"|undefined} _placeholder
         * @memberof pl_types.ProjectInputCategory
         * @instance
         */
        Object.defineProperty(ProjectInputCategory.prototype, "_placeholder", {
            get: $util.oneOfGetter($oneOfFields = ["placeholder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectInputCategory _valueType.
         * @member {"valueType"|undefined} _valueType
         * @memberof pl_types.ProjectInputCategory
         * @instance
         */
        Object.defineProperty(ProjectInputCategory.prototype, "_valueType", {
            get: $util.oneOfGetter($oneOfFields = ["valueType"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectInputCategory _maxNumValues.
         * @member {"maxNumValues"|undefined} _maxNumValues
         * @memberof pl_types.ProjectInputCategory
         * @instance
         */
        Object.defineProperty(ProjectInputCategory.prototype, "_maxNumValues", {
            get: $util.oneOfGetter($oneOfFields = ["maxNumValues"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ProjectInputCategory instance using the specified properties.
         * @function create
         * @memberof pl_types.ProjectInputCategory
         * @static
         * @param {pl_types.IProjectInputCategory=} [properties] Properties to set
         * @returns {pl_types.ProjectInputCategory} ProjectInputCategory instance
         */
        ProjectInputCategory.create = function create(properties) {
            return new ProjectInputCategory(properties);
        };

        /**
         * Encodes the specified ProjectInputCategory message. Does not implicitly {@link pl_types.ProjectInputCategory.verify|verify} messages.
         * @function encode
         * @memberof pl_types.ProjectInputCategory
         * @static
         * @param {pl_types.IProjectInputCategory} message ProjectInputCategory message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectInputCategory.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.typeId != null && Object.hasOwnProperty.call(message, "typeId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.typeId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.shortDescr != null && Object.hasOwnProperty.call(message, "shortDescr"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.shortDescr);
            if (message.hint != null && Object.hasOwnProperty.call(message, "hint"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.hint);
            if (message.placeholder != null && Object.hasOwnProperty.call(message, "placeholder"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.placeholder);
            if (message.valueType != null && Object.hasOwnProperty.call(message, "valueType"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.valueType);
            if (message.maxNumValues != null && Object.hasOwnProperty.call(message, "maxNumValues"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.maxNumValues);
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.pl_types.ProjectInputCategory.Option.encode(message.options[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.inputDescr != null && Object.hasOwnProperty.call(message, "inputDescr"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.inputDescr);
            return writer;
        };

        /**
         * Encodes the specified ProjectInputCategory message, length delimited. Does not implicitly {@link pl_types.ProjectInputCategory.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pl_types.ProjectInputCategory
         * @static
         * @param {pl_types.IProjectInputCategory} message ProjectInputCategory message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectInputCategory.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProjectInputCategory message from the specified reader or buffer.
         * @function decode
         * @memberof pl_types.ProjectInputCategory
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pl_types.ProjectInputCategory} ProjectInputCategory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectInputCategory.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.ProjectInputCategory();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.typeId = reader.int32();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.shortDescr = reader.string();
                        break;
                    }
                case 10: {
                        message.inputDescr = reader.string();
                        break;
                    }
                case 5: {
                        message.hint = reader.string();
                        break;
                    }
                case 6: {
                        message.placeholder = reader.string();
                        break;
                    }
                case 7: {
                        message.valueType = reader.int32();
                        break;
                    }
                case 8: {
                        message.maxNumValues = reader.int32();
                        break;
                    }
                case 9: {
                        if (!(message.options && message.options.length))
                            message.options = [];
                        message.options.push($root.pl_types.ProjectInputCategory.Option.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProjectInputCategory message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pl_types.ProjectInputCategory
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pl_types.ProjectInputCategory} ProjectInputCategory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectInputCategory.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProjectInputCategory message.
         * @function verify
         * @memberof pl_types.ProjectInputCategory
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProjectInputCategory.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            }
            if (message.typeId != null && message.hasOwnProperty("typeId")) {
                properties._typeId = 1;
                if (!$util.isInteger(message.typeId))
                    return "typeId: integer expected";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            if (message.shortDescr != null && message.hasOwnProperty("shortDescr")) {
                properties._shortDescr = 1;
                if (!$util.isString(message.shortDescr))
                    return "shortDescr: string expected";
            }
            if (message.inputDescr != null && message.hasOwnProperty("inputDescr")) {
                properties._inputDescr = 1;
                if (!$util.isString(message.inputDescr))
                    return "inputDescr: string expected";
            }
            if (message.hint != null && message.hasOwnProperty("hint")) {
                properties._hint = 1;
                if (!$util.isString(message.hint))
                    return "hint: string expected";
            }
            if (message.placeholder != null && message.hasOwnProperty("placeholder")) {
                properties._placeholder = 1;
                if (!$util.isString(message.placeholder))
                    return "placeholder: string expected";
            }
            if (message.valueType != null && message.hasOwnProperty("valueType")) {
                properties._valueType = 1;
                switch (message.valueType) {
                default:
                    return "valueType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            }
            if (message.maxNumValues != null && message.hasOwnProperty("maxNumValues")) {
                properties._maxNumValues = 1;
                if (!$util.isInteger(message.maxNumValues))
                    return "maxNumValues: integer expected";
            }
            if (message.options != null && message.hasOwnProperty("options")) {
                if (!Array.isArray(message.options))
                    return "options: array expected";
                for (var i = 0; i < message.options.length; ++i) {
                    var error = $root.pl_types.ProjectInputCategory.Option.verify(message.options[i]);
                    if (error)
                        return "options." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ProjectInputCategory message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pl_types.ProjectInputCategory
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pl_types.ProjectInputCategory} ProjectInputCategory
         */
        ProjectInputCategory.fromObject = function fromObject(object) {
            if (object instanceof $root.pl_types.ProjectInputCategory)
                return object;
            var message = new $root.pl_types.ProjectInputCategory();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.typeId != null)
                message.typeId = object.typeId | 0;
            if (object.name != null)
                message.name = String(object.name);
            if (object.shortDescr != null)
                message.shortDescr = String(object.shortDescr);
            if (object.inputDescr != null)
                message.inputDescr = String(object.inputDescr);
            if (object.hint != null)
                message.hint = String(object.hint);
            if (object.placeholder != null)
                message.placeholder = String(object.placeholder);
            switch (object.valueType) {
            default:
                if (typeof object.valueType === "number") {
                    message.valueType = object.valueType;
                    break;
                }
                break;
            case "UNSET_VALUE_TYPE":
            case 0:
                message.valueType = 0;
                break;
            case "FREE_TEXT":
            case 1:
                message.valueType = 1;
                break;
            case "EKS":
            case 2:
                message.valueType = 2;
                break;
            case "XQ_COMPETENCY":
            case 3:
                message.valueType = 3;
                break;
            case "MOTIVATION":
            case 4:
                message.valueType = 4;
                break;
            case "CTE":
            case 5:
                message.valueType = 5;
                break;
            }
            if (object.maxNumValues != null)
                message.maxNumValues = object.maxNumValues | 0;
            if (object.options) {
                if (!Array.isArray(object.options))
                    throw TypeError(".pl_types.ProjectInputCategory.options: array expected");
                message.options = [];
                for (var i = 0; i < object.options.length; ++i) {
                    if (typeof object.options[i] !== "object")
                        throw TypeError(".pl_types.ProjectInputCategory.options: object expected");
                    message.options[i] = $root.pl_types.ProjectInputCategory.Option.fromObject(object.options[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ProjectInputCategory message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pl_types.ProjectInputCategory
         * @static
         * @param {pl_types.ProjectInputCategory} message ProjectInputCategory
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProjectInputCategory.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.options = [];
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.typeId != null && message.hasOwnProperty("typeId")) {
                object.typeId = message.typeId;
                if (options.oneofs)
                    object._typeId = "typeId";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            if (message.shortDescr != null && message.hasOwnProperty("shortDescr")) {
                object.shortDescr = message.shortDescr;
                if (options.oneofs)
                    object._shortDescr = "shortDescr";
            }
            if (message.hint != null && message.hasOwnProperty("hint")) {
                object.hint = message.hint;
                if (options.oneofs)
                    object._hint = "hint";
            }
            if (message.placeholder != null && message.hasOwnProperty("placeholder")) {
                object.placeholder = message.placeholder;
                if (options.oneofs)
                    object._placeholder = "placeholder";
            }
            if (message.valueType != null && message.hasOwnProperty("valueType")) {
                object.valueType = options.enums === String ? $root.pl_types.ProjectInputCategory.ValueType[message.valueType] === undefined ? message.valueType : $root.pl_types.ProjectInputCategory.ValueType[message.valueType] : message.valueType;
                if (options.oneofs)
                    object._valueType = "valueType";
            }
            if (message.maxNumValues != null && message.hasOwnProperty("maxNumValues")) {
                object.maxNumValues = message.maxNumValues;
                if (options.oneofs)
                    object._maxNumValues = "maxNumValues";
            }
            if (message.options && message.options.length) {
                object.options = [];
                for (var j = 0; j < message.options.length; ++j)
                    object.options[j] = $root.pl_types.ProjectInputCategory.Option.toObject(message.options[j], options);
            }
            if (message.inputDescr != null && message.hasOwnProperty("inputDescr")) {
                object.inputDescr = message.inputDescr;
                if (options.oneofs)
                    object._inputDescr = "inputDescr";
            }
            return object;
        };

        /**
         * Converts this ProjectInputCategory to JSON.
         * @function toJSON
         * @memberof pl_types.ProjectInputCategory
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProjectInputCategory.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProjectInputCategory
         * @function getTypeUrl
         * @memberof pl_types.ProjectInputCategory
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProjectInputCategory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pl_types.ProjectInputCategory";
        };

        /**
         * ValueType enum.
         * @name pl_types.ProjectInputCategory.ValueType
         * @enum {number}
         * @property {number} UNSET_VALUE_TYPE=0 UNSET_VALUE_TYPE value
         * @property {number} FREE_TEXT=1 FREE_TEXT value
         * @property {number} EKS=2 EKS value
         * @property {number} XQ_COMPETENCY=3 XQ_COMPETENCY value
         * @property {number} MOTIVATION=4 MOTIVATION value
         * @property {number} CTE=5 CTE value
         */
        ProjectInputCategory.ValueType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNSET_VALUE_TYPE"] = 0;
            values[valuesById[1] = "FREE_TEXT"] = 1;
            values[valuesById[2] = "EKS"] = 2;
            values[valuesById[3] = "XQ_COMPETENCY"] = 3;
            values[valuesById[4] = "MOTIVATION"] = 4;
            values[valuesById[5] = "CTE"] = 5;
            return values;
        })();

        ProjectInputCategory.Option = (function() {

            /**
             * Properties of an Option.
             * @memberof pl_types.ProjectInputCategory
             * @interface IOption
             * @property {number|null} [id] Option id
             * @property {string|null} [name] Option name
             * @property {string|null} [category] Option category
             * @property {string|null} [shortDescr] Option shortDescr
             * @property {pl_types.IUserX|null} [userX] Option userX
             */

            /**
             * Constructs a new Option.
             * @memberof pl_types.ProjectInputCategory
             * @classdesc Represents an Option.
             * @implements IOption
             * @constructor
             * @param {pl_types.ProjectInputCategory.IOption=} [properties] Properties to set
             */
            function Option(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Option id.
             * @member {number|null|undefined} id
             * @memberof pl_types.ProjectInputCategory.Option
             * @instance
             */
            Option.prototype.id = null;

            /**
             * Option name.
             * @member {string|null|undefined} name
             * @memberof pl_types.ProjectInputCategory.Option
             * @instance
             */
            Option.prototype.name = null;

            /**
             * Option category.
             * @member {string|null|undefined} category
             * @memberof pl_types.ProjectInputCategory.Option
             * @instance
             */
            Option.prototype.category = null;

            /**
             * Option shortDescr.
             * @member {string|null|undefined} shortDescr
             * @memberof pl_types.ProjectInputCategory.Option
             * @instance
             */
            Option.prototype.shortDescr = null;

            /**
             * Option userX.
             * @member {pl_types.IUserX|null|undefined} userX
             * @memberof pl_types.ProjectInputCategory.Option
             * @instance
             */
            Option.prototype.userX = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * Option _id.
             * @member {"id"|undefined} _id
             * @memberof pl_types.ProjectInputCategory.Option
             * @instance
             */
            Object.defineProperty(Option.prototype, "_id", {
                get: $util.oneOfGetter($oneOfFields = ["id"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Option _name.
             * @member {"name"|undefined} _name
             * @memberof pl_types.ProjectInputCategory.Option
             * @instance
             */
            Object.defineProperty(Option.prototype, "_name", {
                get: $util.oneOfGetter($oneOfFields = ["name"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Option _category.
             * @member {"category"|undefined} _category
             * @memberof pl_types.ProjectInputCategory.Option
             * @instance
             */
            Object.defineProperty(Option.prototype, "_category", {
                get: $util.oneOfGetter($oneOfFields = ["category"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Option _shortDescr.
             * @member {"shortDescr"|undefined} _shortDescr
             * @memberof pl_types.ProjectInputCategory.Option
             * @instance
             */
            Object.defineProperty(Option.prototype, "_shortDescr", {
                get: $util.oneOfGetter($oneOfFields = ["shortDescr"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Option _userX.
             * @member {"userX"|undefined} _userX
             * @memberof pl_types.ProjectInputCategory.Option
             * @instance
             */
            Object.defineProperty(Option.prototype, "_userX", {
                get: $util.oneOfGetter($oneOfFields = ["userX"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new Option instance using the specified properties.
             * @function create
             * @memberof pl_types.ProjectInputCategory.Option
             * @static
             * @param {pl_types.ProjectInputCategory.IOption=} [properties] Properties to set
             * @returns {pl_types.ProjectInputCategory.Option} Option instance
             */
            Option.create = function create(properties) {
                return new Option(properties);
            };

            /**
             * Encodes the specified Option message. Does not implicitly {@link pl_types.ProjectInputCategory.Option.verify|verify} messages.
             * @function encode
             * @memberof pl_types.ProjectInputCategory.Option
             * @static
             * @param {pl_types.ProjectInputCategory.IOption} message Option message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Option.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.shortDescr != null && Object.hasOwnProperty.call(message, "shortDescr"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.shortDescr);
                if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.category);
                if (message.userX != null && Object.hasOwnProperty.call(message, "userX"))
                    $root.pl_types.UserX.encode(message.userX, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Option message, length delimited. Does not implicitly {@link pl_types.ProjectInputCategory.Option.verify|verify} messages.
             * @function encodeDelimited
             * @memberof pl_types.ProjectInputCategory.Option
             * @static
             * @param {pl_types.ProjectInputCategory.IOption} message Option message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Option.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Option message from the specified reader or buffer.
             * @function decode
             * @memberof pl_types.ProjectInputCategory.Option
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {pl_types.ProjectInputCategory.Option} Option
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Option.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.ProjectInputCategory.Option();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 4: {
                            message.category = reader.string();
                            break;
                        }
                    case 3: {
                            message.shortDescr = reader.string();
                            break;
                        }
                    case 6: {
                            message.userX = $root.pl_types.UserX.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Option message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof pl_types.ProjectInputCategory.Option
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {pl_types.ProjectInputCategory.Option} Option
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Option.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Option message.
             * @function verify
             * @memberof pl_types.ProjectInputCategory.Option
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Option.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.id != null && message.hasOwnProperty("id")) {
                    properties._id = 1;
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                }
                if (message.name != null && message.hasOwnProperty("name")) {
                    properties._name = 1;
                    if (!$util.isString(message.name))
                        return "name: string expected";
                }
                if (message.category != null && message.hasOwnProperty("category")) {
                    properties._category = 1;
                    if (!$util.isString(message.category))
                        return "category: string expected";
                }
                if (message.shortDescr != null && message.hasOwnProperty("shortDescr")) {
                    properties._shortDescr = 1;
                    if (!$util.isString(message.shortDescr))
                        return "shortDescr: string expected";
                }
                if (message.userX != null && message.hasOwnProperty("userX")) {
                    properties._userX = 1;
                    {
                        var error = $root.pl_types.UserX.verify(message.userX);
                        if (error)
                            return "userX." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an Option message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof pl_types.ProjectInputCategory.Option
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {pl_types.ProjectInputCategory.Option} Option
             */
            Option.fromObject = function fromObject(object) {
                if (object instanceof $root.pl_types.ProjectInputCategory.Option)
                    return object;
                var message = new $root.pl_types.ProjectInputCategory.Option();
                if (object.id != null)
                    message.id = object.id | 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.category != null)
                    message.category = String(object.category);
                if (object.shortDescr != null)
                    message.shortDescr = String(object.shortDescr);
                if (object.userX != null) {
                    if (typeof object.userX !== "object")
                        throw TypeError(".pl_types.ProjectInputCategory.Option.userX: object expected");
                    message.userX = $root.pl_types.UserX.fromObject(object.userX);
                }
                return message;
            };

            /**
             * Creates a plain object from an Option message. Also converts values to other types if specified.
             * @function toObject
             * @memberof pl_types.ProjectInputCategory.Option
             * @static
             * @param {pl_types.ProjectInputCategory.Option} message Option
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Option.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.id != null && message.hasOwnProperty("id")) {
                    object.id = message.id;
                    if (options.oneofs)
                        object._id = "id";
                }
                if (message.name != null && message.hasOwnProperty("name")) {
                    object.name = message.name;
                    if (options.oneofs)
                        object._name = "name";
                }
                if (message.shortDescr != null && message.hasOwnProperty("shortDescr")) {
                    object.shortDescr = message.shortDescr;
                    if (options.oneofs)
                        object._shortDescr = "shortDescr";
                }
                if (message.category != null && message.hasOwnProperty("category")) {
                    object.category = message.category;
                    if (options.oneofs)
                        object._category = "category";
                }
                if (message.userX != null && message.hasOwnProperty("userX")) {
                    object.userX = $root.pl_types.UserX.toObject(message.userX, options);
                    if (options.oneofs)
                        object._userX = "userX";
                }
                return object;
            };

            /**
             * Converts this Option to JSON.
             * @function toJSON
             * @memberof pl_types.ProjectInputCategory.Option
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Option.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Option
             * @function getTypeUrl
             * @memberof pl_types.ProjectInputCategory.Option
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Option.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/pl_types.ProjectInputCategory.Option";
            };

            return Option;
        })();

        return ProjectInputCategory;
    })();

    pl_types.Tag = (function() {

        /**
         * Properties of a Tag.
         * @memberof pl_types
         * @interface ITag
         * @property {string|null} [text] Tag text
         * @property {number|null} [userXId] Tag userXId
         */

        /**
         * Constructs a new Tag.
         * @memberof pl_types
         * @classdesc Represents a Tag.
         * @implements ITag
         * @constructor
         * @param {pl_types.ITag=} [properties] Properties to set
         */
        function Tag(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Tag text.
         * @member {string|null|undefined} text
         * @memberof pl_types.Tag
         * @instance
         */
        Tag.prototype.text = null;

        /**
         * Tag userXId.
         * @member {number|null|undefined} userXId
         * @memberof pl_types.Tag
         * @instance
         */
        Tag.prototype.userXId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Tag _text.
         * @member {"text"|undefined} _text
         * @memberof pl_types.Tag
         * @instance
         */
        Object.defineProperty(Tag.prototype, "_text", {
            get: $util.oneOfGetter($oneOfFields = ["text"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Tag _userXId.
         * @member {"userXId"|undefined} _userXId
         * @memberof pl_types.Tag
         * @instance
         */
        Object.defineProperty(Tag.prototype, "_userXId", {
            get: $util.oneOfGetter($oneOfFields = ["userXId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Tag instance using the specified properties.
         * @function create
         * @memberof pl_types.Tag
         * @static
         * @param {pl_types.ITag=} [properties] Properties to set
         * @returns {pl_types.Tag} Tag instance
         */
        Tag.create = function create(properties) {
            return new Tag(properties);
        };

        /**
         * Encodes the specified Tag message. Does not implicitly {@link pl_types.Tag.verify|verify} messages.
         * @function encode
         * @memberof pl_types.Tag
         * @static
         * @param {pl_types.ITag} message Tag message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Tag.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
            if (message.userXId != null && Object.hasOwnProperty.call(message, "userXId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.userXId);
            return writer;
        };

        /**
         * Encodes the specified Tag message, length delimited. Does not implicitly {@link pl_types.Tag.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pl_types.Tag
         * @static
         * @param {pl_types.ITag} message Tag message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Tag.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Tag message from the specified reader or buffer.
         * @function decode
         * @memberof pl_types.Tag
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pl_types.Tag} Tag
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Tag.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.Tag();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.text = reader.string();
                        break;
                    }
                case 2: {
                        message.userXId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Tag message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pl_types.Tag
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pl_types.Tag} Tag
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Tag.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Tag message.
         * @function verify
         * @memberof pl_types.Tag
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Tag.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.text != null && message.hasOwnProperty("text")) {
                properties._text = 1;
                if (!$util.isString(message.text))
                    return "text: string expected";
            }
            if (message.userXId != null && message.hasOwnProperty("userXId")) {
                properties._userXId = 1;
                if (!$util.isInteger(message.userXId))
                    return "userXId: integer expected";
            }
            return null;
        };

        /**
         * Creates a Tag message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pl_types.Tag
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pl_types.Tag} Tag
         */
        Tag.fromObject = function fromObject(object) {
            if (object instanceof $root.pl_types.Tag)
                return object;
            var message = new $root.pl_types.Tag();
            if (object.text != null)
                message.text = String(object.text);
            if (object.userXId != null)
                message.userXId = object.userXId | 0;
            return message;
        };

        /**
         * Creates a plain object from a Tag message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pl_types.Tag
         * @static
         * @param {pl_types.Tag} message Tag
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Tag.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.text != null && message.hasOwnProperty("text")) {
                object.text = message.text;
                if (options.oneofs)
                    object._text = "text";
            }
            if (message.userXId != null && message.hasOwnProperty("userXId")) {
                object.userXId = message.userXId;
                if (options.oneofs)
                    object._userXId = "userXId";
            }
            return object;
        };

        /**
         * Converts this Tag to JSON.
         * @function toJSON
         * @memberof pl_types.Tag
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Tag.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Tag
         * @function getTypeUrl
         * @memberof pl_types.Tag
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Tag.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pl_types.Tag";
        };

        return Tag;
    })();

    pl_types.ProjectPostComment = (function() {

        /**
         * Properties of a ProjectPostComment.
         * @memberof pl_types
         * @interface IProjectPostComment
         * @property {number|null} [id] ProjectPostComment id
         * @property {pl_types.IUserX|null} [userX] ProjectPostComment userX
         * @property {pl_types.IProjectPost|null} [projectPost] ProjectPostComment projectPost
         * @property {string|null} [longDescrHtml] ProjectPostComment longDescrHtml
         * @property {Long|null} [postTimeMs] ProjectPostComment postTimeMs
         * @property {boolean|null} [beingEdited] ProjectPostComment beingEdited
         * @property {string|null} [aiPrompt] ProjectPostComment aiPrompt
         * @property {string|null} [aiResponse] ProjectPostComment aiResponse
         * @property {Array.<pl_types.IUserX>|null} [taggedUserXs] ProjectPostComment taggedUserXs
         */

        /**
         * Constructs a new ProjectPostComment.
         * @memberof pl_types
         * @classdesc Represents a ProjectPostComment.
         * @implements IProjectPostComment
         * @constructor
         * @param {pl_types.IProjectPostComment=} [properties] Properties to set
         */
        function ProjectPostComment(properties) {
            this.taggedUserXs = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProjectPostComment id.
         * @member {number|null|undefined} id
         * @memberof pl_types.ProjectPostComment
         * @instance
         */
        ProjectPostComment.prototype.id = null;

        /**
         * ProjectPostComment userX.
         * @member {pl_types.IUserX|null|undefined} userX
         * @memberof pl_types.ProjectPostComment
         * @instance
         */
        ProjectPostComment.prototype.userX = null;

        /**
         * ProjectPostComment projectPost.
         * @member {pl_types.IProjectPost|null|undefined} projectPost
         * @memberof pl_types.ProjectPostComment
         * @instance
         */
        ProjectPostComment.prototype.projectPost = null;

        /**
         * ProjectPostComment longDescrHtml.
         * @member {string|null|undefined} longDescrHtml
         * @memberof pl_types.ProjectPostComment
         * @instance
         */
        ProjectPostComment.prototype.longDescrHtml = null;

        /**
         * ProjectPostComment postTimeMs.
         * @member {Long|null|undefined} postTimeMs
         * @memberof pl_types.ProjectPostComment
         * @instance
         */
        ProjectPostComment.prototype.postTimeMs = null;

        /**
         * ProjectPostComment beingEdited.
         * @member {boolean|null|undefined} beingEdited
         * @memberof pl_types.ProjectPostComment
         * @instance
         */
        ProjectPostComment.prototype.beingEdited = null;

        /**
         * ProjectPostComment aiPrompt.
         * @member {string|null|undefined} aiPrompt
         * @memberof pl_types.ProjectPostComment
         * @instance
         */
        ProjectPostComment.prototype.aiPrompt = null;

        /**
         * ProjectPostComment aiResponse.
         * @member {string|null|undefined} aiResponse
         * @memberof pl_types.ProjectPostComment
         * @instance
         */
        ProjectPostComment.prototype.aiResponse = null;

        /**
         * ProjectPostComment taggedUserXs.
         * @member {Array.<pl_types.IUserX>} taggedUserXs
         * @memberof pl_types.ProjectPostComment
         * @instance
         */
        ProjectPostComment.prototype.taggedUserXs = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ProjectPostComment _id.
         * @member {"id"|undefined} _id
         * @memberof pl_types.ProjectPostComment
         * @instance
         */
        Object.defineProperty(ProjectPostComment.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPostComment _userX.
         * @member {"userX"|undefined} _userX
         * @memberof pl_types.ProjectPostComment
         * @instance
         */
        Object.defineProperty(ProjectPostComment.prototype, "_userX", {
            get: $util.oneOfGetter($oneOfFields = ["userX"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPostComment _projectPost.
         * @member {"projectPost"|undefined} _projectPost
         * @memberof pl_types.ProjectPostComment
         * @instance
         */
        Object.defineProperty(ProjectPostComment.prototype, "_projectPost", {
            get: $util.oneOfGetter($oneOfFields = ["projectPost"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPostComment _longDescrHtml.
         * @member {"longDescrHtml"|undefined} _longDescrHtml
         * @memberof pl_types.ProjectPostComment
         * @instance
         */
        Object.defineProperty(ProjectPostComment.prototype, "_longDescrHtml", {
            get: $util.oneOfGetter($oneOfFields = ["longDescrHtml"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPostComment _postTimeMs.
         * @member {"postTimeMs"|undefined} _postTimeMs
         * @memberof pl_types.ProjectPostComment
         * @instance
         */
        Object.defineProperty(ProjectPostComment.prototype, "_postTimeMs", {
            get: $util.oneOfGetter($oneOfFields = ["postTimeMs"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPostComment _beingEdited.
         * @member {"beingEdited"|undefined} _beingEdited
         * @memberof pl_types.ProjectPostComment
         * @instance
         */
        Object.defineProperty(ProjectPostComment.prototype, "_beingEdited", {
            get: $util.oneOfGetter($oneOfFields = ["beingEdited"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPostComment _aiPrompt.
         * @member {"aiPrompt"|undefined} _aiPrompt
         * @memberof pl_types.ProjectPostComment
         * @instance
         */
        Object.defineProperty(ProjectPostComment.prototype, "_aiPrompt", {
            get: $util.oneOfGetter($oneOfFields = ["aiPrompt"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPostComment _aiResponse.
         * @member {"aiResponse"|undefined} _aiResponse
         * @memberof pl_types.ProjectPostComment
         * @instance
         */
        Object.defineProperty(ProjectPostComment.prototype, "_aiResponse", {
            get: $util.oneOfGetter($oneOfFields = ["aiResponse"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ProjectPostComment instance using the specified properties.
         * @function create
         * @memberof pl_types.ProjectPostComment
         * @static
         * @param {pl_types.IProjectPostComment=} [properties] Properties to set
         * @returns {pl_types.ProjectPostComment} ProjectPostComment instance
         */
        ProjectPostComment.create = function create(properties) {
            return new ProjectPostComment(properties);
        };

        /**
         * Encodes the specified ProjectPostComment message. Does not implicitly {@link pl_types.ProjectPostComment.verify|verify} messages.
         * @function encode
         * @memberof pl_types.ProjectPostComment
         * @static
         * @param {pl_types.IProjectPostComment} message ProjectPostComment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectPostComment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.userX != null && Object.hasOwnProperty.call(message, "userX"))
                $root.pl_types.UserX.encode(message.userX, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.longDescrHtml != null && Object.hasOwnProperty.call(message, "longDescrHtml"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.longDescrHtml);
            if (message.postTimeMs != null && Object.hasOwnProperty.call(message, "postTimeMs"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.postTimeMs);
            if (message.beingEdited != null && Object.hasOwnProperty.call(message, "beingEdited"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.beingEdited);
            if (message.projectPost != null && Object.hasOwnProperty.call(message, "projectPost"))
                $root.pl_types.ProjectPost.encode(message.projectPost, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.aiPrompt != null && Object.hasOwnProperty.call(message, "aiPrompt"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.aiPrompt);
            if (message.aiResponse != null && Object.hasOwnProperty.call(message, "aiResponse"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.aiResponse);
            if (message.taggedUserXs != null && message.taggedUserXs.length)
                for (var i = 0; i < message.taggedUserXs.length; ++i)
                    $root.pl_types.UserX.encode(message.taggedUserXs[i], writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ProjectPostComment message, length delimited. Does not implicitly {@link pl_types.ProjectPostComment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pl_types.ProjectPostComment
         * @static
         * @param {pl_types.IProjectPostComment} message ProjectPostComment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectPostComment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProjectPostComment message from the specified reader or buffer.
         * @function decode
         * @memberof pl_types.ProjectPostComment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pl_types.ProjectPostComment} ProjectPostComment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectPostComment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.ProjectPostComment();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.userX = $root.pl_types.UserX.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.projectPost = $root.pl_types.ProjectPost.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.longDescrHtml = reader.string();
                        break;
                    }
                case 8: {
                        message.postTimeMs = reader.int64();
                        break;
                    }
                case 9: {
                        message.beingEdited = reader.bool();
                        break;
                    }
                case 12: {
                        message.aiPrompt = reader.string();
                        break;
                    }
                case 13: {
                        message.aiResponse = reader.string();
                        break;
                    }
                case 17: {
                        if (!(message.taggedUserXs && message.taggedUserXs.length))
                            message.taggedUserXs = [];
                        message.taggedUserXs.push($root.pl_types.UserX.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProjectPostComment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pl_types.ProjectPostComment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pl_types.ProjectPostComment} ProjectPostComment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectPostComment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProjectPostComment message.
         * @function verify
         * @memberof pl_types.ProjectPostComment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProjectPostComment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            }
            if (message.userX != null && message.hasOwnProperty("userX")) {
                properties._userX = 1;
                {
                    var error = $root.pl_types.UserX.verify(message.userX);
                    if (error)
                        return "userX." + error;
                }
            }
            if (message.projectPost != null && message.hasOwnProperty("projectPost")) {
                properties._projectPost = 1;
                {
                    var error = $root.pl_types.ProjectPost.verify(message.projectPost);
                    if (error)
                        return "projectPost." + error;
                }
            }
            if (message.longDescrHtml != null && message.hasOwnProperty("longDescrHtml")) {
                properties._longDescrHtml = 1;
                if (!$util.isString(message.longDescrHtml))
                    return "longDescrHtml: string expected";
            }
            if (message.postTimeMs != null && message.hasOwnProperty("postTimeMs")) {
                properties._postTimeMs = 1;
                if (!$util.isInteger(message.postTimeMs) && !(message.postTimeMs && $util.isInteger(message.postTimeMs.low) && $util.isInteger(message.postTimeMs.high)))
                    return "postTimeMs: integer|Long expected";
            }
            if (message.beingEdited != null && message.hasOwnProperty("beingEdited")) {
                properties._beingEdited = 1;
                if (typeof message.beingEdited !== "boolean")
                    return "beingEdited: boolean expected";
            }
            if (message.aiPrompt != null && message.hasOwnProperty("aiPrompt")) {
                properties._aiPrompt = 1;
                if (!$util.isString(message.aiPrompt))
                    return "aiPrompt: string expected";
            }
            if (message.aiResponse != null && message.hasOwnProperty("aiResponse")) {
                properties._aiResponse = 1;
                if (!$util.isString(message.aiResponse))
                    return "aiResponse: string expected";
            }
            if (message.taggedUserXs != null && message.hasOwnProperty("taggedUserXs")) {
                if (!Array.isArray(message.taggedUserXs))
                    return "taggedUserXs: array expected";
                for (var i = 0; i < message.taggedUserXs.length; ++i) {
                    var error = $root.pl_types.UserX.verify(message.taggedUserXs[i]);
                    if (error)
                        return "taggedUserXs." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ProjectPostComment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pl_types.ProjectPostComment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pl_types.ProjectPostComment} ProjectPostComment
         */
        ProjectPostComment.fromObject = function fromObject(object) {
            if (object instanceof $root.pl_types.ProjectPostComment)
                return object;
            var message = new $root.pl_types.ProjectPostComment();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.userX != null) {
                if (typeof object.userX !== "object")
                    throw TypeError(".pl_types.ProjectPostComment.userX: object expected");
                message.userX = $root.pl_types.UserX.fromObject(object.userX);
            }
            if (object.projectPost != null) {
                if (typeof object.projectPost !== "object")
                    throw TypeError(".pl_types.ProjectPostComment.projectPost: object expected");
                message.projectPost = $root.pl_types.ProjectPost.fromObject(object.projectPost);
            }
            if (object.longDescrHtml != null)
                message.longDescrHtml = String(object.longDescrHtml);
            if (object.postTimeMs != null)
                if ($util.Long)
                    (message.postTimeMs = $util.Long.fromValue(object.postTimeMs)).unsigned = false;
                else if (typeof object.postTimeMs === "string")
                    message.postTimeMs = parseInt(object.postTimeMs, 10);
                else if (typeof object.postTimeMs === "number")
                    message.postTimeMs = object.postTimeMs;
                else if (typeof object.postTimeMs === "object")
                    message.postTimeMs = new $util.LongBits(object.postTimeMs.low >>> 0, object.postTimeMs.high >>> 0).toNumber();
            if (object.beingEdited != null)
                message.beingEdited = Boolean(object.beingEdited);
            if (object.aiPrompt != null)
                message.aiPrompt = String(object.aiPrompt);
            if (object.aiResponse != null)
                message.aiResponse = String(object.aiResponse);
            if (object.taggedUserXs) {
                if (!Array.isArray(object.taggedUserXs))
                    throw TypeError(".pl_types.ProjectPostComment.taggedUserXs: array expected");
                message.taggedUserXs = [];
                for (var i = 0; i < object.taggedUserXs.length; ++i) {
                    if (typeof object.taggedUserXs[i] !== "object")
                        throw TypeError(".pl_types.ProjectPostComment.taggedUserXs: object expected");
                    message.taggedUserXs[i] = $root.pl_types.UserX.fromObject(object.taggedUserXs[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ProjectPostComment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pl_types.ProjectPostComment
         * @static
         * @param {pl_types.ProjectPostComment} message ProjectPostComment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProjectPostComment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.taggedUserXs = [];
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.userX != null && message.hasOwnProperty("userX")) {
                object.userX = $root.pl_types.UserX.toObject(message.userX, options);
                if (options.oneofs)
                    object._userX = "userX";
            }
            if (message.longDescrHtml != null && message.hasOwnProperty("longDescrHtml")) {
                object.longDescrHtml = message.longDescrHtml;
                if (options.oneofs)
                    object._longDescrHtml = "longDescrHtml";
            }
            if (message.postTimeMs != null && message.hasOwnProperty("postTimeMs")) {
                if (typeof message.postTimeMs === "number")
                    object.postTimeMs = options.longs === String ? String(message.postTimeMs) : message.postTimeMs;
                else
                    object.postTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.postTimeMs) : options.longs === Number ? new $util.LongBits(message.postTimeMs.low >>> 0, message.postTimeMs.high >>> 0).toNumber() : message.postTimeMs;
                if (options.oneofs)
                    object._postTimeMs = "postTimeMs";
            }
            if (message.beingEdited != null && message.hasOwnProperty("beingEdited")) {
                object.beingEdited = message.beingEdited;
                if (options.oneofs)
                    object._beingEdited = "beingEdited";
            }
            if (message.projectPost != null && message.hasOwnProperty("projectPost")) {
                object.projectPost = $root.pl_types.ProjectPost.toObject(message.projectPost, options);
                if (options.oneofs)
                    object._projectPost = "projectPost";
            }
            if (message.aiPrompt != null && message.hasOwnProperty("aiPrompt")) {
                object.aiPrompt = message.aiPrompt;
                if (options.oneofs)
                    object._aiPrompt = "aiPrompt";
            }
            if (message.aiResponse != null && message.hasOwnProperty("aiResponse")) {
                object.aiResponse = message.aiResponse;
                if (options.oneofs)
                    object._aiResponse = "aiResponse";
            }
            if (message.taggedUserXs && message.taggedUserXs.length) {
                object.taggedUserXs = [];
                for (var j = 0; j < message.taggedUserXs.length; ++j)
                    object.taggedUserXs[j] = $root.pl_types.UserX.toObject(message.taggedUserXs[j], options);
            }
            return object;
        };

        /**
         * Converts this ProjectPostComment to JSON.
         * @function toJSON
         * @memberof pl_types.ProjectPostComment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProjectPostComment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProjectPostComment
         * @function getTypeUrl
         * @memberof pl_types.ProjectPostComment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProjectPostComment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pl_types.ProjectPostComment";
        };

        return ProjectPostComment;
    })();

    pl_types.ProjectPostRatingCategory = (function() {

        /**
         * Properties of a ProjectPostRatingCategory.
         * @memberof pl_types
         * @interface IProjectPostRatingCategory
         * @property {number|null} [projectInputFulfillmentId] ProjectPostRatingCategory projectInputFulfillmentId
         * @property {string|null} [category] ProjectPostRatingCategory category
         * @property {string|null} [value] ProjectPostRatingCategory value
         * @property {pl_types.ProjectInputCategory.ValueType|null} [valueType] ProjectPostRatingCategory valueType
         */

        /**
         * Constructs a new ProjectPostRatingCategory.
         * @memberof pl_types
         * @classdesc Represents a ProjectPostRatingCategory.
         * @implements IProjectPostRatingCategory
         * @constructor
         * @param {pl_types.IProjectPostRatingCategory=} [properties] Properties to set
         */
        function ProjectPostRatingCategory(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProjectPostRatingCategory projectInputFulfillmentId.
         * @member {number|null|undefined} projectInputFulfillmentId
         * @memberof pl_types.ProjectPostRatingCategory
         * @instance
         */
        ProjectPostRatingCategory.prototype.projectInputFulfillmentId = null;

        /**
         * ProjectPostRatingCategory category.
         * @member {string|null|undefined} category
         * @memberof pl_types.ProjectPostRatingCategory
         * @instance
         */
        ProjectPostRatingCategory.prototype.category = null;

        /**
         * ProjectPostRatingCategory value.
         * @member {string|null|undefined} value
         * @memberof pl_types.ProjectPostRatingCategory
         * @instance
         */
        ProjectPostRatingCategory.prototype.value = null;

        /**
         * ProjectPostRatingCategory valueType.
         * @member {pl_types.ProjectInputCategory.ValueType|null|undefined} valueType
         * @memberof pl_types.ProjectPostRatingCategory
         * @instance
         */
        ProjectPostRatingCategory.prototype.valueType = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ProjectPostRatingCategory _projectInputFulfillmentId.
         * @member {"projectInputFulfillmentId"|undefined} _projectInputFulfillmentId
         * @memberof pl_types.ProjectPostRatingCategory
         * @instance
         */
        Object.defineProperty(ProjectPostRatingCategory.prototype, "_projectInputFulfillmentId", {
            get: $util.oneOfGetter($oneOfFields = ["projectInputFulfillmentId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPostRatingCategory _category.
         * @member {"category"|undefined} _category
         * @memberof pl_types.ProjectPostRatingCategory
         * @instance
         */
        Object.defineProperty(ProjectPostRatingCategory.prototype, "_category", {
            get: $util.oneOfGetter($oneOfFields = ["category"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPostRatingCategory _value.
         * @member {"value"|undefined} _value
         * @memberof pl_types.ProjectPostRatingCategory
         * @instance
         */
        Object.defineProperty(ProjectPostRatingCategory.prototype, "_value", {
            get: $util.oneOfGetter($oneOfFields = ["value"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPostRatingCategory _valueType.
         * @member {"valueType"|undefined} _valueType
         * @memberof pl_types.ProjectPostRatingCategory
         * @instance
         */
        Object.defineProperty(ProjectPostRatingCategory.prototype, "_valueType", {
            get: $util.oneOfGetter($oneOfFields = ["valueType"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ProjectPostRatingCategory instance using the specified properties.
         * @function create
         * @memberof pl_types.ProjectPostRatingCategory
         * @static
         * @param {pl_types.IProjectPostRatingCategory=} [properties] Properties to set
         * @returns {pl_types.ProjectPostRatingCategory} ProjectPostRatingCategory instance
         */
        ProjectPostRatingCategory.create = function create(properties) {
            return new ProjectPostRatingCategory(properties);
        };

        /**
         * Encodes the specified ProjectPostRatingCategory message. Does not implicitly {@link pl_types.ProjectPostRatingCategory.verify|verify} messages.
         * @function encode
         * @memberof pl_types.ProjectPostRatingCategory
         * @static
         * @param {pl_types.IProjectPostRatingCategory} message ProjectPostRatingCategory message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectPostRatingCategory.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectInputFulfillmentId != null && Object.hasOwnProperty.call(message, "projectInputFulfillmentId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.projectInputFulfillmentId);
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.category);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
            if (message.valueType != null && Object.hasOwnProperty.call(message, "valueType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.valueType);
            return writer;
        };

        /**
         * Encodes the specified ProjectPostRatingCategory message, length delimited. Does not implicitly {@link pl_types.ProjectPostRatingCategory.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pl_types.ProjectPostRatingCategory
         * @static
         * @param {pl_types.IProjectPostRatingCategory} message ProjectPostRatingCategory message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectPostRatingCategory.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProjectPostRatingCategory message from the specified reader or buffer.
         * @function decode
         * @memberof pl_types.ProjectPostRatingCategory
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pl_types.ProjectPostRatingCategory} ProjectPostRatingCategory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectPostRatingCategory.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.ProjectPostRatingCategory();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.projectInputFulfillmentId = reader.int32();
                        break;
                    }
                case 2: {
                        message.category = reader.string();
                        break;
                    }
                case 3: {
                        message.value = reader.string();
                        break;
                    }
                case 4: {
                        message.valueType = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProjectPostRatingCategory message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pl_types.ProjectPostRatingCategory
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pl_types.ProjectPostRatingCategory} ProjectPostRatingCategory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectPostRatingCategory.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProjectPostRatingCategory message.
         * @function verify
         * @memberof pl_types.ProjectPostRatingCategory
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProjectPostRatingCategory.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.projectInputFulfillmentId != null && message.hasOwnProperty("projectInputFulfillmentId")) {
                properties._projectInputFulfillmentId = 1;
                if (!$util.isInteger(message.projectInputFulfillmentId))
                    return "projectInputFulfillmentId: integer expected";
            }
            if (message.category != null && message.hasOwnProperty("category")) {
                properties._category = 1;
                if (!$util.isString(message.category))
                    return "category: string expected";
            }
            if (message.value != null && message.hasOwnProperty("value")) {
                properties._value = 1;
                if (!$util.isString(message.value))
                    return "value: string expected";
            }
            if (message.valueType != null && message.hasOwnProperty("valueType")) {
                properties._valueType = 1;
                switch (message.valueType) {
                default:
                    return "valueType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            }
            return null;
        };

        /**
         * Creates a ProjectPostRatingCategory message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pl_types.ProjectPostRatingCategory
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pl_types.ProjectPostRatingCategory} ProjectPostRatingCategory
         */
        ProjectPostRatingCategory.fromObject = function fromObject(object) {
            if (object instanceof $root.pl_types.ProjectPostRatingCategory)
                return object;
            var message = new $root.pl_types.ProjectPostRatingCategory();
            if (object.projectInputFulfillmentId != null)
                message.projectInputFulfillmentId = object.projectInputFulfillmentId | 0;
            if (object.category != null)
                message.category = String(object.category);
            if (object.value != null)
                message.value = String(object.value);
            switch (object.valueType) {
            default:
                if (typeof object.valueType === "number") {
                    message.valueType = object.valueType;
                    break;
                }
                break;
            case "UNSET_VALUE_TYPE":
            case 0:
                message.valueType = 0;
                break;
            case "FREE_TEXT":
            case 1:
                message.valueType = 1;
                break;
            case "EKS":
            case 2:
                message.valueType = 2;
                break;
            case "XQ_COMPETENCY":
            case 3:
                message.valueType = 3;
                break;
            case "MOTIVATION":
            case 4:
                message.valueType = 4;
                break;
            case "CTE":
            case 5:
                message.valueType = 5;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ProjectPostRatingCategory message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pl_types.ProjectPostRatingCategory
         * @static
         * @param {pl_types.ProjectPostRatingCategory} message ProjectPostRatingCategory
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProjectPostRatingCategory.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.projectInputFulfillmentId != null && message.hasOwnProperty("projectInputFulfillmentId")) {
                object.projectInputFulfillmentId = message.projectInputFulfillmentId;
                if (options.oneofs)
                    object._projectInputFulfillmentId = "projectInputFulfillmentId";
            }
            if (message.category != null && message.hasOwnProperty("category")) {
                object.category = message.category;
                if (options.oneofs)
                    object._category = "category";
            }
            if (message.value != null && message.hasOwnProperty("value")) {
                object.value = message.value;
                if (options.oneofs)
                    object._value = "value";
            }
            if (message.valueType != null && message.hasOwnProperty("valueType")) {
                object.valueType = options.enums === String ? $root.pl_types.ProjectInputCategory.ValueType[message.valueType] === undefined ? message.valueType : $root.pl_types.ProjectInputCategory.ValueType[message.valueType] : message.valueType;
                if (options.oneofs)
                    object._valueType = "valueType";
            }
            return object;
        };

        /**
         * Converts this ProjectPostRatingCategory to JSON.
         * @function toJSON
         * @memberof pl_types.ProjectPostRatingCategory
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProjectPostRatingCategory.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProjectPostRatingCategory
         * @function getTypeUrl
         * @memberof pl_types.ProjectPostRatingCategory
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProjectPostRatingCategory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pl_types.ProjectPostRatingCategory";
        };

        return ProjectPostRatingCategory;
    })();

    pl_types.ProjectPostRating = (function() {

        /**
         * Properties of a ProjectPostRating.
         * @memberof pl_types
         * @interface IProjectPostRating
         * @property {number|null} [id] ProjectPostRating id
         * @property {pl_types.IUserX|null} [userX] ProjectPostRating userX
         * @property {number|null} [rating] ProjectPostRating rating
         * @property {pl_types.ProjectPostRating.RatingType|null} [ratingType] ProjectPostRating ratingType
         * @property {pl_types.IProjectPost|null} [projectPost] ProjectPostRating projectPost
         * @property {pl_types.IKnowledgeAndSkill|null} [knowledgeAndSkill] ProjectPostRating knowledgeAndSkill
         * @property {number|null} [projectInputFulfillmentId] ProjectPostRating projectInputFulfillmentId
         */

        /**
         * Constructs a new ProjectPostRating.
         * @memberof pl_types
         * @classdesc Represents a ProjectPostRating.
         * @implements IProjectPostRating
         * @constructor
         * @param {pl_types.IProjectPostRating=} [properties] Properties to set
         */
        function ProjectPostRating(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProjectPostRating id.
         * @member {number|null|undefined} id
         * @memberof pl_types.ProjectPostRating
         * @instance
         */
        ProjectPostRating.prototype.id = null;

        /**
         * ProjectPostRating userX.
         * @member {pl_types.IUserX|null|undefined} userX
         * @memberof pl_types.ProjectPostRating
         * @instance
         */
        ProjectPostRating.prototype.userX = null;

        /**
         * ProjectPostRating rating.
         * @member {number|null|undefined} rating
         * @memberof pl_types.ProjectPostRating
         * @instance
         */
        ProjectPostRating.prototype.rating = null;

        /**
         * ProjectPostRating ratingType.
         * @member {pl_types.ProjectPostRating.RatingType|null|undefined} ratingType
         * @memberof pl_types.ProjectPostRating
         * @instance
         */
        ProjectPostRating.prototype.ratingType = null;

        /**
         * ProjectPostRating projectPost.
         * @member {pl_types.IProjectPost|null|undefined} projectPost
         * @memberof pl_types.ProjectPostRating
         * @instance
         */
        ProjectPostRating.prototype.projectPost = null;

        /**
         * ProjectPostRating knowledgeAndSkill.
         * @member {pl_types.IKnowledgeAndSkill|null|undefined} knowledgeAndSkill
         * @memberof pl_types.ProjectPostRating
         * @instance
         */
        ProjectPostRating.prototype.knowledgeAndSkill = null;

        /**
         * ProjectPostRating projectInputFulfillmentId.
         * @member {number|null|undefined} projectInputFulfillmentId
         * @memberof pl_types.ProjectPostRating
         * @instance
         */
        ProjectPostRating.prototype.projectInputFulfillmentId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ProjectPostRating _id.
         * @member {"id"|undefined} _id
         * @memberof pl_types.ProjectPostRating
         * @instance
         */
        Object.defineProperty(ProjectPostRating.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPostRating _userX.
         * @member {"userX"|undefined} _userX
         * @memberof pl_types.ProjectPostRating
         * @instance
         */
        Object.defineProperty(ProjectPostRating.prototype, "_userX", {
            get: $util.oneOfGetter($oneOfFields = ["userX"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPostRating _rating.
         * @member {"rating"|undefined} _rating
         * @memberof pl_types.ProjectPostRating
         * @instance
         */
        Object.defineProperty(ProjectPostRating.prototype, "_rating", {
            get: $util.oneOfGetter($oneOfFields = ["rating"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPostRating _ratingType.
         * @member {"ratingType"|undefined} _ratingType
         * @memberof pl_types.ProjectPostRating
         * @instance
         */
        Object.defineProperty(ProjectPostRating.prototype, "_ratingType", {
            get: $util.oneOfGetter($oneOfFields = ["ratingType"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPostRating _projectPost.
         * @member {"projectPost"|undefined} _projectPost
         * @memberof pl_types.ProjectPostRating
         * @instance
         */
        Object.defineProperty(ProjectPostRating.prototype, "_projectPost", {
            get: $util.oneOfGetter($oneOfFields = ["projectPost"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPostRating _knowledgeAndSkill.
         * @member {"knowledgeAndSkill"|undefined} _knowledgeAndSkill
         * @memberof pl_types.ProjectPostRating
         * @instance
         */
        Object.defineProperty(ProjectPostRating.prototype, "_knowledgeAndSkill", {
            get: $util.oneOfGetter($oneOfFields = ["knowledgeAndSkill"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPostRating _projectInputFulfillmentId.
         * @member {"projectInputFulfillmentId"|undefined} _projectInputFulfillmentId
         * @memberof pl_types.ProjectPostRating
         * @instance
         */
        Object.defineProperty(ProjectPostRating.prototype, "_projectInputFulfillmentId", {
            get: $util.oneOfGetter($oneOfFields = ["projectInputFulfillmentId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ProjectPostRating instance using the specified properties.
         * @function create
         * @memberof pl_types.ProjectPostRating
         * @static
         * @param {pl_types.IProjectPostRating=} [properties] Properties to set
         * @returns {pl_types.ProjectPostRating} ProjectPostRating instance
         */
        ProjectPostRating.create = function create(properties) {
            return new ProjectPostRating(properties);
        };

        /**
         * Encodes the specified ProjectPostRating message. Does not implicitly {@link pl_types.ProjectPostRating.verify|verify} messages.
         * @function encode
         * @memberof pl_types.ProjectPostRating
         * @static
         * @param {pl_types.IProjectPostRating} message ProjectPostRating message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectPostRating.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.userX != null && Object.hasOwnProperty.call(message, "userX"))
                $root.pl_types.UserX.encode(message.userX, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.rating != null && Object.hasOwnProperty.call(message, "rating"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.rating);
            if (message.ratingType != null && Object.hasOwnProperty.call(message, "ratingType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.ratingType);
            if (message.projectPost != null && Object.hasOwnProperty.call(message, "projectPost"))
                $root.pl_types.ProjectPost.encode(message.projectPost, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.knowledgeAndSkill != null && Object.hasOwnProperty.call(message, "knowledgeAndSkill"))
                $root.pl_types.KnowledgeAndSkill.encode(message.knowledgeAndSkill, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.projectInputFulfillmentId != null && Object.hasOwnProperty.call(message, "projectInputFulfillmentId"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.projectInputFulfillmentId);
            return writer;
        };

        /**
         * Encodes the specified ProjectPostRating message, length delimited. Does not implicitly {@link pl_types.ProjectPostRating.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pl_types.ProjectPostRating
         * @static
         * @param {pl_types.IProjectPostRating} message ProjectPostRating message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectPostRating.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProjectPostRating message from the specified reader or buffer.
         * @function decode
         * @memberof pl_types.ProjectPostRating
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pl_types.ProjectPostRating} ProjectPostRating
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectPostRating.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.ProjectPostRating();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.userX = $root.pl_types.UserX.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.rating = reader.int32();
                        break;
                    }
                case 4: {
                        message.ratingType = reader.int32();
                        break;
                    }
                case 5: {
                        message.projectPost = $root.pl_types.ProjectPost.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.knowledgeAndSkill = $root.pl_types.KnowledgeAndSkill.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.projectInputFulfillmentId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProjectPostRating message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pl_types.ProjectPostRating
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pl_types.ProjectPostRating} ProjectPostRating
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectPostRating.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProjectPostRating message.
         * @function verify
         * @memberof pl_types.ProjectPostRating
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProjectPostRating.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            }
            if (message.userX != null && message.hasOwnProperty("userX")) {
                properties._userX = 1;
                {
                    var error = $root.pl_types.UserX.verify(message.userX);
                    if (error)
                        return "userX." + error;
                }
            }
            if (message.rating != null && message.hasOwnProperty("rating")) {
                properties._rating = 1;
                if (!$util.isInteger(message.rating))
                    return "rating: integer expected";
            }
            if (message.ratingType != null && message.hasOwnProperty("ratingType")) {
                properties._ratingType = 1;
                switch (message.ratingType) {
                default:
                    return "ratingType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            }
            if (message.projectPost != null && message.hasOwnProperty("projectPost")) {
                properties._projectPost = 1;
                {
                    var error = $root.pl_types.ProjectPost.verify(message.projectPost);
                    if (error)
                        return "projectPost." + error;
                }
            }
            if (message.knowledgeAndSkill != null && message.hasOwnProperty("knowledgeAndSkill")) {
                properties._knowledgeAndSkill = 1;
                {
                    var error = $root.pl_types.KnowledgeAndSkill.verify(message.knowledgeAndSkill);
                    if (error)
                        return "knowledgeAndSkill." + error;
                }
            }
            if (message.projectInputFulfillmentId != null && message.hasOwnProperty("projectInputFulfillmentId")) {
                properties._projectInputFulfillmentId = 1;
                if (!$util.isInteger(message.projectInputFulfillmentId))
                    return "projectInputFulfillmentId: integer expected";
            }
            return null;
        };

        /**
         * Creates a ProjectPostRating message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pl_types.ProjectPostRating
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pl_types.ProjectPostRating} ProjectPostRating
         */
        ProjectPostRating.fromObject = function fromObject(object) {
            if (object instanceof $root.pl_types.ProjectPostRating)
                return object;
            var message = new $root.pl_types.ProjectPostRating();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.userX != null) {
                if (typeof object.userX !== "object")
                    throw TypeError(".pl_types.ProjectPostRating.userX: object expected");
                message.userX = $root.pl_types.UserX.fromObject(object.userX);
            }
            if (object.rating != null)
                message.rating = object.rating | 0;
            switch (object.ratingType) {
            default:
                if (typeof object.ratingType === "number") {
                    message.ratingType = object.ratingType;
                    break;
                }
                break;
            case "UNSET_RATING_TYPE":
            case 0:
                message.ratingType = 0;
                break;
            case "INITIAL_1_TO_5":
            case 1:
                message.ratingType = 1;
                break;
            case "GOAL_COMPLETE_PCT":
            case 2:
                message.ratingType = 2;
                break;
            }
            if (object.projectPost != null) {
                if (typeof object.projectPost !== "object")
                    throw TypeError(".pl_types.ProjectPostRating.projectPost: object expected");
                message.projectPost = $root.pl_types.ProjectPost.fromObject(object.projectPost);
            }
            if (object.knowledgeAndSkill != null) {
                if (typeof object.knowledgeAndSkill !== "object")
                    throw TypeError(".pl_types.ProjectPostRating.knowledgeAndSkill: object expected");
                message.knowledgeAndSkill = $root.pl_types.KnowledgeAndSkill.fromObject(object.knowledgeAndSkill);
            }
            if (object.projectInputFulfillmentId != null)
                message.projectInputFulfillmentId = object.projectInputFulfillmentId | 0;
            return message;
        };

        /**
         * Creates a plain object from a ProjectPostRating message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pl_types.ProjectPostRating
         * @static
         * @param {pl_types.ProjectPostRating} message ProjectPostRating
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProjectPostRating.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.userX != null && message.hasOwnProperty("userX")) {
                object.userX = $root.pl_types.UserX.toObject(message.userX, options);
                if (options.oneofs)
                    object._userX = "userX";
            }
            if (message.rating != null && message.hasOwnProperty("rating")) {
                object.rating = message.rating;
                if (options.oneofs)
                    object._rating = "rating";
            }
            if (message.ratingType != null && message.hasOwnProperty("ratingType")) {
                object.ratingType = options.enums === String ? $root.pl_types.ProjectPostRating.RatingType[message.ratingType] === undefined ? message.ratingType : $root.pl_types.ProjectPostRating.RatingType[message.ratingType] : message.ratingType;
                if (options.oneofs)
                    object._ratingType = "ratingType";
            }
            if (message.projectPost != null && message.hasOwnProperty("projectPost")) {
                object.projectPost = $root.pl_types.ProjectPost.toObject(message.projectPost, options);
                if (options.oneofs)
                    object._projectPost = "projectPost";
            }
            if (message.knowledgeAndSkill != null && message.hasOwnProperty("knowledgeAndSkill")) {
                object.knowledgeAndSkill = $root.pl_types.KnowledgeAndSkill.toObject(message.knowledgeAndSkill, options);
                if (options.oneofs)
                    object._knowledgeAndSkill = "knowledgeAndSkill";
            }
            if (message.projectInputFulfillmentId != null && message.hasOwnProperty("projectInputFulfillmentId")) {
                object.projectInputFulfillmentId = message.projectInputFulfillmentId;
                if (options.oneofs)
                    object._projectInputFulfillmentId = "projectInputFulfillmentId";
            }
            return object;
        };

        /**
         * Converts this ProjectPostRating to JSON.
         * @function toJSON
         * @memberof pl_types.ProjectPostRating
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProjectPostRating.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProjectPostRating
         * @function getTypeUrl
         * @memberof pl_types.ProjectPostRating
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProjectPostRating.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pl_types.ProjectPostRating";
        };

        /**
         * RatingType enum.
         * @name pl_types.ProjectPostRating.RatingType
         * @enum {number}
         * @property {number} UNSET_RATING_TYPE=0 UNSET_RATING_TYPE value
         * @property {number} INITIAL_1_TO_5=1 INITIAL_1_TO_5 value
         * @property {number} GOAL_COMPLETE_PCT=2 GOAL_COMPLETE_PCT value
         */
        ProjectPostRating.RatingType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNSET_RATING_TYPE"] = 0;
            values[valuesById[1] = "INITIAL_1_TO_5"] = 1;
            values[valuesById[2] = "GOAL_COMPLETE_PCT"] = 2;
            return values;
        })();

        return ProjectPostRating;
    })();

    pl_types.ProjectPost = (function() {

        /**
         * Properties of a ProjectPost.
         * @memberof pl_types
         * @interface IProjectPost
         * @property {number|null} [id] ProjectPost id
         * @property {pl_types.IUserX|null} [userX] ProjectPost userX
         * @property {pl_types.IProject|null} [project] ProjectPost project
         * @property {string|null} [name] ProjectPost name
         * @property {string|null} [longDescrHtml] ProjectPost longDescrHtml
         * @property {string|null} [desiredFeedback] ProjectPost desiredFeedback
         * @property {Array.<pl_types.ITag>|null} [tags] ProjectPost tags
         * @property {Array.<pl_types.IProjectPostComment>|null} [comments] ProjectPost comments
         * @property {Long|null} [postTimeMs] ProjectPost postTimeMs
         * @property {boolean|null} [beingEdited] ProjectPost beingEdited
         * @property {Array.<pl_types.IProjectPostRatingCategory>|null} [ratingCategories] ProjectPost ratingCategories
         * @property {Array.<pl_types.IProjectPostRating>|null} [ratings] ProjectPost ratings
         * @property {boolean|null} [includeInPortfolio] ProjectPost includeInPortfolio
         * @property {string|null} [portfolioNoteHtml] ProjectPost portfolioNoteHtml
         * @property {Array.<pl_types.IUserX>|null} [taggedUserXs] ProjectPost taggedUserXs
         */

        /**
         * Constructs a new ProjectPost.
         * @memberof pl_types
         * @classdesc Represents a ProjectPost.
         * @implements IProjectPost
         * @constructor
         * @param {pl_types.IProjectPost=} [properties] Properties to set
         */
        function ProjectPost(properties) {
            this.tags = [];
            this.comments = [];
            this.ratingCategories = [];
            this.ratings = [];
            this.taggedUserXs = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProjectPost id.
         * @member {number|null|undefined} id
         * @memberof pl_types.ProjectPost
         * @instance
         */
        ProjectPost.prototype.id = null;

        /**
         * ProjectPost userX.
         * @member {pl_types.IUserX|null|undefined} userX
         * @memberof pl_types.ProjectPost
         * @instance
         */
        ProjectPost.prototype.userX = null;

        /**
         * ProjectPost project.
         * @member {pl_types.IProject|null|undefined} project
         * @memberof pl_types.ProjectPost
         * @instance
         */
        ProjectPost.prototype.project = null;

        /**
         * ProjectPost name.
         * @member {string|null|undefined} name
         * @memberof pl_types.ProjectPost
         * @instance
         */
        ProjectPost.prototype.name = null;

        /**
         * ProjectPost longDescrHtml.
         * @member {string|null|undefined} longDescrHtml
         * @memberof pl_types.ProjectPost
         * @instance
         */
        ProjectPost.prototype.longDescrHtml = null;

        /**
         * ProjectPost desiredFeedback.
         * @member {string|null|undefined} desiredFeedback
         * @memberof pl_types.ProjectPost
         * @instance
         */
        ProjectPost.prototype.desiredFeedback = null;

        /**
         * ProjectPost tags.
         * @member {Array.<pl_types.ITag>} tags
         * @memberof pl_types.ProjectPost
         * @instance
         */
        ProjectPost.prototype.tags = $util.emptyArray;

        /**
         * ProjectPost comments.
         * @member {Array.<pl_types.IProjectPostComment>} comments
         * @memberof pl_types.ProjectPost
         * @instance
         */
        ProjectPost.prototype.comments = $util.emptyArray;

        /**
         * ProjectPost postTimeMs.
         * @member {Long|null|undefined} postTimeMs
         * @memberof pl_types.ProjectPost
         * @instance
         */
        ProjectPost.prototype.postTimeMs = null;

        /**
         * ProjectPost beingEdited.
         * @member {boolean|null|undefined} beingEdited
         * @memberof pl_types.ProjectPost
         * @instance
         */
        ProjectPost.prototype.beingEdited = null;

        /**
         * ProjectPost ratingCategories.
         * @member {Array.<pl_types.IProjectPostRatingCategory>} ratingCategories
         * @memberof pl_types.ProjectPost
         * @instance
         */
        ProjectPost.prototype.ratingCategories = $util.emptyArray;

        /**
         * ProjectPost ratings.
         * @member {Array.<pl_types.IProjectPostRating>} ratings
         * @memberof pl_types.ProjectPost
         * @instance
         */
        ProjectPost.prototype.ratings = $util.emptyArray;

        /**
         * ProjectPost includeInPortfolio.
         * @member {boolean|null|undefined} includeInPortfolio
         * @memberof pl_types.ProjectPost
         * @instance
         */
        ProjectPost.prototype.includeInPortfolio = null;

        /**
         * ProjectPost portfolioNoteHtml.
         * @member {string|null|undefined} portfolioNoteHtml
         * @memberof pl_types.ProjectPost
         * @instance
         */
        ProjectPost.prototype.portfolioNoteHtml = null;

        /**
         * ProjectPost taggedUserXs.
         * @member {Array.<pl_types.IUserX>} taggedUserXs
         * @memberof pl_types.ProjectPost
         * @instance
         */
        ProjectPost.prototype.taggedUserXs = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ProjectPost _id.
         * @member {"id"|undefined} _id
         * @memberof pl_types.ProjectPost
         * @instance
         */
        Object.defineProperty(ProjectPost.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPost _userX.
         * @member {"userX"|undefined} _userX
         * @memberof pl_types.ProjectPost
         * @instance
         */
        Object.defineProperty(ProjectPost.prototype, "_userX", {
            get: $util.oneOfGetter($oneOfFields = ["userX"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPost _project.
         * @member {"project"|undefined} _project
         * @memberof pl_types.ProjectPost
         * @instance
         */
        Object.defineProperty(ProjectPost.prototype, "_project", {
            get: $util.oneOfGetter($oneOfFields = ["project"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPost _name.
         * @member {"name"|undefined} _name
         * @memberof pl_types.ProjectPost
         * @instance
         */
        Object.defineProperty(ProjectPost.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPost _longDescrHtml.
         * @member {"longDescrHtml"|undefined} _longDescrHtml
         * @memberof pl_types.ProjectPost
         * @instance
         */
        Object.defineProperty(ProjectPost.prototype, "_longDescrHtml", {
            get: $util.oneOfGetter($oneOfFields = ["longDescrHtml"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPost _desiredFeedback.
         * @member {"desiredFeedback"|undefined} _desiredFeedback
         * @memberof pl_types.ProjectPost
         * @instance
         */
        Object.defineProperty(ProjectPost.prototype, "_desiredFeedback", {
            get: $util.oneOfGetter($oneOfFields = ["desiredFeedback"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPost _postTimeMs.
         * @member {"postTimeMs"|undefined} _postTimeMs
         * @memberof pl_types.ProjectPost
         * @instance
         */
        Object.defineProperty(ProjectPost.prototype, "_postTimeMs", {
            get: $util.oneOfGetter($oneOfFields = ["postTimeMs"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPost _beingEdited.
         * @member {"beingEdited"|undefined} _beingEdited
         * @memberof pl_types.ProjectPost
         * @instance
         */
        Object.defineProperty(ProjectPost.prototype, "_beingEdited", {
            get: $util.oneOfGetter($oneOfFields = ["beingEdited"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPost _includeInPortfolio.
         * @member {"includeInPortfolio"|undefined} _includeInPortfolio
         * @memberof pl_types.ProjectPost
         * @instance
         */
        Object.defineProperty(ProjectPost.prototype, "_includeInPortfolio", {
            get: $util.oneOfGetter($oneOfFields = ["includeInPortfolio"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProjectPost _portfolioNoteHtml.
         * @member {"portfolioNoteHtml"|undefined} _portfolioNoteHtml
         * @memberof pl_types.ProjectPost
         * @instance
         */
        Object.defineProperty(ProjectPost.prototype, "_portfolioNoteHtml", {
            get: $util.oneOfGetter($oneOfFields = ["portfolioNoteHtml"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ProjectPost instance using the specified properties.
         * @function create
         * @memberof pl_types.ProjectPost
         * @static
         * @param {pl_types.IProjectPost=} [properties] Properties to set
         * @returns {pl_types.ProjectPost} ProjectPost instance
         */
        ProjectPost.create = function create(properties) {
            return new ProjectPost(properties);
        };

        /**
         * Encodes the specified ProjectPost message. Does not implicitly {@link pl_types.ProjectPost.verify|verify} messages.
         * @function encode
         * @memberof pl_types.ProjectPost
         * @static
         * @param {pl_types.IProjectPost} message ProjectPost message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectPost.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.userX != null && Object.hasOwnProperty.call(message, "userX"))
                $root.pl_types.UserX.encode(message.userX, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.longDescrHtml != null && Object.hasOwnProperty.call(message, "longDescrHtml"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.longDescrHtml);
            if (message.desiredFeedback != null && Object.hasOwnProperty.call(message, "desiredFeedback"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.desiredFeedback);
            if (message.tags != null && message.tags.length)
                for (var i = 0; i < message.tags.length; ++i)
                    $root.pl_types.Tag.encode(message.tags[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.postTimeMs != null && Object.hasOwnProperty.call(message, "postTimeMs"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.postTimeMs);
            if (message.beingEdited != null && Object.hasOwnProperty.call(message, "beingEdited"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.beingEdited);
            if (message.comments != null && message.comments.length)
                for (var i = 0; i < message.comments.length; ++i)
                    $root.pl_types.ProjectPostComment.encode(message.comments[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.project != null && Object.hasOwnProperty.call(message, "project"))
                $root.pl_types.Project.encode(message.project, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.ratings != null && message.ratings.length)
                for (var i = 0; i < message.ratings.length; ++i)
                    $root.pl_types.ProjectPostRating.encode(message.ratings[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.ratingCategories != null && message.ratingCategories.length)
                for (var i = 0; i < message.ratingCategories.length; ++i)
                    $root.pl_types.ProjectPostRatingCategory.encode(message.ratingCategories[i], writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.includeInPortfolio != null && Object.hasOwnProperty.call(message, "includeInPortfolio"))
                writer.uint32(/* id 15, wireType 0 =*/120).bool(message.includeInPortfolio);
            if (message.portfolioNoteHtml != null && Object.hasOwnProperty.call(message, "portfolioNoteHtml"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.portfolioNoteHtml);
            if (message.taggedUserXs != null && message.taggedUserXs.length)
                for (var i = 0; i < message.taggedUserXs.length; ++i)
                    $root.pl_types.UserX.encode(message.taggedUserXs[i], writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ProjectPost message, length delimited. Does not implicitly {@link pl_types.ProjectPost.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pl_types.ProjectPost
         * @static
         * @param {pl_types.IProjectPost} message ProjectPost message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectPost.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProjectPost message from the specified reader or buffer.
         * @function decode
         * @memberof pl_types.ProjectPost
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pl_types.ProjectPost} ProjectPost
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectPost.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.ProjectPost();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.userX = $root.pl_types.UserX.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.project = $root.pl_types.Project.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.longDescrHtml = reader.string();
                        break;
                    }
                case 6: {
                        message.desiredFeedback = reader.string();
                        break;
                    }
                case 7: {
                        if (!(message.tags && message.tags.length))
                            message.tags = [];
                        message.tags.push($root.pl_types.Tag.decode(reader, reader.uint32()));
                        break;
                    }
                case 11: {
                        if (!(message.comments && message.comments.length))
                            message.comments = [];
                        message.comments.push($root.pl_types.ProjectPostComment.decode(reader, reader.uint32()));
                        break;
                    }
                case 8: {
                        message.postTimeMs = reader.int64();
                        break;
                    }
                case 9: {
                        message.beingEdited = reader.bool();
                        break;
                    }
                case 14: {
                        if (!(message.ratingCategories && message.ratingCategories.length))
                            message.ratingCategories = [];
                        message.ratingCategories.push($root.pl_types.ProjectPostRatingCategory.decode(reader, reader.uint32()));
                        break;
                    }
                case 13: {
                        if (!(message.ratings && message.ratings.length))
                            message.ratings = [];
                        message.ratings.push($root.pl_types.ProjectPostRating.decode(reader, reader.uint32()));
                        break;
                    }
                case 15: {
                        message.includeInPortfolio = reader.bool();
                        break;
                    }
                case 16: {
                        message.portfolioNoteHtml = reader.string();
                        break;
                    }
                case 17: {
                        if (!(message.taggedUserXs && message.taggedUserXs.length))
                            message.taggedUserXs = [];
                        message.taggedUserXs.push($root.pl_types.UserX.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProjectPost message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pl_types.ProjectPost
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pl_types.ProjectPost} ProjectPost
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectPost.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProjectPost message.
         * @function verify
         * @memberof pl_types.ProjectPost
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProjectPost.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            }
            if (message.userX != null && message.hasOwnProperty("userX")) {
                properties._userX = 1;
                {
                    var error = $root.pl_types.UserX.verify(message.userX);
                    if (error)
                        return "userX." + error;
                }
            }
            if (message.project != null && message.hasOwnProperty("project")) {
                properties._project = 1;
                {
                    var error = $root.pl_types.Project.verify(message.project);
                    if (error)
                        return "project." + error;
                }
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            if (message.longDescrHtml != null && message.hasOwnProperty("longDescrHtml")) {
                properties._longDescrHtml = 1;
                if (!$util.isString(message.longDescrHtml))
                    return "longDescrHtml: string expected";
            }
            if (message.desiredFeedback != null && message.hasOwnProperty("desiredFeedback")) {
                properties._desiredFeedback = 1;
                if (!$util.isString(message.desiredFeedback))
                    return "desiredFeedback: string expected";
            }
            if (message.tags != null && message.hasOwnProperty("tags")) {
                if (!Array.isArray(message.tags))
                    return "tags: array expected";
                for (var i = 0; i < message.tags.length; ++i) {
                    var error = $root.pl_types.Tag.verify(message.tags[i]);
                    if (error)
                        return "tags." + error;
                }
            }
            if (message.comments != null && message.hasOwnProperty("comments")) {
                if (!Array.isArray(message.comments))
                    return "comments: array expected";
                for (var i = 0; i < message.comments.length; ++i) {
                    var error = $root.pl_types.ProjectPostComment.verify(message.comments[i]);
                    if (error)
                        return "comments." + error;
                }
            }
            if (message.postTimeMs != null && message.hasOwnProperty("postTimeMs")) {
                properties._postTimeMs = 1;
                if (!$util.isInteger(message.postTimeMs) && !(message.postTimeMs && $util.isInteger(message.postTimeMs.low) && $util.isInteger(message.postTimeMs.high)))
                    return "postTimeMs: integer|Long expected";
            }
            if (message.beingEdited != null && message.hasOwnProperty("beingEdited")) {
                properties._beingEdited = 1;
                if (typeof message.beingEdited !== "boolean")
                    return "beingEdited: boolean expected";
            }
            if (message.ratingCategories != null && message.hasOwnProperty("ratingCategories")) {
                if (!Array.isArray(message.ratingCategories))
                    return "ratingCategories: array expected";
                for (var i = 0; i < message.ratingCategories.length; ++i) {
                    var error = $root.pl_types.ProjectPostRatingCategory.verify(message.ratingCategories[i]);
                    if (error)
                        return "ratingCategories." + error;
                }
            }
            if (message.ratings != null && message.hasOwnProperty("ratings")) {
                if (!Array.isArray(message.ratings))
                    return "ratings: array expected";
                for (var i = 0; i < message.ratings.length; ++i) {
                    var error = $root.pl_types.ProjectPostRating.verify(message.ratings[i]);
                    if (error)
                        return "ratings." + error;
                }
            }
            if (message.includeInPortfolio != null && message.hasOwnProperty("includeInPortfolio")) {
                properties._includeInPortfolio = 1;
                if (typeof message.includeInPortfolio !== "boolean")
                    return "includeInPortfolio: boolean expected";
            }
            if (message.portfolioNoteHtml != null && message.hasOwnProperty("portfolioNoteHtml")) {
                properties._portfolioNoteHtml = 1;
                if (!$util.isString(message.portfolioNoteHtml))
                    return "portfolioNoteHtml: string expected";
            }
            if (message.taggedUserXs != null && message.hasOwnProperty("taggedUserXs")) {
                if (!Array.isArray(message.taggedUserXs))
                    return "taggedUserXs: array expected";
                for (var i = 0; i < message.taggedUserXs.length; ++i) {
                    var error = $root.pl_types.UserX.verify(message.taggedUserXs[i]);
                    if (error)
                        return "taggedUserXs." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ProjectPost message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pl_types.ProjectPost
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pl_types.ProjectPost} ProjectPost
         */
        ProjectPost.fromObject = function fromObject(object) {
            if (object instanceof $root.pl_types.ProjectPost)
                return object;
            var message = new $root.pl_types.ProjectPost();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.userX != null) {
                if (typeof object.userX !== "object")
                    throw TypeError(".pl_types.ProjectPost.userX: object expected");
                message.userX = $root.pl_types.UserX.fromObject(object.userX);
            }
            if (object.project != null) {
                if (typeof object.project !== "object")
                    throw TypeError(".pl_types.ProjectPost.project: object expected");
                message.project = $root.pl_types.Project.fromObject(object.project);
            }
            if (object.name != null)
                message.name = String(object.name);
            if (object.longDescrHtml != null)
                message.longDescrHtml = String(object.longDescrHtml);
            if (object.desiredFeedback != null)
                message.desiredFeedback = String(object.desiredFeedback);
            if (object.tags) {
                if (!Array.isArray(object.tags))
                    throw TypeError(".pl_types.ProjectPost.tags: array expected");
                message.tags = [];
                for (var i = 0; i < object.tags.length; ++i) {
                    if (typeof object.tags[i] !== "object")
                        throw TypeError(".pl_types.ProjectPost.tags: object expected");
                    message.tags[i] = $root.pl_types.Tag.fromObject(object.tags[i]);
                }
            }
            if (object.comments) {
                if (!Array.isArray(object.comments))
                    throw TypeError(".pl_types.ProjectPost.comments: array expected");
                message.comments = [];
                for (var i = 0; i < object.comments.length; ++i) {
                    if (typeof object.comments[i] !== "object")
                        throw TypeError(".pl_types.ProjectPost.comments: object expected");
                    message.comments[i] = $root.pl_types.ProjectPostComment.fromObject(object.comments[i]);
                }
            }
            if (object.postTimeMs != null)
                if ($util.Long)
                    (message.postTimeMs = $util.Long.fromValue(object.postTimeMs)).unsigned = false;
                else if (typeof object.postTimeMs === "string")
                    message.postTimeMs = parseInt(object.postTimeMs, 10);
                else if (typeof object.postTimeMs === "number")
                    message.postTimeMs = object.postTimeMs;
                else if (typeof object.postTimeMs === "object")
                    message.postTimeMs = new $util.LongBits(object.postTimeMs.low >>> 0, object.postTimeMs.high >>> 0).toNumber();
            if (object.beingEdited != null)
                message.beingEdited = Boolean(object.beingEdited);
            if (object.ratingCategories) {
                if (!Array.isArray(object.ratingCategories))
                    throw TypeError(".pl_types.ProjectPost.ratingCategories: array expected");
                message.ratingCategories = [];
                for (var i = 0; i < object.ratingCategories.length; ++i) {
                    if (typeof object.ratingCategories[i] !== "object")
                        throw TypeError(".pl_types.ProjectPost.ratingCategories: object expected");
                    message.ratingCategories[i] = $root.pl_types.ProjectPostRatingCategory.fromObject(object.ratingCategories[i]);
                }
            }
            if (object.ratings) {
                if (!Array.isArray(object.ratings))
                    throw TypeError(".pl_types.ProjectPost.ratings: array expected");
                message.ratings = [];
                for (var i = 0; i < object.ratings.length; ++i) {
                    if (typeof object.ratings[i] !== "object")
                        throw TypeError(".pl_types.ProjectPost.ratings: object expected");
                    message.ratings[i] = $root.pl_types.ProjectPostRating.fromObject(object.ratings[i]);
                }
            }
            if (object.includeInPortfolio != null)
                message.includeInPortfolio = Boolean(object.includeInPortfolio);
            if (object.portfolioNoteHtml != null)
                message.portfolioNoteHtml = String(object.portfolioNoteHtml);
            if (object.taggedUserXs) {
                if (!Array.isArray(object.taggedUserXs))
                    throw TypeError(".pl_types.ProjectPost.taggedUserXs: array expected");
                message.taggedUserXs = [];
                for (var i = 0; i < object.taggedUserXs.length; ++i) {
                    if (typeof object.taggedUserXs[i] !== "object")
                        throw TypeError(".pl_types.ProjectPost.taggedUserXs: object expected");
                    message.taggedUserXs[i] = $root.pl_types.UserX.fromObject(object.taggedUserXs[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ProjectPost message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pl_types.ProjectPost
         * @static
         * @param {pl_types.ProjectPost} message ProjectPost
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProjectPost.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.tags = [];
                object.comments = [];
                object.ratings = [];
                object.ratingCategories = [];
                object.taggedUserXs = [];
            }
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.userX != null && message.hasOwnProperty("userX")) {
                object.userX = $root.pl_types.UserX.toObject(message.userX, options);
                if (options.oneofs)
                    object._userX = "userX";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            if (message.longDescrHtml != null && message.hasOwnProperty("longDescrHtml")) {
                object.longDescrHtml = message.longDescrHtml;
                if (options.oneofs)
                    object._longDescrHtml = "longDescrHtml";
            }
            if (message.desiredFeedback != null && message.hasOwnProperty("desiredFeedback")) {
                object.desiredFeedback = message.desiredFeedback;
                if (options.oneofs)
                    object._desiredFeedback = "desiredFeedback";
            }
            if (message.tags && message.tags.length) {
                object.tags = [];
                for (var j = 0; j < message.tags.length; ++j)
                    object.tags[j] = $root.pl_types.Tag.toObject(message.tags[j], options);
            }
            if (message.postTimeMs != null && message.hasOwnProperty("postTimeMs")) {
                if (typeof message.postTimeMs === "number")
                    object.postTimeMs = options.longs === String ? String(message.postTimeMs) : message.postTimeMs;
                else
                    object.postTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.postTimeMs) : options.longs === Number ? new $util.LongBits(message.postTimeMs.low >>> 0, message.postTimeMs.high >>> 0).toNumber() : message.postTimeMs;
                if (options.oneofs)
                    object._postTimeMs = "postTimeMs";
            }
            if (message.beingEdited != null && message.hasOwnProperty("beingEdited")) {
                object.beingEdited = message.beingEdited;
                if (options.oneofs)
                    object._beingEdited = "beingEdited";
            }
            if (message.comments && message.comments.length) {
                object.comments = [];
                for (var j = 0; j < message.comments.length; ++j)
                    object.comments[j] = $root.pl_types.ProjectPostComment.toObject(message.comments[j], options);
            }
            if (message.project != null && message.hasOwnProperty("project")) {
                object.project = $root.pl_types.Project.toObject(message.project, options);
                if (options.oneofs)
                    object._project = "project";
            }
            if (message.ratings && message.ratings.length) {
                object.ratings = [];
                for (var j = 0; j < message.ratings.length; ++j)
                    object.ratings[j] = $root.pl_types.ProjectPostRating.toObject(message.ratings[j], options);
            }
            if (message.ratingCategories && message.ratingCategories.length) {
                object.ratingCategories = [];
                for (var j = 0; j < message.ratingCategories.length; ++j)
                    object.ratingCategories[j] = $root.pl_types.ProjectPostRatingCategory.toObject(message.ratingCategories[j], options);
            }
            if (message.includeInPortfolio != null && message.hasOwnProperty("includeInPortfolio")) {
                object.includeInPortfolio = message.includeInPortfolio;
                if (options.oneofs)
                    object._includeInPortfolio = "includeInPortfolio";
            }
            if (message.portfolioNoteHtml != null && message.hasOwnProperty("portfolioNoteHtml")) {
                object.portfolioNoteHtml = message.portfolioNoteHtml;
                if (options.oneofs)
                    object._portfolioNoteHtml = "portfolioNoteHtml";
            }
            if (message.taggedUserXs && message.taggedUserXs.length) {
                object.taggedUserXs = [];
                for (var j = 0; j < message.taggedUserXs.length; ++j)
                    object.taggedUserXs[j] = $root.pl_types.UserX.toObject(message.taggedUserXs[j], options);
            }
            return object;
        };

        /**
         * Converts this ProjectPost to JSON.
         * @function toJSON
         * @memberof pl_types.ProjectPost
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProjectPost.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProjectPost
         * @function getTypeUrl
         * @memberof pl_types.ProjectPost
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProjectPost.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pl_types.ProjectPost";
        };

        return ProjectPost;
    })();

    pl_types.Term = (function() {

        /**
         * Properties of a Term.
         * @memberof pl_types
         * @interface ITerm
         * @property {number|null} [id] Term id
         * @property {string|null} [name] Term name
         * @property {Long|null} [startTimeMs] Term startTimeMs
         * @property {Long|null} [endTimeMs] Term endTimeMs
         */

        /**
         * Constructs a new Term.
         * @memberof pl_types
         * @classdesc Represents a Term.
         * @implements ITerm
         * @constructor
         * @param {pl_types.ITerm=} [properties] Properties to set
         */
        function Term(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Term id.
         * @member {number|null|undefined} id
         * @memberof pl_types.Term
         * @instance
         */
        Term.prototype.id = null;

        /**
         * Term name.
         * @member {string|null|undefined} name
         * @memberof pl_types.Term
         * @instance
         */
        Term.prototype.name = null;

        /**
         * Term startTimeMs.
         * @member {Long|null|undefined} startTimeMs
         * @memberof pl_types.Term
         * @instance
         */
        Term.prototype.startTimeMs = null;

        /**
         * Term endTimeMs.
         * @member {Long|null|undefined} endTimeMs
         * @memberof pl_types.Term
         * @instance
         */
        Term.prototype.endTimeMs = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Term _id.
         * @member {"id"|undefined} _id
         * @memberof pl_types.Term
         * @instance
         */
        Object.defineProperty(Term.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Term _name.
         * @member {"name"|undefined} _name
         * @memberof pl_types.Term
         * @instance
         */
        Object.defineProperty(Term.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Term _startTimeMs.
         * @member {"startTimeMs"|undefined} _startTimeMs
         * @memberof pl_types.Term
         * @instance
         */
        Object.defineProperty(Term.prototype, "_startTimeMs", {
            get: $util.oneOfGetter($oneOfFields = ["startTimeMs"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Term _endTimeMs.
         * @member {"endTimeMs"|undefined} _endTimeMs
         * @memberof pl_types.Term
         * @instance
         */
        Object.defineProperty(Term.prototype, "_endTimeMs", {
            get: $util.oneOfGetter($oneOfFields = ["endTimeMs"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Term instance using the specified properties.
         * @function create
         * @memberof pl_types.Term
         * @static
         * @param {pl_types.ITerm=} [properties] Properties to set
         * @returns {pl_types.Term} Term instance
         */
        Term.create = function create(properties) {
            return new Term(properties);
        };

        /**
         * Encodes the specified Term message. Does not implicitly {@link pl_types.Term.verify|verify} messages.
         * @function encode
         * @memberof pl_types.Term
         * @static
         * @param {pl_types.ITerm} message Term message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Term.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.startTimeMs != null && Object.hasOwnProperty.call(message, "startTimeMs"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.startTimeMs);
            if (message.endTimeMs != null && Object.hasOwnProperty.call(message, "endTimeMs"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.endTimeMs);
            return writer;
        };

        /**
         * Encodes the specified Term message, length delimited. Does not implicitly {@link pl_types.Term.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pl_types.Term
         * @static
         * @param {pl_types.ITerm} message Term message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Term.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Term message from the specified reader or buffer.
         * @function decode
         * @memberof pl_types.Term
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pl_types.Term} Term
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Term.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.Term();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.startTimeMs = reader.int64();
                        break;
                    }
                case 4: {
                        message.endTimeMs = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Term message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pl_types.Term
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pl_types.Term} Term
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Term.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Term message.
         * @function verify
         * @memberof pl_types.Term
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Term.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs")) {
                properties._startTimeMs = 1;
                if (!$util.isInteger(message.startTimeMs) && !(message.startTimeMs && $util.isInteger(message.startTimeMs.low) && $util.isInteger(message.startTimeMs.high)))
                    return "startTimeMs: integer|Long expected";
            }
            if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs")) {
                properties._endTimeMs = 1;
                if (!$util.isInteger(message.endTimeMs) && !(message.endTimeMs && $util.isInteger(message.endTimeMs.low) && $util.isInteger(message.endTimeMs.high)))
                    return "endTimeMs: integer|Long expected";
            }
            return null;
        };

        /**
         * Creates a Term message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pl_types.Term
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pl_types.Term} Term
         */
        Term.fromObject = function fromObject(object) {
            if (object instanceof $root.pl_types.Term)
                return object;
            var message = new $root.pl_types.Term();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.name != null)
                message.name = String(object.name);
            if (object.startTimeMs != null)
                if ($util.Long)
                    (message.startTimeMs = $util.Long.fromValue(object.startTimeMs)).unsigned = false;
                else if (typeof object.startTimeMs === "string")
                    message.startTimeMs = parseInt(object.startTimeMs, 10);
                else if (typeof object.startTimeMs === "number")
                    message.startTimeMs = object.startTimeMs;
                else if (typeof object.startTimeMs === "object")
                    message.startTimeMs = new $util.LongBits(object.startTimeMs.low >>> 0, object.startTimeMs.high >>> 0).toNumber();
            if (object.endTimeMs != null)
                if ($util.Long)
                    (message.endTimeMs = $util.Long.fromValue(object.endTimeMs)).unsigned = false;
                else if (typeof object.endTimeMs === "string")
                    message.endTimeMs = parseInt(object.endTimeMs, 10);
                else if (typeof object.endTimeMs === "number")
                    message.endTimeMs = object.endTimeMs;
                else if (typeof object.endTimeMs === "object")
                    message.endTimeMs = new $util.LongBits(object.endTimeMs.low >>> 0, object.endTimeMs.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a Term message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pl_types.Term
         * @static
         * @param {pl_types.Term} message Term
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Term.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs")) {
                if (typeof message.startTimeMs === "number")
                    object.startTimeMs = options.longs === String ? String(message.startTimeMs) : message.startTimeMs;
                else
                    object.startTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMs) : options.longs === Number ? new $util.LongBits(message.startTimeMs.low >>> 0, message.startTimeMs.high >>> 0).toNumber() : message.startTimeMs;
                if (options.oneofs)
                    object._startTimeMs = "startTimeMs";
            }
            if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs")) {
                if (typeof message.endTimeMs === "number")
                    object.endTimeMs = options.longs === String ? String(message.endTimeMs) : message.endTimeMs;
                else
                    object.endTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMs) : options.longs === Number ? new $util.LongBits(message.endTimeMs.low >>> 0, message.endTimeMs.high >>> 0).toNumber() : message.endTimeMs;
                if (options.oneofs)
                    object._endTimeMs = "endTimeMs";
            }
            return object;
        };

        /**
         * Converts this Term to JSON.
         * @function toJSON
         * @memberof pl_types.Term
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Term.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Term
         * @function getTypeUrl
         * @memberof pl_types.Term
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Term.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pl_types.Term";
        };

        return Term;
    })();

    pl_types.ResourceX = (function() {

        /**
         * Properties of a ResourceX.
         * @memberof pl_types
         * @interface IResourceX
         * @property {number|null} [id] ResourceX id
         * @property {string|null} [name] ResourceX name
         * @property {string|null} [url] ResourceX url
         * @property {string|null} [shortDescrHtml] ResourceX shortDescrHtml
         * @property {string|null} [longDescrHtml] ResourceX longDescrHtml
         * @property {pl_types.IUserX|null} [userX] ResourceX userX
         */

        /**
         * Constructs a new ResourceX.
         * @memberof pl_types
         * @classdesc Represents a ResourceX.
         * @implements IResourceX
         * @constructor
         * @param {pl_types.IResourceX=} [properties] Properties to set
         */
        function ResourceX(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResourceX id.
         * @member {number|null|undefined} id
         * @memberof pl_types.ResourceX
         * @instance
         */
        ResourceX.prototype.id = null;

        /**
         * ResourceX name.
         * @member {string|null|undefined} name
         * @memberof pl_types.ResourceX
         * @instance
         */
        ResourceX.prototype.name = null;

        /**
         * ResourceX url.
         * @member {string|null|undefined} url
         * @memberof pl_types.ResourceX
         * @instance
         */
        ResourceX.prototype.url = null;

        /**
         * ResourceX shortDescrHtml.
         * @member {string|null|undefined} shortDescrHtml
         * @memberof pl_types.ResourceX
         * @instance
         */
        ResourceX.prototype.shortDescrHtml = null;

        /**
         * ResourceX longDescrHtml.
         * @member {string|null|undefined} longDescrHtml
         * @memberof pl_types.ResourceX
         * @instance
         */
        ResourceX.prototype.longDescrHtml = null;

        /**
         * ResourceX userX.
         * @member {pl_types.IUserX|null|undefined} userX
         * @memberof pl_types.ResourceX
         * @instance
         */
        ResourceX.prototype.userX = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ResourceX _id.
         * @member {"id"|undefined} _id
         * @memberof pl_types.ResourceX
         * @instance
         */
        Object.defineProperty(ResourceX.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ResourceX _name.
         * @member {"name"|undefined} _name
         * @memberof pl_types.ResourceX
         * @instance
         */
        Object.defineProperty(ResourceX.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ResourceX _url.
         * @member {"url"|undefined} _url
         * @memberof pl_types.ResourceX
         * @instance
         */
        Object.defineProperty(ResourceX.prototype, "_url", {
            get: $util.oneOfGetter($oneOfFields = ["url"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ResourceX _shortDescrHtml.
         * @member {"shortDescrHtml"|undefined} _shortDescrHtml
         * @memberof pl_types.ResourceX
         * @instance
         */
        Object.defineProperty(ResourceX.prototype, "_shortDescrHtml", {
            get: $util.oneOfGetter($oneOfFields = ["shortDescrHtml"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ResourceX _longDescrHtml.
         * @member {"longDescrHtml"|undefined} _longDescrHtml
         * @memberof pl_types.ResourceX
         * @instance
         */
        Object.defineProperty(ResourceX.prototype, "_longDescrHtml", {
            get: $util.oneOfGetter($oneOfFields = ["longDescrHtml"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ResourceX _userX.
         * @member {"userX"|undefined} _userX
         * @memberof pl_types.ResourceX
         * @instance
         */
        Object.defineProperty(ResourceX.prototype, "_userX", {
            get: $util.oneOfGetter($oneOfFields = ["userX"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ResourceX instance using the specified properties.
         * @function create
         * @memberof pl_types.ResourceX
         * @static
         * @param {pl_types.IResourceX=} [properties] Properties to set
         * @returns {pl_types.ResourceX} ResourceX instance
         */
        ResourceX.create = function create(properties) {
            return new ResourceX(properties);
        };

        /**
         * Encodes the specified ResourceX message. Does not implicitly {@link pl_types.ResourceX.verify|verify} messages.
         * @function encode
         * @memberof pl_types.ResourceX
         * @static
         * @param {pl_types.IResourceX} message ResourceX message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResourceX.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.url);
            if (message.shortDescrHtml != null && Object.hasOwnProperty.call(message, "shortDescrHtml"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.shortDescrHtml);
            if (message.longDescrHtml != null && Object.hasOwnProperty.call(message, "longDescrHtml"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.longDescrHtml);
            if (message.userX != null && Object.hasOwnProperty.call(message, "userX"))
                $root.pl_types.UserX.encode(message.userX, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ResourceX message, length delimited. Does not implicitly {@link pl_types.ResourceX.verify|verify} messages.
         * @function encodeDelimited
         * @memberof pl_types.ResourceX
         * @static
         * @param {pl_types.IResourceX} message ResourceX message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResourceX.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResourceX message from the specified reader or buffer.
         * @function decode
         * @memberof pl_types.ResourceX
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {pl_types.ResourceX} ResourceX
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResourceX.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pl_types.ResourceX();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.url = reader.string();
                        break;
                    }
                case 4: {
                        message.shortDescrHtml = reader.string();
                        break;
                    }
                case 5: {
                        message.longDescrHtml = reader.string();
                        break;
                    }
                case 6: {
                        message.userX = $root.pl_types.UserX.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResourceX message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof pl_types.ResourceX
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {pl_types.ResourceX} ResourceX
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResourceX.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResourceX message.
         * @function verify
         * @memberof pl_types.ResourceX
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResourceX.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            if (message.url != null && message.hasOwnProperty("url")) {
                properties._url = 1;
                if (!$util.isString(message.url))
                    return "url: string expected";
            }
            if (message.shortDescrHtml != null && message.hasOwnProperty("shortDescrHtml")) {
                properties._shortDescrHtml = 1;
                if (!$util.isString(message.shortDescrHtml))
                    return "shortDescrHtml: string expected";
            }
            if (message.longDescrHtml != null && message.hasOwnProperty("longDescrHtml")) {
                properties._longDescrHtml = 1;
                if (!$util.isString(message.longDescrHtml))
                    return "longDescrHtml: string expected";
            }
            if (message.userX != null && message.hasOwnProperty("userX")) {
                properties._userX = 1;
                {
                    var error = $root.pl_types.UserX.verify(message.userX);
                    if (error)
                        return "userX." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ResourceX message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof pl_types.ResourceX
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {pl_types.ResourceX} ResourceX
         */
        ResourceX.fromObject = function fromObject(object) {
            if (object instanceof $root.pl_types.ResourceX)
                return object;
            var message = new $root.pl_types.ResourceX();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.name != null)
                message.name = String(object.name);
            if (object.url != null)
                message.url = String(object.url);
            if (object.shortDescrHtml != null)
                message.shortDescrHtml = String(object.shortDescrHtml);
            if (object.longDescrHtml != null)
                message.longDescrHtml = String(object.longDescrHtml);
            if (object.userX != null) {
                if (typeof object.userX !== "object")
                    throw TypeError(".pl_types.ResourceX.userX: object expected");
                message.userX = $root.pl_types.UserX.fromObject(object.userX);
            }
            return message;
        };

        /**
         * Creates a plain object from a ResourceX message. Also converts values to other types if specified.
         * @function toObject
         * @memberof pl_types.ResourceX
         * @static
         * @param {pl_types.ResourceX} message ResourceX
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResourceX.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            if (message.url != null && message.hasOwnProperty("url")) {
                object.url = message.url;
                if (options.oneofs)
                    object._url = "url";
            }
            if (message.shortDescrHtml != null && message.hasOwnProperty("shortDescrHtml")) {
                object.shortDescrHtml = message.shortDescrHtml;
                if (options.oneofs)
                    object._shortDescrHtml = "shortDescrHtml";
            }
            if (message.longDescrHtml != null && message.hasOwnProperty("longDescrHtml")) {
                object.longDescrHtml = message.longDescrHtml;
                if (options.oneofs)
                    object._longDescrHtml = "longDescrHtml";
            }
            if (message.userX != null && message.hasOwnProperty("userX")) {
                object.userX = $root.pl_types.UserX.toObject(message.userX, options);
                if (options.oneofs)
                    object._userX = "userX";
            }
            return object;
        };

        /**
         * Converts this ResourceX to JSON.
         * @function toJSON
         * @memberof pl_types.ResourceX
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResourceX.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResourceX
         * @function getTypeUrl
         * @memberof pl_types.ResourceX
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResourceX.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/pl_types.ResourceX";
        };

        return ResourceX;
    })();

    return pl_types;
})();

$root.class_x_service = (function() {

    /**
     * Namespace class_x_service.
     * @exports class_x_service
     * @namespace
     */
    var class_x_service = {};

    class_x_service.ClassXService = (function() {

        /**
         * Constructs a new ClassXService service.
         * @memberof class_x_service
         * @classdesc Represents a ClassXService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ClassXService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ClassXService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ClassXService;

        /**
         * Creates new ClassXService service using the specified rpc implementation.
         * @function create
         * @memberof class_x_service.ClassXService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {ClassXService} RPC service. Useful where requests and/or responses are streamed.
         */
        ClassXService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link class_x_service.ClassXService#getClassXs}.
         * @memberof class_x_service.ClassXService
         * @typedef GetClassXsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {class_x_service.GetClassXsResponse} [response] GetClassXsResponse
         */

        /**
         * Calls GetClassXs.
         * @function getClassXs
         * @memberof class_x_service.ClassXService
         * @instance
         * @param {class_x_service.IGetClassXsRequest} request GetClassXsRequest message or plain object
         * @param {class_x_service.ClassXService.GetClassXsCallback} callback Node-style callback called with the error, if any, and GetClassXsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ClassXService.prototype.getClassXs = function getClassXs(request, callback) {
            return this.rpcCall(getClassXs, $root.class_x_service.GetClassXsRequest, $root.class_x_service.GetClassXsResponse, request, callback);
        }, "name", { value: "GetClassXs" });

        /**
         * Calls GetClassXs.
         * @function getClassXs
         * @memberof class_x_service.ClassXService
         * @instance
         * @param {class_x_service.IGetClassXsRequest} request GetClassXsRequest message or plain object
         * @returns {Promise<class_x_service.GetClassXsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link class_x_service.ClassXService#upsertClassX}.
         * @memberof class_x_service.ClassXService
         * @typedef UpsertClassXCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {class_x_service.UpsertClassXResponse} [response] UpsertClassXResponse
         */

        /**
         * Calls UpsertClassX.
         * @function upsertClassX
         * @memberof class_x_service.ClassXService
         * @instance
         * @param {class_x_service.IUpsertClassXRequest} request UpsertClassXRequest message or plain object
         * @param {class_x_service.ClassXService.UpsertClassXCallback} callback Node-style callback called with the error, if any, and UpsertClassXResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ClassXService.prototype.upsertClassX = function upsertClassX(request, callback) {
            return this.rpcCall(upsertClassX, $root.class_x_service.UpsertClassXRequest, $root.class_x_service.UpsertClassXResponse, request, callback);
        }, "name", { value: "UpsertClassX" });

        /**
         * Calls UpsertClassX.
         * @function upsertClassX
         * @memberof class_x_service.ClassXService
         * @instance
         * @param {class_x_service.IUpsertClassXRequest} request UpsertClassXRequest message or plain object
         * @returns {Promise<class_x_service.UpsertClassXResponse>} Promise
         * @variation 2
         */

        return ClassXService;
    })();

    class_x_service.GetClassXsRequest = (function() {

        /**
         * Properties of a GetClassXsRequest.
         * @memberof class_x_service
         * @interface IGetClassXsRequest
         * @property {Array.<number>|null} [districtIds] GetClassXsRequest districtIds
         * @property {Array.<number>|null} [schoolIds] GetClassXsRequest schoolIds
         * @property {Array.<number>|null} [classXIds] GetClassXsRequest classXIds
         * @property {Array.<number>|null} [sectionIds] GetClassXsRequest sectionIds
         * @property {Array.<number>|null} [teacherUserXIds] GetClassXsRequest teacherUserXIds
         * @property {Array.<number>|null} [studentUserXIds] GetClassXsRequest studentUserXIds
         * @property {boolean|null} [includeSchool] GetClassXsRequest includeSchool
         * @property {boolean|null} [includeSections] GetClassXsRequest includeSections
         * @property {boolean|null} [includeAssignments] GetClassXsRequest includeAssignments
         * @property {boolean|null} [includeKnowledgeAndSkills] GetClassXsRequest includeKnowledgeAndSkills
         */

        /**
         * Constructs a new GetClassXsRequest.
         * @memberof class_x_service
         * @classdesc Represents a GetClassXsRequest.
         * @implements IGetClassXsRequest
         * @constructor
         * @param {class_x_service.IGetClassXsRequest=} [properties] Properties to set
         */
        function GetClassXsRequest(properties) {
            this.districtIds = [];
            this.schoolIds = [];
            this.classXIds = [];
            this.sectionIds = [];
            this.teacherUserXIds = [];
            this.studentUserXIds = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetClassXsRequest districtIds.
         * @member {Array.<number>} districtIds
         * @memberof class_x_service.GetClassXsRequest
         * @instance
         */
        GetClassXsRequest.prototype.districtIds = $util.emptyArray;

        /**
         * GetClassXsRequest schoolIds.
         * @member {Array.<number>} schoolIds
         * @memberof class_x_service.GetClassXsRequest
         * @instance
         */
        GetClassXsRequest.prototype.schoolIds = $util.emptyArray;

        /**
         * GetClassXsRequest classXIds.
         * @member {Array.<number>} classXIds
         * @memberof class_x_service.GetClassXsRequest
         * @instance
         */
        GetClassXsRequest.prototype.classXIds = $util.emptyArray;

        /**
         * GetClassXsRequest sectionIds.
         * @member {Array.<number>} sectionIds
         * @memberof class_x_service.GetClassXsRequest
         * @instance
         */
        GetClassXsRequest.prototype.sectionIds = $util.emptyArray;

        /**
         * GetClassXsRequest teacherUserXIds.
         * @member {Array.<number>} teacherUserXIds
         * @memberof class_x_service.GetClassXsRequest
         * @instance
         */
        GetClassXsRequest.prototype.teacherUserXIds = $util.emptyArray;

        /**
         * GetClassXsRequest studentUserXIds.
         * @member {Array.<number>} studentUserXIds
         * @memberof class_x_service.GetClassXsRequest
         * @instance
         */
        GetClassXsRequest.prototype.studentUserXIds = $util.emptyArray;

        /**
         * GetClassXsRequest includeSchool.
         * @member {boolean|null|undefined} includeSchool
         * @memberof class_x_service.GetClassXsRequest
         * @instance
         */
        GetClassXsRequest.prototype.includeSchool = null;

        /**
         * GetClassXsRequest includeSections.
         * @member {boolean|null|undefined} includeSections
         * @memberof class_x_service.GetClassXsRequest
         * @instance
         */
        GetClassXsRequest.prototype.includeSections = null;

        /**
         * GetClassXsRequest includeAssignments.
         * @member {boolean|null|undefined} includeAssignments
         * @memberof class_x_service.GetClassXsRequest
         * @instance
         */
        GetClassXsRequest.prototype.includeAssignments = null;

        /**
         * GetClassXsRequest includeKnowledgeAndSkills.
         * @member {boolean|null|undefined} includeKnowledgeAndSkills
         * @memberof class_x_service.GetClassXsRequest
         * @instance
         */
        GetClassXsRequest.prototype.includeKnowledgeAndSkills = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GetClassXsRequest _includeSchool.
         * @member {"includeSchool"|undefined} _includeSchool
         * @memberof class_x_service.GetClassXsRequest
         * @instance
         */
        Object.defineProperty(GetClassXsRequest.prototype, "_includeSchool", {
            get: $util.oneOfGetter($oneOfFields = ["includeSchool"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetClassXsRequest _includeSections.
         * @member {"includeSections"|undefined} _includeSections
         * @memberof class_x_service.GetClassXsRequest
         * @instance
         */
        Object.defineProperty(GetClassXsRequest.prototype, "_includeSections", {
            get: $util.oneOfGetter($oneOfFields = ["includeSections"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetClassXsRequest _includeAssignments.
         * @member {"includeAssignments"|undefined} _includeAssignments
         * @memberof class_x_service.GetClassXsRequest
         * @instance
         */
        Object.defineProperty(GetClassXsRequest.prototype, "_includeAssignments", {
            get: $util.oneOfGetter($oneOfFields = ["includeAssignments"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetClassXsRequest _includeKnowledgeAndSkills.
         * @member {"includeKnowledgeAndSkills"|undefined} _includeKnowledgeAndSkills
         * @memberof class_x_service.GetClassXsRequest
         * @instance
         */
        Object.defineProperty(GetClassXsRequest.prototype, "_includeKnowledgeAndSkills", {
            get: $util.oneOfGetter($oneOfFields = ["includeKnowledgeAndSkills"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetClassXsRequest instance using the specified properties.
         * @function create
         * @memberof class_x_service.GetClassXsRequest
         * @static
         * @param {class_x_service.IGetClassXsRequest=} [properties] Properties to set
         * @returns {class_x_service.GetClassXsRequest} GetClassXsRequest instance
         */
        GetClassXsRequest.create = function create(properties) {
            return new GetClassXsRequest(properties);
        };

        /**
         * Encodes the specified GetClassXsRequest message. Does not implicitly {@link class_x_service.GetClassXsRequest.verify|verify} messages.
         * @function encode
         * @memberof class_x_service.GetClassXsRequest
         * @static
         * @param {class_x_service.IGetClassXsRequest} message GetClassXsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetClassXsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.studentUserXIds != null && message.studentUserXIds.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.studentUserXIds.length; ++i)
                    writer.int32(message.studentUserXIds[i]);
                writer.ldelim();
            }
            if (message.teacherUserXIds != null && message.teacherUserXIds.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.teacherUserXIds.length; ++i)
                    writer.int32(message.teacherUserXIds[i]);
                writer.ldelim();
            }
            if (message.includeKnowledgeAndSkills != null && Object.hasOwnProperty.call(message, "includeKnowledgeAndSkills"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includeKnowledgeAndSkills);
            if (message.schoolIds != null && message.schoolIds.length) {
                writer.uint32(/* id 5, wireType 2 =*/42).fork();
                for (var i = 0; i < message.schoolIds.length; ++i)
                    writer.int32(message.schoolIds[i]);
                writer.ldelim();
            }
            if (message.includeAssignments != null && Object.hasOwnProperty.call(message, "includeAssignments"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.includeAssignments);
            if (message.classXIds != null && message.classXIds.length) {
                writer.uint32(/* id 7, wireType 2 =*/58).fork();
                for (var i = 0; i < message.classXIds.length; ++i)
                    writer.int32(message.classXIds[i]);
                writer.ldelim();
            }
            if (message.includeSchool != null && Object.hasOwnProperty.call(message, "includeSchool"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.includeSchool);
            if (message.sectionIds != null && message.sectionIds.length) {
                writer.uint32(/* id 9, wireType 2 =*/74).fork();
                for (var i = 0; i < message.sectionIds.length; ++i)
                    writer.int32(message.sectionIds[i]);
                writer.ldelim();
            }
            if (message.includeSections != null && Object.hasOwnProperty.call(message, "includeSections"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.includeSections);
            if (message.districtIds != null && message.districtIds.length) {
                writer.uint32(/* id 11, wireType 2 =*/90).fork();
                for (var i = 0; i < message.districtIds.length; ++i)
                    writer.int32(message.districtIds[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified GetClassXsRequest message, length delimited. Does not implicitly {@link class_x_service.GetClassXsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof class_x_service.GetClassXsRequest
         * @static
         * @param {class_x_service.IGetClassXsRequest} message GetClassXsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetClassXsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetClassXsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof class_x_service.GetClassXsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {class_x_service.GetClassXsRequest} GetClassXsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetClassXsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.class_x_service.GetClassXsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 11: {
                        if (!(message.districtIds && message.districtIds.length))
                            message.districtIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.districtIds.push(reader.int32());
                        } else
                            message.districtIds.push(reader.int32());
                        break;
                    }
                case 5: {
                        if (!(message.schoolIds && message.schoolIds.length))
                            message.schoolIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.schoolIds.push(reader.int32());
                        } else
                            message.schoolIds.push(reader.int32());
                        break;
                    }
                case 7: {
                        if (!(message.classXIds && message.classXIds.length))
                            message.classXIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.classXIds.push(reader.int32());
                        } else
                            message.classXIds.push(reader.int32());
                        break;
                    }
                case 9: {
                        if (!(message.sectionIds && message.sectionIds.length))
                            message.sectionIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.sectionIds.push(reader.int32());
                        } else
                            message.sectionIds.push(reader.int32());
                        break;
                    }
                case 2: {
                        if (!(message.teacherUserXIds && message.teacherUserXIds.length))
                            message.teacherUserXIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.teacherUserXIds.push(reader.int32());
                        } else
                            message.teacherUserXIds.push(reader.int32());
                        break;
                    }
                case 1: {
                        if (!(message.studentUserXIds && message.studentUserXIds.length))
                            message.studentUserXIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.studentUserXIds.push(reader.int32());
                        } else
                            message.studentUserXIds.push(reader.int32());
                        break;
                    }
                case 8: {
                        message.includeSchool = reader.bool();
                        break;
                    }
                case 10: {
                        message.includeSections = reader.bool();
                        break;
                    }
                case 6: {
                        message.includeAssignments = reader.bool();
                        break;
                    }
                case 4: {
                        message.includeKnowledgeAndSkills = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetClassXsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof class_x_service.GetClassXsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {class_x_service.GetClassXsRequest} GetClassXsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetClassXsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetClassXsRequest message.
         * @function verify
         * @memberof class_x_service.GetClassXsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetClassXsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.districtIds != null && message.hasOwnProperty("districtIds")) {
                if (!Array.isArray(message.districtIds))
                    return "districtIds: array expected";
                for (var i = 0; i < message.districtIds.length; ++i)
                    if (!$util.isInteger(message.districtIds[i]))
                        return "districtIds: integer[] expected";
            }
            if (message.schoolIds != null && message.hasOwnProperty("schoolIds")) {
                if (!Array.isArray(message.schoolIds))
                    return "schoolIds: array expected";
                for (var i = 0; i < message.schoolIds.length; ++i)
                    if (!$util.isInteger(message.schoolIds[i]))
                        return "schoolIds: integer[] expected";
            }
            if (message.classXIds != null && message.hasOwnProperty("classXIds")) {
                if (!Array.isArray(message.classXIds))
                    return "classXIds: array expected";
                for (var i = 0; i < message.classXIds.length; ++i)
                    if (!$util.isInteger(message.classXIds[i]))
                        return "classXIds: integer[] expected";
            }
            if (message.sectionIds != null && message.hasOwnProperty("sectionIds")) {
                if (!Array.isArray(message.sectionIds))
                    return "sectionIds: array expected";
                for (var i = 0; i < message.sectionIds.length; ++i)
                    if (!$util.isInteger(message.sectionIds[i]))
                        return "sectionIds: integer[] expected";
            }
            if (message.teacherUserXIds != null && message.hasOwnProperty("teacherUserXIds")) {
                if (!Array.isArray(message.teacherUserXIds))
                    return "teacherUserXIds: array expected";
                for (var i = 0; i < message.teacherUserXIds.length; ++i)
                    if (!$util.isInteger(message.teacherUserXIds[i]))
                        return "teacherUserXIds: integer[] expected";
            }
            if (message.studentUserXIds != null && message.hasOwnProperty("studentUserXIds")) {
                if (!Array.isArray(message.studentUserXIds))
                    return "studentUserXIds: array expected";
                for (var i = 0; i < message.studentUserXIds.length; ++i)
                    if (!$util.isInteger(message.studentUserXIds[i]))
                        return "studentUserXIds: integer[] expected";
            }
            if (message.includeSchool != null && message.hasOwnProperty("includeSchool")) {
                properties._includeSchool = 1;
                if (typeof message.includeSchool !== "boolean")
                    return "includeSchool: boolean expected";
            }
            if (message.includeSections != null && message.hasOwnProperty("includeSections")) {
                properties._includeSections = 1;
                if (typeof message.includeSections !== "boolean")
                    return "includeSections: boolean expected";
            }
            if (message.includeAssignments != null && message.hasOwnProperty("includeAssignments")) {
                properties._includeAssignments = 1;
                if (typeof message.includeAssignments !== "boolean")
                    return "includeAssignments: boolean expected";
            }
            if (message.includeKnowledgeAndSkills != null && message.hasOwnProperty("includeKnowledgeAndSkills")) {
                properties._includeKnowledgeAndSkills = 1;
                if (typeof message.includeKnowledgeAndSkills !== "boolean")
                    return "includeKnowledgeAndSkills: boolean expected";
            }
            return null;
        };

        /**
         * Creates a GetClassXsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof class_x_service.GetClassXsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {class_x_service.GetClassXsRequest} GetClassXsRequest
         */
        GetClassXsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.class_x_service.GetClassXsRequest)
                return object;
            var message = new $root.class_x_service.GetClassXsRequest();
            if (object.districtIds) {
                if (!Array.isArray(object.districtIds))
                    throw TypeError(".class_x_service.GetClassXsRequest.districtIds: array expected");
                message.districtIds = [];
                for (var i = 0; i < object.districtIds.length; ++i)
                    message.districtIds[i] = object.districtIds[i] | 0;
            }
            if (object.schoolIds) {
                if (!Array.isArray(object.schoolIds))
                    throw TypeError(".class_x_service.GetClassXsRequest.schoolIds: array expected");
                message.schoolIds = [];
                for (var i = 0; i < object.schoolIds.length; ++i)
                    message.schoolIds[i] = object.schoolIds[i] | 0;
            }
            if (object.classXIds) {
                if (!Array.isArray(object.classXIds))
                    throw TypeError(".class_x_service.GetClassXsRequest.classXIds: array expected");
                message.classXIds = [];
                for (var i = 0; i < object.classXIds.length; ++i)
                    message.classXIds[i] = object.classXIds[i] | 0;
            }
            if (object.sectionIds) {
                if (!Array.isArray(object.sectionIds))
                    throw TypeError(".class_x_service.GetClassXsRequest.sectionIds: array expected");
                message.sectionIds = [];
                for (var i = 0; i < object.sectionIds.length; ++i)
                    message.sectionIds[i] = object.sectionIds[i] | 0;
            }
            if (object.teacherUserXIds) {
                if (!Array.isArray(object.teacherUserXIds))
                    throw TypeError(".class_x_service.GetClassXsRequest.teacherUserXIds: array expected");
                message.teacherUserXIds = [];
                for (var i = 0; i < object.teacherUserXIds.length; ++i)
                    message.teacherUserXIds[i] = object.teacherUserXIds[i] | 0;
            }
            if (object.studentUserXIds) {
                if (!Array.isArray(object.studentUserXIds))
                    throw TypeError(".class_x_service.GetClassXsRequest.studentUserXIds: array expected");
                message.studentUserXIds = [];
                for (var i = 0; i < object.studentUserXIds.length; ++i)
                    message.studentUserXIds[i] = object.studentUserXIds[i] | 0;
            }
            if (object.includeSchool != null)
                message.includeSchool = Boolean(object.includeSchool);
            if (object.includeSections != null)
                message.includeSections = Boolean(object.includeSections);
            if (object.includeAssignments != null)
                message.includeAssignments = Boolean(object.includeAssignments);
            if (object.includeKnowledgeAndSkills != null)
                message.includeKnowledgeAndSkills = Boolean(object.includeKnowledgeAndSkills);
            return message;
        };

        /**
         * Creates a plain object from a GetClassXsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof class_x_service.GetClassXsRequest
         * @static
         * @param {class_x_service.GetClassXsRequest} message GetClassXsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetClassXsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.studentUserXIds = [];
                object.teacherUserXIds = [];
                object.schoolIds = [];
                object.classXIds = [];
                object.sectionIds = [];
                object.districtIds = [];
            }
            if (message.studentUserXIds && message.studentUserXIds.length) {
                object.studentUserXIds = [];
                for (var j = 0; j < message.studentUserXIds.length; ++j)
                    object.studentUserXIds[j] = message.studentUserXIds[j];
            }
            if (message.teacherUserXIds && message.teacherUserXIds.length) {
                object.teacherUserXIds = [];
                for (var j = 0; j < message.teacherUserXIds.length; ++j)
                    object.teacherUserXIds[j] = message.teacherUserXIds[j];
            }
            if (message.includeKnowledgeAndSkills != null && message.hasOwnProperty("includeKnowledgeAndSkills")) {
                object.includeKnowledgeAndSkills = message.includeKnowledgeAndSkills;
                if (options.oneofs)
                    object._includeKnowledgeAndSkills = "includeKnowledgeAndSkills";
            }
            if (message.schoolIds && message.schoolIds.length) {
                object.schoolIds = [];
                for (var j = 0; j < message.schoolIds.length; ++j)
                    object.schoolIds[j] = message.schoolIds[j];
            }
            if (message.includeAssignments != null && message.hasOwnProperty("includeAssignments")) {
                object.includeAssignments = message.includeAssignments;
                if (options.oneofs)
                    object._includeAssignments = "includeAssignments";
            }
            if (message.classXIds && message.classXIds.length) {
                object.classXIds = [];
                for (var j = 0; j < message.classXIds.length; ++j)
                    object.classXIds[j] = message.classXIds[j];
            }
            if (message.includeSchool != null && message.hasOwnProperty("includeSchool")) {
                object.includeSchool = message.includeSchool;
                if (options.oneofs)
                    object._includeSchool = "includeSchool";
            }
            if (message.sectionIds && message.sectionIds.length) {
                object.sectionIds = [];
                for (var j = 0; j < message.sectionIds.length; ++j)
                    object.sectionIds[j] = message.sectionIds[j];
            }
            if (message.includeSections != null && message.hasOwnProperty("includeSections")) {
                object.includeSections = message.includeSections;
                if (options.oneofs)
                    object._includeSections = "includeSections";
            }
            if (message.districtIds && message.districtIds.length) {
                object.districtIds = [];
                for (var j = 0; j < message.districtIds.length; ++j)
                    object.districtIds[j] = message.districtIds[j];
            }
            return object;
        };

        /**
         * Converts this GetClassXsRequest to JSON.
         * @function toJSON
         * @memberof class_x_service.GetClassXsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetClassXsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetClassXsRequest
         * @function getTypeUrl
         * @memberof class_x_service.GetClassXsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetClassXsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/class_x_service.GetClassXsRequest";
        };

        return GetClassXsRequest;
    })();

    class_x_service.GetClassXsResponse = (function() {

        /**
         * Properties of a GetClassXsResponse.
         * @memberof class_x_service
         * @interface IGetClassXsResponse
         * @property {Array.<pl_types.IClassX>|null} [classXs] GetClassXsResponse classXs
         */

        /**
         * Constructs a new GetClassXsResponse.
         * @memberof class_x_service
         * @classdesc Represents a GetClassXsResponse.
         * @implements IGetClassXsResponse
         * @constructor
         * @param {class_x_service.IGetClassXsResponse=} [properties] Properties to set
         */
        function GetClassXsResponse(properties) {
            this.classXs = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetClassXsResponse classXs.
         * @member {Array.<pl_types.IClassX>} classXs
         * @memberof class_x_service.GetClassXsResponse
         * @instance
         */
        GetClassXsResponse.prototype.classXs = $util.emptyArray;

        /**
         * Creates a new GetClassXsResponse instance using the specified properties.
         * @function create
         * @memberof class_x_service.GetClassXsResponse
         * @static
         * @param {class_x_service.IGetClassXsResponse=} [properties] Properties to set
         * @returns {class_x_service.GetClassXsResponse} GetClassXsResponse instance
         */
        GetClassXsResponse.create = function create(properties) {
            return new GetClassXsResponse(properties);
        };

        /**
         * Encodes the specified GetClassXsResponse message. Does not implicitly {@link class_x_service.GetClassXsResponse.verify|verify} messages.
         * @function encode
         * @memberof class_x_service.GetClassXsResponse
         * @static
         * @param {class_x_service.IGetClassXsResponse} message GetClassXsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetClassXsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.classXs != null && message.classXs.length)
                for (var i = 0; i < message.classXs.length; ++i)
                    $root.pl_types.ClassX.encode(message.classXs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetClassXsResponse message, length delimited. Does not implicitly {@link class_x_service.GetClassXsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof class_x_service.GetClassXsResponse
         * @static
         * @param {class_x_service.IGetClassXsResponse} message GetClassXsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetClassXsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetClassXsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof class_x_service.GetClassXsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {class_x_service.GetClassXsResponse} GetClassXsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetClassXsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.class_x_service.GetClassXsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.classXs && message.classXs.length))
                            message.classXs = [];
                        message.classXs.push($root.pl_types.ClassX.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetClassXsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof class_x_service.GetClassXsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {class_x_service.GetClassXsResponse} GetClassXsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetClassXsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetClassXsResponse message.
         * @function verify
         * @memberof class_x_service.GetClassXsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetClassXsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.classXs != null && message.hasOwnProperty("classXs")) {
                if (!Array.isArray(message.classXs))
                    return "classXs: array expected";
                for (var i = 0; i < message.classXs.length; ++i) {
                    var error = $root.pl_types.ClassX.verify(message.classXs[i]);
                    if (error)
                        return "classXs." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetClassXsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof class_x_service.GetClassXsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {class_x_service.GetClassXsResponse} GetClassXsResponse
         */
        GetClassXsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.class_x_service.GetClassXsResponse)
                return object;
            var message = new $root.class_x_service.GetClassXsResponse();
            if (object.classXs) {
                if (!Array.isArray(object.classXs))
                    throw TypeError(".class_x_service.GetClassXsResponse.classXs: array expected");
                message.classXs = [];
                for (var i = 0; i < object.classXs.length; ++i) {
                    if (typeof object.classXs[i] !== "object")
                        throw TypeError(".class_x_service.GetClassXsResponse.classXs: object expected");
                    message.classXs[i] = $root.pl_types.ClassX.fromObject(object.classXs[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetClassXsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof class_x_service.GetClassXsResponse
         * @static
         * @param {class_x_service.GetClassXsResponse} message GetClassXsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetClassXsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.classXs = [];
            if (message.classXs && message.classXs.length) {
                object.classXs = [];
                for (var j = 0; j < message.classXs.length; ++j)
                    object.classXs[j] = $root.pl_types.ClassX.toObject(message.classXs[j], options);
            }
            return object;
        };

        /**
         * Converts this GetClassXsResponse to JSON.
         * @function toJSON
         * @memberof class_x_service.GetClassXsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetClassXsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetClassXsResponse
         * @function getTypeUrl
         * @memberof class_x_service.GetClassXsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetClassXsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/class_x_service.GetClassXsResponse";
        };

        return GetClassXsResponse;
    })();

    class_x_service.UpsertClassXRequest = (function() {

        /**
         * Properties of an UpsertClassXRequest.
         * @memberof class_x_service
         * @interface IUpsertClassXRequest
         * @property {pl_types.IClassX|null} [classX] UpsertClassXRequest classX
         */

        /**
         * Constructs a new UpsertClassXRequest.
         * @memberof class_x_service
         * @classdesc Represents an UpsertClassXRequest.
         * @implements IUpsertClassXRequest
         * @constructor
         * @param {class_x_service.IUpsertClassXRequest=} [properties] Properties to set
         */
        function UpsertClassXRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertClassXRequest classX.
         * @member {pl_types.IClassX|null|undefined} classX
         * @memberof class_x_service.UpsertClassXRequest
         * @instance
         */
        UpsertClassXRequest.prototype.classX = null;

        /**
         * Creates a new UpsertClassXRequest instance using the specified properties.
         * @function create
         * @memberof class_x_service.UpsertClassXRequest
         * @static
         * @param {class_x_service.IUpsertClassXRequest=} [properties] Properties to set
         * @returns {class_x_service.UpsertClassXRequest} UpsertClassXRequest instance
         */
        UpsertClassXRequest.create = function create(properties) {
            return new UpsertClassXRequest(properties);
        };

        /**
         * Encodes the specified UpsertClassXRequest message. Does not implicitly {@link class_x_service.UpsertClassXRequest.verify|verify} messages.
         * @function encode
         * @memberof class_x_service.UpsertClassXRequest
         * @static
         * @param {class_x_service.IUpsertClassXRequest} message UpsertClassXRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertClassXRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.classX != null && Object.hasOwnProperty.call(message, "classX"))
                $root.pl_types.ClassX.encode(message.classX, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpsertClassXRequest message, length delimited. Does not implicitly {@link class_x_service.UpsertClassXRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof class_x_service.UpsertClassXRequest
         * @static
         * @param {class_x_service.IUpsertClassXRequest} message UpsertClassXRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertClassXRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpsertClassXRequest message from the specified reader or buffer.
         * @function decode
         * @memberof class_x_service.UpsertClassXRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {class_x_service.UpsertClassXRequest} UpsertClassXRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertClassXRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.class_x_service.UpsertClassXRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.classX = $root.pl_types.ClassX.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpsertClassXRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof class_x_service.UpsertClassXRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {class_x_service.UpsertClassXRequest} UpsertClassXRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertClassXRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpsertClassXRequest message.
         * @function verify
         * @memberof class_x_service.UpsertClassXRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpsertClassXRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.classX != null && message.hasOwnProperty("classX")) {
                var error = $root.pl_types.ClassX.verify(message.classX);
                if (error)
                    return "classX." + error;
            }
            return null;
        };

        /**
         * Creates an UpsertClassXRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof class_x_service.UpsertClassXRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {class_x_service.UpsertClassXRequest} UpsertClassXRequest
         */
        UpsertClassXRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.class_x_service.UpsertClassXRequest)
                return object;
            var message = new $root.class_x_service.UpsertClassXRequest();
            if (object.classX != null) {
                if (typeof object.classX !== "object")
                    throw TypeError(".class_x_service.UpsertClassXRequest.classX: object expected");
                message.classX = $root.pl_types.ClassX.fromObject(object.classX);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpsertClassXRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof class_x_service.UpsertClassXRequest
         * @static
         * @param {class_x_service.UpsertClassXRequest} message UpsertClassXRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpsertClassXRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.classX = null;
            if (message.classX != null && message.hasOwnProperty("classX"))
                object.classX = $root.pl_types.ClassX.toObject(message.classX, options);
            return object;
        };

        /**
         * Converts this UpsertClassXRequest to JSON.
         * @function toJSON
         * @memberof class_x_service.UpsertClassXRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpsertClassXRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpsertClassXRequest
         * @function getTypeUrl
         * @memberof class_x_service.UpsertClassXRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpsertClassXRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/class_x_service.UpsertClassXRequest";
        };

        return UpsertClassXRequest;
    })();

    class_x_service.UpsertClassXResponse = (function() {

        /**
         * Properties of an UpsertClassXResponse.
         * @memberof class_x_service
         * @interface IUpsertClassXResponse
         * @property {pl_types.IClassX|null} [classX] UpsertClassXResponse classX
         */

        /**
         * Constructs a new UpsertClassXResponse.
         * @memberof class_x_service
         * @classdesc Represents an UpsertClassXResponse.
         * @implements IUpsertClassXResponse
         * @constructor
         * @param {class_x_service.IUpsertClassXResponse=} [properties] Properties to set
         */
        function UpsertClassXResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertClassXResponse classX.
         * @member {pl_types.IClassX|null|undefined} classX
         * @memberof class_x_service.UpsertClassXResponse
         * @instance
         */
        UpsertClassXResponse.prototype.classX = null;

        /**
         * Creates a new UpsertClassXResponse instance using the specified properties.
         * @function create
         * @memberof class_x_service.UpsertClassXResponse
         * @static
         * @param {class_x_service.IUpsertClassXResponse=} [properties] Properties to set
         * @returns {class_x_service.UpsertClassXResponse} UpsertClassXResponse instance
         */
        UpsertClassXResponse.create = function create(properties) {
            return new UpsertClassXResponse(properties);
        };

        /**
         * Encodes the specified UpsertClassXResponse message. Does not implicitly {@link class_x_service.UpsertClassXResponse.verify|verify} messages.
         * @function encode
         * @memberof class_x_service.UpsertClassXResponse
         * @static
         * @param {class_x_service.IUpsertClassXResponse} message UpsertClassXResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertClassXResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.classX != null && Object.hasOwnProperty.call(message, "classX"))
                $root.pl_types.ClassX.encode(message.classX, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpsertClassXResponse message, length delimited. Does not implicitly {@link class_x_service.UpsertClassXResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof class_x_service.UpsertClassXResponse
         * @static
         * @param {class_x_service.IUpsertClassXResponse} message UpsertClassXResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertClassXResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpsertClassXResponse message from the specified reader or buffer.
         * @function decode
         * @memberof class_x_service.UpsertClassXResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {class_x_service.UpsertClassXResponse} UpsertClassXResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertClassXResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.class_x_service.UpsertClassXResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.classX = $root.pl_types.ClassX.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpsertClassXResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof class_x_service.UpsertClassXResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {class_x_service.UpsertClassXResponse} UpsertClassXResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertClassXResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpsertClassXResponse message.
         * @function verify
         * @memberof class_x_service.UpsertClassXResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpsertClassXResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.classX != null && message.hasOwnProperty("classX")) {
                var error = $root.pl_types.ClassX.verify(message.classX);
                if (error)
                    return "classX." + error;
            }
            return null;
        };

        /**
         * Creates an UpsertClassXResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof class_x_service.UpsertClassXResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {class_x_service.UpsertClassXResponse} UpsertClassXResponse
         */
        UpsertClassXResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.class_x_service.UpsertClassXResponse)
                return object;
            var message = new $root.class_x_service.UpsertClassXResponse();
            if (object.classX != null) {
                if (typeof object.classX !== "object")
                    throw TypeError(".class_x_service.UpsertClassXResponse.classX: object expected");
                message.classX = $root.pl_types.ClassX.fromObject(object.classX);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpsertClassXResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof class_x_service.UpsertClassXResponse
         * @static
         * @param {class_x_service.UpsertClassXResponse} message UpsertClassXResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpsertClassXResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.classX = null;
            if (message.classX != null && message.hasOwnProperty("classX"))
                object.classX = $root.pl_types.ClassX.toObject(message.classX, options);
            return object;
        };

        /**
         * Converts this UpsertClassXResponse to JSON.
         * @function toJSON
         * @memberof class_x_service.UpsertClassXResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpsertClassXResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpsertClassXResponse
         * @function getTypeUrl
         * @memberof class_x_service.UpsertClassXResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpsertClassXResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/class_x_service.UpsertClassXResponse";
        };

        return UpsertClassXResponse;
    })();

    return class_x_service;
})();

$root.district_service = (function() {

    /**
     * Namespace district_service.
     * @exports district_service
     * @namespace
     */
    var district_service = {};

    district_service.DistrictService = (function() {

        /**
         * Constructs a new DistrictService service.
         * @memberof district_service
         * @classdesc Represents a DistrictService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function DistrictService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (DistrictService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = DistrictService;

        /**
         * Creates new DistrictService service using the specified rpc implementation.
         * @function create
         * @memberof district_service.DistrictService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {DistrictService} RPC service. Useful where requests and/or responses are streamed.
         */
        DistrictService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link district_service.DistrictService#getTerms}.
         * @memberof district_service.DistrictService
         * @typedef GetTermsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {district_service.GetTermsResponse} [response] GetTermsResponse
         */

        /**
         * Calls GetTerms.
         * @function getTerms
         * @memberof district_service.DistrictService
         * @instance
         * @param {district_service.IGetTermsRequest} request GetTermsRequest message or plain object
         * @param {district_service.DistrictService.GetTermsCallback} callback Node-style callback called with the error, if any, and GetTermsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(DistrictService.prototype.getTerms = function getTerms(request, callback) {
            return this.rpcCall(getTerms, $root.district_service.GetTermsRequest, $root.district_service.GetTermsResponse, request, callback);
        }, "name", { value: "GetTerms" });

        /**
         * Calls GetTerms.
         * @function getTerms
         * @memberof district_service.DistrictService
         * @instance
         * @param {district_service.IGetTermsRequest} request GetTermsRequest message or plain object
         * @returns {Promise<district_service.GetTermsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link district_service.DistrictService#getDistricts}.
         * @memberof district_service.DistrictService
         * @typedef GetDistrictsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {district_service.DistrictInformationResponse} [response] DistrictInformationResponse
         */

        /**
         * Calls GetDistricts.
         * @function getDistricts
         * @memberof district_service.DistrictService
         * @instance
         * @param {district_service.IGetDistrictsRequest} request GetDistrictsRequest message or plain object
         * @param {district_service.DistrictService.GetDistrictsCallback} callback Node-style callback called with the error, if any, and DistrictInformationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(DistrictService.prototype.getDistricts = function getDistricts(request, callback) {
            return this.rpcCall(getDistricts, $root.district_service.GetDistrictsRequest, $root.district_service.DistrictInformationResponse, request, callback);
        }, "name", { value: "GetDistricts" });

        /**
         * Calls GetDistricts.
         * @function getDistricts
         * @memberof district_service.DistrictService
         * @instance
         * @param {district_service.IGetDistrictsRequest} request GetDistrictsRequest message or plain object
         * @returns {Promise<district_service.DistrictInformationResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link district_service.DistrictService#addDistrict}.
         * @memberof district_service.DistrictService
         * @typedef AddDistrictCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {district_service.DistrictInformationResponse} [response] DistrictInformationResponse
         */

        /**
         * Calls AddDistrict.
         * @function addDistrict
         * @memberof district_service.DistrictService
         * @instance
         * @param {district_service.IAddDistrictRequest} request AddDistrictRequest message or plain object
         * @param {district_service.DistrictService.AddDistrictCallback} callback Node-style callback called with the error, if any, and DistrictInformationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(DistrictService.prototype.addDistrict = function addDistrict(request, callback) {
            return this.rpcCall(addDistrict, $root.district_service.AddDistrictRequest, $root.district_service.DistrictInformationResponse, request, callback);
        }, "name", { value: "AddDistrict" });

        /**
         * Calls AddDistrict.
         * @function addDistrict
         * @memberof district_service.DistrictService
         * @instance
         * @param {district_service.IAddDistrictRequest} request AddDistrictRequest message or plain object
         * @returns {Promise<district_service.DistrictInformationResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link district_service.DistrictService#updateDistrict}.
         * @memberof district_service.DistrictService
         * @typedef UpdateDistrictCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {district_service.DistrictInformationResponse} [response] DistrictInformationResponse
         */

        /**
         * Calls UpdateDistrict.
         * @function updateDistrict
         * @memberof district_service.DistrictService
         * @instance
         * @param {district_service.IUpdateDistrictRequest} request UpdateDistrictRequest message or plain object
         * @param {district_service.DistrictService.UpdateDistrictCallback} callback Node-style callback called with the error, if any, and DistrictInformationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(DistrictService.prototype.updateDistrict = function updateDistrict(request, callback) {
            return this.rpcCall(updateDistrict, $root.district_service.UpdateDistrictRequest, $root.district_service.DistrictInformationResponse, request, callback);
        }, "name", { value: "UpdateDistrict" });

        /**
         * Calls UpdateDistrict.
         * @function updateDistrict
         * @memberof district_service.DistrictService
         * @instance
         * @param {district_service.IUpdateDistrictRequest} request UpdateDistrictRequest message or plain object
         * @returns {Promise<district_service.DistrictInformationResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link district_service.DistrictService#removeDistrict}.
         * @memberof district_service.DistrictService
         * @typedef RemoveDistrictCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {district_service.DistrictInformationResponse} [response] DistrictInformationResponse
         */

        /**
         * Calls RemoveDistrict.
         * @function removeDistrict
         * @memberof district_service.DistrictService
         * @instance
         * @param {district_service.IRemoveDistrictRequest} request RemoveDistrictRequest message or plain object
         * @param {district_service.DistrictService.RemoveDistrictCallback} callback Node-style callback called with the error, if any, and DistrictInformationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(DistrictService.prototype.removeDistrict = function removeDistrict(request, callback) {
            return this.rpcCall(removeDistrict, $root.district_service.RemoveDistrictRequest, $root.district_service.DistrictInformationResponse, request, callback);
        }, "name", { value: "RemoveDistrict" });

        /**
         * Calls RemoveDistrict.
         * @function removeDistrict
         * @memberof district_service.DistrictService
         * @instance
         * @param {district_service.IRemoveDistrictRequest} request RemoveDistrictRequest message or plain object
         * @returns {Promise<district_service.DistrictInformationResponse>} Promise
         * @variation 2
         */

        return DistrictService;
    })();

    district_service.GetTermsRequest = (function() {

        /**
         * Properties of a GetTermsRequest.
         * @memberof district_service
         * @interface IGetTermsRequest
         * @property {Array.<number>|null} [userXIds] GetTermsRequest userXIds
         * @property {Array.<number>|null} [teacherUserXIds] GetTermsRequest teacherUserXIds
         * @property {Array.<number>|null} [studentUserXIds] GetTermsRequest studentUserXIds
         */

        /**
         * Constructs a new GetTermsRequest.
         * @memberof district_service
         * @classdesc Represents a GetTermsRequest.
         * @implements IGetTermsRequest
         * @constructor
         * @param {district_service.IGetTermsRequest=} [properties] Properties to set
         */
        function GetTermsRequest(properties) {
            this.userXIds = [];
            this.teacherUserXIds = [];
            this.studentUserXIds = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetTermsRequest userXIds.
         * @member {Array.<number>} userXIds
         * @memberof district_service.GetTermsRequest
         * @instance
         */
        GetTermsRequest.prototype.userXIds = $util.emptyArray;

        /**
         * GetTermsRequest teacherUserXIds.
         * @member {Array.<number>} teacherUserXIds
         * @memberof district_service.GetTermsRequest
         * @instance
         */
        GetTermsRequest.prototype.teacherUserXIds = $util.emptyArray;

        /**
         * GetTermsRequest studentUserXIds.
         * @member {Array.<number>} studentUserXIds
         * @memberof district_service.GetTermsRequest
         * @instance
         */
        GetTermsRequest.prototype.studentUserXIds = $util.emptyArray;

        /**
         * Creates a new GetTermsRequest instance using the specified properties.
         * @function create
         * @memberof district_service.GetTermsRequest
         * @static
         * @param {district_service.IGetTermsRequest=} [properties] Properties to set
         * @returns {district_service.GetTermsRequest} GetTermsRequest instance
         */
        GetTermsRequest.create = function create(properties) {
            return new GetTermsRequest(properties);
        };

        /**
         * Encodes the specified GetTermsRequest message. Does not implicitly {@link district_service.GetTermsRequest.verify|verify} messages.
         * @function encode
         * @memberof district_service.GetTermsRequest
         * @static
         * @param {district_service.IGetTermsRequest} message GetTermsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTermsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userXIds != null && message.userXIds.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.userXIds.length; ++i)
                    writer.int32(message.userXIds[i]);
                writer.ldelim();
            }
            if (message.teacherUserXIds != null && message.teacherUserXIds.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.teacherUserXIds.length; ++i)
                    writer.int32(message.teacherUserXIds[i]);
                writer.ldelim();
            }
            if (message.studentUserXIds != null && message.studentUserXIds.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (var i = 0; i < message.studentUserXIds.length; ++i)
                    writer.int32(message.studentUserXIds[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified GetTermsRequest message, length delimited. Does not implicitly {@link district_service.GetTermsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof district_service.GetTermsRequest
         * @static
         * @param {district_service.IGetTermsRequest} message GetTermsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTermsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetTermsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof district_service.GetTermsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {district_service.GetTermsRequest} GetTermsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTermsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.district_service.GetTermsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.userXIds && message.userXIds.length))
                            message.userXIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.userXIds.push(reader.int32());
                        } else
                            message.userXIds.push(reader.int32());
                        break;
                    }
                case 2: {
                        if (!(message.teacherUserXIds && message.teacherUserXIds.length))
                            message.teacherUserXIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.teacherUserXIds.push(reader.int32());
                        } else
                            message.teacherUserXIds.push(reader.int32());
                        break;
                    }
                case 3: {
                        if (!(message.studentUserXIds && message.studentUserXIds.length))
                            message.studentUserXIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.studentUserXIds.push(reader.int32());
                        } else
                            message.studentUserXIds.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetTermsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof district_service.GetTermsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {district_service.GetTermsRequest} GetTermsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTermsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetTermsRequest message.
         * @function verify
         * @memberof district_service.GetTermsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetTermsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userXIds != null && message.hasOwnProperty("userXIds")) {
                if (!Array.isArray(message.userXIds))
                    return "userXIds: array expected";
                for (var i = 0; i < message.userXIds.length; ++i)
                    if (!$util.isInteger(message.userXIds[i]))
                        return "userXIds: integer[] expected";
            }
            if (message.teacherUserXIds != null && message.hasOwnProperty("teacherUserXIds")) {
                if (!Array.isArray(message.teacherUserXIds))
                    return "teacherUserXIds: array expected";
                for (var i = 0; i < message.teacherUserXIds.length; ++i)
                    if (!$util.isInteger(message.teacherUserXIds[i]))
                        return "teacherUserXIds: integer[] expected";
            }
            if (message.studentUserXIds != null && message.hasOwnProperty("studentUserXIds")) {
                if (!Array.isArray(message.studentUserXIds))
                    return "studentUserXIds: array expected";
                for (var i = 0; i < message.studentUserXIds.length; ++i)
                    if (!$util.isInteger(message.studentUserXIds[i]))
                        return "studentUserXIds: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a GetTermsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof district_service.GetTermsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {district_service.GetTermsRequest} GetTermsRequest
         */
        GetTermsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.district_service.GetTermsRequest)
                return object;
            var message = new $root.district_service.GetTermsRequest();
            if (object.userXIds) {
                if (!Array.isArray(object.userXIds))
                    throw TypeError(".district_service.GetTermsRequest.userXIds: array expected");
                message.userXIds = [];
                for (var i = 0; i < object.userXIds.length; ++i)
                    message.userXIds[i] = object.userXIds[i] | 0;
            }
            if (object.teacherUserXIds) {
                if (!Array.isArray(object.teacherUserXIds))
                    throw TypeError(".district_service.GetTermsRequest.teacherUserXIds: array expected");
                message.teacherUserXIds = [];
                for (var i = 0; i < object.teacherUserXIds.length; ++i)
                    message.teacherUserXIds[i] = object.teacherUserXIds[i] | 0;
            }
            if (object.studentUserXIds) {
                if (!Array.isArray(object.studentUserXIds))
                    throw TypeError(".district_service.GetTermsRequest.studentUserXIds: array expected");
                message.studentUserXIds = [];
                for (var i = 0; i < object.studentUserXIds.length; ++i)
                    message.studentUserXIds[i] = object.studentUserXIds[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a GetTermsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof district_service.GetTermsRequest
         * @static
         * @param {district_service.GetTermsRequest} message GetTermsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetTermsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.userXIds = [];
                object.teacherUserXIds = [];
                object.studentUserXIds = [];
            }
            if (message.userXIds && message.userXIds.length) {
                object.userXIds = [];
                for (var j = 0; j < message.userXIds.length; ++j)
                    object.userXIds[j] = message.userXIds[j];
            }
            if (message.teacherUserXIds && message.teacherUserXIds.length) {
                object.teacherUserXIds = [];
                for (var j = 0; j < message.teacherUserXIds.length; ++j)
                    object.teacherUserXIds[j] = message.teacherUserXIds[j];
            }
            if (message.studentUserXIds && message.studentUserXIds.length) {
                object.studentUserXIds = [];
                for (var j = 0; j < message.studentUserXIds.length; ++j)
                    object.studentUserXIds[j] = message.studentUserXIds[j];
            }
            return object;
        };

        /**
         * Converts this GetTermsRequest to JSON.
         * @function toJSON
         * @memberof district_service.GetTermsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetTermsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetTermsRequest
         * @function getTypeUrl
         * @memberof district_service.GetTermsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetTermsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/district_service.GetTermsRequest";
        };

        return GetTermsRequest;
    })();

    district_service.GetTermsResponse = (function() {

        /**
         * Properties of a GetTermsResponse.
         * @memberof district_service
         * @interface IGetTermsResponse
         * @property {Array.<pl_types.ITerm>|null} [terms] GetTermsResponse terms
         */

        /**
         * Constructs a new GetTermsResponse.
         * @memberof district_service
         * @classdesc Represents a GetTermsResponse.
         * @implements IGetTermsResponse
         * @constructor
         * @param {district_service.IGetTermsResponse=} [properties] Properties to set
         */
        function GetTermsResponse(properties) {
            this.terms = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetTermsResponse terms.
         * @member {Array.<pl_types.ITerm>} terms
         * @memberof district_service.GetTermsResponse
         * @instance
         */
        GetTermsResponse.prototype.terms = $util.emptyArray;

        /**
         * Creates a new GetTermsResponse instance using the specified properties.
         * @function create
         * @memberof district_service.GetTermsResponse
         * @static
         * @param {district_service.IGetTermsResponse=} [properties] Properties to set
         * @returns {district_service.GetTermsResponse} GetTermsResponse instance
         */
        GetTermsResponse.create = function create(properties) {
            return new GetTermsResponse(properties);
        };

        /**
         * Encodes the specified GetTermsResponse message. Does not implicitly {@link district_service.GetTermsResponse.verify|verify} messages.
         * @function encode
         * @memberof district_service.GetTermsResponse
         * @static
         * @param {district_service.IGetTermsResponse} message GetTermsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTermsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.terms != null && message.terms.length)
                for (var i = 0; i < message.terms.length; ++i)
                    $root.pl_types.Term.encode(message.terms[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetTermsResponse message, length delimited. Does not implicitly {@link district_service.GetTermsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof district_service.GetTermsResponse
         * @static
         * @param {district_service.IGetTermsResponse} message GetTermsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTermsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetTermsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof district_service.GetTermsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {district_service.GetTermsResponse} GetTermsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTermsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.district_service.GetTermsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.terms && message.terms.length))
                            message.terms = [];
                        message.terms.push($root.pl_types.Term.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetTermsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof district_service.GetTermsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {district_service.GetTermsResponse} GetTermsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTermsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetTermsResponse message.
         * @function verify
         * @memberof district_service.GetTermsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetTermsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.terms != null && message.hasOwnProperty("terms")) {
                if (!Array.isArray(message.terms))
                    return "terms: array expected";
                for (var i = 0; i < message.terms.length; ++i) {
                    var error = $root.pl_types.Term.verify(message.terms[i]);
                    if (error)
                        return "terms." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetTermsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof district_service.GetTermsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {district_service.GetTermsResponse} GetTermsResponse
         */
        GetTermsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.district_service.GetTermsResponse)
                return object;
            var message = new $root.district_service.GetTermsResponse();
            if (object.terms) {
                if (!Array.isArray(object.terms))
                    throw TypeError(".district_service.GetTermsResponse.terms: array expected");
                message.terms = [];
                for (var i = 0; i < object.terms.length; ++i) {
                    if (typeof object.terms[i] !== "object")
                        throw TypeError(".district_service.GetTermsResponse.terms: object expected");
                    message.terms[i] = $root.pl_types.Term.fromObject(object.terms[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetTermsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof district_service.GetTermsResponse
         * @static
         * @param {district_service.GetTermsResponse} message GetTermsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetTermsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.terms = [];
            if (message.terms && message.terms.length) {
                object.terms = [];
                for (var j = 0; j < message.terms.length; ++j)
                    object.terms[j] = $root.pl_types.Term.toObject(message.terms[j], options);
            }
            return object;
        };

        /**
         * Converts this GetTermsResponse to JSON.
         * @function toJSON
         * @memberof district_service.GetTermsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetTermsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetTermsResponse
         * @function getTypeUrl
         * @memberof district_service.GetTermsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetTermsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/district_service.GetTermsResponse";
        };

        return GetTermsResponse;
    })();

    district_service.GetDistrictsRequest = (function() {

        /**
         * Properties of a GetDistrictsRequest.
         * @memberof district_service
         * @interface IGetDistrictsRequest
         */

        /**
         * Constructs a new GetDistrictsRequest.
         * @memberof district_service
         * @classdesc Represents a GetDistrictsRequest.
         * @implements IGetDistrictsRequest
         * @constructor
         * @param {district_service.IGetDistrictsRequest=} [properties] Properties to set
         */
        function GetDistrictsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetDistrictsRequest instance using the specified properties.
         * @function create
         * @memberof district_service.GetDistrictsRequest
         * @static
         * @param {district_service.IGetDistrictsRequest=} [properties] Properties to set
         * @returns {district_service.GetDistrictsRequest} GetDistrictsRequest instance
         */
        GetDistrictsRequest.create = function create(properties) {
            return new GetDistrictsRequest(properties);
        };

        /**
         * Encodes the specified GetDistrictsRequest message. Does not implicitly {@link district_service.GetDistrictsRequest.verify|verify} messages.
         * @function encode
         * @memberof district_service.GetDistrictsRequest
         * @static
         * @param {district_service.IGetDistrictsRequest} message GetDistrictsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetDistrictsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetDistrictsRequest message, length delimited. Does not implicitly {@link district_service.GetDistrictsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof district_service.GetDistrictsRequest
         * @static
         * @param {district_service.IGetDistrictsRequest} message GetDistrictsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetDistrictsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetDistrictsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof district_service.GetDistrictsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {district_service.GetDistrictsRequest} GetDistrictsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetDistrictsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.district_service.GetDistrictsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetDistrictsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof district_service.GetDistrictsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {district_service.GetDistrictsRequest} GetDistrictsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetDistrictsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetDistrictsRequest message.
         * @function verify
         * @memberof district_service.GetDistrictsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetDistrictsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetDistrictsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof district_service.GetDistrictsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {district_service.GetDistrictsRequest} GetDistrictsRequest
         */
        GetDistrictsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.district_service.GetDistrictsRequest)
                return object;
            return new $root.district_service.GetDistrictsRequest();
        };

        /**
         * Creates a plain object from a GetDistrictsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof district_service.GetDistrictsRequest
         * @static
         * @param {district_service.GetDistrictsRequest} message GetDistrictsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetDistrictsRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetDistrictsRequest to JSON.
         * @function toJSON
         * @memberof district_service.GetDistrictsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetDistrictsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetDistrictsRequest
         * @function getTypeUrl
         * @memberof district_service.GetDistrictsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetDistrictsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/district_service.GetDistrictsRequest";
        };

        return GetDistrictsRequest;
    })();

    district_service.AddDistrictRequest = (function() {

        /**
         * Properties of an AddDistrictRequest.
         * @memberof district_service
         * @interface IAddDistrictRequest
         * @property {pl_types.IDistrict|null} [district] AddDistrictRequest district
         */

        /**
         * Constructs a new AddDistrictRequest.
         * @memberof district_service
         * @classdesc Represents an AddDistrictRequest.
         * @implements IAddDistrictRequest
         * @constructor
         * @param {district_service.IAddDistrictRequest=} [properties] Properties to set
         */
        function AddDistrictRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddDistrictRequest district.
         * @member {pl_types.IDistrict|null|undefined} district
         * @memberof district_service.AddDistrictRequest
         * @instance
         */
        AddDistrictRequest.prototype.district = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * AddDistrictRequest _district.
         * @member {"district"|undefined} _district
         * @memberof district_service.AddDistrictRequest
         * @instance
         */
        Object.defineProperty(AddDistrictRequest.prototype, "_district", {
            get: $util.oneOfGetter($oneOfFields = ["district"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new AddDistrictRequest instance using the specified properties.
         * @function create
         * @memberof district_service.AddDistrictRequest
         * @static
         * @param {district_service.IAddDistrictRequest=} [properties] Properties to set
         * @returns {district_service.AddDistrictRequest} AddDistrictRequest instance
         */
        AddDistrictRequest.create = function create(properties) {
            return new AddDistrictRequest(properties);
        };

        /**
         * Encodes the specified AddDistrictRequest message. Does not implicitly {@link district_service.AddDistrictRequest.verify|verify} messages.
         * @function encode
         * @memberof district_service.AddDistrictRequest
         * @static
         * @param {district_service.IAddDistrictRequest} message AddDistrictRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddDistrictRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.district != null && Object.hasOwnProperty.call(message, "district"))
                $root.pl_types.District.encode(message.district, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AddDistrictRequest message, length delimited. Does not implicitly {@link district_service.AddDistrictRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof district_service.AddDistrictRequest
         * @static
         * @param {district_service.IAddDistrictRequest} message AddDistrictRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddDistrictRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddDistrictRequest message from the specified reader or buffer.
         * @function decode
         * @memberof district_service.AddDistrictRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {district_service.AddDistrictRequest} AddDistrictRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddDistrictRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.district_service.AddDistrictRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.district = $root.pl_types.District.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddDistrictRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof district_service.AddDistrictRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {district_service.AddDistrictRequest} AddDistrictRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddDistrictRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddDistrictRequest message.
         * @function verify
         * @memberof district_service.AddDistrictRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddDistrictRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.district != null && message.hasOwnProperty("district")) {
                properties._district = 1;
                {
                    var error = $root.pl_types.District.verify(message.district);
                    if (error)
                        return "district." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AddDistrictRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof district_service.AddDistrictRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {district_service.AddDistrictRequest} AddDistrictRequest
         */
        AddDistrictRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.district_service.AddDistrictRequest)
                return object;
            var message = new $root.district_service.AddDistrictRequest();
            if (object.district != null) {
                if (typeof object.district !== "object")
                    throw TypeError(".district_service.AddDistrictRequest.district: object expected");
                message.district = $root.pl_types.District.fromObject(object.district);
            }
            return message;
        };

        /**
         * Creates a plain object from an AddDistrictRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof district_service.AddDistrictRequest
         * @static
         * @param {district_service.AddDistrictRequest} message AddDistrictRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddDistrictRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.district != null && message.hasOwnProperty("district")) {
                object.district = $root.pl_types.District.toObject(message.district, options);
                if (options.oneofs)
                    object._district = "district";
            }
            return object;
        };

        /**
         * Converts this AddDistrictRequest to JSON.
         * @function toJSON
         * @memberof district_service.AddDistrictRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddDistrictRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AddDistrictRequest
         * @function getTypeUrl
         * @memberof district_service.AddDistrictRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AddDistrictRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/district_service.AddDistrictRequest";
        };

        return AddDistrictRequest;
    })();

    district_service.UpdateDistrictRequest = (function() {

        /**
         * Properties of an UpdateDistrictRequest.
         * @memberof district_service
         * @interface IUpdateDistrictRequest
         * @property {pl_types.IDistrict|null} [district] UpdateDistrictRequest district
         */

        /**
         * Constructs a new UpdateDistrictRequest.
         * @memberof district_service
         * @classdesc Represents an UpdateDistrictRequest.
         * @implements IUpdateDistrictRequest
         * @constructor
         * @param {district_service.IUpdateDistrictRequest=} [properties] Properties to set
         */
        function UpdateDistrictRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateDistrictRequest district.
         * @member {pl_types.IDistrict|null|undefined} district
         * @memberof district_service.UpdateDistrictRequest
         * @instance
         */
        UpdateDistrictRequest.prototype.district = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UpdateDistrictRequest _district.
         * @member {"district"|undefined} _district
         * @memberof district_service.UpdateDistrictRequest
         * @instance
         */
        Object.defineProperty(UpdateDistrictRequest.prototype, "_district", {
            get: $util.oneOfGetter($oneOfFields = ["district"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UpdateDistrictRequest instance using the specified properties.
         * @function create
         * @memberof district_service.UpdateDistrictRequest
         * @static
         * @param {district_service.IUpdateDistrictRequest=} [properties] Properties to set
         * @returns {district_service.UpdateDistrictRequest} UpdateDistrictRequest instance
         */
        UpdateDistrictRequest.create = function create(properties) {
            return new UpdateDistrictRequest(properties);
        };

        /**
         * Encodes the specified UpdateDistrictRequest message. Does not implicitly {@link district_service.UpdateDistrictRequest.verify|verify} messages.
         * @function encode
         * @memberof district_service.UpdateDistrictRequest
         * @static
         * @param {district_service.IUpdateDistrictRequest} message UpdateDistrictRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateDistrictRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.district != null && Object.hasOwnProperty.call(message, "district"))
                $root.pl_types.District.encode(message.district, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateDistrictRequest message, length delimited. Does not implicitly {@link district_service.UpdateDistrictRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof district_service.UpdateDistrictRequest
         * @static
         * @param {district_service.IUpdateDistrictRequest} message UpdateDistrictRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateDistrictRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateDistrictRequest message from the specified reader or buffer.
         * @function decode
         * @memberof district_service.UpdateDistrictRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {district_service.UpdateDistrictRequest} UpdateDistrictRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateDistrictRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.district_service.UpdateDistrictRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.district = $root.pl_types.District.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateDistrictRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof district_service.UpdateDistrictRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {district_service.UpdateDistrictRequest} UpdateDistrictRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateDistrictRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateDistrictRequest message.
         * @function verify
         * @memberof district_service.UpdateDistrictRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateDistrictRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.district != null && message.hasOwnProperty("district")) {
                properties._district = 1;
                {
                    var error = $root.pl_types.District.verify(message.district);
                    if (error)
                        return "district." + error;
                }
            }
            return null;
        };

        /**
         * Creates an UpdateDistrictRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof district_service.UpdateDistrictRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {district_service.UpdateDistrictRequest} UpdateDistrictRequest
         */
        UpdateDistrictRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.district_service.UpdateDistrictRequest)
                return object;
            var message = new $root.district_service.UpdateDistrictRequest();
            if (object.district != null) {
                if (typeof object.district !== "object")
                    throw TypeError(".district_service.UpdateDistrictRequest.district: object expected");
                message.district = $root.pl_types.District.fromObject(object.district);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateDistrictRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof district_service.UpdateDistrictRequest
         * @static
         * @param {district_service.UpdateDistrictRequest} message UpdateDistrictRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateDistrictRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.district != null && message.hasOwnProperty("district")) {
                object.district = $root.pl_types.District.toObject(message.district, options);
                if (options.oneofs)
                    object._district = "district";
            }
            return object;
        };

        /**
         * Converts this UpdateDistrictRequest to JSON.
         * @function toJSON
         * @memberof district_service.UpdateDistrictRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateDistrictRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateDistrictRequest
         * @function getTypeUrl
         * @memberof district_service.UpdateDistrictRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateDistrictRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/district_service.UpdateDistrictRequest";
        };

        return UpdateDistrictRequest;
    })();

    district_service.RemoveDistrictRequest = (function() {

        /**
         * Properties of a RemoveDistrictRequest.
         * @memberof district_service
         * @interface IRemoveDistrictRequest
         * @property {number|null} [districtId] RemoveDistrictRequest districtId
         */

        /**
         * Constructs a new RemoveDistrictRequest.
         * @memberof district_service
         * @classdesc Represents a RemoveDistrictRequest.
         * @implements IRemoveDistrictRequest
         * @constructor
         * @param {district_service.IRemoveDistrictRequest=} [properties] Properties to set
         */
        function RemoveDistrictRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RemoveDistrictRequest districtId.
         * @member {number|null|undefined} districtId
         * @memberof district_service.RemoveDistrictRequest
         * @instance
         */
        RemoveDistrictRequest.prototype.districtId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * RemoveDistrictRequest _districtId.
         * @member {"districtId"|undefined} _districtId
         * @memberof district_service.RemoveDistrictRequest
         * @instance
         */
        Object.defineProperty(RemoveDistrictRequest.prototype, "_districtId", {
            get: $util.oneOfGetter($oneOfFields = ["districtId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new RemoveDistrictRequest instance using the specified properties.
         * @function create
         * @memberof district_service.RemoveDistrictRequest
         * @static
         * @param {district_service.IRemoveDistrictRequest=} [properties] Properties to set
         * @returns {district_service.RemoveDistrictRequest} RemoveDistrictRequest instance
         */
        RemoveDistrictRequest.create = function create(properties) {
            return new RemoveDistrictRequest(properties);
        };

        /**
         * Encodes the specified RemoveDistrictRequest message. Does not implicitly {@link district_service.RemoveDistrictRequest.verify|verify} messages.
         * @function encode
         * @memberof district_service.RemoveDistrictRequest
         * @static
         * @param {district_service.IRemoveDistrictRequest} message RemoveDistrictRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveDistrictRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.districtId != null && Object.hasOwnProperty.call(message, "districtId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.districtId);
            return writer;
        };

        /**
         * Encodes the specified RemoveDistrictRequest message, length delimited. Does not implicitly {@link district_service.RemoveDistrictRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof district_service.RemoveDistrictRequest
         * @static
         * @param {district_service.IRemoveDistrictRequest} message RemoveDistrictRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveDistrictRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RemoveDistrictRequest message from the specified reader or buffer.
         * @function decode
         * @memberof district_service.RemoveDistrictRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {district_service.RemoveDistrictRequest} RemoveDistrictRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveDistrictRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.district_service.RemoveDistrictRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.districtId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RemoveDistrictRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof district_service.RemoveDistrictRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {district_service.RemoveDistrictRequest} RemoveDistrictRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveDistrictRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RemoveDistrictRequest message.
         * @function verify
         * @memberof district_service.RemoveDistrictRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RemoveDistrictRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.districtId != null && message.hasOwnProperty("districtId")) {
                properties._districtId = 1;
                if (!$util.isInteger(message.districtId))
                    return "districtId: integer expected";
            }
            return null;
        };

        /**
         * Creates a RemoveDistrictRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof district_service.RemoveDistrictRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {district_service.RemoveDistrictRequest} RemoveDistrictRequest
         */
        RemoveDistrictRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.district_service.RemoveDistrictRequest)
                return object;
            var message = new $root.district_service.RemoveDistrictRequest();
            if (object.districtId != null)
                message.districtId = object.districtId | 0;
            return message;
        };

        /**
         * Creates a plain object from a RemoveDistrictRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof district_service.RemoveDistrictRequest
         * @static
         * @param {district_service.RemoveDistrictRequest} message RemoveDistrictRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RemoveDistrictRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.districtId != null && message.hasOwnProperty("districtId")) {
                object.districtId = message.districtId;
                if (options.oneofs)
                    object._districtId = "districtId";
            }
            return object;
        };

        /**
         * Converts this RemoveDistrictRequest to JSON.
         * @function toJSON
         * @memberof district_service.RemoveDistrictRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RemoveDistrictRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RemoveDistrictRequest
         * @function getTypeUrl
         * @memberof district_service.RemoveDistrictRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RemoveDistrictRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/district_service.RemoveDistrictRequest";
        };

        return RemoveDistrictRequest;
    })();

    district_service.DistrictInformationResponse = (function() {

        /**
         * Properties of a DistrictInformationResponse.
         * @memberof district_service
         * @interface IDistrictInformationResponse
         * @property {number|null} [modifiedDistrictId] DistrictInformationResponse modifiedDistrictId
         * @property {Array.<pl_types.IDistrict>|null} [districts] DistrictInformationResponse districts
         */

        /**
         * Constructs a new DistrictInformationResponse.
         * @memberof district_service
         * @classdesc Represents a DistrictInformationResponse.
         * @implements IDistrictInformationResponse
         * @constructor
         * @param {district_service.IDistrictInformationResponse=} [properties] Properties to set
         */
        function DistrictInformationResponse(properties) {
            this.districts = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DistrictInformationResponse modifiedDistrictId.
         * @member {number|null|undefined} modifiedDistrictId
         * @memberof district_service.DistrictInformationResponse
         * @instance
         */
        DistrictInformationResponse.prototype.modifiedDistrictId = null;

        /**
         * DistrictInformationResponse districts.
         * @member {Array.<pl_types.IDistrict>} districts
         * @memberof district_service.DistrictInformationResponse
         * @instance
         */
        DistrictInformationResponse.prototype.districts = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * DistrictInformationResponse _modifiedDistrictId.
         * @member {"modifiedDistrictId"|undefined} _modifiedDistrictId
         * @memberof district_service.DistrictInformationResponse
         * @instance
         */
        Object.defineProperty(DistrictInformationResponse.prototype, "_modifiedDistrictId", {
            get: $util.oneOfGetter($oneOfFields = ["modifiedDistrictId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new DistrictInformationResponse instance using the specified properties.
         * @function create
         * @memberof district_service.DistrictInformationResponse
         * @static
         * @param {district_service.IDistrictInformationResponse=} [properties] Properties to set
         * @returns {district_service.DistrictInformationResponse} DistrictInformationResponse instance
         */
        DistrictInformationResponse.create = function create(properties) {
            return new DistrictInformationResponse(properties);
        };

        /**
         * Encodes the specified DistrictInformationResponse message. Does not implicitly {@link district_service.DistrictInformationResponse.verify|verify} messages.
         * @function encode
         * @memberof district_service.DistrictInformationResponse
         * @static
         * @param {district_service.IDistrictInformationResponse} message DistrictInformationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DistrictInformationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.modifiedDistrictId != null && Object.hasOwnProperty.call(message, "modifiedDistrictId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.modifiedDistrictId);
            if (message.districts != null && message.districts.length)
                for (var i = 0; i < message.districts.length; ++i)
                    $root.pl_types.District.encode(message.districts[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DistrictInformationResponse message, length delimited. Does not implicitly {@link district_service.DistrictInformationResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof district_service.DistrictInformationResponse
         * @static
         * @param {district_service.IDistrictInformationResponse} message DistrictInformationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DistrictInformationResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DistrictInformationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof district_service.DistrictInformationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {district_service.DistrictInformationResponse} DistrictInformationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DistrictInformationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.district_service.DistrictInformationResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.modifiedDistrictId = reader.int32();
                        break;
                    }
                case 2: {
                        if (!(message.districts && message.districts.length))
                            message.districts = [];
                        message.districts.push($root.pl_types.District.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DistrictInformationResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof district_service.DistrictInformationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {district_service.DistrictInformationResponse} DistrictInformationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DistrictInformationResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DistrictInformationResponse message.
         * @function verify
         * @memberof district_service.DistrictInformationResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DistrictInformationResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.modifiedDistrictId != null && message.hasOwnProperty("modifiedDistrictId")) {
                properties._modifiedDistrictId = 1;
                if (!$util.isInteger(message.modifiedDistrictId))
                    return "modifiedDistrictId: integer expected";
            }
            if (message.districts != null && message.hasOwnProperty("districts")) {
                if (!Array.isArray(message.districts))
                    return "districts: array expected";
                for (var i = 0; i < message.districts.length; ++i) {
                    var error = $root.pl_types.District.verify(message.districts[i]);
                    if (error)
                        return "districts." + error;
                }
            }
            return null;
        };

        /**
         * Creates a DistrictInformationResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof district_service.DistrictInformationResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {district_service.DistrictInformationResponse} DistrictInformationResponse
         */
        DistrictInformationResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.district_service.DistrictInformationResponse)
                return object;
            var message = new $root.district_service.DistrictInformationResponse();
            if (object.modifiedDistrictId != null)
                message.modifiedDistrictId = object.modifiedDistrictId | 0;
            if (object.districts) {
                if (!Array.isArray(object.districts))
                    throw TypeError(".district_service.DistrictInformationResponse.districts: array expected");
                message.districts = [];
                for (var i = 0; i < object.districts.length; ++i) {
                    if (typeof object.districts[i] !== "object")
                        throw TypeError(".district_service.DistrictInformationResponse.districts: object expected");
                    message.districts[i] = $root.pl_types.District.fromObject(object.districts[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a DistrictInformationResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof district_service.DistrictInformationResponse
         * @static
         * @param {district_service.DistrictInformationResponse} message DistrictInformationResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DistrictInformationResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.districts = [];
            if (message.modifiedDistrictId != null && message.hasOwnProperty("modifiedDistrictId")) {
                object.modifiedDistrictId = message.modifiedDistrictId;
                if (options.oneofs)
                    object._modifiedDistrictId = "modifiedDistrictId";
            }
            if (message.districts && message.districts.length) {
                object.districts = [];
                for (var j = 0; j < message.districts.length; ++j)
                    object.districts[j] = $root.pl_types.District.toObject(message.districts[j], options);
            }
            return object;
        };

        /**
         * Converts this DistrictInformationResponse to JSON.
         * @function toJSON
         * @memberof district_service.DistrictInformationResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DistrictInformationResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DistrictInformationResponse
         * @function getTypeUrl
         * @memberof district_service.DistrictInformationResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DistrictInformationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/district_service.DistrictInformationResponse";
        };

        return DistrictInformationResponse;
    })();

    return district_service;
})();

$root.error_service = (function() {

    /**
     * Namespace error_service.
     * @exports error_service
     * @namespace
     */
    var error_service = {};

    error_service.ErrorService = (function() {

        /**
         * Constructs a new ErrorService service.
         * @memberof error_service
         * @classdesc Represents an ErrorService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ErrorService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ErrorService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ErrorService;

        /**
         * Creates new ErrorService service using the specified rpc implementation.
         * @function create
         * @memberof error_service.ErrorService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {ErrorService} RPC service. Useful where requests and/or responses are streamed.
         */
        ErrorService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link error_service.ErrorService#reportError}.
         * @memberof error_service.ErrorService
         * @typedef ReportErrorCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {error_service.ReportErrorResponse} [response] ReportErrorResponse
         */

        /**
         * Calls ReportError.
         * @function reportError
         * @memberof error_service.ErrorService
         * @instance
         * @param {error_service.IReportErrorRequest} request ReportErrorRequest message or plain object
         * @param {error_service.ErrorService.ReportErrorCallback} callback Node-style callback called with the error, if any, and ReportErrorResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ErrorService.prototype.reportError = function reportError(request, callback) {
            return this.rpcCall(reportError, $root.error_service.ReportErrorRequest, $root.error_service.ReportErrorResponse, request, callback);
        }, "name", { value: "ReportError" });

        /**
         * Calls ReportError.
         * @function reportError
         * @memberof error_service.ErrorService
         * @instance
         * @param {error_service.IReportErrorRequest} request ReportErrorRequest message or plain object
         * @returns {Promise<error_service.ReportErrorResponse>} Promise
         * @variation 2
         */

        return ErrorService;
    })();

    error_service.ReportErrorRequest = (function() {

        /**
         * Properties of a ReportErrorRequest.
         * @memberof error_service
         * @interface IReportErrorRequest
         * @property {string|null} [name] ReportErrorRequest name
         * @property {string|null} [error] ReportErrorRequest error
         * @property {string|null} [sourceUrl] ReportErrorRequest sourceUrl
         * @property {pl_types.IUserX|null} [userX] ReportErrorRequest userX
         * @property {string|null} [request] ReportErrorRequest request
         * @property {string|null} [requestUrl] ReportErrorRequest requestUrl
         * @property {Uint8Array|null} [requestBody] ReportErrorRequest requestBody
         * @property {string|null} [response] ReportErrorRequest response
         * @property {string|null} [responseUrl] ReportErrorRequest responseUrl
         * @property {Uint8Array|null} [responseBody] ReportErrorRequest responseBody
         */

        /**
         * Constructs a new ReportErrorRequest.
         * @memberof error_service
         * @classdesc Represents a ReportErrorRequest.
         * @implements IReportErrorRequest
         * @constructor
         * @param {error_service.IReportErrorRequest=} [properties] Properties to set
         */
        function ReportErrorRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReportErrorRequest name.
         * @member {string|null|undefined} name
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        ReportErrorRequest.prototype.name = null;

        /**
         * ReportErrorRequest error.
         * @member {string|null|undefined} error
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        ReportErrorRequest.prototype.error = null;

        /**
         * ReportErrorRequest sourceUrl.
         * @member {string|null|undefined} sourceUrl
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        ReportErrorRequest.prototype.sourceUrl = null;

        /**
         * ReportErrorRequest userX.
         * @member {pl_types.IUserX|null|undefined} userX
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        ReportErrorRequest.prototype.userX = null;

        /**
         * ReportErrorRequest request.
         * @member {string|null|undefined} request
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        ReportErrorRequest.prototype.request = null;

        /**
         * ReportErrorRequest requestUrl.
         * @member {string|null|undefined} requestUrl
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        ReportErrorRequest.prototype.requestUrl = null;

        /**
         * ReportErrorRequest requestBody.
         * @member {Uint8Array|null|undefined} requestBody
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        ReportErrorRequest.prototype.requestBody = null;

        /**
         * ReportErrorRequest response.
         * @member {string|null|undefined} response
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        ReportErrorRequest.prototype.response = null;

        /**
         * ReportErrorRequest responseUrl.
         * @member {string|null|undefined} responseUrl
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        ReportErrorRequest.prototype.responseUrl = null;

        /**
         * ReportErrorRequest responseBody.
         * @member {Uint8Array|null|undefined} responseBody
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        ReportErrorRequest.prototype.responseBody = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ReportErrorRequest _name.
         * @member {"name"|undefined} _name
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        Object.defineProperty(ReportErrorRequest.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReportErrorRequest _error.
         * @member {"error"|undefined} _error
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        Object.defineProperty(ReportErrorRequest.prototype, "_error", {
            get: $util.oneOfGetter($oneOfFields = ["error"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReportErrorRequest _sourceUrl.
         * @member {"sourceUrl"|undefined} _sourceUrl
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        Object.defineProperty(ReportErrorRequest.prototype, "_sourceUrl", {
            get: $util.oneOfGetter($oneOfFields = ["sourceUrl"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReportErrorRequest _userX.
         * @member {"userX"|undefined} _userX
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        Object.defineProperty(ReportErrorRequest.prototype, "_userX", {
            get: $util.oneOfGetter($oneOfFields = ["userX"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReportErrorRequest _request.
         * @member {"request"|undefined} _request
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        Object.defineProperty(ReportErrorRequest.prototype, "_request", {
            get: $util.oneOfGetter($oneOfFields = ["request"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReportErrorRequest _requestUrl.
         * @member {"requestUrl"|undefined} _requestUrl
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        Object.defineProperty(ReportErrorRequest.prototype, "_requestUrl", {
            get: $util.oneOfGetter($oneOfFields = ["requestUrl"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReportErrorRequest _requestBody.
         * @member {"requestBody"|undefined} _requestBody
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        Object.defineProperty(ReportErrorRequest.prototype, "_requestBody", {
            get: $util.oneOfGetter($oneOfFields = ["requestBody"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReportErrorRequest _response.
         * @member {"response"|undefined} _response
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        Object.defineProperty(ReportErrorRequest.prototype, "_response", {
            get: $util.oneOfGetter($oneOfFields = ["response"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReportErrorRequest _responseUrl.
         * @member {"responseUrl"|undefined} _responseUrl
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        Object.defineProperty(ReportErrorRequest.prototype, "_responseUrl", {
            get: $util.oneOfGetter($oneOfFields = ["responseUrl"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReportErrorRequest _responseBody.
         * @member {"responseBody"|undefined} _responseBody
         * @memberof error_service.ReportErrorRequest
         * @instance
         */
        Object.defineProperty(ReportErrorRequest.prototype, "_responseBody", {
            get: $util.oneOfGetter($oneOfFields = ["responseBody"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ReportErrorRequest instance using the specified properties.
         * @function create
         * @memberof error_service.ReportErrorRequest
         * @static
         * @param {error_service.IReportErrorRequest=} [properties] Properties to set
         * @returns {error_service.ReportErrorRequest} ReportErrorRequest instance
         */
        ReportErrorRequest.create = function create(properties) {
            return new ReportErrorRequest(properties);
        };

        /**
         * Encodes the specified ReportErrorRequest message. Does not implicitly {@link error_service.ReportErrorRequest.verify|verify} messages.
         * @function encode
         * @memberof error_service.ReportErrorRequest
         * @static
         * @param {error_service.IReportErrorRequest} message ReportErrorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReportErrorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.requestUrl != null && Object.hasOwnProperty.call(message, "requestUrl"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.requestUrl);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.error);
            if (message.userX != null && Object.hasOwnProperty.call(message, "userX"))
                $root.pl_types.UserX.encode(message.userX, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.request != null && Object.hasOwnProperty.call(message, "request"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.request);
            if (message.response != null && Object.hasOwnProperty.call(message, "response"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.response);
            if (message.responseUrl != null && Object.hasOwnProperty.call(message, "responseUrl"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.responseUrl);
            if (message.responseBody != null && Object.hasOwnProperty.call(message, "responseBody"))
                writer.uint32(/* id 12, wireType 2 =*/98).bytes(message.responseBody);
            if (message.sourceUrl != null && Object.hasOwnProperty.call(message, "sourceUrl"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.sourceUrl);
            if (message.requestBody != null && Object.hasOwnProperty.call(message, "requestBody"))
                writer.uint32(/* id 14, wireType 2 =*/114).bytes(message.requestBody);
            return writer;
        };

        /**
         * Encodes the specified ReportErrorRequest message, length delimited. Does not implicitly {@link error_service.ReportErrorRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof error_service.ReportErrorRequest
         * @static
         * @param {error_service.IReportErrorRequest} message ReportErrorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReportErrorRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReportErrorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof error_service.ReportErrorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {error_service.ReportErrorRequest} ReportErrorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReportErrorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.error_service.ReportErrorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 7: {
                        message.error = reader.string();
                        break;
                    }
                case 13: {
                        message.sourceUrl = reader.string();
                        break;
                    }
                case 8: {
                        message.userX = $root.pl_types.UserX.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.request = reader.string();
                        break;
                    }
                case 4: {
                        message.requestUrl = reader.string();
                        break;
                    }
                case 14: {
                        message.requestBody = reader.bytes();
                        break;
                    }
                case 10: {
                        message.response = reader.string();
                        break;
                    }
                case 11: {
                        message.responseUrl = reader.string();
                        break;
                    }
                case 12: {
                        message.responseBody = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReportErrorRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof error_service.ReportErrorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {error_service.ReportErrorRequest} ReportErrorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReportErrorRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReportErrorRequest message.
         * @function verify
         * @memberof error_service.ReportErrorRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReportErrorRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                properties._error = 1;
                if (!$util.isString(message.error))
                    return "error: string expected";
            }
            if (message.sourceUrl != null && message.hasOwnProperty("sourceUrl")) {
                properties._sourceUrl = 1;
                if (!$util.isString(message.sourceUrl))
                    return "sourceUrl: string expected";
            }
            if (message.userX != null && message.hasOwnProperty("userX")) {
                properties._userX = 1;
                {
                    var error = $root.pl_types.UserX.verify(message.userX);
                    if (error)
                        return "userX." + error;
                }
            }
            if (message.request != null && message.hasOwnProperty("request")) {
                properties._request = 1;
                if (!$util.isString(message.request))
                    return "request: string expected";
            }
            if (message.requestUrl != null && message.hasOwnProperty("requestUrl")) {
                properties._requestUrl = 1;
                if (!$util.isString(message.requestUrl))
                    return "requestUrl: string expected";
            }
            if (message.requestBody != null && message.hasOwnProperty("requestBody")) {
                properties._requestBody = 1;
                if (!(message.requestBody && typeof message.requestBody.length === "number" || $util.isString(message.requestBody)))
                    return "requestBody: buffer expected";
            }
            if (message.response != null && message.hasOwnProperty("response")) {
                properties._response = 1;
                if (!$util.isString(message.response))
                    return "response: string expected";
            }
            if (message.responseUrl != null && message.hasOwnProperty("responseUrl")) {
                properties._responseUrl = 1;
                if (!$util.isString(message.responseUrl))
                    return "responseUrl: string expected";
            }
            if (message.responseBody != null && message.hasOwnProperty("responseBody")) {
                properties._responseBody = 1;
                if (!(message.responseBody && typeof message.responseBody.length === "number" || $util.isString(message.responseBody)))
                    return "responseBody: buffer expected";
            }
            return null;
        };

        /**
         * Creates a ReportErrorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof error_service.ReportErrorRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {error_service.ReportErrorRequest} ReportErrorRequest
         */
        ReportErrorRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.error_service.ReportErrorRequest)
                return object;
            var message = new $root.error_service.ReportErrorRequest();
            if (object.name != null)
                message.name = String(object.name);
            if (object.error != null)
                message.error = String(object.error);
            if (object.sourceUrl != null)
                message.sourceUrl = String(object.sourceUrl);
            if (object.userX != null) {
                if (typeof object.userX !== "object")
                    throw TypeError(".error_service.ReportErrorRequest.userX: object expected");
                message.userX = $root.pl_types.UserX.fromObject(object.userX);
            }
            if (object.request != null)
                message.request = String(object.request);
            if (object.requestUrl != null)
                message.requestUrl = String(object.requestUrl);
            if (object.requestBody != null)
                if (typeof object.requestBody === "string")
                    $util.base64.decode(object.requestBody, message.requestBody = $util.newBuffer($util.base64.length(object.requestBody)), 0);
                else if (object.requestBody.length >= 0)
                    message.requestBody = object.requestBody;
            if (object.response != null)
                message.response = String(object.response);
            if (object.responseUrl != null)
                message.responseUrl = String(object.responseUrl);
            if (object.responseBody != null)
                if (typeof object.responseBody === "string")
                    $util.base64.decode(object.responseBody, message.responseBody = $util.newBuffer($util.base64.length(object.responseBody)), 0);
                else if (object.responseBody.length >= 0)
                    message.responseBody = object.responseBody;
            return message;
        };

        /**
         * Creates a plain object from a ReportErrorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof error_service.ReportErrorRequest
         * @static
         * @param {error_service.ReportErrorRequest} message ReportErrorRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReportErrorRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            if (message.requestUrl != null && message.hasOwnProperty("requestUrl")) {
                object.requestUrl = message.requestUrl;
                if (options.oneofs)
                    object._requestUrl = "requestUrl";
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                object.error = message.error;
                if (options.oneofs)
                    object._error = "error";
            }
            if (message.userX != null && message.hasOwnProperty("userX")) {
                object.userX = $root.pl_types.UserX.toObject(message.userX, options);
                if (options.oneofs)
                    object._userX = "userX";
            }
            if (message.request != null && message.hasOwnProperty("request")) {
                object.request = message.request;
                if (options.oneofs)
                    object._request = "request";
            }
            if (message.response != null && message.hasOwnProperty("response")) {
                object.response = message.response;
                if (options.oneofs)
                    object._response = "response";
            }
            if (message.responseUrl != null && message.hasOwnProperty("responseUrl")) {
                object.responseUrl = message.responseUrl;
                if (options.oneofs)
                    object._responseUrl = "responseUrl";
            }
            if (message.responseBody != null && message.hasOwnProperty("responseBody")) {
                object.responseBody = options.bytes === String ? $util.base64.encode(message.responseBody, 0, message.responseBody.length) : options.bytes === Array ? Array.prototype.slice.call(message.responseBody) : message.responseBody;
                if (options.oneofs)
                    object._responseBody = "responseBody";
            }
            if (message.sourceUrl != null && message.hasOwnProperty("sourceUrl")) {
                object.sourceUrl = message.sourceUrl;
                if (options.oneofs)
                    object._sourceUrl = "sourceUrl";
            }
            if (message.requestBody != null && message.hasOwnProperty("requestBody")) {
                object.requestBody = options.bytes === String ? $util.base64.encode(message.requestBody, 0, message.requestBody.length) : options.bytes === Array ? Array.prototype.slice.call(message.requestBody) : message.requestBody;
                if (options.oneofs)
                    object._requestBody = "requestBody";
            }
            return object;
        };

        /**
         * Converts this ReportErrorRequest to JSON.
         * @function toJSON
         * @memberof error_service.ReportErrorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReportErrorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReportErrorRequest
         * @function getTypeUrl
         * @memberof error_service.ReportErrorRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReportErrorRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/error_service.ReportErrorRequest";
        };

        return ReportErrorRequest;
    })();

    error_service.ReportErrorResponse = (function() {

        /**
         * Properties of a ReportErrorResponse.
         * @memberof error_service
         * @interface IReportErrorResponse
         * @property {string|null} [failureReason] ReportErrorResponse failureReason
         * @property {string|null} [issueLink] ReportErrorResponse issueLink
         */

        /**
         * Constructs a new ReportErrorResponse.
         * @memberof error_service
         * @classdesc Represents a ReportErrorResponse.
         * @implements IReportErrorResponse
         * @constructor
         * @param {error_service.IReportErrorResponse=} [properties] Properties to set
         */
        function ReportErrorResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReportErrorResponse failureReason.
         * @member {string|null|undefined} failureReason
         * @memberof error_service.ReportErrorResponse
         * @instance
         */
        ReportErrorResponse.prototype.failureReason = null;

        /**
         * ReportErrorResponse issueLink.
         * @member {string|null|undefined} issueLink
         * @memberof error_service.ReportErrorResponse
         * @instance
         */
        ReportErrorResponse.prototype.issueLink = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ReportErrorResponse _failureReason.
         * @member {"failureReason"|undefined} _failureReason
         * @memberof error_service.ReportErrorResponse
         * @instance
         */
        Object.defineProperty(ReportErrorResponse.prototype, "_failureReason", {
            get: $util.oneOfGetter($oneOfFields = ["failureReason"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReportErrorResponse _issueLink.
         * @member {"issueLink"|undefined} _issueLink
         * @memberof error_service.ReportErrorResponse
         * @instance
         */
        Object.defineProperty(ReportErrorResponse.prototype, "_issueLink", {
            get: $util.oneOfGetter($oneOfFields = ["issueLink"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ReportErrorResponse instance using the specified properties.
         * @function create
         * @memberof error_service.ReportErrorResponse
         * @static
         * @param {error_service.IReportErrorResponse=} [properties] Properties to set
         * @returns {error_service.ReportErrorResponse} ReportErrorResponse instance
         */
        ReportErrorResponse.create = function create(properties) {
            return new ReportErrorResponse(properties);
        };

        /**
         * Encodes the specified ReportErrorResponse message. Does not implicitly {@link error_service.ReportErrorResponse.verify|verify} messages.
         * @function encode
         * @memberof error_service.ReportErrorResponse
         * @static
         * @param {error_service.IReportErrorResponse} message ReportErrorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReportErrorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.failureReason != null && Object.hasOwnProperty.call(message, "failureReason"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.failureReason);
            if (message.issueLink != null && Object.hasOwnProperty.call(message, "issueLink"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.issueLink);
            return writer;
        };

        /**
         * Encodes the specified ReportErrorResponse message, length delimited. Does not implicitly {@link error_service.ReportErrorResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof error_service.ReportErrorResponse
         * @static
         * @param {error_service.IReportErrorResponse} message ReportErrorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReportErrorResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReportErrorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof error_service.ReportErrorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {error_service.ReportErrorResponse} ReportErrorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReportErrorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.error_service.ReportErrorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.failureReason = reader.string();
                        break;
                    }
                case 2: {
                        message.issueLink = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReportErrorResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof error_service.ReportErrorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {error_service.ReportErrorResponse} ReportErrorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReportErrorResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReportErrorResponse message.
         * @function verify
         * @memberof error_service.ReportErrorResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReportErrorResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.failureReason != null && message.hasOwnProperty("failureReason")) {
                properties._failureReason = 1;
                if (!$util.isString(message.failureReason))
                    return "failureReason: string expected";
            }
            if (message.issueLink != null && message.hasOwnProperty("issueLink")) {
                properties._issueLink = 1;
                if (!$util.isString(message.issueLink))
                    return "issueLink: string expected";
            }
            return null;
        };

        /**
         * Creates a ReportErrorResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof error_service.ReportErrorResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {error_service.ReportErrorResponse} ReportErrorResponse
         */
        ReportErrorResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.error_service.ReportErrorResponse)
                return object;
            var message = new $root.error_service.ReportErrorResponse();
            if (object.failureReason != null)
                message.failureReason = String(object.failureReason);
            if (object.issueLink != null)
                message.issueLink = String(object.issueLink);
            return message;
        };

        /**
         * Creates a plain object from a ReportErrorResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof error_service.ReportErrorResponse
         * @static
         * @param {error_service.ReportErrorResponse} message ReportErrorResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReportErrorResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.failureReason != null && message.hasOwnProperty("failureReason")) {
                object.failureReason = message.failureReason;
                if (options.oneofs)
                    object._failureReason = "failureReason";
            }
            if (message.issueLink != null && message.hasOwnProperty("issueLink")) {
                object.issueLink = message.issueLink;
                if (options.oneofs)
                    object._issueLink = "issueLink";
            }
            return object;
        };

        /**
         * Converts this ReportErrorResponse to JSON.
         * @function toJSON
         * @memberof error_service.ReportErrorResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReportErrorResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReportErrorResponse
         * @function getTypeUrl
         * @memberof error_service.ReportErrorResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReportErrorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/error_service.ReportErrorResponse";
        };

        return ReportErrorResponse;
    })();

    return error_service;
})();

$root.jira = (function() {

    /**
     * Namespace jira.
     * @exports jira
     * @namespace
     */
    var jira = {};

    jira.ErrorResponse = (function() {

        /**
         * Properties of an ErrorResponse.
         * @memberof jira
         * @interface IErrorResponse
         * @property {Array.<string>|null} [errorMessages] ErrorResponse errorMessages
         * @property {Object.<string,string>|null} [errors] ErrorResponse errors
         * @property {number|null} [status] ErrorResponse status
         */

        /**
         * Constructs a new ErrorResponse.
         * @memberof jira
         * @classdesc Represents an ErrorResponse.
         * @implements IErrorResponse
         * @constructor
         * @param {jira.IErrorResponse=} [properties] Properties to set
         */
        function ErrorResponse(properties) {
            this.errorMessages = [];
            this.errors = {};
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ErrorResponse errorMessages.
         * @member {Array.<string>} errorMessages
         * @memberof jira.ErrorResponse
         * @instance
         */
        ErrorResponse.prototype.errorMessages = $util.emptyArray;

        /**
         * ErrorResponse errors.
         * @member {Object.<string,string>} errors
         * @memberof jira.ErrorResponse
         * @instance
         */
        ErrorResponse.prototype.errors = $util.emptyObject;

        /**
         * ErrorResponse status.
         * @member {number|null|undefined} status
         * @memberof jira.ErrorResponse
         * @instance
         */
        ErrorResponse.prototype.status = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ErrorResponse _status.
         * @member {"status"|undefined} _status
         * @memberof jira.ErrorResponse
         * @instance
         */
        Object.defineProperty(ErrorResponse.prototype, "_status", {
            get: $util.oneOfGetter($oneOfFields = ["status"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ErrorResponse instance using the specified properties.
         * @function create
         * @memberof jira.ErrorResponse
         * @static
         * @param {jira.IErrorResponse=} [properties] Properties to set
         * @returns {jira.ErrorResponse} ErrorResponse instance
         */
        ErrorResponse.create = function create(properties) {
            return new ErrorResponse(properties);
        };

        /**
         * Encodes the specified ErrorResponse message. Does not implicitly {@link jira.ErrorResponse.verify|verify} messages.
         * @function encode
         * @memberof jira.ErrorResponse
         * @static
         * @param {jira.IErrorResponse} message ErrorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ErrorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.errorMessages != null && message.errorMessages.length)
                for (var i = 0; i < message.errorMessages.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.errorMessages[i]);
            if (message.errors != null && Object.hasOwnProperty.call(message, "errors"))
                for (var keys = Object.keys(message.errors), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.errors[keys[i]]).ldelim();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified ErrorResponse message, length delimited. Does not implicitly {@link jira.ErrorResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.ErrorResponse
         * @static
         * @param {jira.IErrorResponse} message ErrorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ErrorResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ErrorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof jira.ErrorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.ErrorResponse} ErrorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ErrorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.ErrorResponse(), key, value;
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.errorMessages && message.errorMessages.length))
                            message.errorMessages = [];
                        message.errorMessages.push(reader.string());
                        break;
                    }
                case 2: {
                        if (message.errors === $util.emptyObject)
                            message.errors = {};
                        var end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = "";
                        while (reader.pos < end2) {
                            var tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.string();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.errors[key] = value;
                        break;
                    }
                case 3: {
                        message.status = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ErrorResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.ErrorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.ErrorResponse} ErrorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ErrorResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ErrorResponse message.
         * @function verify
         * @memberof jira.ErrorResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ErrorResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.errorMessages != null && message.hasOwnProperty("errorMessages")) {
                if (!Array.isArray(message.errorMessages))
                    return "errorMessages: array expected";
                for (var i = 0; i < message.errorMessages.length; ++i)
                    if (!$util.isString(message.errorMessages[i]))
                        return "errorMessages: string[] expected";
            }
            if (message.errors != null && message.hasOwnProperty("errors")) {
                if (!$util.isObject(message.errors))
                    return "errors: object expected";
                var key = Object.keys(message.errors);
                for (var i = 0; i < key.length; ++i)
                    if (!$util.isString(message.errors[key[i]]))
                        return "errors: string{k:string} expected";
            }
            if (message.status != null && message.hasOwnProperty("status")) {
                properties._status = 1;
                if (!$util.isInteger(message.status))
                    return "status: integer expected";
            }
            return null;
        };

        /**
         * Creates an ErrorResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.ErrorResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.ErrorResponse} ErrorResponse
         */
        ErrorResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.ErrorResponse)
                return object;
            var message = new $root.jira.ErrorResponse();
            if (object.errorMessages) {
                if (!Array.isArray(object.errorMessages))
                    throw TypeError(".jira.ErrorResponse.errorMessages: array expected");
                message.errorMessages = [];
                for (var i = 0; i < object.errorMessages.length; ++i)
                    message.errorMessages[i] = String(object.errorMessages[i]);
            }
            if (object.errors) {
                if (typeof object.errors !== "object")
                    throw TypeError(".jira.ErrorResponse.errors: object expected");
                message.errors = {};
                for (var keys = Object.keys(object.errors), i = 0; i < keys.length; ++i)
                    message.errors[keys[i]] = String(object.errors[keys[i]]);
            }
            if (object.status != null)
                message.status = object.status | 0;
            return message;
        };

        /**
         * Creates a plain object from an ErrorResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.ErrorResponse
         * @static
         * @param {jira.ErrorResponse} message ErrorResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ErrorResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.errorMessages = [];
            if (options.objects || options.defaults)
                object.errors = {};
            if (message.errorMessages && message.errorMessages.length) {
                object.errorMessages = [];
                for (var j = 0; j < message.errorMessages.length; ++j)
                    object.errorMessages[j] = message.errorMessages[j];
            }
            var keys2;
            if (message.errors && (keys2 = Object.keys(message.errors)).length) {
                object.errors = {};
                for (var j = 0; j < keys2.length; ++j)
                    object.errors[keys2[j]] = message.errors[keys2[j]];
            }
            if (message.status != null && message.hasOwnProperty("status")) {
                object.status = message.status;
                if (options.oneofs)
                    object._status = "status";
            }
            return object;
        };

        /**
         * Converts this ErrorResponse to JSON.
         * @function toJSON
         * @memberof jira.ErrorResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ErrorResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ErrorResponse
         * @function getTypeUrl
         * @memberof jira.ErrorResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ErrorResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.ErrorResponse";
        };

        return ErrorResponse;
    })();

    jira.LoginInfo = (function() {

        /**
         * Properties of a LoginInfo.
         * @memberof jira
         * @interface ILoginInfo
         * @property {number|null} [failedLoginCount] LoginInfo failedLoginCount
         * @property {number|null} [loginCount] LoginInfo loginCount
         * @property {string|null} [lastFailedLoginTime] LoginInfo lastFailedLoginTime
         * @property {string|null} [previousLoginTime] LoginInfo previousLoginTime
         */

        /**
         * Constructs a new LoginInfo.
         * @memberof jira
         * @classdesc Represents a LoginInfo.
         * @implements ILoginInfo
         * @constructor
         * @param {jira.ILoginInfo=} [properties] Properties to set
         */
        function LoginInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginInfo failedLoginCount.
         * @member {number|null|undefined} failedLoginCount
         * @memberof jira.LoginInfo
         * @instance
         */
        LoginInfo.prototype.failedLoginCount = null;

        /**
         * LoginInfo loginCount.
         * @member {number|null|undefined} loginCount
         * @memberof jira.LoginInfo
         * @instance
         */
        LoginInfo.prototype.loginCount = null;

        /**
         * LoginInfo lastFailedLoginTime.
         * @member {string|null|undefined} lastFailedLoginTime
         * @memberof jira.LoginInfo
         * @instance
         */
        LoginInfo.prototype.lastFailedLoginTime = null;

        /**
         * LoginInfo previousLoginTime.
         * @member {string|null|undefined} previousLoginTime
         * @memberof jira.LoginInfo
         * @instance
         */
        LoginInfo.prototype.previousLoginTime = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * LoginInfo _failedLoginCount.
         * @member {"failedLoginCount"|undefined} _failedLoginCount
         * @memberof jira.LoginInfo
         * @instance
         */
        Object.defineProperty(LoginInfo.prototype, "_failedLoginCount", {
            get: $util.oneOfGetter($oneOfFields = ["failedLoginCount"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * LoginInfo _loginCount.
         * @member {"loginCount"|undefined} _loginCount
         * @memberof jira.LoginInfo
         * @instance
         */
        Object.defineProperty(LoginInfo.prototype, "_loginCount", {
            get: $util.oneOfGetter($oneOfFields = ["loginCount"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * LoginInfo _lastFailedLoginTime.
         * @member {"lastFailedLoginTime"|undefined} _lastFailedLoginTime
         * @memberof jira.LoginInfo
         * @instance
         */
        Object.defineProperty(LoginInfo.prototype, "_lastFailedLoginTime", {
            get: $util.oneOfGetter($oneOfFields = ["lastFailedLoginTime"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * LoginInfo _previousLoginTime.
         * @member {"previousLoginTime"|undefined} _previousLoginTime
         * @memberof jira.LoginInfo
         * @instance
         */
        Object.defineProperty(LoginInfo.prototype, "_previousLoginTime", {
            get: $util.oneOfGetter($oneOfFields = ["previousLoginTime"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new LoginInfo instance using the specified properties.
         * @function create
         * @memberof jira.LoginInfo
         * @static
         * @param {jira.ILoginInfo=} [properties] Properties to set
         * @returns {jira.LoginInfo} LoginInfo instance
         */
        LoginInfo.create = function create(properties) {
            return new LoginInfo(properties);
        };

        /**
         * Encodes the specified LoginInfo message. Does not implicitly {@link jira.LoginInfo.verify|verify} messages.
         * @function encode
         * @memberof jira.LoginInfo
         * @static
         * @param {jira.ILoginInfo} message LoginInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.failedLoginCount != null && Object.hasOwnProperty.call(message, "failedLoginCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.failedLoginCount);
            if (message.loginCount != null && Object.hasOwnProperty.call(message, "loginCount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.loginCount);
            if (message.lastFailedLoginTime != null && Object.hasOwnProperty.call(message, "lastFailedLoginTime"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastFailedLoginTime);
            if (message.previousLoginTime != null && Object.hasOwnProperty.call(message, "previousLoginTime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.previousLoginTime);
            return writer;
        };

        /**
         * Encodes the specified LoginInfo message, length delimited. Does not implicitly {@link jira.LoginInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.LoginInfo
         * @static
         * @param {jira.ILoginInfo} message LoginInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginInfo message from the specified reader or buffer.
         * @function decode
         * @memberof jira.LoginInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.LoginInfo} LoginInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.LoginInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.failedLoginCount = reader.int32();
                        break;
                    }
                case 2: {
                        message.loginCount = reader.int32();
                        break;
                    }
                case 3: {
                        message.lastFailedLoginTime = reader.string();
                        break;
                    }
                case 4: {
                        message.previousLoginTime = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.LoginInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.LoginInfo} LoginInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginInfo message.
         * @function verify
         * @memberof jira.LoginInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.failedLoginCount != null && message.hasOwnProperty("failedLoginCount")) {
                properties._failedLoginCount = 1;
                if (!$util.isInteger(message.failedLoginCount))
                    return "failedLoginCount: integer expected";
            }
            if (message.loginCount != null && message.hasOwnProperty("loginCount")) {
                properties._loginCount = 1;
                if (!$util.isInteger(message.loginCount))
                    return "loginCount: integer expected";
            }
            if (message.lastFailedLoginTime != null && message.hasOwnProperty("lastFailedLoginTime")) {
                properties._lastFailedLoginTime = 1;
                if (!$util.isString(message.lastFailedLoginTime))
                    return "lastFailedLoginTime: string expected";
            }
            if (message.previousLoginTime != null && message.hasOwnProperty("previousLoginTime")) {
                properties._previousLoginTime = 1;
                if (!$util.isString(message.previousLoginTime))
                    return "previousLoginTime: string expected";
            }
            return null;
        };

        /**
         * Creates a LoginInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.LoginInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.LoginInfo} LoginInfo
         */
        LoginInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.LoginInfo)
                return object;
            var message = new $root.jira.LoginInfo();
            if (object.failedLoginCount != null)
                message.failedLoginCount = object.failedLoginCount | 0;
            if (object.loginCount != null)
                message.loginCount = object.loginCount | 0;
            if (object.lastFailedLoginTime != null)
                message.lastFailedLoginTime = String(object.lastFailedLoginTime);
            if (object.previousLoginTime != null)
                message.previousLoginTime = String(object.previousLoginTime);
            return message;
        };

        /**
         * Creates a plain object from a LoginInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.LoginInfo
         * @static
         * @param {jira.LoginInfo} message LoginInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.failedLoginCount != null && message.hasOwnProperty("failedLoginCount")) {
                object.failedLoginCount = message.failedLoginCount;
                if (options.oneofs)
                    object._failedLoginCount = "failedLoginCount";
            }
            if (message.loginCount != null && message.hasOwnProperty("loginCount")) {
                object.loginCount = message.loginCount;
                if (options.oneofs)
                    object._loginCount = "loginCount";
            }
            if (message.lastFailedLoginTime != null && message.hasOwnProperty("lastFailedLoginTime")) {
                object.lastFailedLoginTime = message.lastFailedLoginTime;
                if (options.oneofs)
                    object._lastFailedLoginTime = "lastFailedLoginTime";
            }
            if (message.previousLoginTime != null && message.hasOwnProperty("previousLoginTime")) {
                object.previousLoginTime = message.previousLoginTime;
                if (options.oneofs)
                    object._previousLoginTime = "previousLoginTime";
            }
            return object;
        };

        /**
         * Converts this LoginInfo to JSON.
         * @function toJSON
         * @memberof jira.LoginInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoginInfo
         * @function getTypeUrl
         * @memberof jira.LoginInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoginInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.LoginInfo";
        };

        return LoginInfo;
    })();

    jira.CurrentUserResponse = (function() {

        /**
         * Properties of a CurrentUserResponse.
         * @memberof jira
         * @interface ICurrentUserResponse
         * @property {string|null} [self] CurrentUserResponse self
         * @property {string|null} [name] CurrentUserResponse name
         * @property {jira.ILoginInfo|null} [loginInfo] CurrentUserResponse loginInfo
         */

        /**
         * Constructs a new CurrentUserResponse.
         * @memberof jira
         * @classdesc Represents a CurrentUserResponse.
         * @implements ICurrentUserResponse
         * @constructor
         * @param {jira.ICurrentUserResponse=} [properties] Properties to set
         */
        function CurrentUserResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CurrentUserResponse self.
         * @member {string|null|undefined} self
         * @memberof jira.CurrentUserResponse
         * @instance
         */
        CurrentUserResponse.prototype.self = null;

        /**
         * CurrentUserResponse name.
         * @member {string|null|undefined} name
         * @memberof jira.CurrentUserResponse
         * @instance
         */
        CurrentUserResponse.prototype.name = null;

        /**
         * CurrentUserResponse loginInfo.
         * @member {jira.ILoginInfo|null|undefined} loginInfo
         * @memberof jira.CurrentUserResponse
         * @instance
         */
        CurrentUserResponse.prototype.loginInfo = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * CurrentUserResponse _self.
         * @member {"self"|undefined} _self
         * @memberof jira.CurrentUserResponse
         * @instance
         */
        Object.defineProperty(CurrentUserResponse.prototype, "_self", {
            get: $util.oneOfGetter($oneOfFields = ["self"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CurrentUserResponse _name.
         * @member {"name"|undefined} _name
         * @memberof jira.CurrentUserResponse
         * @instance
         */
        Object.defineProperty(CurrentUserResponse.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CurrentUserResponse _loginInfo.
         * @member {"loginInfo"|undefined} _loginInfo
         * @memberof jira.CurrentUserResponse
         * @instance
         */
        Object.defineProperty(CurrentUserResponse.prototype, "_loginInfo", {
            get: $util.oneOfGetter($oneOfFields = ["loginInfo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CurrentUserResponse instance using the specified properties.
         * @function create
         * @memberof jira.CurrentUserResponse
         * @static
         * @param {jira.ICurrentUserResponse=} [properties] Properties to set
         * @returns {jira.CurrentUserResponse} CurrentUserResponse instance
         */
        CurrentUserResponse.create = function create(properties) {
            return new CurrentUserResponse(properties);
        };

        /**
         * Encodes the specified CurrentUserResponse message. Does not implicitly {@link jira.CurrentUserResponse.verify|verify} messages.
         * @function encode
         * @memberof jira.CurrentUserResponse
         * @static
         * @param {jira.ICurrentUserResponse} message CurrentUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CurrentUserResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.self != null && Object.hasOwnProperty.call(message, "self"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.self);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.loginInfo != null && Object.hasOwnProperty.call(message, "loginInfo"))
                $root.jira.LoginInfo.encode(message.loginInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CurrentUserResponse message, length delimited. Does not implicitly {@link jira.CurrentUserResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.CurrentUserResponse
         * @static
         * @param {jira.ICurrentUserResponse} message CurrentUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CurrentUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CurrentUserResponse message from the specified reader or buffer.
         * @function decode
         * @memberof jira.CurrentUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.CurrentUserResponse} CurrentUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CurrentUserResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.CurrentUserResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.self = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.loginInfo = $root.jira.LoginInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CurrentUserResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.CurrentUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.CurrentUserResponse} CurrentUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CurrentUserResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CurrentUserResponse message.
         * @function verify
         * @memberof jira.CurrentUserResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CurrentUserResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.self != null && message.hasOwnProperty("self")) {
                properties._self = 1;
                if (!$util.isString(message.self))
                    return "self: string expected";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            if (message.loginInfo != null && message.hasOwnProperty("loginInfo")) {
                properties._loginInfo = 1;
                {
                    var error = $root.jira.LoginInfo.verify(message.loginInfo);
                    if (error)
                        return "loginInfo." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CurrentUserResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.CurrentUserResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.CurrentUserResponse} CurrentUserResponse
         */
        CurrentUserResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.CurrentUserResponse)
                return object;
            var message = new $root.jira.CurrentUserResponse();
            if (object.self != null)
                message.self = String(object.self);
            if (object.name != null)
                message.name = String(object.name);
            if (object.loginInfo != null) {
                if (typeof object.loginInfo !== "object")
                    throw TypeError(".jira.CurrentUserResponse.loginInfo: object expected");
                message.loginInfo = $root.jira.LoginInfo.fromObject(object.loginInfo);
            }
            return message;
        };

        /**
         * Creates a plain object from a CurrentUserResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.CurrentUserResponse
         * @static
         * @param {jira.CurrentUserResponse} message CurrentUserResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CurrentUserResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.self != null && message.hasOwnProperty("self")) {
                object.self = message.self;
                if (options.oneofs)
                    object._self = "self";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            if (message.loginInfo != null && message.hasOwnProperty("loginInfo")) {
                object.loginInfo = $root.jira.LoginInfo.toObject(message.loginInfo, options);
                if (options.oneofs)
                    object._loginInfo = "loginInfo";
            }
            return object;
        };

        /**
         * Converts this CurrentUserResponse to JSON.
         * @function toJSON
         * @memberof jira.CurrentUserResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CurrentUserResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CurrentUserResponse
         * @function getTypeUrl
         * @memberof jira.CurrentUserResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CurrentUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.CurrentUserResponse";
        };

        return CurrentUserResponse;
    })();

    jira.LoginRequest = (function() {

        /**
         * Properties of a LoginRequest.
         * @memberof jira
         * @interface ILoginRequest
         * @property {string|null} [username] LoginRequest username
         * @property {string|null} [password] LoginRequest password
         */

        /**
         * Constructs a new LoginRequest.
         * @memberof jira
         * @classdesc Represents a LoginRequest.
         * @implements ILoginRequest
         * @constructor
         * @param {jira.ILoginRequest=} [properties] Properties to set
         */
        function LoginRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginRequest username.
         * @member {string|null|undefined} username
         * @memberof jira.LoginRequest
         * @instance
         */
        LoginRequest.prototype.username = null;

        /**
         * LoginRequest password.
         * @member {string|null|undefined} password
         * @memberof jira.LoginRequest
         * @instance
         */
        LoginRequest.prototype.password = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * LoginRequest _username.
         * @member {"username"|undefined} _username
         * @memberof jira.LoginRequest
         * @instance
         */
        Object.defineProperty(LoginRequest.prototype, "_username", {
            get: $util.oneOfGetter($oneOfFields = ["username"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * LoginRequest _password.
         * @member {"password"|undefined} _password
         * @memberof jira.LoginRequest
         * @instance
         */
        Object.defineProperty(LoginRequest.prototype, "_password", {
            get: $util.oneOfGetter($oneOfFields = ["password"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new LoginRequest instance using the specified properties.
         * @function create
         * @memberof jira.LoginRequest
         * @static
         * @param {jira.ILoginRequest=} [properties] Properties to set
         * @returns {jira.LoginRequest} LoginRequest instance
         */
        LoginRequest.create = function create(properties) {
            return new LoginRequest(properties);
        };

        /**
         * Encodes the specified LoginRequest message. Does not implicitly {@link jira.LoginRequest.verify|verify} messages.
         * @function encode
         * @memberof jira.LoginRequest
         * @static
         * @param {jira.ILoginRequest} message LoginRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.username);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
            return writer;
        };

        /**
         * Encodes the specified LoginRequest message, length delimited. Does not implicitly {@link jira.LoginRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.LoginRequest
         * @static
         * @param {jira.ILoginRequest} message LoginRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginRequest message from the specified reader or buffer.
         * @function decode
         * @memberof jira.LoginRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.LoginRequest} LoginRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.LoginRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.username = reader.string();
                        break;
                    }
                case 2: {
                        message.password = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.LoginRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.LoginRequest} LoginRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginRequest message.
         * @function verify
         * @memberof jira.LoginRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.username != null && message.hasOwnProperty("username")) {
                properties._username = 1;
                if (!$util.isString(message.username))
                    return "username: string expected";
            }
            if (message.password != null && message.hasOwnProperty("password")) {
                properties._password = 1;
                if (!$util.isString(message.password))
                    return "password: string expected";
            }
            return null;
        };

        /**
         * Creates a LoginRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.LoginRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.LoginRequest} LoginRequest
         */
        LoginRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.LoginRequest)
                return object;
            var message = new $root.jira.LoginRequest();
            if (object.username != null)
                message.username = String(object.username);
            if (object.password != null)
                message.password = String(object.password);
            return message;
        };

        /**
         * Creates a plain object from a LoginRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.LoginRequest
         * @static
         * @param {jira.LoginRequest} message LoginRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.username != null && message.hasOwnProperty("username")) {
                object.username = message.username;
                if (options.oneofs)
                    object._username = "username";
            }
            if (message.password != null && message.hasOwnProperty("password")) {
                object.password = message.password;
                if (options.oneofs)
                    object._password = "password";
            }
            return object;
        };

        /**
         * Converts this LoginRequest to JSON.
         * @function toJSON
         * @memberof jira.LoginRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoginRequest
         * @function getTypeUrl
         * @memberof jira.LoginRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoginRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.LoginRequest";
        };

        return LoginRequest;
    })();

    jira.LoginResponse = (function() {

        /**
         * Properties of a LoginResponse.
         * @memberof jira
         * @interface ILoginResponse
         * @property {jira.LoginResponse.ISession|null} [session] LoginResponse session
         * @property {jira.ILoginInfo|null} [loginInfo] LoginResponse loginInfo
         */

        /**
         * Constructs a new LoginResponse.
         * @memberof jira
         * @classdesc Represents a LoginResponse.
         * @implements ILoginResponse
         * @constructor
         * @param {jira.ILoginResponse=} [properties] Properties to set
         */
        function LoginResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginResponse session.
         * @member {jira.LoginResponse.ISession|null|undefined} session
         * @memberof jira.LoginResponse
         * @instance
         */
        LoginResponse.prototype.session = null;

        /**
         * LoginResponse loginInfo.
         * @member {jira.ILoginInfo|null|undefined} loginInfo
         * @memberof jira.LoginResponse
         * @instance
         */
        LoginResponse.prototype.loginInfo = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * LoginResponse _session.
         * @member {"session"|undefined} _session
         * @memberof jira.LoginResponse
         * @instance
         */
        Object.defineProperty(LoginResponse.prototype, "_session", {
            get: $util.oneOfGetter($oneOfFields = ["session"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * LoginResponse _loginInfo.
         * @member {"loginInfo"|undefined} _loginInfo
         * @memberof jira.LoginResponse
         * @instance
         */
        Object.defineProperty(LoginResponse.prototype, "_loginInfo", {
            get: $util.oneOfGetter($oneOfFields = ["loginInfo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new LoginResponse instance using the specified properties.
         * @function create
         * @memberof jira.LoginResponse
         * @static
         * @param {jira.ILoginResponse=} [properties] Properties to set
         * @returns {jira.LoginResponse} LoginResponse instance
         */
        LoginResponse.create = function create(properties) {
            return new LoginResponse(properties);
        };

        /**
         * Encodes the specified LoginResponse message. Does not implicitly {@link jira.LoginResponse.verify|verify} messages.
         * @function encode
         * @memberof jira.LoginResponse
         * @static
         * @param {jira.ILoginResponse} message LoginResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.session != null && Object.hasOwnProperty.call(message, "session"))
                $root.jira.LoginResponse.Session.encode(message.session, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.loginInfo != null && Object.hasOwnProperty.call(message, "loginInfo"))
                $root.jira.LoginInfo.encode(message.loginInfo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LoginResponse message, length delimited. Does not implicitly {@link jira.LoginResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.LoginResponse
         * @static
         * @param {jira.ILoginResponse} message LoginResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginResponse message from the specified reader or buffer.
         * @function decode
         * @memberof jira.LoginResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.LoginResponse} LoginResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.LoginResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.session = $root.jira.LoginResponse.Session.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.loginInfo = $root.jira.LoginInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.LoginResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.LoginResponse} LoginResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginResponse message.
         * @function verify
         * @memberof jira.LoginResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.session != null && message.hasOwnProperty("session")) {
                properties._session = 1;
                {
                    var error = $root.jira.LoginResponse.Session.verify(message.session);
                    if (error)
                        return "session." + error;
                }
            }
            if (message.loginInfo != null && message.hasOwnProperty("loginInfo")) {
                properties._loginInfo = 1;
                {
                    var error = $root.jira.LoginInfo.verify(message.loginInfo);
                    if (error)
                        return "loginInfo." + error;
                }
            }
            return null;
        };

        /**
         * Creates a LoginResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.LoginResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.LoginResponse} LoginResponse
         */
        LoginResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.LoginResponse)
                return object;
            var message = new $root.jira.LoginResponse();
            if (object.session != null) {
                if (typeof object.session !== "object")
                    throw TypeError(".jira.LoginResponse.session: object expected");
                message.session = $root.jira.LoginResponse.Session.fromObject(object.session);
            }
            if (object.loginInfo != null) {
                if (typeof object.loginInfo !== "object")
                    throw TypeError(".jira.LoginResponse.loginInfo: object expected");
                message.loginInfo = $root.jira.LoginInfo.fromObject(object.loginInfo);
            }
            return message;
        };

        /**
         * Creates a plain object from a LoginResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.LoginResponse
         * @static
         * @param {jira.LoginResponse} message LoginResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.session != null && message.hasOwnProperty("session")) {
                object.session = $root.jira.LoginResponse.Session.toObject(message.session, options);
                if (options.oneofs)
                    object._session = "session";
            }
            if (message.loginInfo != null && message.hasOwnProperty("loginInfo")) {
                object.loginInfo = $root.jira.LoginInfo.toObject(message.loginInfo, options);
                if (options.oneofs)
                    object._loginInfo = "loginInfo";
            }
            return object;
        };

        /**
         * Converts this LoginResponse to JSON.
         * @function toJSON
         * @memberof jira.LoginResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoginResponse
         * @function getTypeUrl
         * @memberof jira.LoginResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoginResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.LoginResponse";
        };

        LoginResponse.Session = (function() {

            /**
             * Properties of a Session.
             * @memberof jira.LoginResponse
             * @interface ISession
             * @property {string|null} [name] Session name
             * @property {string|null} [value] Session value
             */

            /**
             * Constructs a new Session.
             * @memberof jira.LoginResponse
             * @classdesc Represents a Session.
             * @implements ISession
             * @constructor
             * @param {jira.LoginResponse.ISession=} [properties] Properties to set
             */
            function Session(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Session name.
             * @member {string|null|undefined} name
             * @memberof jira.LoginResponse.Session
             * @instance
             */
            Session.prototype.name = null;

            /**
             * Session value.
             * @member {string|null|undefined} value
             * @memberof jira.LoginResponse.Session
             * @instance
             */
            Session.prototype.value = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * Session _name.
             * @member {"name"|undefined} _name
             * @memberof jira.LoginResponse.Session
             * @instance
             */
            Object.defineProperty(Session.prototype, "_name", {
                get: $util.oneOfGetter($oneOfFields = ["name"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Session _value.
             * @member {"value"|undefined} _value
             * @memberof jira.LoginResponse.Session
             * @instance
             */
            Object.defineProperty(Session.prototype, "_value", {
                get: $util.oneOfGetter($oneOfFields = ["value"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new Session instance using the specified properties.
             * @function create
             * @memberof jira.LoginResponse.Session
             * @static
             * @param {jira.LoginResponse.ISession=} [properties] Properties to set
             * @returns {jira.LoginResponse.Session} Session instance
             */
            Session.create = function create(properties) {
                return new Session(properties);
            };

            /**
             * Encodes the specified Session message. Does not implicitly {@link jira.LoginResponse.Session.verify|verify} messages.
             * @function encode
             * @memberof jira.LoginResponse.Session
             * @static
             * @param {jira.LoginResponse.ISession} message Session message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Session.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
                return writer;
            };

            /**
             * Encodes the specified Session message, length delimited. Does not implicitly {@link jira.LoginResponse.Session.verify|verify} messages.
             * @function encodeDelimited
             * @memberof jira.LoginResponse.Session
             * @static
             * @param {jira.LoginResponse.ISession} message Session message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Session.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Session message from the specified reader or buffer.
             * @function decode
             * @memberof jira.LoginResponse.Session
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {jira.LoginResponse.Session} Session
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Session.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.LoginResponse.Session();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.value = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Session message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof jira.LoginResponse.Session
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {jira.LoginResponse.Session} Session
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Session.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Session message.
             * @function verify
             * @memberof jira.LoginResponse.Session
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Session.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.name != null && message.hasOwnProperty("name")) {
                    properties._name = 1;
                    if (!$util.isString(message.name))
                        return "name: string expected";
                }
                if (message.value != null && message.hasOwnProperty("value")) {
                    properties._value = 1;
                    if (!$util.isString(message.value))
                        return "value: string expected";
                }
                return null;
            };

            /**
             * Creates a Session message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof jira.LoginResponse.Session
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {jira.LoginResponse.Session} Session
             */
            Session.fromObject = function fromObject(object) {
                if (object instanceof $root.jira.LoginResponse.Session)
                    return object;
                var message = new $root.jira.LoginResponse.Session();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.value != null)
                    message.value = String(object.value);
                return message;
            };

            /**
             * Creates a plain object from a Session message. Also converts values to other types if specified.
             * @function toObject
             * @memberof jira.LoginResponse.Session
             * @static
             * @param {jira.LoginResponse.Session} message Session
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Session.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.name != null && message.hasOwnProperty("name")) {
                    object.name = message.name;
                    if (options.oneofs)
                        object._name = "name";
                }
                if (message.value != null && message.hasOwnProperty("value")) {
                    object.value = message.value;
                    if (options.oneofs)
                        object._value = "value";
                }
                return object;
            };

            /**
             * Converts this Session to JSON.
             * @function toJSON
             * @memberof jira.LoginResponse.Session
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Session.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Session
             * @function getTypeUrl
             * @memberof jira.LoginResponse.Session
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Session.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/jira.LoginResponse.Session";
            };

            return Session;
        })();

        return LoginResponse;
    })();

    jira.Parent = (function() {

        /**
         * Properties of a Parent.
         * @memberof jira
         * @interface IParent
         * @property {string|null} [key] Parent key
         */

        /**
         * Constructs a new Parent.
         * @memberof jira
         * @classdesc Represents a Parent.
         * @implements IParent
         * @constructor
         * @param {jira.IParent=} [properties] Properties to set
         */
        function Parent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Parent key.
         * @member {string|null|undefined} key
         * @memberof jira.Parent
         * @instance
         */
        Parent.prototype.key = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Parent _key.
         * @member {"key"|undefined} _key
         * @memberof jira.Parent
         * @instance
         */
        Object.defineProperty(Parent.prototype, "_key", {
            get: $util.oneOfGetter($oneOfFields = ["key"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Parent instance using the specified properties.
         * @function create
         * @memberof jira.Parent
         * @static
         * @param {jira.IParent=} [properties] Properties to set
         * @returns {jira.Parent} Parent instance
         */
        Parent.create = function create(properties) {
            return new Parent(properties);
        };

        /**
         * Encodes the specified Parent message. Does not implicitly {@link jira.Parent.verify|verify} messages.
         * @function encode
         * @memberof jira.Parent
         * @static
         * @param {jira.IParent} message Parent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Parent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            return writer;
        };

        /**
         * Encodes the specified Parent message, length delimited. Does not implicitly {@link jira.Parent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.Parent
         * @static
         * @param {jira.IParent} message Parent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Parent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Parent message from the specified reader or buffer.
         * @function decode
         * @memberof jira.Parent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.Parent} Parent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Parent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.Parent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Parent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.Parent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.Parent} Parent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Parent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Parent message.
         * @function verify
         * @memberof jira.Parent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Parent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.key != null && message.hasOwnProperty("key")) {
                properties._key = 1;
                if (!$util.isString(message.key))
                    return "key: string expected";
            }
            return null;
        };

        /**
         * Creates a Parent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.Parent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.Parent} Parent
         */
        Parent.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.Parent)
                return object;
            var message = new $root.jira.Parent();
            if (object.key != null)
                message.key = String(object.key);
            return message;
        };

        /**
         * Creates a plain object from a Parent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.Parent
         * @static
         * @param {jira.Parent} message Parent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Parent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.key != null && message.hasOwnProperty("key")) {
                object.key = message.key;
                if (options.oneofs)
                    object._key = "key";
            }
            return object;
        };

        /**
         * Converts this Parent to JSON.
         * @function toJSON
         * @memberof jira.Parent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Parent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Parent
         * @function getTypeUrl
         * @memberof jira.Parent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Parent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.Parent";
        };

        return Parent;
    })();

    jira.Worklog = (function() {

        /**
         * Properties of a Worklog.
         * @memberof jira
         * @interface IWorklog
         * @property {jira.Worklog.IAdd|null} [add] Worklog add
         */

        /**
         * Constructs a new Worklog.
         * @memberof jira
         * @classdesc Represents a Worklog.
         * @implements IWorklog
         * @constructor
         * @param {jira.IWorklog=} [properties] Properties to set
         */
        function Worklog(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Worklog add.
         * @member {jira.Worklog.IAdd|null|undefined} add
         * @memberof jira.Worklog
         * @instance
         */
        Worklog.prototype.add = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Worklog _add.
         * @member {"add"|undefined} _add
         * @memberof jira.Worklog
         * @instance
         */
        Object.defineProperty(Worklog.prototype, "_add", {
            get: $util.oneOfGetter($oneOfFields = ["add"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Worklog instance using the specified properties.
         * @function create
         * @memberof jira.Worklog
         * @static
         * @param {jira.IWorklog=} [properties] Properties to set
         * @returns {jira.Worklog} Worklog instance
         */
        Worklog.create = function create(properties) {
            return new Worklog(properties);
        };

        /**
         * Encodes the specified Worklog message. Does not implicitly {@link jira.Worklog.verify|verify} messages.
         * @function encode
         * @memberof jira.Worklog
         * @static
         * @param {jira.IWorklog} message Worklog message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Worklog.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.add != null && Object.hasOwnProperty.call(message, "add"))
                $root.jira.Worklog.Add.encode(message.add, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Worklog message, length delimited. Does not implicitly {@link jira.Worklog.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.Worklog
         * @static
         * @param {jira.IWorklog} message Worklog message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Worklog.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Worklog message from the specified reader or buffer.
         * @function decode
         * @memberof jira.Worklog
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.Worklog} Worklog
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Worklog.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.Worklog();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.add = $root.jira.Worklog.Add.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Worklog message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.Worklog
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.Worklog} Worklog
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Worklog.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Worklog message.
         * @function verify
         * @memberof jira.Worklog
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Worklog.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.add != null && message.hasOwnProperty("add")) {
                properties._add = 1;
                {
                    var error = $root.jira.Worklog.Add.verify(message.add);
                    if (error)
                        return "add." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Worklog message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.Worklog
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.Worklog} Worklog
         */
        Worklog.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.Worklog)
                return object;
            var message = new $root.jira.Worklog();
            if (object.add != null) {
                if (typeof object.add !== "object")
                    throw TypeError(".jira.Worklog.add: object expected");
                message.add = $root.jira.Worklog.Add.fromObject(object.add);
            }
            return message;
        };

        /**
         * Creates a plain object from a Worklog message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.Worklog
         * @static
         * @param {jira.Worklog} message Worklog
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Worklog.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.add != null && message.hasOwnProperty("add")) {
                object.add = $root.jira.Worklog.Add.toObject(message.add, options);
                if (options.oneofs)
                    object._add = "add";
            }
            return object;
        };

        /**
         * Converts this Worklog to JSON.
         * @function toJSON
         * @memberof jira.Worklog
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Worklog.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Worklog
         * @function getTypeUrl
         * @memberof jira.Worklog
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Worklog.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.Worklog";
        };

        Worklog.Add = (function() {

            /**
             * Properties of an Add.
             * @memberof jira.Worklog
             * @interface IAdd
             * @property {string|null} [timeSpent] Add timeSpent
             * @property {string|null} [started] Add started
             */

            /**
             * Constructs a new Add.
             * @memberof jira.Worklog
             * @classdesc Represents an Add.
             * @implements IAdd
             * @constructor
             * @param {jira.Worklog.IAdd=} [properties] Properties to set
             */
            function Add(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Add timeSpent.
             * @member {string|null|undefined} timeSpent
             * @memberof jira.Worklog.Add
             * @instance
             */
            Add.prototype.timeSpent = null;

            /**
             * Add started.
             * @member {string|null|undefined} started
             * @memberof jira.Worklog.Add
             * @instance
             */
            Add.prototype.started = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * Add _timeSpent.
             * @member {"timeSpent"|undefined} _timeSpent
             * @memberof jira.Worklog.Add
             * @instance
             */
            Object.defineProperty(Add.prototype, "_timeSpent", {
                get: $util.oneOfGetter($oneOfFields = ["timeSpent"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Add _started.
             * @member {"started"|undefined} _started
             * @memberof jira.Worklog.Add
             * @instance
             */
            Object.defineProperty(Add.prototype, "_started", {
                get: $util.oneOfGetter($oneOfFields = ["started"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new Add instance using the specified properties.
             * @function create
             * @memberof jira.Worklog.Add
             * @static
             * @param {jira.Worklog.IAdd=} [properties] Properties to set
             * @returns {jira.Worklog.Add} Add instance
             */
            Add.create = function create(properties) {
                return new Add(properties);
            };

            /**
             * Encodes the specified Add message. Does not implicitly {@link jira.Worklog.Add.verify|verify} messages.
             * @function encode
             * @memberof jira.Worklog.Add
             * @static
             * @param {jira.Worklog.IAdd} message Add message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Add.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.timeSpent != null && Object.hasOwnProperty.call(message, "timeSpent"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.timeSpent);
                if (message.started != null && Object.hasOwnProperty.call(message, "started"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.started);
                return writer;
            };

            /**
             * Encodes the specified Add message, length delimited. Does not implicitly {@link jira.Worklog.Add.verify|verify} messages.
             * @function encodeDelimited
             * @memberof jira.Worklog.Add
             * @static
             * @param {jira.Worklog.IAdd} message Add message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Add.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Add message from the specified reader or buffer.
             * @function decode
             * @memberof jira.Worklog.Add
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {jira.Worklog.Add} Add
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Add.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.Worklog.Add();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.timeSpent = reader.string();
                            break;
                        }
                    case 2: {
                            message.started = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Add message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof jira.Worklog.Add
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {jira.Worklog.Add} Add
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Add.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Add message.
             * @function verify
             * @memberof jira.Worklog.Add
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Add.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.timeSpent != null && message.hasOwnProperty("timeSpent")) {
                    properties._timeSpent = 1;
                    if (!$util.isString(message.timeSpent))
                        return "timeSpent: string expected";
                }
                if (message.started != null && message.hasOwnProperty("started")) {
                    properties._started = 1;
                    if (!$util.isString(message.started))
                        return "started: string expected";
                }
                return null;
            };

            /**
             * Creates an Add message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof jira.Worklog.Add
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {jira.Worklog.Add} Add
             */
            Add.fromObject = function fromObject(object) {
                if (object instanceof $root.jira.Worklog.Add)
                    return object;
                var message = new $root.jira.Worklog.Add();
                if (object.timeSpent != null)
                    message.timeSpent = String(object.timeSpent);
                if (object.started != null)
                    message.started = String(object.started);
                return message;
            };

            /**
             * Creates a plain object from an Add message. Also converts values to other types if specified.
             * @function toObject
             * @memberof jira.Worklog.Add
             * @static
             * @param {jira.Worklog.Add} message Add
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Add.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.timeSpent != null && message.hasOwnProperty("timeSpent")) {
                    object.timeSpent = message.timeSpent;
                    if (options.oneofs)
                        object._timeSpent = "timeSpent";
                }
                if (message.started != null && message.hasOwnProperty("started")) {
                    object.started = message.started;
                    if (options.oneofs)
                        object._started = "started";
                }
                return object;
            };

            /**
             * Converts this Add to JSON.
             * @function toJSON
             * @memberof jira.Worklog.Add
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Add.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Add
             * @function getTypeUrl
             * @memberof jira.Worklog.Add
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Add.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/jira.Worklog.Add";
            };

            return Add;
        })();

        return Worklog;
    })();

    jira.Update = (function() {

        /**
         * Properties of an Update.
         * @memberof jira
         * @interface IUpdate
         * @property {Array.<jira.IWorklog>|null} [worklog] Update worklog
         */

        /**
         * Constructs a new Update.
         * @memberof jira
         * @classdesc Represents an Update.
         * @implements IUpdate
         * @constructor
         * @param {jira.IUpdate=} [properties] Properties to set
         */
        function Update(properties) {
            this.worklog = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Update worklog.
         * @member {Array.<jira.IWorklog>} worklog
         * @memberof jira.Update
         * @instance
         */
        Update.prototype.worklog = $util.emptyArray;

        /**
         * Creates a new Update instance using the specified properties.
         * @function create
         * @memberof jira.Update
         * @static
         * @param {jira.IUpdate=} [properties] Properties to set
         * @returns {jira.Update} Update instance
         */
        Update.create = function create(properties) {
            return new Update(properties);
        };

        /**
         * Encodes the specified Update message. Does not implicitly {@link jira.Update.verify|verify} messages.
         * @function encode
         * @memberof jira.Update
         * @static
         * @param {jira.IUpdate} message Update message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Update.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.worklog != null && message.worklog.length)
                for (var i = 0; i < message.worklog.length; ++i)
                    $root.jira.Worklog.encode(message.worklog[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Update message, length delimited. Does not implicitly {@link jira.Update.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.Update
         * @static
         * @param {jira.IUpdate} message Update message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Update.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Update message from the specified reader or buffer.
         * @function decode
         * @memberof jira.Update
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.Update} Update
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Update.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.Update();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.worklog && message.worklog.length))
                            message.worklog = [];
                        message.worklog.push($root.jira.Worklog.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Update message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.Update
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.Update} Update
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Update.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Update message.
         * @function verify
         * @memberof jira.Update
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Update.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.worklog != null && message.hasOwnProperty("worklog")) {
                if (!Array.isArray(message.worklog))
                    return "worklog: array expected";
                for (var i = 0; i < message.worklog.length; ++i) {
                    var error = $root.jira.Worklog.verify(message.worklog[i]);
                    if (error)
                        return "worklog." + error;
                }
            }
            return null;
        };

        /**
         * Creates an Update message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.Update
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.Update} Update
         */
        Update.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.Update)
                return object;
            var message = new $root.jira.Update();
            if (object.worklog) {
                if (!Array.isArray(object.worklog))
                    throw TypeError(".jira.Update.worklog: array expected");
                message.worklog = [];
                for (var i = 0; i < object.worklog.length; ++i) {
                    if (typeof object.worklog[i] !== "object")
                        throw TypeError(".jira.Update.worklog: object expected");
                    message.worklog[i] = $root.jira.Worklog.fromObject(object.worklog[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an Update message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.Update
         * @static
         * @param {jira.Update} message Update
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Update.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.worklog = [];
            if (message.worklog && message.worklog.length) {
                object.worklog = [];
                for (var j = 0; j < message.worklog.length; ++j)
                    object.worklog[j] = $root.jira.Worklog.toObject(message.worklog[j], options);
            }
            return object;
        };

        /**
         * Converts this Update to JSON.
         * @function toJSON
         * @memberof jira.Update
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Update.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Update
         * @function getTypeUrl
         * @memberof jira.Update
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Update.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.Update";
        };

        return Update;
    })();

    jira.Project = (function() {

        /**
         * Properties of a Project.
         * @memberof jira
         * @interface IProject
         * @property {string|null} [id] Project id
         * @property {string|null} [key] Project key
         */

        /**
         * Constructs a new Project.
         * @memberof jira
         * @classdesc Represents a Project.
         * @implements IProject
         * @constructor
         * @param {jira.IProject=} [properties] Properties to set
         */
        function Project(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Project id.
         * @member {string|null|undefined} id
         * @memberof jira.Project
         * @instance
         */
        Project.prototype.id = null;

        /**
         * Project key.
         * @member {string|null|undefined} key
         * @memberof jira.Project
         * @instance
         */
        Project.prototype.key = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Project _id.
         * @member {"id"|undefined} _id
         * @memberof jira.Project
         * @instance
         */
        Object.defineProperty(Project.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Project _key.
         * @member {"key"|undefined} _key
         * @memberof jira.Project
         * @instance
         */
        Object.defineProperty(Project.prototype, "_key", {
            get: $util.oneOfGetter($oneOfFields = ["key"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Project instance using the specified properties.
         * @function create
         * @memberof jira.Project
         * @static
         * @param {jira.IProject=} [properties] Properties to set
         * @returns {jira.Project} Project instance
         */
        Project.create = function create(properties) {
            return new Project(properties);
        };

        /**
         * Encodes the specified Project message. Does not implicitly {@link jira.Project.verify|verify} messages.
         * @function encode
         * @memberof jira.Project
         * @static
         * @param {jira.IProject} message Project message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Project.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
            return writer;
        };

        /**
         * Encodes the specified Project message, length delimited. Does not implicitly {@link jira.Project.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.Project
         * @static
         * @param {jira.IProject} message Project message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Project.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Project message from the specified reader or buffer.
         * @function decode
         * @memberof jira.Project
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.Project} Project
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Project.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.Project();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.key = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Project message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.Project
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.Project} Project
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Project.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Project message.
         * @function verify
         * @memberof jira.Project
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Project.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isString(message.id))
                    return "id: string expected";
            }
            if (message.key != null && message.hasOwnProperty("key")) {
                properties._key = 1;
                if (!$util.isString(message.key))
                    return "key: string expected";
            }
            return null;
        };

        /**
         * Creates a Project message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.Project
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.Project} Project
         */
        Project.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.Project)
                return object;
            var message = new $root.jira.Project();
            if (object.id != null)
                message.id = String(object.id);
            if (object.key != null)
                message.key = String(object.key);
            return message;
        };

        /**
         * Creates a plain object from a Project message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.Project
         * @static
         * @param {jira.Project} message Project
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Project.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.key != null && message.hasOwnProperty("key")) {
                object.key = message.key;
                if (options.oneofs)
                    object._key = "key";
            }
            return object;
        };

        /**
         * Converts this Project to JSON.
         * @function toJSON
         * @memberof jira.Project
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Project.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Project
         * @function getTypeUrl
         * @memberof jira.Project
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Project.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.Project";
        };

        return Project;
    })();

    jira.IssueType = (function() {

        /**
         * Properties of an IssueType.
         * @memberof jira
         * @interface IIssueType
         * @property {string|null} [id] IssueType id
         * @property {string|null} [name] IssueType name
         */

        /**
         * Constructs a new IssueType.
         * @memberof jira
         * @classdesc Represents an IssueType.
         * @implements IIssueType
         * @constructor
         * @param {jira.IIssueType=} [properties] Properties to set
         */
        function IssueType(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IssueType id.
         * @member {string|null|undefined} id
         * @memberof jira.IssueType
         * @instance
         */
        IssueType.prototype.id = null;

        /**
         * IssueType name.
         * @member {string|null|undefined} name
         * @memberof jira.IssueType
         * @instance
         */
        IssueType.prototype.name = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * IssueType _id.
         * @member {"id"|undefined} _id
         * @memberof jira.IssueType
         * @instance
         */
        Object.defineProperty(IssueType.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * IssueType _name.
         * @member {"name"|undefined} _name
         * @memberof jira.IssueType
         * @instance
         */
        Object.defineProperty(IssueType.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new IssueType instance using the specified properties.
         * @function create
         * @memberof jira.IssueType
         * @static
         * @param {jira.IIssueType=} [properties] Properties to set
         * @returns {jira.IssueType} IssueType instance
         */
        IssueType.create = function create(properties) {
            return new IssueType(properties);
        };

        /**
         * Encodes the specified IssueType message. Does not implicitly {@link jira.IssueType.verify|verify} messages.
         * @function encode
         * @memberof jira.IssueType
         * @static
         * @param {jira.IIssueType} message IssueType message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IssueType.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified IssueType message, length delimited. Does not implicitly {@link jira.IssueType.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.IssueType
         * @static
         * @param {jira.IIssueType} message IssueType message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IssueType.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an IssueType message from the specified reader or buffer.
         * @function decode
         * @memberof jira.IssueType
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.IssueType} IssueType
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IssueType.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.IssueType();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an IssueType message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.IssueType
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.IssueType} IssueType
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IssueType.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an IssueType message.
         * @function verify
         * @memberof jira.IssueType
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        IssueType.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isString(message.id))
                    return "id: string expected";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            return null;
        };

        /**
         * Creates an IssueType message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.IssueType
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.IssueType} IssueType
         */
        IssueType.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.IssueType)
                return object;
            var message = new $root.jira.IssueType();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from an IssueType message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.IssueType
         * @static
         * @param {jira.IssueType} message IssueType
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IssueType.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            return object;
        };

        /**
         * Converts this IssueType to JSON.
         * @function toJSON
         * @memberof jira.IssueType
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IssueType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for IssueType
         * @function getTypeUrl
         * @memberof jira.IssueType
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        IssueType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.IssueType";
        };

        return IssueType;
    })();

    jira.Assignee = (function() {

        /**
         * Properties of an Assignee.
         * @memberof jira
         * @interface IAssignee
         * @property {string|null} [id] Assignee id
         */

        /**
         * Constructs a new Assignee.
         * @memberof jira
         * @classdesc Represents an Assignee.
         * @implements IAssignee
         * @constructor
         * @param {jira.IAssignee=} [properties] Properties to set
         */
        function Assignee(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Assignee id.
         * @member {string|null|undefined} id
         * @memberof jira.Assignee
         * @instance
         */
        Assignee.prototype.id = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Assignee _id.
         * @member {"id"|undefined} _id
         * @memberof jira.Assignee
         * @instance
         */
        Object.defineProperty(Assignee.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Assignee instance using the specified properties.
         * @function create
         * @memberof jira.Assignee
         * @static
         * @param {jira.IAssignee=} [properties] Properties to set
         * @returns {jira.Assignee} Assignee instance
         */
        Assignee.create = function create(properties) {
            return new Assignee(properties);
        };

        /**
         * Encodes the specified Assignee message. Does not implicitly {@link jira.Assignee.verify|verify} messages.
         * @function encode
         * @memberof jira.Assignee
         * @static
         * @param {jira.IAssignee} message Assignee message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Assignee.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified Assignee message, length delimited. Does not implicitly {@link jira.Assignee.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.Assignee
         * @static
         * @param {jira.IAssignee} message Assignee message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Assignee.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Assignee message from the specified reader or buffer.
         * @function decode
         * @memberof jira.Assignee
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.Assignee} Assignee
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Assignee.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.Assignee();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Assignee message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.Assignee
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.Assignee} Assignee
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Assignee.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Assignee message.
         * @function verify
         * @memberof jira.Assignee
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Assignee.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isString(message.id))
                    return "id: string expected";
            }
            return null;
        };

        /**
         * Creates an Assignee message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.Assignee
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.Assignee} Assignee
         */
        Assignee.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.Assignee)
                return object;
            var message = new $root.jira.Assignee();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from an Assignee message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.Assignee
         * @static
         * @param {jira.Assignee} message Assignee
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Assignee.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            return object;
        };

        /**
         * Converts this Assignee to JSON.
         * @function toJSON
         * @memberof jira.Assignee
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Assignee.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Assignee
         * @function getTypeUrl
         * @memberof jira.Assignee
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Assignee.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.Assignee";
        };

        return Assignee;
    })();

    jira.Reporter = (function() {

        /**
         * Properties of a Reporter.
         * @memberof jira
         * @interface IReporter
         * @property {string|null} [id] Reporter id
         */

        /**
         * Constructs a new Reporter.
         * @memberof jira
         * @classdesc Represents a Reporter.
         * @implements IReporter
         * @constructor
         * @param {jira.IReporter=} [properties] Properties to set
         */
        function Reporter(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Reporter id.
         * @member {string|null|undefined} id
         * @memberof jira.Reporter
         * @instance
         */
        Reporter.prototype.id = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Reporter _id.
         * @member {"id"|undefined} _id
         * @memberof jira.Reporter
         * @instance
         */
        Object.defineProperty(Reporter.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Reporter instance using the specified properties.
         * @function create
         * @memberof jira.Reporter
         * @static
         * @param {jira.IReporter=} [properties] Properties to set
         * @returns {jira.Reporter} Reporter instance
         */
        Reporter.create = function create(properties) {
            return new Reporter(properties);
        };

        /**
         * Encodes the specified Reporter message. Does not implicitly {@link jira.Reporter.verify|verify} messages.
         * @function encode
         * @memberof jira.Reporter
         * @static
         * @param {jira.IReporter} message Reporter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Reporter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified Reporter message, length delimited. Does not implicitly {@link jira.Reporter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.Reporter
         * @static
         * @param {jira.IReporter} message Reporter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Reporter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Reporter message from the specified reader or buffer.
         * @function decode
         * @memberof jira.Reporter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.Reporter} Reporter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Reporter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.Reporter();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Reporter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.Reporter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.Reporter} Reporter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Reporter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Reporter message.
         * @function verify
         * @memberof jira.Reporter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Reporter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isString(message.id))
                    return "id: string expected";
            }
            return null;
        };

        /**
         * Creates a Reporter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.Reporter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.Reporter} Reporter
         */
        Reporter.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.Reporter)
                return object;
            var message = new $root.jira.Reporter();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a Reporter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.Reporter
         * @static
         * @param {jira.Reporter} message Reporter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Reporter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            return object;
        };

        /**
         * Converts this Reporter to JSON.
         * @function toJSON
         * @memberof jira.Reporter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Reporter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Reporter
         * @function getTypeUrl
         * @memberof jira.Reporter
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Reporter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.Reporter";
        };

        return Reporter;
    })();

    jira.Priority = (function() {

        /**
         * Properties of a Priority.
         * @memberof jira
         * @interface IPriority
         * @property {string|null} [id] Priority id
         */

        /**
         * Constructs a new Priority.
         * @memberof jira
         * @classdesc Represents a Priority.
         * @implements IPriority
         * @constructor
         * @param {jira.IPriority=} [properties] Properties to set
         */
        function Priority(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Priority id.
         * @member {string|null|undefined} id
         * @memberof jira.Priority
         * @instance
         */
        Priority.prototype.id = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Priority _id.
         * @member {"id"|undefined} _id
         * @memberof jira.Priority
         * @instance
         */
        Object.defineProperty(Priority.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Priority instance using the specified properties.
         * @function create
         * @memberof jira.Priority
         * @static
         * @param {jira.IPriority=} [properties] Properties to set
         * @returns {jira.Priority} Priority instance
         */
        Priority.create = function create(properties) {
            return new Priority(properties);
        };

        /**
         * Encodes the specified Priority message. Does not implicitly {@link jira.Priority.verify|verify} messages.
         * @function encode
         * @memberof jira.Priority
         * @static
         * @param {jira.IPriority} message Priority message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Priority.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified Priority message, length delimited. Does not implicitly {@link jira.Priority.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.Priority
         * @static
         * @param {jira.IPriority} message Priority message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Priority.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Priority message from the specified reader or buffer.
         * @function decode
         * @memberof jira.Priority
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.Priority} Priority
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Priority.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.Priority();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Priority message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.Priority
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.Priority} Priority
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Priority.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Priority message.
         * @function verify
         * @memberof jira.Priority
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Priority.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isString(message.id))
                    return "id: string expected";
            }
            return null;
        };

        /**
         * Creates a Priority message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.Priority
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.Priority} Priority
         */
        Priority.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.Priority)
                return object;
            var message = new $root.jira.Priority();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a Priority message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.Priority
         * @static
         * @param {jira.Priority} message Priority
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Priority.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            return object;
        };

        /**
         * Converts this Priority to JSON.
         * @function toJSON
         * @memberof jira.Priority
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Priority.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Priority
         * @function getTypeUrl
         * @memberof jira.Priority
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Priority.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.Priority";
        };

        return Priority;
    })();

    jira.TimeTracking = (function() {

        /**
         * Properties of a TimeTracking.
         * @memberof jira
         * @interface ITimeTracking
         * @property {string|null} [originalEstimate] TimeTracking originalEstimate
         * @property {string|null} [remainingEstimate] TimeTracking remainingEstimate
         */

        /**
         * Constructs a new TimeTracking.
         * @memberof jira
         * @classdesc Represents a TimeTracking.
         * @implements ITimeTracking
         * @constructor
         * @param {jira.ITimeTracking=} [properties] Properties to set
         */
        function TimeTracking(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TimeTracking originalEstimate.
         * @member {string|null|undefined} originalEstimate
         * @memberof jira.TimeTracking
         * @instance
         */
        TimeTracking.prototype.originalEstimate = null;

        /**
         * TimeTracking remainingEstimate.
         * @member {string|null|undefined} remainingEstimate
         * @memberof jira.TimeTracking
         * @instance
         */
        TimeTracking.prototype.remainingEstimate = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * TimeTracking _originalEstimate.
         * @member {"originalEstimate"|undefined} _originalEstimate
         * @memberof jira.TimeTracking
         * @instance
         */
        Object.defineProperty(TimeTracking.prototype, "_originalEstimate", {
            get: $util.oneOfGetter($oneOfFields = ["originalEstimate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * TimeTracking _remainingEstimate.
         * @member {"remainingEstimate"|undefined} _remainingEstimate
         * @memberof jira.TimeTracking
         * @instance
         */
        Object.defineProperty(TimeTracking.prototype, "_remainingEstimate", {
            get: $util.oneOfGetter($oneOfFields = ["remainingEstimate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new TimeTracking instance using the specified properties.
         * @function create
         * @memberof jira.TimeTracking
         * @static
         * @param {jira.ITimeTracking=} [properties] Properties to set
         * @returns {jira.TimeTracking} TimeTracking instance
         */
        TimeTracking.create = function create(properties) {
            return new TimeTracking(properties);
        };

        /**
         * Encodes the specified TimeTracking message. Does not implicitly {@link jira.TimeTracking.verify|verify} messages.
         * @function encode
         * @memberof jira.TimeTracking
         * @static
         * @param {jira.ITimeTracking} message TimeTracking message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeTracking.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.originalEstimate != null && Object.hasOwnProperty.call(message, "originalEstimate"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.originalEstimate);
            if (message.remainingEstimate != null && Object.hasOwnProperty.call(message, "remainingEstimate"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.remainingEstimate);
            return writer;
        };

        /**
         * Encodes the specified TimeTracking message, length delimited. Does not implicitly {@link jira.TimeTracking.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.TimeTracking
         * @static
         * @param {jira.ITimeTracking} message TimeTracking message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeTracking.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TimeTracking message from the specified reader or buffer.
         * @function decode
         * @memberof jira.TimeTracking
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.TimeTracking} TimeTracking
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeTracking.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.TimeTracking();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.originalEstimate = reader.string();
                        break;
                    }
                case 2: {
                        message.remainingEstimate = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TimeTracking message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.TimeTracking
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.TimeTracking} TimeTracking
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeTracking.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TimeTracking message.
         * @function verify
         * @memberof jira.TimeTracking
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TimeTracking.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.originalEstimate != null && message.hasOwnProperty("originalEstimate")) {
                properties._originalEstimate = 1;
                if (!$util.isString(message.originalEstimate))
                    return "originalEstimate: string expected";
            }
            if (message.remainingEstimate != null && message.hasOwnProperty("remainingEstimate")) {
                properties._remainingEstimate = 1;
                if (!$util.isString(message.remainingEstimate))
                    return "remainingEstimate: string expected";
            }
            return null;
        };

        /**
         * Creates a TimeTracking message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.TimeTracking
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.TimeTracking} TimeTracking
         */
        TimeTracking.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.TimeTracking)
                return object;
            var message = new $root.jira.TimeTracking();
            if (object.originalEstimate != null)
                message.originalEstimate = String(object.originalEstimate);
            if (object.remainingEstimate != null)
                message.remainingEstimate = String(object.remainingEstimate);
            return message;
        };

        /**
         * Creates a plain object from a TimeTracking message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.TimeTracking
         * @static
         * @param {jira.TimeTracking} message TimeTracking
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TimeTracking.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.originalEstimate != null && message.hasOwnProperty("originalEstimate")) {
                object.originalEstimate = message.originalEstimate;
                if (options.oneofs)
                    object._originalEstimate = "originalEstimate";
            }
            if (message.remainingEstimate != null && message.hasOwnProperty("remainingEstimate")) {
                object.remainingEstimate = message.remainingEstimate;
                if (options.oneofs)
                    object._remainingEstimate = "remainingEstimate";
            }
            return object;
        };

        /**
         * Converts this TimeTracking to JSON.
         * @function toJSON
         * @memberof jira.TimeTracking
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TimeTracking.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TimeTracking
         * @function getTypeUrl
         * @memberof jira.TimeTracking
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TimeTracking.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.TimeTracking";
        };

        return TimeTracking;
    })();

    jira.Security = (function() {

        /**
         * Properties of a Security.
         * @memberof jira
         * @interface ISecurity
         * @property {string|null} [id] Security id
         */

        /**
         * Constructs a new Security.
         * @memberof jira
         * @classdesc Represents a Security.
         * @implements ISecurity
         * @constructor
         * @param {jira.ISecurity=} [properties] Properties to set
         */
        function Security(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Security id.
         * @member {string|null|undefined} id
         * @memberof jira.Security
         * @instance
         */
        Security.prototype.id = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Security _id.
         * @member {"id"|undefined} _id
         * @memberof jira.Security
         * @instance
         */
        Object.defineProperty(Security.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Security instance using the specified properties.
         * @function create
         * @memberof jira.Security
         * @static
         * @param {jira.ISecurity=} [properties] Properties to set
         * @returns {jira.Security} Security instance
         */
        Security.create = function create(properties) {
            return new Security(properties);
        };

        /**
         * Encodes the specified Security message. Does not implicitly {@link jira.Security.verify|verify} messages.
         * @function encode
         * @memberof jira.Security
         * @static
         * @param {jira.ISecurity} message Security message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Security.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified Security message, length delimited. Does not implicitly {@link jira.Security.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.Security
         * @static
         * @param {jira.ISecurity} message Security message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Security.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Security message from the specified reader or buffer.
         * @function decode
         * @memberof jira.Security
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.Security} Security
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Security.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.Security();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Security message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.Security
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.Security} Security
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Security.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Security message.
         * @function verify
         * @memberof jira.Security
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Security.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isString(message.id))
                    return "id: string expected";
            }
            return null;
        };

        /**
         * Creates a Security message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.Security
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.Security} Security
         */
        Security.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.Security)
                return object;
            var message = new $root.jira.Security();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a Security message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.Security
         * @static
         * @param {jira.Security} message Security
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Security.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            return object;
        };

        /**
         * Converts this Security to JSON.
         * @function toJSON
         * @memberof jira.Security
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Security.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Security
         * @function getTypeUrl
         * @memberof jira.Security
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Security.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.Security";
        };

        return Security;
    })();

    jira.Version = (function() {

        /**
         * Properties of a Version.
         * @memberof jira
         * @interface IVersion
         * @property {string|null} [id] Version id
         */

        /**
         * Constructs a new Version.
         * @memberof jira
         * @classdesc Represents a Version.
         * @implements IVersion
         * @constructor
         * @param {jira.IVersion=} [properties] Properties to set
         */
        function Version(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Version id.
         * @member {string|null|undefined} id
         * @memberof jira.Version
         * @instance
         */
        Version.prototype.id = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Version _id.
         * @member {"id"|undefined} _id
         * @memberof jira.Version
         * @instance
         */
        Object.defineProperty(Version.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Version instance using the specified properties.
         * @function create
         * @memberof jira.Version
         * @static
         * @param {jira.IVersion=} [properties] Properties to set
         * @returns {jira.Version} Version instance
         */
        Version.create = function create(properties) {
            return new Version(properties);
        };

        /**
         * Encodes the specified Version message. Does not implicitly {@link jira.Version.verify|verify} messages.
         * @function encode
         * @memberof jira.Version
         * @static
         * @param {jira.IVersion} message Version message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Version.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified Version message, length delimited. Does not implicitly {@link jira.Version.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.Version
         * @static
         * @param {jira.IVersion} message Version message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Version.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Version message from the specified reader or buffer.
         * @function decode
         * @memberof jira.Version
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.Version} Version
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Version.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.Version();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Version message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.Version
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.Version} Version
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Version.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Version message.
         * @function verify
         * @memberof jira.Version
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Version.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isString(message.id))
                    return "id: string expected";
            }
            return null;
        };

        /**
         * Creates a Version message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.Version
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.Version} Version
         */
        Version.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.Version)
                return object;
            var message = new $root.jira.Version();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a Version message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.Version
         * @static
         * @param {jira.Version} message Version
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Version.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            return object;
        };

        /**
         * Converts this Version to JSON.
         * @function toJSON
         * @memberof jira.Version
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Version.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Version
         * @function getTypeUrl
         * @memberof jira.Version
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Version.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.Version";
        };

        return Version;
    })();

    jira.Component = (function() {

        /**
         * Properties of a Component.
         * @memberof jira
         * @interface IComponent
         * @property {string|null} [id] Component id
         */

        /**
         * Constructs a new Component.
         * @memberof jira
         * @classdesc Represents a Component.
         * @implements IComponent
         * @constructor
         * @param {jira.IComponent=} [properties] Properties to set
         */
        function Component(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Component id.
         * @member {string|null|undefined} id
         * @memberof jira.Component
         * @instance
         */
        Component.prototype.id = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Component _id.
         * @member {"id"|undefined} _id
         * @memberof jira.Component
         * @instance
         */
        Object.defineProperty(Component.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Component instance using the specified properties.
         * @function create
         * @memberof jira.Component
         * @static
         * @param {jira.IComponent=} [properties] Properties to set
         * @returns {jira.Component} Component instance
         */
        Component.create = function create(properties) {
            return new Component(properties);
        };

        /**
         * Encodes the specified Component message. Does not implicitly {@link jira.Component.verify|verify} messages.
         * @function encode
         * @memberof jira.Component
         * @static
         * @param {jira.IComponent} message Component message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Component.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified Component message, length delimited. Does not implicitly {@link jira.Component.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.Component
         * @static
         * @param {jira.IComponent} message Component message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Component.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Component message from the specified reader or buffer.
         * @function decode
         * @memberof jira.Component
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.Component} Component
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Component.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.Component();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Component message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.Component
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.Component} Component
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Component.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Component message.
         * @function verify
         * @memberof jira.Component
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Component.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isString(message.id))
                    return "id: string expected";
            }
            return null;
        };

        /**
         * Creates a Component message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.Component
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.Component} Component
         */
        Component.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.Component)
                return object;
            var message = new $root.jira.Component();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a Component message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.Component
         * @static
         * @param {jira.Component} message Component
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Component.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            return object;
        };

        /**
         * Converts this Component to JSON.
         * @function toJSON
         * @memberof jira.Component
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Component.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Component
         * @function getTypeUrl
         * @memberof jira.Component
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Component.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.Component";
        };

        return Component;
    })();

    jira.Error = (function() {

        /**
         * Properties of an Error.
         * @memberof jira
         * @interface IError
         * @property {string|null} [priority] Error priority
         */

        /**
         * Constructs a new Error.
         * @memberof jira
         * @classdesc Represents an Error.
         * @implements IError
         * @constructor
         * @param {jira.IError=} [properties] Properties to set
         */
        function Error(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Error priority.
         * @member {string|null|undefined} priority
         * @memberof jira.Error
         * @instance
         */
        Error.prototype.priority = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Error _priority.
         * @member {"priority"|undefined} _priority
         * @memberof jira.Error
         * @instance
         */
        Object.defineProperty(Error.prototype, "_priority", {
            get: $util.oneOfGetter($oneOfFields = ["priority"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Error instance using the specified properties.
         * @function create
         * @memberof jira.Error
         * @static
         * @param {jira.IError=} [properties] Properties to set
         * @returns {jira.Error} Error instance
         */
        Error.create = function create(properties) {
            return new Error(properties);
        };

        /**
         * Encodes the specified Error message. Does not implicitly {@link jira.Error.verify|verify} messages.
         * @function encode
         * @memberof jira.Error
         * @static
         * @param {jira.IError} message Error message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Error.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.priority);
            return writer;
        };

        /**
         * Encodes the specified Error message, length delimited. Does not implicitly {@link jira.Error.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.Error
         * @static
         * @param {jira.IError} message Error message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Error.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Error message from the specified reader or buffer.
         * @function decode
         * @memberof jira.Error
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.Error} Error
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Error.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.Error();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.priority = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Error message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.Error
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.Error} Error
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Error.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Error message.
         * @function verify
         * @memberof jira.Error
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Error.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.priority != null && message.hasOwnProperty("priority")) {
                properties._priority = 1;
                if (!$util.isString(message.priority))
                    return "priority: string expected";
            }
            return null;
        };

        /**
         * Creates an Error message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.Error
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.Error} Error
         */
        Error.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.Error)
                return object;
            var message = new $root.jira.Error();
            if (object.priority != null)
                message.priority = String(object.priority);
            return message;
        };

        /**
         * Creates a plain object from an Error message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.Error
         * @static
         * @param {jira.Error} message Error
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Error.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.priority != null && message.hasOwnProperty("priority")) {
                object.priority = message.priority;
                if (options.oneofs)
                    object._priority = "priority";
            }
            return object;
        };

        /**
         * Converts this Error to JSON.
         * @function toJSON
         * @memberof jira.Error
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Error.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Error
         * @function getTypeUrl
         * @memberof jira.Error
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Error.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.Error";
        };

        return Error;
    })();

    jira.FindUsersAndGroupsRequest = (function() {

        /**
         * Properties of a FindUsersAndGroupsRequest.
         * @memberof jira
         * @interface IFindUsersAndGroupsRequest
         * @property {string|null} [query] FindUsersAndGroupsRequest query
         * @property {number|null} [maxResults] FindUsersAndGroupsRequest maxResults
         * @property {boolean|null} [caseInsensitive] FindUsersAndGroupsRequest caseInsensitive
         */

        /**
         * Constructs a new FindUsersAndGroupsRequest.
         * @memberof jira
         * @classdesc Represents a FindUsersAndGroupsRequest.
         * @implements IFindUsersAndGroupsRequest
         * @constructor
         * @param {jira.IFindUsersAndGroupsRequest=} [properties] Properties to set
         */
        function FindUsersAndGroupsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FindUsersAndGroupsRequest query.
         * @member {string|null|undefined} query
         * @memberof jira.FindUsersAndGroupsRequest
         * @instance
         */
        FindUsersAndGroupsRequest.prototype.query = null;

        /**
         * FindUsersAndGroupsRequest maxResults.
         * @member {number|null|undefined} maxResults
         * @memberof jira.FindUsersAndGroupsRequest
         * @instance
         */
        FindUsersAndGroupsRequest.prototype.maxResults = null;

        /**
         * FindUsersAndGroupsRequest caseInsensitive.
         * @member {boolean|null|undefined} caseInsensitive
         * @memberof jira.FindUsersAndGroupsRequest
         * @instance
         */
        FindUsersAndGroupsRequest.prototype.caseInsensitive = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * FindUsersAndGroupsRequest _query.
         * @member {"query"|undefined} _query
         * @memberof jira.FindUsersAndGroupsRequest
         * @instance
         */
        Object.defineProperty(FindUsersAndGroupsRequest.prototype, "_query", {
            get: $util.oneOfGetter($oneOfFields = ["query"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FindUsersAndGroupsRequest _maxResults.
         * @member {"maxResults"|undefined} _maxResults
         * @memberof jira.FindUsersAndGroupsRequest
         * @instance
         */
        Object.defineProperty(FindUsersAndGroupsRequest.prototype, "_maxResults", {
            get: $util.oneOfGetter($oneOfFields = ["maxResults"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FindUsersAndGroupsRequest _caseInsensitive.
         * @member {"caseInsensitive"|undefined} _caseInsensitive
         * @memberof jira.FindUsersAndGroupsRequest
         * @instance
         */
        Object.defineProperty(FindUsersAndGroupsRequest.prototype, "_caseInsensitive", {
            get: $util.oneOfGetter($oneOfFields = ["caseInsensitive"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new FindUsersAndGroupsRequest instance using the specified properties.
         * @function create
         * @memberof jira.FindUsersAndGroupsRequest
         * @static
         * @param {jira.IFindUsersAndGroupsRequest=} [properties] Properties to set
         * @returns {jira.FindUsersAndGroupsRequest} FindUsersAndGroupsRequest instance
         */
        FindUsersAndGroupsRequest.create = function create(properties) {
            return new FindUsersAndGroupsRequest(properties);
        };

        /**
         * Encodes the specified FindUsersAndGroupsRequest message. Does not implicitly {@link jira.FindUsersAndGroupsRequest.verify|verify} messages.
         * @function encode
         * @memberof jira.FindUsersAndGroupsRequest
         * @static
         * @param {jira.IFindUsersAndGroupsRequest} message FindUsersAndGroupsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindUsersAndGroupsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.query);
            if (message.maxResults != null && Object.hasOwnProperty.call(message, "maxResults"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.maxResults);
            if (message.caseInsensitive != null && Object.hasOwnProperty.call(message, "caseInsensitive"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.caseInsensitive);
            return writer;
        };

        /**
         * Encodes the specified FindUsersAndGroupsRequest message, length delimited. Does not implicitly {@link jira.FindUsersAndGroupsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.FindUsersAndGroupsRequest
         * @static
         * @param {jira.IFindUsersAndGroupsRequest} message FindUsersAndGroupsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindUsersAndGroupsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FindUsersAndGroupsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof jira.FindUsersAndGroupsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.FindUsersAndGroupsRequest} FindUsersAndGroupsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindUsersAndGroupsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.FindUsersAndGroupsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.query = reader.string();
                        break;
                    }
                case 2: {
                        message.maxResults = reader.int32();
                        break;
                    }
                case 3: {
                        message.caseInsensitive = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FindUsersAndGroupsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.FindUsersAndGroupsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.FindUsersAndGroupsRequest} FindUsersAndGroupsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindUsersAndGroupsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FindUsersAndGroupsRequest message.
         * @function verify
         * @memberof jira.FindUsersAndGroupsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FindUsersAndGroupsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.query != null && message.hasOwnProperty("query")) {
                properties._query = 1;
                if (!$util.isString(message.query))
                    return "query: string expected";
            }
            if (message.maxResults != null && message.hasOwnProperty("maxResults")) {
                properties._maxResults = 1;
                if (!$util.isInteger(message.maxResults))
                    return "maxResults: integer expected";
            }
            if (message.caseInsensitive != null && message.hasOwnProperty("caseInsensitive")) {
                properties._caseInsensitive = 1;
                if (typeof message.caseInsensitive !== "boolean")
                    return "caseInsensitive: boolean expected";
            }
            return null;
        };

        /**
         * Creates a FindUsersAndGroupsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.FindUsersAndGroupsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.FindUsersAndGroupsRequest} FindUsersAndGroupsRequest
         */
        FindUsersAndGroupsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.FindUsersAndGroupsRequest)
                return object;
            var message = new $root.jira.FindUsersAndGroupsRequest();
            if (object.query != null)
                message.query = String(object.query);
            if (object.maxResults != null)
                message.maxResults = object.maxResults | 0;
            if (object.caseInsensitive != null)
                message.caseInsensitive = Boolean(object.caseInsensitive);
            return message;
        };

        /**
         * Creates a plain object from a FindUsersAndGroupsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.FindUsersAndGroupsRequest
         * @static
         * @param {jira.FindUsersAndGroupsRequest} message FindUsersAndGroupsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FindUsersAndGroupsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.query != null && message.hasOwnProperty("query")) {
                object.query = message.query;
                if (options.oneofs)
                    object._query = "query";
            }
            if (message.maxResults != null && message.hasOwnProperty("maxResults")) {
                object.maxResults = message.maxResults;
                if (options.oneofs)
                    object._maxResults = "maxResults";
            }
            if (message.caseInsensitive != null && message.hasOwnProperty("caseInsensitive")) {
                object.caseInsensitive = message.caseInsensitive;
                if (options.oneofs)
                    object._caseInsensitive = "caseInsensitive";
            }
            return object;
        };

        /**
         * Converts this FindUsersAndGroupsRequest to JSON.
         * @function toJSON
         * @memberof jira.FindUsersAndGroupsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FindUsersAndGroupsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FindUsersAndGroupsRequest
         * @function getTypeUrl
         * @memberof jira.FindUsersAndGroupsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FindUsersAndGroupsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.FindUsersAndGroupsRequest";
        };

        return FindUsersAndGroupsRequest;
    })();

    jira.FindUsersAndGroupsResponse = (function() {

        /**
         * Properties of a FindUsersAndGroupsResponse.
         * @memberof jira
         * @interface IFindUsersAndGroupsResponse
         * @property {jira.FindUsersAndGroupsResponse.IFoundUsers|null} [users] FindUsersAndGroupsResponse users
         * @property {jira.IErrorResponse|null} [projectLeoError] FindUsersAndGroupsResponse projectLeoError
         */

        /**
         * Constructs a new FindUsersAndGroupsResponse.
         * @memberof jira
         * @classdesc Represents a FindUsersAndGroupsResponse.
         * @implements IFindUsersAndGroupsResponse
         * @constructor
         * @param {jira.IFindUsersAndGroupsResponse=} [properties] Properties to set
         */
        function FindUsersAndGroupsResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FindUsersAndGroupsResponse users.
         * @member {jira.FindUsersAndGroupsResponse.IFoundUsers|null|undefined} users
         * @memberof jira.FindUsersAndGroupsResponse
         * @instance
         */
        FindUsersAndGroupsResponse.prototype.users = null;

        /**
         * FindUsersAndGroupsResponse projectLeoError.
         * @member {jira.IErrorResponse|null|undefined} projectLeoError
         * @memberof jira.FindUsersAndGroupsResponse
         * @instance
         */
        FindUsersAndGroupsResponse.prototype.projectLeoError = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * FindUsersAndGroupsResponse _users.
         * @member {"users"|undefined} _users
         * @memberof jira.FindUsersAndGroupsResponse
         * @instance
         */
        Object.defineProperty(FindUsersAndGroupsResponse.prototype, "_users", {
            get: $util.oneOfGetter($oneOfFields = ["users"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FindUsersAndGroupsResponse _projectLeoError.
         * @member {"projectLeoError"|undefined} _projectLeoError
         * @memberof jira.FindUsersAndGroupsResponse
         * @instance
         */
        Object.defineProperty(FindUsersAndGroupsResponse.prototype, "_projectLeoError", {
            get: $util.oneOfGetter($oneOfFields = ["projectLeoError"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new FindUsersAndGroupsResponse instance using the specified properties.
         * @function create
         * @memberof jira.FindUsersAndGroupsResponse
         * @static
         * @param {jira.IFindUsersAndGroupsResponse=} [properties] Properties to set
         * @returns {jira.FindUsersAndGroupsResponse} FindUsersAndGroupsResponse instance
         */
        FindUsersAndGroupsResponse.create = function create(properties) {
            return new FindUsersAndGroupsResponse(properties);
        };

        /**
         * Encodes the specified FindUsersAndGroupsResponse message. Does not implicitly {@link jira.FindUsersAndGroupsResponse.verify|verify} messages.
         * @function encode
         * @memberof jira.FindUsersAndGroupsResponse
         * @static
         * @param {jira.IFindUsersAndGroupsResponse} message FindUsersAndGroupsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindUsersAndGroupsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.users != null && Object.hasOwnProperty.call(message, "users"))
                $root.jira.FindUsersAndGroupsResponse.FoundUsers.encode(message.users, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.projectLeoError != null && Object.hasOwnProperty.call(message, "projectLeoError"))
                $root.jira.ErrorResponse.encode(message.projectLeoError, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified FindUsersAndGroupsResponse message, length delimited. Does not implicitly {@link jira.FindUsersAndGroupsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.FindUsersAndGroupsResponse
         * @static
         * @param {jira.IFindUsersAndGroupsResponse} message FindUsersAndGroupsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindUsersAndGroupsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FindUsersAndGroupsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof jira.FindUsersAndGroupsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.FindUsersAndGroupsResponse} FindUsersAndGroupsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindUsersAndGroupsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.FindUsersAndGroupsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.users = $root.jira.FindUsersAndGroupsResponse.FoundUsers.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.projectLeoError = $root.jira.ErrorResponse.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FindUsersAndGroupsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.FindUsersAndGroupsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.FindUsersAndGroupsResponse} FindUsersAndGroupsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindUsersAndGroupsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FindUsersAndGroupsResponse message.
         * @function verify
         * @memberof jira.FindUsersAndGroupsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FindUsersAndGroupsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.users != null && message.hasOwnProperty("users")) {
                properties._users = 1;
                {
                    var error = $root.jira.FindUsersAndGroupsResponse.FoundUsers.verify(message.users);
                    if (error)
                        return "users." + error;
                }
            }
            if (message.projectLeoError != null && message.hasOwnProperty("projectLeoError")) {
                properties._projectLeoError = 1;
                {
                    var error = $root.jira.ErrorResponse.verify(message.projectLeoError);
                    if (error)
                        return "projectLeoError." + error;
                }
            }
            return null;
        };

        /**
         * Creates a FindUsersAndGroupsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.FindUsersAndGroupsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.FindUsersAndGroupsResponse} FindUsersAndGroupsResponse
         */
        FindUsersAndGroupsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.FindUsersAndGroupsResponse)
                return object;
            var message = new $root.jira.FindUsersAndGroupsResponse();
            if (object.users != null) {
                if (typeof object.users !== "object")
                    throw TypeError(".jira.FindUsersAndGroupsResponse.users: object expected");
                message.users = $root.jira.FindUsersAndGroupsResponse.FoundUsers.fromObject(object.users);
            }
            if (object.projectLeoError != null) {
                if (typeof object.projectLeoError !== "object")
                    throw TypeError(".jira.FindUsersAndGroupsResponse.projectLeoError: object expected");
                message.projectLeoError = $root.jira.ErrorResponse.fromObject(object.projectLeoError);
            }
            return message;
        };

        /**
         * Creates a plain object from a FindUsersAndGroupsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.FindUsersAndGroupsResponse
         * @static
         * @param {jira.FindUsersAndGroupsResponse} message FindUsersAndGroupsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FindUsersAndGroupsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.users != null && message.hasOwnProperty("users")) {
                object.users = $root.jira.FindUsersAndGroupsResponse.FoundUsers.toObject(message.users, options);
                if (options.oneofs)
                    object._users = "users";
            }
            if (message.projectLeoError != null && message.hasOwnProperty("projectLeoError")) {
                object.projectLeoError = $root.jira.ErrorResponse.toObject(message.projectLeoError, options);
                if (options.oneofs)
                    object._projectLeoError = "projectLeoError";
            }
            return object;
        };

        /**
         * Converts this FindUsersAndGroupsResponse to JSON.
         * @function toJSON
         * @memberof jira.FindUsersAndGroupsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FindUsersAndGroupsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FindUsersAndGroupsResponse
         * @function getTypeUrl
         * @memberof jira.FindUsersAndGroupsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FindUsersAndGroupsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.FindUsersAndGroupsResponse";
        };

        FindUsersAndGroupsResponse.FoundUsers = (function() {

            /**
             * Properties of a FoundUsers.
             * @memberof jira.FindUsersAndGroupsResponse
             * @interface IFoundUsers
             * @property {Array.<jira.FindUsersAndGroupsResponse.FoundUsers.IUserPickerUser>|null} [users] FoundUsers users
             */

            /**
             * Constructs a new FoundUsers.
             * @memberof jira.FindUsersAndGroupsResponse
             * @classdesc Represents a FoundUsers.
             * @implements IFoundUsers
             * @constructor
             * @param {jira.FindUsersAndGroupsResponse.IFoundUsers=} [properties] Properties to set
             */
            function FoundUsers(properties) {
                this.users = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FoundUsers users.
             * @member {Array.<jira.FindUsersAndGroupsResponse.FoundUsers.IUserPickerUser>} users
             * @memberof jira.FindUsersAndGroupsResponse.FoundUsers
             * @instance
             */
            FoundUsers.prototype.users = $util.emptyArray;

            /**
             * Creates a new FoundUsers instance using the specified properties.
             * @function create
             * @memberof jira.FindUsersAndGroupsResponse.FoundUsers
             * @static
             * @param {jira.FindUsersAndGroupsResponse.IFoundUsers=} [properties] Properties to set
             * @returns {jira.FindUsersAndGroupsResponse.FoundUsers} FoundUsers instance
             */
            FoundUsers.create = function create(properties) {
                return new FoundUsers(properties);
            };

            /**
             * Encodes the specified FoundUsers message. Does not implicitly {@link jira.FindUsersAndGroupsResponse.FoundUsers.verify|verify} messages.
             * @function encode
             * @memberof jira.FindUsersAndGroupsResponse.FoundUsers
             * @static
             * @param {jira.FindUsersAndGroupsResponse.IFoundUsers} message FoundUsers message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FoundUsers.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.users != null && message.users.length)
                    for (var i = 0; i < message.users.length; ++i)
                        $root.jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser.encode(message.users[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified FoundUsers message, length delimited. Does not implicitly {@link jira.FindUsersAndGroupsResponse.FoundUsers.verify|verify} messages.
             * @function encodeDelimited
             * @memberof jira.FindUsersAndGroupsResponse.FoundUsers
             * @static
             * @param {jira.FindUsersAndGroupsResponse.IFoundUsers} message FoundUsers message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FoundUsers.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FoundUsers message from the specified reader or buffer.
             * @function decode
             * @memberof jira.FindUsersAndGroupsResponse.FoundUsers
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {jira.FindUsersAndGroupsResponse.FoundUsers} FoundUsers
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FoundUsers.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.FindUsersAndGroupsResponse.FoundUsers();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.users && message.users.length))
                                message.users = [];
                            message.users.push($root.jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FoundUsers message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof jira.FindUsersAndGroupsResponse.FoundUsers
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {jira.FindUsersAndGroupsResponse.FoundUsers} FoundUsers
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FoundUsers.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FoundUsers message.
             * @function verify
             * @memberof jira.FindUsersAndGroupsResponse.FoundUsers
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FoundUsers.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.users != null && message.hasOwnProperty("users")) {
                    if (!Array.isArray(message.users))
                        return "users: array expected";
                    for (var i = 0; i < message.users.length; ++i) {
                        var error = $root.jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser.verify(message.users[i]);
                        if (error)
                            return "users." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a FoundUsers message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof jira.FindUsersAndGroupsResponse.FoundUsers
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {jira.FindUsersAndGroupsResponse.FoundUsers} FoundUsers
             */
            FoundUsers.fromObject = function fromObject(object) {
                if (object instanceof $root.jira.FindUsersAndGroupsResponse.FoundUsers)
                    return object;
                var message = new $root.jira.FindUsersAndGroupsResponse.FoundUsers();
                if (object.users) {
                    if (!Array.isArray(object.users))
                        throw TypeError(".jira.FindUsersAndGroupsResponse.FoundUsers.users: array expected");
                    message.users = [];
                    for (var i = 0; i < object.users.length; ++i) {
                        if (typeof object.users[i] !== "object")
                            throw TypeError(".jira.FindUsersAndGroupsResponse.FoundUsers.users: object expected");
                        message.users[i] = $root.jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser.fromObject(object.users[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a FoundUsers message. Also converts values to other types if specified.
             * @function toObject
             * @memberof jira.FindUsersAndGroupsResponse.FoundUsers
             * @static
             * @param {jira.FindUsersAndGroupsResponse.FoundUsers} message FoundUsers
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FoundUsers.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.users = [];
                if (message.users && message.users.length) {
                    object.users = [];
                    for (var j = 0; j < message.users.length; ++j)
                        object.users[j] = $root.jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser.toObject(message.users[j], options);
                }
                return object;
            };

            /**
             * Converts this FoundUsers to JSON.
             * @function toJSON
             * @memberof jira.FindUsersAndGroupsResponse.FoundUsers
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FoundUsers.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FoundUsers
             * @function getTypeUrl
             * @memberof jira.FindUsersAndGroupsResponse.FoundUsers
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FoundUsers.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/jira.FindUsersAndGroupsResponse.FoundUsers";
            };

            FoundUsers.UserPickerUser = (function() {

                /**
                 * Properties of a UserPickerUser.
                 * @memberof jira.FindUsersAndGroupsResponse.FoundUsers
                 * @interface IUserPickerUser
                 * @property {string|null} [accountId] UserPickerUser accountId
                 * @property {string|null} [html] UserPickerUser html
                 */

                /**
                 * Constructs a new UserPickerUser.
                 * @memberof jira.FindUsersAndGroupsResponse.FoundUsers
                 * @classdesc Represents a UserPickerUser.
                 * @implements IUserPickerUser
                 * @constructor
                 * @param {jira.FindUsersAndGroupsResponse.FoundUsers.IUserPickerUser=} [properties] Properties to set
                 */
                function UserPickerUser(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserPickerUser accountId.
                 * @member {string|null|undefined} accountId
                 * @memberof jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser
                 * @instance
                 */
                UserPickerUser.prototype.accountId = null;

                /**
                 * UserPickerUser html.
                 * @member {string|null|undefined} html
                 * @memberof jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser
                 * @instance
                 */
                UserPickerUser.prototype.html = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * UserPickerUser _accountId.
                 * @member {"accountId"|undefined} _accountId
                 * @memberof jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser
                 * @instance
                 */
                Object.defineProperty(UserPickerUser.prototype, "_accountId", {
                    get: $util.oneOfGetter($oneOfFields = ["accountId"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * UserPickerUser _html.
                 * @member {"html"|undefined} _html
                 * @memberof jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser
                 * @instance
                 */
                Object.defineProperty(UserPickerUser.prototype, "_html", {
                    get: $util.oneOfGetter($oneOfFields = ["html"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new UserPickerUser instance using the specified properties.
                 * @function create
                 * @memberof jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser
                 * @static
                 * @param {jira.FindUsersAndGroupsResponse.FoundUsers.IUserPickerUser=} [properties] Properties to set
                 * @returns {jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser} UserPickerUser instance
                 */
                UserPickerUser.create = function create(properties) {
                    return new UserPickerUser(properties);
                };

                /**
                 * Encodes the specified UserPickerUser message. Does not implicitly {@link jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser.verify|verify} messages.
                 * @function encode
                 * @memberof jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser
                 * @static
                 * @param {jira.FindUsersAndGroupsResponse.FoundUsers.IUserPickerUser} message UserPickerUser message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserPickerUser.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.accountId);
                    if (message.html != null && Object.hasOwnProperty.call(message, "html"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.html);
                    return writer;
                };

                /**
                 * Encodes the specified UserPickerUser message, length delimited. Does not implicitly {@link jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser
                 * @static
                 * @param {jira.FindUsersAndGroupsResponse.FoundUsers.IUserPickerUser} message UserPickerUser message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserPickerUser.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a UserPickerUser message from the specified reader or buffer.
                 * @function decode
                 * @memberof jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser} UserPickerUser
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserPickerUser.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.accountId = reader.string();
                                break;
                            }
                        case 2: {
                                message.html = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a UserPickerUser message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser} UserPickerUser
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserPickerUser.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a UserPickerUser message.
                 * @function verify
                 * @memberof jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserPickerUser.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.accountId != null && message.hasOwnProperty("accountId")) {
                        properties._accountId = 1;
                        if (!$util.isString(message.accountId))
                            return "accountId: string expected";
                    }
                    if (message.html != null && message.hasOwnProperty("html")) {
                        properties._html = 1;
                        if (!$util.isString(message.html))
                            return "html: string expected";
                    }
                    return null;
                };

                /**
                 * Creates a UserPickerUser message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser} UserPickerUser
                 */
                UserPickerUser.fromObject = function fromObject(object) {
                    if (object instanceof $root.jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser)
                        return object;
                    var message = new $root.jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser();
                    if (object.accountId != null)
                        message.accountId = String(object.accountId);
                    if (object.html != null)
                        message.html = String(object.html);
                    return message;
                };

                /**
                 * Creates a plain object from a UserPickerUser message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser
                 * @static
                 * @param {jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser} message UserPickerUser
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserPickerUser.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.accountId != null && message.hasOwnProperty("accountId")) {
                        object.accountId = message.accountId;
                        if (options.oneofs)
                            object._accountId = "accountId";
                    }
                    if (message.html != null && message.hasOwnProperty("html")) {
                        object.html = message.html;
                        if (options.oneofs)
                            object._html = "html";
                    }
                    return object;
                };

                /**
                 * Converts this UserPickerUser to JSON.
                 * @function toJSON
                 * @memberof jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserPickerUser.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UserPickerUser
                 * @function getTypeUrl
                 * @memberof jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UserPickerUser.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/jira.FindUsersAndGroupsResponse.FoundUsers.UserPickerUser";
                };

                return UserPickerUser;
            })();

            return FoundUsers;
        })();

        return FindUsersAndGroupsResponse;
    })();

    jira.CreateIssueRequest = (function() {

        /**
         * Properties of a CreateIssueRequest.
         * @memberof jira
         * @interface ICreateIssueRequest
         * @property {jira.IUpdate|null} [update] CreateIssueRequest update
         * @property {jira.CreateIssueRequest.IFields|null} [fields] CreateIssueRequest fields
         */

        /**
         * Constructs a new CreateIssueRequest.
         * @memberof jira
         * @classdesc Represents a CreateIssueRequest.
         * @implements ICreateIssueRequest
         * @constructor
         * @param {jira.ICreateIssueRequest=} [properties] Properties to set
         */
        function CreateIssueRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateIssueRequest update.
         * @member {jira.IUpdate|null|undefined} update
         * @memberof jira.CreateIssueRequest
         * @instance
         */
        CreateIssueRequest.prototype.update = null;

        /**
         * CreateIssueRequest fields.
         * @member {jira.CreateIssueRequest.IFields|null|undefined} fields
         * @memberof jira.CreateIssueRequest
         * @instance
         */
        CreateIssueRequest.prototype.fields = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * CreateIssueRequest _update.
         * @member {"update"|undefined} _update
         * @memberof jira.CreateIssueRequest
         * @instance
         */
        Object.defineProperty(CreateIssueRequest.prototype, "_update", {
            get: $util.oneOfGetter($oneOfFields = ["update"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreateIssueRequest _fields.
         * @member {"fields"|undefined} _fields
         * @memberof jira.CreateIssueRequest
         * @instance
         */
        Object.defineProperty(CreateIssueRequest.prototype, "_fields", {
            get: $util.oneOfGetter($oneOfFields = ["fields"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CreateIssueRequest instance using the specified properties.
         * @function create
         * @memberof jira.CreateIssueRequest
         * @static
         * @param {jira.ICreateIssueRequest=} [properties] Properties to set
         * @returns {jira.CreateIssueRequest} CreateIssueRequest instance
         */
        CreateIssueRequest.create = function create(properties) {
            return new CreateIssueRequest(properties);
        };

        /**
         * Encodes the specified CreateIssueRequest message. Does not implicitly {@link jira.CreateIssueRequest.verify|verify} messages.
         * @function encode
         * @memberof jira.CreateIssueRequest
         * @static
         * @param {jira.ICreateIssueRequest} message CreateIssueRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateIssueRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.jira.Update.encode(message.update, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.fields != null && Object.hasOwnProperty.call(message, "fields"))
                $root.jira.CreateIssueRequest.Fields.encode(message.fields, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateIssueRequest message, length delimited. Does not implicitly {@link jira.CreateIssueRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.CreateIssueRequest
         * @static
         * @param {jira.ICreateIssueRequest} message CreateIssueRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateIssueRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateIssueRequest message from the specified reader or buffer.
         * @function decode
         * @memberof jira.CreateIssueRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.CreateIssueRequest} CreateIssueRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateIssueRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.CreateIssueRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.update = $root.jira.Update.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.fields = $root.jira.CreateIssueRequest.Fields.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateIssueRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.CreateIssueRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.CreateIssueRequest} CreateIssueRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateIssueRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateIssueRequest message.
         * @function verify
         * @memberof jira.CreateIssueRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateIssueRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.update != null && message.hasOwnProperty("update")) {
                properties._update = 1;
                {
                    var error = $root.jira.Update.verify(message.update);
                    if (error)
                        return "update." + error;
                }
            }
            if (message.fields != null && message.hasOwnProperty("fields")) {
                properties._fields = 1;
                {
                    var error = $root.jira.CreateIssueRequest.Fields.verify(message.fields);
                    if (error)
                        return "fields." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CreateIssueRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.CreateIssueRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.CreateIssueRequest} CreateIssueRequest
         */
        CreateIssueRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.CreateIssueRequest)
                return object;
            var message = new $root.jira.CreateIssueRequest();
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".jira.CreateIssueRequest.update: object expected");
                message.update = $root.jira.Update.fromObject(object.update);
            }
            if (object.fields != null) {
                if (typeof object.fields !== "object")
                    throw TypeError(".jira.CreateIssueRequest.fields: object expected");
                message.fields = $root.jira.CreateIssueRequest.Fields.fromObject(object.fields);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateIssueRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.CreateIssueRequest
         * @static
         * @param {jira.CreateIssueRequest} message CreateIssueRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateIssueRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.update != null && message.hasOwnProperty("update")) {
                object.update = $root.jira.Update.toObject(message.update, options);
                if (options.oneofs)
                    object._update = "update";
            }
            if (message.fields != null && message.hasOwnProperty("fields")) {
                object.fields = $root.jira.CreateIssueRequest.Fields.toObject(message.fields, options);
                if (options.oneofs)
                    object._fields = "fields";
            }
            return object;
        };

        /**
         * Converts this CreateIssueRequest to JSON.
         * @function toJSON
         * @memberof jira.CreateIssueRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateIssueRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateIssueRequest
         * @function getTypeUrl
         * @memberof jira.CreateIssueRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateIssueRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.CreateIssueRequest";
        };

        CreateIssueRequest.Fields = (function() {

            /**
             * Properties of a Fields.
             * @memberof jira.CreateIssueRequest
             * @interface IFields
             * @property {jira.IProject|null} [project] Fields project
             * @property {string|null} [summary] Fields summary
             * @property {jira.IIssueType|null} [issuetype] Fields issuetype
             * @property {jira.IAssignee|null} [assignee] Fields assignee
             * @property {jira.IReporter|null} [reporter] Fields reporter
             * @property {jira.IPriority|null} [priority] Fields priority
             * @property {Array.<string>|null} [labels] Fields labels
             * @property {jira.ITimeTracking|null} [timetracking] Fields timetracking
             * @property {jira.ISecurity|null} [security] Fields security
             * @property {Array.<jira.IVersion>|null} [versions] Fields versions
             * @property {string|null} [environment] Fields environment
             * @property {string|null} [description] Fields description
             * @property {string|null} [duedate] Fields duedate
             * @property {Array.<jira.IVersion>|null} [fixVersions] Fields fixVersions
             * @property {Array.<jira.IComponent>|null} [components] Fields components
             * @property {jira.IParent|null} [parent] Fields parent
             */

            /**
             * Constructs a new Fields.
             * @memberof jira.CreateIssueRequest
             * @classdesc Represents a Fields.
             * @implements IFields
             * @constructor
             * @param {jira.CreateIssueRequest.IFields=} [properties] Properties to set
             */
            function Fields(properties) {
                this.labels = [];
                this.versions = [];
                this.fixVersions = [];
                this.components = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Fields project.
             * @member {jira.IProject|null|undefined} project
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Fields.prototype.project = null;

            /**
             * Fields summary.
             * @member {string|null|undefined} summary
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Fields.prototype.summary = null;

            /**
             * Fields issuetype.
             * @member {jira.IIssueType|null|undefined} issuetype
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Fields.prototype.issuetype = null;

            /**
             * Fields assignee.
             * @member {jira.IAssignee|null|undefined} assignee
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Fields.prototype.assignee = null;

            /**
             * Fields reporter.
             * @member {jira.IReporter|null|undefined} reporter
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Fields.prototype.reporter = null;

            /**
             * Fields priority.
             * @member {jira.IPriority|null|undefined} priority
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Fields.prototype.priority = null;

            /**
             * Fields labels.
             * @member {Array.<string>} labels
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Fields.prototype.labels = $util.emptyArray;

            /**
             * Fields timetracking.
             * @member {jira.ITimeTracking|null|undefined} timetracking
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Fields.prototype.timetracking = null;

            /**
             * Fields security.
             * @member {jira.ISecurity|null|undefined} security
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Fields.prototype.security = null;

            /**
             * Fields versions.
             * @member {Array.<jira.IVersion>} versions
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Fields.prototype.versions = $util.emptyArray;

            /**
             * Fields environment.
             * @member {string|null|undefined} environment
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Fields.prototype.environment = null;

            /**
             * Fields description.
             * @member {string|null|undefined} description
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Fields.prototype.description = null;

            /**
             * Fields duedate.
             * @member {string|null|undefined} duedate
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Fields.prototype.duedate = null;

            /**
             * Fields fixVersions.
             * @member {Array.<jira.IVersion>} fixVersions
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Fields.prototype.fixVersions = $util.emptyArray;

            /**
             * Fields components.
             * @member {Array.<jira.IComponent>} components
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Fields.prototype.components = $util.emptyArray;

            /**
             * Fields parent.
             * @member {jira.IParent|null|undefined} parent
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Fields.prototype.parent = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * Fields _project.
             * @member {"project"|undefined} _project
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Object.defineProperty(Fields.prototype, "_project", {
                get: $util.oneOfGetter($oneOfFields = ["project"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Fields _summary.
             * @member {"summary"|undefined} _summary
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Object.defineProperty(Fields.prototype, "_summary", {
                get: $util.oneOfGetter($oneOfFields = ["summary"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Fields _issuetype.
             * @member {"issuetype"|undefined} _issuetype
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Object.defineProperty(Fields.prototype, "_issuetype", {
                get: $util.oneOfGetter($oneOfFields = ["issuetype"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Fields _assignee.
             * @member {"assignee"|undefined} _assignee
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Object.defineProperty(Fields.prototype, "_assignee", {
                get: $util.oneOfGetter($oneOfFields = ["assignee"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Fields _reporter.
             * @member {"reporter"|undefined} _reporter
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Object.defineProperty(Fields.prototype, "_reporter", {
                get: $util.oneOfGetter($oneOfFields = ["reporter"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Fields _priority.
             * @member {"priority"|undefined} _priority
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Object.defineProperty(Fields.prototype, "_priority", {
                get: $util.oneOfGetter($oneOfFields = ["priority"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Fields _timetracking.
             * @member {"timetracking"|undefined} _timetracking
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Object.defineProperty(Fields.prototype, "_timetracking", {
                get: $util.oneOfGetter($oneOfFields = ["timetracking"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Fields _security.
             * @member {"security"|undefined} _security
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Object.defineProperty(Fields.prototype, "_security", {
                get: $util.oneOfGetter($oneOfFields = ["security"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Fields _environment.
             * @member {"environment"|undefined} _environment
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Object.defineProperty(Fields.prototype, "_environment", {
                get: $util.oneOfGetter($oneOfFields = ["environment"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Fields _description.
             * @member {"description"|undefined} _description
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Object.defineProperty(Fields.prototype, "_description", {
                get: $util.oneOfGetter($oneOfFields = ["description"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Fields _duedate.
             * @member {"duedate"|undefined} _duedate
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Object.defineProperty(Fields.prototype, "_duedate", {
                get: $util.oneOfGetter($oneOfFields = ["duedate"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Fields _parent.
             * @member {"parent"|undefined} _parent
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             */
            Object.defineProperty(Fields.prototype, "_parent", {
                get: $util.oneOfGetter($oneOfFields = ["parent"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new Fields instance using the specified properties.
             * @function create
             * @memberof jira.CreateIssueRequest.Fields
             * @static
             * @param {jira.CreateIssueRequest.IFields=} [properties] Properties to set
             * @returns {jira.CreateIssueRequest.Fields} Fields instance
             */
            Fields.create = function create(properties) {
                return new Fields(properties);
            };

            /**
             * Encodes the specified Fields message. Does not implicitly {@link jira.CreateIssueRequest.Fields.verify|verify} messages.
             * @function encode
             * @memberof jira.CreateIssueRequest.Fields
             * @static
             * @param {jira.CreateIssueRequest.IFields} message Fields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Fields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.project != null && Object.hasOwnProperty.call(message, "project"))
                    $root.jira.Project.encode(message.project, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.summary != null && Object.hasOwnProperty.call(message, "summary"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.summary);
                if (message.issuetype != null && Object.hasOwnProperty.call(message, "issuetype"))
                    $root.jira.IssueType.encode(message.issuetype, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.assignee != null && Object.hasOwnProperty.call(message, "assignee"))
                    $root.jira.Assignee.encode(message.assignee, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.reporter != null && Object.hasOwnProperty.call(message, "reporter"))
                    $root.jira.Reporter.encode(message.reporter, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                    $root.jira.Priority.encode(message.priority, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.labels != null && message.labels.length)
                    for (var i = 0; i < message.labels.length; ++i)
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.labels[i]);
                if (message.timetracking != null && Object.hasOwnProperty.call(message, "timetracking"))
                    $root.jira.TimeTracking.encode(message.timetracking, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.security != null && Object.hasOwnProperty.call(message, "security"))
                    $root.jira.Security.encode(message.security, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.versions != null && message.versions.length)
                    for (var i = 0; i < message.versions.length; ++i)
                        $root.jira.Version.encode(message.versions[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.environment != null && Object.hasOwnProperty.call(message, "environment"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.environment);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.description);
                if (message.duedate != null && Object.hasOwnProperty.call(message, "duedate"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.duedate);
                if (message.fixVersions != null && message.fixVersions.length)
                    for (var i = 0; i < message.fixVersions.length; ++i)
                        $root.jira.Version.encode(message.fixVersions[i], writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.components != null && message.components.length)
                    for (var i = 0; i < message.components.length; ++i)
                        $root.jira.Component.encode(message.components[i], writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.parent != null && Object.hasOwnProperty.call(message, "parent"))
                    $root.jira.Parent.encode(message.parent, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Fields message, length delimited. Does not implicitly {@link jira.CreateIssueRequest.Fields.verify|verify} messages.
             * @function encodeDelimited
             * @memberof jira.CreateIssueRequest.Fields
             * @static
             * @param {jira.CreateIssueRequest.IFields} message Fields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Fields.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Fields message from the specified reader or buffer.
             * @function decode
             * @memberof jira.CreateIssueRequest.Fields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {jira.CreateIssueRequest.Fields} Fields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Fields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.CreateIssueRequest.Fields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.project = $root.jira.Project.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.summary = reader.string();
                            break;
                        }
                    case 3: {
                            message.issuetype = $root.jira.IssueType.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.assignee = $root.jira.Assignee.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.reporter = $root.jira.Reporter.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.priority = $root.jira.Priority.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            if (!(message.labels && message.labels.length))
                                message.labels = [];
                            message.labels.push(reader.string());
                            break;
                        }
                    case 8: {
                            message.timetracking = $root.jira.TimeTracking.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.security = $root.jira.Security.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            if (!(message.versions && message.versions.length))
                                message.versions = [];
                            message.versions.push($root.jira.Version.decode(reader, reader.uint32()));
                            break;
                        }
                    case 11: {
                            message.environment = reader.string();
                            break;
                        }
                    case 12: {
                            message.description = reader.string();
                            break;
                        }
                    case 13: {
                            message.duedate = reader.string();
                            break;
                        }
                    case 14: {
                            if (!(message.fixVersions && message.fixVersions.length))
                                message.fixVersions = [];
                            message.fixVersions.push($root.jira.Version.decode(reader, reader.uint32()));
                            break;
                        }
                    case 15: {
                            if (!(message.components && message.components.length))
                                message.components = [];
                            message.components.push($root.jira.Component.decode(reader, reader.uint32()));
                            break;
                        }
                    case 16: {
                            message.parent = $root.jira.Parent.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Fields message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof jira.CreateIssueRequest.Fields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {jira.CreateIssueRequest.Fields} Fields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Fields.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Fields message.
             * @function verify
             * @memberof jira.CreateIssueRequest.Fields
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Fields.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.project != null && message.hasOwnProperty("project")) {
                    properties._project = 1;
                    {
                        var error = $root.jira.Project.verify(message.project);
                        if (error)
                            return "project." + error;
                    }
                }
                if (message.summary != null && message.hasOwnProperty("summary")) {
                    properties._summary = 1;
                    if (!$util.isString(message.summary))
                        return "summary: string expected";
                }
                if (message.issuetype != null && message.hasOwnProperty("issuetype")) {
                    properties._issuetype = 1;
                    {
                        var error = $root.jira.IssueType.verify(message.issuetype);
                        if (error)
                            return "issuetype." + error;
                    }
                }
                if (message.assignee != null && message.hasOwnProperty("assignee")) {
                    properties._assignee = 1;
                    {
                        var error = $root.jira.Assignee.verify(message.assignee);
                        if (error)
                            return "assignee." + error;
                    }
                }
                if (message.reporter != null && message.hasOwnProperty("reporter")) {
                    properties._reporter = 1;
                    {
                        var error = $root.jira.Reporter.verify(message.reporter);
                        if (error)
                            return "reporter." + error;
                    }
                }
                if (message.priority != null && message.hasOwnProperty("priority")) {
                    properties._priority = 1;
                    {
                        var error = $root.jira.Priority.verify(message.priority);
                        if (error)
                            return "priority." + error;
                    }
                }
                if (message.labels != null && message.hasOwnProperty("labels")) {
                    if (!Array.isArray(message.labels))
                        return "labels: array expected";
                    for (var i = 0; i < message.labels.length; ++i)
                        if (!$util.isString(message.labels[i]))
                            return "labels: string[] expected";
                }
                if (message.timetracking != null && message.hasOwnProperty("timetracking")) {
                    properties._timetracking = 1;
                    {
                        var error = $root.jira.TimeTracking.verify(message.timetracking);
                        if (error)
                            return "timetracking." + error;
                    }
                }
                if (message.security != null && message.hasOwnProperty("security")) {
                    properties._security = 1;
                    {
                        var error = $root.jira.Security.verify(message.security);
                        if (error)
                            return "security." + error;
                    }
                }
                if (message.versions != null && message.hasOwnProperty("versions")) {
                    if (!Array.isArray(message.versions))
                        return "versions: array expected";
                    for (var i = 0; i < message.versions.length; ++i) {
                        var error = $root.jira.Version.verify(message.versions[i]);
                        if (error)
                            return "versions." + error;
                    }
                }
                if (message.environment != null && message.hasOwnProperty("environment")) {
                    properties._environment = 1;
                    if (!$util.isString(message.environment))
                        return "environment: string expected";
                }
                if (message.description != null && message.hasOwnProperty("description")) {
                    properties._description = 1;
                    if (!$util.isString(message.description))
                        return "description: string expected";
                }
                if (message.duedate != null && message.hasOwnProperty("duedate")) {
                    properties._duedate = 1;
                    if (!$util.isString(message.duedate))
                        return "duedate: string expected";
                }
                if (message.fixVersions != null && message.hasOwnProperty("fixVersions")) {
                    if (!Array.isArray(message.fixVersions))
                        return "fixVersions: array expected";
                    for (var i = 0; i < message.fixVersions.length; ++i) {
                        var error = $root.jira.Version.verify(message.fixVersions[i]);
                        if (error)
                            return "fixVersions." + error;
                    }
                }
                if (message.components != null && message.hasOwnProperty("components")) {
                    if (!Array.isArray(message.components))
                        return "components: array expected";
                    for (var i = 0; i < message.components.length; ++i) {
                        var error = $root.jira.Component.verify(message.components[i]);
                        if (error)
                            return "components." + error;
                    }
                }
                if (message.parent != null && message.hasOwnProperty("parent")) {
                    properties._parent = 1;
                    {
                        var error = $root.jira.Parent.verify(message.parent);
                        if (error)
                            return "parent." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Fields message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof jira.CreateIssueRequest.Fields
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {jira.CreateIssueRequest.Fields} Fields
             */
            Fields.fromObject = function fromObject(object) {
                if (object instanceof $root.jira.CreateIssueRequest.Fields)
                    return object;
                var message = new $root.jira.CreateIssueRequest.Fields();
                if (object.project != null) {
                    if (typeof object.project !== "object")
                        throw TypeError(".jira.CreateIssueRequest.Fields.project: object expected");
                    message.project = $root.jira.Project.fromObject(object.project);
                }
                if (object.summary != null)
                    message.summary = String(object.summary);
                if (object.issuetype != null) {
                    if (typeof object.issuetype !== "object")
                        throw TypeError(".jira.CreateIssueRequest.Fields.issuetype: object expected");
                    message.issuetype = $root.jira.IssueType.fromObject(object.issuetype);
                }
                if (object.assignee != null) {
                    if (typeof object.assignee !== "object")
                        throw TypeError(".jira.CreateIssueRequest.Fields.assignee: object expected");
                    message.assignee = $root.jira.Assignee.fromObject(object.assignee);
                }
                if (object.reporter != null) {
                    if (typeof object.reporter !== "object")
                        throw TypeError(".jira.CreateIssueRequest.Fields.reporter: object expected");
                    message.reporter = $root.jira.Reporter.fromObject(object.reporter);
                }
                if (object.priority != null) {
                    if (typeof object.priority !== "object")
                        throw TypeError(".jira.CreateIssueRequest.Fields.priority: object expected");
                    message.priority = $root.jira.Priority.fromObject(object.priority);
                }
                if (object.labels) {
                    if (!Array.isArray(object.labels))
                        throw TypeError(".jira.CreateIssueRequest.Fields.labels: array expected");
                    message.labels = [];
                    for (var i = 0; i < object.labels.length; ++i)
                        message.labels[i] = String(object.labels[i]);
                }
                if (object.timetracking != null) {
                    if (typeof object.timetracking !== "object")
                        throw TypeError(".jira.CreateIssueRequest.Fields.timetracking: object expected");
                    message.timetracking = $root.jira.TimeTracking.fromObject(object.timetracking);
                }
                if (object.security != null) {
                    if (typeof object.security !== "object")
                        throw TypeError(".jira.CreateIssueRequest.Fields.security: object expected");
                    message.security = $root.jira.Security.fromObject(object.security);
                }
                if (object.versions) {
                    if (!Array.isArray(object.versions))
                        throw TypeError(".jira.CreateIssueRequest.Fields.versions: array expected");
                    message.versions = [];
                    for (var i = 0; i < object.versions.length; ++i) {
                        if (typeof object.versions[i] !== "object")
                            throw TypeError(".jira.CreateIssueRequest.Fields.versions: object expected");
                        message.versions[i] = $root.jira.Version.fromObject(object.versions[i]);
                    }
                }
                if (object.environment != null)
                    message.environment = String(object.environment);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.duedate != null)
                    message.duedate = String(object.duedate);
                if (object.fixVersions) {
                    if (!Array.isArray(object.fixVersions))
                        throw TypeError(".jira.CreateIssueRequest.Fields.fixVersions: array expected");
                    message.fixVersions = [];
                    for (var i = 0; i < object.fixVersions.length; ++i) {
                        if (typeof object.fixVersions[i] !== "object")
                            throw TypeError(".jira.CreateIssueRequest.Fields.fixVersions: object expected");
                        message.fixVersions[i] = $root.jira.Version.fromObject(object.fixVersions[i]);
                    }
                }
                if (object.components) {
                    if (!Array.isArray(object.components))
                        throw TypeError(".jira.CreateIssueRequest.Fields.components: array expected");
                    message.components = [];
                    for (var i = 0; i < object.components.length; ++i) {
                        if (typeof object.components[i] !== "object")
                            throw TypeError(".jira.CreateIssueRequest.Fields.components: object expected");
                        message.components[i] = $root.jira.Component.fromObject(object.components[i]);
                    }
                }
                if (object.parent != null) {
                    if (typeof object.parent !== "object")
                        throw TypeError(".jira.CreateIssueRequest.Fields.parent: object expected");
                    message.parent = $root.jira.Parent.fromObject(object.parent);
                }
                return message;
            };

            /**
             * Creates a plain object from a Fields message. Also converts values to other types if specified.
             * @function toObject
             * @memberof jira.CreateIssueRequest.Fields
             * @static
             * @param {jira.CreateIssueRequest.Fields} message Fields
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Fields.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.labels = [];
                    object.versions = [];
                    object.fixVersions = [];
                    object.components = [];
                }
                if (message.project != null && message.hasOwnProperty("project")) {
                    object.project = $root.jira.Project.toObject(message.project, options);
                    if (options.oneofs)
                        object._project = "project";
                }
                if (message.summary != null && message.hasOwnProperty("summary")) {
                    object.summary = message.summary;
                    if (options.oneofs)
                        object._summary = "summary";
                }
                if (message.issuetype != null && message.hasOwnProperty("issuetype")) {
                    object.issuetype = $root.jira.IssueType.toObject(message.issuetype, options);
                    if (options.oneofs)
                        object._issuetype = "issuetype";
                }
                if (message.assignee != null && message.hasOwnProperty("assignee")) {
                    object.assignee = $root.jira.Assignee.toObject(message.assignee, options);
                    if (options.oneofs)
                        object._assignee = "assignee";
                }
                if (message.reporter != null && message.hasOwnProperty("reporter")) {
                    object.reporter = $root.jira.Reporter.toObject(message.reporter, options);
                    if (options.oneofs)
                        object._reporter = "reporter";
                }
                if (message.priority != null && message.hasOwnProperty("priority")) {
                    object.priority = $root.jira.Priority.toObject(message.priority, options);
                    if (options.oneofs)
                        object._priority = "priority";
                }
                if (message.labels && message.labels.length) {
                    object.labels = [];
                    for (var j = 0; j < message.labels.length; ++j)
                        object.labels[j] = message.labels[j];
                }
                if (message.timetracking != null && message.hasOwnProperty("timetracking")) {
                    object.timetracking = $root.jira.TimeTracking.toObject(message.timetracking, options);
                    if (options.oneofs)
                        object._timetracking = "timetracking";
                }
                if (message.security != null && message.hasOwnProperty("security")) {
                    object.security = $root.jira.Security.toObject(message.security, options);
                    if (options.oneofs)
                        object._security = "security";
                }
                if (message.versions && message.versions.length) {
                    object.versions = [];
                    for (var j = 0; j < message.versions.length; ++j)
                        object.versions[j] = $root.jira.Version.toObject(message.versions[j], options);
                }
                if (message.environment != null && message.hasOwnProperty("environment")) {
                    object.environment = message.environment;
                    if (options.oneofs)
                        object._environment = "environment";
                }
                if (message.description != null && message.hasOwnProperty("description")) {
                    object.description = message.description;
                    if (options.oneofs)
                        object._description = "description";
                }
                if (message.duedate != null && message.hasOwnProperty("duedate")) {
                    object.duedate = message.duedate;
                    if (options.oneofs)
                        object._duedate = "duedate";
                }
                if (message.fixVersions && message.fixVersions.length) {
                    object.fixVersions = [];
                    for (var j = 0; j < message.fixVersions.length; ++j)
                        object.fixVersions[j] = $root.jira.Version.toObject(message.fixVersions[j], options);
                }
                if (message.components && message.components.length) {
                    object.components = [];
                    for (var j = 0; j < message.components.length; ++j)
                        object.components[j] = $root.jira.Component.toObject(message.components[j], options);
                }
                if (message.parent != null && message.hasOwnProperty("parent")) {
                    object.parent = $root.jira.Parent.toObject(message.parent, options);
                    if (options.oneofs)
                        object._parent = "parent";
                }
                return object;
            };

            /**
             * Converts this Fields to JSON.
             * @function toJSON
             * @memberof jira.CreateIssueRequest.Fields
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Fields.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Fields
             * @function getTypeUrl
             * @memberof jira.CreateIssueRequest.Fields
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Fields.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/jira.CreateIssueRequest.Fields";
            };

            return Fields;
        })();

        return CreateIssueRequest;
    })();

    jira.CreateIssueResponse = (function() {

        /**
         * Properties of a CreateIssueResponse.
         * @memberof jira
         * @interface ICreateIssueResponse
         * @property {string|null} [id] CreateIssueResponse id
         * @property {string|null} [key] CreateIssueResponse key
         * @property {string|null} [self] CreateIssueResponse self
         * @property {jira.IErrorResponse|null} [projectLeoError] CreateIssueResponse projectLeoError
         */

        /**
         * Constructs a new CreateIssueResponse.
         * @memberof jira
         * @classdesc Represents a CreateIssueResponse.
         * @implements ICreateIssueResponse
         * @constructor
         * @param {jira.ICreateIssueResponse=} [properties] Properties to set
         */
        function CreateIssueResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateIssueResponse id.
         * @member {string|null|undefined} id
         * @memberof jira.CreateIssueResponse
         * @instance
         */
        CreateIssueResponse.prototype.id = null;

        /**
         * CreateIssueResponse key.
         * @member {string|null|undefined} key
         * @memberof jira.CreateIssueResponse
         * @instance
         */
        CreateIssueResponse.prototype.key = null;

        /**
         * CreateIssueResponse self.
         * @member {string|null|undefined} self
         * @memberof jira.CreateIssueResponse
         * @instance
         */
        CreateIssueResponse.prototype.self = null;

        /**
         * CreateIssueResponse projectLeoError.
         * @member {jira.IErrorResponse|null|undefined} projectLeoError
         * @memberof jira.CreateIssueResponse
         * @instance
         */
        CreateIssueResponse.prototype.projectLeoError = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * CreateIssueResponse _id.
         * @member {"id"|undefined} _id
         * @memberof jira.CreateIssueResponse
         * @instance
         */
        Object.defineProperty(CreateIssueResponse.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreateIssueResponse _key.
         * @member {"key"|undefined} _key
         * @memberof jira.CreateIssueResponse
         * @instance
         */
        Object.defineProperty(CreateIssueResponse.prototype, "_key", {
            get: $util.oneOfGetter($oneOfFields = ["key"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreateIssueResponse _self.
         * @member {"self"|undefined} _self
         * @memberof jira.CreateIssueResponse
         * @instance
         */
        Object.defineProperty(CreateIssueResponse.prototype, "_self", {
            get: $util.oneOfGetter($oneOfFields = ["self"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreateIssueResponse _projectLeoError.
         * @member {"projectLeoError"|undefined} _projectLeoError
         * @memberof jira.CreateIssueResponse
         * @instance
         */
        Object.defineProperty(CreateIssueResponse.prototype, "_projectLeoError", {
            get: $util.oneOfGetter($oneOfFields = ["projectLeoError"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CreateIssueResponse instance using the specified properties.
         * @function create
         * @memberof jira.CreateIssueResponse
         * @static
         * @param {jira.ICreateIssueResponse=} [properties] Properties to set
         * @returns {jira.CreateIssueResponse} CreateIssueResponse instance
         */
        CreateIssueResponse.create = function create(properties) {
            return new CreateIssueResponse(properties);
        };

        /**
         * Encodes the specified CreateIssueResponse message. Does not implicitly {@link jira.CreateIssueResponse.verify|verify} messages.
         * @function encode
         * @memberof jira.CreateIssueResponse
         * @static
         * @param {jira.ICreateIssueResponse} message CreateIssueResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateIssueResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
            if (message.self != null && Object.hasOwnProperty.call(message, "self"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.self);
            if (message.projectLeoError != null && Object.hasOwnProperty.call(message, "projectLeoError"))
                $root.jira.ErrorResponse.encode(message.projectLeoError, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateIssueResponse message, length delimited. Does not implicitly {@link jira.CreateIssueResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof jira.CreateIssueResponse
         * @static
         * @param {jira.ICreateIssueResponse} message CreateIssueResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateIssueResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateIssueResponse message from the specified reader or buffer.
         * @function decode
         * @memberof jira.CreateIssueResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {jira.CreateIssueResponse} CreateIssueResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateIssueResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.jira.CreateIssueResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.key = reader.string();
                        break;
                    }
                case 3: {
                        message.self = reader.string();
                        break;
                    }
                case 4: {
                        message.projectLeoError = $root.jira.ErrorResponse.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateIssueResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof jira.CreateIssueResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {jira.CreateIssueResponse} CreateIssueResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateIssueResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateIssueResponse message.
         * @function verify
         * @memberof jira.CreateIssueResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateIssueResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isString(message.id))
                    return "id: string expected";
            }
            if (message.key != null && message.hasOwnProperty("key")) {
                properties._key = 1;
                if (!$util.isString(message.key))
                    return "key: string expected";
            }
            if (message.self != null && message.hasOwnProperty("self")) {
                properties._self = 1;
                if (!$util.isString(message.self))
                    return "self: string expected";
            }
            if (message.projectLeoError != null && message.hasOwnProperty("projectLeoError")) {
                properties._projectLeoError = 1;
                {
                    var error = $root.jira.ErrorResponse.verify(message.projectLeoError);
                    if (error)
                        return "projectLeoError." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CreateIssueResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof jira.CreateIssueResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {jira.CreateIssueResponse} CreateIssueResponse
         */
        CreateIssueResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.jira.CreateIssueResponse)
                return object;
            var message = new $root.jira.CreateIssueResponse();
            if (object.id != null)
                message.id = String(object.id);
            if (object.key != null)
                message.key = String(object.key);
            if (object.self != null)
                message.self = String(object.self);
            if (object.projectLeoError != null) {
                if (typeof object.projectLeoError !== "object")
                    throw TypeError(".jira.CreateIssueResponse.projectLeoError: object expected");
                message.projectLeoError = $root.jira.ErrorResponse.fromObject(object.projectLeoError);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateIssueResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof jira.CreateIssueResponse
         * @static
         * @param {jira.CreateIssueResponse} message CreateIssueResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateIssueResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.key != null && message.hasOwnProperty("key")) {
                object.key = message.key;
                if (options.oneofs)
                    object._key = "key";
            }
            if (message.self != null && message.hasOwnProperty("self")) {
                object.self = message.self;
                if (options.oneofs)
                    object._self = "self";
            }
            if (message.projectLeoError != null && message.hasOwnProperty("projectLeoError")) {
                object.projectLeoError = $root.jira.ErrorResponse.toObject(message.projectLeoError, options);
                if (options.oneofs)
                    object._projectLeoError = "projectLeoError";
            }
            return object;
        };

        /**
         * Converts this CreateIssueResponse to JSON.
         * @function toJSON
         * @memberof jira.CreateIssueResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateIssueResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateIssueResponse
         * @function getTypeUrl
         * @memberof jira.CreateIssueResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateIssueResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/jira.CreateIssueResponse";
        };

        return CreateIssueResponse;
    })();

    return jira;
})();

$root.mailjet = (function() {

    /**
     * Namespace mailjet.
     * @exports mailjet
     * @namespace
     */
    var mailjet = {};

    /**
     * MailjetSendResponseStatus enum.
     * @name mailjet.MailjetSendResponseStatus
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} success=1 success value
     * @property {number} error=2 error value
     */
    mailjet.MailjetSendResponseStatus = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "success"] = 1;
        values[valuesById[2] = "error"] = 2;
        return values;
    })();

    mailjet.MailjetSendRequest = (function() {

        /**
         * Properties of a MailjetSendRequest.
         * @memberof mailjet
         * @interface IMailjetSendRequest
         * @property {boolean|null} [SandboxMode] MailjetSendRequest SandboxMode
         * @property {boolean|null} [AdvanceErrorHandling] MailjetSendRequest AdvanceErrorHandling
         * @property {Array.<mailjet.IMailjetSendRequestMessage>|null} [messages] MailjetSendRequest messages
         */

        /**
         * Constructs a new MailjetSendRequest.
         * @memberof mailjet
         * @classdesc Represents a MailjetSendRequest.
         * @implements IMailjetSendRequest
         * @constructor
         * @param {mailjet.IMailjetSendRequest=} [properties] Properties to set
         */
        function MailjetSendRequest(properties) {
            this.messages = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MailjetSendRequest SandboxMode.
         * @member {boolean|null|undefined} SandboxMode
         * @memberof mailjet.MailjetSendRequest
         * @instance
         */
        MailjetSendRequest.prototype.SandboxMode = null;

        /**
         * MailjetSendRequest AdvanceErrorHandling.
         * @member {boolean|null|undefined} AdvanceErrorHandling
         * @memberof mailjet.MailjetSendRequest
         * @instance
         */
        MailjetSendRequest.prototype.AdvanceErrorHandling = null;

        /**
         * MailjetSendRequest messages.
         * @member {Array.<mailjet.IMailjetSendRequestMessage>} messages
         * @memberof mailjet.MailjetSendRequest
         * @instance
         */
        MailjetSendRequest.prototype.messages = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * MailjetSendRequest _SandboxMode.
         * @member {"SandboxMode"|undefined} _SandboxMode
         * @memberof mailjet.MailjetSendRequest
         * @instance
         */
        Object.defineProperty(MailjetSendRequest.prototype, "_SandboxMode", {
            get: $util.oneOfGetter($oneOfFields = ["SandboxMode"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MailjetSendRequest _AdvanceErrorHandling.
         * @member {"AdvanceErrorHandling"|undefined} _AdvanceErrorHandling
         * @memberof mailjet.MailjetSendRequest
         * @instance
         */
        Object.defineProperty(MailjetSendRequest.prototype, "_AdvanceErrorHandling", {
            get: $util.oneOfGetter($oneOfFields = ["AdvanceErrorHandling"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MailjetSendRequest instance using the specified properties.
         * @function create
         * @memberof mailjet.MailjetSendRequest
         * @static
         * @param {mailjet.IMailjetSendRequest=} [properties] Properties to set
         * @returns {mailjet.MailjetSendRequest} MailjetSendRequest instance
         */
        MailjetSendRequest.create = function create(properties) {
            return new MailjetSendRequest(properties);
        };

        /**
         * Encodes the specified MailjetSendRequest message. Does not implicitly {@link mailjet.MailjetSendRequest.verify|verify} messages.
         * @function encode
         * @memberof mailjet.MailjetSendRequest
         * @static
         * @param {mailjet.IMailjetSendRequest} message MailjetSendRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.SandboxMode != null && Object.hasOwnProperty.call(message, "SandboxMode"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.SandboxMode);
            if (message.AdvanceErrorHandling != null && Object.hasOwnProperty.call(message, "AdvanceErrorHandling"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.AdvanceErrorHandling);
            if (message.messages != null && message.messages.length)
                for (var i = 0; i < message.messages.length; ++i)
                    $root.mailjet.MailjetSendRequestMessage.encode(message.messages[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MailjetSendRequest message, length delimited. Does not implicitly {@link mailjet.MailjetSendRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mailjet.MailjetSendRequest
         * @static
         * @param {mailjet.IMailjetSendRequest} message MailjetSendRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MailjetSendRequest message from the specified reader or buffer.
         * @function decode
         * @memberof mailjet.MailjetSendRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mailjet.MailjetSendRequest} MailjetSendRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mailjet.MailjetSendRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.SandboxMode = reader.bool();
                        break;
                    }
                case 2: {
                        message.AdvanceErrorHandling = reader.bool();
                        break;
                    }
                case 3: {
                        if (!(message.messages && message.messages.length))
                            message.messages = [];
                        message.messages.push($root.mailjet.MailjetSendRequestMessage.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MailjetSendRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mailjet.MailjetSendRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mailjet.MailjetSendRequest} MailjetSendRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MailjetSendRequest message.
         * @function verify
         * @memberof mailjet.MailjetSendRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MailjetSendRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.SandboxMode != null && message.hasOwnProperty("SandboxMode")) {
                properties._SandboxMode = 1;
                if (typeof message.SandboxMode !== "boolean")
                    return "SandboxMode: boolean expected";
            }
            if (message.AdvanceErrorHandling != null && message.hasOwnProperty("AdvanceErrorHandling")) {
                properties._AdvanceErrorHandling = 1;
                if (typeof message.AdvanceErrorHandling !== "boolean")
                    return "AdvanceErrorHandling: boolean expected";
            }
            if (message.messages != null && message.hasOwnProperty("messages")) {
                if (!Array.isArray(message.messages))
                    return "messages: array expected";
                for (var i = 0; i < message.messages.length; ++i) {
                    var error = $root.mailjet.MailjetSendRequestMessage.verify(message.messages[i]);
                    if (error)
                        return "messages." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MailjetSendRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mailjet.MailjetSendRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mailjet.MailjetSendRequest} MailjetSendRequest
         */
        MailjetSendRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.mailjet.MailjetSendRequest)
                return object;
            var message = new $root.mailjet.MailjetSendRequest();
            if (object.SandboxMode != null)
                message.SandboxMode = Boolean(object.SandboxMode);
            if (object.AdvanceErrorHandling != null)
                message.AdvanceErrorHandling = Boolean(object.AdvanceErrorHandling);
            if (object.messages) {
                if (!Array.isArray(object.messages))
                    throw TypeError(".mailjet.MailjetSendRequest.messages: array expected");
                message.messages = [];
                for (var i = 0; i < object.messages.length; ++i) {
                    if (typeof object.messages[i] !== "object")
                        throw TypeError(".mailjet.MailjetSendRequest.messages: object expected");
                    message.messages[i] = $root.mailjet.MailjetSendRequestMessage.fromObject(object.messages[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a MailjetSendRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mailjet.MailjetSendRequest
         * @static
         * @param {mailjet.MailjetSendRequest} message MailjetSendRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MailjetSendRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.messages = [];
            if (message.SandboxMode != null && message.hasOwnProperty("SandboxMode")) {
                object.SandboxMode = message.SandboxMode;
                if (options.oneofs)
                    object._SandboxMode = "SandboxMode";
            }
            if (message.AdvanceErrorHandling != null && message.hasOwnProperty("AdvanceErrorHandling")) {
                object.AdvanceErrorHandling = message.AdvanceErrorHandling;
                if (options.oneofs)
                    object._AdvanceErrorHandling = "AdvanceErrorHandling";
            }
            if (message.messages && message.messages.length) {
                object.messages = [];
                for (var j = 0; j < message.messages.length; ++j)
                    object.messages[j] = $root.mailjet.MailjetSendRequestMessage.toObject(message.messages[j], options);
            }
            return object;
        };

        /**
         * Converts this MailjetSendRequest to JSON.
         * @function toJSON
         * @memberof mailjet.MailjetSendRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MailjetSendRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MailjetSendRequest
         * @function getTypeUrl
         * @memberof mailjet.MailjetSendRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MailjetSendRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mailjet.MailjetSendRequest";
        };

        return MailjetSendRequest;
    })();

    mailjet.MailjetSendResponse = (function() {

        /**
         * Properties of a MailjetSendResponse.
         * @memberof mailjet
         * @interface IMailjetSendResponse
         * @property {Array.<mailjet.IMailjetSendResponseMessage>|null} [Messages] MailjetSendResponse Messages
         */

        /**
         * Constructs a new MailjetSendResponse.
         * @memberof mailjet
         * @classdesc Represents a MailjetSendResponse.
         * @implements IMailjetSendResponse
         * @constructor
         * @param {mailjet.IMailjetSendResponse=} [properties] Properties to set
         */
        function MailjetSendResponse(properties) {
            this.Messages = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MailjetSendResponse Messages.
         * @member {Array.<mailjet.IMailjetSendResponseMessage>} Messages
         * @memberof mailjet.MailjetSendResponse
         * @instance
         */
        MailjetSendResponse.prototype.Messages = $util.emptyArray;

        /**
         * Creates a new MailjetSendResponse instance using the specified properties.
         * @function create
         * @memberof mailjet.MailjetSendResponse
         * @static
         * @param {mailjet.IMailjetSendResponse=} [properties] Properties to set
         * @returns {mailjet.MailjetSendResponse} MailjetSendResponse instance
         */
        MailjetSendResponse.create = function create(properties) {
            return new MailjetSendResponse(properties);
        };

        /**
         * Encodes the specified MailjetSendResponse message. Does not implicitly {@link mailjet.MailjetSendResponse.verify|verify} messages.
         * @function encode
         * @memberof mailjet.MailjetSendResponse
         * @static
         * @param {mailjet.IMailjetSendResponse} message MailjetSendResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Messages != null && message.Messages.length)
                for (var i = 0; i < message.Messages.length; ++i)
                    $root.mailjet.MailjetSendResponseMessage.encode(message.Messages[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MailjetSendResponse message, length delimited. Does not implicitly {@link mailjet.MailjetSendResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mailjet.MailjetSendResponse
         * @static
         * @param {mailjet.IMailjetSendResponse} message MailjetSendResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MailjetSendResponse message from the specified reader or buffer.
         * @function decode
         * @memberof mailjet.MailjetSendResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mailjet.MailjetSendResponse} MailjetSendResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mailjet.MailjetSendResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.Messages && message.Messages.length))
                            message.Messages = [];
                        message.Messages.push($root.mailjet.MailjetSendResponseMessage.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MailjetSendResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mailjet.MailjetSendResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mailjet.MailjetSendResponse} MailjetSendResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MailjetSendResponse message.
         * @function verify
         * @memberof mailjet.MailjetSendResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MailjetSendResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Messages != null && message.hasOwnProperty("Messages")) {
                if (!Array.isArray(message.Messages))
                    return "Messages: array expected";
                for (var i = 0; i < message.Messages.length; ++i) {
                    var error = $root.mailjet.MailjetSendResponseMessage.verify(message.Messages[i]);
                    if (error)
                        return "Messages." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MailjetSendResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mailjet.MailjetSendResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mailjet.MailjetSendResponse} MailjetSendResponse
         */
        MailjetSendResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.mailjet.MailjetSendResponse)
                return object;
            var message = new $root.mailjet.MailjetSendResponse();
            if (object.Messages) {
                if (!Array.isArray(object.Messages))
                    throw TypeError(".mailjet.MailjetSendResponse.Messages: array expected");
                message.Messages = [];
                for (var i = 0; i < object.Messages.length; ++i) {
                    if (typeof object.Messages[i] !== "object")
                        throw TypeError(".mailjet.MailjetSendResponse.Messages: object expected");
                    message.Messages[i] = $root.mailjet.MailjetSendResponseMessage.fromObject(object.Messages[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a MailjetSendResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mailjet.MailjetSendResponse
         * @static
         * @param {mailjet.MailjetSendResponse} message MailjetSendResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MailjetSendResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.Messages = [];
            if (message.Messages && message.Messages.length) {
                object.Messages = [];
                for (var j = 0; j < message.Messages.length; ++j)
                    object.Messages[j] = $root.mailjet.MailjetSendResponseMessage.toObject(message.Messages[j], options);
            }
            return object;
        };

        /**
         * Converts this MailjetSendResponse to JSON.
         * @function toJSON
         * @memberof mailjet.MailjetSendResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MailjetSendResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MailjetSendResponse
         * @function getTypeUrl
         * @memberof mailjet.MailjetSendResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MailjetSendResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mailjet.MailjetSendResponse";
        };

        return MailjetSendResponse;
    })();

    mailjet.MailjetSendRequestMessage = (function() {

        /**
         * Properties of a MailjetSendRequestMessage.
         * @memberof mailjet
         * @interface IMailjetSendRequestMessage
         * @property {mailjet.IMailjetSendRequestFrom|null} [From] MailjetSendRequestMessage From
         * @property {Array.<mailjet.IMailjetSendRequestTo>|null} [To] MailjetSendRequestMessage To
         * @property {mailjet.IMailjetSendRequestReplyTo|null} [ReplyTo] MailjetSendRequestMessage ReplyTo
         * @property {string|null} [Subject] MailjetSendRequestMessage Subject
         * @property {string|null} [TextPart] MailjetSendRequestMessage TextPart
         * @property {string|null} [HTMLPart] MailjetSendRequestMessage HTMLPart
         * @property {string|null} [CustomID] MailjetSendRequestMessage CustomID
         */

        /**
         * Constructs a new MailjetSendRequestMessage.
         * @memberof mailjet
         * @classdesc Represents a MailjetSendRequestMessage.
         * @implements IMailjetSendRequestMessage
         * @constructor
         * @param {mailjet.IMailjetSendRequestMessage=} [properties] Properties to set
         */
        function MailjetSendRequestMessage(properties) {
            this.To = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MailjetSendRequestMessage From.
         * @member {mailjet.IMailjetSendRequestFrom|null|undefined} From
         * @memberof mailjet.MailjetSendRequestMessage
         * @instance
         */
        MailjetSendRequestMessage.prototype.From = null;

        /**
         * MailjetSendRequestMessage To.
         * @member {Array.<mailjet.IMailjetSendRequestTo>} To
         * @memberof mailjet.MailjetSendRequestMessage
         * @instance
         */
        MailjetSendRequestMessage.prototype.To = $util.emptyArray;

        /**
         * MailjetSendRequestMessage ReplyTo.
         * @member {mailjet.IMailjetSendRequestReplyTo|null|undefined} ReplyTo
         * @memberof mailjet.MailjetSendRequestMessage
         * @instance
         */
        MailjetSendRequestMessage.prototype.ReplyTo = null;

        /**
         * MailjetSendRequestMessage Subject.
         * @member {string|null|undefined} Subject
         * @memberof mailjet.MailjetSendRequestMessage
         * @instance
         */
        MailjetSendRequestMessage.prototype.Subject = null;

        /**
         * MailjetSendRequestMessage TextPart.
         * @member {string|null|undefined} TextPart
         * @memberof mailjet.MailjetSendRequestMessage
         * @instance
         */
        MailjetSendRequestMessage.prototype.TextPart = null;

        /**
         * MailjetSendRequestMessage HTMLPart.
         * @member {string|null|undefined} HTMLPart
         * @memberof mailjet.MailjetSendRequestMessage
         * @instance
         */
        MailjetSendRequestMessage.prototype.HTMLPart = null;

        /**
         * MailjetSendRequestMessage CustomID.
         * @member {string|null|undefined} CustomID
         * @memberof mailjet.MailjetSendRequestMessage
         * @instance
         */
        MailjetSendRequestMessage.prototype.CustomID = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * MailjetSendRequestMessage _ReplyTo.
         * @member {"ReplyTo"|undefined} _ReplyTo
         * @memberof mailjet.MailjetSendRequestMessage
         * @instance
         */
        Object.defineProperty(MailjetSendRequestMessage.prototype, "_ReplyTo", {
            get: $util.oneOfGetter($oneOfFields = ["ReplyTo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MailjetSendRequestMessage _Subject.
         * @member {"Subject"|undefined} _Subject
         * @memberof mailjet.MailjetSendRequestMessage
         * @instance
         */
        Object.defineProperty(MailjetSendRequestMessage.prototype, "_Subject", {
            get: $util.oneOfGetter($oneOfFields = ["Subject"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MailjetSendRequestMessage _TextPart.
         * @member {"TextPart"|undefined} _TextPart
         * @memberof mailjet.MailjetSendRequestMessage
         * @instance
         */
        Object.defineProperty(MailjetSendRequestMessage.prototype, "_TextPart", {
            get: $util.oneOfGetter($oneOfFields = ["TextPart"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MailjetSendRequestMessage _HTMLPart.
         * @member {"HTMLPart"|undefined} _HTMLPart
         * @memberof mailjet.MailjetSendRequestMessage
         * @instance
         */
        Object.defineProperty(MailjetSendRequestMessage.prototype, "_HTMLPart", {
            get: $util.oneOfGetter($oneOfFields = ["HTMLPart"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MailjetSendRequestMessage _CustomID.
         * @member {"CustomID"|undefined} _CustomID
         * @memberof mailjet.MailjetSendRequestMessage
         * @instance
         */
        Object.defineProperty(MailjetSendRequestMessage.prototype, "_CustomID", {
            get: $util.oneOfGetter($oneOfFields = ["CustomID"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MailjetSendRequestMessage instance using the specified properties.
         * @function create
         * @memberof mailjet.MailjetSendRequestMessage
         * @static
         * @param {mailjet.IMailjetSendRequestMessage=} [properties] Properties to set
         * @returns {mailjet.MailjetSendRequestMessage} MailjetSendRequestMessage instance
         */
        MailjetSendRequestMessage.create = function create(properties) {
            return new MailjetSendRequestMessage(properties);
        };

        /**
         * Encodes the specified MailjetSendRequestMessage message. Does not implicitly {@link mailjet.MailjetSendRequestMessage.verify|verify} messages.
         * @function encode
         * @memberof mailjet.MailjetSendRequestMessage
         * @static
         * @param {mailjet.IMailjetSendRequestMessage} message MailjetSendRequestMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendRequestMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.From != null && Object.hasOwnProperty.call(message, "From"))
                $root.mailjet.MailjetSendRequestFrom.encode(message.From, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.To != null && message.To.length)
                for (var i = 0; i < message.To.length; ++i)
                    $root.mailjet.MailjetSendRequestTo.encode(message.To[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.ReplyTo != null && Object.hasOwnProperty.call(message, "ReplyTo"))
                $root.mailjet.MailjetSendRequestReplyTo.encode(message.ReplyTo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.Subject != null && Object.hasOwnProperty.call(message, "Subject"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.Subject);
            if (message.TextPart != null && Object.hasOwnProperty.call(message, "TextPart"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.TextPart);
            if (message.HTMLPart != null && Object.hasOwnProperty.call(message, "HTMLPart"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.HTMLPart);
            if (message.CustomID != null && Object.hasOwnProperty.call(message, "CustomID"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.CustomID);
            return writer;
        };

        /**
         * Encodes the specified MailjetSendRequestMessage message, length delimited. Does not implicitly {@link mailjet.MailjetSendRequestMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mailjet.MailjetSendRequestMessage
         * @static
         * @param {mailjet.IMailjetSendRequestMessage} message MailjetSendRequestMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendRequestMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MailjetSendRequestMessage message from the specified reader or buffer.
         * @function decode
         * @memberof mailjet.MailjetSendRequestMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mailjet.MailjetSendRequestMessage} MailjetSendRequestMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendRequestMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mailjet.MailjetSendRequestMessage();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.From = $root.mailjet.MailjetSendRequestFrom.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        if (!(message.To && message.To.length))
                            message.To = [];
                        message.To.push($root.mailjet.MailjetSendRequestTo.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.ReplyTo = $root.mailjet.MailjetSendRequestReplyTo.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.Subject = reader.string();
                        break;
                    }
                case 5: {
                        message.TextPart = reader.string();
                        break;
                    }
                case 6: {
                        message.HTMLPart = reader.string();
                        break;
                    }
                case 7: {
                        message.CustomID = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MailjetSendRequestMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mailjet.MailjetSendRequestMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mailjet.MailjetSendRequestMessage} MailjetSendRequestMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendRequestMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MailjetSendRequestMessage message.
         * @function verify
         * @memberof mailjet.MailjetSendRequestMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MailjetSendRequestMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.From != null && message.hasOwnProperty("From")) {
                var error = $root.mailjet.MailjetSendRequestFrom.verify(message.From);
                if (error)
                    return "From." + error;
            }
            if (message.To != null && message.hasOwnProperty("To")) {
                if (!Array.isArray(message.To))
                    return "To: array expected";
                for (var i = 0; i < message.To.length; ++i) {
                    var error = $root.mailjet.MailjetSendRequestTo.verify(message.To[i]);
                    if (error)
                        return "To." + error;
                }
            }
            if (message.ReplyTo != null && message.hasOwnProperty("ReplyTo")) {
                properties._ReplyTo = 1;
                {
                    var error = $root.mailjet.MailjetSendRequestReplyTo.verify(message.ReplyTo);
                    if (error)
                        return "ReplyTo." + error;
                }
            }
            if (message.Subject != null && message.hasOwnProperty("Subject")) {
                properties._Subject = 1;
                if (!$util.isString(message.Subject))
                    return "Subject: string expected";
            }
            if (message.TextPart != null && message.hasOwnProperty("TextPart")) {
                properties._TextPart = 1;
                if (!$util.isString(message.TextPart))
                    return "TextPart: string expected";
            }
            if (message.HTMLPart != null && message.hasOwnProperty("HTMLPart")) {
                properties._HTMLPart = 1;
                if (!$util.isString(message.HTMLPart))
                    return "HTMLPart: string expected";
            }
            if (message.CustomID != null && message.hasOwnProperty("CustomID")) {
                properties._CustomID = 1;
                if (!$util.isString(message.CustomID))
                    return "CustomID: string expected";
            }
            return null;
        };

        /**
         * Creates a MailjetSendRequestMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mailjet.MailjetSendRequestMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mailjet.MailjetSendRequestMessage} MailjetSendRequestMessage
         */
        MailjetSendRequestMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.mailjet.MailjetSendRequestMessage)
                return object;
            var message = new $root.mailjet.MailjetSendRequestMessage();
            if (object.From != null) {
                if (typeof object.From !== "object")
                    throw TypeError(".mailjet.MailjetSendRequestMessage.From: object expected");
                message.From = $root.mailjet.MailjetSendRequestFrom.fromObject(object.From);
            }
            if (object.To) {
                if (!Array.isArray(object.To))
                    throw TypeError(".mailjet.MailjetSendRequestMessage.To: array expected");
                message.To = [];
                for (var i = 0; i < object.To.length; ++i) {
                    if (typeof object.To[i] !== "object")
                        throw TypeError(".mailjet.MailjetSendRequestMessage.To: object expected");
                    message.To[i] = $root.mailjet.MailjetSendRequestTo.fromObject(object.To[i]);
                }
            }
            if (object.ReplyTo != null) {
                if (typeof object.ReplyTo !== "object")
                    throw TypeError(".mailjet.MailjetSendRequestMessage.ReplyTo: object expected");
                message.ReplyTo = $root.mailjet.MailjetSendRequestReplyTo.fromObject(object.ReplyTo);
            }
            if (object.Subject != null)
                message.Subject = String(object.Subject);
            if (object.TextPart != null)
                message.TextPart = String(object.TextPart);
            if (object.HTMLPart != null)
                message.HTMLPart = String(object.HTMLPart);
            if (object.CustomID != null)
                message.CustomID = String(object.CustomID);
            return message;
        };

        /**
         * Creates a plain object from a MailjetSendRequestMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mailjet.MailjetSendRequestMessage
         * @static
         * @param {mailjet.MailjetSendRequestMessage} message MailjetSendRequestMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MailjetSendRequestMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.To = [];
            if (options.defaults)
                object.From = null;
            if (message.From != null && message.hasOwnProperty("From"))
                object.From = $root.mailjet.MailjetSendRequestFrom.toObject(message.From, options);
            if (message.To && message.To.length) {
                object.To = [];
                for (var j = 0; j < message.To.length; ++j)
                    object.To[j] = $root.mailjet.MailjetSendRequestTo.toObject(message.To[j], options);
            }
            if (message.ReplyTo != null && message.hasOwnProperty("ReplyTo")) {
                object.ReplyTo = $root.mailjet.MailjetSendRequestReplyTo.toObject(message.ReplyTo, options);
                if (options.oneofs)
                    object._ReplyTo = "ReplyTo";
            }
            if (message.Subject != null && message.hasOwnProperty("Subject")) {
                object.Subject = message.Subject;
                if (options.oneofs)
                    object._Subject = "Subject";
            }
            if (message.TextPart != null && message.hasOwnProperty("TextPart")) {
                object.TextPart = message.TextPart;
                if (options.oneofs)
                    object._TextPart = "TextPart";
            }
            if (message.HTMLPart != null && message.hasOwnProperty("HTMLPart")) {
                object.HTMLPart = message.HTMLPart;
                if (options.oneofs)
                    object._HTMLPart = "HTMLPart";
            }
            if (message.CustomID != null && message.hasOwnProperty("CustomID")) {
                object.CustomID = message.CustomID;
                if (options.oneofs)
                    object._CustomID = "CustomID";
            }
            return object;
        };

        /**
         * Converts this MailjetSendRequestMessage to JSON.
         * @function toJSON
         * @memberof mailjet.MailjetSendRequestMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MailjetSendRequestMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MailjetSendRequestMessage
         * @function getTypeUrl
         * @memberof mailjet.MailjetSendRequestMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MailjetSendRequestMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mailjet.MailjetSendRequestMessage";
        };

        return MailjetSendRequestMessage;
    })();

    mailjet.MailjetSendRequestFrom = (function() {

        /**
         * Properties of a MailjetSendRequestFrom.
         * @memberof mailjet
         * @interface IMailjetSendRequestFrom
         * @property {string|null} [Email] MailjetSendRequestFrom Email
         * @property {string|null} [Name] MailjetSendRequestFrom Name
         */

        /**
         * Constructs a new MailjetSendRequestFrom.
         * @memberof mailjet
         * @classdesc Represents a MailjetSendRequestFrom.
         * @implements IMailjetSendRequestFrom
         * @constructor
         * @param {mailjet.IMailjetSendRequestFrom=} [properties] Properties to set
         */
        function MailjetSendRequestFrom(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MailjetSendRequestFrom Email.
         * @member {string|null|undefined} Email
         * @memberof mailjet.MailjetSendRequestFrom
         * @instance
         */
        MailjetSendRequestFrom.prototype.Email = null;

        /**
         * MailjetSendRequestFrom Name.
         * @member {string|null|undefined} Name
         * @memberof mailjet.MailjetSendRequestFrom
         * @instance
         */
        MailjetSendRequestFrom.prototype.Name = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * MailjetSendRequestFrom _Name.
         * @member {"Name"|undefined} _Name
         * @memberof mailjet.MailjetSendRequestFrom
         * @instance
         */
        Object.defineProperty(MailjetSendRequestFrom.prototype, "_Name", {
            get: $util.oneOfGetter($oneOfFields = ["Name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MailjetSendRequestFrom instance using the specified properties.
         * @function create
         * @memberof mailjet.MailjetSendRequestFrom
         * @static
         * @param {mailjet.IMailjetSendRequestFrom=} [properties] Properties to set
         * @returns {mailjet.MailjetSendRequestFrom} MailjetSendRequestFrom instance
         */
        MailjetSendRequestFrom.create = function create(properties) {
            return new MailjetSendRequestFrom(properties);
        };

        /**
         * Encodes the specified MailjetSendRequestFrom message. Does not implicitly {@link mailjet.MailjetSendRequestFrom.verify|verify} messages.
         * @function encode
         * @memberof mailjet.MailjetSendRequestFrom
         * @static
         * @param {mailjet.IMailjetSendRequestFrom} message MailjetSendRequestFrom message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendRequestFrom.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Email != null && Object.hasOwnProperty.call(message, "Email"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.Email);
            if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.Name);
            return writer;
        };

        /**
         * Encodes the specified MailjetSendRequestFrom message, length delimited. Does not implicitly {@link mailjet.MailjetSendRequestFrom.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mailjet.MailjetSendRequestFrom
         * @static
         * @param {mailjet.IMailjetSendRequestFrom} message MailjetSendRequestFrom message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendRequestFrom.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MailjetSendRequestFrom message from the specified reader or buffer.
         * @function decode
         * @memberof mailjet.MailjetSendRequestFrom
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mailjet.MailjetSendRequestFrom} MailjetSendRequestFrom
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendRequestFrom.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mailjet.MailjetSendRequestFrom();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.Email = reader.string();
                        break;
                    }
                case 2: {
                        message.Name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MailjetSendRequestFrom message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mailjet.MailjetSendRequestFrom
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mailjet.MailjetSendRequestFrom} MailjetSendRequestFrom
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendRequestFrom.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MailjetSendRequestFrom message.
         * @function verify
         * @memberof mailjet.MailjetSendRequestFrom
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MailjetSendRequestFrom.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.Email != null && message.hasOwnProperty("Email"))
                if (!$util.isString(message.Email))
                    return "Email: string expected";
            if (message.Name != null && message.hasOwnProperty("Name")) {
                properties._Name = 1;
                if (!$util.isString(message.Name))
                    return "Name: string expected";
            }
            return null;
        };

        /**
         * Creates a MailjetSendRequestFrom message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mailjet.MailjetSendRequestFrom
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mailjet.MailjetSendRequestFrom} MailjetSendRequestFrom
         */
        MailjetSendRequestFrom.fromObject = function fromObject(object) {
            if (object instanceof $root.mailjet.MailjetSendRequestFrom)
                return object;
            var message = new $root.mailjet.MailjetSendRequestFrom();
            if (object.Email != null)
                message.Email = String(object.Email);
            if (object.Name != null)
                message.Name = String(object.Name);
            return message;
        };

        /**
         * Creates a plain object from a MailjetSendRequestFrom message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mailjet.MailjetSendRequestFrom
         * @static
         * @param {mailjet.MailjetSendRequestFrom} message MailjetSendRequestFrom
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MailjetSendRequestFrom.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.Email = "";
            if (message.Email != null && message.hasOwnProperty("Email"))
                object.Email = message.Email;
            if (message.Name != null && message.hasOwnProperty("Name")) {
                object.Name = message.Name;
                if (options.oneofs)
                    object._Name = "Name";
            }
            return object;
        };

        /**
         * Converts this MailjetSendRequestFrom to JSON.
         * @function toJSON
         * @memberof mailjet.MailjetSendRequestFrom
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MailjetSendRequestFrom.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MailjetSendRequestFrom
         * @function getTypeUrl
         * @memberof mailjet.MailjetSendRequestFrom
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MailjetSendRequestFrom.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mailjet.MailjetSendRequestFrom";
        };

        return MailjetSendRequestFrom;
    })();

    mailjet.MailjetSendRequestTo = (function() {

        /**
         * Properties of a MailjetSendRequestTo.
         * @memberof mailjet
         * @interface IMailjetSendRequestTo
         * @property {string|null} [Email] MailjetSendRequestTo Email
         * @property {string|null} [Name] MailjetSendRequestTo Name
         */

        /**
         * Constructs a new MailjetSendRequestTo.
         * @memberof mailjet
         * @classdesc Represents a MailjetSendRequestTo.
         * @implements IMailjetSendRequestTo
         * @constructor
         * @param {mailjet.IMailjetSendRequestTo=} [properties] Properties to set
         */
        function MailjetSendRequestTo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MailjetSendRequestTo Email.
         * @member {string|null|undefined} Email
         * @memberof mailjet.MailjetSendRequestTo
         * @instance
         */
        MailjetSendRequestTo.prototype.Email = null;

        /**
         * MailjetSendRequestTo Name.
         * @member {string|null|undefined} Name
         * @memberof mailjet.MailjetSendRequestTo
         * @instance
         */
        MailjetSendRequestTo.prototype.Name = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * MailjetSendRequestTo _Name.
         * @member {"Name"|undefined} _Name
         * @memberof mailjet.MailjetSendRequestTo
         * @instance
         */
        Object.defineProperty(MailjetSendRequestTo.prototype, "_Name", {
            get: $util.oneOfGetter($oneOfFields = ["Name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MailjetSendRequestTo instance using the specified properties.
         * @function create
         * @memberof mailjet.MailjetSendRequestTo
         * @static
         * @param {mailjet.IMailjetSendRequestTo=} [properties] Properties to set
         * @returns {mailjet.MailjetSendRequestTo} MailjetSendRequestTo instance
         */
        MailjetSendRequestTo.create = function create(properties) {
            return new MailjetSendRequestTo(properties);
        };

        /**
         * Encodes the specified MailjetSendRequestTo message. Does not implicitly {@link mailjet.MailjetSendRequestTo.verify|verify} messages.
         * @function encode
         * @memberof mailjet.MailjetSendRequestTo
         * @static
         * @param {mailjet.IMailjetSendRequestTo} message MailjetSendRequestTo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendRequestTo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Email != null && Object.hasOwnProperty.call(message, "Email"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.Email);
            if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.Name);
            return writer;
        };

        /**
         * Encodes the specified MailjetSendRequestTo message, length delimited. Does not implicitly {@link mailjet.MailjetSendRequestTo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mailjet.MailjetSendRequestTo
         * @static
         * @param {mailjet.IMailjetSendRequestTo} message MailjetSendRequestTo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendRequestTo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MailjetSendRequestTo message from the specified reader or buffer.
         * @function decode
         * @memberof mailjet.MailjetSendRequestTo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mailjet.MailjetSendRequestTo} MailjetSendRequestTo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendRequestTo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mailjet.MailjetSendRequestTo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.Email = reader.string();
                        break;
                    }
                case 2: {
                        message.Name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MailjetSendRequestTo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mailjet.MailjetSendRequestTo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mailjet.MailjetSendRequestTo} MailjetSendRequestTo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendRequestTo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MailjetSendRequestTo message.
         * @function verify
         * @memberof mailjet.MailjetSendRequestTo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MailjetSendRequestTo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.Email != null && message.hasOwnProperty("Email"))
                if (!$util.isString(message.Email))
                    return "Email: string expected";
            if (message.Name != null && message.hasOwnProperty("Name")) {
                properties._Name = 1;
                if (!$util.isString(message.Name))
                    return "Name: string expected";
            }
            return null;
        };

        /**
         * Creates a MailjetSendRequestTo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mailjet.MailjetSendRequestTo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mailjet.MailjetSendRequestTo} MailjetSendRequestTo
         */
        MailjetSendRequestTo.fromObject = function fromObject(object) {
            if (object instanceof $root.mailjet.MailjetSendRequestTo)
                return object;
            var message = new $root.mailjet.MailjetSendRequestTo();
            if (object.Email != null)
                message.Email = String(object.Email);
            if (object.Name != null)
                message.Name = String(object.Name);
            return message;
        };

        /**
         * Creates a plain object from a MailjetSendRequestTo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mailjet.MailjetSendRequestTo
         * @static
         * @param {mailjet.MailjetSendRequestTo} message MailjetSendRequestTo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MailjetSendRequestTo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.Email = "";
            if (message.Email != null && message.hasOwnProperty("Email"))
                object.Email = message.Email;
            if (message.Name != null && message.hasOwnProperty("Name")) {
                object.Name = message.Name;
                if (options.oneofs)
                    object._Name = "Name";
            }
            return object;
        };

        /**
         * Converts this MailjetSendRequestTo to JSON.
         * @function toJSON
         * @memberof mailjet.MailjetSendRequestTo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MailjetSendRequestTo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MailjetSendRequestTo
         * @function getTypeUrl
         * @memberof mailjet.MailjetSendRequestTo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MailjetSendRequestTo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mailjet.MailjetSendRequestTo";
        };

        return MailjetSendRequestTo;
    })();

    mailjet.MailjetSendRequestReplyTo = (function() {

        /**
         * Properties of a MailjetSendRequestReplyTo.
         * @memberof mailjet
         * @interface IMailjetSendRequestReplyTo
         * @property {string|null} [Email] MailjetSendRequestReplyTo Email
         * @property {string|null} [Name] MailjetSendRequestReplyTo Name
         */

        /**
         * Constructs a new MailjetSendRequestReplyTo.
         * @memberof mailjet
         * @classdesc Represents a MailjetSendRequestReplyTo.
         * @implements IMailjetSendRequestReplyTo
         * @constructor
         * @param {mailjet.IMailjetSendRequestReplyTo=} [properties] Properties to set
         */
        function MailjetSendRequestReplyTo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MailjetSendRequestReplyTo Email.
         * @member {string|null|undefined} Email
         * @memberof mailjet.MailjetSendRequestReplyTo
         * @instance
         */
        MailjetSendRequestReplyTo.prototype.Email = null;

        /**
         * MailjetSendRequestReplyTo Name.
         * @member {string|null|undefined} Name
         * @memberof mailjet.MailjetSendRequestReplyTo
         * @instance
         */
        MailjetSendRequestReplyTo.prototype.Name = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * MailjetSendRequestReplyTo _Name.
         * @member {"Name"|undefined} _Name
         * @memberof mailjet.MailjetSendRequestReplyTo
         * @instance
         */
        Object.defineProperty(MailjetSendRequestReplyTo.prototype, "_Name", {
            get: $util.oneOfGetter($oneOfFields = ["Name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MailjetSendRequestReplyTo instance using the specified properties.
         * @function create
         * @memberof mailjet.MailjetSendRequestReplyTo
         * @static
         * @param {mailjet.IMailjetSendRequestReplyTo=} [properties] Properties to set
         * @returns {mailjet.MailjetSendRequestReplyTo} MailjetSendRequestReplyTo instance
         */
        MailjetSendRequestReplyTo.create = function create(properties) {
            return new MailjetSendRequestReplyTo(properties);
        };

        /**
         * Encodes the specified MailjetSendRequestReplyTo message. Does not implicitly {@link mailjet.MailjetSendRequestReplyTo.verify|verify} messages.
         * @function encode
         * @memberof mailjet.MailjetSendRequestReplyTo
         * @static
         * @param {mailjet.IMailjetSendRequestReplyTo} message MailjetSendRequestReplyTo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendRequestReplyTo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Email != null && Object.hasOwnProperty.call(message, "Email"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.Email);
            if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.Name);
            return writer;
        };

        /**
         * Encodes the specified MailjetSendRequestReplyTo message, length delimited. Does not implicitly {@link mailjet.MailjetSendRequestReplyTo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mailjet.MailjetSendRequestReplyTo
         * @static
         * @param {mailjet.IMailjetSendRequestReplyTo} message MailjetSendRequestReplyTo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendRequestReplyTo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MailjetSendRequestReplyTo message from the specified reader or buffer.
         * @function decode
         * @memberof mailjet.MailjetSendRequestReplyTo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mailjet.MailjetSendRequestReplyTo} MailjetSendRequestReplyTo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendRequestReplyTo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mailjet.MailjetSendRequestReplyTo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.Email = reader.string();
                        break;
                    }
                case 2: {
                        message.Name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MailjetSendRequestReplyTo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mailjet.MailjetSendRequestReplyTo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mailjet.MailjetSendRequestReplyTo} MailjetSendRequestReplyTo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendRequestReplyTo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MailjetSendRequestReplyTo message.
         * @function verify
         * @memberof mailjet.MailjetSendRequestReplyTo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MailjetSendRequestReplyTo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.Email != null && message.hasOwnProperty("Email"))
                if (!$util.isString(message.Email))
                    return "Email: string expected";
            if (message.Name != null && message.hasOwnProperty("Name")) {
                properties._Name = 1;
                if (!$util.isString(message.Name))
                    return "Name: string expected";
            }
            return null;
        };

        /**
         * Creates a MailjetSendRequestReplyTo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mailjet.MailjetSendRequestReplyTo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mailjet.MailjetSendRequestReplyTo} MailjetSendRequestReplyTo
         */
        MailjetSendRequestReplyTo.fromObject = function fromObject(object) {
            if (object instanceof $root.mailjet.MailjetSendRequestReplyTo)
                return object;
            var message = new $root.mailjet.MailjetSendRequestReplyTo();
            if (object.Email != null)
                message.Email = String(object.Email);
            if (object.Name != null)
                message.Name = String(object.Name);
            return message;
        };

        /**
         * Creates a plain object from a MailjetSendRequestReplyTo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mailjet.MailjetSendRequestReplyTo
         * @static
         * @param {mailjet.MailjetSendRequestReplyTo} message MailjetSendRequestReplyTo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MailjetSendRequestReplyTo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.Email = "";
            if (message.Email != null && message.hasOwnProperty("Email"))
                object.Email = message.Email;
            if (message.Name != null && message.hasOwnProperty("Name")) {
                object.Name = message.Name;
                if (options.oneofs)
                    object._Name = "Name";
            }
            return object;
        };

        /**
         * Converts this MailjetSendRequestReplyTo to JSON.
         * @function toJSON
         * @memberof mailjet.MailjetSendRequestReplyTo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MailjetSendRequestReplyTo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MailjetSendRequestReplyTo
         * @function getTypeUrl
         * @memberof mailjet.MailjetSendRequestReplyTo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MailjetSendRequestReplyTo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mailjet.MailjetSendRequestReplyTo";
        };

        return MailjetSendRequestReplyTo;
    })();

    mailjet.MailjetSendResponseMessage = (function() {

        /**
         * Properties of a MailjetSendResponseMessage.
         * @memberof mailjet
         * @interface IMailjetSendResponseMessage
         * @property {mailjet.MailjetSendResponseStatus|null} [Status] MailjetSendResponseMessage Status
         * @property {Array.<mailjet.IMailjetSendResponseError>|null} [Errors] MailjetSendResponseMessage Errors
         * @property {string|null} [CustomID] MailjetSendResponseMessage CustomID
         * @property {Array.<mailjet.IMailjetSendResponseTo>|null} [To] MailjetSendResponseMessage To
         * @property {Array.<mailjet.IMailjetSendResponseCc>|null} [Cc] MailjetSendResponseMessage Cc
         * @property {Array.<mailjet.IMailjetSendResponseBcc>|null} [Bcc] MailjetSendResponseMessage Bcc
         */

        /**
         * Constructs a new MailjetSendResponseMessage.
         * @memberof mailjet
         * @classdesc Represents a MailjetSendResponseMessage.
         * @implements IMailjetSendResponseMessage
         * @constructor
         * @param {mailjet.IMailjetSendResponseMessage=} [properties] Properties to set
         */
        function MailjetSendResponseMessage(properties) {
            this.Errors = [];
            this.To = [];
            this.Cc = [];
            this.Bcc = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MailjetSendResponseMessage Status.
         * @member {mailjet.MailjetSendResponseStatus|null|undefined} Status
         * @memberof mailjet.MailjetSendResponseMessage
         * @instance
         */
        MailjetSendResponseMessage.prototype.Status = null;

        /**
         * MailjetSendResponseMessage Errors.
         * @member {Array.<mailjet.IMailjetSendResponseError>} Errors
         * @memberof mailjet.MailjetSendResponseMessage
         * @instance
         */
        MailjetSendResponseMessage.prototype.Errors = $util.emptyArray;

        /**
         * MailjetSendResponseMessage CustomID.
         * @member {string|null|undefined} CustomID
         * @memberof mailjet.MailjetSendResponseMessage
         * @instance
         */
        MailjetSendResponseMessage.prototype.CustomID = null;

        /**
         * MailjetSendResponseMessage To.
         * @member {Array.<mailjet.IMailjetSendResponseTo>} To
         * @memberof mailjet.MailjetSendResponseMessage
         * @instance
         */
        MailjetSendResponseMessage.prototype.To = $util.emptyArray;

        /**
         * MailjetSendResponseMessage Cc.
         * @member {Array.<mailjet.IMailjetSendResponseCc>} Cc
         * @memberof mailjet.MailjetSendResponseMessage
         * @instance
         */
        MailjetSendResponseMessage.prototype.Cc = $util.emptyArray;

        /**
         * MailjetSendResponseMessage Bcc.
         * @member {Array.<mailjet.IMailjetSendResponseBcc>} Bcc
         * @memberof mailjet.MailjetSendResponseMessage
         * @instance
         */
        MailjetSendResponseMessage.prototype.Bcc = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * MailjetSendResponseMessage _Status.
         * @member {"Status"|undefined} _Status
         * @memberof mailjet.MailjetSendResponseMessage
         * @instance
         */
        Object.defineProperty(MailjetSendResponseMessage.prototype, "_Status", {
            get: $util.oneOfGetter($oneOfFields = ["Status"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MailjetSendResponseMessage _CustomID.
         * @member {"CustomID"|undefined} _CustomID
         * @memberof mailjet.MailjetSendResponseMessage
         * @instance
         */
        Object.defineProperty(MailjetSendResponseMessage.prototype, "_CustomID", {
            get: $util.oneOfGetter($oneOfFields = ["CustomID"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MailjetSendResponseMessage instance using the specified properties.
         * @function create
         * @memberof mailjet.MailjetSendResponseMessage
         * @static
         * @param {mailjet.IMailjetSendResponseMessage=} [properties] Properties to set
         * @returns {mailjet.MailjetSendResponseMessage} MailjetSendResponseMessage instance
         */
        MailjetSendResponseMessage.create = function create(properties) {
            return new MailjetSendResponseMessage(properties);
        };

        /**
         * Encodes the specified MailjetSendResponseMessage message. Does not implicitly {@link mailjet.MailjetSendResponseMessage.verify|verify} messages.
         * @function encode
         * @memberof mailjet.MailjetSendResponseMessage
         * @static
         * @param {mailjet.IMailjetSendResponseMessage} message MailjetSendResponseMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendResponseMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Status != null && Object.hasOwnProperty.call(message, "Status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Status);
            if (message.Errors != null && message.Errors.length)
                for (var i = 0; i < message.Errors.length; ++i)
                    $root.mailjet.MailjetSendResponseError.encode(message.Errors[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.CustomID != null && Object.hasOwnProperty.call(message, "CustomID"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.CustomID);
            if (message.To != null && message.To.length)
                for (var i = 0; i < message.To.length; ++i)
                    $root.mailjet.MailjetSendResponseTo.encode(message.To[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.Cc != null && message.Cc.length)
                for (var i = 0; i < message.Cc.length; ++i)
                    $root.mailjet.MailjetSendResponseCc.encode(message.Cc[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.Bcc != null && message.Bcc.length)
                for (var i = 0; i < message.Bcc.length; ++i)
                    $root.mailjet.MailjetSendResponseBcc.encode(message.Bcc[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MailjetSendResponseMessage message, length delimited. Does not implicitly {@link mailjet.MailjetSendResponseMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mailjet.MailjetSendResponseMessage
         * @static
         * @param {mailjet.IMailjetSendResponseMessage} message MailjetSendResponseMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendResponseMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MailjetSendResponseMessage message from the specified reader or buffer.
         * @function decode
         * @memberof mailjet.MailjetSendResponseMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mailjet.MailjetSendResponseMessage} MailjetSendResponseMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendResponseMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mailjet.MailjetSendResponseMessage();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.Status = reader.int32();
                        break;
                    }
                case 2: {
                        if (!(message.Errors && message.Errors.length))
                            message.Errors = [];
                        message.Errors.push($root.mailjet.MailjetSendResponseError.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.CustomID = reader.string();
                        break;
                    }
                case 4: {
                        if (!(message.To && message.To.length))
                            message.To = [];
                        message.To.push($root.mailjet.MailjetSendResponseTo.decode(reader, reader.uint32()));
                        break;
                    }
                case 5: {
                        if (!(message.Cc && message.Cc.length))
                            message.Cc = [];
                        message.Cc.push($root.mailjet.MailjetSendResponseCc.decode(reader, reader.uint32()));
                        break;
                    }
                case 6: {
                        if (!(message.Bcc && message.Bcc.length))
                            message.Bcc = [];
                        message.Bcc.push($root.mailjet.MailjetSendResponseBcc.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MailjetSendResponseMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mailjet.MailjetSendResponseMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mailjet.MailjetSendResponseMessage} MailjetSendResponseMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendResponseMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MailjetSendResponseMessage message.
         * @function verify
         * @memberof mailjet.MailjetSendResponseMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MailjetSendResponseMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.Status != null && message.hasOwnProperty("Status")) {
                properties._Status = 1;
                switch (message.Status) {
                default:
                    return "Status: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            }
            if (message.Errors != null && message.hasOwnProperty("Errors")) {
                if (!Array.isArray(message.Errors))
                    return "Errors: array expected";
                for (var i = 0; i < message.Errors.length; ++i) {
                    var error = $root.mailjet.MailjetSendResponseError.verify(message.Errors[i]);
                    if (error)
                        return "Errors." + error;
                }
            }
            if (message.CustomID != null && message.hasOwnProperty("CustomID")) {
                properties._CustomID = 1;
                if (!$util.isString(message.CustomID))
                    return "CustomID: string expected";
            }
            if (message.To != null && message.hasOwnProperty("To")) {
                if (!Array.isArray(message.To))
                    return "To: array expected";
                for (var i = 0; i < message.To.length; ++i) {
                    var error = $root.mailjet.MailjetSendResponseTo.verify(message.To[i]);
                    if (error)
                        return "To." + error;
                }
            }
            if (message.Cc != null && message.hasOwnProperty("Cc")) {
                if (!Array.isArray(message.Cc))
                    return "Cc: array expected";
                for (var i = 0; i < message.Cc.length; ++i) {
                    var error = $root.mailjet.MailjetSendResponseCc.verify(message.Cc[i]);
                    if (error)
                        return "Cc." + error;
                }
            }
            if (message.Bcc != null && message.hasOwnProperty("Bcc")) {
                if (!Array.isArray(message.Bcc))
                    return "Bcc: array expected";
                for (var i = 0; i < message.Bcc.length; ++i) {
                    var error = $root.mailjet.MailjetSendResponseBcc.verify(message.Bcc[i]);
                    if (error)
                        return "Bcc." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MailjetSendResponseMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mailjet.MailjetSendResponseMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mailjet.MailjetSendResponseMessage} MailjetSendResponseMessage
         */
        MailjetSendResponseMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.mailjet.MailjetSendResponseMessage)
                return object;
            var message = new $root.mailjet.MailjetSendResponseMessage();
            switch (object.Status) {
            default:
                if (typeof object.Status === "number") {
                    message.Status = object.Status;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                message.Status = 0;
                break;
            case "success":
            case 1:
                message.Status = 1;
                break;
            case "error":
            case 2:
                message.Status = 2;
                break;
            }
            if (object.Errors) {
                if (!Array.isArray(object.Errors))
                    throw TypeError(".mailjet.MailjetSendResponseMessage.Errors: array expected");
                message.Errors = [];
                for (var i = 0; i < object.Errors.length; ++i) {
                    if (typeof object.Errors[i] !== "object")
                        throw TypeError(".mailjet.MailjetSendResponseMessage.Errors: object expected");
                    message.Errors[i] = $root.mailjet.MailjetSendResponseError.fromObject(object.Errors[i]);
                }
            }
            if (object.CustomID != null)
                message.CustomID = String(object.CustomID);
            if (object.To) {
                if (!Array.isArray(object.To))
                    throw TypeError(".mailjet.MailjetSendResponseMessage.To: array expected");
                message.To = [];
                for (var i = 0; i < object.To.length; ++i) {
                    if (typeof object.To[i] !== "object")
                        throw TypeError(".mailjet.MailjetSendResponseMessage.To: object expected");
                    message.To[i] = $root.mailjet.MailjetSendResponseTo.fromObject(object.To[i]);
                }
            }
            if (object.Cc) {
                if (!Array.isArray(object.Cc))
                    throw TypeError(".mailjet.MailjetSendResponseMessage.Cc: array expected");
                message.Cc = [];
                for (var i = 0; i < object.Cc.length; ++i) {
                    if (typeof object.Cc[i] !== "object")
                        throw TypeError(".mailjet.MailjetSendResponseMessage.Cc: object expected");
                    message.Cc[i] = $root.mailjet.MailjetSendResponseCc.fromObject(object.Cc[i]);
                }
            }
            if (object.Bcc) {
                if (!Array.isArray(object.Bcc))
                    throw TypeError(".mailjet.MailjetSendResponseMessage.Bcc: array expected");
                message.Bcc = [];
                for (var i = 0; i < object.Bcc.length; ++i) {
                    if (typeof object.Bcc[i] !== "object")
                        throw TypeError(".mailjet.MailjetSendResponseMessage.Bcc: object expected");
                    message.Bcc[i] = $root.mailjet.MailjetSendResponseBcc.fromObject(object.Bcc[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a MailjetSendResponseMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mailjet.MailjetSendResponseMessage
         * @static
         * @param {mailjet.MailjetSendResponseMessage} message MailjetSendResponseMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MailjetSendResponseMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.Errors = [];
                object.To = [];
                object.Cc = [];
                object.Bcc = [];
            }
            if (message.Status != null && message.hasOwnProperty("Status")) {
                object.Status = options.enums === String ? $root.mailjet.MailjetSendResponseStatus[message.Status] === undefined ? message.Status : $root.mailjet.MailjetSendResponseStatus[message.Status] : message.Status;
                if (options.oneofs)
                    object._Status = "Status";
            }
            if (message.Errors && message.Errors.length) {
                object.Errors = [];
                for (var j = 0; j < message.Errors.length; ++j)
                    object.Errors[j] = $root.mailjet.MailjetSendResponseError.toObject(message.Errors[j], options);
            }
            if (message.CustomID != null && message.hasOwnProperty("CustomID")) {
                object.CustomID = message.CustomID;
                if (options.oneofs)
                    object._CustomID = "CustomID";
            }
            if (message.To && message.To.length) {
                object.To = [];
                for (var j = 0; j < message.To.length; ++j)
                    object.To[j] = $root.mailjet.MailjetSendResponseTo.toObject(message.To[j], options);
            }
            if (message.Cc && message.Cc.length) {
                object.Cc = [];
                for (var j = 0; j < message.Cc.length; ++j)
                    object.Cc[j] = $root.mailjet.MailjetSendResponseCc.toObject(message.Cc[j], options);
            }
            if (message.Bcc && message.Bcc.length) {
                object.Bcc = [];
                for (var j = 0; j < message.Bcc.length; ++j)
                    object.Bcc[j] = $root.mailjet.MailjetSendResponseBcc.toObject(message.Bcc[j], options);
            }
            return object;
        };

        /**
         * Converts this MailjetSendResponseMessage to JSON.
         * @function toJSON
         * @memberof mailjet.MailjetSendResponseMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MailjetSendResponseMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MailjetSendResponseMessage
         * @function getTypeUrl
         * @memberof mailjet.MailjetSendResponseMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MailjetSendResponseMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mailjet.MailjetSendResponseMessage";
        };

        return MailjetSendResponseMessage;
    })();

    mailjet.MailjetSendResponseError = (function() {

        /**
         * Properties of a MailjetSendResponseError.
         * @memberof mailjet
         * @interface IMailjetSendResponseError
         * @property {string|null} [ErrorIdentifier] MailjetSendResponseError ErrorIdentifier
         * @property {string|null} [ErrorCode] MailjetSendResponseError ErrorCode
         * @property {number|null} [StatusCode] MailjetSendResponseError StatusCode
         * @property {string|null} [ErrorMessage] MailjetSendResponseError ErrorMessage
         * @property {string|null} [ErrorRelatedTo] MailjetSendResponseError ErrorRelatedTo
         */

        /**
         * Constructs a new MailjetSendResponseError.
         * @memberof mailjet
         * @classdesc Represents a MailjetSendResponseError.
         * @implements IMailjetSendResponseError
         * @constructor
         * @param {mailjet.IMailjetSendResponseError=} [properties] Properties to set
         */
        function MailjetSendResponseError(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MailjetSendResponseError ErrorIdentifier.
         * @member {string|null|undefined} ErrorIdentifier
         * @memberof mailjet.MailjetSendResponseError
         * @instance
         */
        MailjetSendResponseError.prototype.ErrorIdentifier = null;

        /**
         * MailjetSendResponseError ErrorCode.
         * @member {string|null|undefined} ErrorCode
         * @memberof mailjet.MailjetSendResponseError
         * @instance
         */
        MailjetSendResponseError.prototype.ErrorCode = null;

        /**
         * MailjetSendResponseError StatusCode.
         * @member {number|null|undefined} StatusCode
         * @memberof mailjet.MailjetSendResponseError
         * @instance
         */
        MailjetSendResponseError.prototype.StatusCode = null;

        /**
         * MailjetSendResponseError ErrorMessage.
         * @member {string|null|undefined} ErrorMessage
         * @memberof mailjet.MailjetSendResponseError
         * @instance
         */
        MailjetSendResponseError.prototype.ErrorMessage = null;

        /**
         * MailjetSendResponseError ErrorRelatedTo.
         * @member {string|null|undefined} ErrorRelatedTo
         * @memberof mailjet.MailjetSendResponseError
         * @instance
         */
        MailjetSendResponseError.prototype.ErrorRelatedTo = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * MailjetSendResponseError _ErrorIdentifier.
         * @member {"ErrorIdentifier"|undefined} _ErrorIdentifier
         * @memberof mailjet.MailjetSendResponseError
         * @instance
         */
        Object.defineProperty(MailjetSendResponseError.prototype, "_ErrorIdentifier", {
            get: $util.oneOfGetter($oneOfFields = ["ErrorIdentifier"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MailjetSendResponseError _ErrorCode.
         * @member {"ErrorCode"|undefined} _ErrorCode
         * @memberof mailjet.MailjetSendResponseError
         * @instance
         */
        Object.defineProperty(MailjetSendResponseError.prototype, "_ErrorCode", {
            get: $util.oneOfGetter($oneOfFields = ["ErrorCode"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MailjetSendResponseError _StatusCode.
         * @member {"StatusCode"|undefined} _StatusCode
         * @memberof mailjet.MailjetSendResponseError
         * @instance
         */
        Object.defineProperty(MailjetSendResponseError.prototype, "_StatusCode", {
            get: $util.oneOfGetter($oneOfFields = ["StatusCode"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MailjetSendResponseError _ErrorMessage.
         * @member {"ErrorMessage"|undefined} _ErrorMessage
         * @memberof mailjet.MailjetSendResponseError
         * @instance
         */
        Object.defineProperty(MailjetSendResponseError.prototype, "_ErrorMessage", {
            get: $util.oneOfGetter($oneOfFields = ["ErrorMessage"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MailjetSendResponseError _ErrorRelatedTo.
         * @member {"ErrorRelatedTo"|undefined} _ErrorRelatedTo
         * @memberof mailjet.MailjetSendResponseError
         * @instance
         */
        Object.defineProperty(MailjetSendResponseError.prototype, "_ErrorRelatedTo", {
            get: $util.oneOfGetter($oneOfFields = ["ErrorRelatedTo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MailjetSendResponseError instance using the specified properties.
         * @function create
         * @memberof mailjet.MailjetSendResponseError
         * @static
         * @param {mailjet.IMailjetSendResponseError=} [properties] Properties to set
         * @returns {mailjet.MailjetSendResponseError} MailjetSendResponseError instance
         */
        MailjetSendResponseError.create = function create(properties) {
            return new MailjetSendResponseError(properties);
        };

        /**
         * Encodes the specified MailjetSendResponseError message. Does not implicitly {@link mailjet.MailjetSendResponseError.verify|verify} messages.
         * @function encode
         * @memberof mailjet.MailjetSendResponseError
         * @static
         * @param {mailjet.IMailjetSendResponseError} message MailjetSendResponseError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendResponseError.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ErrorIdentifier != null && Object.hasOwnProperty.call(message, "ErrorIdentifier"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ErrorIdentifier);
            if (message.ErrorCode != null && Object.hasOwnProperty.call(message, "ErrorCode"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.ErrorCode);
            if (message.StatusCode != null && Object.hasOwnProperty.call(message, "StatusCode"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.StatusCode);
            if (message.ErrorMessage != null && Object.hasOwnProperty.call(message, "ErrorMessage"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.ErrorMessage);
            if (message.ErrorRelatedTo != null && Object.hasOwnProperty.call(message, "ErrorRelatedTo"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.ErrorRelatedTo);
            return writer;
        };

        /**
         * Encodes the specified MailjetSendResponseError message, length delimited. Does not implicitly {@link mailjet.MailjetSendResponseError.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mailjet.MailjetSendResponseError
         * @static
         * @param {mailjet.IMailjetSendResponseError} message MailjetSendResponseError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendResponseError.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MailjetSendResponseError message from the specified reader or buffer.
         * @function decode
         * @memberof mailjet.MailjetSendResponseError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mailjet.MailjetSendResponseError} MailjetSendResponseError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendResponseError.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mailjet.MailjetSendResponseError();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ErrorIdentifier = reader.string();
                        break;
                    }
                case 2: {
                        message.ErrorCode = reader.string();
                        break;
                    }
                case 3: {
                        message.StatusCode = reader.int32();
                        break;
                    }
                case 4: {
                        message.ErrorMessage = reader.string();
                        break;
                    }
                case 5: {
                        message.ErrorRelatedTo = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MailjetSendResponseError message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mailjet.MailjetSendResponseError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mailjet.MailjetSendResponseError} MailjetSendResponseError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendResponseError.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MailjetSendResponseError message.
         * @function verify
         * @memberof mailjet.MailjetSendResponseError
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MailjetSendResponseError.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.ErrorIdentifier != null && message.hasOwnProperty("ErrorIdentifier")) {
                properties._ErrorIdentifier = 1;
                if (!$util.isString(message.ErrorIdentifier))
                    return "ErrorIdentifier: string expected";
            }
            if (message.ErrorCode != null && message.hasOwnProperty("ErrorCode")) {
                properties._ErrorCode = 1;
                if (!$util.isString(message.ErrorCode))
                    return "ErrorCode: string expected";
            }
            if (message.StatusCode != null && message.hasOwnProperty("StatusCode")) {
                properties._StatusCode = 1;
                if (!$util.isInteger(message.StatusCode))
                    return "StatusCode: integer expected";
            }
            if (message.ErrorMessage != null && message.hasOwnProperty("ErrorMessage")) {
                properties._ErrorMessage = 1;
                if (!$util.isString(message.ErrorMessage))
                    return "ErrorMessage: string expected";
            }
            if (message.ErrorRelatedTo != null && message.hasOwnProperty("ErrorRelatedTo")) {
                properties._ErrorRelatedTo = 1;
                if (!$util.isString(message.ErrorRelatedTo))
                    return "ErrorRelatedTo: string expected";
            }
            return null;
        };

        /**
         * Creates a MailjetSendResponseError message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mailjet.MailjetSendResponseError
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mailjet.MailjetSendResponseError} MailjetSendResponseError
         */
        MailjetSendResponseError.fromObject = function fromObject(object) {
            if (object instanceof $root.mailjet.MailjetSendResponseError)
                return object;
            var message = new $root.mailjet.MailjetSendResponseError();
            if (object.ErrorIdentifier != null)
                message.ErrorIdentifier = String(object.ErrorIdentifier);
            if (object.ErrorCode != null)
                message.ErrorCode = String(object.ErrorCode);
            if (object.StatusCode != null)
                message.StatusCode = object.StatusCode | 0;
            if (object.ErrorMessage != null)
                message.ErrorMessage = String(object.ErrorMessage);
            if (object.ErrorRelatedTo != null)
                message.ErrorRelatedTo = String(object.ErrorRelatedTo);
            return message;
        };

        /**
         * Creates a plain object from a MailjetSendResponseError message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mailjet.MailjetSendResponseError
         * @static
         * @param {mailjet.MailjetSendResponseError} message MailjetSendResponseError
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MailjetSendResponseError.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.ErrorIdentifier != null && message.hasOwnProperty("ErrorIdentifier")) {
                object.ErrorIdentifier = message.ErrorIdentifier;
                if (options.oneofs)
                    object._ErrorIdentifier = "ErrorIdentifier";
            }
            if (message.ErrorCode != null && message.hasOwnProperty("ErrorCode")) {
                object.ErrorCode = message.ErrorCode;
                if (options.oneofs)
                    object._ErrorCode = "ErrorCode";
            }
            if (message.StatusCode != null && message.hasOwnProperty("StatusCode")) {
                object.StatusCode = message.StatusCode;
                if (options.oneofs)
                    object._StatusCode = "StatusCode";
            }
            if (message.ErrorMessage != null && message.hasOwnProperty("ErrorMessage")) {
                object.ErrorMessage = message.ErrorMessage;
                if (options.oneofs)
                    object._ErrorMessage = "ErrorMessage";
            }
            if (message.ErrorRelatedTo != null && message.hasOwnProperty("ErrorRelatedTo")) {
                object.ErrorRelatedTo = message.ErrorRelatedTo;
                if (options.oneofs)
                    object._ErrorRelatedTo = "ErrorRelatedTo";
            }
            return object;
        };

        /**
         * Converts this MailjetSendResponseError to JSON.
         * @function toJSON
         * @memberof mailjet.MailjetSendResponseError
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MailjetSendResponseError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MailjetSendResponseError
         * @function getTypeUrl
         * @memberof mailjet.MailjetSendResponseError
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MailjetSendResponseError.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mailjet.MailjetSendResponseError";
        };

        return MailjetSendResponseError;
    })();

    mailjet.MailjetSendResponseTo = (function() {

        /**
         * Properties of a MailjetSendResponseTo.
         * @memberof mailjet
         * @interface IMailjetSendResponseTo
         * @property {string|null} [Email] MailjetSendResponseTo Email
         * @property {string|null} [MessageUUID] MailjetSendResponseTo MessageUUID
         * @property {Long|null} [MessageID] MailjetSendResponseTo MessageID
         * @property {string|null} [MessageHref] MailjetSendResponseTo MessageHref
         */

        /**
         * Constructs a new MailjetSendResponseTo.
         * @memberof mailjet
         * @classdesc Represents a MailjetSendResponseTo.
         * @implements IMailjetSendResponseTo
         * @constructor
         * @param {mailjet.IMailjetSendResponseTo=} [properties] Properties to set
         */
        function MailjetSendResponseTo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MailjetSendResponseTo Email.
         * @member {string|null|undefined} Email
         * @memberof mailjet.MailjetSendResponseTo
         * @instance
         */
        MailjetSendResponseTo.prototype.Email = null;

        /**
         * MailjetSendResponseTo MessageUUID.
         * @member {string|null|undefined} MessageUUID
         * @memberof mailjet.MailjetSendResponseTo
         * @instance
         */
        MailjetSendResponseTo.prototype.MessageUUID = null;

        /**
         * MailjetSendResponseTo MessageID.
         * @member {Long|null|undefined} MessageID
         * @memberof mailjet.MailjetSendResponseTo
         * @instance
         */
        MailjetSendResponseTo.prototype.MessageID = null;

        /**
         * MailjetSendResponseTo MessageHref.
         * @member {string|null|undefined} MessageHref
         * @memberof mailjet.MailjetSendResponseTo
         * @instance
         */
        MailjetSendResponseTo.prototype.MessageHref = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * MailjetSendResponseTo _Email.
         * @member {"Email"|undefined} _Email
         * @memberof mailjet.MailjetSendResponseTo
         * @instance
         */
        Object.defineProperty(MailjetSendResponseTo.prototype, "_Email", {
            get: $util.oneOfGetter($oneOfFields = ["Email"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MailjetSendResponseTo _MessageUUID.
         * @member {"MessageUUID"|undefined} _MessageUUID
         * @memberof mailjet.MailjetSendResponseTo
         * @instance
         */
        Object.defineProperty(MailjetSendResponseTo.prototype, "_MessageUUID", {
            get: $util.oneOfGetter($oneOfFields = ["MessageUUID"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MailjetSendResponseTo _MessageID.
         * @member {"MessageID"|undefined} _MessageID
         * @memberof mailjet.MailjetSendResponseTo
         * @instance
         */
        Object.defineProperty(MailjetSendResponseTo.prototype, "_MessageID", {
            get: $util.oneOfGetter($oneOfFields = ["MessageID"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MailjetSendResponseTo _MessageHref.
         * @member {"MessageHref"|undefined} _MessageHref
         * @memberof mailjet.MailjetSendResponseTo
         * @instance
         */
        Object.defineProperty(MailjetSendResponseTo.prototype, "_MessageHref", {
            get: $util.oneOfGetter($oneOfFields = ["MessageHref"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MailjetSendResponseTo instance using the specified properties.
         * @function create
         * @memberof mailjet.MailjetSendResponseTo
         * @static
         * @param {mailjet.IMailjetSendResponseTo=} [properties] Properties to set
         * @returns {mailjet.MailjetSendResponseTo} MailjetSendResponseTo instance
         */
        MailjetSendResponseTo.create = function create(properties) {
            return new MailjetSendResponseTo(properties);
        };

        /**
         * Encodes the specified MailjetSendResponseTo message. Does not implicitly {@link mailjet.MailjetSendResponseTo.verify|verify} messages.
         * @function encode
         * @memberof mailjet.MailjetSendResponseTo
         * @static
         * @param {mailjet.IMailjetSendResponseTo} message MailjetSendResponseTo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendResponseTo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Email != null && Object.hasOwnProperty.call(message, "Email"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.Email);
            if (message.MessageUUID != null && Object.hasOwnProperty.call(message, "MessageUUID"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.MessageUUID);
            if (message.MessageID != null && Object.hasOwnProperty.call(message, "MessageID"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.MessageID);
            if (message.MessageHref != null && Object.hasOwnProperty.call(message, "MessageHref"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.MessageHref);
            return writer;
        };

        /**
         * Encodes the specified MailjetSendResponseTo message, length delimited. Does not implicitly {@link mailjet.MailjetSendResponseTo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mailjet.MailjetSendResponseTo
         * @static
         * @param {mailjet.IMailjetSendResponseTo} message MailjetSendResponseTo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendResponseTo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MailjetSendResponseTo message from the specified reader or buffer.
         * @function decode
         * @memberof mailjet.MailjetSendResponseTo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mailjet.MailjetSendResponseTo} MailjetSendResponseTo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendResponseTo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mailjet.MailjetSendResponseTo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.Email = reader.string();
                        break;
                    }
                case 2: {
                        message.MessageUUID = reader.string();
                        break;
                    }
                case 3: {
                        message.MessageID = reader.int64();
                        break;
                    }
                case 4: {
                        message.MessageHref = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MailjetSendResponseTo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mailjet.MailjetSendResponseTo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mailjet.MailjetSendResponseTo} MailjetSendResponseTo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendResponseTo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MailjetSendResponseTo message.
         * @function verify
         * @memberof mailjet.MailjetSendResponseTo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MailjetSendResponseTo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.Email != null && message.hasOwnProperty("Email")) {
                properties._Email = 1;
                if (!$util.isString(message.Email))
                    return "Email: string expected";
            }
            if (message.MessageUUID != null && message.hasOwnProperty("MessageUUID")) {
                properties._MessageUUID = 1;
                if (!$util.isString(message.MessageUUID))
                    return "MessageUUID: string expected";
            }
            if (message.MessageID != null && message.hasOwnProperty("MessageID")) {
                properties._MessageID = 1;
                if (!$util.isInteger(message.MessageID) && !(message.MessageID && $util.isInteger(message.MessageID.low) && $util.isInteger(message.MessageID.high)))
                    return "MessageID: integer|Long expected";
            }
            if (message.MessageHref != null && message.hasOwnProperty("MessageHref")) {
                properties._MessageHref = 1;
                if (!$util.isString(message.MessageHref))
                    return "MessageHref: string expected";
            }
            return null;
        };

        /**
         * Creates a MailjetSendResponseTo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mailjet.MailjetSendResponseTo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mailjet.MailjetSendResponseTo} MailjetSendResponseTo
         */
        MailjetSendResponseTo.fromObject = function fromObject(object) {
            if (object instanceof $root.mailjet.MailjetSendResponseTo)
                return object;
            var message = new $root.mailjet.MailjetSendResponseTo();
            if (object.Email != null)
                message.Email = String(object.Email);
            if (object.MessageUUID != null)
                message.MessageUUID = String(object.MessageUUID);
            if (object.MessageID != null)
                if ($util.Long)
                    (message.MessageID = $util.Long.fromValue(object.MessageID)).unsigned = false;
                else if (typeof object.MessageID === "string")
                    message.MessageID = parseInt(object.MessageID, 10);
                else if (typeof object.MessageID === "number")
                    message.MessageID = object.MessageID;
                else if (typeof object.MessageID === "object")
                    message.MessageID = new $util.LongBits(object.MessageID.low >>> 0, object.MessageID.high >>> 0).toNumber();
            if (object.MessageHref != null)
                message.MessageHref = String(object.MessageHref);
            return message;
        };

        /**
         * Creates a plain object from a MailjetSendResponseTo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mailjet.MailjetSendResponseTo
         * @static
         * @param {mailjet.MailjetSendResponseTo} message MailjetSendResponseTo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MailjetSendResponseTo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.Email != null && message.hasOwnProperty("Email")) {
                object.Email = message.Email;
                if (options.oneofs)
                    object._Email = "Email";
            }
            if (message.MessageUUID != null && message.hasOwnProperty("MessageUUID")) {
                object.MessageUUID = message.MessageUUID;
                if (options.oneofs)
                    object._MessageUUID = "MessageUUID";
            }
            if (message.MessageID != null && message.hasOwnProperty("MessageID")) {
                if (typeof message.MessageID === "number")
                    object.MessageID = options.longs === String ? String(message.MessageID) : message.MessageID;
                else
                    object.MessageID = options.longs === String ? $util.Long.prototype.toString.call(message.MessageID) : options.longs === Number ? new $util.LongBits(message.MessageID.low >>> 0, message.MessageID.high >>> 0).toNumber() : message.MessageID;
                if (options.oneofs)
                    object._MessageID = "MessageID";
            }
            if (message.MessageHref != null && message.hasOwnProperty("MessageHref")) {
                object.MessageHref = message.MessageHref;
                if (options.oneofs)
                    object._MessageHref = "MessageHref";
            }
            return object;
        };

        /**
         * Converts this MailjetSendResponseTo to JSON.
         * @function toJSON
         * @memberof mailjet.MailjetSendResponseTo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MailjetSendResponseTo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MailjetSendResponseTo
         * @function getTypeUrl
         * @memberof mailjet.MailjetSendResponseTo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MailjetSendResponseTo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mailjet.MailjetSendResponseTo";
        };

        return MailjetSendResponseTo;
    })();

    mailjet.MailjetSendResponseCc = (function() {

        /**
         * Properties of a MailjetSendResponseCc.
         * @memberof mailjet
         * @interface IMailjetSendResponseCc
         * @property {string|null} [Email] MailjetSendResponseCc Email
         * @property {string|null} [MessageUUID] MailjetSendResponseCc MessageUUID
         * @property {Long|null} [MessageID] MailjetSendResponseCc MessageID
         * @property {string|null} [MessageHref] MailjetSendResponseCc MessageHref
         */

        /**
         * Constructs a new MailjetSendResponseCc.
         * @memberof mailjet
         * @classdesc Represents a MailjetSendResponseCc.
         * @implements IMailjetSendResponseCc
         * @constructor
         * @param {mailjet.IMailjetSendResponseCc=} [properties] Properties to set
         */
        function MailjetSendResponseCc(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MailjetSendResponseCc Email.
         * @member {string|null|undefined} Email
         * @memberof mailjet.MailjetSendResponseCc
         * @instance
         */
        MailjetSendResponseCc.prototype.Email = null;

        /**
         * MailjetSendResponseCc MessageUUID.
         * @member {string|null|undefined} MessageUUID
         * @memberof mailjet.MailjetSendResponseCc
         * @instance
         */
        MailjetSendResponseCc.prototype.MessageUUID = null;

        /**
         * MailjetSendResponseCc MessageID.
         * @member {Long|null|undefined} MessageID
         * @memberof mailjet.MailjetSendResponseCc
         * @instance
         */
        MailjetSendResponseCc.prototype.MessageID = null;

        /**
         * MailjetSendResponseCc MessageHref.
         * @member {string|null|undefined} MessageHref
         * @memberof mailjet.MailjetSendResponseCc
         * @instance
         */
        MailjetSendResponseCc.prototype.MessageHref = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * MailjetSendResponseCc _Email.
         * @member {"Email"|undefined} _Email
         * @memberof mailjet.MailjetSendResponseCc
         * @instance
         */
        Object.defineProperty(MailjetSendResponseCc.prototype, "_Email", {
            get: $util.oneOfGetter($oneOfFields = ["Email"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MailjetSendResponseCc _MessageUUID.
         * @member {"MessageUUID"|undefined} _MessageUUID
         * @memberof mailjet.MailjetSendResponseCc
         * @instance
         */
        Object.defineProperty(MailjetSendResponseCc.prototype, "_MessageUUID", {
            get: $util.oneOfGetter($oneOfFields = ["MessageUUID"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MailjetSendResponseCc _MessageID.
         * @member {"MessageID"|undefined} _MessageID
         * @memberof mailjet.MailjetSendResponseCc
         * @instance
         */
        Object.defineProperty(MailjetSendResponseCc.prototype, "_MessageID", {
            get: $util.oneOfGetter($oneOfFields = ["MessageID"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MailjetSendResponseCc _MessageHref.
         * @member {"MessageHref"|undefined} _MessageHref
         * @memberof mailjet.MailjetSendResponseCc
         * @instance
         */
        Object.defineProperty(MailjetSendResponseCc.prototype, "_MessageHref", {
            get: $util.oneOfGetter($oneOfFields = ["MessageHref"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MailjetSendResponseCc instance using the specified properties.
         * @function create
         * @memberof mailjet.MailjetSendResponseCc
         * @static
         * @param {mailjet.IMailjetSendResponseCc=} [properties] Properties to set
         * @returns {mailjet.MailjetSendResponseCc} MailjetSendResponseCc instance
         */
        MailjetSendResponseCc.create = function create(properties) {
            return new MailjetSendResponseCc(properties);
        };

        /**
         * Encodes the specified MailjetSendResponseCc message. Does not implicitly {@link mailjet.MailjetSendResponseCc.verify|verify} messages.
         * @function encode
         * @memberof mailjet.MailjetSendResponseCc
         * @static
         * @param {mailjet.IMailjetSendResponseCc} message MailjetSendResponseCc message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendResponseCc.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Email != null && Object.hasOwnProperty.call(message, "Email"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.Email);
            if (message.MessageUUID != null && Object.hasOwnProperty.call(message, "MessageUUID"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.MessageUUID);
            if (message.MessageID != null && Object.hasOwnProperty.call(message, "MessageID"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.MessageID);
            if (message.MessageHref != null && Object.hasOwnProperty.call(message, "MessageHref"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.MessageHref);
            return writer;
        };

        /**
         * Encodes the specified MailjetSendResponseCc message, length delimited. Does not implicitly {@link mailjet.MailjetSendResponseCc.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mailjet.MailjetSendResponseCc
         * @static
         * @param {mailjet.IMailjetSendResponseCc} message MailjetSendResponseCc message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendResponseCc.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MailjetSendResponseCc message from the specified reader or buffer.
         * @function decode
         * @memberof mailjet.MailjetSendResponseCc
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mailjet.MailjetSendResponseCc} MailjetSendResponseCc
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendResponseCc.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mailjet.MailjetSendResponseCc();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.Email = reader.string();
                        break;
                    }
                case 2: {
                        message.MessageUUID = reader.string();
                        break;
                    }
                case 3: {
                        message.MessageID = reader.int64();
                        break;
                    }
                case 4: {
                        message.MessageHref = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MailjetSendResponseCc message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mailjet.MailjetSendResponseCc
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mailjet.MailjetSendResponseCc} MailjetSendResponseCc
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendResponseCc.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MailjetSendResponseCc message.
         * @function verify
         * @memberof mailjet.MailjetSendResponseCc
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MailjetSendResponseCc.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.Email != null && message.hasOwnProperty("Email")) {
                properties._Email = 1;
                if (!$util.isString(message.Email))
                    return "Email: string expected";
            }
            if (message.MessageUUID != null && message.hasOwnProperty("MessageUUID")) {
                properties._MessageUUID = 1;
                if (!$util.isString(message.MessageUUID))
                    return "MessageUUID: string expected";
            }
            if (message.MessageID != null && message.hasOwnProperty("MessageID")) {
                properties._MessageID = 1;
                if (!$util.isInteger(message.MessageID) && !(message.MessageID && $util.isInteger(message.MessageID.low) && $util.isInteger(message.MessageID.high)))
                    return "MessageID: integer|Long expected";
            }
            if (message.MessageHref != null && message.hasOwnProperty("MessageHref")) {
                properties._MessageHref = 1;
                if (!$util.isString(message.MessageHref))
                    return "MessageHref: string expected";
            }
            return null;
        };

        /**
         * Creates a MailjetSendResponseCc message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mailjet.MailjetSendResponseCc
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mailjet.MailjetSendResponseCc} MailjetSendResponseCc
         */
        MailjetSendResponseCc.fromObject = function fromObject(object) {
            if (object instanceof $root.mailjet.MailjetSendResponseCc)
                return object;
            var message = new $root.mailjet.MailjetSendResponseCc();
            if (object.Email != null)
                message.Email = String(object.Email);
            if (object.MessageUUID != null)
                message.MessageUUID = String(object.MessageUUID);
            if (object.MessageID != null)
                if ($util.Long)
                    (message.MessageID = $util.Long.fromValue(object.MessageID)).unsigned = false;
                else if (typeof object.MessageID === "string")
                    message.MessageID = parseInt(object.MessageID, 10);
                else if (typeof object.MessageID === "number")
                    message.MessageID = object.MessageID;
                else if (typeof object.MessageID === "object")
                    message.MessageID = new $util.LongBits(object.MessageID.low >>> 0, object.MessageID.high >>> 0).toNumber();
            if (object.MessageHref != null)
                message.MessageHref = String(object.MessageHref);
            return message;
        };

        /**
         * Creates a plain object from a MailjetSendResponseCc message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mailjet.MailjetSendResponseCc
         * @static
         * @param {mailjet.MailjetSendResponseCc} message MailjetSendResponseCc
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MailjetSendResponseCc.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.Email != null && message.hasOwnProperty("Email")) {
                object.Email = message.Email;
                if (options.oneofs)
                    object._Email = "Email";
            }
            if (message.MessageUUID != null && message.hasOwnProperty("MessageUUID")) {
                object.MessageUUID = message.MessageUUID;
                if (options.oneofs)
                    object._MessageUUID = "MessageUUID";
            }
            if (message.MessageID != null && message.hasOwnProperty("MessageID")) {
                if (typeof message.MessageID === "number")
                    object.MessageID = options.longs === String ? String(message.MessageID) : message.MessageID;
                else
                    object.MessageID = options.longs === String ? $util.Long.prototype.toString.call(message.MessageID) : options.longs === Number ? new $util.LongBits(message.MessageID.low >>> 0, message.MessageID.high >>> 0).toNumber() : message.MessageID;
                if (options.oneofs)
                    object._MessageID = "MessageID";
            }
            if (message.MessageHref != null && message.hasOwnProperty("MessageHref")) {
                object.MessageHref = message.MessageHref;
                if (options.oneofs)
                    object._MessageHref = "MessageHref";
            }
            return object;
        };

        /**
         * Converts this MailjetSendResponseCc to JSON.
         * @function toJSON
         * @memberof mailjet.MailjetSendResponseCc
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MailjetSendResponseCc.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MailjetSendResponseCc
         * @function getTypeUrl
         * @memberof mailjet.MailjetSendResponseCc
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MailjetSendResponseCc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mailjet.MailjetSendResponseCc";
        };

        return MailjetSendResponseCc;
    })();

    mailjet.MailjetSendResponseBcc = (function() {

        /**
         * Properties of a MailjetSendResponseBcc.
         * @memberof mailjet
         * @interface IMailjetSendResponseBcc
         * @property {string|null} [Email] MailjetSendResponseBcc Email
         * @property {string|null} [MessageUUID] MailjetSendResponseBcc MessageUUID
         * @property {Long|null} [MessageID] MailjetSendResponseBcc MessageID
         * @property {string|null} [MessageHref] MailjetSendResponseBcc MessageHref
         */

        /**
         * Constructs a new MailjetSendResponseBcc.
         * @memberof mailjet
         * @classdesc Represents a MailjetSendResponseBcc.
         * @implements IMailjetSendResponseBcc
         * @constructor
         * @param {mailjet.IMailjetSendResponseBcc=} [properties] Properties to set
         */
        function MailjetSendResponseBcc(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MailjetSendResponseBcc Email.
         * @member {string|null|undefined} Email
         * @memberof mailjet.MailjetSendResponseBcc
         * @instance
         */
        MailjetSendResponseBcc.prototype.Email = null;

        /**
         * MailjetSendResponseBcc MessageUUID.
         * @member {string|null|undefined} MessageUUID
         * @memberof mailjet.MailjetSendResponseBcc
         * @instance
         */
        MailjetSendResponseBcc.prototype.MessageUUID = null;

        /**
         * MailjetSendResponseBcc MessageID.
         * @member {Long|null|undefined} MessageID
         * @memberof mailjet.MailjetSendResponseBcc
         * @instance
         */
        MailjetSendResponseBcc.prototype.MessageID = null;

        /**
         * MailjetSendResponseBcc MessageHref.
         * @member {string|null|undefined} MessageHref
         * @memberof mailjet.MailjetSendResponseBcc
         * @instance
         */
        MailjetSendResponseBcc.prototype.MessageHref = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * MailjetSendResponseBcc _Email.
         * @member {"Email"|undefined} _Email
         * @memberof mailjet.MailjetSendResponseBcc
         * @instance
         */
        Object.defineProperty(MailjetSendResponseBcc.prototype, "_Email", {
            get: $util.oneOfGetter($oneOfFields = ["Email"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MailjetSendResponseBcc _MessageUUID.
         * @member {"MessageUUID"|undefined} _MessageUUID
         * @memberof mailjet.MailjetSendResponseBcc
         * @instance
         */
        Object.defineProperty(MailjetSendResponseBcc.prototype, "_MessageUUID", {
            get: $util.oneOfGetter($oneOfFields = ["MessageUUID"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MailjetSendResponseBcc _MessageID.
         * @member {"MessageID"|undefined} _MessageID
         * @memberof mailjet.MailjetSendResponseBcc
         * @instance
         */
        Object.defineProperty(MailjetSendResponseBcc.prototype, "_MessageID", {
            get: $util.oneOfGetter($oneOfFields = ["MessageID"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MailjetSendResponseBcc _MessageHref.
         * @member {"MessageHref"|undefined} _MessageHref
         * @memberof mailjet.MailjetSendResponseBcc
         * @instance
         */
        Object.defineProperty(MailjetSendResponseBcc.prototype, "_MessageHref", {
            get: $util.oneOfGetter($oneOfFields = ["MessageHref"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MailjetSendResponseBcc instance using the specified properties.
         * @function create
         * @memberof mailjet.MailjetSendResponseBcc
         * @static
         * @param {mailjet.IMailjetSendResponseBcc=} [properties] Properties to set
         * @returns {mailjet.MailjetSendResponseBcc} MailjetSendResponseBcc instance
         */
        MailjetSendResponseBcc.create = function create(properties) {
            return new MailjetSendResponseBcc(properties);
        };

        /**
         * Encodes the specified MailjetSendResponseBcc message. Does not implicitly {@link mailjet.MailjetSendResponseBcc.verify|verify} messages.
         * @function encode
         * @memberof mailjet.MailjetSendResponseBcc
         * @static
         * @param {mailjet.IMailjetSendResponseBcc} message MailjetSendResponseBcc message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendResponseBcc.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Email != null && Object.hasOwnProperty.call(message, "Email"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.Email);
            if (message.MessageUUID != null && Object.hasOwnProperty.call(message, "MessageUUID"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.MessageUUID);
            if (message.MessageID != null && Object.hasOwnProperty.call(message, "MessageID"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.MessageID);
            if (message.MessageHref != null && Object.hasOwnProperty.call(message, "MessageHref"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.MessageHref);
            return writer;
        };

        /**
         * Encodes the specified MailjetSendResponseBcc message, length delimited. Does not implicitly {@link mailjet.MailjetSendResponseBcc.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mailjet.MailjetSendResponseBcc
         * @static
         * @param {mailjet.IMailjetSendResponseBcc} message MailjetSendResponseBcc message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MailjetSendResponseBcc.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MailjetSendResponseBcc message from the specified reader or buffer.
         * @function decode
         * @memberof mailjet.MailjetSendResponseBcc
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mailjet.MailjetSendResponseBcc} MailjetSendResponseBcc
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendResponseBcc.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mailjet.MailjetSendResponseBcc();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.Email = reader.string();
                        break;
                    }
                case 2: {
                        message.MessageUUID = reader.string();
                        break;
                    }
                case 3: {
                        message.MessageID = reader.int64();
                        break;
                    }
                case 4: {
                        message.MessageHref = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MailjetSendResponseBcc message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mailjet.MailjetSendResponseBcc
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mailjet.MailjetSendResponseBcc} MailjetSendResponseBcc
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MailjetSendResponseBcc.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MailjetSendResponseBcc message.
         * @function verify
         * @memberof mailjet.MailjetSendResponseBcc
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MailjetSendResponseBcc.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.Email != null && message.hasOwnProperty("Email")) {
                properties._Email = 1;
                if (!$util.isString(message.Email))
                    return "Email: string expected";
            }
            if (message.MessageUUID != null && message.hasOwnProperty("MessageUUID")) {
                properties._MessageUUID = 1;
                if (!$util.isString(message.MessageUUID))
                    return "MessageUUID: string expected";
            }
            if (message.MessageID != null && message.hasOwnProperty("MessageID")) {
                properties._MessageID = 1;
                if (!$util.isInteger(message.MessageID) && !(message.MessageID && $util.isInteger(message.MessageID.low) && $util.isInteger(message.MessageID.high)))
                    return "MessageID: integer|Long expected";
            }
            if (message.MessageHref != null && message.hasOwnProperty("MessageHref")) {
                properties._MessageHref = 1;
                if (!$util.isString(message.MessageHref))
                    return "MessageHref: string expected";
            }
            return null;
        };

        /**
         * Creates a MailjetSendResponseBcc message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mailjet.MailjetSendResponseBcc
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mailjet.MailjetSendResponseBcc} MailjetSendResponseBcc
         */
        MailjetSendResponseBcc.fromObject = function fromObject(object) {
            if (object instanceof $root.mailjet.MailjetSendResponseBcc)
                return object;
            var message = new $root.mailjet.MailjetSendResponseBcc();
            if (object.Email != null)
                message.Email = String(object.Email);
            if (object.MessageUUID != null)
                message.MessageUUID = String(object.MessageUUID);
            if (object.MessageID != null)
                if ($util.Long)
                    (message.MessageID = $util.Long.fromValue(object.MessageID)).unsigned = false;
                else if (typeof object.MessageID === "string")
                    message.MessageID = parseInt(object.MessageID, 10);
                else if (typeof object.MessageID === "number")
                    message.MessageID = object.MessageID;
                else if (typeof object.MessageID === "object")
                    message.MessageID = new $util.LongBits(object.MessageID.low >>> 0, object.MessageID.high >>> 0).toNumber();
            if (object.MessageHref != null)
                message.MessageHref = String(object.MessageHref);
            return message;
        };

        /**
         * Creates a plain object from a MailjetSendResponseBcc message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mailjet.MailjetSendResponseBcc
         * @static
         * @param {mailjet.MailjetSendResponseBcc} message MailjetSendResponseBcc
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MailjetSendResponseBcc.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.Email != null && message.hasOwnProperty("Email")) {
                object.Email = message.Email;
                if (options.oneofs)
                    object._Email = "Email";
            }
            if (message.MessageUUID != null && message.hasOwnProperty("MessageUUID")) {
                object.MessageUUID = message.MessageUUID;
                if (options.oneofs)
                    object._MessageUUID = "MessageUUID";
            }
            if (message.MessageID != null && message.hasOwnProperty("MessageID")) {
                if (typeof message.MessageID === "number")
                    object.MessageID = options.longs === String ? String(message.MessageID) : message.MessageID;
                else
                    object.MessageID = options.longs === String ? $util.Long.prototype.toString.call(message.MessageID) : options.longs === Number ? new $util.LongBits(message.MessageID.low >>> 0, message.MessageID.high >>> 0).toNumber() : message.MessageID;
                if (options.oneofs)
                    object._MessageID = "MessageID";
            }
            if (message.MessageHref != null && message.hasOwnProperty("MessageHref")) {
                object.MessageHref = message.MessageHref;
                if (options.oneofs)
                    object._MessageHref = "MessageHref";
            }
            return object;
        };

        /**
         * Converts this MailjetSendResponseBcc to JSON.
         * @function toJSON
         * @memberof mailjet.MailjetSendResponseBcc
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MailjetSendResponseBcc.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MailjetSendResponseBcc
         * @function getTypeUrl
         * @memberof mailjet.MailjetSendResponseBcc
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MailjetSendResponseBcc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mailjet.MailjetSendResponseBcc";
        };

        return MailjetSendResponseBcc;
    })();

    return mailjet;
})();

$root.post_service = (function() {

    /**
     * Namespace post_service.
     * @exports post_service
     * @namespace
     */
    var post_service = {};

    post_service.PostService = (function() {

        /**
         * Constructs a new PostService service.
         * @memberof post_service
         * @classdesc Represents a PostService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function PostService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (PostService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = PostService;

        /**
         * Creates new PostService service using the specified rpc implementation.
         * @function create
         * @memberof post_service.PostService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {PostService} RPC service. Useful where requests and/or responses are streamed.
         */
        PostService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link post_service.PostService#getProjectPosts}.
         * @memberof post_service.PostService
         * @typedef GetProjectPostsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {post_service.GetProjectPostsResponse} [response] GetProjectPostsResponse
         */

        /**
         * Calls GetProjectPosts.
         * @function getProjectPosts
         * @memberof post_service.PostService
         * @instance
         * @param {post_service.IGetProjectPostsRequest} request GetProjectPostsRequest message or plain object
         * @param {post_service.PostService.GetProjectPostsCallback} callback Node-style callback called with the error, if any, and GetProjectPostsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PostService.prototype.getProjectPosts = function getProjectPosts(request, callback) {
            return this.rpcCall(getProjectPosts, $root.post_service.GetProjectPostsRequest, $root.post_service.GetProjectPostsResponse, request, callback);
        }, "name", { value: "GetProjectPosts" });

        /**
         * Calls GetProjectPosts.
         * @function getProjectPosts
         * @memberof post_service.PostService
         * @instance
         * @param {post_service.IGetProjectPostsRequest} request GetProjectPostsRequest message or plain object
         * @returns {Promise<post_service.GetProjectPostsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link post_service.PostService#upsertProjectPost}.
         * @memberof post_service.PostService
         * @typedef UpsertProjectPostCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {post_service.UpsertProjectPostResponse} [response] UpsertProjectPostResponse
         */

        /**
         * Calls UpsertProjectPost.
         * @function upsertProjectPost
         * @memberof post_service.PostService
         * @instance
         * @param {post_service.IUpsertProjectPostRequest} request UpsertProjectPostRequest message or plain object
         * @param {post_service.PostService.UpsertProjectPostCallback} callback Node-style callback called with the error, if any, and UpsertProjectPostResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PostService.prototype.upsertProjectPost = function upsertProjectPost(request, callback) {
            return this.rpcCall(upsertProjectPost, $root.post_service.UpsertProjectPostRequest, $root.post_service.UpsertProjectPostResponse, request, callback);
        }, "name", { value: "UpsertProjectPost" });

        /**
         * Calls UpsertProjectPost.
         * @function upsertProjectPost
         * @memberof post_service.PostService
         * @instance
         * @param {post_service.IUpsertProjectPostRequest} request UpsertProjectPostRequest message or plain object
         * @returns {Promise<post_service.UpsertProjectPostResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link post_service.PostService#upsertProjectPostComment}.
         * @memberof post_service.PostService
         * @typedef UpsertProjectPostCommentCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {post_service.UpsertProjectPostCommentResponse} [response] UpsertProjectPostCommentResponse
         */

        /**
         * Calls UpsertProjectPostComment.
         * @function upsertProjectPostComment
         * @memberof post_service.PostService
         * @instance
         * @param {post_service.IUpsertProjectPostCommentRequest} request UpsertProjectPostCommentRequest message or plain object
         * @param {post_service.PostService.UpsertProjectPostCommentCallback} callback Node-style callback called with the error, if any, and UpsertProjectPostCommentResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PostService.prototype.upsertProjectPostComment = function upsertProjectPostComment(request, callback) {
            return this.rpcCall(upsertProjectPostComment, $root.post_service.UpsertProjectPostCommentRequest, $root.post_service.UpsertProjectPostCommentResponse, request, callback);
        }, "name", { value: "UpsertProjectPostComment" });

        /**
         * Calls UpsertProjectPostComment.
         * @function upsertProjectPostComment
         * @memberof post_service.PostService
         * @instance
         * @param {post_service.IUpsertProjectPostCommentRequest} request UpsertProjectPostCommentRequest message or plain object
         * @returns {Promise<post_service.UpsertProjectPostCommentResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link post_service.PostService#deleteProjectPostComment}.
         * @memberof post_service.PostService
         * @typedef DeleteProjectPostCommentCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {post_service.DeleteProjectPostCommentResponse} [response] DeleteProjectPostCommentResponse
         */

        /**
         * Calls DeleteProjectPostComment.
         * @function deleteProjectPostComment
         * @memberof post_service.PostService
         * @instance
         * @param {post_service.IDeleteProjectPostCommentRequest} request DeleteProjectPostCommentRequest message or plain object
         * @param {post_service.PostService.DeleteProjectPostCommentCallback} callback Node-style callback called with the error, if any, and DeleteProjectPostCommentResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PostService.prototype.deleteProjectPostComment = function deleteProjectPostComment(request, callback) {
            return this.rpcCall(deleteProjectPostComment, $root.post_service.DeleteProjectPostCommentRequest, $root.post_service.DeleteProjectPostCommentResponse, request, callback);
        }, "name", { value: "DeleteProjectPostComment" });

        /**
         * Calls DeleteProjectPostComment.
         * @function deleteProjectPostComment
         * @memberof post_service.PostService
         * @instance
         * @param {post_service.IDeleteProjectPostCommentRequest} request DeleteProjectPostCommentRequest message or plain object
         * @returns {Promise<post_service.DeleteProjectPostCommentResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link post_service.PostService#upsertProjectPostRating}.
         * @memberof post_service.PostService
         * @typedef UpsertProjectPostRatingCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {post_service.UpsertProjectPostRatingResponse} [response] UpsertProjectPostRatingResponse
         */

        /**
         * Calls UpsertProjectPostRating.
         * @function upsertProjectPostRating
         * @memberof post_service.PostService
         * @instance
         * @param {post_service.IUpsertProjectPostRatingRequest} request UpsertProjectPostRatingRequest message or plain object
         * @param {post_service.PostService.UpsertProjectPostRatingCallback} callback Node-style callback called with the error, if any, and UpsertProjectPostRatingResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PostService.prototype.upsertProjectPostRating = function upsertProjectPostRating(request, callback) {
            return this.rpcCall(upsertProjectPostRating, $root.post_service.UpsertProjectPostRatingRequest, $root.post_service.UpsertProjectPostRatingResponse, request, callback);
        }, "name", { value: "UpsertProjectPostRating" });

        /**
         * Calls UpsertProjectPostRating.
         * @function upsertProjectPostRating
         * @memberof post_service.PostService
         * @instance
         * @param {post_service.IUpsertProjectPostRatingRequest} request UpsertProjectPostRatingRequest message or plain object
         * @returns {Promise<post_service.UpsertProjectPostRatingResponse>} Promise
         * @variation 2
         */

        return PostService;
    })();

    post_service.GetProjectPostsRequest = (function() {

        /**
         * Properties of a GetProjectPostsRequest.
         * @memberof post_service
         * @interface IGetProjectPostsRequest
         * @property {boolean|null} [includeTags] GetProjectPostsRequest includeTags
         * @property {boolean|null} [includeComments] GetProjectPostsRequest includeComments
         * @property {boolean|null} [includeProjects] GetProjectPostsRequest includeProjects
         * @property {boolean|null} [includeRatings] GetProjectPostsRequest includeRatings
         * @property {boolean|null} [includeAssignments] GetProjectPostsRequest includeAssignments
         * @property {Array.<number>|null} [projectIds] GetProjectPostsRequest projectIds
         * @property {Array.<number>|null} [projectPostIds] GetProjectPostsRequest projectPostIds
         * @property {Array.<number>|null} [assignmentIds] GetProjectPostsRequest assignmentIds
         * @property {Array.<number>|null} [classXIds] GetProjectPostsRequest classXIds
         * @property {Array.<number>|null} [schoolIds] GetProjectPostsRequest schoolIds
         * @property {Array.<number>|null} [userXIds] GetProjectPostsRequest userXIds
         * @property {boolean|null} [beingEdited] GetProjectPostsRequest beingEdited
         * @property {number|null} [page] GetProjectPostsRequest page
         * @property {number|null} [pageSize] GetProjectPostsRequest pageSize
         */

        /**
         * Constructs a new GetProjectPostsRequest.
         * @memberof post_service
         * @classdesc Represents a GetProjectPostsRequest.
         * @implements IGetProjectPostsRequest
         * @constructor
         * @param {post_service.IGetProjectPostsRequest=} [properties] Properties to set
         */
        function GetProjectPostsRequest(properties) {
            this.projectIds = [];
            this.projectPostIds = [];
            this.assignmentIds = [];
            this.classXIds = [];
            this.schoolIds = [];
            this.userXIds = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetProjectPostsRequest includeTags.
         * @member {boolean|null|undefined} includeTags
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        GetProjectPostsRequest.prototype.includeTags = null;

        /**
         * GetProjectPostsRequest includeComments.
         * @member {boolean|null|undefined} includeComments
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        GetProjectPostsRequest.prototype.includeComments = null;

        /**
         * GetProjectPostsRequest includeProjects.
         * @member {boolean|null|undefined} includeProjects
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        GetProjectPostsRequest.prototype.includeProjects = null;

        /**
         * GetProjectPostsRequest includeRatings.
         * @member {boolean|null|undefined} includeRatings
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        GetProjectPostsRequest.prototype.includeRatings = null;

        /**
         * GetProjectPostsRequest includeAssignments.
         * @member {boolean|null|undefined} includeAssignments
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        GetProjectPostsRequest.prototype.includeAssignments = null;

        /**
         * GetProjectPostsRequest projectIds.
         * @member {Array.<number>} projectIds
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        GetProjectPostsRequest.prototype.projectIds = $util.emptyArray;

        /**
         * GetProjectPostsRequest projectPostIds.
         * @member {Array.<number>} projectPostIds
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        GetProjectPostsRequest.prototype.projectPostIds = $util.emptyArray;

        /**
         * GetProjectPostsRequest assignmentIds.
         * @member {Array.<number>} assignmentIds
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        GetProjectPostsRequest.prototype.assignmentIds = $util.emptyArray;

        /**
         * GetProjectPostsRequest classXIds.
         * @member {Array.<number>} classXIds
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        GetProjectPostsRequest.prototype.classXIds = $util.emptyArray;

        /**
         * GetProjectPostsRequest schoolIds.
         * @member {Array.<number>} schoolIds
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        GetProjectPostsRequest.prototype.schoolIds = $util.emptyArray;

        /**
         * GetProjectPostsRequest userXIds.
         * @member {Array.<number>} userXIds
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        GetProjectPostsRequest.prototype.userXIds = $util.emptyArray;

        /**
         * GetProjectPostsRequest beingEdited.
         * @member {boolean|null|undefined} beingEdited
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        GetProjectPostsRequest.prototype.beingEdited = null;

        /**
         * GetProjectPostsRequest page.
         * @member {number|null|undefined} page
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        GetProjectPostsRequest.prototype.page = null;

        /**
         * GetProjectPostsRequest pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        GetProjectPostsRequest.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GetProjectPostsRequest _includeTags.
         * @member {"includeTags"|undefined} _includeTags
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        Object.defineProperty(GetProjectPostsRequest.prototype, "_includeTags", {
            get: $util.oneOfGetter($oneOfFields = ["includeTags"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetProjectPostsRequest _includeComments.
         * @member {"includeComments"|undefined} _includeComments
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        Object.defineProperty(GetProjectPostsRequest.prototype, "_includeComments", {
            get: $util.oneOfGetter($oneOfFields = ["includeComments"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetProjectPostsRequest _includeProjects.
         * @member {"includeProjects"|undefined} _includeProjects
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        Object.defineProperty(GetProjectPostsRequest.prototype, "_includeProjects", {
            get: $util.oneOfGetter($oneOfFields = ["includeProjects"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetProjectPostsRequest _includeRatings.
         * @member {"includeRatings"|undefined} _includeRatings
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        Object.defineProperty(GetProjectPostsRequest.prototype, "_includeRatings", {
            get: $util.oneOfGetter($oneOfFields = ["includeRatings"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetProjectPostsRequest _includeAssignments.
         * @member {"includeAssignments"|undefined} _includeAssignments
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        Object.defineProperty(GetProjectPostsRequest.prototype, "_includeAssignments", {
            get: $util.oneOfGetter($oneOfFields = ["includeAssignments"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetProjectPostsRequest _beingEdited.
         * @member {"beingEdited"|undefined} _beingEdited
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        Object.defineProperty(GetProjectPostsRequest.prototype, "_beingEdited", {
            get: $util.oneOfGetter($oneOfFields = ["beingEdited"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetProjectPostsRequest _page.
         * @member {"page"|undefined} _page
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        Object.defineProperty(GetProjectPostsRequest.prototype, "_page", {
            get: $util.oneOfGetter($oneOfFields = ["page"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetProjectPostsRequest _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         */
        Object.defineProperty(GetProjectPostsRequest.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetProjectPostsRequest instance using the specified properties.
         * @function create
         * @memberof post_service.GetProjectPostsRequest
         * @static
         * @param {post_service.IGetProjectPostsRequest=} [properties] Properties to set
         * @returns {post_service.GetProjectPostsRequest} GetProjectPostsRequest instance
         */
        GetProjectPostsRequest.create = function create(properties) {
            return new GetProjectPostsRequest(properties);
        };

        /**
         * Encodes the specified GetProjectPostsRequest message. Does not implicitly {@link post_service.GetProjectPostsRequest.verify|verify} messages.
         * @function encode
         * @memberof post_service.GetProjectPostsRequest
         * @static
         * @param {post_service.IGetProjectPostsRequest} message GetProjectPostsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectPostsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.beingEdited != null && Object.hasOwnProperty.call(message, "beingEdited"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.beingEdited);
            if (message.classXIds != null && message.classXIds.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (var i = 0; i < message.classXIds.length; ++i)
                    writer.int32(message.classXIds[i]);
                writer.ldelim();
            }
            if (message.userXIds != null && message.userXIds.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (var i = 0; i < message.userXIds.length; ++i)
                    writer.int32(message.userXIds[i]);
                writer.ldelim();
            }
            if (message.schoolIds != null && message.schoolIds.length) {
                writer.uint32(/* id 5, wireType 2 =*/42).fork();
                for (var i = 0; i < message.schoolIds.length; ++i)
                    writer.int32(message.schoolIds[i]);
                writer.ldelim();
            }
            if (message.assignmentIds != null && message.assignmentIds.length) {
                writer.uint32(/* id 6, wireType 2 =*/50).fork();
                for (var i = 0; i < message.assignmentIds.length; ++i)
                    writer.int32(message.assignmentIds[i]);
                writer.ldelim();
            }
            if (message.projectIds != null && message.projectIds.length) {
                writer.uint32(/* id 7, wireType 2 =*/58).fork();
                for (var i = 0; i < message.projectIds.length; ++i)
                    writer.int32(message.projectIds[i]);
                writer.ldelim();
            }
            if (message.includeTags != null && Object.hasOwnProperty.call(message, "includeTags"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.includeTags);
            if (message.includeComments != null && Object.hasOwnProperty.call(message, "includeComments"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.includeComments);
            if (message.includeProjects != null && Object.hasOwnProperty.call(message, "includeProjects"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.includeProjects);
            if (message.projectPostIds != null && message.projectPostIds.length) {
                writer.uint32(/* id 11, wireType 2 =*/90).fork();
                for (var i = 0; i < message.projectPostIds.length; ++i)
                    writer.int32(message.projectPostIds[i]);
                writer.ldelim();
            }
            if (message.includeRatings != null && Object.hasOwnProperty.call(message, "includeRatings"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.includeRatings);
            if (message.includeAssignments != null && Object.hasOwnProperty.call(message, "includeAssignments"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.includeAssignments);
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 14, wireType 0 =*/112).int32(message.page);
            if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                writer.uint32(/* id 15, wireType 0 =*/120).int32(message.pageSize);
            return writer;
        };

        /**
         * Encodes the specified GetProjectPostsRequest message, length delimited. Does not implicitly {@link post_service.GetProjectPostsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof post_service.GetProjectPostsRequest
         * @static
         * @param {post_service.IGetProjectPostsRequest} message GetProjectPostsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectPostsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetProjectPostsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof post_service.GetProjectPostsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {post_service.GetProjectPostsRequest} GetProjectPostsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectPostsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.post_service.GetProjectPostsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 8: {
                        message.includeTags = reader.bool();
                        break;
                    }
                case 9: {
                        message.includeComments = reader.bool();
                        break;
                    }
                case 10: {
                        message.includeProjects = reader.bool();
                        break;
                    }
                case 12: {
                        message.includeRatings = reader.bool();
                        break;
                    }
                case 13: {
                        message.includeAssignments = reader.bool();
                        break;
                    }
                case 7: {
                        if (!(message.projectIds && message.projectIds.length))
                            message.projectIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.projectIds.push(reader.int32());
                        } else
                            message.projectIds.push(reader.int32());
                        break;
                    }
                case 11: {
                        if (!(message.projectPostIds && message.projectPostIds.length))
                            message.projectPostIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.projectPostIds.push(reader.int32());
                        } else
                            message.projectPostIds.push(reader.int32());
                        break;
                    }
                case 6: {
                        if (!(message.assignmentIds && message.assignmentIds.length))
                            message.assignmentIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.assignmentIds.push(reader.int32());
                        } else
                            message.assignmentIds.push(reader.int32());
                        break;
                    }
                case 3: {
                        if (!(message.classXIds && message.classXIds.length))
                            message.classXIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.classXIds.push(reader.int32());
                        } else
                            message.classXIds.push(reader.int32());
                        break;
                    }
                case 5: {
                        if (!(message.schoolIds && message.schoolIds.length))
                            message.schoolIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.schoolIds.push(reader.int32());
                        } else
                            message.schoolIds.push(reader.int32());
                        break;
                    }
                case 4: {
                        if (!(message.userXIds && message.userXIds.length))
                            message.userXIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.userXIds.push(reader.int32());
                        } else
                            message.userXIds.push(reader.int32());
                        break;
                    }
                case 2: {
                        message.beingEdited = reader.bool();
                        break;
                    }
                case 14: {
                        message.page = reader.int32();
                        break;
                    }
                case 15: {
                        message.pageSize = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetProjectPostsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof post_service.GetProjectPostsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {post_service.GetProjectPostsRequest} GetProjectPostsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectPostsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetProjectPostsRequest message.
         * @function verify
         * @memberof post_service.GetProjectPostsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetProjectPostsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.includeTags != null && message.hasOwnProperty("includeTags")) {
                properties._includeTags = 1;
                if (typeof message.includeTags !== "boolean")
                    return "includeTags: boolean expected";
            }
            if (message.includeComments != null && message.hasOwnProperty("includeComments")) {
                properties._includeComments = 1;
                if (typeof message.includeComments !== "boolean")
                    return "includeComments: boolean expected";
            }
            if (message.includeProjects != null && message.hasOwnProperty("includeProjects")) {
                properties._includeProjects = 1;
                if (typeof message.includeProjects !== "boolean")
                    return "includeProjects: boolean expected";
            }
            if (message.includeRatings != null && message.hasOwnProperty("includeRatings")) {
                properties._includeRatings = 1;
                if (typeof message.includeRatings !== "boolean")
                    return "includeRatings: boolean expected";
            }
            if (message.includeAssignments != null && message.hasOwnProperty("includeAssignments")) {
                properties._includeAssignments = 1;
                if (typeof message.includeAssignments !== "boolean")
                    return "includeAssignments: boolean expected";
            }
            if (message.projectIds != null && message.hasOwnProperty("projectIds")) {
                if (!Array.isArray(message.projectIds))
                    return "projectIds: array expected";
                for (var i = 0; i < message.projectIds.length; ++i)
                    if (!$util.isInteger(message.projectIds[i]))
                        return "projectIds: integer[] expected";
            }
            if (message.projectPostIds != null && message.hasOwnProperty("projectPostIds")) {
                if (!Array.isArray(message.projectPostIds))
                    return "projectPostIds: array expected";
                for (var i = 0; i < message.projectPostIds.length; ++i)
                    if (!$util.isInteger(message.projectPostIds[i]))
                        return "projectPostIds: integer[] expected";
            }
            if (message.assignmentIds != null && message.hasOwnProperty("assignmentIds")) {
                if (!Array.isArray(message.assignmentIds))
                    return "assignmentIds: array expected";
                for (var i = 0; i < message.assignmentIds.length; ++i)
                    if (!$util.isInteger(message.assignmentIds[i]))
                        return "assignmentIds: integer[] expected";
            }
            if (message.classXIds != null && message.hasOwnProperty("classXIds")) {
                if (!Array.isArray(message.classXIds))
                    return "classXIds: array expected";
                for (var i = 0; i < message.classXIds.length; ++i)
                    if (!$util.isInteger(message.classXIds[i]))
                        return "classXIds: integer[] expected";
            }
            if (message.schoolIds != null && message.hasOwnProperty("schoolIds")) {
                if (!Array.isArray(message.schoolIds))
                    return "schoolIds: array expected";
                for (var i = 0; i < message.schoolIds.length; ++i)
                    if (!$util.isInteger(message.schoolIds[i]))
                        return "schoolIds: integer[] expected";
            }
            if (message.userXIds != null && message.hasOwnProperty("userXIds")) {
                if (!Array.isArray(message.userXIds))
                    return "userXIds: array expected";
                for (var i = 0; i < message.userXIds.length; ++i)
                    if (!$util.isInteger(message.userXIds[i]))
                        return "userXIds: integer[] expected";
            }
            if (message.beingEdited != null && message.hasOwnProperty("beingEdited")) {
                properties._beingEdited = 1;
                if (typeof message.beingEdited !== "boolean")
                    return "beingEdited: boolean expected";
            }
            if (message.page != null && message.hasOwnProperty("page")) {
                properties._page = 1;
                if (!$util.isInteger(message.page))
                    return "page: integer expected";
            }
            if (message.pageSize != null && message.hasOwnProperty("pageSize")) {
                properties._pageSize = 1;
                if (!$util.isInteger(message.pageSize))
                    return "pageSize: integer expected";
            }
            return null;
        };

        /**
         * Creates a GetProjectPostsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof post_service.GetProjectPostsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {post_service.GetProjectPostsRequest} GetProjectPostsRequest
         */
        GetProjectPostsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.post_service.GetProjectPostsRequest)
                return object;
            var message = new $root.post_service.GetProjectPostsRequest();
            if (object.includeTags != null)
                message.includeTags = Boolean(object.includeTags);
            if (object.includeComments != null)
                message.includeComments = Boolean(object.includeComments);
            if (object.includeProjects != null)
                message.includeProjects = Boolean(object.includeProjects);
            if (object.includeRatings != null)
                message.includeRatings = Boolean(object.includeRatings);
            if (object.includeAssignments != null)
                message.includeAssignments = Boolean(object.includeAssignments);
            if (object.projectIds) {
                if (!Array.isArray(object.projectIds))
                    throw TypeError(".post_service.GetProjectPostsRequest.projectIds: array expected");
                message.projectIds = [];
                for (var i = 0; i < object.projectIds.length; ++i)
                    message.projectIds[i] = object.projectIds[i] | 0;
            }
            if (object.projectPostIds) {
                if (!Array.isArray(object.projectPostIds))
                    throw TypeError(".post_service.GetProjectPostsRequest.projectPostIds: array expected");
                message.projectPostIds = [];
                for (var i = 0; i < object.projectPostIds.length; ++i)
                    message.projectPostIds[i] = object.projectPostIds[i] | 0;
            }
            if (object.assignmentIds) {
                if (!Array.isArray(object.assignmentIds))
                    throw TypeError(".post_service.GetProjectPostsRequest.assignmentIds: array expected");
                message.assignmentIds = [];
                for (var i = 0; i < object.assignmentIds.length; ++i)
                    message.assignmentIds[i] = object.assignmentIds[i] | 0;
            }
            if (object.classXIds) {
                if (!Array.isArray(object.classXIds))
                    throw TypeError(".post_service.GetProjectPostsRequest.classXIds: array expected");
                message.classXIds = [];
                for (var i = 0; i < object.classXIds.length; ++i)
                    message.classXIds[i] = object.classXIds[i] | 0;
            }
            if (object.schoolIds) {
                if (!Array.isArray(object.schoolIds))
                    throw TypeError(".post_service.GetProjectPostsRequest.schoolIds: array expected");
                message.schoolIds = [];
                for (var i = 0; i < object.schoolIds.length; ++i)
                    message.schoolIds[i] = object.schoolIds[i] | 0;
            }
            if (object.userXIds) {
                if (!Array.isArray(object.userXIds))
                    throw TypeError(".post_service.GetProjectPostsRequest.userXIds: array expected");
                message.userXIds = [];
                for (var i = 0; i < object.userXIds.length; ++i)
                    message.userXIds[i] = object.userXIds[i] | 0;
            }
            if (object.beingEdited != null)
                message.beingEdited = Boolean(object.beingEdited);
            if (object.page != null)
                message.page = object.page | 0;
            if (object.pageSize != null)
                message.pageSize = object.pageSize | 0;
            return message;
        };

        /**
         * Creates a plain object from a GetProjectPostsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof post_service.GetProjectPostsRequest
         * @static
         * @param {post_service.GetProjectPostsRequest} message GetProjectPostsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetProjectPostsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.classXIds = [];
                object.userXIds = [];
                object.schoolIds = [];
                object.assignmentIds = [];
                object.projectIds = [];
                object.projectPostIds = [];
            }
            if (message.beingEdited != null && message.hasOwnProperty("beingEdited")) {
                object.beingEdited = message.beingEdited;
                if (options.oneofs)
                    object._beingEdited = "beingEdited";
            }
            if (message.classXIds && message.classXIds.length) {
                object.classXIds = [];
                for (var j = 0; j < message.classXIds.length; ++j)
                    object.classXIds[j] = message.classXIds[j];
            }
            if (message.userXIds && message.userXIds.length) {
                object.userXIds = [];
                for (var j = 0; j < message.userXIds.length; ++j)
                    object.userXIds[j] = message.userXIds[j];
            }
            if (message.schoolIds && message.schoolIds.length) {
                object.schoolIds = [];
                for (var j = 0; j < message.schoolIds.length; ++j)
                    object.schoolIds[j] = message.schoolIds[j];
            }
            if (message.assignmentIds && message.assignmentIds.length) {
                object.assignmentIds = [];
                for (var j = 0; j < message.assignmentIds.length; ++j)
                    object.assignmentIds[j] = message.assignmentIds[j];
            }
            if (message.projectIds && message.projectIds.length) {
                object.projectIds = [];
                for (var j = 0; j < message.projectIds.length; ++j)
                    object.projectIds[j] = message.projectIds[j];
            }
            if (message.includeTags != null && message.hasOwnProperty("includeTags")) {
                object.includeTags = message.includeTags;
                if (options.oneofs)
                    object._includeTags = "includeTags";
            }
            if (message.includeComments != null && message.hasOwnProperty("includeComments")) {
                object.includeComments = message.includeComments;
                if (options.oneofs)
                    object._includeComments = "includeComments";
            }
            if (message.includeProjects != null && message.hasOwnProperty("includeProjects")) {
                object.includeProjects = message.includeProjects;
                if (options.oneofs)
                    object._includeProjects = "includeProjects";
            }
            if (message.projectPostIds && message.projectPostIds.length) {
                object.projectPostIds = [];
                for (var j = 0; j < message.projectPostIds.length; ++j)
                    object.projectPostIds[j] = message.projectPostIds[j];
            }
            if (message.includeRatings != null && message.hasOwnProperty("includeRatings")) {
                object.includeRatings = message.includeRatings;
                if (options.oneofs)
                    object._includeRatings = "includeRatings";
            }
            if (message.includeAssignments != null && message.hasOwnProperty("includeAssignments")) {
                object.includeAssignments = message.includeAssignments;
                if (options.oneofs)
                    object._includeAssignments = "includeAssignments";
            }
            if (message.page != null && message.hasOwnProperty("page")) {
                object.page = message.page;
                if (options.oneofs)
                    object._page = "page";
            }
            if (message.pageSize != null && message.hasOwnProperty("pageSize")) {
                object.pageSize = message.pageSize;
                if (options.oneofs)
                    object._pageSize = "pageSize";
            }
            return object;
        };

        /**
         * Converts this GetProjectPostsRequest to JSON.
         * @function toJSON
         * @memberof post_service.GetProjectPostsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetProjectPostsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetProjectPostsRequest
         * @function getTypeUrl
         * @memberof post_service.GetProjectPostsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetProjectPostsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/post_service.GetProjectPostsRequest";
        };

        return GetProjectPostsRequest;
    })();

    post_service.GetProjectPostsResponse = (function() {

        /**
         * Properties of a GetProjectPostsResponse.
         * @memberof post_service
         * @interface IGetProjectPostsResponse
         * @property {Array.<pl_types.IProjectPost>|null} [projectPosts] GetProjectPostsResponse projectPosts
         * @property {number|null} [page] GetProjectPostsResponse page
         * @property {number|null} [pageSize] GetProjectPostsResponse pageSize
         * @property {Long|null} [totalProjectPosts] GetProjectPostsResponse totalProjectPosts
         */

        /**
         * Constructs a new GetProjectPostsResponse.
         * @memberof post_service
         * @classdesc Represents a GetProjectPostsResponse.
         * @implements IGetProjectPostsResponse
         * @constructor
         * @param {post_service.IGetProjectPostsResponse=} [properties] Properties to set
         */
        function GetProjectPostsResponse(properties) {
            this.projectPosts = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetProjectPostsResponse projectPosts.
         * @member {Array.<pl_types.IProjectPost>} projectPosts
         * @memberof post_service.GetProjectPostsResponse
         * @instance
         */
        GetProjectPostsResponse.prototype.projectPosts = $util.emptyArray;

        /**
         * GetProjectPostsResponse page.
         * @member {number|null|undefined} page
         * @memberof post_service.GetProjectPostsResponse
         * @instance
         */
        GetProjectPostsResponse.prototype.page = null;

        /**
         * GetProjectPostsResponse pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof post_service.GetProjectPostsResponse
         * @instance
         */
        GetProjectPostsResponse.prototype.pageSize = null;

        /**
         * GetProjectPostsResponse totalProjectPosts.
         * @member {Long|null|undefined} totalProjectPosts
         * @memberof post_service.GetProjectPostsResponse
         * @instance
         */
        GetProjectPostsResponse.prototype.totalProjectPosts = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GetProjectPostsResponse _page.
         * @member {"page"|undefined} _page
         * @memberof post_service.GetProjectPostsResponse
         * @instance
         */
        Object.defineProperty(GetProjectPostsResponse.prototype, "_page", {
            get: $util.oneOfGetter($oneOfFields = ["page"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetProjectPostsResponse _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof post_service.GetProjectPostsResponse
         * @instance
         */
        Object.defineProperty(GetProjectPostsResponse.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetProjectPostsResponse _totalProjectPosts.
         * @member {"totalProjectPosts"|undefined} _totalProjectPosts
         * @memberof post_service.GetProjectPostsResponse
         * @instance
         */
        Object.defineProperty(GetProjectPostsResponse.prototype, "_totalProjectPosts", {
            get: $util.oneOfGetter($oneOfFields = ["totalProjectPosts"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetProjectPostsResponse instance using the specified properties.
         * @function create
         * @memberof post_service.GetProjectPostsResponse
         * @static
         * @param {post_service.IGetProjectPostsResponse=} [properties] Properties to set
         * @returns {post_service.GetProjectPostsResponse} GetProjectPostsResponse instance
         */
        GetProjectPostsResponse.create = function create(properties) {
            return new GetProjectPostsResponse(properties);
        };

        /**
         * Encodes the specified GetProjectPostsResponse message. Does not implicitly {@link post_service.GetProjectPostsResponse.verify|verify} messages.
         * @function encode
         * @memberof post_service.GetProjectPostsResponse
         * @static
         * @param {post_service.IGetProjectPostsResponse} message GetProjectPostsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectPostsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectPosts != null && message.projectPosts.length)
                for (var i = 0; i < message.projectPosts.length; ++i)
                    $root.pl_types.ProjectPost.encode(message.projectPosts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageSize);
            if (message.totalProjectPosts != null && Object.hasOwnProperty.call(message, "totalProjectPosts"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.totalProjectPosts);
            return writer;
        };

        /**
         * Encodes the specified GetProjectPostsResponse message, length delimited. Does not implicitly {@link post_service.GetProjectPostsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof post_service.GetProjectPostsResponse
         * @static
         * @param {post_service.IGetProjectPostsResponse} message GetProjectPostsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectPostsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetProjectPostsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof post_service.GetProjectPostsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {post_service.GetProjectPostsResponse} GetProjectPostsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectPostsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.post_service.GetProjectPostsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.projectPosts && message.projectPosts.length))
                            message.projectPosts = [];
                        message.projectPosts.push($root.pl_types.ProjectPost.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.page = reader.int32();
                        break;
                    }
                case 3: {
                        message.pageSize = reader.int32();
                        break;
                    }
                case 4: {
                        message.totalProjectPosts = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetProjectPostsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof post_service.GetProjectPostsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {post_service.GetProjectPostsResponse} GetProjectPostsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectPostsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetProjectPostsResponse message.
         * @function verify
         * @memberof post_service.GetProjectPostsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetProjectPostsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.projectPosts != null && message.hasOwnProperty("projectPosts")) {
                if (!Array.isArray(message.projectPosts))
                    return "projectPosts: array expected";
                for (var i = 0; i < message.projectPosts.length; ++i) {
                    var error = $root.pl_types.ProjectPost.verify(message.projectPosts[i]);
                    if (error)
                        return "projectPosts." + error;
                }
            }
            if (message.page != null && message.hasOwnProperty("page")) {
                properties._page = 1;
                if (!$util.isInteger(message.page))
                    return "page: integer expected";
            }
            if (message.pageSize != null && message.hasOwnProperty("pageSize")) {
                properties._pageSize = 1;
                if (!$util.isInteger(message.pageSize))
                    return "pageSize: integer expected";
            }
            if (message.totalProjectPosts != null && message.hasOwnProperty("totalProjectPosts")) {
                properties._totalProjectPosts = 1;
                if (!$util.isInteger(message.totalProjectPosts) && !(message.totalProjectPosts && $util.isInteger(message.totalProjectPosts.low) && $util.isInteger(message.totalProjectPosts.high)))
                    return "totalProjectPosts: integer|Long expected";
            }
            return null;
        };

        /**
         * Creates a GetProjectPostsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof post_service.GetProjectPostsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {post_service.GetProjectPostsResponse} GetProjectPostsResponse
         */
        GetProjectPostsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.post_service.GetProjectPostsResponse)
                return object;
            var message = new $root.post_service.GetProjectPostsResponse();
            if (object.projectPosts) {
                if (!Array.isArray(object.projectPosts))
                    throw TypeError(".post_service.GetProjectPostsResponse.projectPosts: array expected");
                message.projectPosts = [];
                for (var i = 0; i < object.projectPosts.length; ++i) {
                    if (typeof object.projectPosts[i] !== "object")
                        throw TypeError(".post_service.GetProjectPostsResponse.projectPosts: object expected");
                    message.projectPosts[i] = $root.pl_types.ProjectPost.fromObject(object.projectPosts[i]);
                }
            }
            if (object.page != null)
                message.page = object.page | 0;
            if (object.pageSize != null)
                message.pageSize = object.pageSize | 0;
            if (object.totalProjectPosts != null)
                if ($util.Long)
                    (message.totalProjectPosts = $util.Long.fromValue(object.totalProjectPosts)).unsigned = false;
                else if (typeof object.totalProjectPosts === "string")
                    message.totalProjectPosts = parseInt(object.totalProjectPosts, 10);
                else if (typeof object.totalProjectPosts === "number")
                    message.totalProjectPosts = object.totalProjectPosts;
                else if (typeof object.totalProjectPosts === "object")
                    message.totalProjectPosts = new $util.LongBits(object.totalProjectPosts.low >>> 0, object.totalProjectPosts.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a GetProjectPostsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof post_service.GetProjectPostsResponse
         * @static
         * @param {post_service.GetProjectPostsResponse} message GetProjectPostsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetProjectPostsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.projectPosts = [];
            if (message.projectPosts && message.projectPosts.length) {
                object.projectPosts = [];
                for (var j = 0; j < message.projectPosts.length; ++j)
                    object.projectPosts[j] = $root.pl_types.ProjectPost.toObject(message.projectPosts[j], options);
            }
            if (message.page != null && message.hasOwnProperty("page")) {
                object.page = message.page;
                if (options.oneofs)
                    object._page = "page";
            }
            if (message.pageSize != null && message.hasOwnProperty("pageSize")) {
                object.pageSize = message.pageSize;
                if (options.oneofs)
                    object._pageSize = "pageSize";
            }
            if (message.totalProjectPosts != null && message.hasOwnProperty("totalProjectPosts")) {
                if (typeof message.totalProjectPosts === "number")
                    object.totalProjectPosts = options.longs === String ? String(message.totalProjectPosts) : message.totalProjectPosts;
                else
                    object.totalProjectPosts = options.longs === String ? $util.Long.prototype.toString.call(message.totalProjectPosts) : options.longs === Number ? new $util.LongBits(message.totalProjectPosts.low >>> 0, message.totalProjectPosts.high >>> 0).toNumber() : message.totalProjectPosts;
                if (options.oneofs)
                    object._totalProjectPosts = "totalProjectPosts";
            }
            return object;
        };

        /**
         * Converts this GetProjectPostsResponse to JSON.
         * @function toJSON
         * @memberof post_service.GetProjectPostsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetProjectPostsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetProjectPostsResponse
         * @function getTypeUrl
         * @memberof post_service.GetProjectPostsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetProjectPostsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/post_service.GetProjectPostsResponse";
        };

        return GetProjectPostsResponse;
    })();

    post_service.UpsertProjectPostRequest = (function() {

        /**
         * Properties of an UpsertProjectPostRequest.
         * @memberof post_service
         * @interface IUpsertProjectPostRequest
         * @property {pl_types.IProjectPost|null} [projectPost] UpsertProjectPostRequest projectPost
         */

        /**
         * Constructs a new UpsertProjectPostRequest.
         * @memberof post_service
         * @classdesc Represents an UpsertProjectPostRequest.
         * @implements IUpsertProjectPostRequest
         * @constructor
         * @param {post_service.IUpsertProjectPostRequest=} [properties] Properties to set
         */
        function UpsertProjectPostRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertProjectPostRequest projectPost.
         * @member {pl_types.IProjectPost|null|undefined} projectPost
         * @memberof post_service.UpsertProjectPostRequest
         * @instance
         */
        UpsertProjectPostRequest.prototype.projectPost = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UpsertProjectPostRequest _projectPost.
         * @member {"projectPost"|undefined} _projectPost
         * @memberof post_service.UpsertProjectPostRequest
         * @instance
         */
        Object.defineProperty(UpsertProjectPostRequest.prototype, "_projectPost", {
            get: $util.oneOfGetter($oneOfFields = ["projectPost"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UpsertProjectPostRequest instance using the specified properties.
         * @function create
         * @memberof post_service.UpsertProjectPostRequest
         * @static
         * @param {post_service.IUpsertProjectPostRequest=} [properties] Properties to set
         * @returns {post_service.UpsertProjectPostRequest} UpsertProjectPostRequest instance
         */
        UpsertProjectPostRequest.create = function create(properties) {
            return new UpsertProjectPostRequest(properties);
        };

        /**
         * Encodes the specified UpsertProjectPostRequest message. Does not implicitly {@link post_service.UpsertProjectPostRequest.verify|verify} messages.
         * @function encode
         * @memberof post_service.UpsertProjectPostRequest
         * @static
         * @param {post_service.IUpsertProjectPostRequest} message UpsertProjectPostRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertProjectPostRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectPost != null && Object.hasOwnProperty.call(message, "projectPost"))
                $root.pl_types.ProjectPost.encode(message.projectPost, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpsertProjectPostRequest message, length delimited. Does not implicitly {@link post_service.UpsertProjectPostRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof post_service.UpsertProjectPostRequest
         * @static
         * @param {post_service.IUpsertProjectPostRequest} message UpsertProjectPostRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertProjectPostRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpsertProjectPostRequest message from the specified reader or buffer.
         * @function decode
         * @memberof post_service.UpsertProjectPostRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {post_service.UpsertProjectPostRequest} UpsertProjectPostRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertProjectPostRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.post_service.UpsertProjectPostRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.projectPost = $root.pl_types.ProjectPost.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpsertProjectPostRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof post_service.UpsertProjectPostRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {post_service.UpsertProjectPostRequest} UpsertProjectPostRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertProjectPostRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpsertProjectPostRequest message.
         * @function verify
         * @memberof post_service.UpsertProjectPostRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpsertProjectPostRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.projectPost != null && message.hasOwnProperty("projectPost")) {
                properties._projectPost = 1;
                {
                    var error = $root.pl_types.ProjectPost.verify(message.projectPost);
                    if (error)
                        return "projectPost." + error;
                }
            }
            return null;
        };

        /**
         * Creates an UpsertProjectPostRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof post_service.UpsertProjectPostRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {post_service.UpsertProjectPostRequest} UpsertProjectPostRequest
         */
        UpsertProjectPostRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.post_service.UpsertProjectPostRequest)
                return object;
            var message = new $root.post_service.UpsertProjectPostRequest();
            if (object.projectPost != null) {
                if (typeof object.projectPost !== "object")
                    throw TypeError(".post_service.UpsertProjectPostRequest.projectPost: object expected");
                message.projectPost = $root.pl_types.ProjectPost.fromObject(object.projectPost);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpsertProjectPostRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof post_service.UpsertProjectPostRequest
         * @static
         * @param {post_service.UpsertProjectPostRequest} message UpsertProjectPostRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpsertProjectPostRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.projectPost != null && message.hasOwnProperty("projectPost")) {
                object.projectPost = $root.pl_types.ProjectPost.toObject(message.projectPost, options);
                if (options.oneofs)
                    object._projectPost = "projectPost";
            }
            return object;
        };

        /**
         * Converts this UpsertProjectPostRequest to JSON.
         * @function toJSON
         * @memberof post_service.UpsertProjectPostRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpsertProjectPostRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpsertProjectPostRequest
         * @function getTypeUrl
         * @memberof post_service.UpsertProjectPostRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpsertProjectPostRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/post_service.UpsertProjectPostRequest";
        };

        return UpsertProjectPostRequest;
    })();

    post_service.UpsertProjectPostResponse = (function() {

        /**
         * Properties of an UpsertProjectPostResponse.
         * @memberof post_service
         * @interface IUpsertProjectPostResponse
         * @property {number|null} [projectPostId] UpsertProjectPostResponse projectPostId
         */

        /**
         * Constructs a new UpsertProjectPostResponse.
         * @memberof post_service
         * @classdesc Represents an UpsertProjectPostResponse.
         * @implements IUpsertProjectPostResponse
         * @constructor
         * @param {post_service.IUpsertProjectPostResponse=} [properties] Properties to set
         */
        function UpsertProjectPostResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertProjectPostResponse projectPostId.
         * @member {number|null|undefined} projectPostId
         * @memberof post_service.UpsertProjectPostResponse
         * @instance
         */
        UpsertProjectPostResponse.prototype.projectPostId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UpsertProjectPostResponse _projectPostId.
         * @member {"projectPostId"|undefined} _projectPostId
         * @memberof post_service.UpsertProjectPostResponse
         * @instance
         */
        Object.defineProperty(UpsertProjectPostResponse.prototype, "_projectPostId", {
            get: $util.oneOfGetter($oneOfFields = ["projectPostId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UpsertProjectPostResponse instance using the specified properties.
         * @function create
         * @memberof post_service.UpsertProjectPostResponse
         * @static
         * @param {post_service.IUpsertProjectPostResponse=} [properties] Properties to set
         * @returns {post_service.UpsertProjectPostResponse} UpsertProjectPostResponse instance
         */
        UpsertProjectPostResponse.create = function create(properties) {
            return new UpsertProjectPostResponse(properties);
        };

        /**
         * Encodes the specified UpsertProjectPostResponse message. Does not implicitly {@link post_service.UpsertProjectPostResponse.verify|verify} messages.
         * @function encode
         * @memberof post_service.UpsertProjectPostResponse
         * @static
         * @param {post_service.IUpsertProjectPostResponse} message UpsertProjectPostResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertProjectPostResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectPostId != null && Object.hasOwnProperty.call(message, "projectPostId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.projectPostId);
            return writer;
        };

        /**
         * Encodes the specified UpsertProjectPostResponse message, length delimited. Does not implicitly {@link post_service.UpsertProjectPostResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof post_service.UpsertProjectPostResponse
         * @static
         * @param {post_service.IUpsertProjectPostResponse} message UpsertProjectPostResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertProjectPostResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpsertProjectPostResponse message from the specified reader or buffer.
         * @function decode
         * @memberof post_service.UpsertProjectPostResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {post_service.UpsertProjectPostResponse} UpsertProjectPostResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertProjectPostResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.post_service.UpsertProjectPostResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.projectPostId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpsertProjectPostResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof post_service.UpsertProjectPostResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {post_service.UpsertProjectPostResponse} UpsertProjectPostResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertProjectPostResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpsertProjectPostResponse message.
         * @function verify
         * @memberof post_service.UpsertProjectPostResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpsertProjectPostResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.projectPostId != null && message.hasOwnProperty("projectPostId")) {
                properties._projectPostId = 1;
                if (!$util.isInteger(message.projectPostId))
                    return "projectPostId: integer expected";
            }
            return null;
        };

        /**
         * Creates an UpsertProjectPostResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof post_service.UpsertProjectPostResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {post_service.UpsertProjectPostResponse} UpsertProjectPostResponse
         */
        UpsertProjectPostResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.post_service.UpsertProjectPostResponse)
                return object;
            var message = new $root.post_service.UpsertProjectPostResponse();
            if (object.projectPostId != null)
                message.projectPostId = object.projectPostId | 0;
            return message;
        };

        /**
         * Creates a plain object from an UpsertProjectPostResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof post_service.UpsertProjectPostResponse
         * @static
         * @param {post_service.UpsertProjectPostResponse} message UpsertProjectPostResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpsertProjectPostResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.projectPostId != null && message.hasOwnProperty("projectPostId")) {
                object.projectPostId = message.projectPostId;
                if (options.oneofs)
                    object._projectPostId = "projectPostId";
            }
            return object;
        };

        /**
         * Converts this UpsertProjectPostResponse to JSON.
         * @function toJSON
         * @memberof post_service.UpsertProjectPostResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpsertProjectPostResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpsertProjectPostResponse
         * @function getTypeUrl
         * @memberof post_service.UpsertProjectPostResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpsertProjectPostResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/post_service.UpsertProjectPostResponse";
        };

        return UpsertProjectPostResponse;
    })();

    post_service.UpsertProjectPostCommentRequest = (function() {

        /**
         * Properties of an UpsertProjectPostCommentRequest.
         * @memberof post_service
         * @interface IUpsertProjectPostCommentRequest
         * @property {pl_types.IProjectPostComment|null} [projectPostComment] UpsertProjectPostCommentRequest projectPostComment
         */

        /**
         * Constructs a new UpsertProjectPostCommentRequest.
         * @memberof post_service
         * @classdesc Represents an UpsertProjectPostCommentRequest.
         * @implements IUpsertProjectPostCommentRequest
         * @constructor
         * @param {post_service.IUpsertProjectPostCommentRequest=} [properties] Properties to set
         */
        function UpsertProjectPostCommentRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertProjectPostCommentRequest projectPostComment.
         * @member {pl_types.IProjectPostComment|null|undefined} projectPostComment
         * @memberof post_service.UpsertProjectPostCommentRequest
         * @instance
         */
        UpsertProjectPostCommentRequest.prototype.projectPostComment = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UpsertProjectPostCommentRequest _projectPostComment.
         * @member {"projectPostComment"|undefined} _projectPostComment
         * @memberof post_service.UpsertProjectPostCommentRequest
         * @instance
         */
        Object.defineProperty(UpsertProjectPostCommentRequest.prototype, "_projectPostComment", {
            get: $util.oneOfGetter($oneOfFields = ["projectPostComment"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UpsertProjectPostCommentRequest instance using the specified properties.
         * @function create
         * @memberof post_service.UpsertProjectPostCommentRequest
         * @static
         * @param {post_service.IUpsertProjectPostCommentRequest=} [properties] Properties to set
         * @returns {post_service.UpsertProjectPostCommentRequest} UpsertProjectPostCommentRequest instance
         */
        UpsertProjectPostCommentRequest.create = function create(properties) {
            return new UpsertProjectPostCommentRequest(properties);
        };

        /**
         * Encodes the specified UpsertProjectPostCommentRequest message. Does not implicitly {@link post_service.UpsertProjectPostCommentRequest.verify|verify} messages.
         * @function encode
         * @memberof post_service.UpsertProjectPostCommentRequest
         * @static
         * @param {post_service.IUpsertProjectPostCommentRequest} message UpsertProjectPostCommentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertProjectPostCommentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectPostComment != null && Object.hasOwnProperty.call(message, "projectPostComment"))
                $root.pl_types.ProjectPostComment.encode(message.projectPostComment, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpsertProjectPostCommentRequest message, length delimited. Does not implicitly {@link post_service.UpsertProjectPostCommentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof post_service.UpsertProjectPostCommentRequest
         * @static
         * @param {post_service.IUpsertProjectPostCommentRequest} message UpsertProjectPostCommentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertProjectPostCommentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpsertProjectPostCommentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof post_service.UpsertProjectPostCommentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {post_service.UpsertProjectPostCommentRequest} UpsertProjectPostCommentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertProjectPostCommentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.post_service.UpsertProjectPostCommentRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.projectPostComment = $root.pl_types.ProjectPostComment.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpsertProjectPostCommentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof post_service.UpsertProjectPostCommentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {post_service.UpsertProjectPostCommentRequest} UpsertProjectPostCommentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertProjectPostCommentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpsertProjectPostCommentRequest message.
         * @function verify
         * @memberof post_service.UpsertProjectPostCommentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpsertProjectPostCommentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.projectPostComment != null && message.hasOwnProperty("projectPostComment")) {
                properties._projectPostComment = 1;
                {
                    var error = $root.pl_types.ProjectPostComment.verify(message.projectPostComment);
                    if (error)
                        return "projectPostComment." + error;
                }
            }
            return null;
        };

        /**
         * Creates an UpsertProjectPostCommentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof post_service.UpsertProjectPostCommentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {post_service.UpsertProjectPostCommentRequest} UpsertProjectPostCommentRequest
         */
        UpsertProjectPostCommentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.post_service.UpsertProjectPostCommentRequest)
                return object;
            var message = new $root.post_service.UpsertProjectPostCommentRequest();
            if (object.projectPostComment != null) {
                if (typeof object.projectPostComment !== "object")
                    throw TypeError(".post_service.UpsertProjectPostCommentRequest.projectPostComment: object expected");
                message.projectPostComment = $root.pl_types.ProjectPostComment.fromObject(object.projectPostComment);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpsertProjectPostCommentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof post_service.UpsertProjectPostCommentRequest
         * @static
         * @param {post_service.UpsertProjectPostCommentRequest} message UpsertProjectPostCommentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpsertProjectPostCommentRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.projectPostComment != null && message.hasOwnProperty("projectPostComment")) {
                object.projectPostComment = $root.pl_types.ProjectPostComment.toObject(message.projectPostComment, options);
                if (options.oneofs)
                    object._projectPostComment = "projectPostComment";
            }
            return object;
        };

        /**
         * Converts this UpsertProjectPostCommentRequest to JSON.
         * @function toJSON
         * @memberof post_service.UpsertProjectPostCommentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpsertProjectPostCommentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpsertProjectPostCommentRequest
         * @function getTypeUrl
         * @memberof post_service.UpsertProjectPostCommentRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpsertProjectPostCommentRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/post_service.UpsertProjectPostCommentRequest";
        };

        return UpsertProjectPostCommentRequest;
    })();

    post_service.UpsertProjectPostCommentResponse = (function() {

        /**
         * Properties of an UpsertProjectPostCommentResponse.
         * @memberof post_service
         * @interface IUpsertProjectPostCommentResponse
         * @property {pl_types.IProjectPostComment|null} [projectPostComment] UpsertProjectPostCommentResponse projectPostComment
         */

        /**
         * Constructs a new UpsertProjectPostCommentResponse.
         * @memberof post_service
         * @classdesc Represents an UpsertProjectPostCommentResponse.
         * @implements IUpsertProjectPostCommentResponse
         * @constructor
         * @param {post_service.IUpsertProjectPostCommentResponse=} [properties] Properties to set
         */
        function UpsertProjectPostCommentResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertProjectPostCommentResponse projectPostComment.
         * @member {pl_types.IProjectPostComment|null|undefined} projectPostComment
         * @memberof post_service.UpsertProjectPostCommentResponse
         * @instance
         */
        UpsertProjectPostCommentResponse.prototype.projectPostComment = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UpsertProjectPostCommentResponse _projectPostComment.
         * @member {"projectPostComment"|undefined} _projectPostComment
         * @memberof post_service.UpsertProjectPostCommentResponse
         * @instance
         */
        Object.defineProperty(UpsertProjectPostCommentResponse.prototype, "_projectPostComment", {
            get: $util.oneOfGetter($oneOfFields = ["projectPostComment"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UpsertProjectPostCommentResponse instance using the specified properties.
         * @function create
         * @memberof post_service.UpsertProjectPostCommentResponse
         * @static
         * @param {post_service.IUpsertProjectPostCommentResponse=} [properties] Properties to set
         * @returns {post_service.UpsertProjectPostCommentResponse} UpsertProjectPostCommentResponse instance
         */
        UpsertProjectPostCommentResponse.create = function create(properties) {
            return new UpsertProjectPostCommentResponse(properties);
        };

        /**
         * Encodes the specified UpsertProjectPostCommentResponse message. Does not implicitly {@link post_service.UpsertProjectPostCommentResponse.verify|verify} messages.
         * @function encode
         * @memberof post_service.UpsertProjectPostCommentResponse
         * @static
         * @param {post_service.IUpsertProjectPostCommentResponse} message UpsertProjectPostCommentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertProjectPostCommentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectPostComment != null && Object.hasOwnProperty.call(message, "projectPostComment"))
                $root.pl_types.ProjectPostComment.encode(message.projectPostComment, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpsertProjectPostCommentResponse message, length delimited. Does not implicitly {@link post_service.UpsertProjectPostCommentResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof post_service.UpsertProjectPostCommentResponse
         * @static
         * @param {post_service.IUpsertProjectPostCommentResponse} message UpsertProjectPostCommentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertProjectPostCommentResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpsertProjectPostCommentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof post_service.UpsertProjectPostCommentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {post_service.UpsertProjectPostCommentResponse} UpsertProjectPostCommentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertProjectPostCommentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.post_service.UpsertProjectPostCommentResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.projectPostComment = $root.pl_types.ProjectPostComment.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpsertProjectPostCommentResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof post_service.UpsertProjectPostCommentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {post_service.UpsertProjectPostCommentResponse} UpsertProjectPostCommentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertProjectPostCommentResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpsertProjectPostCommentResponse message.
         * @function verify
         * @memberof post_service.UpsertProjectPostCommentResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpsertProjectPostCommentResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.projectPostComment != null && message.hasOwnProperty("projectPostComment")) {
                properties._projectPostComment = 1;
                {
                    var error = $root.pl_types.ProjectPostComment.verify(message.projectPostComment);
                    if (error)
                        return "projectPostComment." + error;
                }
            }
            return null;
        };

        /**
         * Creates an UpsertProjectPostCommentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof post_service.UpsertProjectPostCommentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {post_service.UpsertProjectPostCommentResponse} UpsertProjectPostCommentResponse
         */
        UpsertProjectPostCommentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.post_service.UpsertProjectPostCommentResponse)
                return object;
            var message = new $root.post_service.UpsertProjectPostCommentResponse();
            if (object.projectPostComment != null) {
                if (typeof object.projectPostComment !== "object")
                    throw TypeError(".post_service.UpsertProjectPostCommentResponse.projectPostComment: object expected");
                message.projectPostComment = $root.pl_types.ProjectPostComment.fromObject(object.projectPostComment);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpsertProjectPostCommentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof post_service.UpsertProjectPostCommentResponse
         * @static
         * @param {post_service.UpsertProjectPostCommentResponse} message UpsertProjectPostCommentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpsertProjectPostCommentResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.projectPostComment != null && message.hasOwnProperty("projectPostComment")) {
                object.projectPostComment = $root.pl_types.ProjectPostComment.toObject(message.projectPostComment, options);
                if (options.oneofs)
                    object._projectPostComment = "projectPostComment";
            }
            return object;
        };

        /**
         * Converts this UpsertProjectPostCommentResponse to JSON.
         * @function toJSON
         * @memberof post_service.UpsertProjectPostCommentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpsertProjectPostCommentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpsertProjectPostCommentResponse
         * @function getTypeUrl
         * @memberof post_service.UpsertProjectPostCommentResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpsertProjectPostCommentResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/post_service.UpsertProjectPostCommentResponse";
        };

        return UpsertProjectPostCommentResponse;
    })();

    post_service.DeleteProjectPostCommentRequest = (function() {

        /**
         * Properties of a DeleteProjectPostCommentRequest.
         * @memberof post_service
         * @interface IDeleteProjectPostCommentRequest
         * @property {number|null} [projectPostCommentId] DeleteProjectPostCommentRequest projectPostCommentId
         */

        /**
         * Constructs a new DeleteProjectPostCommentRequest.
         * @memberof post_service
         * @classdesc Represents a DeleteProjectPostCommentRequest.
         * @implements IDeleteProjectPostCommentRequest
         * @constructor
         * @param {post_service.IDeleteProjectPostCommentRequest=} [properties] Properties to set
         */
        function DeleteProjectPostCommentRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteProjectPostCommentRequest projectPostCommentId.
         * @member {number|null|undefined} projectPostCommentId
         * @memberof post_service.DeleteProjectPostCommentRequest
         * @instance
         */
        DeleteProjectPostCommentRequest.prototype.projectPostCommentId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * DeleteProjectPostCommentRequest _projectPostCommentId.
         * @member {"projectPostCommentId"|undefined} _projectPostCommentId
         * @memberof post_service.DeleteProjectPostCommentRequest
         * @instance
         */
        Object.defineProperty(DeleteProjectPostCommentRequest.prototype, "_projectPostCommentId", {
            get: $util.oneOfGetter($oneOfFields = ["projectPostCommentId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new DeleteProjectPostCommentRequest instance using the specified properties.
         * @function create
         * @memberof post_service.DeleteProjectPostCommentRequest
         * @static
         * @param {post_service.IDeleteProjectPostCommentRequest=} [properties] Properties to set
         * @returns {post_service.DeleteProjectPostCommentRequest} DeleteProjectPostCommentRequest instance
         */
        DeleteProjectPostCommentRequest.create = function create(properties) {
            return new DeleteProjectPostCommentRequest(properties);
        };

        /**
         * Encodes the specified DeleteProjectPostCommentRequest message. Does not implicitly {@link post_service.DeleteProjectPostCommentRequest.verify|verify} messages.
         * @function encode
         * @memberof post_service.DeleteProjectPostCommentRequest
         * @static
         * @param {post_service.IDeleteProjectPostCommentRequest} message DeleteProjectPostCommentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteProjectPostCommentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectPostCommentId != null && Object.hasOwnProperty.call(message, "projectPostCommentId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.projectPostCommentId);
            return writer;
        };

        /**
         * Encodes the specified DeleteProjectPostCommentRequest message, length delimited. Does not implicitly {@link post_service.DeleteProjectPostCommentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof post_service.DeleteProjectPostCommentRequest
         * @static
         * @param {post_service.IDeleteProjectPostCommentRequest} message DeleteProjectPostCommentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteProjectPostCommentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteProjectPostCommentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof post_service.DeleteProjectPostCommentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {post_service.DeleteProjectPostCommentRequest} DeleteProjectPostCommentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteProjectPostCommentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.post_service.DeleteProjectPostCommentRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.projectPostCommentId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteProjectPostCommentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof post_service.DeleteProjectPostCommentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {post_service.DeleteProjectPostCommentRequest} DeleteProjectPostCommentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteProjectPostCommentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteProjectPostCommentRequest message.
         * @function verify
         * @memberof post_service.DeleteProjectPostCommentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteProjectPostCommentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.projectPostCommentId != null && message.hasOwnProperty("projectPostCommentId")) {
                properties._projectPostCommentId = 1;
                if (!$util.isInteger(message.projectPostCommentId))
                    return "projectPostCommentId: integer expected";
            }
            return null;
        };

        /**
         * Creates a DeleteProjectPostCommentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof post_service.DeleteProjectPostCommentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {post_service.DeleteProjectPostCommentRequest} DeleteProjectPostCommentRequest
         */
        DeleteProjectPostCommentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.post_service.DeleteProjectPostCommentRequest)
                return object;
            var message = new $root.post_service.DeleteProjectPostCommentRequest();
            if (object.projectPostCommentId != null)
                message.projectPostCommentId = object.projectPostCommentId | 0;
            return message;
        };

        /**
         * Creates a plain object from a DeleteProjectPostCommentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof post_service.DeleteProjectPostCommentRequest
         * @static
         * @param {post_service.DeleteProjectPostCommentRequest} message DeleteProjectPostCommentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteProjectPostCommentRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.projectPostCommentId != null && message.hasOwnProperty("projectPostCommentId")) {
                object.projectPostCommentId = message.projectPostCommentId;
                if (options.oneofs)
                    object._projectPostCommentId = "projectPostCommentId";
            }
            return object;
        };

        /**
         * Converts this DeleteProjectPostCommentRequest to JSON.
         * @function toJSON
         * @memberof post_service.DeleteProjectPostCommentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteProjectPostCommentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteProjectPostCommentRequest
         * @function getTypeUrl
         * @memberof post_service.DeleteProjectPostCommentRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteProjectPostCommentRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/post_service.DeleteProjectPostCommentRequest";
        };

        return DeleteProjectPostCommentRequest;
    })();

    post_service.DeleteProjectPostCommentResponse = (function() {

        /**
         * Properties of a DeleteProjectPostCommentResponse.
         * @memberof post_service
         * @interface IDeleteProjectPostCommentResponse
         */

        /**
         * Constructs a new DeleteProjectPostCommentResponse.
         * @memberof post_service
         * @classdesc Represents a DeleteProjectPostCommentResponse.
         * @implements IDeleteProjectPostCommentResponse
         * @constructor
         * @param {post_service.IDeleteProjectPostCommentResponse=} [properties] Properties to set
         */
        function DeleteProjectPostCommentResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteProjectPostCommentResponse instance using the specified properties.
         * @function create
         * @memberof post_service.DeleteProjectPostCommentResponse
         * @static
         * @param {post_service.IDeleteProjectPostCommentResponse=} [properties] Properties to set
         * @returns {post_service.DeleteProjectPostCommentResponse} DeleteProjectPostCommentResponse instance
         */
        DeleteProjectPostCommentResponse.create = function create(properties) {
            return new DeleteProjectPostCommentResponse(properties);
        };

        /**
         * Encodes the specified DeleteProjectPostCommentResponse message. Does not implicitly {@link post_service.DeleteProjectPostCommentResponse.verify|verify} messages.
         * @function encode
         * @memberof post_service.DeleteProjectPostCommentResponse
         * @static
         * @param {post_service.IDeleteProjectPostCommentResponse} message DeleteProjectPostCommentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteProjectPostCommentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteProjectPostCommentResponse message, length delimited. Does not implicitly {@link post_service.DeleteProjectPostCommentResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof post_service.DeleteProjectPostCommentResponse
         * @static
         * @param {post_service.IDeleteProjectPostCommentResponse} message DeleteProjectPostCommentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteProjectPostCommentResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteProjectPostCommentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof post_service.DeleteProjectPostCommentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {post_service.DeleteProjectPostCommentResponse} DeleteProjectPostCommentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteProjectPostCommentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.post_service.DeleteProjectPostCommentResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteProjectPostCommentResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof post_service.DeleteProjectPostCommentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {post_service.DeleteProjectPostCommentResponse} DeleteProjectPostCommentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteProjectPostCommentResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteProjectPostCommentResponse message.
         * @function verify
         * @memberof post_service.DeleteProjectPostCommentResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteProjectPostCommentResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteProjectPostCommentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof post_service.DeleteProjectPostCommentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {post_service.DeleteProjectPostCommentResponse} DeleteProjectPostCommentResponse
         */
        DeleteProjectPostCommentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.post_service.DeleteProjectPostCommentResponse)
                return object;
            return new $root.post_service.DeleteProjectPostCommentResponse();
        };

        /**
         * Creates a plain object from a DeleteProjectPostCommentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof post_service.DeleteProjectPostCommentResponse
         * @static
         * @param {post_service.DeleteProjectPostCommentResponse} message DeleteProjectPostCommentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteProjectPostCommentResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteProjectPostCommentResponse to JSON.
         * @function toJSON
         * @memberof post_service.DeleteProjectPostCommentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteProjectPostCommentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteProjectPostCommentResponse
         * @function getTypeUrl
         * @memberof post_service.DeleteProjectPostCommentResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteProjectPostCommentResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/post_service.DeleteProjectPostCommentResponse";
        };

        return DeleteProjectPostCommentResponse;
    })();

    post_service.UpsertProjectPostRatingRequest = (function() {

        /**
         * Properties of an UpsertProjectPostRatingRequest.
         * @memberof post_service
         * @interface IUpsertProjectPostRatingRequest
         * @property {pl_types.IProjectPostRating|null} [projectPostRating] UpsertProjectPostRatingRequest projectPostRating
         */

        /**
         * Constructs a new UpsertProjectPostRatingRequest.
         * @memberof post_service
         * @classdesc Represents an UpsertProjectPostRatingRequest.
         * @implements IUpsertProjectPostRatingRequest
         * @constructor
         * @param {post_service.IUpsertProjectPostRatingRequest=} [properties] Properties to set
         */
        function UpsertProjectPostRatingRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertProjectPostRatingRequest projectPostRating.
         * @member {pl_types.IProjectPostRating|null|undefined} projectPostRating
         * @memberof post_service.UpsertProjectPostRatingRequest
         * @instance
         */
        UpsertProjectPostRatingRequest.prototype.projectPostRating = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UpsertProjectPostRatingRequest _projectPostRating.
         * @member {"projectPostRating"|undefined} _projectPostRating
         * @memberof post_service.UpsertProjectPostRatingRequest
         * @instance
         */
        Object.defineProperty(UpsertProjectPostRatingRequest.prototype, "_projectPostRating", {
            get: $util.oneOfGetter($oneOfFields = ["projectPostRating"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UpsertProjectPostRatingRequest instance using the specified properties.
         * @function create
         * @memberof post_service.UpsertProjectPostRatingRequest
         * @static
         * @param {post_service.IUpsertProjectPostRatingRequest=} [properties] Properties to set
         * @returns {post_service.UpsertProjectPostRatingRequest} UpsertProjectPostRatingRequest instance
         */
        UpsertProjectPostRatingRequest.create = function create(properties) {
            return new UpsertProjectPostRatingRequest(properties);
        };

        /**
         * Encodes the specified UpsertProjectPostRatingRequest message. Does not implicitly {@link post_service.UpsertProjectPostRatingRequest.verify|verify} messages.
         * @function encode
         * @memberof post_service.UpsertProjectPostRatingRequest
         * @static
         * @param {post_service.IUpsertProjectPostRatingRequest} message UpsertProjectPostRatingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertProjectPostRatingRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectPostRating != null && Object.hasOwnProperty.call(message, "projectPostRating"))
                $root.pl_types.ProjectPostRating.encode(message.projectPostRating, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpsertProjectPostRatingRequest message, length delimited. Does not implicitly {@link post_service.UpsertProjectPostRatingRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof post_service.UpsertProjectPostRatingRequest
         * @static
         * @param {post_service.IUpsertProjectPostRatingRequest} message UpsertProjectPostRatingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertProjectPostRatingRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpsertProjectPostRatingRequest message from the specified reader or buffer.
         * @function decode
         * @memberof post_service.UpsertProjectPostRatingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {post_service.UpsertProjectPostRatingRequest} UpsertProjectPostRatingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertProjectPostRatingRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.post_service.UpsertProjectPostRatingRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.projectPostRating = $root.pl_types.ProjectPostRating.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpsertProjectPostRatingRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof post_service.UpsertProjectPostRatingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {post_service.UpsertProjectPostRatingRequest} UpsertProjectPostRatingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertProjectPostRatingRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpsertProjectPostRatingRequest message.
         * @function verify
         * @memberof post_service.UpsertProjectPostRatingRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpsertProjectPostRatingRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.projectPostRating != null && message.hasOwnProperty("projectPostRating")) {
                properties._projectPostRating = 1;
                {
                    var error = $root.pl_types.ProjectPostRating.verify(message.projectPostRating);
                    if (error)
                        return "projectPostRating." + error;
                }
            }
            return null;
        };

        /**
         * Creates an UpsertProjectPostRatingRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof post_service.UpsertProjectPostRatingRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {post_service.UpsertProjectPostRatingRequest} UpsertProjectPostRatingRequest
         */
        UpsertProjectPostRatingRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.post_service.UpsertProjectPostRatingRequest)
                return object;
            var message = new $root.post_service.UpsertProjectPostRatingRequest();
            if (object.projectPostRating != null) {
                if (typeof object.projectPostRating !== "object")
                    throw TypeError(".post_service.UpsertProjectPostRatingRequest.projectPostRating: object expected");
                message.projectPostRating = $root.pl_types.ProjectPostRating.fromObject(object.projectPostRating);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpsertProjectPostRatingRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof post_service.UpsertProjectPostRatingRequest
         * @static
         * @param {post_service.UpsertProjectPostRatingRequest} message UpsertProjectPostRatingRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpsertProjectPostRatingRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.projectPostRating != null && message.hasOwnProperty("projectPostRating")) {
                object.projectPostRating = $root.pl_types.ProjectPostRating.toObject(message.projectPostRating, options);
                if (options.oneofs)
                    object._projectPostRating = "projectPostRating";
            }
            return object;
        };

        /**
         * Converts this UpsertProjectPostRatingRequest to JSON.
         * @function toJSON
         * @memberof post_service.UpsertProjectPostRatingRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpsertProjectPostRatingRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpsertProjectPostRatingRequest
         * @function getTypeUrl
         * @memberof post_service.UpsertProjectPostRatingRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpsertProjectPostRatingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/post_service.UpsertProjectPostRatingRequest";
        };

        return UpsertProjectPostRatingRequest;
    })();

    post_service.UpsertProjectPostRatingResponse = (function() {

        /**
         * Properties of an UpsertProjectPostRatingResponse.
         * @memberof post_service
         * @interface IUpsertProjectPostRatingResponse
         * @property {number|null} [id] UpsertProjectPostRatingResponse id
         */

        /**
         * Constructs a new UpsertProjectPostRatingResponse.
         * @memberof post_service
         * @classdesc Represents an UpsertProjectPostRatingResponse.
         * @implements IUpsertProjectPostRatingResponse
         * @constructor
         * @param {post_service.IUpsertProjectPostRatingResponse=} [properties] Properties to set
         */
        function UpsertProjectPostRatingResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertProjectPostRatingResponse id.
         * @member {number|null|undefined} id
         * @memberof post_service.UpsertProjectPostRatingResponse
         * @instance
         */
        UpsertProjectPostRatingResponse.prototype.id = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UpsertProjectPostRatingResponse _id.
         * @member {"id"|undefined} _id
         * @memberof post_service.UpsertProjectPostRatingResponse
         * @instance
         */
        Object.defineProperty(UpsertProjectPostRatingResponse.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UpsertProjectPostRatingResponse instance using the specified properties.
         * @function create
         * @memberof post_service.UpsertProjectPostRatingResponse
         * @static
         * @param {post_service.IUpsertProjectPostRatingResponse=} [properties] Properties to set
         * @returns {post_service.UpsertProjectPostRatingResponse} UpsertProjectPostRatingResponse instance
         */
        UpsertProjectPostRatingResponse.create = function create(properties) {
            return new UpsertProjectPostRatingResponse(properties);
        };

        /**
         * Encodes the specified UpsertProjectPostRatingResponse message. Does not implicitly {@link post_service.UpsertProjectPostRatingResponse.verify|verify} messages.
         * @function encode
         * @memberof post_service.UpsertProjectPostRatingResponse
         * @static
         * @param {post_service.IUpsertProjectPostRatingResponse} message UpsertProjectPostRatingResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertProjectPostRatingResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            return writer;
        };

        /**
         * Encodes the specified UpsertProjectPostRatingResponse message, length delimited. Does not implicitly {@link post_service.UpsertProjectPostRatingResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof post_service.UpsertProjectPostRatingResponse
         * @static
         * @param {post_service.IUpsertProjectPostRatingResponse} message UpsertProjectPostRatingResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertProjectPostRatingResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpsertProjectPostRatingResponse message from the specified reader or buffer.
         * @function decode
         * @memberof post_service.UpsertProjectPostRatingResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {post_service.UpsertProjectPostRatingResponse} UpsertProjectPostRatingResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertProjectPostRatingResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.post_service.UpsertProjectPostRatingResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpsertProjectPostRatingResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof post_service.UpsertProjectPostRatingResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {post_service.UpsertProjectPostRatingResponse} UpsertProjectPostRatingResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertProjectPostRatingResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpsertProjectPostRatingResponse message.
         * @function verify
         * @memberof post_service.UpsertProjectPostRatingResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpsertProjectPostRatingResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            }
            return null;
        };

        /**
         * Creates an UpsertProjectPostRatingResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof post_service.UpsertProjectPostRatingResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {post_service.UpsertProjectPostRatingResponse} UpsertProjectPostRatingResponse
         */
        UpsertProjectPostRatingResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.post_service.UpsertProjectPostRatingResponse)
                return object;
            var message = new $root.post_service.UpsertProjectPostRatingResponse();
            if (object.id != null)
                message.id = object.id | 0;
            return message;
        };

        /**
         * Creates a plain object from an UpsertProjectPostRatingResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof post_service.UpsertProjectPostRatingResponse
         * @static
         * @param {post_service.UpsertProjectPostRatingResponse} message UpsertProjectPostRatingResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpsertProjectPostRatingResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            return object;
        };

        /**
         * Converts this UpsertProjectPostRatingResponse to JSON.
         * @function toJSON
         * @memberof post_service.UpsertProjectPostRatingResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpsertProjectPostRatingResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpsertProjectPostRatingResponse
         * @function getTypeUrl
         * @memberof post_service.UpsertProjectPostRatingResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpsertProjectPostRatingResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/post_service.UpsertProjectPostRatingResponse";
        };

        return UpsertProjectPostRatingResponse;
    })();

    return post_service;
})();

$root.project_service = (function() {

    /**
     * Namespace project_service.
     * @exports project_service
     * @namespace
     */
    var project_service = {};

    project_service.ProjectService = (function() {

        /**
         * Constructs a new ProjectService service.
         * @memberof project_service
         * @classdesc Represents a ProjectService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ProjectService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ProjectService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ProjectService;

        /**
         * Creates new ProjectService service using the specified rpc implementation.
         * @function create
         * @memberof project_service.ProjectService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {ProjectService} RPC service. Useful where requests and/or responses are streamed.
         */
        ProjectService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link project_service.ProjectService#getMotivations}.
         * @memberof project_service.ProjectService
         * @typedef GetMotivationsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {project_service.GetMotivationsResponse} [response] GetMotivationsResponse
         */

        /**
         * Calls GetMotivations.
         * @function getMotivations
         * @memberof project_service.ProjectService
         * @instance
         * @param {project_service.IGetMotivationsRequest} request GetMotivationsRequest message or plain object
         * @param {project_service.ProjectService.GetMotivationsCallback} callback Node-style callback called with the error, if any, and GetMotivationsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProjectService.prototype.getMotivations = function getMotivations(request, callback) {
            return this.rpcCall(getMotivations, $root.project_service.GetMotivationsRequest, $root.project_service.GetMotivationsResponse, request, callback);
        }, "name", { value: "GetMotivations" });

        /**
         * Calls GetMotivations.
         * @function getMotivations
         * @memberof project_service.ProjectService
         * @instance
         * @param {project_service.IGetMotivationsRequest} request GetMotivationsRequest message or plain object
         * @returns {Promise<project_service.GetMotivationsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link project_service.ProjectService#getKnowledgeAndSkills}.
         * @memberof project_service.ProjectService
         * @typedef GetKnowledgeAndSkillsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {project_service.GetKnowledgeAndSkillsResponse} [response] GetKnowledgeAndSkillsResponse
         */

        /**
         * Calls GetKnowledgeAndSkills.
         * @function getKnowledgeAndSkills
         * @memberof project_service.ProjectService
         * @instance
         * @param {project_service.IGetKnowledgeAndSkillsRequest} request GetKnowledgeAndSkillsRequest message or plain object
         * @param {project_service.ProjectService.GetKnowledgeAndSkillsCallback} callback Node-style callback called with the error, if any, and GetKnowledgeAndSkillsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProjectService.prototype.getKnowledgeAndSkills = function getKnowledgeAndSkills(request, callback) {
            return this.rpcCall(getKnowledgeAndSkills, $root.project_service.GetKnowledgeAndSkillsRequest, $root.project_service.GetKnowledgeAndSkillsResponse, request, callback);
        }, "name", { value: "GetKnowledgeAndSkills" });

        /**
         * Calls GetKnowledgeAndSkills.
         * @function getKnowledgeAndSkills
         * @memberof project_service.ProjectService
         * @instance
         * @param {project_service.IGetKnowledgeAndSkillsRequest} request GetKnowledgeAndSkillsRequest message or plain object
         * @returns {Promise<project_service.GetKnowledgeAndSkillsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link project_service.ProjectService#upsertKnowledgeAndSkill}.
         * @memberof project_service.ProjectService
         * @typedef UpsertKnowledgeAndSkillCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {project_service.UpsertKnowledgeAndSkillResponse} [response] UpsertKnowledgeAndSkillResponse
         */

        /**
         * Calls UpsertKnowledgeAndSkill.
         * @function upsertKnowledgeAndSkill
         * @memberof project_service.ProjectService
         * @instance
         * @param {project_service.IUpsertKnowledgeAndSkillRequest} request UpsertKnowledgeAndSkillRequest message or plain object
         * @param {project_service.ProjectService.UpsertKnowledgeAndSkillCallback} callback Node-style callback called with the error, if any, and UpsertKnowledgeAndSkillResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProjectService.prototype.upsertKnowledgeAndSkill = function upsertKnowledgeAndSkill(request, callback) {
            return this.rpcCall(upsertKnowledgeAndSkill, $root.project_service.UpsertKnowledgeAndSkillRequest, $root.project_service.UpsertKnowledgeAndSkillResponse, request, callback);
        }, "name", { value: "UpsertKnowledgeAndSkill" });

        /**
         * Calls UpsertKnowledgeAndSkill.
         * @function upsertKnowledgeAndSkill
         * @memberof project_service.ProjectService
         * @instance
         * @param {project_service.IUpsertKnowledgeAndSkillRequest} request UpsertKnowledgeAndSkillRequest message or plain object
         * @returns {Promise<project_service.UpsertKnowledgeAndSkillResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link project_service.ProjectService#generateProjects}.
         * @memberof project_service.ProjectService
         * @typedef GenerateProjectsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {project_service.GenerateProjectsResponse} [response] GenerateProjectsResponse
         */

        /**
         * Calls GenerateProjects.
         * @function generateProjects
         * @memberof project_service.ProjectService
         * @instance
         * @param {project_service.IGenerateProjectsRequest} request GenerateProjectsRequest message or plain object
         * @param {project_service.ProjectService.GenerateProjectsCallback} callback Node-style callback called with the error, if any, and GenerateProjectsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProjectService.prototype.generateProjects = function generateProjects(request, callback) {
            return this.rpcCall(generateProjects, $root.project_service.GenerateProjectsRequest, $root.project_service.GenerateProjectsResponse, request, callback);
        }, "name", { value: "GenerateProjects" });

        /**
         * Calls GenerateProjects.
         * @function generateProjects
         * @memberof project_service.ProjectService
         * @instance
         * @param {project_service.IGenerateProjectsRequest} request GenerateProjectsRequest message or plain object
         * @returns {Promise<project_service.GenerateProjectsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link project_service.ProjectService#registerAnonymousProjects}.
         * @memberof project_service.ProjectService
         * @typedef RegisterAnonymousProjectsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {project_service.RegisterAnonymousProjectsResponse} [response] RegisterAnonymousProjectsResponse
         */

        /**
         * Calls RegisterAnonymousProjects.
         * @function registerAnonymousProjects
         * @memberof project_service.ProjectService
         * @instance
         * @param {project_service.IRegisterAnonymousProjectsRequest} request RegisterAnonymousProjectsRequest message or plain object
         * @param {project_service.ProjectService.RegisterAnonymousProjectsCallback} callback Node-style callback called with the error, if any, and RegisterAnonymousProjectsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProjectService.prototype.registerAnonymousProjects = function registerAnonymousProjects(request, callback) {
            return this.rpcCall(registerAnonymousProjects, $root.project_service.RegisterAnonymousProjectsRequest, $root.project_service.RegisterAnonymousProjectsResponse, request, callback);
        }, "name", { value: "RegisterAnonymousProjects" });

        /**
         * Calls RegisterAnonymousProjects.
         * @function registerAnonymousProjects
         * @memberof project_service.ProjectService
         * @instance
         * @param {project_service.IRegisterAnonymousProjectsRequest} request RegisterAnonymousProjectsRequest message or plain object
         * @returns {Promise<project_service.RegisterAnonymousProjectsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link project_service.ProjectService#getProjectInputs}.
         * @memberof project_service.ProjectService
         * @typedef GetProjectInputsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {project_service.GetProjectInputsResponse} [response] GetProjectInputsResponse
         */

        /**
         * Calls GetProjectInputs.
         * @function getProjectInputs
         * @memberof project_service.ProjectService
         * @instance
         * @param {project_service.IGetProjectInputsRequest} request GetProjectInputsRequest message or plain object
         * @param {project_service.ProjectService.GetProjectInputsCallback} callback Node-style callback called with the error, if any, and GetProjectInputsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProjectService.prototype.getProjectInputs = function getProjectInputs(request, callback) {
            return this.rpcCall(getProjectInputs, $root.project_service.GetProjectInputsRequest, $root.project_service.GetProjectInputsResponse, request, callback);
        }, "name", { value: "GetProjectInputs" });

        /**
         * Calls GetProjectInputs.
         * @function getProjectInputs
         * @memberof project_service.ProjectService
         * @instance
         * @param {project_service.IGetProjectInputsRequest} request GetProjectInputsRequest message or plain object
         * @returns {Promise<project_service.GetProjectInputsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link project_service.ProjectService#getProjects}.
         * @memberof project_service.ProjectService
         * @typedef GetProjectsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {project_service.GetProjectsResponse} [response] GetProjectsResponse
         */

        /**
         * Calls GetProjects.
         * @function getProjects
         * @memberof project_service.ProjectService
         * @instance
         * @param {project_service.IGetProjectsRequest} request GetProjectsRequest message or plain object
         * @param {project_service.ProjectService.GetProjectsCallback} callback Node-style callback called with the error, if any, and GetProjectsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProjectService.prototype.getProjects = function getProjects(request, callback) {
            return this.rpcCall(getProjects, $root.project_service.GetProjectsRequest, $root.project_service.GetProjectsResponse, request, callback);
        }, "name", { value: "GetProjects" });

        /**
         * Calls GetProjects.
         * @function getProjects
         * @memberof project_service.ProjectService
         * @instance
         * @param {project_service.IGetProjectsRequest} request GetProjectsRequest message or plain object
         * @returns {Promise<project_service.GetProjectsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link project_service.ProjectService#getProjectDefinitionCategoryTypes}.
         * @memberof project_service.ProjectService
         * @typedef GetProjectDefinitionCategoryTypesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {project_service.GetProjectDefinitionCategoryTypesResponse} [response] GetProjectDefinitionCategoryTypesResponse
         */

        /**
         * Calls GetProjectDefinitionCategoryTypes.
         * @function getProjectDefinitionCategoryTypes
         * @memberof project_service.ProjectService
         * @instance
         * @param {project_service.IGetProjectDefinitionCategoryTypesRequest} request GetProjectDefinitionCategoryTypesRequest message or plain object
         * @param {project_service.ProjectService.GetProjectDefinitionCategoryTypesCallback} callback Node-style callback called with the error, if any, and GetProjectDefinitionCategoryTypesResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProjectService.prototype.getProjectDefinitionCategoryTypes = function getProjectDefinitionCategoryTypes(request, callback) {
            return this.rpcCall(getProjectDefinitionCategoryTypes, $root.project_service.GetProjectDefinitionCategoryTypesRequest, $root.project_service.GetProjectDefinitionCategoryTypesResponse, request, callback);
        }, "name", { value: "GetProjectDefinitionCategoryTypes" });

        /**
         * Calls GetProjectDefinitionCategoryTypes.
         * @function getProjectDefinitionCategoryTypes
         * @memberof project_service.ProjectService
         * @instance
         * @param {project_service.IGetProjectDefinitionCategoryTypesRequest} request GetProjectDefinitionCategoryTypesRequest message or plain object
         * @returns {Promise<project_service.GetProjectDefinitionCategoryTypesResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link project_service.ProjectService#updateProject}.
         * @memberof project_service.ProjectService
         * @typedef UpdateProjectCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {project_service.UpdateProjectResponse} [response] UpdateProjectResponse
         */

        /**
         * Calls UpdateProject.
         * @function updateProject
         * @memberof project_service.ProjectService
         * @instance
         * @param {project_service.IUpdateProjectRequest} request UpdateProjectRequest message or plain object
         * @param {project_service.ProjectService.UpdateProjectCallback} callback Node-style callback called with the error, if any, and UpdateProjectResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProjectService.prototype.updateProject = function updateProject(request, callback) {
            return this.rpcCall(updateProject, $root.project_service.UpdateProjectRequest, $root.project_service.UpdateProjectResponse, request, callback);
        }, "name", { value: "UpdateProject" });

        /**
         * Calls UpdateProject.
         * @function updateProject
         * @memberof project_service.ProjectService
         * @instance
         * @param {project_service.IUpdateProjectRequest} request UpdateProjectRequest message or plain object
         * @returns {Promise<project_service.UpdateProjectResponse>} Promise
         * @variation 2
         */

        return ProjectService;
    })();

    project_service.GetMotivationsRequest = (function() {

        /**
         * Properties of a GetMotivationsRequest.
         * @memberof project_service
         * @interface IGetMotivationsRequest
         */

        /**
         * Constructs a new GetMotivationsRequest.
         * @memberof project_service
         * @classdesc Represents a GetMotivationsRequest.
         * @implements IGetMotivationsRequest
         * @constructor
         * @param {project_service.IGetMotivationsRequest=} [properties] Properties to set
         */
        function GetMotivationsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetMotivationsRequest instance using the specified properties.
         * @function create
         * @memberof project_service.GetMotivationsRequest
         * @static
         * @param {project_service.IGetMotivationsRequest=} [properties] Properties to set
         * @returns {project_service.GetMotivationsRequest} GetMotivationsRequest instance
         */
        GetMotivationsRequest.create = function create(properties) {
            return new GetMotivationsRequest(properties);
        };

        /**
         * Encodes the specified GetMotivationsRequest message. Does not implicitly {@link project_service.GetMotivationsRequest.verify|verify} messages.
         * @function encode
         * @memberof project_service.GetMotivationsRequest
         * @static
         * @param {project_service.IGetMotivationsRequest} message GetMotivationsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMotivationsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetMotivationsRequest message, length delimited. Does not implicitly {@link project_service.GetMotivationsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof project_service.GetMotivationsRequest
         * @static
         * @param {project_service.IGetMotivationsRequest} message GetMotivationsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMotivationsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMotivationsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof project_service.GetMotivationsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {project_service.GetMotivationsRequest} GetMotivationsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMotivationsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.project_service.GetMotivationsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMotivationsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof project_service.GetMotivationsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {project_service.GetMotivationsRequest} GetMotivationsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMotivationsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMotivationsRequest message.
         * @function verify
         * @memberof project_service.GetMotivationsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMotivationsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetMotivationsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof project_service.GetMotivationsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {project_service.GetMotivationsRequest} GetMotivationsRequest
         */
        GetMotivationsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.project_service.GetMotivationsRequest)
                return object;
            return new $root.project_service.GetMotivationsRequest();
        };

        /**
         * Creates a plain object from a GetMotivationsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof project_service.GetMotivationsRequest
         * @static
         * @param {project_service.GetMotivationsRequest} message GetMotivationsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMotivationsRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetMotivationsRequest to JSON.
         * @function toJSON
         * @memberof project_service.GetMotivationsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMotivationsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetMotivationsRequest
         * @function getTypeUrl
         * @memberof project_service.GetMotivationsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetMotivationsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/project_service.GetMotivationsRequest";
        };

        return GetMotivationsRequest;
    })();

    project_service.GetMotivationsResponse = (function() {

        /**
         * Properties of a GetMotivationsResponse.
         * @memberof project_service
         * @interface IGetMotivationsResponse
         */

        /**
         * Constructs a new GetMotivationsResponse.
         * @memberof project_service
         * @classdesc Represents a GetMotivationsResponse.
         * @implements IGetMotivationsResponse
         * @constructor
         * @param {project_service.IGetMotivationsResponse=} [properties] Properties to set
         */
        function GetMotivationsResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetMotivationsResponse instance using the specified properties.
         * @function create
         * @memberof project_service.GetMotivationsResponse
         * @static
         * @param {project_service.IGetMotivationsResponse=} [properties] Properties to set
         * @returns {project_service.GetMotivationsResponse} GetMotivationsResponse instance
         */
        GetMotivationsResponse.create = function create(properties) {
            return new GetMotivationsResponse(properties);
        };

        /**
         * Encodes the specified GetMotivationsResponse message. Does not implicitly {@link project_service.GetMotivationsResponse.verify|verify} messages.
         * @function encode
         * @memberof project_service.GetMotivationsResponse
         * @static
         * @param {project_service.IGetMotivationsResponse} message GetMotivationsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMotivationsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetMotivationsResponse message, length delimited. Does not implicitly {@link project_service.GetMotivationsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof project_service.GetMotivationsResponse
         * @static
         * @param {project_service.IGetMotivationsResponse} message GetMotivationsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMotivationsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMotivationsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof project_service.GetMotivationsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {project_service.GetMotivationsResponse} GetMotivationsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMotivationsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.project_service.GetMotivationsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMotivationsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof project_service.GetMotivationsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {project_service.GetMotivationsResponse} GetMotivationsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMotivationsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMotivationsResponse message.
         * @function verify
         * @memberof project_service.GetMotivationsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMotivationsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetMotivationsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof project_service.GetMotivationsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {project_service.GetMotivationsResponse} GetMotivationsResponse
         */
        GetMotivationsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.project_service.GetMotivationsResponse)
                return object;
            return new $root.project_service.GetMotivationsResponse();
        };

        /**
         * Creates a plain object from a GetMotivationsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof project_service.GetMotivationsResponse
         * @static
         * @param {project_service.GetMotivationsResponse} message GetMotivationsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMotivationsResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetMotivationsResponse to JSON.
         * @function toJSON
         * @memberof project_service.GetMotivationsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMotivationsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetMotivationsResponse
         * @function getTypeUrl
         * @memberof project_service.GetMotivationsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetMotivationsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/project_service.GetMotivationsResponse";
        };

        return GetMotivationsResponse;
    })();

    project_service.GenerateProjectsRequest = (function() {

        /**
         * Properties of a GenerateProjectsRequest.
         * @memberof project_service
         * @interface IGenerateProjectsRequest
         * @property {pl_types.IProjectDefinition|null} [definition] GenerateProjectsRequest definition
         */

        /**
         * Constructs a new GenerateProjectsRequest.
         * @memberof project_service
         * @classdesc Represents a GenerateProjectsRequest.
         * @implements IGenerateProjectsRequest
         * @constructor
         * @param {project_service.IGenerateProjectsRequest=} [properties] Properties to set
         */
        function GenerateProjectsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GenerateProjectsRequest definition.
         * @member {pl_types.IProjectDefinition|null|undefined} definition
         * @memberof project_service.GenerateProjectsRequest
         * @instance
         */
        GenerateProjectsRequest.prototype.definition = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GenerateProjectsRequest _definition.
         * @member {"definition"|undefined} _definition
         * @memberof project_service.GenerateProjectsRequest
         * @instance
         */
        Object.defineProperty(GenerateProjectsRequest.prototype, "_definition", {
            get: $util.oneOfGetter($oneOfFields = ["definition"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GenerateProjectsRequest instance using the specified properties.
         * @function create
         * @memberof project_service.GenerateProjectsRequest
         * @static
         * @param {project_service.IGenerateProjectsRequest=} [properties] Properties to set
         * @returns {project_service.GenerateProjectsRequest} GenerateProjectsRequest instance
         */
        GenerateProjectsRequest.create = function create(properties) {
            return new GenerateProjectsRequest(properties);
        };

        /**
         * Encodes the specified GenerateProjectsRequest message. Does not implicitly {@link project_service.GenerateProjectsRequest.verify|verify} messages.
         * @function encode
         * @memberof project_service.GenerateProjectsRequest
         * @static
         * @param {project_service.IGenerateProjectsRequest} message GenerateProjectsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateProjectsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.definition != null && Object.hasOwnProperty.call(message, "definition"))
                $root.pl_types.ProjectDefinition.encode(message.definition, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GenerateProjectsRequest message, length delimited. Does not implicitly {@link project_service.GenerateProjectsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof project_service.GenerateProjectsRequest
         * @static
         * @param {project_service.IGenerateProjectsRequest} message GenerateProjectsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateProjectsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GenerateProjectsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof project_service.GenerateProjectsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {project_service.GenerateProjectsRequest} GenerateProjectsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateProjectsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.project_service.GenerateProjectsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.definition = $root.pl_types.ProjectDefinition.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GenerateProjectsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof project_service.GenerateProjectsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {project_service.GenerateProjectsRequest} GenerateProjectsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateProjectsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GenerateProjectsRequest message.
         * @function verify
         * @memberof project_service.GenerateProjectsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GenerateProjectsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.definition != null && message.hasOwnProperty("definition")) {
                properties._definition = 1;
                {
                    var error = $root.pl_types.ProjectDefinition.verify(message.definition);
                    if (error)
                        return "definition." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GenerateProjectsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof project_service.GenerateProjectsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {project_service.GenerateProjectsRequest} GenerateProjectsRequest
         */
        GenerateProjectsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.project_service.GenerateProjectsRequest)
                return object;
            var message = new $root.project_service.GenerateProjectsRequest();
            if (object.definition != null) {
                if (typeof object.definition !== "object")
                    throw TypeError(".project_service.GenerateProjectsRequest.definition: object expected");
                message.definition = $root.pl_types.ProjectDefinition.fromObject(object.definition);
            }
            return message;
        };

        /**
         * Creates a plain object from a GenerateProjectsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof project_service.GenerateProjectsRequest
         * @static
         * @param {project_service.GenerateProjectsRequest} message GenerateProjectsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GenerateProjectsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.definition != null && message.hasOwnProperty("definition")) {
                object.definition = $root.pl_types.ProjectDefinition.toObject(message.definition, options);
                if (options.oneofs)
                    object._definition = "definition";
            }
            return object;
        };

        /**
         * Converts this GenerateProjectsRequest to JSON.
         * @function toJSON
         * @memberof project_service.GenerateProjectsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GenerateProjectsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GenerateProjectsRequest
         * @function getTypeUrl
         * @memberof project_service.GenerateProjectsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GenerateProjectsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/project_service.GenerateProjectsRequest";
        };

        return GenerateProjectsRequest;
    })();

    project_service.GenerateProjectsResponse = (function() {

        /**
         * Properties of a GenerateProjectsResponse.
         * @memberof project_service
         * @interface IGenerateProjectsResponse
         * @property {number|null} [projectInputId] GenerateProjectsResponse projectInputId
         */

        /**
         * Constructs a new GenerateProjectsResponse.
         * @memberof project_service
         * @classdesc Represents a GenerateProjectsResponse.
         * @implements IGenerateProjectsResponse
         * @constructor
         * @param {project_service.IGenerateProjectsResponse=} [properties] Properties to set
         */
        function GenerateProjectsResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GenerateProjectsResponse projectInputId.
         * @member {number|null|undefined} projectInputId
         * @memberof project_service.GenerateProjectsResponse
         * @instance
         */
        GenerateProjectsResponse.prototype.projectInputId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GenerateProjectsResponse _projectInputId.
         * @member {"projectInputId"|undefined} _projectInputId
         * @memberof project_service.GenerateProjectsResponse
         * @instance
         */
        Object.defineProperty(GenerateProjectsResponse.prototype, "_projectInputId", {
            get: $util.oneOfGetter($oneOfFields = ["projectInputId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GenerateProjectsResponse instance using the specified properties.
         * @function create
         * @memberof project_service.GenerateProjectsResponse
         * @static
         * @param {project_service.IGenerateProjectsResponse=} [properties] Properties to set
         * @returns {project_service.GenerateProjectsResponse} GenerateProjectsResponse instance
         */
        GenerateProjectsResponse.create = function create(properties) {
            return new GenerateProjectsResponse(properties);
        };

        /**
         * Encodes the specified GenerateProjectsResponse message. Does not implicitly {@link project_service.GenerateProjectsResponse.verify|verify} messages.
         * @function encode
         * @memberof project_service.GenerateProjectsResponse
         * @static
         * @param {project_service.IGenerateProjectsResponse} message GenerateProjectsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateProjectsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectInputId != null && Object.hasOwnProperty.call(message, "projectInputId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.projectInputId);
            return writer;
        };

        /**
         * Encodes the specified GenerateProjectsResponse message, length delimited. Does not implicitly {@link project_service.GenerateProjectsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof project_service.GenerateProjectsResponse
         * @static
         * @param {project_service.IGenerateProjectsResponse} message GenerateProjectsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateProjectsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GenerateProjectsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof project_service.GenerateProjectsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {project_service.GenerateProjectsResponse} GenerateProjectsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateProjectsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.project_service.GenerateProjectsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.projectInputId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GenerateProjectsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof project_service.GenerateProjectsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {project_service.GenerateProjectsResponse} GenerateProjectsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateProjectsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GenerateProjectsResponse message.
         * @function verify
         * @memberof project_service.GenerateProjectsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GenerateProjectsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.projectInputId != null && message.hasOwnProperty("projectInputId")) {
                properties._projectInputId = 1;
                if (!$util.isInteger(message.projectInputId))
                    return "projectInputId: integer expected";
            }
            return null;
        };

        /**
         * Creates a GenerateProjectsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof project_service.GenerateProjectsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {project_service.GenerateProjectsResponse} GenerateProjectsResponse
         */
        GenerateProjectsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.project_service.GenerateProjectsResponse)
                return object;
            var message = new $root.project_service.GenerateProjectsResponse();
            if (object.projectInputId != null)
                message.projectInputId = object.projectInputId | 0;
            return message;
        };

        /**
         * Creates a plain object from a GenerateProjectsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof project_service.GenerateProjectsResponse
         * @static
         * @param {project_service.GenerateProjectsResponse} message GenerateProjectsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GenerateProjectsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.projectInputId != null && message.hasOwnProperty("projectInputId")) {
                object.projectInputId = message.projectInputId;
                if (options.oneofs)
                    object._projectInputId = "projectInputId";
            }
            return object;
        };

        /**
         * Converts this GenerateProjectsResponse to JSON.
         * @function toJSON
         * @memberof project_service.GenerateProjectsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GenerateProjectsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GenerateProjectsResponse
         * @function getTypeUrl
         * @memberof project_service.GenerateProjectsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GenerateProjectsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/project_service.GenerateProjectsResponse";
        };

        return GenerateProjectsResponse;
    })();

    project_service.GetProjectInputsRequest = (function() {

        /**
         * Properties of a GetProjectInputsRequest.
         * @memberof project_service
         * @interface IGetProjectInputsRequest
         * @property {Array.<number>|null} [userXIds] GetProjectInputsRequest userXIds
         * @property {Array.<number>|null} [projectInputIds] GetProjectInputsRequest projectInputIds
         * @property {boolean|null} [includeComplete] GetProjectInputsRequest includeComplete
         * @property {boolean|null} [includeProcessing] GetProjectInputsRequest includeProcessing
         * @property {boolean|null} [includeAssignment] GetProjectInputsRequest includeAssignment
         */

        /**
         * Constructs a new GetProjectInputsRequest.
         * @memberof project_service
         * @classdesc Represents a GetProjectInputsRequest.
         * @implements IGetProjectInputsRequest
         * @constructor
         * @param {project_service.IGetProjectInputsRequest=} [properties] Properties to set
         */
        function GetProjectInputsRequest(properties) {
            this.userXIds = [];
            this.projectInputIds = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetProjectInputsRequest userXIds.
         * @member {Array.<number>} userXIds
         * @memberof project_service.GetProjectInputsRequest
         * @instance
         */
        GetProjectInputsRequest.prototype.userXIds = $util.emptyArray;

        /**
         * GetProjectInputsRequest projectInputIds.
         * @member {Array.<number>} projectInputIds
         * @memberof project_service.GetProjectInputsRequest
         * @instance
         */
        GetProjectInputsRequest.prototype.projectInputIds = $util.emptyArray;

        /**
         * GetProjectInputsRequest includeComplete.
         * @member {boolean|null|undefined} includeComplete
         * @memberof project_service.GetProjectInputsRequest
         * @instance
         */
        GetProjectInputsRequest.prototype.includeComplete = null;

        /**
         * GetProjectInputsRequest includeProcessing.
         * @member {boolean|null|undefined} includeProcessing
         * @memberof project_service.GetProjectInputsRequest
         * @instance
         */
        GetProjectInputsRequest.prototype.includeProcessing = null;

        /**
         * GetProjectInputsRequest includeAssignment.
         * @member {boolean|null|undefined} includeAssignment
         * @memberof project_service.GetProjectInputsRequest
         * @instance
         */
        GetProjectInputsRequest.prototype.includeAssignment = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GetProjectInputsRequest _includeComplete.
         * @member {"includeComplete"|undefined} _includeComplete
         * @memberof project_service.GetProjectInputsRequest
         * @instance
         */
        Object.defineProperty(GetProjectInputsRequest.prototype, "_includeComplete", {
            get: $util.oneOfGetter($oneOfFields = ["includeComplete"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetProjectInputsRequest _includeProcessing.
         * @member {"includeProcessing"|undefined} _includeProcessing
         * @memberof project_service.GetProjectInputsRequest
         * @instance
         */
        Object.defineProperty(GetProjectInputsRequest.prototype, "_includeProcessing", {
            get: $util.oneOfGetter($oneOfFields = ["includeProcessing"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetProjectInputsRequest _includeAssignment.
         * @member {"includeAssignment"|undefined} _includeAssignment
         * @memberof project_service.GetProjectInputsRequest
         * @instance
         */
        Object.defineProperty(GetProjectInputsRequest.prototype, "_includeAssignment", {
            get: $util.oneOfGetter($oneOfFields = ["includeAssignment"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetProjectInputsRequest instance using the specified properties.
         * @function create
         * @memberof project_service.GetProjectInputsRequest
         * @static
         * @param {project_service.IGetProjectInputsRequest=} [properties] Properties to set
         * @returns {project_service.GetProjectInputsRequest} GetProjectInputsRequest instance
         */
        GetProjectInputsRequest.create = function create(properties) {
            return new GetProjectInputsRequest(properties);
        };

        /**
         * Encodes the specified GetProjectInputsRequest message. Does not implicitly {@link project_service.GetProjectInputsRequest.verify|verify} messages.
         * @function encode
         * @memberof project_service.GetProjectInputsRequest
         * @static
         * @param {project_service.IGetProjectInputsRequest} message GetProjectInputsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectInputsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userXIds != null && message.userXIds.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.userXIds.length; ++i)
                    writer.int32(message.userXIds[i]);
                writer.ldelim();
            }
            if (message.projectInputIds != null && message.projectInputIds.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.projectInputIds.length; ++i)
                    writer.int32(message.projectInputIds[i]);
                writer.ldelim();
            }
            if (message.includeComplete != null && Object.hasOwnProperty.call(message, "includeComplete"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.includeComplete);
            if (message.includeProcessing != null && Object.hasOwnProperty.call(message, "includeProcessing"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includeProcessing);
            if (message.includeAssignment != null && Object.hasOwnProperty.call(message, "includeAssignment"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.includeAssignment);
            return writer;
        };

        /**
         * Encodes the specified GetProjectInputsRequest message, length delimited. Does not implicitly {@link project_service.GetProjectInputsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof project_service.GetProjectInputsRequest
         * @static
         * @param {project_service.IGetProjectInputsRequest} message GetProjectInputsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectInputsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetProjectInputsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof project_service.GetProjectInputsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {project_service.GetProjectInputsRequest} GetProjectInputsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectInputsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.project_service.GetProjectInputsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.userXIds && message.userXIds.length))
                            message.userXIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.userXIds.push(reader.int32());
                        } else
                            message.userXIds.push(reader.int32());
                        break;
                    }
                case 2: {
                        if (!(message.projectInputIds && message.projectInputIds.length))
                            message.projectInputIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.projectInputIds.push(reader.int32());
                        } else
                            message.projectInputIds.push(reader.int32());
                        break;
                    }
                case 3: {
                        message.includeComplete = reader.bool();
                        break;
                    }
                case 4: {
                        message.includeProcessing = reader.bool();
                        break;
                    }
                case 5: {
                        message.includeAssignment = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetProjectInputsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof project_service.GetProjectInputsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {project_service.GetProjectInputsRequest} GetProjectInputsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectInputsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetProjectInputsRequest message.
         * @function verify
         * @memberof project_service.GetProjectInputsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetProjectInputsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.userXIds != null && message.hasOwnProperty("userXIds")) {
                if (!Array.isArray(message.userXIds))
                    return "userXIds: array expected";
                for (var i = 0; i < message.userXIds.length; ++i)
                    if (!$util.isInteger(message.userXIds[i]))
                        return "userXIds: integer[] expected";
            }
            if (message.projectInputIds != null && message.hasOwnProperty("projectInputIds")) {
                if (!Array.isArray(message.projectInputIds))
                    return "projectInputIds: array expected";
                for (var i = 0; i < message.projectInputIds.length; ++i)
                    if (!$util.isInteger(message.projectInputIds[i]))
                        return "projectInputIds: integer[] expected";
            }
            if (message.includeComplete != null && message.hasOwnProperty("includeComplete")) {
                properties._includeComplete = 1;
                if (typeof message.includeComplete !== "boolean")
                    return "includeComplete: boolean expected";
            }
            if (message.includeProcessing != null && message.hasOwnProperty("includeProcessing")) {
                properties._includeProcessing = 1;
                if (typeof message.includeProcessing !== "boolean")
                    return "includeProcessing: boolean expected";
            }
            if (message.includeAssignment != null && message.hasOwnProperty("includeAssignment")) {
                properties._includeAssignment = 1;
                if (typeof message.includeAssignment !== "boolean")
                    return "includeAssignment: boolean expected";
            }
            return null;
        };

        /**
         * Creates a GetProjectInputsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof project_service.GetProjectInputsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {project_service.GetProjectInputsRequest} GetProjectInputsRequest
         */
        GetProjectInputsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.project_service.GetProjectInputsRequest)
                return object;
            var message = new $root.project_service.GetProjectInputsRequest();
            if (object.userXIds) {
                if (!Array.isArray(object.userXIds))
                    throw TypeError(".project_service.GetProjectInputsRequest.userXIds: array expected");
                message.userXIds = [];
                for (var i = 0; i < object.userXIds.length; ++i)
                    message.userXIds[i] = object.userXIds[i] | 0;
            }
            if (object.projectInputIds) {
                if (!Array.isArray(object.projectInputIds))
                    throw TypeError(".project_service.GetProjectInputsRequest.projectInputIds: array expected");
                message.projectInputIds = [];
                for (var i = 0; i < object.projectInputIds.length; ++i)
                    message.projectInputIds[i] = object.projectInputIds[i] | 0;
            }
            if (object.includeComplete != null)
                message.includeComplete = Boolean(object.includeComplete);
            if (object.includeProcessing != null)
                message.includeProcessing = Boolean(object.includeProcessing);
            if (object.includeAssignment != null)
                message.includeAssignment = Boolean(object.includeAssignment);
            return message;
        };

        /**
         * Creates a plain object from a GetProjectInputsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof project_service.GetProjectInputsRequest
         * @static
         * @param {project_service.GetProjectInputsRequest} message GetProjectInputsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetProjectInputsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.userXIds = [];
                object.projectInputIds = [];
            }
            if (message.userXIds && message.userXIds.length) {
                object.userXIds = [];
                for (var j = 0; j < message.userXIds.length; ++j)
                    object.userXIds[j] = message.userXIds[j];
            }
            if (message.projectInputIds && message.projectInputIds.length) {
                object.projectInputIds = [];
                for (var j = 0; j < message.projectInputIds.length; ++j)
                    object.projectInputIds[j] = message.projectInputIds[j];
            }
            if (message.includeComplete != null && message.hasOwnProperty("includeComplete")) {
                object.includeComplete = message.includeComplete;
                if (options.oneofs)
                    object._includeComplete = "includeComplete";
            }
            if (message.includeProcessing != null && message.hasOwnProperty("includeProcessing")) {
                object.includeProcessing = message.includeProcessing;
                if (options.oneofs)
                    object._includeProcessing = "includeProcessing";
            }
            if (message.includeAssignment != null && message.hasOwnProperty("includeAssignment")) {
                object.includeAssignment = message.includeAssignment;
                if (options.oneofs)
                    object._includeAssignment = "includeAssignment";
            }
            return object;
        };

        /**
         * Converts this GetProjectInputsRequest to JSON.
         * @function toJSON
         * @memberof project_service.GetProjectInputsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetProjectInputsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetProjectInputsRequest
         * @function getTypeUrl
         * @memberof project_service.GetProjectInputsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetProjectInputsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/project_service.GetProjectInputsRequest";
        };

        return GetProjectInputsRequest;
    })();

    project_service.GetProjectInputsResponse = (function() {

        /**
         * Properties of a GetProjectInputsResponse.
         * @memberof project_service
         * @interface IGetProjectInputsResponse
         * @property {Array.<pl_types.IProjectDefinition>|null} [projects] GetProjectInputsResponse projects
         */

        /**
         * Constructs a new GetProjectInputsResponse.
         * @memberof project_service
         * @classdesc Represents a GetProjectInputsResponse.
         * @implements IGetProjectInputsResponse
         * @constructor
         * @param {project_service.IGetProjectInputsResponse=} [properties] Properties to set
         */
        function GetProjectInputsResponse(properties) {
            this.projects = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetProjectInputsResponse projects.
         * @member {Array.<pl_types.IProjectDefinition>} projects
         * @memberof project_service.GetProjectInputsResponse
         * @instance
         */
        GetProjectInputsResponse.prototype.projects = $util.emptyArray;

        /**
         * Creates a new GetProjectInputsResponse instance using the specified properties.
         * @function create
         * @memberof project_service.GetProjectInputsResponse
         * @static
         * @param {project_service.IGetProjectInputsResponse=} [properties] Properties to set
         * @returns {project_service.GetProjectInputsResponse} GetProjectInputsResponse instance
         */
        GetProjectInputsResponse.create = function create(properties) {
            return new GetProjectInputsResponse(properties);
        };

        /**
         * Encodes the specified GetProjectInputsResponse message. Does not implicitly {@link project_service.GetProjectInputsResponse.verify|verify} messages.
         * @function encode
         * @memberof project_service.GetProjectInputsResponse
         * @static
         * @param {project_service.IGetProjectInputsResponse} message GetProjectInputsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectInputsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projects != null && message.projects.length)
                for (var i = 0; i < message.projects.length; ++i)
                    $root.pl_types.ProjectDefinition.encode(message.projects[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetProjectInputsResponse message, length delimited. Does not implicitly {@link project_service.GetProjectInputsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof project_service.GetProjectInputsResponse
         * @static
         * @param {project_service.IGetProjectInputsResponse} message GetProjectInputsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectInputsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetProjectInputsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof project_service.GetProjectInputsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {project_service.GetProjectInputsResponse} GetProjectInputsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectInputsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.project_service.GetProjectInputsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.projects && message.projects.length))
                            message.projects = [];
                        message.projects.push($root.pl_types.ProjectDefinition.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetProjectInputsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof project_service.GetProjectInputsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {project_service.GetProjectInputsResponse} GetProjectInputsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectInputsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetProjectInputsResponse message.
         * @function verify
         * @memberof project_service.GetProjectInputsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetProjectInputsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projects != null && message.hasOwnProperty("projects")) {
                if (!Array.isArray(message.projects))
                    return "projects: array expected";
                for (var i = 0; i < message.projects.length; ++i) {
                    var error = $root.pl_types.ProjectDefinition.verify(message.projects[i]);
                    if (error)
                        return "projects." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetProjectInputsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof project_service.GetProjectInputsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {project_service.GetProjectInputsResponse} GetProjectInputsResponse
         */
        GetProjectInputsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.project_service.GetProjectInputsResponse)
                return object;
            var message = new $root.project_service.GetProjectInputsResponse();
            if (object.projects) {
                if (!Array.isArray(object.projects))
                    throw TypeError(".project_service.GetProjectInputsResponse.projects: array expected");
                message.projects = [];
                for (var i = 0; i < object.projects.length; ++i) {
                    if (typeof object.projects[i] !== "object")
                        throw TypeError(".project_service.GetProjectInputsResponse.projects: object expected");
                    message.projects[i] = $root.pl_types.ProjectDefinition.fromObject(object.projects[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetProjectInputsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof project_service.GetProjectInputsResponse
         * @static
         * @param {project_service.GetProjectInputsResponse} message GetProjectInputsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetProjectInputsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.projects = [];
            if (message.projects && message.projects.length) {
                object.projects = [];
                for (var j = 0; j < message.projects.length; ++j)
                    object.projects[j] = $root.pl_types.ProjectDefinition.toObject(message.projects[j], options);
            }
            return object;
        };

        /**
         * Converts this GetProjectInputsResponse to JSON.
         * @function toJSON
         * @memberof project_service.GetProjectInputsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetProjectInputsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetProjectInputsResponse
         * @function getTypeUrl
         * @memberof project_service.GetProjectInputsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetProjectInputsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/project_service.GetProjectInputsResponse";
        };

        return GetProjectInputsResponse;
    })();

    project_service.GetProjectsRequest = (function() {

        /**
         * Properties of a GetProjectsRequest.
         * @memberof project_service
         * @interface IGetProjectsRequest
         * @property {Array.<number>|null} [userXIds] GetProjectsRequest userXIds
         * @property {Array.<number>|null} [projectIds] GetProjectsRequest projectIds
         * @property {boolean|null} [includeInactive] GetProjectsRequest includeInactive
         * @property {boolean|null} [includeTags] GetProjectsRequest includeTags
         * @property {boolean|null} [includeInputs] GetProjectsRequest includeInputs
         * @property {boolean|null} [includeInputOptions] GetProjectsRequest includeInputOptions
         * @property {boolean|null} [includeFulfillments] GetProjectsRequest includeFulfillments
         * @property {boolean|null} [includeAssignment] GetProjectsRequest includeAssignment
         * @property {boolean|null} [includeMilestones] GetProjectsRequest includeMilestones
         * @property {boolean|null} [includePortfolioPosts] GetProjectsRequest includePortfolioPosts
         */

        /**
         * Constructs a new GetProjectsRequest.
         * @memberof project_service
         * @classdesc Represents a GetProjectsRequest.
         * @implements IGetProjectsRequest
         * @constructor
         * @param {project_service.IGetProjectsRequest=} [properties] Properties to set
         */
        function GetProjectsRequest(properties) {
            this.userXIds = [];
            this.projectIds = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetProjectsRequest userXIds.
         * @member {Array.<number>} userXIds
         * @memberof project_service.GetProjectsRequest
         * @instance
         */
        GetProjectsRequest.prototype.userXIds = $util.emptyArray;

        /**
         * GetProjectsRequest projectIds.
         * @member {Array.<number>} projectIds
         * @memberof project_service.GetProjectsRequest
         * @instance
         */
        GetProjectsRequest.prototype.projectIds = $util.emptyArray;

        /**
         * GetProjectsRequest includeInactive.
         * @member {boolean|null|undefined} includeInactive
         * @memberof project_service.GetProjectsRequest
         * @instance
         */
        GetProjectsRequest.prototype.includeInactive = null;

        /**
         * GetProjectsRequest includeTags.
         * @member {boolean|null|undefined} includeTags
         * @memberof project_service.GetProjectsRequest
         * @instance
         */
        GetProjectsRequest.prototype.includeTags = null;

        /**
         * GetProjectsRequest includeInputs.
         * @member {boolean|null|undefined} includeInputs
         * @memberof project_service.GetProjectsRequest
         * @instance
         */
        GetProjectsRequest.prototype.includeInputs = null;

        /**
         * GetProjectsRequest includeInputOptions.
         * @member {boolean|null|undefined} includeInputOptions
         * @memberof project_service.GetProjectsRequest
         * @instance
         */
        GetProjectsRequest.prototype.includeInputOptions = null;

        /**
         * GetProjectsRequest includeFulfillments.
         * @member {boolean|null|undefined} includeFulfillments
         * @memberof project_service.GetProjectsRequest
         * @instance
         */
        GetProjectsRequest.prototype.includeFulfillments = null;

        /**
         * GetProjectsRequest includeAssignment.
         * @member {boolean|null|undefined} includeAssignment
         * @memberof project_service.GetProjectsRequest
         * @instance
         */
        GetProjectsRequest.prototype.includeAssignment = null;

        /**
         * GetProjectsRequest includeMilestones.
         * @member {boolean|null|undefined} includeMilestones
         * @memberof project_service.GetProjectsRequest
         * @instance
         */
        GetProjectsRequest.prototype.includeMilestones = null;

        /**
         * GetProjectsRequest includePortfolioPosts.
         * @member {boolean|null|undefined} includePortfolioPosts
         * @memberof project_service.GetProjectsRequest
         * @instance
         */
        GetProjectsRequest.prototype.includePortfolioPosts = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GetProjectsRequest _includeInactive.
         * @member {"includeInactive"|undefined} _includeInactive
         * @memberof project_service.GetProjectsRequest
         * @instance
         */
        Object.defineProperty(GetProjectsRequest.prototype, "_includeInactive", {
            get: $util.oneOfGetter($oneOfFields = ["includeInactive"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetProjectsRequest _includeTags.
         * @member {"includeTags"|undefined} _includeTags
         * @memberof project_service.GetProjectsRequest
         * @instance
         */
        Object.defineProperty(GetProjectsRequest.prototype, "_includeTags", {
            get: $util.oneOfGetter($oneOfFields = ["includeTags"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetProjectsRequest _includeInputs.
         * @member {"includeInputs"|undefined} _includeInputs
         * @memberof project_service.GetProjectsRequest
         * @instance
         */
        Object.defineProperty(GetProjectsRequest.prototype, "_includeInputs", {
            get: $util.oneOfGetter($oneOfFields = ["includeInputs"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetProjectsRequest _includeInputOptions.
         * @member {"includeInputOptions"|undefined} _includeInputOptions
         * @memberof project_service.GetProjectsRequest
         * @instance
         */
        Object.defineProperty(GetProjectsRequest.prototype, "_includeInputOptions", {
            get: $util.oneOfGetter($oneOfFields = ["includeInputOptions"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetProjectsRequest _includeFulfillments.
         * @member {"includeFulfillments"|undefined} _includeFulfillments
         * @memberof project_service.GetProjectsRequest
         * @instance
         */
        Object.defineProperty(GetProjectsRequest.prototype, "_includeFulfillments", {
            get: $util.oneOfGetter($oneOfFields = ["includeFulfillments"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetProjectsRequest _includeAssignment.
         * @member {"includeAssignment"|undefined} _includeAssignment
         * @memberof project_service.GetProjectsRequest
         * @instance
         */
        Object.defineProperty(GetProjectsRequest.prototype, "_includeAssignment", {
            get: $util.oneOfGetter($oneOfFields = ["includeAssignment"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetProjectsRequest _includeMilestones.
         * @member {"includeMilestones"|undefined} _includeMilestones
         * @memberof project_service.GetProjectsRequest
         * @instance
         */
        Object.defineProperty(GetProjectsRequest.prototype, "_includeMilestones", {
            get: $util.oneOfGetter($oneOfFields = ["includeMilestones"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetProjectsRequest _includePortfolioPosts.
         * @member {"includePortfolioPosts"|undefined} _includePortfolioPosts
         * @memberof project_service.GetProjectsRequest
         * @instance
         */
        Object.defineProperty(GetProjectsRequest.prototype, "_includePortfolioPosts", {
            get: $util.oneOfGetter($oneOfFields = ["includePortfolioPosts"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetProjectsRequest instance using the specified properties.
         * @function create
         * @memberof project_service.GetProjectsRequest
         * @static
         * @param {project_service.IGetProjectsRequest=} [properties] Properties to set
         * @returns {project_service.GetProjectsRequest} GetProjectsRequest instance
         */
        GetProjectsRequest.create = function create(properties) {
            return new GetProjectsRequest(properties);
        };

        /**
         * Encodes the specified GetProjectsRequest message. Does not implicitly {@link project_service.GetProjectsRequest.verify|verify} messages.
         * @function encode
         * @memberof project_service.GetProjectsRequest
         * @static
         * @param {project_service.IGetProjectsRequest} message GetProjectsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userXIds != null && message.userXIds.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.userXIds.length; ++i)
                    writer.int32(message.userXIds[i]);
                writer.ldelim();
            }
            if (message.projectIds != null && message.projectIds.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.projectIds.length; ++i)
                    writer.int32(message.projectIds[i]);
                writer.ldelim();
            }
            if (message.includeInactive != null && Object.hasOwnProperty.call(message, "includeInactive"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.includeInactive);
            if (message.includeTags != null && Object.hasOwnProperty.call(message, "includeTags"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includeTags);
            if (message.includeInputs != null && Object.hasOwnProperty.call(message, "includeInputs"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.includeInputs);
            if (message.includeFulfillments != null && Object.hasOwnProperty.call(message, "includeFulfillments"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.includeFulfillments);
            if (message.includeAssignment != null && Object.hasOwnProperty.call(message, "includeAssignment"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.includeAssignment);
            if (message.includeMilestones != null && Object.hasOwnProperty.call(message, "includeMilestones"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.includeMilestones);
            if (message.includeInputOptions != null && Object.hasOwnProperty.call(message, "includeInputOptions"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.includeInputOptions);
            if (message.includePortfolioPosts != null && Object.hasOwnProperty.call(message, "includePortfolioPosts"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.includePortfolioPosts);
            return writer;
        };

        /**
         * Encodes the specified GetProjectsRequest message, length delimited. Does not implicitly {@link project_service.GetProjectsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof project_service.GetProjectsRequest
         * @static
         * @param {project_service.IGetProjectsRequest} message GetProjectsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetProjectsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof project_service.GetProjectsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {project_service.GetProjectsRequest} GetProjectsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.project_service.GetProjectsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.userXIds && message.userXIds.length))
                            message.userXIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.userXIds.push(reader.int32());
                        } else
                            message.userXIds.push(reader.int32());
                        break;
                    }
                case 2: {
                        if (!(message.projectIds && message.projectIds.length))
                            message.projectIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.projectIds.push(reader.int32());
                        } else
                            message.projectIds.push(reader.int32());
                        break;
                    }
                case 3: {
                        message.includeInactive = reader.bool();
                        break;
                    }
                case 4: {
                        message.includeTags = reader.bool();
                        break;
                    }
                case 5: {
                        message.includeInputs = reader.bool();
                        break;
                    }
                case 9: {
                        message.includeInputOptions = reader.bool();
                        break;
                    }
                case 6: {
                        message.includeFulfillments = reader.bool();
                        break;
                    }
                case 7: {
                        message.includeAssignment = reader.bool();
                        break;
                    }
                case 8: {
                        message.includeMilestones = reader.bool();
                        break;
                    }
                case 10: {
                        message.includePortfolioPosts = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetProjectsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof project_service.GetProjectsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {project_service.GetProjectsRequest} GetProjectsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetProjectsRequest message.
         * @function verify
         * @memberof project_service.GetProjectsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetProjectsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.userXIds != null && message.hasOwnProperty("userXIds")) {
                if (!Array.isArray(message.userXIds))
                    return "userXIds: array expected";
                for (var i = 0; i < message.userXIds.length; ++i)
                    if (!$util.isInteger(message.userXIds[i]))
                        return "userXIds: integer[] expected";
            }
            if (message.projectIds != null && message.hasOwnProperty("projectIds")) {
                if (!Array.isArray(message.projectIds))
                    return "projectIds: array expected";
                for (var i = 0; i < message.projectIds.length; ++i)
                    if (!$util.isInteger(message.projectIds[i]))
                        return "projectIds: integer[] expected";
            }
            if (message.includeInactive != null && message.hasOwnProperty("includeInactive")) {
                properties._includeInactive = 1;
                if (typeof message.includeInactive !== "boolean")
                    return "includeInactive: boolean expected";
            }
            if (message.includeTags != null && message.hasOwnProperty("includeTags")) {
                properties._includeTags = 1;
                if (typeof message.includeTags !== "boolean")
                    return "includeTags: boolean expected";
            }
            if (message.includeInputs != null && message.hasOwnProperty("includeInputs")) {
                properties._includeInputs = 1;
                if (typeof message.includeInputs !== "boolean")
                    return "includeInputs: boolean expected";
            }
            if (message.includeInputOptions != null && message.hasOwnProperty("includeInputOptions")) {
                properties._includeInputOptions = 1;
                if (typeof message.includeInputOptions !== "boolean")
                    return "includeInputOptions: boolean expected";
            }
            if (message.includeFulfillments != null && message.hasOwnProperty("includeFulfillments")) {
                properties._includeFulfillments = 1;
                if (typeof message.includeFulfillments !== "boolean")
                    return "includeFulfillments: boolean expected";
            }
            if (message.includeAssignment != null && message.hasOwnProperty("includeAssignment")) {
                properties._includeAssignment = 1;
                if (typeof message.includeAssignment !== "boolean")
                    return "includeAssignment: boolean expected";
            }
            if (message.includeMilestones != null && message.hasOwnProperty("includeMilestones")) {
                properties._includeMilestones = 1;
                if (typeof message.includeMilestones !== "boolean")
                    return "includeMilestones: boolean expected";
            }
            if (message.includePortfolioPosts != null && message.hasOwnProperty("includePortfolioPosts")) {
                properties._includePortfolioPosts = 1;
                if (typeof message.includePortfolioPosts !== "boolean")
                    return "includePortfolioPosts: boolean expected";
            }
            return null;
        };

        /**
         * Creates a GetProjectsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof project_service.GetProjectsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {project_service.GetProjectsRequest} GetProjectsRequest
         */
        GetProjectsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.project_service.GetProjectsRequest)
                return object;
            var message = new $root.project_service.GetProjectsRequest();
            if (object.userXIds) {
                if (!Array.isArray(object.userXIds))
                    throw TypeError(".project_service.GetProjectsRequest.userXIds: array expected");
                message.userXIds = [];
                for (var i = 0; i < object.userXIds.length; ++i)
                    message.userXIds[i] = object.userXIds[i] | 0;
            }
            if (object.projectIds) {
                if (!Array.isArray(object.projectIds))
                    throw TypeError(".project_service.GetProjectsRequest.projectIds: array expected");
                message.projectIds = [];
                for (var i = 0; i < object.projectIds.length; ++i)
                    message.projectIds[i] = object.projectIds[i] | 0;
            }
            if (object.includeInactive != null)
                message.includeInactive = Boolean(object.includeInactive);
            if (object.includeTags != null)
                message.includeTags = Boolean(object.includeTags);
            if (object.includeInputs != null)
                message.includeInputs = Boolean(object.includeInputs);
            if (object.includeInputOptions != null)
                message.includeInputOptions = Boolean(object.includeInputOptions);
            if (object.includeFulfillments != null)
                message.includeFulfillments = Boolean(object.includeFulfillments);
            if (object.includeAssignment != null)
                message.includeAssignment = Boolean(object.includeAssignment);
            if (object.includeMilestones != null)
                message.includeMilestones = Boolean(object.includeMilestones);
            if (object.includePortfolioPosts != null)
                message.includePortfolioPosts = Boolean(object.includePortfolioPosts);
            return message;
        };

        /**
         * Creates a plain object from a GetProjectsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof project_service.GetProjectsRequest
         * @static
         * @param {project_service.GetProjectsRequest} message GetProjectsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetProjectsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.userXIds = [];
                object.projectIds = [];
            }
            if (message.userXIds && message.userXIds.length) {
                object.userXIds = [];
                for (var j = 0; j < message.userXIds.length; ++j)
                    object.userXIds[j] = message.userXIds[j];
            }
            if (message.projectIds && message.projectIds.length) {
                object.projectIds = [];
                for (var j = 0; j < message.projectIds.length; ++j)
                    object.projectIds[j] = message.projectIds[j];
            }
            if (message.includeInactive != null && message.hasOwnProperty("includeInactive")) {
                object.includeInactive = message.includeInactive;
                if (options.oneofs)
                    object._includeInactive = "includeInactive";
            }
            if (message.includeTags != null && message.hasOwnProperty("includeTags")) {
                object.includeTags = message.includeTags;
                if (options.oneofs)
                    object._includeTags = "includeTags";
            }
            if (message.includeInputs != null && message.hasOwnProperty("includeInputs")) {
                object.includeInputs = message.includeInputs;
                if (options.oneofs)
                    object._includeInputs = "includeInputs";
            }
            if (message.includeFulfillments != null && message.hasOwnProperty("includeFulfillments")) {
                object.includeFulfillments = message.includeFulfillments;
                if (options.oneofs)
                    object._includeFulfillments = "includeFulfillments";
            }
            if (message.includeAssignment != null && message.hasOwnProperty("includeAssignment")) {
                object.includeAssignment = message.includeAssignment;
                if (options.oneofs)
                    object._includeAssignment = "includeAssignment";
            }
            if (message.includeMilestones != null && message.hasOwnProperty("includeMilestones")) {
                object.includeMilestones = message.includeMilestones;
                if (options.oneofs)
                    object._includeMilestones = "includeMilestones";
            }
            if (message.includeInputOptions != null && message.hasOwnProperty("includeInputOptions")) {
                object.includeInputOptions = message.includeInputOptions;
                if (options.oneofs)
                    object._includeInputOptions = "includeInputOptions";
            }
            if (message.includePortfolioPosts != null && message.hasOwnProperty("includePortfolioPosts")) {
                object.includePortfolioPosts = message.includePortfolioPosts;
                if (options.oneofs)
                    object._includePortfolioPosts = "includePortfolioPosts";
            }
            return object;
        };

        /**
         * Converts this GetProjectsRequest to JSON.
         * @function toJSON
         * @memberof project_service.GetProjectsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetProjectsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetProjectsRequest
         * @function getTypeUrl
         * @memberof project_service.GetProjectsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetProjectsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/project_service.GetProjectsRequest";
        };

        return GetProjectsRequest;
    })();

    project_service.GetProjectsResponse = (function() {

        /**
         * Properties of a GetProjectsResponse.
         * @memberof project_service
         * @interface IGetProjectsResponse
         * @property {Array.<pl_types.IProject>|null} [projects] GetProjectsResponse projects
         */

        /**
         * Constructs a new GetProjectsResponse.
         * @memberof project_service
         * @classdesc Represents a GetProjectsResponse.
         * @implements IGetProjectsResponse
         * @constructor
         * @param {project_service.IGetProjectsResponse=} [properties] Properties to set
         */
        function GetProjectsResponse(properties) {
            this.projects = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetProjectsResponse projects.
         * @member {Array.<pl_types.IProject>} projects
         * @memberof project_service.GetProjectsResponse
         * @instance
         */
        GetProjectsResponse.prototype.projects = $util.emptyArray;

        /**
         * Creates a new GetProjectsResponse instance using the specified properties.
         * @function create
         * @memberof project_service.GetProjectsResponse
         * @static
         * @param {project_service.IGetProjectsResponse=} [properties] Properties to set
         * @returns {project_service.GetProjectsResponse} GetProjectsResponse instance
         */
        GetProjectsResponse.create = function create(properties) {
            return new GetProjectsResponse(properties);
        };

        /**
         * Encodes the specified GetProjectsResponse message. Does not implicitly {@link project_service.GetProjectsResponse.verify|verify} messages.
         * @function encode
         * @memberof project_service.GetProjectsResponse
         * @static
         * @param {project_service.IGetProjectsResponse} message GetProjectsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projects != null && message.projects.length)
                for (var i = 0; i < message.projects.length; ++i)
                    $root.pl_types.Project.encode(message.projects[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetProjectsResponse message, length delimited. Does not implicitly {@link project_service.GetProjectsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof project_service.GetProjectsResponse
         * @static
         * @param {project_service.IGetProjectsResponse} message GetProjectsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetProjectsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof project_service.GetProjectsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {project_service.GetProjectsResponse} GetProjectsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.project_service.GetProjectsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.projects && message.projects.length))
                            message.projects = [];
                        message.projects.push($root.pl_types.Project.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetProjectsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof project_service.GetProjectsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {project_service.GetProjectsResponse} GetProjectsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetProjectsResponse message.
         * @function verify
         * @memberof project_service.GetProjectsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetProjectsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projects != null && message.hasOwnProperty("projects")) {
                if (!Array.isArray(message.projects))
                    return "projects: array expected";
                for (var i = 0; i < message.projects.length; ++i) {
                    var error = $root.pl_types.Project.verify(message.projects[i]);
                    if (error)
                        return "projects." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetProjectsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof project_service.GetProjectsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {project_service.GetProjectsResponse} GetProjectsResponse
         */
        GetProjectsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.project_service.GetProjectsResponse)
                return object;
            var message = new $root.project_service.GetProjectsResponse();
            if (object.projects) {
                if (!Array.isArray(object.projects))
                    throw TypeError(".project_service.GetProjectsResponse.projects: array expected");
                message.projects = [];
                for (var i = 0; i < object.projects.length; ++i) {
                    if (typeof object.projects[i] !== "object")
                        throw TypeError(".project_service.GetProjectsResponse.projects: object expected");
                    message.projects[i] = $root.pl_types.Project.fromObject(object.projects[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetProjectsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof project_service.GetProjectsResponse
         * @static
         * @param {project_service.GetProjectsResponse} message GetProjectsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetProjectsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.projects = [];
            if (message.projects && message.projects.length) {
                object.projects = [];
                for (var j = 0; j < message.projects.length; ++j)
                    object.projects[j] = $root.pl_types.Project.toObject(message.projects[j], options);
            }
            return object;
        };

        /**
         * Converts this GetProjectsResponse to JSON.
         * @function toJSON
         * @memberof project_service.GetProjectsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetProjectsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetProjectsResponse
         * @function getTypeUrl
         * @memberof project_service.GetProjectsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetProjectsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/project_service.GetProjectsResponse";
        };

        return GetProjectsResponse;
    })();

    project_service.UpdateProjectRequest = (function() {

        /**
         * Properties of an UpdateProjectRequest.
         * @memberof project_service
         * @interface IUpdateProjectRequest
         * @property {pl_types.IProject|null} [project] UpdateProjectRequest project
         * @property {boolean|null} [includeAssignments] UpdateProjectRequest includeAssignments
         * @property {boolean|null} [includeMilestones] UpdateProjectRequest includeMilestones
         */

        /**
         * Constructs a new UpdateProjectRequest.
         * @memberof project_service
         * @classdesc Represents an UpdateProjectRequest.
         * @implements IUpdateProjectRequest
         * @constructor
         * @param {project_service.IUpdateProjectRequest=} [properties] Properties to set
         */
        function UpdateProjectRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateProjectRequest project.
         * @member {pl_types.IProject|null|undefined} project
         * @memberof project_service.UpdateProjectRequest
         * @instance
         */
        UpdateProjectRequest.prototype.project = null;

        /**
         * UpdateProjectRequest includeAssignments.
         * @member {boolean|null|undefined} includeAssignments
         * @memberof project_service.UpdateProjectRequest
         * @instance
         */
        UpdateProjectRequest.prototype.includeAssignments = null;

        /**
         * UpdateProjectRequest includeMilestones.
         * @member {boolean|null|undefined} includeMilestones
         * @memberof project_service.UpdateProjectRequest
         * @instance
         */
        UpdateProjectRequest.prototype.includeMilestones = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UpdateProjectRequest _project.
         * @member {"project"|undefined} _project
         * @memberof project_service.UpdateProjectRequest
         * @instance
         */
        Object.defineProperty(UpdateProjectRequest.prototype, "_project", {
            get: $util.oneOfGetter($oneOfFields = ["project"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UpdateProjectRequest _includeAssignments.
         * @member {"includeAssignments"|undefined} _includeAssignments
         * @memberof project_service.UpdateProjectRequest
         * @instance
         */
        Object.defineProperty(UpdateProjectRequest.prototype, "_includeAssignments", {
            get: $util.oneOfGetter($oneOfFields = ["includeAssignments"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UpdateProjectRequest _includeMilestones.
         * @member {"includeMilestones"|undefined} _includeMilestones
         * @memberof project_service.UpdateProjectRequest
         * @instance
         */
        Object.defineProperty(UpdateProjectRequest.prototype, "_includeMilestones", {
            get: $util.oneOfGetter($oneOfFields = ["includeMilestones"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UpdateProjectRequest instance using the specified properties.
         * @function create
         * @memberof project_service.UpdateProjectRequest
         * @static
         * @param {project_service.IUpdateProjectRequest=} [properties] Properties to set
         * @returns {project_service.UpdateProjectRequest} UpdateProjectRequest instance
         */
        UpdateProjectRequest.create = function create(properties) {
            return new UpdateProjectRequest(properties);
        };

        /**
         * Encodes the specified UpdateProjectRequest message. Does not implicitly {@link project_service.UpdateProjectRequest.verify|verify} messages.
         * @function encode
         * @memberof project_service.UpdateProjectRequest
         * @static
         * @param {project_service.IUpdateProjectRequest} message UpdateProjectRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateProjectRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.project != null && Object.hasOwnProperty.call(message, "project"))
                $root.pl_types.Project.encode(message.project, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.includeAssignments != null && Object.hasOwnProperty.call(message, "includeAssignments"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.includeAssignments);
            if (message.includeMilestones != null && Object.hasOwnProperty.call(message, "includeMilestones"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includeMilestones);
            return writer;
        };

        /**
         * Encodes the specified UpdateProjectRequest message, length delimited. Does not implicitly {@link project_service.UpdateProjectRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof project_service.UpdateProjectRequest
         * @static
         * @param {project_service.IUpdateProjectRequest} message UpdateProjectRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateProjectRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateProjectRequest message from the specified reader or buffer.
         * @function decode
         * @memberof project_service.UpdateProjectRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {project_service.UpdateProjectRequest} UpdateProjectRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateProjectRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.project_service.UpdateProjectRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.project = $root.pl_types.Project.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.includeAssignments = reader.bool();
                        break;
                    }
                case 4: {
                        message.includeMilestones = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateProjectRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof project_service.UpdateProjectRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {project_service.UpdateProjectRequest} UpdateProjectRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateProjectRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateProjectRequest message.
         * @function verify
         * @memberof project_service.UpdateProjectRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateProjectRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.project != null && message.hasOwnProperty("project")) {
                properties._project = 1;
                {
                    var error = $root.pl_types.Project.verify(message.project);
                    if (error)
                        return "project." + error;
                }
            }
            if (message.includeAssignments != null && message.hasOwnProperty("includeAssignments")) {
                properties._includeAssignments = 1;
                if (typeof message.includeAssignments !== "boolean")
                    return "includeAssignments: boolean expected";
            }
            if (message.includeMilestones != null && message.hasOwnProperty("includeMilestones")) {
                properties._includeMilestones = 1;
                if (typeof message.includeMilestones !== "boolean")
                    return "includeMilestones: boolean expected";
            }
            return null;
        };

        /**
         * Creates an UpdateProjectRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof project_service.UpdateProjectRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {project_service.UpdateProjectRequest} UpdateProjectRequest
         */
        UpdateProjectRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.project_service.UpdateProjectRequest)
                return object;
            var message = new $root.project_service.UpdateProjectRequest();
            if (object.project != null) {
                if (typeof object.project !== "object")
                    throw TypeError(".project_service.UpdateProjectRequest.project: object expected");
                message.project = $root.pl_types.Project.fromObject(object.project);
            }
            if (object.includeAssignments != null)
                message.includeAssignments = Boolean(object.includeAssignments);
            if (object.includeMilestones != null)
                message.includeMilestones = Boolean(object.includeMilestones);
            return message;
        };

        /**
         * Creates a plain object from an UpdateProjectRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof project_service.UpdateProjectRequest
         * @static
         * @param {project_service.UpdateProjectRequest} message UpdateProjectRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateProjectRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.project != null && message.hasOwnProperty("project")) {
                object.project = $root.pl_types.Project.toObject(message.project, options);
                if (options.oneofs)
                    object._project = "project";
            }
            if (message.includeAssignments != null && message.hasOwnProperty("includeAssignments")) {
                object.includeAssignments = message.includeAssignments;
                if (options.oneofs)
                    object._includeAssignments = "includeAssignments";
            }
            if (message.includeMilestones != null && message.hasOwnProperty("includeMilestones")) {
                object.includeMilestones = message.includeMilestones;
                if (options.oneofs)
                    object._includeMilestones = "includeMilestones";
            }
            return object;
        };

        /**
         * Converts this UpdateProjectRequest to JSON.
         * @function toJSON
         * @memberof project_service.UpdateProjectRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateProjectRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateProjectRequest
         * @function getTypeUrl
         * @memberof project_service.UpdateProjectRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateProjectRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/project_service.UpdateProjectRequest";
        };

        return UpdateProjectRequest;
    })();

    project_service.UpdateProjectResponse = (function() {

        /**
         * Properties of an UpdateProjectResponse.
         * @memberof project_service
         * @interface IUpdateProjectResponse
         * @property {pl_types.IProject|null} [project] UpdateProjectResponse project
         */

        /**
         * Constructs a new UpdateProjectResponse.
         * @memberof project_service
         * @classdesc Represents an UpdateProjectResponse.
         * @implements IUpdateProjectResponse
         * @constructor
         * @param {project_service.IUpdateProjectResponse=} [properties] Properties to set
         */
        function UpdateProjectResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateProjectResponse project.
         * @member {pl_types.IProject|null|undefined} project
         * @memberof project_service.UpdateProjectResponse
         * @instance
         */
        UpdateProjectResponse.prototype.project = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UpdateProjectResponse _project.
         * @member {"project"|undefined} _project
         * @memberof project_service.UpdateProjectResponse
         * @instance
         */
        Object.defineProperty(UpdateProjectResponse.prototype, "_project", {
            get: $util.oneOfGetter($oneOfFields = ["project"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UpdateProjectResponse instance using the specified properties.
         * @function create
         * @memberof project_service.UpdateProjectResponse
         * @static
         * @param {project_service.IUpdateProjectResponse=} [properties] Properties to set
         * @returns {project_service.UpdateProjectResponse} UpdateProjectResponse instance
         */
        UpdateProjectResponse.create = function create(properties) {
            return new UpdateProjectResponse(properties);
        };

        /**
         * Encodes the specified UpdateProjectResponse message. Does not implicitly {@link project_service.UpdateProjectResponse.verify|verify} messages.
         * @function encode
         * @memberof project_service.UpdateProjectResponse
         * @static
         * @param {project_service.IUpdateProjectResponse} message UpdateProjectResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateProjectResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.project != null && Object.hasOwnProperty.call(message, "project"))
                $root.pl_types.Project.encode(message.project, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateProjectResponse message, length delimited. Does not implicitly {@link project_service.UpdateProjectResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof project_service.UpdateProjectResponse
         * @static
         * @param {project_service.IUpdateProjectResponse} message UpdateProjectResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateProjectResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateProjectResponse message from the specified reader or buffer.
         * @function decode
         * @memberof project_service.UpdateProjectResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {project_service.UpdateProjectResponse} UpdateProjectResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateProjectResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.project_service.UpdateProjectResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.project = $root.pl_types.Project.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateProjectResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof project_service.UpdateProjectResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {project_service.UpdateProjectResponse} UpdateProjectResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateProjectResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateProjectResponse message.
         * @function verify
         * @memberof project_service.UpdateProjectResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateProjectResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.project != null && message.hasOwnProperty("project")) {
                properties._project = 1;
                {
                    var error = $root.pl_types.Project.verify(message.project);
                    if (error)
                        return "project." + error;
                }
            }
            return null;
        };

        /**
         * Creates an UpdateProjectResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof project_service.UpdateProjectResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {project_service.UpdateProjectResponse} UpdateProjectResponse
         */
        UpdateProjectResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.project_service.UpdateProjectResponse)
                return object;
            var message = new $root.project_service.UpdateProjectResponse();
            if (object.project != null) {
                if (typeof object.project !== "object")
                    throw TypeError(".project_service.UpdateProjectResponse.project: object expected");
                message.project = $root.pl_types.Project.fromObject(object.project);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateProjectResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof project_service.UpdateProjectResponse
         * @static
         * @param {project_service.UpdateProjectResponse} message UpdateProjectResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateProjectResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.project != null && message.hasOwnProperty("project")) {
                object.project = $root.pl_types.Project.toObject(message.project, options);
                if (options.oneofs)
                    object._project = "project";
            }
            return object;
        };

        /**
         * Converts this UpdateProjectResponse to JSON.
         * @function toJSON
         * @memberof project_service.UpdateProjectResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateProjectResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateProjectResponse
         * @function getTypeUrl
         * @memberof project_service.UpdateProjectResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateProjectResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/project_service.UpdateProjectResponse";
        };

        return UpdateProjectResponse;
    })();

    project_service.GetProjectDefinitionCategoryTypesRequest = (function() {

        /**
         * Properties of a GetProjectDefinitionCategoryTypesRequest.
         * @memberof project_service
         * @interface IGetProjectDefinitionCategoryTypesRequest
         * @property {boolean|null} [includeDemos] GetProjectDefinitionCategoryTypesRequest includeDemos
         */

        /**
         * Constructs a new GetProjectDefinitionCategoryTypesRequest.
         * @memberof project_service
         * @classdesc Represents a GetProjectDefinitionCategoryTypesRequest.
         * @implements IGetProjectDefinitionCategoryTypesRequest
         * @constructor
         * @param {project_service.IGetProjectDefinitionCategoryTypesRequest=} [properties] Properties to set
         */
        function GetProjectDefinitionCategoryTypesRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetProjectDefinitionCategoryTypesRequest includeDemos.
         * @member {boolean|null|undefined} includeDemos
         * @memberof project_service.GetProjectDefinitionCategoryTypesRequest
         * @instance
         */
        GetProjectDefinitionCategoryTypesRequest.prototype.includeDemos = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GetProjectDefinitionCategoryTypesRequest _includeDemos.
         * @member {"includeDemos"|undefined} _includeDemos
         * @memberof project_service.GetProjectDefinitionCategoryTypesRequest
         * @instance
         */
        Object.defineProperty(GetProjectDefinitionCategoryTypesRequest.prototype, "_includeDemos", {
            get: $util.oneOfGetter($oneOfFields = ["includeDemos"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetProjectDefinitionCategoryTypesRequest instance using the specified properties.
         * @function create
         * @memberof project_service.GetProjectDefinitionCategoryTypesRequest
         * @static
         * @param {project_service.IGetProjectDefinitionCategoryTypesRequest=} [properties] Properties to set
         * @returns {project_service.GetProjectDefinitionCategoryTypesRequest} GetProjectDefinitionCategoryTypesRequest instance
         */
        GetProjectDefinitionCategoryTypesRequest.create = function create(properties) {
            return new GetProjectDefinitionCategoryTypesRequest(properties);
        };

        /**
         * Encodes the specified GetProjectDefinitionCategoryTypesRequest message. Does not implicitly {@link project_service.GetProjectDefinitionCategoryTypesRequest.verify|verify} messages.
         * @function encode
         * @memberof project_service.GetProjectDefinitionCategoryTypesRequest
         * @static
         * @param {project_service.IGetProjectDefinitionCategoryTypesRequest} message GetProjectDefinitionCategoryTypesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectDefinitionCategoryTypesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.includeDemos != null && Object.hasOwnProperty.call(message, "includeDemos"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.includeDemos);
            return writer;
        };

        /**
         * Encodes the specified GetProjectDefinitionCategoryTypesRequest message, length delimited. Does not implicitly {@link project_service.GetProjectDefinitionCategoryTypesRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof project_service.GetProjectDefinitionCategoryTypesRequest
         * @static
         * @param {project_service.IGetProjectDefinitionCategoryTypesRequest} message GetProjectDefinitionCategoryTypesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectDefinitionCategoryTypesRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetProjectDefinitionCategoryTypesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof project_service.GetProjectDefinitionCategoryTypesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {project_service.GetProjectDefinitionCategoryTypesRequest} GetProjectDefinitionCategoryTypesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectDefinitionCategoryTypesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.project_service.GetProjectDefinitionCategoryTypesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.includeDemos = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetProjectDefinitionCategoryTypesRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof project_service.GetProjectDefinitionCategoryTypesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {project_service.GetProjectDefinitionCategoryTypesRequest} GetProjectDefinitionCategoryTypesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectDefinitionCategoryTypesRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetProjectDefinitionCategoryTypesRequest message.
         * @function verify
         * @memberof project_service.GetProjectDefinitionCategoryTypesRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetProjectDefinitionCategoryTypesRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.includeDemos != null && message.hasOwnProperty("includeDemos")) {
                properties._includeDemos = 1;
                if (typeof message.includeDemos !== "boolean")
                    return "includeDemos: boolean expected";
            }
            return null;
        };

        /**
         * Creates a GetProjectDefinitionCategoryTypesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof project_service.GetProjectDefinitionCategoryTypesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {project_service.GetProjectDefinitionCategoryTypesRequest} GetProjectDefinitionCategoryTypesRequest
         */
        GetProjectDefinitionCategoryTypesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.project_service.GetProjectDefinitionCategoryTypesRequest)
                return object;
            var message = new $root.project_service.GetProjectDefinitionCategoryTypesRequest();
            if (object.includeDemos != null)
                message.includeDemos = Boolean(object.includeDemos);
            return message;
        };

        /**
         * Creates a plain object from a GetProjectDefinitionCategoryTypesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof project_service.GetProjectDefinitionCategoryTypesRequest
         * @static
         * @param {project_service.GetProjectDefinitionCategoryTypesRequest} message GetProjectDefinitionCategoryTypesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetProjectDefinitionCategoryTypesRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.includeDemos != null && message.hasOwnProperty("includeDemos")) {
                object.includeDemos = message.includeDemos;
                if (options.oneofs)
                    object._includeDemos = "includeDemos";
            }
            return object;
        };

        /**
         * Converts this GetProjectDefinitionCategoryTypesRequest to JSON.
         * @function toJSON
         * @memberof project_service.GetProjectDefinitionCategoryTypesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetProjectDefinitionCategoryTypesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetProjectDefinitionCategoryTypesRequest
         * @function getTypeUrl
         * @memberof project_service.GetProjectDefinitionCategoryTypesRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetProjectDefinitionCategoryTypesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/project_service.GetProjectDefinitionCategoryTypesRequest";
        };

        return GetProjectDefinitionCategoryTypesRequest;
    })();

    project_service.GetProjectDefinitionCategoryTypesResponse = (function() {

        /**
         * Properties of a GetProjectDefinitionCategoryTypesResponse.
         * @memberof project_service
         * @interface IGetProjectDefinitionCategoryTypesResponse
         * @property {Array.<pl_types.IProjectInputCategory>|null} [inputCategories] GetProjectDefinitionCategoryTypesResponse inputCategories
         */

        /**
         * Constructs a new GetProjectDefinitionCategoryTypesResponse.
         * @memberof project_service
         * @classdesc Represents a GetProjectDefinitionCategoryTypesResponse.
         * @implements IGetProjectDefinitionCategoryTypesResponse
         * @constructor
         * @param {project_service.IGetProjectDefinitionCategoryTypesResponse=} [properties] Properties to set
         */
        function GetProjectDefinitionCategoryTypesResponse(properties) {
            this.inputCategories = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetProjectDefinitionCategoryTypesResponse inputCategories.
         * @member {Array.<pl_types.IProjectInputCategory>} inputCategories
         * @memberof project_service.GetProjectDefinitionCategoryTypesResponse
         * @instance
         */
        GetProjectDefinitionCategoryTypesResponse.prototype.inputCategories = $util.emptyArray;

        /**
         * Creates a new GetProjectDefinitionCategoryTypesResponse instance using the specified properties.
         * @function create
         * @memberof project_service.GetProjectDefinitionCategoryTypesResponse
         * @static
         * @param {project_service.IGetProjectDefinitionCategoryTypesResponse=} [properties] Properties to set
         * @returns {project_service.GetProjectDefinitionCategoryTypesResponse} GetProjectDefinitionCategoryTypesResponse instance
         */
        GetProjectDefinitionCategoryTypesResponse.create = function create(properties) {
            return new GetProjectDefinitionCategoryTypesResponse(properties);
        };

        /**
         * Encodes the specified GetProjectDefinitionCategoryTypesResponse message. Does not implicitly {@link project_service.GetProjectDefinitionCategoryTypesResponse.verify|verify} messages.
         * @function encode
         * @memberof project_service.GetProjectDefinitionCategoryTypesResponse
         * @static
         * @param {project_service.IGetProjectDefinitionCategoryTypesResponse} message GetProjectDefinitionCategoryTypesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectDefinitionCategoryTypesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.inputCategories != null && message.inputCategories.length)
                for (var i = 0; i < message.inputCategories.length; ++i)
                    $root.pl_types.ProjectInputCategory.encode(message.inputCategories[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetProjectDefinitionCategoryTypesResponse message, length delimited. Does not implicitly {@link project_service.GetProjectDefinitionCategoryTypesResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof project_service.GetProjectDefinitionCategoryTypesResponse
         * @static
         * @param {project_service.IGetProjectDefinitionCategoryTypesResponse} message GetProjectDefinitionCategoryTypesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectDefinitionCategoryTypesResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetProjectDefinitionCategoryTypesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof project_service.GetProjectDefinitionCategoryTypesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {project_service.GetProjectDefinitionCategoryTypesResponse} GetProjectDefinitionCategoryTypesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectDefinitionCategoryTypesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.project_service.GetProjectDefinitionCategoryTypesResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.inputCategories && message.inputCategories.length))
                            message.inputCategories = [];
                        message.inputCategories.push($root.pl_types.ProjectInputCategory.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetProjectDefinitionCategoryTypesResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof project_service.GetProjectDefinitionCategoryTypesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {project_service.GetProjectDefinitionCategoryTypesResponse} GetProjectDefinitionCategoryTypesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectDefinitionCategoryTypesResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetProjectDefinitionCategoryTypesResponse message.
         * @function verify
         * @memberof project_service.GetProjectDefinitionCategoryTypesResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetProjectDefinitionCategoryTypesResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.inputCategories != null && message.hasOwnProperty("inputCategories")) {
                if (!Array.isArray(message.inputCategories))
                    return "inputCategories: array expected";
                for (var i = 0; i < message.inputCategories.length; ++i) {
                    var error = $root.pl_types.ProjectInputCategory.verify(message.inputCategories[i]);
                    if (error)
                        return "inputCategories." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetProjectDefinitionCategoryTypesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof project_service.GetProjectDefinitionCategoryTypesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {project_service.GetProjectDefinitionCategoryTypesResponse} GetProjectDefinitionCategoryTypesResponse
         */
        GetProjectDefinitionCategoryTypesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.project_service.GetProjectDefinitionCategoryTypesResponse)
                return object;
            var message = new $root.project_service.GetProjectDefinitionCategoryTypesResponse();
            if (object.inputCategories) {
                if (!Array.isArray(object.inputCategories))
                    throw TypeError(".project_service.GetProjectDefinitionCategoryTypesResponse.inputCategories: array expected");
                message.inputCategories = [];
                for (var i = 0; i < object.inputCategories.length; ++i) {
                    if (typeof object.inputCategories[i] !== "object")
                        throw TypeError(".project_service.GetProjectDefinitionCategoryTypesResponse.inputCategories: object expected");
                    message.inputCategories[i] = $root.pl_types.ProjectInputCategory.fromObject(object.inputCategories[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetProjectDefinitionCategoryTypesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof project_service.GetProjectDefinitionCategoryTypesResponse
         * @static
         * @param {project_service.GetProjectDefinitionCategoryTypesResponse} message GetProjectDefinitionCategoryTypesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetProjectDefinitionCategoryTypesResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.inputCategories = [];
            if (message.inputCategories && message.inputCategories.length) {
                object.inputCategories = [];
                for (var j = 0; j < message.inputCategories.length; ++j)
                    object.inputCategories[j] = $root.pl_types.ProjectInputCategory.toObject(message.inputCategories[j], options);
            }
            return object;
        };

        /**
         * Converts this GetProjectDefinitionCategoryTypesResponse to JSON.
         * @function toJSON
         * @memberof project_service.GetProjectDefinitionCategoryTypesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetProjectDefinitionCategoryTypesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetProjectDefinitionCategoryTypesResponse
         * @function getTypeUrl
         * @memberof project_service.GetProjectDefinitionCategoryTypesResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetProjectDefinitionCategoryTypesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/project_service.GetProjectDefinitionCategoryTypesResponse";
        };

        return GetProjectDefinitionCategoryTypesResponse;
    })();

    project_service.RegisterAnonymousProjectsRequest = (function() {

        /**
         * Properties of a RegisterAnonymousProjectsRequest.
         * @memberof project_service
         * @interface IRegisterAnonymousProjectsRequest
         * @property {number|null} [projectInputId] RegisterAnonymousProjectsRequest projectInputId
         */

        /**
         * Constructs a new RegisterAnonymousProjectsRequest.
         * @memberof project_service
         * @classdesc Represents a RegisterAnonymousProjectsRequest.
         * @implements IRegisterAnonymousProjectsRequest
         * @constructor
         * @param {project_service.IRegisterAnonymousProjectsRequest=} [properties] Properties to set
         */
        function RegisterAnonymousProjectsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RegisterAnonymousProjectsRequest projectInputId.
         * @member {number|null|undefined} projectInputId
         * @memberof project_service.RegisterAnonymousProjectsRequest
         * @instance
         */
        RegisterAnonymousProjectsRequest.prototype.projectInputId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * RegisterAnonymousProjectsRequest _projectInputId.
         * @member {"projectInputId"|undefined} _projectInputId
         * @memberof project_service.RegisterAnonymousProjectsRequest
         * @instance
         */
        Object.defineProperty(RegisterAnonymousProjectsRequest.prototype, "_projectInputId", {
            get: $util.oneOfGetter($oneOfFields = ["projectInputId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new RegisterAnonymousProjectsRequest instance using the specified properties.
         * @function create
         * @memberof project_service.RegisterAnonymousProjectsRequest
         * @static
         * @param {project_service.IRegisterAnonymousProjectsRequest=} [properties] Properties to set
         * @returns {project_service.RegisterAnonymousProjectsRequest} RegisterAnonymousProjectsRequest instance
         */
        RegisterAnonymousProjectsRequest.create = function create(properties) {
            return new RegisterAnonymousProjectsRequest(properties);
        };

        /**
         * Encodes the specified RegisterAnonymousProjectsRequest message. Does not implicitly {@link project_service.RegisterAnonymousProjectsRequest.verify|verify} messages.
         * @function encode
         * @memberof project_service.RegisterAnonymousProjectsRequest
         * @static
         * @param {project_service.IRegisterAnonymousProjectsRequest} message RegisterAnonymousProjectsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegisterAnonymousProjectsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectInputId != null && Object.hasOwnProperty.call(message, "projectInputId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.projectInputId);
            return writer;
        };

        /**
         * Encodes the specified RegisterAnonymousProjectsRequest message, length delimited. Does not implicitly {@link project_service.RegisterAnonymousProjectsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof project_service.RegisterAnonymousProjectsRequest
         * @static
         * @param {project_service.IRegisterAnonymousProjectsRequest} message RegisterAnonymousProjectsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegisterAnonymousProjectsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RegisterAnonymousProjectsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof project_service.RegisterAnonymousProjectsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {project_service.RegisterAnonymousProjectsRequest} RegisterAnonymousProjectsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegisterAnonymousProjectsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.project_service.RegisterAnonymousProjectsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.projectInputId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RegisterAnonymousProjectsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof project_service.RegisterAnonymousProjectsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {project_service.RegisterAnonymousProjectsRequest} RegisterAnonymousProjectsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegisterAnonymousProjectsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RegisterAnonymousProjectsRequest message.
         * @function verify
         * @memberof project_service.RegisterAnonymousProjectsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RegisterAnonymousProjectsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.projectInputId != null && message.hasOwnProperty("projectInputId")) {
                properties._projectInputId = 1;
                if (!$util.isInteger(message.projectInputId))
                    return "projectInputId: integer expected";
            }
            return null;
        };

        /**
         * Creates a RegisterAnonymousProjectsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof project_service.RegisterAnonymousProjectsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {project_service.RegisterAnonymousProjectsRequest} RegisterAnonymousProjectsRequest
         */
        RegisterAnonymousProjectsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.project_service.RegisterAnonymousProjectsRequest)
                return object;
            var message = new $root.project_service.RegisterAnonymousProjectsRequest();
            if (object.projectInputId != null)
                message.projectInputId = object.projectInputId | 0;
            return message;
        };

        /**
         * Creates a plain object from a RegisterAnonymousProjectsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof project_service.RegisterAnonymousProjectsRequest
         * @static
         * @param {project_service.RegisterAnonymousProjectsRequest} message RegisterAnonymousProjectsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RegisterAnonymousProjectsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.projectInputId != null && message.hasOwnProperty("projectInputId")) {
                object.projectInputId = message.projectInputId;
                if (options.oneofs)
                    object._projectInputId = "projectInputId";
            }
            return object;
        };

        /**
         * Converts this RegisterAnonymousProjectsRequest to JSON.
         * @function toJSON
         * @memberof project_service.RegisterAnonymousProjectsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RegisterAnonymousProjectsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RegisterAnonymousProjectsRequest
         * @function getTypeUrl
         * @memberof project_service.RegisterAnonymousProjectsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RegisterAnonymousProjectsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/project_service.RegisterAnonymousProjectsRequest";
        };

        return RegisterAnonymousProjectsRequest;
    })();

    project_service.RegisterAnonymousProjectsResponse = (function() {

        /**
         * Properties of a RegisterAnonymousProjectsResponse.
         * @memberof project_service
         * @interface IRegisterAnonymousProjectsResponse
         */

        /**
         * Constructs a new RegisterAnonymousProjectsResponse.
         * @memberof project_service
         * @classdesc Represents a RegisterAnonymousProjectsResponse.
         * @implements IRegisterAnonymousProjectsResponse
         * @constructor
         * @param {project_service.IRegisterAnonymousProjectsResponse=} [properties] Properties to set
         */
        function RegisterAnonymousProjectsResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RegisterAnonymousProjectsResponse instance using the specified properties.
         * @function create
         * @memberof project_service.RegisterAnonymousProjectsResponse
         * @static
         * @param {project_service.IRegisterAnonymousProjectsResponse=} [properties] Properties to set
         * @returns {project_service.RegisterAnonymousProjectsResponse} RegisterAnonymousProjectsResponse instance
         */
        RegisterAnonymousProjectsResponse.create = function create(properties) {
            return new RegisterAnonymousProjectsResponse(properties);
        };

        /**
         * Encodes the specified RegisterAnonymousProjectsResponse message. Does not implicitly {@link project_service.RegisterAnonymousProjectsResponse.verify|verify} messages.
         * @function encode
         * @memberof project_service.RegisterAnonymousProjectsResponse
         * @static
         * @param {project_service.IRegisterAnonymousProjectsResponse} message RegisterAnonymousProjectsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegisterAnonymousProjectsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RegisterAnonymousProjectsResponse message, length delimited. Does not implicitly {@link project_service.RegisterAnonymousProjectsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof project_service.RegisterAnonymousProjectsResponse
         * @static
         * @param {project_service.IRegisterAnonymousProjectsResponse} message RegisterAnonymousProjectsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegisterAnonymousProjectsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RegisterAnonymousProjectsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof project_service.RegisterAnonymousProjectsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {project_service.RegisterAnonymousProjectsResponse} RegisterAnonymousProjectsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegisterAnonymousProjectsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.project_service.RegisterAnonymousProjectsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RegisterAnonymousProjectsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof project_service.RegisterAnonymousProjectsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {project_service.RegisterAnonymousProjectsResponse} RegisterAnonymousProjectsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegisterAnonymousProjectsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RegisterAnonymousProjectsResponse message.
         * @function verify
         * @memberof project_service.RegisterAnonymousProjectsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RegisterAnonymousProjectsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RegisterAnonymousProjectsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof project_service.RegisterAnonymousProjectsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {project_service.RegisterAnonymousProjectsResponse} RegisterAnonymousProjectsResponse
         */
        RegisterAnonymousProjectsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.project_service.RegisterAnonymousProjectsResponse)
                return object;
            return new $root.project_service.RegisterAnonymousProjectsResponse();
        };

        /**
         * Creates a plain object from a RegisterAnonymousProjectsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof project_service.RegisterAnonymousProjectsResponse
         * @static
         * @param {project_service.RegisterAnonymousProjectsResponse} message RegisterAnonymousProjectsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RegisterAnonymousProjectsResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RegisterAnonymousProjectsResponse to JSON.
         * @function toJSON
         * @memberof project_service.RegisterAnonymousProjectsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RegisterAnonymousProjectsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RegisterAnonymousProjectsResponse
         * @function getTypeUrl
         * @memberof project_service.RegisterAnonymousProjectsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RegisterAnonymousProjectsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/project_service.RegisterAnonymousProjectsResponse";
        };

        return RegisterAnonymousProjectsResponse;
    })();

    project_service.GetKnowledgeAndSkillsRequest = (function() {

        /**
         * Properties of a GetKnowledgeAndSkillsRequest.
         * @memberof project_service
         * @interface IGetKnowledgeAndSkillsRequest
         * @property {Array.<pl_types.KnowledgeAndSkill.Type>|null} [types] GetKnowledgeAndSkillsRequest types
         */

        /**
         * Constructs a new GetKnowledgeAndSkillsRequest.
         * @memberof project_service
         * @classdesc Represents a GetKnowledgeAndSkillsRequest.
         * @implements IGetKnowledgeAndSkillsRequest
         * @constructor
         * @param {project_service.IGetKnowledgeAndSkillsRequest=} [properties] Properties to set
         */
        function GetKnowledgeAndSkillsRequest(properties) {
            this.types = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetKnowledgeAndSkillsRequest types.
         * @member {Array.<pl_types.KnowledgeAndSkill.Type>} types
         * @memberof project_service.GetKnowledgeAndSkillsRequest
         * @instance
         */
        GetKnowledgeAndSkillsRequest.prototype.types = $util.emptyArray;

        /**
         * Creates a new GetKnowledgeAndSkillsRequest instance using the specified properties.
         * @function create
         * @memberof project_service.GetKnowledgeAndSkillsRequest
         * @static
         * @param {project_service.IGetKnowledgeAndSkillsRequest=} [properties] Properties to set
         * @returns {project_service.GetKnowledgeAndSkillsRequest} GetKnowledgeAndSkillsRequest instance
         */
        GetKnowledgeAndSkillsRequest.create = function create(properties) {
            return new GetKnowledgeAndSkillsRequest(properties);
        };

        /**
         * Encodes the specified GetKnowledgeAndSkillsRequest message. Does not implicitly {@link project_service.GetKnowledgeAndSkillsRequest.verify|verify} messages.
         * @function encode
         * @memberof project_service.GetKnowledgeAndSkillsRequest
         * @static
         * @param {project_service.IGetKnowledgeAndSkillsRequest} message GetKnowledgeAndSkillsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetKnowledgeAndSkillsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.types != null && message.types.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.types.length; ++i)
                    writer.int32(message.types[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified GetKnowledgeAndSkillsRequest message, length delimited. Does not implicitly {@link project_service.GetKnowledgeAndSkillsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof project_service.GetKnowledgeAndSkillsRequest
         * @static
         * @param {project_service.IGetKnowledgeAndSkillsRequest} message GetKnowledgeAndSkillsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetKnowledgeAndSkillsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetKnowledgeAndSkillsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof project_service.GetKnowledgeAndSkillsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {project_service.GetKnowledgeAndSkillsRequest} GetKnowledgeAndSkillsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetKnowledgeAndSkillsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.project_service.GetKnowledgeAndSkillsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.types && message.types.length))
                            message.types = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.types.push(reader.int32());
                        } else
                            message.types.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetKnowledgeAndSkillsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof project_service.GetKnowledgeAndSkillsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {project_service.GetKnowledgeAndSkillsRequest} GetKnowledgeAndSkillsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetKnowledgeAndSkillsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetKnowledgeAndSkillsRequest message.
         * @function verify
         * @memberof project_service.GetKnowledgeAndSkillsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetKnowledgeAndSkillsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.types != null && message.hasOwnProperty("types")) {
                if (!Array.isArray(message.types))
                    return "types: array expected";
                for (var i = 0; i < message.types.length; ++i)
                    switch (message.types[i]) {
                    default:
                        return "types: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
            }
            return null;
        };

        /**
         * Creates a GetKnowledgeAndSkillsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof project_service.GetKnowledgeAndSkillsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {project_service.GetKnowledgeAndSkillsRequest} GetKnowledgeAndSkillsRequest
         */
        GetKnowledgeAndSkillsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.project_service.GetKnowledgeAndSkillsRequest)
                return object;
            var message = new $root.project_service.GetKnowledgeAndSkillsRequest();
            if (object.types) {
                if (!Array.isArray(object.types))
                    throw TypeError(".project_service.GetKnowledgeAndSkillsRequest.types: array expected");
                message.types = [];
                for (var i = 0; i < object.types.length; ++i)
                    switch (object.types[i]) {
                    default:
                        if (typeof object.types[i] === "number") {
                            message.types[i] = object.types[i];
                            break;
                        }
                    case "UNSET_TYPE":
                    case 0:
                        message.types[i] = 0;
                        break;
                    case "EKS":
                    case 1:
                        message.types[i] = 1;
                        break;
                    case "XQ_COMPETENCY":
                    case 2:
                        message.types[i] = 2;
                        break;
                    case "CTE":
                    case 3:
                        message.types[i] = 3;
                        break;
                    }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetKnowledgeAndSkillsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof project_service.GetKnowledgeAndSkillsRequest
         * @static
         * @param {project_service.GetKnowledgeAndSkillsRequest} message GetKnowledgeAndSkillsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetKnowledgeAndSkillsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.types = [];
            if (message.types && message.types.length) {
                object.types = [];
                for (var j = 0; j < message.types.length; ++j)
                    object.types[j] = options.enums === String ? $root.pl_types.KnowledgeAndSkill.Type[message.types[j]] === undefined ? message.types[j] : $root.pl_types.KnowledgeAndSkill.Type[message.types[j]] : message.types[j];
            }
            return object;
        };

        /**
         * Converts this GetKnowledgeAndSkillsRequest to JSON.
         * @function toJSON
         * @memberof project_service.GetKnowledgeAndSkillsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetKnowledgeAndSkillsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetKnowledgeAndSkillsRequest
         * @function getTypeUrl
         * @memberof project_service.GetKnowledgeAndSkillsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetKnowledgeAndSkillsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/project_service.GetKnowledgeAndSkillsRequest";
        };

        return GetKnowledgeAndSkillsRequest;
    })();

    project_service.GetKnowledgeAndSkillsResponse = (function() {

        /**
         * Properties of a GetKnowledgeAndSkillsResponse.
         * @memberof project_service
         * @interface IGetKnowledgeAndSkillsResponse
         * @property {Array.<pl_types.IKnowledgeAndSkill>|null} [knowledgeAndSkills] GetKnowledgeAndSkillsResponse knowledgeAndSkills
         */

        /**
         * Constructs a new GetKnowledgeAndSkillsResponse.
         * @memberof project_service
         * @classdesc Represents a GetKnowledgeAndSkillsResponse.
         * @implements IGetKnowledgeAndSkillsResponse
         * @constructor
         * @param {project_service.IGetKnowledgeAndSkillsResponse=} [properties] Properties to set
         */
        function GetKnowledgeAndSkillsResponse(properties) {
            this.knowledgeAndSkills = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetKnowledgeAndSkillsResponse knowledgeAndSkills.
         * @member {Array.<pl_types.IKnowledgeAndSkill>} knowledgeAndSkills
         * @memberof project_service.GetKnowledgeAndSkillsResponse
         * @instance
         */
        GetKnowledgeAndSkillsResponse.prototype.knowledgeAndSkills = $util.emptyArray;

        /**
         * Creates a new GetKnowledgeAndSkillsResponse instance using the specified properties.
         * @function create
         * @memberof project_service.GetKnowledgeAndSkillsResponse
         * @static
         * @param {project_service.IGetKnowledgeAndSkillsResponse=} [properties] Properties to set
         * @returns {project_service.GetKnowledgeAndSkillsResponse} GetKnowledgeAndSkillsResponse instance
         */
        GetKnowledgeAndSkillsResponse.create = function create(properties) {
            return new GetKnowledgeAndSkillsResponse(properties);
        };

        /**
         * Encodes the specified GetKnowledgeAndSkillsResponse message. Does not implicitly {@link project_service.GetKnowledgeAndSkillsResponse.verify|verify} messages.
         * @function encode
         * @memberof project_service.GetKnowledgeAndSkillsResponse
         * @static
         * @param {project_service.IGetKnowledgeAndSkillsResponse} message GetKnowledgeAndSkillsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetKnowledgeAndSkillsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.knowledgeAndSkills != null && message.knowledgeAndSkills.length)
                for (var i = 0; i < message.knowledgeAndSkills.length; ++i)
                    $root.pl_types.KnowledgeAndSkill.encode(message.knowledgeAndSkills[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetKnowledgeAndSkillsResponse message, length delimited. Does not implicitly {@link project_service.GetKnowledgeAndSkillsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof project_service.GetKnowledgeAndSkillsResponse
         * @static
         * @param {project_service.IGetKnowledgeAndSkillsResponse} message GetKnowledgeAndSkillsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetKnowledgeAndSkillsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetKnowledgeAndSkillsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof project_service.GetKnowledgeAndSkillsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {project_service.GetKnowledgeAndSkillsResponse} GetKnowledgeAndSkillsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetKnowledgeAndSkillsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.project_service.GetKnowledgeAndSkillsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.knowledgeAndSkills && message.knowledgeAndSkills.length))
                            message.knowledgeAndSkills = [];
                        message.knowledgeAndSkills.push($root.pl_types.KnowledgeAndSkill.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetKnowledgeAndSkillsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof project_service.GetKnowledgeAndSkillsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {project_service.GetKnowledgeAndSkillsResponse} GetKnowledgeAndSkillsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetKnowledgeAndSkillsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetKnowledgeAndSkillsResponse message.
         * @function verify
         * @memberof project_service.GetKnowledgeAndSkillsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetKnowledgeAndSkillsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.knowledgeAndSkills != null && message.hasOwnProperty("knowledgeAndSkills")) {
                if (!Array.isArray(message.knowledgeAndSkills))
                    return "knowledgeAndSkills: array expected";
                for (var i = 0; i < message.knowledgeAndSkills.length; ++i) {
                    var error = $root.pl_types.KnowledgeAndSkill.verify(message.knowledgeAndSkills[i]);
                    if (error)
                        return "knowledgeAndSkills." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetKnowledgeAndSkillsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof project_service.GetKnowledgeAndSkillsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {project_service.GetKnowledgeAndSkillsResponse} GetKnowledgeAndSkillsResponse
         */
        GetKnowledgeAndSkillsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.project_service.GetKnowledgeAndSkillsResponse)
                return object;
            var message = new $root.project_service.GetKnowledgeAndSkillsResponse();
            if (object.knowledgeAndSkills) {
                if (!Array.isArray(object.knowledgeAndSkills))
                    throw TypeError(".project_service.GetKnowledgeAndSkillsResponse.knowledgeAndSkills: array expected");
                message.knowledgeAndSkills = [];
                for (var i = 0; i < object.knowledgeAndSkills.length; ++i) {
                    if (typeof object.knowledgeAndSkills[i] !== "object")
                        throw TypeError(".project_service.GetKnowledgeAndSkillsResponse.knowledgeAndSkills: object expected");
                    message.knowledgeAndSkills[i] = $root.pl_types.KnowledgeAndSkill.fromObject(object.knowledgeAndSkills[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetKnowledgeAndSkillsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof project_service.GetKnowledgeAndSkillsResponse
         * @static
         * @param {project_service.GetKnowledgeAndSkillsResponse} message GetKnowledgeAndSkillsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetKnowledgeAndSkillsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.knowledgeAndSkills = [];
            if (message.knowledgeAndSkills && message.knowledgeAndSkills.length) {
                object.knowledgeAndSkills = [];
                for (var j = 0; j < message.knowledgeAndSkills.length; ++j)
                    object.knowledgeAndSkills[j] = $root.pl_types.KnowledgeAndSkill.toObject(message.knowledgeAndSkills[j], options);
            }
            return object;
        };

        /**
         * Converts this GetKnowledgeAndSkillsResponse to JSON.
         * @function toJSON
         * @memberof project_service.GetKnowledgeAndSkillsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetKnowledgeAndSkillsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetKnowledgeAndSkillsResponse
         * @function getTypeUrl
         * @memberof project_service.GetKnowledgeAndSkillsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetKnowledgeAndSkillsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/project_service.GetKnowledgeAndSkillsResponse";
        };

        return GetKnowledgeAndSkillsResponse;
    })();

    project_service.UpsertKnowledgeAndSkillRequest = (function() {

        /**
         * Properties of an UpsertKnowledgeAndSkillRequest.
         * @memberof project_service
         * @interface IUpsertKnowledgeAndSkillRequest
         * @property {pl_types.IKnowledgeAndSkill|null} [knowledgeAndSkill] UpsertKnowledgeAndSkillRequest knowledgeAndSkill
         */

        /**
         * Constructs a new UpsertKnowledgeAndSkillRequest.
         * @memberof project_service
         * @classdesc Represents an UpsertKnowledgeAndSkillRequest.
         * @implements IUpsertKnowledgeAndSkillRequest
         * @constructor
         * @param {project_service.IUpsertKnowledgeAndSkillRequest=} [properties] Properties to set
         */
        function UpsertKnowledgeAndSkillRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertKnowledgeAndSkillRequest knowledgeAndSkill.
         * @member {pl_types.IKnowledgeAndSkill|null|undefined} knowledgeAndSkill
         * @memberof project_service.UpsertKnowledgeAndSkillRequest
         * @instance
         */
        UpsertKnowledgeAndSkillRequest.prototype.knowledgeAndSkill = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UpsertKnowledgeAndSkillRequest _knowledgeAndSkill.
         * @member {"knowledgeAndSkill"|undefined} _knowledgeAndSkill
         * @memberof project_service.UpsertKnowledgeAndSkillRequest
         * @instance
         */
        Object.defineProperty(UpsertKnowledgeAndSkillRequest.prototype, "_knowledgeAndSkill", {
            get: $util.oneOfGetter($oneOfFields = ["knowledgeAndSkill"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UpsertKnowledgeAndSkillRequest instance using the specified properties.
         * @function create
         * @memberof project_service.UpsertKnowledgeAndSkillRequest
         * @static
         * @param {project_service.IUpsertKnowledgeAndSkillRequest=} [properties] Properties to set
         * @returns {project_service.UpsertKnowledgeAndSkillRequest} UpsertKnowledgeAndSkillRequest instance
         */
        UpsertKnowledgeAndSkillRequest.create = function create(properties) {
            return new UpsertKnowledgeAndSkillRequest(properties);
        };

        /**
         * Encodes the specified UpsertKnowledgeAndSkillRequest message. Does not implicitly {@link project_service.UpsertKnowledgeAndSkillRequest.verify|verify} messages.
         * @function encode
         * @memberof project_service.UpsertKnowledgeAndSkillRequest
         * @static
         * @param {project_service.IUpsertKnowledgeAndSkillRequest} message UpsertKnowledgeAndSkillRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertKnowledgeAndSkillRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.knowledgeAndSkill != null && Object.hasOwnProperty.call(message, "knowledgeAndSkill"))
                $root.pl_types.KnowledgeAndSkill.encode(message.knowledgeAndSkill, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpsertKnowledgeAndSkillRequest message, length delimited. Does not implicitly {@link project_service.UpsertKnowledgeAndSkillRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof project_service.UpsertKnowledgeAndSkillRequest
         * @static
         * @param {project_service.IUpsertKnowledgeAndSkillRequest} message UpsertKnowledgeAndSkillRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertKnowledgeAndSkillRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpsertKnowledgeAndSkillRequest message from the specified reader or buffer.
         * @function decode
         * @memberof project_service.UpsertKnowledgeAndSkillRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {project_service.UpsertKnowledgeAndSkillRequest} UpsertKnowledgeAndSkillRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertKnowledgeAndSkillRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.project_service.UpsertKnowledgeAndSkillRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.knowledgeAndSkill = $root.pl_types.KnowledgeAndSkill.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpsertKnowledgeAndSkillRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof project_service.UpsertKnowledgeAndSkillRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {project_service.UpsertKnowledgeAndSkillRequest} UpsertKnowledgeAndSkillRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertKnowledgeAndSkillRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpsertKnowledgeAndSkillRequest message.
         * @function verify
         * @memberof project_service.UpsertKnowledgeAndSkillRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpsertKnowledgeAndSkillRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.knowledgeAndSkill != null && message.hasOwnProperty("knowledgeAndSkill")) {
                properties._knowledgeAndSkill = 1;
                {
                    var error = $root.pl_types.KnowledgeAndSkill.verify(message.knowledgeAndSkill);
                    if (error)
                        return "knowledgeAndSkill." + error;
                }
            }
            return null;
        };

        /**
         * Creates an UpsertKnowledgeAndSkillRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof project_service.UpsertKnowledgeAndSkillRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {project_service.UpsertKnowledgeAndSkillRequest} UpsertKnowledgeAndSkillRequest
         */
        UpsertKnowledgeAndSkillRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.project_service.UpsertKnowledgeAndSkillRequest)
                return object;
            var message = new $root.project_service.UpsertKnowledgeAndSkillRequest();
            if (object.knowledgeAndSkill != null) {
                if (typeof object.knowledgeAndSkill !== "object")
                    throw TypeError(".project_service.UpsertKnowledgeAndSkillRequest.knowledgeAndSkill: object expected");
                message.knowledgeAndSkill = $root.pl_types.KnowledgeAndSkill.fromObject(object.knowledgeAndSkill);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpsertKnowledgeAndSkillRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof project_service.UpsertKnowledgeAndSkillRequest
         * @static
         * @param {project_service.UpsertKnowledgeAndSkillRequest} message UpsertKnowledgeAndSkillRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpsertKnowledgeAndSkillRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.knowledgeAndSkill != null && message.hasOwnProperty("knowledgeAndSkill")) {
                object.knowledgeAndSkill = $root.pl_types.KnowledgeAndSkill.toObject(message.knowledgeAndSkill, options);
                if (options.oneofs)
                    object._knowledgeAndSkill = "knowledgeAndSkill";
            }
            return object;
        };

        /**
         * Converts this UpsertKnowledgeAndSkillRequest to JSON.
         * @function toJSON
         * @memberof project_service.UpsertKnowledgeAndSkillRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpsertKnowledgeAndSkillRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpsertKnowledgeAndSkillRequest
         * @function getTypeUrl
         * @memberof project_service.UpsertKnowledgeAndSkillRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpsertKnowledgeAndSkillRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/project_service.UpsertKnowledgeAndSkillRequest";
        };

        return UpsertKnowledgeAndSkillRequest;
    })();

    project_service.UpsertKnowledgeAndSkillResponse = (function() {

        /**
         * Properties of an UpsertKnowledgeAndSkillResponse.
         * @memberof project_service
         * @interface IUpsertKnowledgeAndSkillResponse
         * @property {pl_types.IKnowledgeAndSkill|null} [knowledgeAndSkill] UpsertKnowledgeAndSkillResponse knowledgeAndSkill
         */

        /**
         * Constructs a new UpsertKnowledgeAndSkillResponse.
         * @memberof project_service
         * @classdesc Represents an UpsertKnowledgeAndSkillResponse.
         * @implements IUpsertKnowledgeAndSkillResponse
         * @constructor
         * @param {project_service.IUpsertKnowledgeAndSkillResponse=} [properties] Properties to set
         */
        function UpsertKnowledgeAndSkillResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertKnowledgeAndSkillResponse knowledgeAndSkill.
         * @member {pl_types.IKnowledgeAndSkill|null|undefined} knowledgeAndSkill
         * @memberof project_service.UpsertKnowledgeAndSkillResponse
         * @instance
         */
        UpsertKnowledgeAndSkillResponse.prototype.knowledgeAndSkill = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UpsertKnowledgeAndSkillResponse _knowledgeAndSkill.
         * @member {"knowledgeAndSkill"|undefined} _knowledgeAndSkill
         * @memberof project_service.UpsertKnowledgeAndSkillResponse
         * @instance
         */
        Object.defineProperty(UpsertKnowledgeAndSkillResponse.prototype, "_knowledgeAndSkill", {
            get: $util.oneOfGetter($oneOfFields = ["knowledgeAndSkill"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UpsertKnowledgeAndSkillResponse instance using the specified properties.
         * @function create
         * @memberof project_service.UpsertKnowledgeAndSkillResponse
         * @static
         * @param {project_service.IUpsertKnowledgeAndSkillResponse=} [properties] Properties to set
         * @returns {project_service.UpsertKnowledgeAndSkillResponse} UpsertKnowledgeAndSkillResponse instance
         */
        UpsertKnowledgeAndSkillResponse.create = function create(properties) {
            return new UpsertKnowledgeAndSkillResponse(properties);
        };

        /**
         * Encodes the specified UpsertKnowledgeAndSkillResponse message. Does not implicitly {@link project_service.UpsertKnowledgeAndSkillResponse.verify|verify} messages.
         * @function encode
         * @memberof project_service.UpsertKnowledgeAndSkillResponse
         * @static
         * @param {project_service.IUpsertKnowledgeAndSkillResponse} message UpsertKnowledgeAndSkillResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertKnowledgeAndSkillResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.knowledgeAndSkill != null && Object.hasOwnProperty.call(message, "knowledgeAndSkill"))
                $root.pl_types.KnowledgeAndSkill.encode(message.knowledgeAndSkill, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpsertKnowledgeAndSkillResponse message, length delimited. Does not implicitly {@link project_service.UpsertKnowledgeAndSkillResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof project_service.UpsertKnowledgeAndSkillResponse
         * @static
         * @param {project_service.IUpsertKnowledgeAndSkillResponse} message UpsertKnowledgeAndSkillResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertKnowledgeAndSkillResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpsertKnowledgeAndSkillResponse message from the specified reader or buffer.
         * @function decode
         * @memberof project_service.UpsertKnowledgeAndSkillResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {project_service.UpsertKnowledgeAndSkillResponse} UpsertKnowledgeAndSkillResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertKnowledgeAndSkillResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.project_service.UpsertKnowledgeAndSkillResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.knowledgeAndSkill = $root.pl_types.KnowledgeAndSkill.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpsertKnowledgeAndSkillResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof project_service.UpsertKnowledgeAndSkillResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {project_service.UpsertKnowledgeAndSkillResponse} UpsertKnowledgeAndSkillResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertKnowledgeAndSkillResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpsertKnowledgeAndSkillResponse message.
         * @function verify
         * @memberof project_service.UpsertKnowledgeAndSkillResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpsertKnowledgeAndSkillResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.knowledgeAndSkill != null && message.hasOwnProperty("knowledgeAndSkill")) {
                properties._knowledgeAndSkill = 1;
                {
                    var error = $root.pl_types.KnowledgeAndSkill.verify(message.knowledgeAndSkill);
                    if (error)
                        return "knowledgeAndSkill." + error;
                }
            }
            return null;
        };

        /**
         * Creates an UpsertKnowledgeAndSkillResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof project_service.UpsertKnowledgeAndSkillResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {project_service.UpsertKnowledgeAndSkillResponse} UpsertKnowledgeAndSkillResponse
         */
        UpsertKnowledgeAndSkillResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.project_service.UpsertKnowledgeAndSkillResponse)
                return object;
            var message = new $root.project_service.UpsertKnowledgeAndSkillResponse();
            if (object.knowledgeAndSkill != null) {
                if (typeof object.knowledgeAndSkill !== "object")
                    throw TypeError(".project_service.UpsertKnowledgeAndSkillResponse.knowledgeAndSkill: object expected");
                message.knowledgeAndSkill = $root.pl_types.KnowledgeAndSkill.fromObject(object.knowledgeAndSkill);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpsertKnowledgeAndSkillResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof project_service.UpsertKnowledgeAndSkillResponse
         * @static
         * @param {project_service.UpsertKnowledgeAndSkillResponse} message UpsertKnowledgeAndSkillResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpsertKnowledgeAndSkillResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.knowledgeAndSkill != null && message.hasOwnProperty("knowledgeAndSkill")) {
                object.knowledgeAndSkill = $root.pl_types.KnowledgeAndSkill.toObject(message.knowledgeAndSkill, options);
                if (options.oneofs)
                    object._knowledgeAndSkill = "knowledgeAndSkill";
            }
            return object;
        };

        /**
         * Converts this UpsertKnowledgeAndSkillResponse to JSON.
         * @function toJSON
         * @memberof project_service.UpsertKnowledgeAndSkillResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpsertKnowledgeAndSkillResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpsertKnowledgeAndSkillResponse
         * @function getTypeUrl
         * @memberof project_service.UpsertKnowledgeAndSkillResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpsertKnowledgeAndSkillResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/project_service.UpsertKnowledgeAndSkillResponse";
        };

        return UpsertKnowledgeAndSkillResponse;
    })();

    return project_service;
})();

$root.resource_x_service = (function() {

    /**
     * Namespace resource_x_service.
     * @exports resource_x_service
     * @namespace
     */
    var resource_x_service = {};

    resource_x_service.ResourceXService = (function() {

        /**
         * Constructs a new ResourceXService service.
         * @memberof resource_x_service
         * @classdesc Represents a ResourceXService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ResourceXService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ResourceXService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ResourceXService;

        /**
         * Creates new ResourceXService service using the specified rpc implementation.
         * @function create
         * @memberof resource_x_service.ResourceXService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {ResourceXService} RPC service. Useful where requests and/or responses are streamed.
         */
        ResourceXService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link resource_x_service.ResourceXService#getResourceXs}.
         * @memberof resource_x_service.ResourceXService
         * @typedef GetResourceXsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {resource_x_service.GetResourceXsResponse} [response] GetResourceXsResponse
         */

        /**
         * Calls GetResourceXs.
         * @function getResourceXs
         * @memberof resource_x_service.ResourceXService
         * @instance
         * @param {resource_x_service.IGetResourceXsRequest} request GetResourceXsRequest message or plain object
         * @param {resource_x_service.ResourceXService.GetResourceXsCallback} callback Node-style callback called with the error, if any, and GetResourceXsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ResourceXService.prototype.getResourceXs = function getResourceXs(request, callback) {
            return this.rpcCall(getResourceXs, $root.resource_x_service.GetResourceXsRequest, $root.resource_x_service.GetResourceXsResponse, request, callback);
        }, "name", { value: "GetResourceXs" });

        /**
         * Calls GetResourceXs.
         * @function getResourceXs
         * @memberof resource_x_service.ResourceXService
         * @instance
         * @param {resource_x_service.IGetResourceXsRequest} request GetResourceXsRequest message or plain object
         * @returns {Promise<resource_x_service.GetResourceXsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link resource_x_service.ResourceXService#upsertResourceX}.
         * @memberof resource_x_service.ResourceXService
         * @typedef UpsertResourceXCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {resource_x_service.UpsertResourceXResponse} [response] UpsertResourceXResponse
         */

        /**
         * Calls UpsertResourceX.
         * @function upsertResourceX
         * @memberof resource_x_service.ResourceXService
         * @instance
         * @param {resource_x_service.IUpsertResourceXRequest} request UpsertResourceXRequest message or plain object
         * @param {resource_x_service.ResourceXService.UpsertResourceXCallback} callback Node-style callback called with the error, if any, and UpsertResourceXResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ResourceXService.prototype.upsertResourceX = function upsertResourceX(request, callback) {
            return this.rpcCall(upsertResourceX, $root.resource_x_service.UpsertResourceXRequest, $root.resource_x_service.UpsertResourceXResponse, request, callback);
        }, "name", { value: "UpsertResourceX" });

        /**
         * Calls UpsertResourceX.
         * @function upsertResourceX
         * @memberof resource_x_service.ResourceXService
         * @instance
         * @param {resource_x_service.IUpsertResourceXRequest} request UpsertResourceXRequest message or plain object
         * @returns {Promise<resource_x_service.UpsertResourceXResponse>} Promise
         * @variation 2
         */

        return ResourceXService;
    })();

    resource_x_service.GetResourceXsRequest = (function() {

        /**
         * Properties of a GetResourceXsRequest.
         * @memberof resource_x_service
         * @interface IGetResourceXsRequest
         * @property {Array.<number>|null} [resourceXIds] GetResourceXsRequest resourceXIds
         * @property {Array.<number>|null} [userXIds] GetResourceXsRequest userXIds
         */

        /**
         * Constructs a new GetResourceXsRequest.
         * @memberof resource_x_service
         * @classdesc Represents a GetResourceXsRequest.
         * @implements IGetResourceXsRequest
         * @constructor
         * @param {resource_x_service.IGetResourceXsRequest=} [properties] Properties to set
         */
        function GetResourceXsRequest(properties) {
            this.resourceXIds = [];
            this.userXIds = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetResourceXsRequest resourceXIds.
         * @member {Array.<number>} resourceXIds
         * @memberof resource_x_service.GetResourceXsRequest
         * @instance
         */
        GetResourceXsRequest.prototype.resourceXIds = $util.emptyArray;

        /**
         * GetResourceXsRequest userXIds.
         * @member {Array.<number>} userXIds
         * @memberof resource_x_service.GetResourceXsRequest
         * @instance
         */
        GetResourceXsRequest.prototype.userXIds = $util.emptyArray;

        /**
         * Creates a new GetResourceXsRequest instance using the specified properties.
         * @function create
         * @memberof resource_x_service.GetResourceXsRequest
         * @static
         * @param {resource_x_service.IGetResourceXsRequest=} [properties] Properties to set
         * @returns {resource_x_service.GetResourceXsRequest} GetResourceXsRequest instance
         */
        GetResourceXsRequest.create = function create(properties) {
            return new GetResourceXsRequest(properties);
        };

        /**
         * Encodes the specified GetResourceXsRequest message. Does not implicitly {@link resource_x_service.GetResourceXsRequest.verify|verify} messages.
         * @function encode
         * @memberof resource_x_service.GetResourceXsRequest
         * @static
         * @param {resource_x_service.IGetResourceXsRequest} message GetResourceXsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetResourceXsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.resourceXIds != null && message.resourceXIds.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.resourceXIds.length; ++i)
                    writer.int32(message.resourceXIds[i]);
                writer.ldelim();
            }
            if (message.userXIds != null && message.userXIds.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.userXIds.length; ++i)
                    writer.int32(message.userXIds[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified GetResourceXsRequest message, length delimited. Does not implicitly {@link resource_x_service.GetResourceXsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof resource_x_service.GetResourceXsRequest
         * @static
         * @param {resource_x_service.IGetResourceXsRequest} message GetResourceXsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetResourceXsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetResourceXsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof resource_x_service.GetResourceXsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource_x_service.GetResourceXsRequest} GetResourceXsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetResourceXsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource_x_service.GetResourceXsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.resourceXIds && message.resourceXIds.length))
                            message.resourceXIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.resourceXIds.push(reader.int32());
                        } else
                            message.resourceXIds.push(reader.int32());
                        break;
                    }
                case 2: {
                        if (!(message.userXIds && message.userXIds.length))
                            message.userXIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.userXIds.push(reader.int32());
                        } else
                            message.userXIds.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetResourceXsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof resource_x_service.GetResourceXsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {resource_x_service.GetResourceXsRequest} GetResourceXsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetResourceXsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetResourceXsRequest message.
         * @function verify
         * @memberof resource_x_service.GetResourceXsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetResourceXsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.resourceXIds != null && message.hasOwnProperty("resourceXIds")) {
                if (!Array.isArray(message.resourceXIds))
                    return "resourceXIds: array expected";
                for (var i = 0; i < message.resourceXIds.length; ++i)
                    if (!$util.isInteger(message.resourceXIds[i]))
                        return "resourceXIds: integer[] expected";
            }
            if (message.userXIds != null && message.hasOwnProperty("userXIds")) {
                if (!Array.isArray(message.userXIds))
                    return "userXIds: array expected";
                for (var i = 0; i < message.userXIds.length; ++i)
                    if (!$util.isInteger(message.userXIds[i]))
                        return "userXIds: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a GetResourceXsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource_x_service.GetResourceXsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource_x_service.GetResourceXsRequest} GetResourceXsRequest
         */
        GetResourceXsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.resource_x_service.GetResourceXsRequest)
                return object;
            var message = new $root.resource_x_service.GetResourceXsRequest();
            if (object.resourceXIds) {
                if (!Array.isArray(object.resourceXIds))
                    throw TypeError(".resource_x_service.GetResourceXsRequest.resourceXIds: array expected");
                message.resourceXIds = [];
                for (var i = 0; i < object.resourceXIds.length; ++i)
                    message.resourceXIds[i] = object.resourceXIds[i] | 0;
            }
            if (object.userXIds) {
                if (!Array.isArray(object.userXIds))
                    throw TypeError(".resource_x_service.GetResourceXsRequest.userXIds: array expected");
                message.userXIds = [];
                for (var i = 0; i < object.userXIds.length; ++i)
                    message.userXIds[i] = object.userXIds[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a GetResourceXsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource_x_service.GetResourceXsRequest
         * @static
         * @param {resource_x_service.GetResourceXsRequest} message GetResourceXsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetResourceXsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.resourceXIds = [];
                object.userXIds = [];
            }
            if (message.resourceXIds && message.resourceXIds.length) {
                object.resourceXIds = [];
                for (var j = 0; j < message.resourceXIds.length; ++j)
                    object.resourceXIds[j] = message.resourceXIds[j];
            }
            if (message.userXIds && message.userXIds.length) {
                object.userXIds = [];
                for (var j = 0; j < message.userXIds.length; ++j)
                    object.userXIds[j] = message.userXIds[j];
            }
            return object;
        };

        /**
         * Converts this GetResourceXsRequest to JSON.
         * @function toJSON
         * @memberof resource_x_service.GetResourceXsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetResourceXsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetResourceXsRequest
         * @function getTypeUrl
         * @memberof resource_x_service.GetResourceXsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetResourceXsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource_x_service.GetResourceXsRequest";
        };

        return GetResourceXsRequest;
    })();

    resource_x_service.GetResourceXsResponse = (function() {

        /**
         * Properties of a GetResourceXsResponse.
         * @memberof resource_x_service
         * @interface IGetResourceXsResponse
         * @property {Array.<pl_types.IResourceX>|null} [resourceXs] GetResourceXsResponse resourceXs
         */

        /**
         * Constructs a new GetResourceXsResponse.
         * @memberof resource_x_service
         * @classdesc Represents a GetResourceXsResponse.
         * @implements IGetResourceXsResponse
         * @constructor
         * @param {resource_x_service.IGetResourceXsResponse=} [properties] Properties to set
         */
        function GetResourceXsResponse(properties) {
            this.resourceXs = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetResourceXsResponse resourceXs.
         * @member {Array.<pl_types.IResourceX>} resourceXs
         * @memberof resource_x_service.GetResourceXsResponse
         * @instance
         */
        GetResourceXsResponse.prototype.resourceXs = $util.emptyArray;

        /**
         * Creates a new GetResourceXsResponse instance using the specified properties.
         * @function create
         * @memberof resource_x_service.GetResourceXsResponse
         * @static
         * @param {resource_x_service.IGetResourceXsResponse=} [properties] Properties to set
         * @returns {resource_x_service.GetResourceXsResponse} GetResourceXsResponse instance
         */
        GetResourceXsResponse.create = function create(properties) {
            return new GetResourceXsResponse(properties);
        };

        /**
         * Encodes the specified GetResourceXsResponse message. Does not implicitly {@link resource_x_service.GetResourceXsResponse.verify|verify} messages.
         * @function encode
         * @memberof resource_x_service.GetResourceXsResponse
         * @static
         * @param {resource_x_service.IGetResourceXsResponse} message GetResourceXsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetResourceXsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.resourceXs != null && message.resourceXs.length)
                for (var i = 0; i < message.resourceXs.length; ++i)
                    $root.pl_types.ResourceX.encode(message.resourceXs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetResourceXsResponse message, length delimited. Does not implicitly {@link resource_x_service.GetResourceXsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof resource_x_service.GetResourceXsResponse
         * @static
         * @param {resource_x_service.IGetResourceXsResponse} message GetResourceXsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetResourceXsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetResourceXsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof resource_x_service.GetResourceXsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource_x_service.GetResourceXsResponse} GetResourceXsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetResourceXsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource_x_service.GetResourceXsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.resourceXs && message.resourceXs.length))
                            message.resourceXs = [];
                        message.resourceXs.push($root.pl_types.ResourceX.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetResourceXsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof resource_x_service.GetResourceXsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {resource_x_service.GetResourceXsResponse} GetResourceXsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetResourceXsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetResourceXsResponse message.
         * @function verify
         * @memberof resource_x_service.GetResourceXsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetResourceXsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.resourceXs != null && message.hasOwnProperty("resourceXs")) {
                if (!Array.isArray(message.resourceXs))
                    return "resourceXs: array expected";
                for (var i = 0; i < message.resourceXs.length; ++i) {
                    var error = $root.pl_types.ResourceX.verify(message.resourceXs[i]);
                    if (error)
                        return "resourceXs." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetResourceXsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource_x_service.GetResourceXsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource_x_service.GetResourceXsResponse} GetResourceXsResponse
         */
        GetResourceXsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.resource_x_service.GetResourceXsResponse)
                return object;
            var message = new $root.resource_x_service.GetResourceXsResponse();
            if (object.resourceXs) {
                if (!Array.isArray(object.resourceXs))
                    throw TypeError(".resource_x_service.GetResourceXsResponse.resourceXs: array expected");
                message.resourceXs = [];
                for (var i = 0; i < object.resourceXs.length; ++i) {
                    if (typeof object.resourceXs[i] !== "object")
                        throw TypeError(".resource_x_service.GetResourceXsResponse.resourceXs: object expected");
                    message.resourceXs[i] = $root.pl_types.ResourceX.fromObject(object.resourceXs[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetResourceXsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource_x_service.GetResourceXsResponse
         * @static
         * @param {resource_x_service.GetResourceXsResponse} message GetResourceXsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetResourceXsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.resourceXs = [];
            if (message.resourceXs && message.resourceXs.length) {
                object.resourceXs = [];
                for (var j = 0; j < message.resourceXs.length; ++j)
                    object.resourceXs[j] = $root.pl_types.ResourceX.toObject(message.resourceXs[j], options);
            }
            return object;
        };

        /**
         * Converts this GetResourceXsResponse to JSON.
         * @function toJSON
         * @memberof resource_x_service.GetResourceXsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetResourceXsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetResourceXsResponse
         * @function getTypeUrl
         * @memberof resource_x_service.GetResourceXsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetResourceXsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource_x_service.GetResourceXsResponse";
        };

        return GetResourceXsResponse;
    })();

    resource_x_service.UpsertResourceXRequest = (function() {

        /**
         * Properties of an UpsertResourceXRequest.
         * @memberof resource_x_service
         * @interface IUpsertResourceXRequest
         * @property {pl_types.IResourceX|null} [resourceX] UpsertResourceXRequest resourceX
         */

        /**
         * Constructs a new UpsertResourceXRequest.
         * @memberof resource_x_service
         * @classdesc Represents an UpsertResourceXRequest.
         * @implements IUpsertResourceXRequest
         * @constructor
         * @param {resource_x_service.IUpsertResourceXRequest=} [properties] Properties to set
         */
        function UpsertResourceXRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertResourceXRequest resourceX.
         * @member {pl_types.IResourceX|null|undefined} resourceX
         * @memberof resource_x_service.UpsertResourceXRequest
         * @instance
         */
        UpsertResourceXRequest.prototype.resourceX = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UpsertResourceXRequest _resourceX.
         * @member {"resourceX"|undefined} _resourceX
         * @memberof resource_x_service.UpsertResourceXRequest
         * @instance
         */
        Object.defineProperty(UpsertResourceXRequest.prototype, "_resourceX", {
            get: $util.oneOfGetter($oneOfFields = ["resourceX"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UpsertResourceXRequest instance using the specified properties.
         * @function create
         * @memberof resource_x_service.UpsertResourceXRequest
         * @static
         * @param {resource_x_service.IUpsertResourceXRequest=} [properties] Properties to set
         * @returns {resource_x_service.UpsertResourceXRequest} UpsertResourceXRequest instance
         */
        UpsertResourceXRequest.create = function create(properties) {
            return new UpsertResourceXRequest(properties);
        };

        /**
         * Encodes the specified UpsertResourceXRequest message. Does not implicitly {@link resource_x_service.UpsertResourceXRequest.verify|verify} messages.
         * @function encode
         * @memberof resource_x_service.UpsertResourceXRequest
         * @static
         * @param {resource_x_service.IUpsertResourceXRequest} message UpsertResourceXRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertResourceXRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.resourceX != null && Object.hasOwnProperty.call(message, "resourceX"))
                $root.pl_types.ResourceX.encode(message.resourceX, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpsertResourceXRequest message, length delimited. Does not implicitly {@link resource_x_service.UpsertResourceXRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof resource_x_service.UpsertResourceXRequest
         * @static
         * @param {resource_x_service.IUpsertResourceXRequest} message UpsertResourceXRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertResourceXRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpsertResourceXRequest message from the specified reader or buffer.
         * @function decode
         * @memberof resource_x_service.UpsertResourceXRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource_x_service.UpsertResourceXRequest} UpsertResourceXRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertResourceXRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource_x_service.UpsertResourceXRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.resourceX = $root.pl_types.ResourceX.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpsertResourceXRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof resource_x_service.UpsertResourceXRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {resource_x_service.UpsertResourceXRequest} UpsertResourceXRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertResourceXRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpsertResourceXRequest message.
         * @function verify
         * @memberof resource_x_service.UpsertResourceXRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpsertResourceXRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.resourceX != null && message.hasOwnProperty("resourceX")) {
                properties._resourceX = 1;
                {
                    var error = $root.pl_types.ResourceX.verify(message.resourceX);
                    if (error)
                        return "resourceX." + error;
                }
            }
            return null;
        };

        /**
         * Creates an UpsertResourceXRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource_x_service.UpsertResourceXRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource_x_service.UpsertResourceXRequest} UpsertResourceXRequest
         */
        UpsertResourceXRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.resource_x_service.UpsertResourceXRequest)
                return object;
            var message = new $root.resource_x_service.UpsertResourceXRequest();
            if (object.resourceX != null) {
                if (typeof object.resourceX !== "object")
                    throw TypeError(".resource_x_service.UpsertResourceXRequest.resourceX: object expected");
                message.resourceX = $root.pl_types.ResourceX.fromObject(object.resourceX);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpsertResourceXRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource_x_service.UpsertResourceXRequest
         * @static
         * @param {resource_x_service.UpsertResourceXRequest} message UpsertResourceXRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpsertResourceXRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.resourceX != null && message.hasOwnProperty("resourceX")) {
                object.resourceX = $root.pl_types.ResourceX.toObject(message.resourceX, options);
                if (options.oneofs)
                    object._resourceX = "resourceX";
            }
            return object;
        };

        /**
         * Converts this UpsertResourceXRequest to JSON.
         * @function toJSON
         * @memberof resource_x_service.UpsertResourceXRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpsertResourceXRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpsertResourceXRequest
         * @function getTypeUrl
         * @memberof resource_x_service.UpsertResourceXRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpsertResourceXRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource_x_service.UpsertResourceXRequest";
        };

        return UpsertResourceXRequest;
    })();

    resource_x_service.UpsertResourceXResponse = (function() {

        /**
         * Properties of an UpsertResourceXResponse.
         * @memberof resource_x_service
         * @interface IUpsertResourceXResponse
         * @property {pl_types.IResourceX|null} [resourceX] UpsertResourceXResponse resourceX
         */

        /**
         * Constructs a new UpsertResourceXResponse.
         * @memberof resource_x_service
         * @classdesc Represents an UpsertResourceXResponse.
         * @implements IUpsertResourceXResponse
         * @constructor
         * @param {resource_x_service.IUpsertResourceXResponse=} [properties] Properties to set
         */
        function UpsertResourceXResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertResourceXResponse resourceX.
         * @member {pl_types.IResourceX|null|undefined} resourceX
         * @memberof resource_x_service.UpsertResourceXResponse
         * @instance
         */
        UpsertResourceXResponse.prototype.resourceX = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UpsertResourceXResponse _resourceX.
         * @member {"resourceX"|undefined} _resourceX
         * @memberof resource_x_service.UpsertResourceXResponse
         * @instance
         */
        Object.defineProperty(UpsertResourceXResponse.prototype, "_resourceX", {
            get: $util.oneOfGetter($oneOfFields = ["resourceX"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UpsertResourceXResponse instance using the specified properties.
         * @function create
         * @memberof resource_x_service.UpsertResourceXResponse
         * @static
         * @param {resource_x_service.IUpsertResourceXResponse=} [properties] Properties to set
         * @returns {resource_x_service.UpsertResourceXResponse} UpsertResourceXResponse instance
         */
        UpsertResourceXResponse.create = function create(properties) {
            return new UpsertResourceXResponse(properties);
        };

        /**
         * Encodes the specified UpsertResourceXResponse message. Does not implicitly {@link resource_x_service.UpsertResourceXResponse.verify|verify} messages.
         * @function encode
         * @memberof resource_x_service.UpsertResourceXResponse
         * @static
         * @param {resource_x_service.IUpsertResourceXResponse} message UpsertResourceXResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertResourceXResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.resourceX != null && Object.hasOwnProperty.call(message, "resourceX"))
                $root.pl_types.ResourceX.encode(message.resourceX, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpsertResourceXResponse message, length delimited. Does not implicitly {@link resource_x_service.UpsertResourceXResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof resource_x_service.UpsertResourceXResponse
         * @static
         * @param {resource_x_service.IUpsertResourceXResponse} message UpsertResourceXResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertResourceXResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpsertResourceXResponse message from the specified reader or buffer.
         * @function decode
         * @memberof resource_x_service.UpsertResourceXResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {resource_x_service.UpsertResourceXResponse} UpsertResourceXResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertResourceXResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.resource_x_service.UpsertResourceXResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.resourceX = $root.pl_types.ResourceX.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpsertResourceXResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof resource_x_service.UpsertResourceXResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {resource_x_service.UpsertResourceXResponse} UpsertResourceXResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertResourceXResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpsertResourceXResponse message.
         * @function verify
         * @memberof resource_x_service.UpsertResourceXResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpsertResourceXResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.resourceX != null && message.hasOwnProperty("resourceX")) {
                properties._resourceX = 1;
                {
                    var error = $root.pl_types.ResourceX.verify(message.resourceX);
                    if (error)
                        return "resourceX." + error;
                }
            }
            return null;
        };

        /**
         * Creates an UpsertResourceXResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof resource_x_service.UpsertResourceXResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {resource_x_service.UpsertResourceXResponse} UpsertResourceXResponse
         */
        UpsertResourceXResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.resource_x_service.UpsertResourceXResponse)
                return object;
            var message = new $root.resource_x_service.UpsertResourceXResponse();
            if (object.resourceX != null) {
                if (typeof object.resourceX !== "object")
                    throw TypeError(".resource_x_service.UpsertResourceXResponse.resourceX: object expected");
                message.resourceX = $root.pl_types.ResourceX.fromObject(object.resourceX);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpsertResourceXResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof resource_x_service.UpsertResourceXResponse
         * @static
         * @param {resource_x_service.UpsertResourceXResponse} message UpsertResourceXResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpsertResourceXResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.resourceX != null && message.hasOwnProperty("resourceX")) {
                object.resourceX = $root.pl_types.ResourceX.toObject(message.resourceX, options);
                if (options.oneofs)
                    object._resourceX = "resourceX";
            }
            return object;
        };

        /**
         * Converts this UpsertResourceXResponse to JSON.
         * @function toJSON
         * @memberof resource_x_service.UpsertResourceXResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpsertResourceXResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpsertResourceXResponse
         * @function getTypeUrl
         * @memberof resource_x_service.UpsertResourceXResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpsertResourceXResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/resource_x_service.UpsertResourceXResponse";
        };

        return UpsertResourceXResponse;
    })();

    return resource_x_service;
})();

$root.school_service = (function() {

    /**
     * Namespace school_service.
     * @exports school_service
     * @namespace
     */
    var school_service = {};

    school_service.SchoolService = (function() {

        /**
         * Constructs a new SchoolService service.
         * @memberof school_service
         * @classdesc Represents a SchoolService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function SchoolService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (SchoolService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SchoolService;

        /**
         * Creates new SchoolService service using the specified rpc implementation.
         * @function create
         * @memberof school_service.SchoolService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {SchoolService} RPC service. Useful where requests and/or responses are streamed.
         */
        SchoolService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link school_service.SchoolService#getSchools}.
         * @memberof school_service.SchoolService
         * @typedef GetSchoolsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {school_service.SchoolInformationResponse} [response] SchoolInformationResponse
         */

        /**
         * Calls GetSchools.
         * @function getSchools
         * @memberof school_service.SchoolService
         * @instance
         * @param {school_service.IGetSchoolsRequest} request GetSchoolsRequest message or plain object
         * @param {school_service.SchoolService.GetSchoolsCallback} callback Node-style callback called with the error, if any, and SchoolInformationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SchoolService.prototype.getSchools = function getSchools(request, callback) {
            return this.rpcCall(getSchools, $root.school_service.GetSchoolsRequest, $root.school_service.SchoolInformationResponse, request, callback);
        }, "name", { value: "GetSchools" });

        /**
         * Calls GetSchools.
         * @function getSchools
         * @memberof school_service.SchoolService
         * @instance
         * @param {school_service.IGetSchoolsRequest} request GetSchoolsRequest message or plain object
         * @returns {Promise<school_service.SchoolInformationResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link school_service.SchoolService#getPagedSchools}.
         * @memberof school_service.SchoolService
         * @typedef GetPagedSchoolsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {school_service.GetPagedSchoolsResponse} [response] GetPagedSchoolsResponse
         */

        /**
         * Calls GetPagedSchools.
         * @function getPagedSchools
         * @memberof school_service.SchoolService
         * @instance
         * @param {school_service.IGetPagedSchoolsRequest} request GetPagedSchoolsRequest message or plain object
         * @param {school_service.SchoolService.GetPagedSchoolsCallback} callback Node-style callback called with the error, if any, and GetPagedSchoolsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SchoolService.prototype.getPagedSchools = function getPagedSchools(request, callback) {
            return this.rpcCall(getPagedSchools, $root.school_service.GetPagedSchoolsRequest, $root.school_service.GetPagedSchoolsResponse, request, callback);
        }, "name", { value: "GetPagedSchools" });

        /**
         * Calls GetPagedSchools.
         * @function getPagedSchools
         * @memberof school_service.SchoolService
         * @instance
         * @param {school_service.IGetPagedSchoolsRequest} request GetPagedSchoolsRequest message or plain object
         * @returns {Promise<school_service.GetPagedSchoolsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link school_service.SchoolService#upsertSchool}.
         * @memberof school_service.SchoolService
         * @typedef UpsertSchoolCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {school_service.SchoolInformationResponse} [response] SchoolInformationResponse
         */

        /**
         * Calls UpsertSchool.
         * @function upsertSchool
         * @memberof school_service.SchoolService
         * @instance
         * @param {school_service.IUpsertSchoolRequest} request UpsertSchoolRequest message or plain object
         * @param {school_service.SchoolService.UpsertSchoolCallback} callback Node-style callback called with the error, if any, and SchoolInformationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SchoolService.prototype.upsertSchool = function upsertSchool(request, callback) {
            return this.rpcCall(upsertSchool, $root.school_service.UpsertSchoolRequest, $root.school_service.SchoolInformationResponse, request, callback);
        }, "name", { value: "UpsertSchool" });

        /**
         * Calls UpsertSchool.
         * @function upsertSchool
         * @memberof school_service.SchoolService
         * @instance
         * @param {school_service.IUpsertSchoolRequest} request UpsertSchoolRequest message or plain object
         * @returns {Promise<school_service.SchoolInformationResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link school_service.SchoolService#removeSchool}.
         * @memberof school_service.SchoolService
         * @typedef RemoveSchoolCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {school_service.SchoolInformationResponse} [response] SchoolInformationResponse
         */

        /**
         * Calls RemoveSchool.
         * @function removeSchool
         * @memberof school_service.SchoolService
         * @instance
         * @param {school_service.IRemoveSchoolRequest} request RemoveSchoolRequest message or plain object
         * @param {school_service.SchoolService.RemoveSchoolCallback} callback Node-style callback called with the error, if any, and SchoolInformationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SchoolService.prototype.removeSchool = function removeSchool(request, callback) {
            return this.rpcCall(removeSchool, $root.school_service.RemoveSchoolRequest, $root.school_service.SchoolInformationResponse, request, callback);
        }, "name", { value: "RemoveSchool" });

        /**
         * Calls RemoveSchool.
         * @function removeSchool
         * @memberof school_service.SchoolService
         * @instance
         * @param {school_service.IRemoveSchoolRequest} request RemoveSchoolRequest message or plain object
         * @returns {Promise<school_service.SchoolInformationResponse>} Promise
         * @variation 2
         */

        return SchoolService;
    })();

    school_service.GetSchoolsRequest = (function() {

        /**
         * Properties of a GetSchoolsRequest.
         * @memberof school_service
         * @interface IGetSchoolsRequest
         * @property {number|null} [districtId] GetSchoolsRequest districtId
         */

        /**
         * Constructs a new GetSchoolsRequest.
         * @memberof school_service
         * @classdesc Represents a GetSchoolsRequest.
         * @implements IGetSchoolsRequest
         * @constructor
         * @param {school_service.IGetSchoolsRequest=} [properties] Properties to set
         */
        function GetSchoolsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetSchoolsRequest districtId.
         * @member {number|null|undefined} districtId
         * @memberof school_service.GetSchoolsRequest
         * @instance
         */
        GetSchoolsRequest.prototype.districtId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GetSchoolsRequest _districtId.
         * @member {"districtId"|undefined} _districtId
         * @memberof school_service.GetSchoolsRequest
         * @instance
         */
        Object.defineProperty(GetSchoolsRequest.prototype, "_districtId", {
            get: $util.oneOfGetter($oneOfFields = ["districtId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetSchoolsRequest instance using the specified properties.
         * @function create
         * @memberof school_service.GetSchoolsRequest
         * @static
         * @param {school_service.IGetSchoolsRequest=} [properties] Properties to set
         * @returns {school_service.GetSchoolsRequest} GetSchoolsRequest instance
         */
        GetSchoolsRequest.create = function create(properties) {
            return new GetSchoolsRequest(properties);
        };

        /**
         * Encodes the specified GetSchoolsRequest message. Does not implicitly {@link school_service.GetSchoolsRequest.verify|verify} messages.
         * @function encode
         * @memberof school_service.GetSchoolsRequest
         * @static
         * @param {school_service.IGetSchoolsRequest} message GetSchoolsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetSchoolsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.districtId != null && Object.hasOwnProperty.call(message, "districtId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.districtId);
            return writer;
        };

        /**
         * Encodes the specified GetSchoolsRequest message, length delimited. Does not implicitly {@link school_service.GetSchoolsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof school_service.GetSchoolsRequest
         * @static
         * @param {school_service.IGetSchoolsRequest} message GetSchoolsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetSchoolsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetSchoolsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof school_service.GetSchoolsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {school_service.GetSchoolsRequest} GetSchoolsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetSchoolsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.school_service.GetSchoolsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.districtId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetSchoolsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof school_service.GetSchoolsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {school_service.GetSchoolsRequest} GetSchoolsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetSchoolsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetSchoolsRequest message.
         * @function verify
         * @memberof school_service.GetSchoolsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetSchoolsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.districtId != null && message.hasOwnProperty("districtId")) {
                properties._districtId = 1;
                if (!$util.isInteger(message.districtId))
                    return "districtId: integer expected";
            }
            return null;
        };

        /**
         * Creates a GetSchoolsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof school_service.GetSchoolsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {school_service.GetSchoolsRequest} GetSchoolsRequest
         */
        GetSchoolsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.school_service.GetSchoolsRequest)
                return object;
            var message = new $root.school_service.GetSchoolsRequest();
            if (object.districtId != null)
                message.districtId = object.districtId | 0;
            return message;
        };

        /**
         * Creates a plain object from a GetSchoolsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof school_service.GetSchoolsRequest
         * @static
         * @param {school_service.GetSchoolsRequest} message GetSchoolsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetSchoolsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.districtId != null && message.hasOwnProperty("districtId")) {
                object.districtId = message.districtId;
                if (options.oneofs)
                    object._districtId = "districtId";
            }
            return object;
        };

        /**
         * Converts this GetSchoolsRequest to JSON.
         * @function toJSON
         * @memberof school_service.GetSchoolsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetSchoolsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetSchoolsRequest
         * @function getTypeUrl
         * @memberof school_service.GetSchoolsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetSchoolsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/school_service.GetSchoolsRequest";
        };

        return GetSchoolsRequest;
    })();

    school_service.GetPagedSchoolsRequest = (function() {

        /**
         * Properties of a GetPagedSchoolsRequest.
         * @memberof school_service
         * @interface IGetPagedSchoolsRequest
         * @property {number|null} [districtId] GetPagedSchoolsRequest districtId
         * @property {number|null} [page] GetPagedSchoolsRequest page
         * @property {number|null} [pageSize] GetPagedSchoolsRequest pageSize
         * @property {string|null} [searchText] GetPagedSchoolsRequest searchText
         */

        /**
         * Constructs a new GetPagedSchoolsRequest.
         * @memberof school_service
         * @classdesc Represents a GetPagedSchoolsRequest.
         * @implements IGetPagedSchoolsRequest
         * @constructor
         * @param {school_service.IGetPagedSchoolsRequest=} [properties] Properties to set
         */
        function GetPagedSchoolsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetPagedSchoolsRequest districtId.
         * @member {number|null|undefined} districtId
         * @memberof school_service.GetPagedSchoolsRequest
         * @instance
         */
        GetPagedSchoolsRequest.prototype.districtId = null;

        /**
         * GetPagedSchoolsRequest page.
         * @member {number|null|undefined} page
         * @memberof school_service.GetPagedSchoolsRequest
         * @instance
         */
        GetPagedSchoolsRequest.prototype.page = null;

        /**
         * GetPagedSchoolsRequest pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof school_service.GetPagedSchoolsRequest
         * @instance
         */
        GetPagedSchoolsRequest.prototype.pageSize = null;

        /**
         * GetPagedSchoolsRequest searchText.
         * @member {string|null|undefined} searchText
         * @memberof school_service.GetPagedSchoolsRequest
         * @instance
         */
        GetPagedSchoolsRequest.prototype.searchText = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GetPagedSchoolsRequest _districtId.
         * @member {"districtId"|undefined} _districtId
         * @memberof school_service.GetPagedSchoolsRequest
         * @instance
         */
        Object.defineProperty(GetPagedSchoolsRequest.prototype, "_districtId", {
            get: $util.oneOfGetter($oneOfFields = ["districtId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetPagedSchoolsRequest _page.
         * @member {"page"|undefined} _page
         * @memberof school_service.GetPagedSchoolsRequest
         * @instance
         */
        Object.defineProperty(GetPagedSchoolsRequest.prototype, "_page", {
            get: $util.oneOfGetter($oneOfFields = ["page"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetPagedSchoolsRequest _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof school_service.GetPagedSchoolsRequest
         * @instance
         */
        Object.defineProperty(GetPagedSchoolsRequest.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetPagedSchoolsRequest _searchText.
         * @member {"searchText"|undefined} _searchText
         * @memberof school_service.GetPagedSchoolsRequest
         * @instance
         */
        Object.defineProperty(GetPagedSchoolsRequest.prototype, "_searchText", {
            get: $util.oneOfGetter($oneOfFields = ["searchText"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetPagedSchoolsRequest instance using the specified properties.
         * @function create
         * @memberof school_service.GetPagedSchoolsRequest
         * @static
         * @param {school_service.IGetPagedSchoolsRequest=} [properties] Properties to set
         * @returns {school_service.GetPagedSchoolsRequest} GetPagedSchoolsRequest instance
         */
        GetPagedSchoolsRequest.create = function create(properties) {
            return new GetPagedSchoolsRequest(properties);
        };

        /**
         * Encodes the specified GetPagedSchoolsRequest message. Does not implicitly {@link school_service.GetPagedSchoolsRequest.verify|verify} messages.
         * @function encode
         * @memberof school_service.GetPagedSchoolsRequest
         * @static
         * @param {school_service.IGetPagedSchoolsRequest} message GetPagedSchoolsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPagedSchoolsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.districtId != null && Object.hasOwnProperty.call(message, "districtId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.districtId);
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageSize);
            if (message.searchText != null && Object.hasOwnProperty.call(message, "searchText"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.searchText);
            return writer;
        };

        /**
         * Encodes the specified GetPagedSchoolsRequest message, length delimited. Does not implicitly {@link school_service.GetPagedSchoolsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof school_service.GetPagedSchoolsRequest
         * @static
         * @param {school_service.IGetPagedSchoolsRequest} message GetPagedSchoolsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPagedSchoolsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetPagedSchoolsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof school_service.GetPagedSchoolsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {school_service.GetPagedSchoolsRequest} GetPagedSchoolsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPagedSchoolsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.school_service.GetPagedSchoolsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.districtId = reader.int32();
                        break;
                    }
                case 2: {
                        message.page = reader.int32();
                        break;
                    }
                case 3: {
                        message.pageSize = reader.int32();
                        break;
                    }
                case 4: {
                        message.searchText = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetPagedSchoolsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof school_service.GetPagedSchoolsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {school_service.GetPagedSchoolsRequest} GetPagedSchoolsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPagedSchoolsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetPagedSchoolsRequest message.
         * @function verify
         * @memberof school_service.GetPagedSchoolsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetPagedSchoolsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.districtId != null && message.hasOwnProperty("districtId")) {
                properties._districtId = 1;
                if (!$util.isInteger(message.districtId))
                    return "districtId: integer expected";
            }
            if (message.page != null && message.hasOwnProperty("page")) {
                properties._page = 1;
                if (!$util.isInteger(message.page))
                    return "page: integer expected";
            }
            if (message.pageSize != null && message.hasOwnProperty("pageSize")) {
                properties._pageSize = 1;
                if (!$util.isInteger(message.pageSize))
                    return "pageSize: integer expected";
            }
            if (message.searchText != null && message.hasOwnProperty("searchText")) {
                properties._searchText = 1;
                if (!$util.isString(message.searchText))
                    return "searchText: string expected";
            }
            return null;
        };

        /**
         * Creates a GetPagedSchoolsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof school_service.GetPagedSchoolsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {school_service.GetPagedSchoolsRequest} GetPagedSchoolsRequest
         */
        GetPagedSchoolsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.school_service.GetPagedSchoolsRequest)
                return object;
            var message = new $root.school_service.GetPagedSchoolsRequest();
            if (object.districtId != null)
                message.districtId = object.districtId | 0;
            if (object.page != null)
                message.page = object.page | 0;
            if (object.pageSize != null)
                message.pageSize = object.pageSize | 0;
            if (object.searchText != null)
                message.searchText = String(object.searchText);
            return message;
        };

        /**
         * Creates a plain object from a GetPagedSchoolsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof school_service.GetPagedSchoolsRequest
         * @static
         * @param {school_service.GetPagedSchoolsRequest} message GetPagedSchoolsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetPagedSchoolsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.districtId != null && message.hasOwnProperty("districtId")) {
                object.districtId = message.districtId;
                if (options.oneofs)
                    object._districtId = "districtId";
            }
            if (message.page != null && message.hasOwnProperty("page")) {
                object.page = message.page;
                if (options.oneofs)
                    object._page = "page";
            }
            if (message.pageSize != null && message.hasOwnProperty("pageSize")) {
                object.pageSize = message.pageSize;
                if (options.oneofs)
                    object._pageSize = "pageSize";
            }
            if (message.searchText != null && message.hasOwnProperty("searchText")) {
                object.searchText = message.searchText;
                if (options.oneofs)
                    object._searchText = "searchText";
            }
            return object;
        };

        /**
         * Converts this GetPagedSchoolsRequest to JSON.
         * @function toJSON
         * @memberof school_service.GetPagedSchoolsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetPagedSchoolsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetPagedSchoolsRequest
         * @function getTypeUrl
         * @memberof school_service.GetPagedSchoolsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetPagedSchoolsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/school_service.GetPagedSchoolsRequest";
        };

        return GetPagedSchoolsRequest;
    })();

    school_service.GetPagedSchoolsResponse = (function() {

        /**
         * Properties of a GetPagedSchoolsResponse.
         * @memberof school_service
         * @interface IGetPagedSchoolsResponse
         * @property {Array.<pl_types.ISchool>|null} [schools] GetPagedSchoolsResponse schools
         * @property {number|null} [totalSchools] GetPagedSchoolsResponse totalSchools
         */

        /**
         * Constructs a new GetPagedSchoolsResponse.
         * @memberof school_service
         * @classdesc Represents a GetPagedSchoolsResponse.
         * @implements IGetPagedSchoolsResponse
         * @constructor
         * @param {school_service.IGetPagedSchoolsResponse=} [properties] Properties to set
         */
        function GetPagedSchoolsResponse(properties) {
            this.schools = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetPagedSchoolsResponse schools.
         * @member {Array.<pl_types.ISchool>} schools
         * @memberof school_service.GetPagedSchoolsResponse
         * @instance
         */
        GetPagedSchoolsResponse.prototype.schools = $util.emptyArray;

        /**
         * GetPagedSchoolsResponse totalSchools.
         * @member {number|null|undefined} totalSchools
         * @memberof school_service.GetPagedSchoolsResponse
         * @instance
         */
        GetPagedSchoolsResponse.prototype.totalSchools = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GetPagedSchoolsResponse _totalSchools.
         * @member {"totalSchools"|undefined} _totalSchools
         * @memberof school_service.GetPagedSchoolsResponse
         * @instance
         */
        Object.defineProperty(GetPagedSchoolsResponse.prototype, "_totalSchools", {
            get: $util.oneOfGetter($oneOfFields = ["totalSchools"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetPagedSchoolsResponse instance using the specified properties.
         * @function create
         * @memberof school_service.GetPagedSchoolsResponse
         * @static
         * @param {school_service.IGetPagedSchoolsResponse=} [properties] Properties to set
         * @returns {school_service.GetPagedSchoolsResponse} GetPagedSchoolsResponse instance
         */
        GetPagedSchoolsResponse.create = function create(properties) {
            return new GetPagedSchoolsResponse(properties);
        };

        /**
         * Encodes the specified GetPagedSchoolsResponse message. Does not implicitly {@link school_service.GetPagedSchoolsResponse.verify|verify} messages.
         * @function encode
         * @memberof school_service.GetPagedSchoolsResponse
         * @static
         * @param {school_service.IGetPagedSchoolsResponse} message GetPagedSchoolsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPagedSchoolsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.schools != null && message.schools.length)
                for (var i = 0; i < message.schools.length; ++i)
                    $root.pl_types.School.encode(message.schools[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.totalSchools != null && Object.hasOwnProperty.call(message, "totalSchools"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalSchools);
            return writer;
        };

        /**
         * Encodes the specified GetPagedSchoolsResponse message, length delimited. Does not implicitly {@link school_service.GetPagedSchoolsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof school_service.GetPagedSchoolsResponse
         * @static
         * @param {school_service.IGetPagedSchoolsResponse} message GetPagedSchoolsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPagedSchoolsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetPagedSchoolsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof school_service.GetPagedSchoolsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {school_service.GetPagedSchoolsResponse} GetPagedSchoolsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPagedSchoolsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.school_service.GetPagedSchoolsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.schools && message.schools.length))
                            message.schools = [];
                        message.schools.push($root.pl_types.School.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.totalSchools = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetPagedSchoolsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof school_service.GetPagedSchoolsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {school_service.GetPagedSchoolsResponse} GetPagedSchoolsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPagedSchoolsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetPagedSchoolsResponse message.
         * @function verify
         * @memberof school_service.GetPagedSchoolsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetPagedSchoolsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.schools != null && message.hasOwnProperty("schools")) {
                if (!Array.isArray(message.schools))
                    return "schools: array expected";
                for (var i = 0; i < message.schools.length; ++i) {
                    var error = $root.pl_types.School.verify(message.schools[i]);
                    if (error)
                        return "schools." + error;
                }
            }
            if (message.totalSchools != null && message.hasOwnProperty("totalSchools")) {
                properties._totalSchools = 1;
                if (!$util.isInteger(message.totalSchools))
                    return "totalSchools: integer expected";
            }
            return null;
        };

        /**
         * Creates a GetPagedSchoolsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof school_service.GetPagedSchoolsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {school_service.GetPagedSchoolsResponse} GetPagedSchoolsResponse
         */
        GetPagedSchoolsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.school_service.GetPagedSchoolsResponse)
                return object;
            var message = new $root.school_service.GetPagedSchoolsResponse();
            if (object.schools) {
                if (!Array.isArray(object.schools))
                    throw TypeError(".school_service.GetPagedSchoolsResponse.schools: array expected");
                message.schools = [];
                for (var i = 0; i < object.schools.length; ++i) {
                    if (typeof object.schools[i] !== "object")
                        throw TypeError(".school_service.GetPagedSchoolsResponse.schools: object expected");
                    message.schools[i] = $root.pl_types.School.fromObject(object.schools[i]);
                }
            }
            if (object.totalSchools != null)
                message.totalSchools = object.totalSchools | 0;
            return message;
        };

        /**
         * Creates a plain object from a GetPagedSchoolsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof school_service.GetPagedSchoolsResponse
         * @static
         * @param {school_service.GetPagedSchoolsResponse} message GetPagedSchoolsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetPagedSchoolsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.schools = [];
            if (message.schools && message.schools.length) {
                object.schools = [];
                for (var j = 0; j < message.schools.length; ++j)
                    object.schools[j] = $root.pl_types.School.toObject(message.schools[j], options);
            }
            if (message.totalSchools != null && message.hasOwnProperty("totalSchools")) {
                object.totalSchools = message.totalSchools;
                if (options.oneofs)
                    object._totalSchools = "totalSchools";
            }
            return object;
        };

        /**
         * Converts this GetPagedSchoolsResponse to JSON.
         * @function toJSON
         * @memberof school_service.GetPagedSchoolsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetPagedSchoolsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetPagedSchoolsResponse
         * @function getTypeUrl
         * @memberof school_service.GetPagedSchoolsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetPagedSchoolsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/school_service.GetPagedSchoolsResponse";
        };

        return GetPagedSchoolsResponse;
    })();

    school_service.UpsertSchoolRequest = (function() {

        /**
         * Properties of an UpsertSchoolRequest.
         * @memberof school_service
         * @interface IUpsertSchoolRequest
         * @property {pl_types.ISchool|null} [school] UpsertSchoolRequest school
         */

        /**
         * Constructs a new UpsertSchoolRequest.
         * @memberof school_service
         * @classdesc Represents an UpsertSchoolRequest.
         * @implements IUpsertSchoolRequest
         * @constructor
         * @param {school_service.IUpsertSchoolRequest=} [properties] Properties to set
         */
        function UpsertSchoolRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertSchoolRequest school.
         * @member {pl_types.ISchool|null|undefined} school
         * @memberof school_service.UpsertSchoolRequest
         * @instance
         */
        UpsertSchoolRequest.prototype.school = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UpsertSchoolRequest _school.
         * @member {"school"|undefined} _school
         * @memberof school_service.UpsertSchoolRequest
         * @instance
         */
        Object.defineProperty(UpsertSchoolRequest.prototype, "_school", {
            get: $util.oneOfGetter($oneOfFields = ["school"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UpsertSchoolRequest instance using the specified properties.
         * @function create
         * @memberof school_service.UpsertSchoolRequest
         * @static
         * @param {school_service.IUpsertSchoolRequest=} [properties] Properties to set
         * @returns {school_service.UpsertSchoolRequest} UpsertSchoolRequest instance
         */
        UpsertSchoolRequest.create = function create(properties) {
            return new UpsertSchoolRequest(properties);
        };

        /**
         * Encodes the specified UpsertSchoolRequest message. Does not implicitly {@link school_service.UpsertSchoolRequest.verify|verify} messages.
         * @function encode
         * @memberof school_service.UpsertSchoolRequest
         * @static
         * @param {school_service.IUpsertSchoolRequest} message UpsertSchoolRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertSchoolRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.school != null && Object.hasOwnProperty.call(message, "school"))
                $root.pl_types.School.encode(message.school, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpsertSchoolRequest message, length delimited. Does not implicitly {@link school_service.UpsertSchoolRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof school_service.UpsertSchoolRequest
         * @static
         * @param {school_service.IUpsertSchoolRequest} message UpsertSchoolRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertSchoolRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpsertSchoolRequest message from the specified reader or buffer.
         * @function decode
         * @memberof school_service.UpsertSchoolRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {school_service.UpsertSchoolRequest} UpsertSchoolRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertSchoolRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.school_service.UpsertSchoolRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.school = $root.pl_types.School.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpsertSchoolRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof school_service.UpsertSchoolRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {school_service.UpsertSchoolRequest} UpsertSchoolRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertSchoolRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpsertSchoolRequest message.
         * @function verify
         * @memberof school_service.UpsertSchoolRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpsertSchoolRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.school != null && message.hasOwnProperty("school")) {
                properties._school = 1;
                {
                    var error = $root.pl_types.School.verify(message.school);
                    if (error)
                        return "school." + error;
                }
            }
            return null;
        };

        /**
         * Creates an UpsertSchoolRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof school_service.UpsertSchoolRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {school_service.UpsertSchoolRequest} UpsertSchoolRequest
         */
        UpsertSchoolRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.school_service.UpsertSchoolRequest)
                return object;
            var message = new $root.school_service.UpsertSchoolRequest();
            if (object.school != null) {
                if (typeof object.school !== "object")
                    throw TypeError(".school_service.UpsertSchoolRequest.school: object expected");
                message.school = $root.pl_types.School.fromObject(object.school);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpsertSchoolRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof school_service.UpsertSchoolRequest
         * @static
         * @param {school_service.UpsertSchoolRequest} message UpsertSchoolRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpsertSchoolRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.school != null && message.hasOwnProperty("school")) {
                object.school = $root.pl_types.School.toObject(message.school, options);
                if (options.oneofs)
                    object._school = "school";
            }
            return object;
        };

        /**
         * Converts this UpsertSchoolRequest to JSON.
         * @function toJSON
         * @memberof school_service.UpsertSchoolRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpsertSchoolRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpsertSchoolRequest
         * @function getTypeUrl
         * @memberof school_service.UpsertSchoolRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpsertSchoolRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/school_service.UpsertSchoolRequest";
        };

        return UpsertSchoolRequest;
    })();

    school_service.RemoveSchoolRequest = (function() {

        /**
         * Properties of a RemoveSchoolRequest.
         * @memberof school_service
         * @interface IRemoveSchoolRequest
         * @property {number|null} [districtId] RemoveSchoolRequest districtId
         * @property {number|null} [schoolId] RemoveSchoolRequest schoolId
         */

        /**
         * Constructs a new RemoveSchoolRequest.
         * @memberof school_service
         * @classdesc Represents a RemoveSchoolRequest.
         * @implements IRemoveSchoolRequest
         * @constructor
         * @param {school_service.IRemoveSchoolRequest=} [properties] Properties to set
         */
        function RemoveSchoolRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RemoveSchoolRequest districtId.
         * @member {number|null|undefined} districtId
         * @memberof school_service.RemoveSchoolRequest
         * @instance
         */
        RemoveSchoolRequest.prototype.districtId = null;

        /**
         * RemoveSchoolRequest schoolId.
         * @member {number|null|undefined} schoolId
         * @memberof school_service.RemoveSchoolRequest
         * @instance
         */
        RemoveSchoolRequest.prototype.schoolId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * RemoveSchoolRequest _districtId.
         * @member {"districtId"|undefined} _districtId
         * @memberof school_service.RemoveSchoolRequest
         * @instance
         */
        Object.defineProperty(RemoveSchoolRequest.prototype, "_districtId", {
            get: $util.oneOfGetter($oneOfFields = ["districtId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * RemoveSchoolRequest _schoolId.
         * @member {"schoolId"|undefined} _schoolId
         * @memberof school_service.RemoveSchoolRequest
         * @instance
         */
        Object.defineProperty(RemoveSchoolRequest.prototype, "_schoolId", {
            get: $util.oneOfGetter($oneOfFields = ["schoolId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new RemoveSchoolRequest instance using the specified properties.
         * @function create
         * @memberof school_service.RemoveSchoolRequest
         * @static
         * @param {school_service.IRemoveSchoolRequest=} [properties] Properties to set
         * @returns {school_service.RemoveSchoolRequest} RemoveSchoolRequest instance
         */
        RemoveSchoolRequest.create = function create(properties) {
            return new RemoveSchoolRequest(properties);
        };

        /**
         * Encodes the specified RemoveSchoolRequest message. Does not implicitly {@link school_service.RemoveSchoolRequest.verify|verify} messages.
         * @function encode
         * @memberof school_service.RemoveSchoolRequest
         * @static
         * @param {school_service.IRemoveSchoolRequest} message RemoveSchoolRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveSchoolRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.districtId != null && Object.hasOwnProperty.call(message, "districtId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.districtId);
            if (message.schoolId != null && Object.hasOwnProperty.call(message, "schoolId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.schoolId);
            return writer;
        };

        /**
         * Encodes the specified RemoveSchoolRequest message, length delimited. Does not implicitly {@link school_service.RemoveSchoolRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof school_service.RemoveSchoolRequest
         * @static
         * @param {school_service.IRemoveSchoolRequest} message RemoveSchoolRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveSchoolRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RemoveSchoolRequest message from the specified reader or buffer.
         * @function decode
         * @memberof school_service.RemoveSchoolRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {school_service.RemoveSchoolRequest} RemoveSchoolRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveSchoolRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.school_service.RemoveSchoolRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.districtId = reader.int32();
                        break;
                    }
                case 2: {
                        message.schoolId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RemoveSchoolRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof school_service.RemoveSchoolRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {school_service.RemoveSchoolRequest} RemoveSchoolRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveSchoolRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RemoveSchoolRequest message.
         * @function verify
         * @memberof school_service.RemoveSchoolRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RemoveSchoolRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.districtId != null && message.hasOwnProperty("districtId")) {
                properties._districtId = 1;
                if (!$util.isInteger(message.districtId))
                    return "districtId: integer expected";
            }
            if (message.schoolId != null && message.hasOwnProperty("schoolId")) {
                properties._schoolId = 1;
                if (!$util.isInteger(message.schoolId))
                    return "schoolId: integer expected";
            }
            return null;
        };

        /**
         * Creates a RemoveSchoolRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof school_service.RemoveSchoolRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {school_service.RemoveSchoolRequest} RemoveSchoolRequest
         */
        RemoveSchoolRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.school_service.RemoveSchoolRequest)
                return object;
            var message = new $root.school_service.RemoveSchoolRequest();
            if (object.districtId != null)
                message.districtId = object.districtId | 0;
            if (object.schoolId != null)
                message.schoolId = object.schoolId | 0;
            return message;
        };

        /**
         * Creates a plain object from a RemoveSchoolRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof school_service.RemoveSchoolRequest
         * @static
         * @param {school_service.RemoveSchoolRequest} message RemoveSchoolRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RemoveSchoolRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.districtId != null && message.hasOwnProperty("districtId")) {
                object.districtId = message.districtId;
                if (options.oneofs)
                    object._districtId = "districtId";
            }
            if (message.schoolId != null && message.hasOwnProperty("schoolId")) {
                object.schoolId = message.schoolId;
                if (options.oneofs)
                    object._schoolId = "schoolId";
            }
            return object;
        };

        /**
         * Converts this RemoveSchoolRequest to JSON.
         * @function toJSON
         * @memberof school_service.RemoveSchoolRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RemoveSchoolRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RemoveSchoolRequest
         * @function getTypeUrl
         * @memberof school_service.RemoveSchoolRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RemoveSchoolRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/school_service.RemoveSchoolRequest";
        };

        return RemoveSchoolRequest;
    })();

    school_service.SchoolInformationResponse = (function() {

        /**
         * Properties of a SchoolInformationResponse.
         * @memberof school_service
         * @interface ISchoolInformationResponse
         * @property {number|null} [districtId] SchoolInformationResponse districtId
         * @property {number|null} [nextSchoolId] SchoolInformationResponse nextSchoolId
         * @property {Array.<pl_types.ISchool>|null} [schools] SchoolInformationResponse schools
         */

        /**
         * Constructs a new SchoolInformationResponse.
         * @memberof school_service
         * @classdesc Represents a SchoolInformationResponse.
         * @implements ISchoolInformationResponse
         * @constructor
         * @param {school_service.ISchoolInformationResponse=} [properties] Properties to set
         */
        function SchoolInformationResponse(properties) {
            this.schools = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SchoolInformationResponse districtId.
         * @member {number|null|undefined} districtId
         * @memberof school_service.SchoolInformationResponse
         * @instance
         */
        SchoolInformationResponse.prototype.districtId = null;

        /**
         * SchoolInformationResponse nextSchoolId.
         * @member {number|null|undefined} nextSchoolId
         * @memberof school_service.SchoolInformationResponse
         * @instance
         */
        SchoolInformationResponse.prototype.nextSchoolId = null;

        /**
         * SchoolInformationResponse schools.
         * @member {Array.<pl_types.ISchool>} schools
         * @memberof school_service.SchoolInformationResponse
         * @instance
         */
        SchoolInformationResponse.prototype.schools = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * SchoolInformationResponse _districtId.
         * @member {"districtId"|undefined} _districtId
         * @memberof school_service.SchoolInformationResponse
         * @instance
         */
        Object.defineProperty(SchoolInformationResponse.prototype, "_districtId", {
            get: $util.oneOfGetter($oneOfFields = ["districtId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SchoolInformationResponse _nextSchoolId.
         * @member {"nextSchoolId"|undefined} _nextSchoolId
         * @memberof school_service.SchoolInformationResponse
         * @instance
         */
        Object.defineProperty(SchoolInformationResponse.prototype, "_nextSchoolId", {
            get: $util.oneOfGetter($oneOfFields = ["nextSchoolId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SchoolInformationResponse instance using the specified properties.
         * @function create
         * @memberof school_service.SchoolInformationResponse
         * @static
         * @param {school_service.ISchoolInformationResponse=} [properties] Properties to set
         * @returns {school_service.SchoolInformationResponse} SchoolInformationResponse instance
         */
        SchoolInformationResponse.create = function create(properties) {
            return new SchoolInformationResponse(properties);
        };

        /**
         * Encodes the specified SchoolInformationResponse message. Does not implicitly {@link school_service.SchoolInformationResponse.verify|verify} messages.
         * @function encode
         * @memberof school_service.SchoolInformationResponse
         * @static
         * @param {school_service.ISchoolInformationResponse} message SchoolInformationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SchoolInformationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.districtId != null && Object.hasOwnProperty.call(message, "districtId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.districtId);
            if (message.nextSchoolId != null && Object.hasOwnProperty.call(message, "nextSchoolId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nextSchoolId);
            if (message.schools != null && message.schools.length)
                for (var i = 0; i < message.schools.length; ++i)
                    $root.pl_types.School.encode(message.schools[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SchoolInformationResponse message, length delimited. Does not implicitly {@link school_service.SchoolInformationResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof school_service.SchoolInformationResponse
         * @static
         * @param {school_service.ISchoolInformationResponse} message SchoolInformationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SchoolInformationResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SchoolInformationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof school_service.SchoolInformationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {school_service.SchoolInformationResponse} SchoolInformationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SchoolInformationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.school_service.SchoolInformationResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.districtId = reader.int32();
                        break;
                    }
                case 2: {
                        message.nextSchoolId = reader.int32();
                        break;
                    }
                case 3: {
                        if (!(message.schools && message.schools.length))
                            message.schools = [];
                        message.schools.push($root.pl_types.School.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SchoolInformationResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof school_service.SchoolInformationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {school_service.SchoolInformationResponse} SchoolInformationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SchoolInformationResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SchoolInformationResponse message.
         * @function verify
         * @memberof school_service.SchoolInformationResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SchoolInformationResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.districtId != null && message.hasOwnProperty("districtId")) {
                properties._districtId = 1;
                if (!$util.isInteger(message.districtId))
                    return "districtId: integer expected";
            }
            if (message.nextSchoolId != null && message.hasOwnProperty("nextSchoolId")) {
                properties._nextSchoolId = 1;
                if (!$util.isInteger(message.nextSchoolId))
                    return "nextSchoolId: integer expected";
            }
            if (message.schools != null && message.hasOwnProperty("schools")) {
                if (!Array.isArray(message.schools))
                    return "schools: array expected";
                for (var i = 0; i < message.schools.length; ++i) {
                    var error = $root.pl_types.School.verify(message.schools[i]);
                    if (error)
                        return "schools." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SchoolInformationResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof school_service.SchoolInformationResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {school_service.SchoolInformationResponse} SchoolInformationResponse
         */
        SchoolInformationResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.school_service.SchoolInformationResponse)
                return object;
            var message = new $root.school_service.SchoolInformationResponse();
            if (object.districtId != null)
                message.districtId = object.districtId | 0;
            if (object.nextSchoolId != null)
                message.nextSchoolId = object.nextSchoolId | 0;
            if (object.schools) {
                if (!Array.isArray(object.schools))
                    throw TypeError(".school_service.SchoolInformationResponse.schools: array expected");
                message.schools = [];
                for (var i = 0; i < object.schools.length; ++i) {
                    if (typeof object.schools[i] !== "object")
                        throw TypeError(".school_service.SchoolInformationResponse.schools: object expected");
                    message.schools[i] = $root.pl_types.School.fromObject(object.schools[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a SchoolInformationResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof school_service.SchoolInformationResponse
         * @static
         * @param {school_service.SchoolInformationResponse} message SchoolInformationResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SchoolInformationResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.schools = [];
            if (message.districtId != null && message.hasOwnProperty("districtId")) {
                object.districtId = message.districtId;
                if (options.oneofs)
                    object._districtId = "districtId";
            }
            if (message.nextSchoolId != null && message.hasOwnProperty("nextSchoolId")) {
                object.nextSchoolId = message.nextSchoolId;
                if (options.oneofs)
                    object._nextSchoolId = "nextSchoolId";
            }
            if (message.schools && message.schools.length) {
                object.schools = [];
                for (var j = 0; j < message.schools.length; ++j)
                    object.schools[j] = $root.pl_types.School.toObject(message.schools[j], options);
            }
            return object;
        };

        /**
         * Converts this SchoolInformationResponse to JSON.
         * @function toJSON
         * @memberof school_service.SchoolInformationResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SchoolInformationResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SchoolInformationResponse
         * @function getTypeUrl
         * @memberof school_service.SchoolInformationResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SchoolInformationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/school_service.SchoolInformationResponse";
        };

        return SchoolInformationResponse;
    })();

    return school_service;
})();

$root.tag_service = (function() {

    /**
     * Namespace tag_service.
     * @exports tag_service
     * @namespace
     */
    var tag_service = {};

    tag_service.TagService = (function() {

        /**
         * Constructs a new TagService service.
         * @memberof tag_service
         * @classdesc Represents a TagService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function TagService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (TagService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = TagService;

        /**
         * Creates new TagService service using the specified rpc implementation.
         * @function create
         * @memberof tag_service.TagService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {TagService} RPC service. Useful where requests and/or responses are streamed.
         */
        TagService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link tag_service.TagService#getAllPreviousTags}.
         * @memberof tag_service.TagService
         * @typedef GetAllPreviousTagsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {tag_service.GetAllPreviousTagsResponse} [response] GetAllPreviousTagsResponse
         */

        /**
         * Calls GetAllPreviousTags.
         * @function getAllPreviousTags
         * @memberof tag_service.TagService
         * @instance
         * @param {tag_service.IGetAllPreviousTagsRequest} request GetAllPreviousTagsRequest message or plain object
         * @param {tag_service.TagService.GetAllPreviousTagsCallback} callback Node-style callback called with the error, if any, and GetAllPreviousTagsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TagService.prototype.getAllPreviousTags = function getAllPreviousTags(request, callback) {
            return this.rpcCall(getAllPreviousTags, $root.tag_service.GetAllPreviousTagsRequest, $root.tag_service.GetAllPreviousTagsResponse, request, callback);
        }, "name", { value: "GetAllPreviousTags" });

        /**
         * Calls GetAllPreviousTags.
         * @function getAllPreviousTags
         * @memberof tag_service.TagService
         * @instance
         * @param {tag_service.IGetAllPreviousTagsRequest} request GetAllPreviousTagsRequest message or plain object
         * @returns {Promise<tag_service.GetAllPreviousTagsResponse>} Promise
         * @variation 2
         */

        return TagService;
    })();

    tag_service.GetAllPreviousTagsRequest = (function() {

        /**
         * Properties of a GetAllPreviousTagsRequest.
         * @memberof tag_service
         * @interface IGetAllPreviousTagsRequest
         * @property {number|null} [userXId] GetAllPreviousTagsRequest userXId
         */

        /**
         * Constructs a new GetAllPreviousTagsRequest.
         * @memberof tag_service
         * @classdesc Represents a GetAllPreviousTagsRequest.
         * @implements IGetAllPreviousTagsRequest
         * @constructor
         * @param {tag_service.IGetAllPreviousTagsRequest=} [properties] Properties to set
         */
        function GetAllPreviousTagsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAllPreviousTagsRequest userXId.
         * @member {number|null|undefined} userXId
         * @memberof tag_service.GetAllPreviousTagsRequest
         * @instance
         */
        GetAllPreviousTagsRequest.prototype.userXId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GetAllPreviousTagsRequest _userXId.
         * @member {"userXId"|undefined} _userXId
         * @memberof tag_service.GetAllPreviousTagsRequest
         * @instance
         */
        Object.defineProperty(GetAllPreviousTagsRequest.prototype, "_userXId", {
            get: $util.oneOfGetter($oneOfFields = ["userXId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetAllPreviousTagsRequest instance using the specified properties.
         * @function create
         * @memberof tag_service.GetAllPreviousTagsRequest
         * @static
         * @param {tag_service.IGetAllPreviousTagsRequest=} [properties] Properties to set
         * @returns {tag_service.GetAllPreviousTagsRequest} GetAllPreviousTagsRequest instance
         */
        GetAllPreviousTagsRequest.create = function create(properties) {
            return new GetAllPreviousTagsRequest(properties);
        };

        /**
         * Encodes the specified GetAllPreviousTagsRequest message. Does not implicitly {@link tag_service.GetAllPreviousTagsRequest.verify|verify} messages.
         * @function encode
         * @memberof tag_service.GetAllPreviousTagsRequest
         * @static
         * @param {tag_service.IGetAllPreviousTagsRequest} message GetAllPreviousTagsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAllPreviousTagsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userXId != null && Object.hasOwnProperty.call(message, "userXId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.userXId);
            return writer;
        };

        /**
         * Encodes the specified GetAllPreviousTagsRequest message, length delimited. Does not implicitly {@link tag_service.GetAllPreviousTagsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tag_service.GetAllPreviousTagsRequest
         * @static
         * @param {tag_service.IGetAllPreviousTagsRequest} message GetAllPreviousTagsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAllPreviousTagsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetAllPreviousTagsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof tag_service.GetAllPreviousTagsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tag_service.GetAllPreviousTagsRequest} GetAllPreviousTagsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAllPreviousTagsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tag_service.GetAllPreviousTagsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.userXId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetAllPreviousTagsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tag_service.GetAllPreviousTagsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tag_service.GetAllPreviousTagsRequest} GetAllPreviousTagsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAllPreviousTagsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetAllPreviousTagsRequest message.
         * @function verify
         * @memberof tag_service.GetAllPreviousTagsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetAllPreviousTagsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.userXId != null && message.hasOwnProperty("userXId")) {
                properties._userXId = 1;
                if (!$util.isInteger(message.userXId))
                    return "userXId: integer expected";
            }
            return null;
        };

        /**
         * Creates a GetAllPreviousTagsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tag_service.GetAllPreviousTagsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tag_service.GetAllPreviousTagsRequest} GetAllPreviousTagsRequest
         */
        GetAllPreviousTagsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.tag_service.GetAllPreviousTagsRequest)
                return object;
            var message = new $root.tag_service.GetAllPreviousTagsRequest();
            if (object.userXId != null)
                message.userXId = object.userXId | 0;
            return message;
        };

        /**
         * Creates a plain object from a GetAllPreviousTagsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tag_service.GetAllPreviousTagsRequest
         * @static
         * @param {tag_service.GetAllPreviousTagsRequest} message GetAllPreviousTagsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetAllPreviousTagsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.userXId != null && message.hasOwnProperty("userXId")) {
                object.userXId = message.userXId;
                if (options.oneofs)
                    object._userXId = "userXId";
            }
            return object;
        };

        /**
         * Converts this GetAllPreviousTagsRequest to JSON.
         * @function toJSON
         * @memberof tag_service.GetAllPreviousTagsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetAllPreviousTagsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetAllPreviousTagsRequest
         * @function getTypeUrl
         * @memberof tag_service.GetAllPreviousTagsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetAllPreviousTagsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tag_service.GetAllPreviousTagsRequest";
        };

        return GetAllPreviousTagsRequest;
    })();

    tag_service.GetAllPreviousTagsResponse = (function() {

        /**
         * Properties of a GetAllPreviousTagsResponse.
         * @memberof tag_service
         * @interface IGetAllPreviousTagsResponse
         * @property {Array.<pl_types.ITag>|null} [tags] GetAllPreviousTagsResponse tags
         */

        /**
         * Constructs a new GetAllPreviousTagsResponse.
         * @memberof tag_service
         * @classdesc Represents a GetAllPreviousTagsResponse.
         * @implements IGetAllPreviousTagsResponse
         * @constructor
         * @param {tag_service.IGetAllPreviousTagsResponse=} [properties] Properties to set
         */
        function GetAllPreviousTagsResponse(properties) {
            this.tags = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAllPreviousTagsResponse tags.
         * @member {Array.<pl_types.ITag>} tags
         * @memberof tag_service.GetAllPreviousTagsResponse
         * @instance
         */
        GetAllPreviousTagsResponse.prototype.tags = $util.emptyArray;

        /**
         * Creates a new GetAllPreviousTagsResponse instance using the specified properties.
         * @function create
         * @memberof tag_service.GetAllPreviousTagsResponse
         * @static
         * @param {tag_service.IGetAllPreviousTagsResponse=} [properties] Properties to set
         * @returns {tag_service.GetAllPreviousTagsResponse} GetAllPreviousTagsResponse instance
         */
        GetAllPreviousTagsResponse.create = function create(properties) {
            return new GetAllPreviousTagsResponse(properties);
        };

        /**
         * Encodes the specified GetAllPreviousTagsResponse message. Does not implicitly {@link tag_service.GetAllPreviousTagsResponse.verify|verify} messages.
         * @function encode
         * @memberof tag_service.GetAllPreviousTagsResponse
         * @static
         * @param {tag_service.IGetAllPreviousTagsResponse} message GetAllPreviousTagsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAllPreviousTagsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tags != null && message.tags.length)
                for (var i = 0; i < message.tags.length; ++i)
                    $root.pl_types.Tag.encode(message.tags[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetAllPreviousTagsResponse message, length delimited. Does not implicitly {@link tag_service.GetAllPreviousTagsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tag_service.GetAllPreviousTagsResponse
         * @static
         * @param {tag_service.IGetAllPreviousTagsResponse} message GetAllPreviousTagsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAllPreviousTagsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetAllPreviousTagsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof tag_service.GetAllPreviousTagsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tag_service.GetAllPreviousTagsResponse} GetAllPreviousTagsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAllPreviousTagsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tag_service.GetAllPreviousTagsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.tags && message.tags.length))
                            message.tags = [];
                        message.tags.push($root.pl_types.Tag.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetAllPreviousTagsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tag_service.GetAllPreviousTagsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tag_service.GetAllPreviousTagsResponse} GetAllPreviousTagsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAllPreviousTagsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetAllPreviousTagsResponse message.
         * @function verify
         * @memberof tag_service.GetAllPreviousTagsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetAllPreviousTagsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tags != null && message.hasOwnProperty("tags")) {
                if (!Array.isArray(message.tags))
                    return "tags: array expected";
                for (var i = 0; i < message.tags.length; ++i) {
                    var error = $root.pl_types.Tag.verify(message.tags[i]);
                    if (error)
                        return "tags." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetAllPreviousTagsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tag_service.GetAllPreviousTagsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tag_service.GetAllPreviousTagsResponse} GetAllPreviousTagsResponse
         */
        GetAllPreviousTagsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.tag_service.GetAllPreviousTagsResponse)
                return object;
            var message = new $root.tag_service.GetAllPreviousTagsResponse();
            if (object.tags) {
                if (!Array.isArray(object.tags))
                    throw TypeError(".tag_service.GetAllPreviousTagsResponse.tags: array expected");
                message.tags = [];
                for (var i = 0; i < object.tags.length; ++i) {
                    if (typeof object.tags[i] !== "object")
                        throw TypeError(".tag_service.GetAllPreviousTagsResponse.tags: object expected");
                    message.tags[i] = $root.pl_types.Tag.fromObject(object.tags[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetAllPreviousTagsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tag_service.GetAllPreviousTagsResponse
         * @static
         * @param {tag_service.GetAllPreviousTagsResponse} message GetAllPreviousTagsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetAllPreviousTagsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.tags = [];
            if (message.tags && message.tags.length) {
                object.tags = [];
                for (var j = 0; j < message.tags.length; ++j)
                    object.tags[j] = $root.pl_types.Tag.toObject(message.tags[j], options);
            }
            return object;
        };

        /**
         * Converts this GetAllPreviousTagsResponse to JSON.
         * @function toJSON
         * @memberof tag_service.GetAllPreviousTagsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetAllPreviousTagsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetAllPreviousTagsResponse
         * @function getTypeUrl
         * @memberof tag_service.GetAllPreviousTagsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetAllPreviousTagsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tag_service.GetAllPreviousTagsResponse";
        };

        return GetAllPreviousTagsResponse;
    })();

    return tag_service;
})();

$root.task_service = (function() {

    /**
     * Namespace task_service.
     * @exports task_service
     * @namespace
     */
    var task_service = {};

    task_service.TaskService = (function() {

        /**
         * Constructs a new TaskService service.
         * @memberof task_service
         * @classdesc Represents a TaskService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function TaskService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (TaskService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = TaskService;

        /**
         * Creates new TaskService service using the specified rpc implementation.
         * @function create
         * @memberof task_service.TaskService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {TaskService} RPC service. Useful where requests and/or responses are streamed.
         */
        TaskService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link task_service.TaskService#getTaskQueuesStatus}.
         * @memberof task_service.TaskService
         * @typedef GetTaskQueuesStatusCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {task_service.GetTaskQueuesStatusResponse} [response] GetTaskQueuesStatusResponse
         */

        /**
         * Calls GetTaskQueuesStatus.
         * @function getTaskQueuesStatus
         * @memberof task_service.TaskService
         * @instance
         * @param {task_service.IGetTaskQueuesStatusRequest} request GetTaskQueuesStatusRequest message or plain object
         * @param {task_service.TaskService.GetTaskQueuesStatusCallback} callback Node-style callback called with the error, if any, and GetTaskQueuesStatusResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TaskService.prototype.getTaskQueuesStatus = function getTaskQueuesStatus(request, callback) {
            return this.rpcCall(getTaskQueuesStatus, $root.task_service.GetTaskQueuesStatusRequest, $root.task_service.GetTaskQueuesStatusResponse, request, callback);
        }, "name", { value: "GetTaskQueuesStatus" });

        /**
         * Calls GetTaskQueuesStatus.
         * @function getTaskQueuesStatus
         * @memberof task_service.TaskService
         * @instance
         * @param {task_service.IGetTaskQueuesStatusRequest} request GetTaskQueuesStatusRequest message or plain object
         * @returns {Promise<task_service.GetTaskQueuesStatusResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link task_service.TaskService#scanForTasks}.
         * @memberof task_service.TaskService
         * @typedef ScanForTasksCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {task_service.ScanForTasksResponse} [response] ScanForTasksResponse
         */

        /**
         * Calls ScanForTasks.
         * @function scanForTasks
         * @memberof task_service.TaskService
         * @instance
         * @param {task_service.IScanForTasksRequest} request ScanForTasksRequest message or plain object
         * @param {task_service.TaskService.ScanForTasksCallback} callback Node-style callback called with the error, if any, and ScanForTasksResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TaskService.prototype.scanForTasks = function scanForTasks(request, callback) {
            return this.rpcCall(scanForTasks, $root.task_service.ScanForTasksRequest, $root.task_service.ScanForTasksResponse, request, callback);
        }, "name", { value: "ScanForTasks" });

        /**
         * Calls ScanForTasks.
         * @function scanForTasks
         * @memberof task_service.TaskService
         * @instance
         * @param {task_service.IScanForTasksRequest} request ScanForTasksRequest message or plain object
         * @returns {Promise<task_service.ScanForTasksResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link task_service.TaskService#resetTaskQueues}.
         * @memberof task_service.TaskService
         * @typedef ResetTaskQueuesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {task_service.ResetTaskQueuesResponse} [response] ResetTaskQueuesResponse
         */

        /**
         * Calls ResetTaskQueues.
         * @function resetTaskQueues
         * @memberof task_service.TaskService
         * @instance
         * @param {task_service.IResetTaskQueuesRequest} request ResetTaskQueuesRequest message or plain object
         * @param {task_service.TaskService.ResetTaskQueuesCallback} callback Node-style callback called with the error, if any, and ResetTaskQueuesResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TaskService.prototype.resetTaskQueues = function resetTaskQueues(request, callback) {
            return this.rpcCall(resetTaskQueues, $root.task_service.ResetTaskQueuesRequest, $root.task_service.ResetTaskQueuesResponse, request, callback);
        }, "name", { value: "ResetTaskQueues" });

        /**
         * Calls ResetTaskQueues.
         * @function resetTaskQueues
         * @memberof task_service.TaskService
         * @instance
         * @param {task_service.IResetTaskQueuesRequest} request ResetTaskQueuesRequest message or plain object
         * @returns {Promise<task_service.ResetTaskQueuesResponse>} Promise
         * @variation 2
         */

        return TaskService;
    })();

    task_service.GetTaskQueuesStatusRequest = (function() {

        /**
         * Properties of a GetTaskQueuesStatusRequest.
         * @memberof task_service
         * @interface IGetTaskQueuesStatusRequest
         */

        /**
         * Constructs a new GetTaskQueuesStatusRequest.
         * @memberof task_service
         * @classdesc Represents a GetTaskQueuesStatusRequest.
         * @implements IGetTaskQueuesStatusRequest
         * @constructor
         * @param {task_service.IGetTaskQueuesStatusRequest=} [properties] Properties to set
         */
        function GetTaskQueuesStatusRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetTaskQueuesStatusRequest instance using the specified properties.
         * @function create
         * @memberof task_service.GetTaskQueuesStatusRequest
         * @static
         * @param {task_service.IGetTaskQueuesStatusRequest=} [properties] Properties to set
         * @returns {task_service.GetTaskQueuesStatusRequest} GetTaskQueuesStatusRequest instance
         */
        GetTaskQueuesStatusRequest.create = function create(properties) {
            return new GetTaskQueuesStatusRequest(properties);
        };

        /**
         * Encodes the specified GetTaskQueuesStatusRequest message. Does not implicitly {@link task_service.GetTaskQueuesStatusRequest.verify|verify} messages.
         * @function encode
         * @memberof task_service.GetTaskQueuesStatusRequest
         * @static
         * @param {task_service.IGetTaskQueuesStatusRequest} message GetTaskQueuesStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTaskQueuesStatusRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetTaskQueuesStatusRequest message, length delimited. Does not implicitly {@link task_service.GetTaskQueuesStatusRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof task_service.GetTaskQueuesStatusRequest
         * @static
         * @param {task_service.IGetTaskQueuesStatusRequest} message GetTaskQueuesStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTaskQueuesStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetTaskQueuesStatusRequest message from the specified reader or buffer.
         * @function decode
         * @memberof task_service.GetTaskQueuesStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {task_service.GetTaskQueuesStatusRequest} GetTaskQueuesStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTaskQueuesStatusRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.task_service.GetTaskQueuesStatusRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetTaskQueuesStatusRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof task_service.GetTaskQueuesStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {task_service.GetTaskQueuesStatusRequest} GetTaskQueuesStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTaskQueuesStatusRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetTaskQueuesStatusRequest message.
         * @function verify
         * @memberof task_service.GetTaskQueuesStatusRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetTaskQueuesStatusRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetTaskQueuesStatusRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof task_service.GetTaskQueuesStatusRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {task_service.GetTaskQueuesStatusRequest} GetTaskQueuesStatusRequest
         */
        GetTaskQueuesStatusRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.task_service.GetTaskQueuesStatusRequest)
                return object;
            return new $root.task_service.GetTaskQueuesStatusRequest();
        };

        /**
         * Creates a plain object from a GetTaskQueuesStatusRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof task_service.GetTaskQueuesStatusRequest
         * @static
         * @param {task_service.GetTaskQueuesStatusRequest} message GetTaskQueuesStatusRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetTaskQueuesStatusRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetTaskQueuesStatusRequest to JSON.
         * @function toJSON
         * @memberof task_service.GetTaskQueuesStatusRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetTaskQueuesStatusRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetTaskQueuesStatusRequest
         * @function getTypeUrl
         * @memberof task_service.GetTaskQueuesStatusRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetTaskQueuesStatusRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/task_service.GetTaskQueuesStatusRequest";
        };

        return GetTaskQueuesStatusRequest;
    })();

    task_service.GetTaskQueuesStatusResponse = (function() {

        /**
         * Properties of a GetTaskQueuesStatusResponse.
         * @memberof task_service
         * @interface IGetTaskQueuesStatusResponse
         * @property {Array.<task_service.ITaskQueueStatus>|null} [taskQueueStatuses] GetTaskQueuesStatusResponse taskQueueStatuses
         */

        /**
         * Constructs a new GetTaskQueuesStatusResponse.
         * @memberof task_service
         * @classdesc Represents a GetTaskQueuesStatusResponse.
         * @implements IGetTaskQueuesStatusResponse
         * @constructor
         * @param {task_service.IGetTaskQueuesStatusResponse=} [properties] Properties to set
         */
        function GetTaskQueuesStatusResponse(properties) {
            this.taskQueueStatuses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetTaskQueuesStatusResponse taskQueueStatuses.
         * @member {Array.<task_service.ITaskQueueStatus>} taskQueueStatuses
         * @memberof task_service.GetTaskQueuesStatusResponse
         * @instance
         */
        GetTaskQueuesStatusResponse.prototype.taskQueueStatuses = $util.emptyArray;

        /**
         * Creates a new GetTaskQueuesStatusResponse instance using the specified properties.
         * @function create
         * @memberof task_service.GetTaskQueuesStatusResponse
         * @static
         * @param {task_service.IGetTaskQueuesStatusResponse=} [properties] Properties to set
         * @returns {task_service.GetTaskQueuesStatusResponse} GetTaskQueuesStatusResponse instance
         */
        GetTaskQueuesStatusResponse.create = function create(properties) {
            return new GetTaskQueuesStatusResponse(properties);
        };

        /**
         * Encodes the specified GetTaskQueuesStatusResponse message. Does not implicitly {@link task_service.GetTaskQueuesStatusResponse.verify|verify} messages.
         * @function encode
         * @memberof task_service.GetTaskQueuesStatusResponse
         * @static
         * @param {task_service.IGetTaskQueuesStatusResponse} message GetTaskQueuesStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTaskQueuesStatusResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.taskQueueStatuses != null && message.taskQueueStatuses.length)
                for (var i = 0; i < message.taskQueueStatuses.length; ++i)
                    $root.task_service.TaskQueueStatus.encode(message.taskQueueStatuses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetTaskQueuesStatusResponse message, length delimited. Does not implicitly {@link task_service.GetTaskQueuesStatusResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof task_service.GetTaskQueuesStatusResponse
         * @static
         * @param {task_service.IGetTaskQueuesStatusResponse} message GetTaskQueuesStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTaskQueuesStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetTaskQueuesStatusResponse message from the specified reader or buffer.
         * @function decode
         * @memberof task_service.GetTaskQueuesStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {task_service.GetTaskQueuesStatusResponse} GetTaskQueuesStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTaskQueuesStatusResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.task_service.GetTaskQueuesStatusResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.taskQueueStatuses && message.taskQueueStatuses.length))
                            message.taskQueueStatuses = [];
                        message.taskQueueStatuses.push($root.task_service.TaskQueueStatus.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetTaskQueuesStatusResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof task_service.GetTaskQueuesStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {task_service.GetTaskQueuesStatusResponse} GetTaskQueuesStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTaskQueuesStatusResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetTaskQueuesStatusResponse message.
         * @function verify
         * @memberof task_service.GetTaskQueuesStatusResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetTaskQueuesStatusResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.taskQueueStatuses != null && message.hasOwnProperty("taskQueueStatuses")) {
                if (!Array.isArray(message.taskQueueStatuses))
                    return "taskQueueStatuses: array expected";
                for (var i = 0; i < message.taskQueueStatuses.length; ++i) {
                    var error = $root.task_service.TaskQueueStatus.verify(message.taskQueueStatuses[i]);
                    if (error)
                        return "taskQueueStatuses." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetTaskQueuesStatusResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof task_service.GetTaskQueuesStatusResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {task_service.GetTaskQueuesStatusResponse} GetTaskQueuesStatusResponse
         */
        GetTaskQueuesStatusResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.task_service.GetTaskQueuesStatusResponse)
                return object;
            var message = new $root.task_service.GetTaskQueuesStatusResponse();
            if (object.taskQueueStatuses) {
                if (!Array.isArray(object.taskQueueStatuses))
                    throw TypeError(".task_service.GetTaskQueuesStatusResponse.taskQueueStatuses: array expected");
                message.taskQueueStatuses = [];
                for (var i = 0; i < object.taskQueueStatuses.length; ++i) {
                    if (typeof object.taskQueueStatuses[i] !== "object")
                        throw TypeError(".task_service.GetTaskQueuesStatusResponse.taskQueueStatuses: object expected");
                    message.taskQueueStatuses[i] = $root.task_service.TaskQueueStatus.fromObject(object.taskQueueStatuses[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetTaskQueuesStatusResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof task_service.GetTaskQueuesStatusResponse
         * @static
         * @param {task_service.GetTaskQueuesStatusResponse} message GetTaskQueuesStatusResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetTaskQueuesStatusResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.taskQueueStatuses = [];
            if (message.taskQueueStatuses && message.taskQueueStatuses.length) {
                object.taskQueueStatuses = [];
                for (var j = 0; j < message.taskQueueStatuses.length; ++j)
                    object.taskQueueStatuses[j] = $root.task_service.TaskQueueStatus.toObject(message.taskQueueStatuses[j], options);
            }
            return object;
        };

        /**
         * Converts this GetTaskQueuesStatusResponse to JSON.
         * @function toJSON
         * @memberof task_service.GetTaskQueuesStatusResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetTaskQueuesStatusResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetTaskQueuesStatusResponse
         * @function getTypeUrl
         * @memberof task_service.GetTaskQueuesStatusResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetTaskQueuesStatusResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/task_service.GetTaskQueuesStatusResponse";
        };

        return GetTaskQueuesStatusResponse;
    })();

    task_service.ScanForTasksRequest = (function() {

        /**
         * Properties of a ScanForTasksRequest.
         * @memberof task_service
         * @interface IScanForTasksRequest
         * @property {string|null} [name] ScanForTasksRequest name
         */

        /**
         * Constructs a new ScanForTasksRequest.
         * @memberof task_service
         * @classdesc Represents a ScanForTasksRequest.
         * @implements IScanForTasksRequest
         * @constructor
         * @param {task_service.IScanForTasksRequest=} [properties] Properties to set
         */
        function ScanForTasksRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ScanForTasksRequest name.
         * @member {string|null|undefined} name
         * @memberof task_service.ScanForTasksRequest
         * @instance
         */
        ScanForTasksRequest.prototype.name = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ScanForTasksRequest _name.
         * @member {"name"|undefined} _name
         * @memberof task_service.ScanForTasksRequest
         * @instance
         */
        Object.defineProperty(ScanForTasksRequest.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ScanForTasksRequest instance using the specified properties.
         * @function create
         * @memberof task_service.ScanForTasksRequest
         * @static
         * @param {task_service.IScanForTasksRequest=} [properties] Properties to set
         * @returns {task_service.ScanForTasksRequest} ScanForTasksRequest instance
         */
        ScanForTasksRequest.create = function create(properties) {
            return new ScanForTasksRequest(properties);
        };

        /**
         * Encodes the specified ScanForTasksRequest message. Does not implicitly {@link task_service.ScanForTasksRequest.verify|verify} messages.
         * @function encode
         * @memberof task_service.ScanForTasksRequest
         * @static
         * @param {task_service.IScanForTasksRequest} message ScanForTasksRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ScanForTasksRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified ScanForTasksRequest message, length delimited. Does not implicitly {@link task_service.ScanForTasksRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof task_service.ScanForTasksRequest
         * @static
         * @param {task_service.IScanForTasksRequest} message ScanForTasksRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ScanForTasksRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ScanForTasksRequest message from the specified reader or buffer.
         * @function decode
         * @memberof task_service.ScanForTasksRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {task_service.ScanForTasksRequest} ScanForTasksRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ScanForTasksRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.task_service.ScanForTasksRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ScanForTasksRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof task_service.ScanForTasksRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {task_service.ScanForTasksRequest} ScanForTasksRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ScanForTasksRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ScanForTasksRequest message.
         * @function verify
         * @memberof task_service.ScanForTasksRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ScanForTasksRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            return null;
        };

        /**
         * Creates a ScanForTasksRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof task_service.ScanForTasksRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {task_service.ScanForTasksRequest} ScanForTasksRequest
         */
        ScanForTasksRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.task_service.ScanForTasksRequest)
                return object;
            var message = new $root.task_service.ScanForTasksRequest();
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a ScanForTasksRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof task_service.ScanForTasksRequest
         * @static
         * @param {task_service.ScanForTasksRequest} message ScanForTasksRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ScanForTasksRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            return object;
        };

        /**
         * Converts this ScanForTasksRequest to JSON.
         * @function toJSON
         * @memberof task_service.ScanForTasksRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ScanForTasksRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ScanForTasksRequest
         * @function getTypeUrl
         * @memberof task_service.ScanForTasksRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ScanForTasksRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/task_service.ScanForTasksRequest";
        };

        return ScanForTasksRequest;
    })();

    task_service.ScanForTasksResponse = (function() {

        /**
         * Properties of a ScanForTasksResponse.
         * @memberof task_service
         * @interface IScanForTasksResponse
         */

        /**
         * Constructs a new ScanForTasksResponse.
         * @memberof task_service
         * @classdesc Represents a ScanForTasksResponse.
         * @implements IScanForTasksResponse
         * @constructor
         * @param {task_service.IScanForTasksResponse=} [properties] Properties to set
         */
        function ScanForTasksResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ScanForTasksResponse instance using the specified properties.
         * @function create
         * @memberof task_service.ScanForTasksResponse
         * @static
         * @param {task_service.IScanForTasksResponse=} [properties] Properties to set
         * @returns {task_service.ScanForTasksResponse} ScanForTasksResponse instance
         */
        ScanForTasksResponse.create = function create(properties) {
            return new ScanForTasksResponse(properties);
        };

        /**
         * Encodes the specified ScanForTasksResponse message. Does not implicitly {@link task_service.ScanForTasksResponse.verify|verify} messages.
         * @function encode
         * @memberof task_service.ScanForTasksResponse
         * @static
         * @param {task_service.IScanForTasksResponse} message ScanForTasksResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ScanForTasksResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ScanForTasksResponse message, length delimited. Does not implicitly {@link task_service.ScanForTasksResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof task_service.ScanForTasksResponse
         * @static
         * @param {task_service.IScanForTasksResponse} message ScanForTasksResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ScanForTasksResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ScanForTasksResponse message from the specified reader or buffer.
         * @function decode
         * @memberof task_service.ScanForTasksResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {task_service.ScanForTasksResponse} ScanForTasksResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ScanForTasksResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.task_service.ScanForTasksResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ScanForTasksResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof task_service.ScanForTasksResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {task_service.ScanForTasksResponse} ScanForTasksResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ScanForTasksResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ScanForTasksResponse message.
         * @function verify
         * @memberof task_service.ScanForTasksResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ScanForTasksResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ScanForTasksResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof task_service.ScanForTasksResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {task_service.ScanForTasksResponse} ScanForTasksResponse
         */
        ScanForTasksResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.task_service.ScanForTasksResponse)
                return object;
            return new $root.task_service.ScanForTasksResponse();
        };

        /**
         * Creates a plain object from a ScanForTasksResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof task_service.ScanForTasksResponse
         * @static
         * @param {task_service.ScanForTasksResponse} message ScanForTasksResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ScanForTasksResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ScanForTasksResponse to JSON.
         * @function toJSON
         * @memberof task_service.ScanForTasksResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ScanForTasksResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ScanForTasksResponse
         * @function getTypeUrl
         * @memberof task_service.ScanForTasksResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ScanForTasksResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/task_service.ScanForTasksResponse";
        };

        return ScanForTasksResponse;
    })();

    task_service.TaskQueueStatus = (function() {

        /**
         * Properties of a TaskQueueStatus.
         * @memberof task_service
         * @interface ITaskQueueStatus
         * @property {string|null} [name] TaskQueueStatus name
         * @property {number|null} [processingTasks] TaskQueueStatus processingTasks
         * @property {number|null} [pendingTasks] TaskQueueStatus pendingTasks
         * @property {number|null} [processedTasks] TaskQueueStatus processedTasks
         * @property {number|null} [skippedTasks] TaskQueueStatus skippedTasks
         * @property {number|null} [submittedTasks] TaskQueueStatus submittedTasks
         * @property {number|null} [retries] TaskQueueStatus retries
         * @property {number|null} [failures] TaskQueueStatus failures
         * @property {number|null} [errors] TaskQueueStatus errors
         * @property {string|null} [lastFailure] TaskQueueStatus lastFailure
         * @property {Long|null} [processingTimeMs] TaskQueueStatus processingTimeMs
         * @property {Long|null} [failedProcessingTimeMs] TaskQueueStatus failedProcessingTimeMs
         */

        /**
         * Constructs a new TaskQueueStatus.
         * @memberof task_service
         * @classdesc Represents a TaskQueueStatus.
         * @implements ITaskQueueStatus
         * @constructor
         * @param {task_service.ITaskQueueStatus=} [properties] Properties to set
         */
        function TaskQueueStatus(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TaskQueueStatus name.
         * @member {string|null|undefined} name
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        TaskQueueStatus.prototype.name = null;

        /**
         * TaskQueueStatus processingTasks.
         * @member {number|null|undefined} processingTasks
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        TaskQueueStatus.prototype.processingTasks = null;

        /**
         * TaskQueueStatus pendingTasks.
         * @member {number|null|undefined} pendingTasks
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        TaskQueueStatus.prototype.pendingTasks = null;

        /**
         * TaskQueueStatus processedTasks.
         * @member {number|null|undefined} processedTasks
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        TaskQueueStatus.prototype.processedTasks = null;

        /**
         * TaskQueueStatus skippedTasks.
         * @member {number|null|undefined} skippedTasks
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        TaskQueueStatus.prototype.skippedTasks = null;

        /**
         * TaskQueueStatus submittedTasks.
         * @member {number|null|undefined} submittedTasks
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        TaskQueueStatus.prototype.submittedTasks = null;

        /**
         * TaskQueueStatus retries.
         * @member {number|null|undefined} retries
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        TaskQueueStatus.prototype.retries = null;

        /**
         * TaskQueueStatus failures.
         * @member {number|null|undefined} failures
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        TaskQueueStatus.prototype.failures = null;

        /**
         * TaskQueueStatus errors.
         * @member {number|null|undefined} errors
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        TaskQueueStatus.prototype.errors = null;

        /**
         * TaskQueueStatus lastFailure.
         * @member {string|null|undefined} lastFailure
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        TaskQueueStatus.prototype.lastFailure = null;

        /**
         * TaskQueueStatus processingTimeMs.
         * @member {Long|null|undefined} processingTimeMs
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        TaskQueueStatus.prototype.processingTimeMs = null;

        /**
         * TaskQueueStatus failedProcessingTimeMs.
         * @member {Long|null|undefined} failedProcessingTimeMs
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        TaskQueueStatus.prototype.failedProcessingTimeMs = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * TaskQueueStatus _name.
         * @member {"name"|undefined} _name
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        Object.defineProperty(TaskQueueStatus.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * TaskQueueStatus _processingTasks.
         * @member {"processingTasks"|undefined} _processingTasks
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        Object.defineProperty(TaskQueueStatus.prototype, "_processingTasks", {
            get: $util.oneOfGetter($oneOfFields = ["processingTasks"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * TaskQueueStatus _pendingTasks.
         * @member {"pendingTasks"|undefined} _pendingTasks
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        Object.defineProperty(TaskQueueStatus.prototype, "_pendingTasks", {
            get: $util.oneOfGetter($oneOfFields = ["pendingTasks"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * TaskQueueStatus _processedTasks.
         * @member {"processedTasks"|undefined} _processedTasks
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        Object.defineProperty(TaskQueueStatus.prototype, "_processedTasks", {
            get: $util.oneOfGetter($oneOfFields = ["processedTasks"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * TaskQueueStatus _skippedTasks.
         * @member {"skippedTasks"|undefined} _skippedTasks
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        Object.defineProperty(TaskQueueStatus.prototype, "_skippedTasks", {
            get: $util.oneOfGetter($oneOfFields = ["skippedTasks"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * TaskQueueStatus _submittedTasks.
         * @member {"submittedTasks"|undefined} _submittedTasks
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        Object.defineProperty(TaskQueueStatus.prototype, "_submittedTasks", {
            get: $util.oneOfGetter($oneOfFields = ["submittedTasks"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * TaskQueueStatus _retries.
         * @member {"retries"|undefined} _retries
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        Object.defineProperty(TaskQueueStatus.prototype, "_retries", {
            get: $util.oneOfGetter($oneOfFields = ["retries"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * TaskQueueStatus _failures.
         * @member {"failures"|undefined} _failures
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        Object.defineProperty(TaskQueueStatus.prototype, "_failures", {
            get: $util.oneOfGetter($oneOfFields = ["failures"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * TaskQueueStatus _errors.
         * @member {"errors"|undefined} _errors
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        Object.defineProperty(TaskQueueStatus.prototype, "_errors", {
            get: $util.oneOfGetter($oneOfFields = ["errors"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * TaskQueueStatus _lastFailure.
         * @member {"lastFailure"|undefined} _lastFailure
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        Object.defineProperty(TaskQueueStatus.prototype, "_lastFailure", {
            get: $util.oneOfGetter($oneOfFields = ["lastFailure"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * TaskQueueStatus _processingTimeMs.
         * @member {"processingTimeMs"|undefined} _processingTimeMs
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        Object.defineProperty(TaskQueueStatus.prototype, "_processingTimeMs", {
            get: $util.oneOfGetter($oneOfFields = ["processingTimeMs"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * TaskQueueStatus _failedProcessingTimeMs.
         * @member {"failedProcessingTimeMs"|undefined} _failedProcessingTimeMs
         * @memberof task_service.TaskQueueStatus
         * @instance
         */
        Object.defineProperty(TaskQueueStatus.prototype, "_failedProcessingTimeMs", {
            get: $util.oneOfGetter($oneOfFields = ["failedProcessingTimeMs"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new TaskQueueStatus instance using the specified properties.
         * @function create
         * @memberof task_service.TaskQueueStatus
         * @static
         * @param {task_service.ITaskQueueStatus=} [properties] Properties to set
         * @returns {task_service.TaskQueueStatus} TaskQueueStatus instance
         */
        TaskQueueStatus.create = function create(properties) {
            return new TaskQueueStatus(properties);
        };

        /**
         * Encodes the specified TaskQueueStatus message. Does not implicitly {@link task_service.TaskQueueStatus.verify|verify} messages.
         * @function encode
         * @memberof task_service.TaskQueueStatus
         * @static
         * @param {task_service.ITaskQueueStatus} message TaskQueueStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TaskQueueStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.processingTasks != null && Object.hasOwnProperty.call(message, "processingTasks"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.processingTasks);
            if (message.pendingTasks != null && Object.hasOwnProperty.call(message, "pendingTasks"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pendingTasks);
            if (message.processedTasks != null && Object.hasOwnProperty.call(message, "processedTasks"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.processedTasks);
            if (message.skippedTasks != null && Object.hasOwnProperty.call(message, "skippedTasks"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.skippedTasks);
            if (message.submittedTasks != null && Object.hasOwnProperty.call(message, "submittedTasks"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.submittedTasks);
            if (message.retries != null && Object.hasOwnProperty.call(message, "retries"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.retries);
            if (message.failures != null && Object.hasOwnProperty.call(message, "failures"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.failures);
            if (message.errors != null && Object.hasOwnProperty.call(message, "errors"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.errors);
            if (message.lastFailure != null && Object.hasOwnProperty.call(message, "lastFailure"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.lastFailure);
            if (message.processingTimeMs != null && Object.hasOwnProperty.call(message, "processingTimeMs"))
                writer.uint32(/* id 11, wireType 0 =*/88).int64(message.processingTimeMs);
            if (message.failedProcessingTimeMs != null && Object.hasOwnProperty.call(message, "failedProcessingTimeMs"))
                writer.uint32(/* id 12, wireType 0 =*/96).int64(message.failedProcessingTimeMs);
            return writer;
        };

        /**
         * Encodes the specified TaskQueueStatus message, length delimited. Does not implicitly {@link task_service.TaskQueueStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof task_service.TaskQueueStatus
         * @static
         * @param {task_service.ITaskQueueStatus} message TaskQueueStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TaskQueueStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TaskQueueStatus message from the specified reader or buffer.
         * @function decode
         * @memberof task_service.TaskQueueStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {task_service.TaskQueueStatus} TaskQueueStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TaskQueueStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.task_service.TaskQueueStatus();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.processingTasks = reader.int32();
                        break;
                    }
                case 3: {
                        message.pendingTasks = reader.int32();
                        break;
                    }
                case 4: {
                        message.processedTasks = reader.int32();
                        break;
                    }
                case 5: {
                        message.skippedTasks = reader.int32();
                        break;
                    }
                case 6: {
                        message.submittedTasks = reader.int32();
                        break;
                    }
                case 7: {
                        message.retries = reader.int32();
                        break;
                    }
                case 8: {
                        message.failures = reader.int32();
                        break;
                    }
                case 9: {
                        message.errors = reader.int32();
                        break;
                    }
                case 10: {
                        message.lastFailure = reader.string();
                        break;
                    }
                case 11: {
                        message.processingTimeMs = reader.int64();
                        break;
                    }
                case 12: {
                        message.failedProcessingTimeMs = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TaskQueueStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof task_service.TaskQueueStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {task_service.TaskQueueStatus} TaskQueueStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TaskQueueStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TaskQueueStatus message.
         * @function verify
         * @memberof task_service.TaskQueueStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TaskQueueStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            if (message.processingTasks != null && message.hasOwnProperty("processingTasks")) {
                properties._processingTasks = 1;
                if (!$util.isInteger(message.processingTasks))
                    return "processingTasks: integer expected";
            }
            if (message.pendingTasks != null && message.hasOwnProperty("pendingTasks")) {
                properties._pendingTasks = 1;
                if (!$util.isInteger(message.pendingTasks))
                    return "pendingTasks: integer expected";
            }
            if (message.processedTasks != null && message.hasOwnProperty("processedTasks")) {
                properties._processedTasks = 1;
                if (!$util.isInteger(message.processedTasks))
                    return "processedTasks: integer expected";
            }
            if (message.skippedTasks != null && message.hasOwnProperty("skippedTasks")) {
                properties._skippedTasks = 1;
                if (!$util.isInteger(message.skippedTasks))
                    return "skippedTasks: integer expected";
            }
            if (message.submittedTasks != null && message.hasOwnProperty("submittedTasks")) {
                properties._submittedTasks = 1;
                if (!$util.isInteger(message.submittedTasks))
                    return "submittedTasks: integer expected";
            }
            if (message.retries != null && message.hasOwnProperty("retries")) {
                properties._retries = 1;
                if (!$util.isInteger(message.retries))
                    return "retries: integer expected";
            }
            if (message.failures != null && message.hasOwnProperty("failures")) {
                properties._failures = 1;
                if (!$util.isInteger(message.failures))
                    return "failures: integer expected";
            }
            if (message.errors != null && message.hasOwnProperty("errors")) {
                properties._errors = 1;
                if (!$util.isInteger(message.errors))
                    return "errors: integer expected";
            }
            if (message.lastFailure != null && message.hasOwnProperty("lastFailure")) {
                properties._lastFailure = 1;
                if (!$util.isString(message.lastFailure))
                    return "lastFailure: string expected";
            }
            if (message.processingTimeMs != null && message.hasOwnProperty("processingTimeMs")) {
                properties._processingTimeMs = 1;
                if (!$util.isInteger(message.processingTimeMs) && !(message.processingTimeMs && $util.isInteger(message.processingTimeMs.low) && $util.isInteger(message.processingTimeMs.high)))
                    return "processingTimeMs: integer|Long expected";
            }
            if (message.failedProcessingTimeMs != null && message.hasOwnProperty("failedProcessingTimeMs")) {
                properties._failedProcessingTimeMs = 1;
                if (!$util.isInteger(message.failedProcessingTimeMs) && !(message.failedProcessingTimeMs && $util.isInteger(message.failedProcessingTimeMs.low) && $util.isInteger(message.failedProcessingTimeMs.high)))
                    return "failedProcessingTimeMs: integer|Long expected";
            }
            return null;
        };

        /**
         * Creates a TaskQueueStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof task_service.TaskQueueStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {task_service.TaskQueueStatus} TaskQueueStatus
         */
        TaskQueueStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.task_service.TaskQueueStatus)
                return object;
            var message = new $root.task_service.TaskQueueStatus();
            if (object.name != null)
                message.name = String(object.name);
            if (object.processingTasks != null)
                message.processingTasks = object.processingTasks | 0;
            if (object.pendingTasks != null)
                message.pendingTasks = object.pendingTasks | 0;
            if (object.processedTasks != null)
                message.processedTasks = object.processedTasks | 0;
            if (object.skippedTasks != null)
                message.skippedTasks = object.skippedTasks | 0;
            if (object.submittedTasks != null)
                message.submittedTasks = object.submittedTasks | 0;
            if (object.retries != null)
                message.retries = object.retries | 0;
            if (object.failures != null)
                message.failures = object.failures | 0;
            if (object.errors != null)
                message.errors = object.errors | 0;
            if (object.lastFailure != null)
                message.lastFailure = String(object.lastFailure);
            if (object.processingTimeMs != null)
                if ($util.Long)
                    (message.processingTimeMs = $util.Long.fromValue(object.processingTimeMs)).unsigned = false;
                else if (typeof object.processingTimeMs === "string")
                    message.processingTimeMs = parseInt(object.processingTimeMs, 10);
                else if (typeof object.processingTimeMs === "number")
                    message.processingTimeMs = object.processingTimeMs;
                else if (typeof object.processingTimeMs === "object")
                    message.processingTimeMs = new $util.LongBits(object.processingTimeMs.low >>> 0, object.processingTimeMs.high >>> 0).toNumber();
            if (object.failedProcessingTimeMs != null)
                if ($util.Long)
                    (message.failedProcessingTimeMs = $util.Long.fromValue(object.failedProcessingTimeMs)).unsigned = false;
                else if (typeof object.failedProcessingTimeMs === "string")
                    message.failedProcessingTimeMs = parseInt(object.failedProcessingTimeMs, 10);
                else if (typeof object.failedProcessingTimeMs === "number")
                    message.failedProcessingTimeMs = object.failedProcessingTimeMs;
                else if (typeof object.failedProcessingTimeMs === "object")
                    message.failedProcessingTimeMs = new $util.LongBits(object.failedProcessingTimeMs.low >>> 0, object.failedProcessingTimeMs.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a TaskQueueStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof task_service.TaskQueueStatus
         * @static
         * @param {task_service.TaskQueueStatus} message TaskQueueStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TaskQueueStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            if (message.processingTasks != null && message.hasOwnProperty("processingTasks")) {
                object.processingTasks = message.processingTasks;
                if (options.oneofs)
                    object._processingTasks = "processingTasks";
            }
            if (message.pendingTasks != null && message.hasOwnProperty("pendingTasks")) {
                object.pendingTasks = message.pendingTasks;
                if (options.oneofs)
                    object._pendingTasks = "pendingTasks";
            }
            if (message.processedTasks != null && message.hasOwnProperty("processedTasks")) {
                object.processedTasks = message.processedTasks;
                if (options.oneofs)
                    object._processedTasks = "processedTasks";
            }
            if (message.skippedTasks != null && message.hasOwnProperty("skippedTasks")) {
                object.skippedTasks = message.skippedTasks;
                if (options.oneofs)
                    object._skippedTasks = "skippedTasks";
            }
            if (message.submittedTasks != null && message.hasOwnProperty("submittedTasks")) {
                object.submittedTasks = message.submittedTasks;
                if (options.oneofs)
                    object._submittedTasks = "submittedTasks";
            }
            if (message.retries != null && message.hasOwnProperty("retries")) {
                object.retries = message.retries;
                if (options.oneofs)
                    object._retries = "retries";
            }
            if (message.failures != null && message.hasOwnProperty("failures")) {
                object.failures = message.failures;
                if (options.oneofs)
                    object._failures = "failures";
            }
            if (message.errors != null && message.hasOwnProperty("errors")) {
                object.errors = message.errors;
                if (options.oneofs)
                    object._errors = "errors";
            }
            if (message.lastFailure != null && message.hasOwnProperty("lastFailure")) {
                object.lastFailure = message.lastFailure;
                if (options.oneofs)
                    object._lastFailure = "lastFailure";
            }
            if (message.processingTimeMs != null && message.hasOwnProperty("processingTimeMs")) {
                if (typeof message.processingTimeMs === "number")
                    object.processingTimeMs = options.longs === String ? String(message.processingTimeMs) : message.processingTimeMs;
                else
                    object.processingTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.processingTimeMs) : options.longs === Number ? new $util.LongBits(message.processingTimeMs.low >>> 0, message.processingTimeMs.high >>> 0).toNumber() : message.processingTimeMs;
                if (options.oneofs)
                    object._processingTimeMs = "processingTimeMs";
            }
            if (message.failedProcessingTimeMs != null && message.hasOwnProperty("failedProcessingTimeMs")) {
                if (typeof message.failedProcessingTimeMs === "number")
                    object.failedProcessingTimeMs = options.longs === String ? String(message.failedProcessingTimeMs) : message.failedProcessingTimeMs;
                else
                    object.failedProcessingTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.failedProcessingTimeMs) : options.longs === Number ? new $util.LongBits(message.failedProcessingTimeMs.low >>> 0, message.failedProcessingTimeMs.high >>> 0).toNumber() : message.failedProcessingTimeMs;
                if (options.oneofs)
                    object._failedProcessingTimeMs = "failedProcessingTimeMs";
            }
            return object;
        };

        /**
         * Converts this TaskQueueStatus to JSON.
         * @function toJSON
         * @memberof task_service.TaskQueueStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TaskQueueStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TaskQueueStatus
         * @function getTypeUrl
         * @memberof task_service.TaskQueueStatus
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TaskQueueStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/task_service.TaskQueueStatus";
        };

        return TaskQueueStatus;
    })();

    task_service.ResetTaskQueuesRequest = (function() {

        /**
         * Properties of a ResetTaskQueuesRequest.
         * @memberof task_service
         * @interface IResetTaskQueuesRequest
         * @property {string|null} [name] ResetTaskQueuesRequest name
         */

        /**
         * Constructs a new ResetTaskQueuesRequest.
         * @memberof task_service
         * @classdesc Represents a ResetTaskQueuesRequest.
         * @implements IResetTaskQueuesRequest
         * @constructor
         * @param {task_service.IResetTaskQueuesRequest=} [properties] Properties to set
         */
        function ResetTaskQueuesRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResetTaskQueuesRequest name.
         * @member {string|null|undefined} name
         * @memberof task_service.ResetTaskQueuesRequest
         * @instance
         */
        ResetTaskQueuesRequest.prototype.name = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ResetTaskQueuesRequest _name.
         * @member {"name"|undefined} _name
         * @memberof task_service.ResetTaskQueuesRequest
         * @instance
         */
        Object.defineProperty(ResetTaskQueuesRequest.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ResetTaskQueuesRequest instance using the specified properties.
         * @function create
         * @memberof task_service.ResetTaskQueuesRequest
         * @static
         * @param {task_service.IResetTaskQueuesRequest=} [properties] Properties to set
         * @returns {task_service.ResetTaskQueuesRequest} ResetTaskQueuesRequest instance
         */
        ResetTaskQueuesRequest.create = function create(properties) {
            return new ResetTaskQueuesRequest(properties);
        };

        /**
         * Encodes the specified ResetTaskQueuesRequest message. Does not implicitly {@link task_service.ResetTaskQueuesRequest.verify|verify} messages.
         * @function encode
         * @memberof task_service.ResetTaskQueuesRequest
         * @static
         * @param {task_service.IResetTaskQueuesRequest} message ResetTaskQueuesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetTaskQueuesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified ResetTaskQueuesRequest message, length delimited. Does not implicitly {@link task_service.ResetTaskQueuesRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof task_service.ResetTaskQueuesRequest
         * @static
         * @param {task_service.IResetTaskQueuesRequest} message ResetTaskQueuesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetTaskQueuesRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResetTaskQueuesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof task_service.ResetTaskQueuesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {task_service.ResetTaskQueuesRequest} ResetTaskQueuesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetTaskQueuesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.task_service.ResetTaskQueuesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResetTaskQueuesRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof task_service.ResetTaskQueuesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {task_service.ResetTaskQueuesRequest} ResetTaskQueuesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetTaskQueuesRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResetTaskQueuesRequest message.
         * @function verify
         * @memberof task_service.ResetTaskQueuesRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResetTaskQueuesRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            return null;
        };

        /**
         * Creates a ResetTaskQueuesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof task_service.ResetTaskQueuesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {task_service.ResetTaskQueuesRequest} ResetTaskQueuesRequest
         */
        ResetTaskQueuesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.task_service.ResetTaskQueuesRequest)
                return object;
            var message = new $root.task_service.ResetTaskQueuesRequest();
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a ResetTaskQueuesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof task_service.ResetTaskQueuesRequest
         * @static
         * @param {task_service.ResetTaskQueuesRequest} message ResetTaskQueuesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResetTaskQueuesRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            return object;
        };

        /**
         * Converts this ResetTaskQueuesRequest to JSON.
         * @function toJSON
         * @memberof task_service.ResetTaskQueuesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResetTaskQueuesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResetTaskQueuesRequest
         * @function getTypeUrl
         * @memberof task_service.ResetTaskQueuesRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResetTaskQueuesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/task_service.ResetTaskQueuesRequest";
        };

        return ResetTaskQueuesRequest;
    })();

    task_service.ResetTaskQueuesResponse = (function() {

        /**
         * Properties of a ResetTaskQueuesResponse.
         * @memberof task_service
         * @interface IResetTaskQueuesResponse
         */

        /**
         * Constructs a new ResetTaskQueuesResponse.
         * @memberof task_service
         * @classdesc Represents a ResetTaskQueuesResponse.
         * @implements IResetTaskQueuesResponse
         * @constructor
         * @param {task_service.IResetTaskQueuesResponse=} [properties] Properties to set
         */
        function ResetTaskQueuesResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ResetTaskQueuesResponse instance using the specified properties.
         * @function create
         * @memberof task_service.ResetTaskQueuesResponse
         * @static
         * @param {task_service.IResetTaskQueuesResponse=} [properties] Properties to set
         * @returns {task_service.ResetTaskQueuesResponse} ResetTaskQueuesResponse instance
         */
        ResetTaskQueuesResponse.create = function create(properties) {
            return new ResetTaskQueuesResponse(properties);
        };

        /**
         * Encodes the specified ResetTaskQueuesResponse message. Does not implicitly {@link task_service.ResetTaskQueuesResponse.verify|verify} messages.
         * @function encode
         * @memberof task_service.ResetTaskQueuesResponse
         * @static
         * @param {task_service.IResetTaskQueuesResponse} message ResetTaskQueuesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetTaskQueuesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ResetTaskQueuesResponse message, length delimited. Does not implicitly {@link task_service.ResetTaskQueuesResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof task_service.ResetTaskQueuesResponse
         * @static
         * @param {task_service.IResetTaskQueuesResponse} message ResetTaskQueuesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetTaskQueuesResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResetTaskQueuesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof task_service.ResetTaskQueuesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {task_service.ResetTaskQueuesResponse} ResetTaskQueuesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetTaskQueuesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.task_service.ResetTaskQueuesResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResetTaskQueuesResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof task_service.ResetTaskQueuesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {task_service.ResetTaskQueuesResponse} ResetTaskQueuesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetTaskQueuesResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResetTaskQueuesResponse message.
         * @function verify
         * @memberof task_service.ResetTaskQueuesResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResetTaskQueuesResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ResetTaskQueuesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof task_service.ResetTaskQueuesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {task_service.ResetTaskQueuesResponse} ResetTaskQueuesResponse
         */
        ResetTaskQueuesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.task_service.ResetTaskQueuesResponse)
                return object;
            return new $root.task_service.ResetTaskQueuesResponse();
        };

        /**
         * Creates a plain object from a ResetTaskQueuesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof task_service.ResetTaskQueuesResponse
         * @static
         * @param {task_service.ResetTaskQueuesResponse} message ResetTaskQueuesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResetTaskQueuesResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ResetTaskQueuesResponse to JSON.
         * @function toJSON
         * @memberof task_service.ResetTaskQueuesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResetTaskQueuesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResetTaskQueuesResponse
         * @function getTypeUrl
         * @memberof task_service.ResetTaskQueuesResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResetTaskQueuesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/task_service.ResetTaskQueuesResponse";
        };

        return ResetTaskQueuesResponse;
    })();

    task_service.ReplyToPostTask = (function() {

        /**
         * Properties of a ReplyToPostTask.
         * @memberof task_service
         * @interface IReplyToPostTask
         * @property {number|null} [projectId] ReplyToPostTask projectId
         */

        /**
         * Constructs a new ReplyToPostTask.
         * @memberof task_service
         * @classdesc Represents a ReplyToPostTask.
         * @implements IReplyToPostTask
         * @constructor
         * @param {task_service.IReplyToPostTask=} [properties] Properties to set
         */
        function ReplyToPostTask(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReplyToPostTask projectId.
         * @member {number|null|undefined} projectId
         * @memberof task_service.ReplyToPostTask
         * @instance
         */
        ReplyToPostTask.prototype.projectId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ReplyToPostTask _projectId.
         * @member {"projectId"|undefined} _projectId
         * @memberof task_service.ReplyToPostTask
         * @instance
         */
        Object.defineProperty(ReplyToPostTask.prototype, "_projectId", {
            get: $util.oneOfGetter($oneOfFields = ["projectId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ReplyToPostTask instance using the specified properties.
         * @function create
         * @memberof task_service.ReplyToPostTask
         * @static
         * @param {task_service.IReplyToPostTask=} [properties] Properties to set
         * @returns {task_service.ReplyToPostTask} ReplyToPostTask instance
         */
        ReplyToPostTask.create = function create(properties) {
            return new ReplyToPostTask(properties);
        };

        /**
         * Encodes the specified ReplyToPostTask message. Does not implicitly {@link task_service.ReplyToPostTask.verify|verify} messages.
         * @function encode
         * @memberof task_service.ReplyToPostTask
         * @static
         * @param {task_service.IReplyToPostTask} message ReplyToPostTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReplyToPostTask.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectId != null && Object.hasOwnProperty.call(message, "projectId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.projectId);
            return writer;
        };

        /**
         * Encodes the specified ReplyToPostTask message, length delimited. Does not implicitly {@link task_service.ReplyToPostTask.verify|verify} messages.
         * @function encodeDelimited
         * @memberof task_service.ReplyToPostTask
         * @static
         * @param {task_service.IReplyToPostTask} message ReplyToPostTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReplyToPostTask.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReplyToPostTask message from the specified reader or buffer.
         * @function decode
         * @memberof task_service.ReplyToPostTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {task_service.ReplyToPostTask} ReplyToPostTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReplyToPostTask.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.task_service.ReplyToPostTask();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.projectId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReplyToPostTask message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof task_service.ReplyToPostTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {task_service.ReplyToPostTask} ReplyToPostTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReplyToPostTask.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReplyToPostTask message.
         * @function verify
         * @memberof task_service.ReplyToPostTask
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReplyToPostTask.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.projectId != null && message.hasOwnProperty("projectId")) {
                properties._projectId = 1;
                if (!$util.isInteger(message.projectId))
                    return "projectId: integer expected";
            }
            return null;
        };

        /**
         * Creates a ReplyToPostTask message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof task_service.ReplyToPostTask
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {task_service.ReplyToPostTask} ReplyToPostTask
         */
        ReplyToPostTask.fromObject = function fromObject(object) {
            if (object instanceof $root.task_service.ReplyToPostTask)
                return object;
            var message = new $root.task_service.ReplyToPostTask();
            if (object.projectId != null)
                message.projectId = object.projectId | 0;
            return message;
        };

        /**
         * Creates a plain object from a ReplyToPostTask message. Also converts values to other types if specified.
         * @function toObject
         * @memberof task_service.ReplyToPostTask
         * @static
         * @param {task_service.ReplyToPostTask} message ReplyToPostTask
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReplyToPostTask.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.projectId != null && message.hasOwnProperty("projectId")) {
                object.projectId = message.projectId;
                if (options.oneofs)
                    object._projectId = "projectId";
            }
            return object;
        };

        /**
         * Converts this ReplyToPostTask to JSON.
         * @function toJSON
         * @memberof task_service.ReplyToPostTask
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReplyToPostTask.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReplyToPostTask
         * @function getTypeUrl
         * @memberof task_service.ReplyToPostTask
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReplyToPostTask.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/task_service.ReplyToPostTask";
        };

        return ReplyToPostTask;
    })();

    task_service.FillInMissingProjectInfoTask = (function() {

        /**
         * Properties of a FillInMissingProjectInfoTask.
         * @memberof task_service
         * @interface IFillInMissingProjectInfoTask
         * @property {number|null} [projectId] FillInMissingProjectInfoTask projectId
         */

        /**
         * Constructs a new FillInMissingProjectInfoTask.
         * @memberof task_service
         * @classdesc Represents a FillInMissingProjectInfoTask.
         * @implements IFillInMissingProjectInfoTask
         * @constructor
         * @param {task_service.IFillInMissingProjectInfoTask=} [properties] Properties to set
         */
        function FillInMissingProjectInfoTask(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FillInMissingProjectInfoTask projectId.
         * @member {number|null|undefined} projectId
         * @memberof task_service.FillInMissingProjectInfoTask
         * @instance
         */
        FillInMissingProjectInfoTask.prototype.projectId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * FillInMissingProjectInfoTask _projectId.
         * @member {"projectId"|undefined} _projectId
         * @memberof task_service.FillInMissingProjectInfoTask
         * @instance
         */
        Object.defineProperty(FillInMissingProjectInfoTask.prototype, "_projectId", {
            get: $util.oneOfGetter($oneOfFields = ["projectId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new FillInMissingProjectInfoTask instance using the specified properties.
         * @function create
         * @memberof task_service.FillInMissingProjectInfoTask
         * @static
         * @param {task_service.IFillInMissingProjectInfoTask=} [properties] Properties to set
         * @returns {task_service.FillInMissingProjectInfoTask} FillInMissingProjectInfoTask instance
         */
        FillInMissingProjectInfoTask.create = function create(properties) {
            return new FillInMissingProjectInfoTask(properties);
        };

        /**
         * Encodes the specified FillInMissingProjectInfoTask message. Does not implicitly {@link task_service.FillInMissingProjectInfoTask.verify|verify} messages.
         * @function encode
         * @memberof task_service.FillInMissingProjectInfoTask
         * @static
         * @param {task_service.IFillInMissingProjectInfoTask} message FillInMissingProjectInfoTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FillInMissingProjectInfoTask.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectId != null && Object.hasOwnProperty.call(message, "projectId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.projectId);
            return writer;
        };

        /**
         * Encodes the specified FillInMissingProjectInfoTask message, length delimited. Does not implicitly {@link task_service.FillInMissingProjectInfoTask.verify|verify} messages.
         * @function encodeDelimited
         * @memberof task_service.FillInMissingProjectInfoTask
         * @static
         * @param {task_service.IFillInMissingProjectInfoTask} message FillInMissingProjectInfoTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FillInMissingProjectInfoTask.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FillInMissingProjectInfoTask message from the specified reader or buffer.
         * @function decode
         * @memberof task_service.FillInMissingProjectInfoTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {task_service.FillInMissingProjectInfoTask} FillInMissingProjectInfoTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FillInMissingProjectInfoTask.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.task_service.FillInMissingProjectInfoTask();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.projectId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FillInMissingProjectInfoTask message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof task_service.FillInMissingProjectInfoTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {task_service.FillInMissingProjectInfoTask} FillInMissingProjectInfoTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FillInMissingProjectInfoTask.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FillInMissingProjectInfoTask message.
         * @function verify
         * @memberof task_service.FillInMissingProjectInfoTask
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FillInMissingProjectInfoTask.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.projectId != null && message.hasOwnProperty("projectId")) {
                properties._projectId = 1;
                if (!$util.isInteger(message.projectId))
                    return "projectId: integer expected";
            }
            return null;
        };

        /**
         * Creates a FillInMissingProjectInfoTask message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof task_service.FillInMissingProjectInfoTask
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {task_service.FillInMissingProjectInfoTask} FillInMissingProjectInfoTask
         */
        FillInMissingProjectInfoTask.fromObject = function fromObject(object) {
            if (object instanceof $root.task_service.FillInMissingProjectInfoTask)
                return object;
            var message = new $root.task_service.FillInMissingProjectInfoTask();
            if (object.projectId != null)
                message.projectId = object.projectId | 0;
            return message;
        };

        /**
         * Creates a plain object from a FillInMissingProjectInfoTask message. Also converts values to other types if specified.
         * @function toObject
         * @memberof task_service.FillInMissingProjectInfoTask
         * @static
         * @param {task_service.FillInMissingProjectInfoTask} message FillInMissingProjectInfoTask
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FillInMissingProjectInfoTask.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.projectId != null && message.hasOwnProperty("projectId")) {
                object.projectId = message.projectId;
                if (options.oneofs)
                    object._projectId = "projectId";
            }
            return object;
        };

        /**
         * Converts this FillInMissingProjectInfoTask to JSON.
         * @function toJSON
         * @memberof task_service.FillInMissingProjectInfoTask
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FillInMissingProjectInfoTask.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FillInMissingProjectInfoTask
         * @function getTypeUrl
         * @memberof task_service.FillInMissingProjectInfoTask
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FillInMissingProjectInfoTask.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/task_service.FillInMissingProjectInfoTask";
        };

        return FillInMissingProjectInfoTask;
    })();

    task_service.GenerateProjectsTask = (function() {

        /**
         * Properties of a GenerateProjectsTask.
         * @memberof task_service
         * @interface IGenerateProjectsTask
         * @property {number|null} [projectInputId] GenerateProjectsTask projectInputId
         */

        /**
         * Constructs a new GenerateProjectsTask.
         * @memberof task_service
         * @classdesc Represents a GenerateProjectsTask.
         * @implements IGenerateProjectsTask
         * @constructor
         * @param {task_service.IGenerateProjectsTask=} [properties] Properties to set
         */
        function GenerateProjectsTask(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GenerateProjectsTask projectInputId.
         * @member {number|null|undefined} projectInputId
         * @memberof task_service.GenerateProjectsTask
         * @instance
         */
        GenerateProjectsTask.prototype.projectInputId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GenerateProjectsTask _projectInputId.
         * @member {"projectInputId"|undefined} _projectInputId
         * @memberof task_service.GenerateProjectsTask
         * @instance
         */
        Object.defineProperty(GenerateProjectsTask.prototype, "_projectInputId", {
            get: $util.oneOfGetter($oneOfFields = ["projectInputId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GenerateProjectsTask instance using the specified properties.
         * @function create
         * @memberof task_service.GenerateProjectsTask
         * @static
         * @param {task_service.IGenerateProjectsTask=} [properties] Properties to set
         * @returns {task_service.GenerateProjectsTask} GenerateProjectsTask instance
         */
        GenerateProjectsTask.create = function create(properties) {
            return new GenerateProjectsTask(properties);
        };

        /**
         * Encodes the specified GenerateProjectsTask message. Does not implicitly {@link task_service.GenerateProjectsTask.verify|verify} messages.
         * @function encode
         * @memberof task_service.GenerateProjectsTask
         * @static
         * @param {task_service.IGenerateProjectsTask} message GenerateProjectsTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateProjectsTask.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectInputId != null && Object.hasOwnProperty.call(message, "projectInputId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.projectInputId);
            return writer;
        };

        /**
         * Encodes the specified GenerateProjectsTask message, length delimited. Does not implicitly {@link task_service.GenerateProjectsTask.verify|verify} messages.
         * @function encodeDelimited
         * @memberof task_service.GenerateProjectsTask
         * @static
         * @param {task_service.IGenerateProjectsTask} message GenerateProjectsTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateProjectsTask.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GenerateProjectsTask message from the specified reader or buffer.
         * @function decode
         * @memberof task_service.GenerateProjectsTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {task_service.GenerateProjectsTask} GenerateProjectsTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateProjectsTask.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.task_service.GenerateProjectsTask();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.projectInputId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GenerateProjectsTask message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof task_service.GenerateProjectsTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {task_service.GenerateProjectsTask} GenerateProjectsTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateProjectsTask.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GenerateProjectsTask message.
         * @function verify
         * @memberof task_service.GenerateProjectsTask
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GenerateProjectsTask.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.projectInputId != null && message.hasOwnProperty("projectInputId")) {
                properties._projectInputId = 1;
                if (!$util.isInteger(message.projectInputId))
                    return "projectInputId: integer expected";
            }
            return null;
        };

        /**
         * Creates a GenerateProjectsTask message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof task_service.GenerateProjectsTask
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {task_service.GenerateProjectsTask} GenerateProjectsTask
         */
        GenerateProjectsTask.fromObject = function fromObject(object) {
            if (object instanceof $root.task_service.GenerateProjectsTask)
                return object;
            var message = new $root.task_service.GenerateProjectsTask();
            if (object.projectInputId != null)
                message.projectInputId = object.projectInputId | 0;
            return message;
        };

        /**
         * Creates a plain object from a GenerateProjectsTask message. Also converts values to other types if specified.
         * @function toObject
         * @memberof task_service.GenerateProjectsTask
         * @static
         * @param {task_service.GenerateProjectsTask} message GenerateProjectsTask
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GenerateProjectsTask.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.projectInputId != null && message.hasOwnProperty("projectInputId")) {
                object.projectInputId = message.projectInputId;
                if (options.oneofs)
                    object._projectInputId = "projectInputId";
            }
            return object;
        };

        /**
         * Converts this GenerateProjectsTask to JSON.
         * @function toJSON
         * @memberof task_service.GenerateProjectsTask
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GenerateProjectsTask.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GenerateProjectsTask
         * @function getTypeUrl
         * @memberof task_service.GenerateProjectsTask
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GenerateProjectsTask.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/task_service.GenerateProjectsTask";
        };

        return GenerateProjectsTask;
    })();

    task_service.GenerateDerivedProjectsTask = (function() {

        /**
         * Properties of a GenerateDerivedProjectsTask.
         * @memberof task_service
         * @interface IGenerateDerivedProjectsTask
         * @property {number|null} [projectInputId] GenerateDerivedProjectsTask projectInputId
         * @property {number|null} [existingProjectId] GenerateDerivedProjectsTask existingProjectId
         */

        /**
         * Constructs a new GenerateDerivedProjectsTask.
         * @memberof task_service
         * @classdesc Represents a GenerateDerivedProjectsTask.
         * @implements IGenerateDerivedProjectsTask
         * @constructor
         * @param {task_service.IGenerateDerivedProjectsTask=} [properties] Properties to set
         */
        function GenerateDerivedProjectsTask(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GenerateDerivedProjectsTask projectInputId.
         * @member {number|null|undefined} projectInputId
         * @memberof task_service.GenerateDerivedProjectsTask
         * @instance
         */
        GenerateDerivedProjectsTask.prototype.projectInputId = null;

        /**
         * GenerateDerivedProjectsTask existingProjectId.
         * @member {number|null|undefined} existingProjectId
         * @memberof task_service.GenerateDerivedProjectsTask
         * @instance
         */
        GenerateDerivedProjectsTask.prototype.existingProjectId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GenerateDerivedProjectsTask _projectInputId.
         * @member {"projectInputId"|undefined} _projectInputId
         * @memberof task_service.GenerateDerivedProjectsTask
         * @instance
         */
        Object.defineProperty(GenerateDerivedProjectsTask.prototype, "_projectInputId", {
            get: $util.oneOfGetter($oneOfFields = ["projectInputId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GenerateDerivedProjectsTask _existingProjectId.
         * @member {"existingProjectId"|undefined} _existingProjectId
         * @memberof task_service.GenerateDerivedProjectsTask
         * @instance
         */
        Object.defineProperty(GenerateDerivedProjectsTask.prototype, "_existingProjectId", {
            get: $util.oneOfGetter($oneOfFields = ["existingProjectId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GenerateDerivedProjectsTask instance using the specified properties.
         * @function create
         * @memberof task_service.GenerateDerivedProjectsTask
         * @static
         * @param {task_service.IGenerateDerivedProjectsTask=} [properties] Properties to set
         * @returns {task_service.GenerateDerivedProjectsTask} GenerateDerivedProjectsTask instance
         */
        GenerateDerivedProjectsTask.create = function create(properties) {
            return new GenerateDerivedProjectsTask(properties);
        };

        /**
         * Encodes the specified GenerateDerivedProjectsTask message. Does not implicitly {@link task_service.GenerateDerivedProjectsTask.verify|verify} messages.
         * @function encode
         * @memberof task_service.GenerateDerivedProjectsTask
         * @static
         * @param {task_service.IGenerateDerivedProjectsTask} message GenerateDerivedProjectsTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateDerivedProjectsTask.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectInputId != null && Object.hasOwnProperty.call(message, "projectInputId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.projectInputId);
            if (message.existingProjectId != null && Object.hasOwnProperty.call(message, "existingProjectId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.existingProjectId);
            return writer;
        };

        /**
         * Encodes the specified GenerateDerivedProjectsTask message, length delimited. Does not implicitly {@link task_service.GenerateDerivedProjectsTask.verify|verify} messages.
         * @function encodeDelimited
         * @memberof task_service.GenerateDerivedProjectsTask
         * @static
         * @param {task_service.IGenerateDerivedProjectsTask} message GenerateDerivedProjectsTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateDerivedProjectsTask.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GenerateDerivedProjectsTask message from the specified reader or buffer.
         * @function decode
         * @memberof task_service.GenerateDerivedProjectsTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {task_service.GenerateDerivedProjectsTask} GenerateDerivedProjectsTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateDerivedProjectsTask.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.task_service.GenerateDerivedProjectsTask();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.projectInputId = reader.int32();
                        break;
                    }
                case 2: {
                        message.existingProjectId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GenerateDerivedProjectsTask message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof task_service.GenerateDerivedProjectsTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {task_service.GenerateDerivedProjectsTask} GenerateDerivedProjectsTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateDerivedProjectsTask.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GenerateDerivedProjectsTask message.
         * @function verify
         * @memberof task_service.GenerateDerivedProjectsTask
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GenerateDerivedProjectsTask.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.projectInputId != null && message.hasOwnProperty("projectInputId")) {
                properties._projectInputId = 1;
                if (!$util.isInteger(message.projectInputId))
                    return "projectInputId: integer expected";
            }
            if (message.existingProjectId != null && message.hasOwnProperty("existingProjectId")) {
                properties._existingProjectId = 1;
                if (!$util.isInteger(message.existingProjectId))
                    return "existingProjectId: integer expected";
            }
            return null;
        };

        /**
         * Creates a GenerateDerivedProjectsTask message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof task_service.GenerateDerivedProjectsTask
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {task_service.GenerateDerivedProjectsTask} GenerateDerivedProjectsTask
         */
        GenerateDerivedProjectsTask.fromObject = function fromObject(object) {
            if (object instanceof $root.task_service.GenerateDerivedProjectsTask)
                return object;
            var message = new $root.task_service.GenerateDerivedProjectsTask();
            if (object.projectInputId != null)
                message.projectInputId = object.projectInputId | 0;
            if (object.existingProjectId != null)
                message.existingProjectId = object.existingProjectId | 0;
            return message;
        };

        /**
         * Creates a plain object from a GenerateDerivedProjectsTask message. Also converts values to other types if specified.
         * @function toObject
         * @memberof task_service.GenerateDerivedProjectsTask
         * @static
         * @param {task_service.GenerateDerivedProjectsTask} message GenerateDerivedProjectsTask
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GenerateDerivedProjectsTask.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.projectInputId != null && message.hasOwnProperty("projectInputId")) {
                object.projectInputId = message.projectInputId;
                if (options.oneofs)
                    object._projectInputId = "projectInputId";
            }
            if (message.existingProjectId != null && message.hasOwnProperty("existingProjectId")) {
                object.existingProjectId = message.existingProjectId;
                if (options.oneofs)
                    object._existingProjectId = "existingProjectId";
            }
            return object;
        };

        /**
         * Converts this GenerateDerivedProjectsTask to JSON.
         * @function toJSON
         * @memberof task_service.GenerateDerivedProjectsTask
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GenerateDerivedProjectsTask.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GenerateDerivedProjectsTask
         * @function getTypeUrl
         * @memberof task_service.GenerateDerivedProjectsTask
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GenerateDerivedProjectsTask.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/task_service.GenerateDerivedProjectsTask";
        };

        return GenerateDerivedProjectsTask;
    })();

    return task_service;
})();

$root.user_x_service = (function() {

    /**
     * Namespace user_x_service.
     * @exports user_x_service
     * @namespace
     */
    var user_x_service = {};

    user_x_service.UserXService = (function() {

        /**
         * Constructs a new UserXService service.
         * @memberof user_x_service
         * @classdesc Represents a UserXService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function UserXService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (UserXService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = UserXService;

        /**
         * Creates new UserXService service using the specified rpc implementation.
         * @function create
         * @memberof user_x_service.UserXService
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {UserXService} RPC service. Useful where requests and/or responses are streamed.
         */
        UserXService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link user_x_service.UserXService#upsertUserX}.
         * @memberof user_x_service.UserXService
         * @typedef UpsertUserXCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user_x_service.UpsertUserXResponse} [response] UpsertUserXResponse
         */

        /**
         * Calls UpsertUserX.
         * @function upsertUserX
         * @memberof user_x_service.UserXService
         * @instance
         * @param {user_x_service.IUpsertUserXRequest} request UpsertUserXRequest message or plain object
         * @param {user_x_service.UserXService.UpsertUserXCallback} callback Node-style callback called with the error, if any, and UpsertUserXResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserXService.prototype.upsertUserX = function upsertUserX(request, callback) {
            return this.rpcCall(upsertUserX, $root.user_x_service.UpsertUserXRequest, $root.user_x_service.UpsertUserXResponse, request, callback);
        }, "name", { value: "UpsertUserX" });

        /**
         * Calls UpsertUserX.
         * @function upsertUserX
         * @memberof user_x_service.UserXService
         * @instance
         * @param {user_x_service.IUpsertUserXRequest} request UpsertUserXRequest message or plain object
         * @returns {Promise<user_x_service.UpsertUserXResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user_x_service.UserXService#registerUserX}.
         * @memberof user_x_service.UserXService
         * @typedef RegisterUserXCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user_x_service.RegisterUserXResponse} [response] RegisterUserXResponse
         */

        /**
         * Calls RegisterUserX.
         * @function registerUserX
         * @memberof user_x_service.UserXService
         * @instance
         * @param {user_x_service.IRegisterUserXRequest} request RegisterUserXRequest message or plain object
         * @param {user_x_service.UserXService.RegisterUserXCallback} callback Node-style callback called with the error, if any, and RegisterUserXResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserXService.prototype.registerUserX = function registerUserX(request, callback) {
            return this.rpcCall(registerUserX, $root.user_x_service.RegisterUserXRequest, $root.user_x_service.RegisterUserXResponse, request, callback);
        }, "name", { value: "RegisterUserX" });

        /**
         * Calls RegisterUserX.
         * @function registerUserX
         * @memberof user_x_service.UserXService
         * @instance
         * @param {user_x_service.IRegisterUserXRequest} request RegisterUserXRequest message or plain object
         * @returns {Promise<user_x_service.RegisterUserXResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user_x_service.UserXService#removeUserX}.
         * @memberof user_x_service.UserXService
         * @typedef RemoveUserXCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user_x_service.RemoveUserXResponse} [response] RemoveUserXResponse
         */

        /**
         * Calls RemoveUserX.
         * @function removeUserX
         * @memberof user_x_service.UserXService
         * @instance
         * @param {user_x_service.IRemoveUserXRequest} request RemoveUserXRequest message or plain object
         * @param {user_x_service.UserXService.RemoveUserXCallback} callback Node-style callback called with the error, if any, and RemoveUserXResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserXService.prototype.removeUserX = function removeUserX(request, callback) {
            return this.rpcCall(removeUserX, $root.user_x_service.RemoveUserXRequest, $root.user_x_service.RemoveUserXResponse, request, callback);
        }, "name", { value: "RemoveUserX" });

        /**
         * Calls RemoveUserX.
         * @function removeUserX
         * @memberof user_x_service.UserXService
         * @instance
         * @param {user_x_service.IRemoveUserXRequest} request RemoveUserXRequest message or plain object
         * @returns {Promise<user_x_service.RemoveUserXResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user_x_service.UserXService#getUserXs}.
         * @memberof user_x_service.UserXService
         * @typedef GetUserXsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user_x_service.GetUserXsResponse} [response] GetUserXsResponse
         */

        /**
         * Calls GetUserXs.
         * @function getUserXs
         * @memberof user_x_service.UserXService
         * @instance
         * @param {user_x_service.IGetUserXsRequest} request GetUserXsRequest message or plain object
         * @param {user_x_service.UserXService.GetUserXsCallback} callback Node-style callback called with the error, if any, and GetUserXsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserXService.prototype.getUserXs = function getUserXs(request, callback) {
            return this.rpcCall(getUserXs, $root.user_x_service.GetUserXsRequest, $root.user_x_service.GetUserXsResponse, request, callback);
        }, "name", { value: "GetUserXs" });

        /**
         * Calls GetUserXs.
         * @function getUserXs
         * @memberof user_x_service.UserXService
         * @instance
         * @param {user_x_service.IGetUserXsRequest} request GetUserXsRequest message or plain object
         * @returns {Promise<user_x_service.GetUserXsResponse>} Promise
         * @variation 2
         */

        return UserXService;
    })();

    user_x_service.FullUserXDetails = (function() {

        /**
         * Properties of a FullUserXDetails.
         * @memberof user_x_service
         * @interface IFullUserXDetails
         * @property {pl_types.IUserX|null} [userX] FullUserXDetails userX
         * @property {pl_types.IDistrict|null} [district] FullUserXDetails district
         * @property {Array.<pl_types.ISchool>|null} [schools] FullUserXDetails schools
         * @property {Array.<pl_types.IClassX>|null} [classXs] FullUserXDetails classXs
         * @property {number|null} [districtStudentId] FullUserXDetails districtStudentId
         * @property {number|null} [studentGrade] FullUserXDetails studentGrade
         */

        /**
         * Constructs a new FullUserXDetails.
         * @memberof user_x_service
         * @classdesc Represents a FullUserXDetails.
         * @implements IFullUserXDetails
         * @constructor
         * @param {user_x_service.IFullUserXDetails=} [properties] Properties to set
         */
        function FullUserXDetails(properties) {
            this.schools = [];
            this.classXs = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FullUserXDetails userX.
         * @member {pl_types.IUserX|null|undefined} userX
         * @memberof user_x_service.FullUserXDetails
         * @instance
         */
        FullUserXDetails.prototype.userX = null;

        /**
         * FullUserXDetails district.
         * @member {pl_types.IDistrict|null|undefined} district
         * @memberof user_x_service.FullUserXDetails
         * @instance
         */
        FullUserXDetails.prototype.district = null;

        /**
         * FullUserXDetails schools.
         * @member {Array.<pl_types.ISchool>} schools
         * @memberof user_x_service.FullUserXDetails
         * @instance
         */
        FullUserXDetails.prototype.schools = $util.emptyArray;

        /**
         * FullUserXDetails classXs.
         * @member {Array.<pl_types.IClassX>} classXs
         * @memberof user_x_service.FullUserXDetails
         * @instance
         */
        FullUserXDetails.prototype.classXs = $util.emptyArray;

        /**
         * FullUserXDetails districtStudentId.
         * @member {number|null|undefined} districtStudentId
         * @memberof user_x_service.FullUserXDetails
         * @instance
         */
        FullUserXDetails.prototype.districtStudentId = null;

        /**
         * FullUserXDetails studentGrade.
         * @member {number|null|undefined} studentGrade
         * @memberof user_x_service.FullUserXDetails
         * @instance
         */
        FullUserXDetails.prototype.studentGrade = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * FullUserXDetails _userX.
         * @member {"userX"|undefined} _userX
         * @memberof user_x_service.FullUserXDetails
         * @instance
         */
        Object.defineProperty(FullUserXDetails.prototype, "_userX", {
            get: $util.oneOfGetter($oneOfFields = ["userX"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FullUserXDetails _district.
         * @member {"district"|undefined} _district
         * @memberof user_x_service.FullUserXDetails
         * @instance
         */
        Object.defineProperty(FullUserXDetails.prototype, "_district", {
            get: $util.oneOfGetter($oneOfFields = ["district"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FullUserXDetails _districtStudentId.
         * @member {"districtStudentId"|undefined} _districtStudentId
         * @memberof user_x_service.FullUserXDetails
         * @instance
         */
        Object.defineProperty(FullUserXDetails.prototype, "_districtStudentId", {
            get: $util.oneOfGetter($oneOfFields = ["districtStudentId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FullUserXDetails _studentGrade.
         * @member {"studentGrade"|undefined} _studentGrade
         * @memberof user_x_service.FullUserXDetails
         * @instance
         */
        Object.defineProperty(FullUserXDetails.prototype, "_studentGrade", {
            get: $util.oneOfGetter($oneOfFields = ["studentGrade"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new FullUserXDetails instance using the specified properties.
         * @function create
         * @memberof user_x_service.FullUserXDetails
         * @static
         * @param {user_x_service.IFullUserXDetails=} [properties] Properties to set
         * @returns {user_x_service.FullUserXDetails} FullUserXDetails instance
         */
        FullUserXDetails.create = function create(properties) {
            return new FullUserXDetails(properties);
        };

        /**
         * Encodes the specified FullUserXDetails message. Does not implicitly {@link user_x_service.FullUserXDetails.verify|verify} messages.
         * @function encode
         * @memberof user_x_service.FullUserXDetails
         * @static
         * @param {user_x_service.IFullUserXDetails} message FullUserXDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FullUserXDetails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userX != null && Object.hasOwnProperty.call(message, "userX"))
                $root.pl_types.UserX.encode(message.userX, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.schools != null && message.schools.length)
                for (var i = 0; i < message.schools.length; ++i)
                    $root.pl_types.School.encode(message.schools[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.districtStudentId != null && Object.hasOwnProperty.call(message, "districtStudentId"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.districtStudentId);
            if (message.studentGrade != null && Object.hasOwnProperty.call(message, "studentGrade"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.studentGrade);
            if (message.classXs != null && message.classXs.length)
                for (var i = 0; i < message.classXs.length; ++i)
                    $root.pl_types.ClassX.encode(message.classXs[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.district != null && Object.hasOwnProperty.call(message, "district"))
                $root.pl_types.District.encode(message.district, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified FullUserXDetails message, length delimited. Does not implicitly {@link user_x_service.FullUserXDetails.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user_x_service.FullUserXDetails
         * @static
         * @param {user_x_service.IFullUserXDetails} message FullUserXDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FullUserXDetails.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FullUserXDetails message from the specified reader or buffer.
         * @function decode
         * @memberof user_x_service.FullUserXDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user_x_service.FullUserXDetails} FullUserXDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FullUserXDetails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user_x_service.FullUserXDetails();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.userX = $root.pl_types.UserX.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.district = $root.pl_types.District.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        if (!(message.schools && message.schools.length))
                            message.schools = [];
                        message.schools.push($root.pl_types.School.decode(reader, reader.uint32()));
                        break;
                    }
                case 5: {
                        if (!(message.classXs && message.classXs.length))
                            message.classXs = [];
                        message.classXs.push($root.pl_types.ClassX.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.districtStudentId = reader.int32();
                        break;
                    }
                case 4: {
                        message.studentGrade = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FullUserXDetails message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user_x_service.FullUserXDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user_x_service.FullUserXDetails} FullUserXDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FullUserXDetails.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FullUserXDetails message.
         * @function verify
         * @memberof user_x_service.FullUserXDetails
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FullUserXDetails.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.userX != null && message.hasOwnProperty("userX")) {
                properties._userX = 1;
                {
                    var error = $root.pl_types.UserX.verify(message.userX);
                    if (error)
                        return "userX." + error;
                }
            }
            if (message.district != null && message.hasOwnProperty("district")) {
                properties._district = 1;
                {
                    var error = $root.pl_types.District.verify(message.district);
                    if (error)
                        return "district." + error;
                }
            }
            if (message.schools != null && message.hasOwnProperty("schools")) {
                if (!Array.isArray(message.schools))
                    return "schools: array expected";
                for (var i = 0; i < message.schools.length; ++i) {
                    var error = $root.pl_types.School.verify(message.schools[i]);
                    if (error)
                        return "schools." + error;
                }
            }
            if (message.classXs != null && message.hasOwnProperty("classXs")) {
                if (!Array.isArray(message.classXs))
                    return "classXs: array expected";
                for (var i = 0; i < message.classXs.length; ++i) {
                    var error = $root.pl_types.ClassX.verify(message.classXs[i]);
                    if (error)
                        return "classXs." + error;
                }
            }
            if (message.districtStudentId != null && message.hasOwnProperty("districtStudentId")) {
                properties._districtStudentId = 1;
                if (!$util.isInteger(message.districtStudentId))
                    return "districtStudentId: integer expected";
            }
            if (message.studentGrade != null && message.hasOwnProperty("studentGrade")) {
                properties._studentGrade = 1;
                if (!$util.isInteger(message.studentGrade))
                    return "studentGrade: integer expected";
            }
            return null;
        };

        /**
         * Creates a FullUserXDetails message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user_x_service.FullUserXDetails
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user_x_service.FullUserXDetails} FullUserXDetails
         */
        FullUserXDetails.fromObject = function fromObject(object) {
            if (object instanceof $root.user_x_service.FullUserXDetails)
                return object;
            var message = new $root.user_x_service.FullUserXDetails();
            if (object.userX != null) {
                if (typeof object.userX !== "object")
                    throw TypeError(".user_x_service.FullUserXDetails.userX: object expected");
                message.userX = $root.pl_types.UserX.fromObject(object.userX);
            }
            if (object.district != null) {
                if (typeof object.district !== "object")
                    throw TypeError(".user_x_service.FullUserXDetails.district: object expected");
                message.district = $root.pl_types.District.fromObject(object.district);
            }
            if (object.schools) {
                if (!Array.isArray(object.schools))
                    throw TypeError(".user_x_service.FullUserXDetails.schools: array expected");
                message.schools = [];
                for (var i = 0; i < object.schools.length; ++i) {
                    if (typeof object.schools[i] !== "object")
                        throw TypeError(".user_x_service.FullUserXDetails.schools: object expected");
                    message.schools[i] = $root.pl_types.School.fromObject(object.schools[i]);
                }
            }
            if (object.classXs) {
                if (!Array.isArray(object.classXs))
                    throw TypeError(".user_x_service.FullUserXDetails.classXs: array expected");
                message.classXs = [];
                for (var i = 0; i < object.classXs.length; ++i) {
                    if (typeof object.classXs[i] !== "object")
                        throw TypeError(".user_x_service.FullUserXDetails.classXs: object expected");
                    message.classXs[i] = $root.pl_types.ClassX.fromObject(object.classXs[i]);
                }
            }
            if (object.districtStudentId != null)
                message.districtStudentId = object.districtStudentId | 0;
            if (object.studentGrade != null)
                message.studentGrade = object.studentGrade | 0;
            return message;
        };

        /**
         * Creates a plain object from a FullUserXDetails message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user_x_service.FullUserXDetails
         * @static
         * @param {user_x_service.FullUserXDetails} message FullUserXDetails
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FullUserXDetails.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.schools = [];
                object.classXs = [];
            }
            if (message.userX != null && message.hasOwnProperty("userX")) {
                object.userX = $root.pl_types.UserX.toObject(message.userX, options);
                if (options.oneofs)
                    object._userX = "userX";
            }
            if (message.schools && message.schools.length) {
                object.schools = [];
                for (var j = 0; j < message.schools.length; ++j)
                    object.schools[j] = $root.pl_types.School.toObject(message.schools[j], options);
            }
            if (message.districtStudentId != null && message.hasOwnProperty("districtStudentId")) {
                object.districtStudentId = message.districtStudentId;
                if (options.oneofs)
                    object._districtStudentId = "districtStudentId";
            }
            if (message.studentGrade != null && message.hasOwnProperty("studentGrade")) {
                object.studentGrade = message.studentGrade;
                if (options.oneofs)
                    object._studentGrade = "studentGrade";
            }
            if (message.classXs && message.classXs.length) {
                object.classXs = [];
                for (var j = 0; j < message.classXs.length; ++j)
                    object.classXs[j] = $root.pl_types.ClassX.toObject(message.classXs[j], options);
            }
            if (message.district != null && message.hasOwnProperty("district")) {
                object.district = $root.pl_types.District.toObject(message.district, options);
                if (options.oneofs)
                    object._district = "district";
            }
            return object;
        };

        /**
         * Converts this FullUserXDetails to JSON.
         * @function toJSON
         * @memberof user_x_service.FullUserXDetails
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FullUserXDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FullUserXDetails
         * @function getTypeUrl
         * @memberof user_x_service.FullUserXDetails
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FullUserXDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user_x_service.FullUserXDetails";
        };

        return FullUserXDetails;
    })();

    user_x_service.UpsertUserXRequest = (function() {

        /**
         * Properties of an UpsertUserXRequest.
         * @memberof user_x_service
         * @interface IUpsertUserXRequest
         * @property {user_x_service.IFullUserXDetails|null} [userX] UpsertUserXRequest userX
         * @property {string|null} [currentPassword] UpsertUserXRequest currentPassword
         * @property {string|null} [newPassword] UpsertUserXRequest newPassword
         * @property {string|null} [verifyPassword] UpsertUserXRequest verifyPassword
         */

        /**
         * Constructs a new UpsertUserXRequest.
         * @memberof user_x_service
         * @classdesc Represents an UpsertUserXRequest.
         * @implements IUpsertUserXRequest
         * @constructor
         * @param {user_x_service.IUpsertUserXRequest=} [properties] Properties to set
         */
        function UpsertUserXRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertUserXRequest userX.
         * @member {user_x_service.IFullUserXDetails|null|undefined} userX
         * @memberof user_x_service.UpsertUserXRequest
         * @instance
         */
        UpsertUserXRequest.prototype.userX = null;

        /**
         * UpsertUserXRequest currentPassword.
         * @member {string|null|undefined} currentPassword
         * @memberof user_x_service.UpsertUserXRequest
         * @instance
         */
        UpsertUserXRequest.prototype.currentPassword = null;

        /**
         * UpsertUserXRequest newPassword.
         * @member {string|null|undefined} newPassword
         * @memberof user_x_service.UpsertUserXRequest
         * @instance
         */
        UpsertUserXRequest.prototype.newPassword = null;

        /**
         * UpsertUserXRequest verifyPassword.
         * @member {string|null|undefined} verifyPassword
         * @memberof user_x_service.UpsertUserXRequest
         * @instance
         */
        UpsertUserXRequest.prototype.verifyPassword = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UpsertUserXRequest _userX.
         * @member {"userX"|undefined} _userX
         * @memberof user_x_service.UpsertUserXRequest
         * @instance
         */
        Object.defineProperty(UpsertUserXRequest.prototype, "_userX", {
            get: $util.oneOfGetter($oneOfFields = ["userX"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UpsertUserXRequest _currentPassword.
         * @member {"currentPassword"|undefined} _currentPassword
         * @memberof user_x_service.UpsertUserXRequest
         * @instance
         */
        Object.defineProperty(UpsertUserXRequest.prototype, "_currentPassword", {
            get: $util.oneOfGetter($oneOfFields = ["currentPassword"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UpsertUserXRequest _newPassword.
         * @member {"newPassword"|undefined} _newPassword
         * @memberof user_x_service.UpsertUserXRequest
         * @instance
         */
        Object.defineProperty(UpsertUserXRequest.prototype, "_newPassword", {
            get: $util.oneOfGetter($oneOfFields = ["newPassword"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UpsertUserXRequest _verifyPassword.
         * @member {"verifyPassword"|undefined} _verifyPassword
         * @memberof user_x_service.UpsertUserXRequest
         * @instance
         */
        Object.defineProperty(UpsertUserXRequest.prototype, "_verifyPassword", {
            get: $util.oneOfGetter($oneOfFields = ["verifyPassword"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UpsertUserXRequest instance using the specified properties.
         * @function create
         * @memberof user_x_service.UpsertUserXRequest
         * @static
         * @param {user_x_service.IUpsertUserXRequest=} [properties] Properties to set
         * @returns {user_x_service.UpsertUserXRequest} UpsertUserXRequest instance
         */
        UpsertUserXRequest.create = function create(properties) {
            return new UpsertUserXRequest(properties);
        };

        /**
         * Encodes the specified UpsertUserXRequest message. Does not implicitly {@link user_x_service.UpsertUserXRequest.verify|verify} messages.
         * @function encode
         * @memberof user_x_service.UpsertUserXRequest
         * @static
         * @param {user_x_service.IUpsertUserXRequest} message UpsertUserXRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertUserXRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userX != null && Object.hasOwnProperty.call(message, "userX"))
                $root.user_x_service.FullUserXDetails.encode(message.userX, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.currentPassword != null && Object.hasOwnProperty.call(message, "currentPassword"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.currentPassword);
            if (message.newPassword != null && Object.hasOwnProperty.call(message, "newPassword"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.newPassword);
            if (message.verifyPassword != null && Object.hasOwnProperty.call(message, "verifyPassword"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.verifyPassword);
            return writer;
        };

        /**
         * Encodes the specified UpsertUserXRequest message, length delimited. Does not implicitly {@link user_x_service.UpsertUserXRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user_x_service.UpsertUserXRequest
         * @static
         * @param {user_x_service.IUpsertUserXRequest} message UpsertUserXRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertUserXRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpsertUserXRequest message from the specified reader or buffer.
         * @function decode
         * @memberof user_x_service.UpsertUserXRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user_x_service.UpsertUserXRequest} UpsertUserXRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertUserXRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user_x_service.UpsertUserXRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.userX = $root.user_x_service.FullUserXDetails.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.currentPassword = reader.string();
                        break;
                    }
                case 3: {
                        message.newPassword = reader.string();
                        break;
                    }
                case 4: {
                        message.verifyPassword = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpsertUserXRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user_x_service.UpsertUserXRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user_x_service.UpsertUserXRequest} UpsertUserXRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertUserXRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpsertUserXRequest message.
         * @function verify
         * @memberof user_x_service.UpsertUserXRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpsertUserXRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.userX != null && message.hasOwnProperty("userX")) {
                properties._userX = 1;
                {
                    var error = $root.user_x_service.FullUserXDetails.verify(message.userX);
                    if (error)
                        return "userX." + error;
                }
            }
            if (message.currentPassword != null && message.hasOwnProperty("currentPassword")) {
                properties._currentPassword = 1;
                if (!$util.isString(message.currentPassword))
                    return "currentPassword: string expected";
            }
            if (message.newPassword != null && message.hasOwnProperty("newPassword")) {
                properties._newPassword = 1;
                if (!$util.isString(message.newPassword))
                    return "newPassword: string expected";
            }
            if (message.verifyPassword != null && message.hasOwnProperty("verifyPassword")) {
                properties._verifyPassword = 1;
                if (!$util.isString(message.verifyPassword))
                    return "verifyPassword: string expected";
            }
            return null;
        };

        /**
         * Creates an UpsertUserXRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user_x_service.UpsertUserXRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user_x_service.UpsertUserXRequest} UpsertUserXRequest
         */
        UpsertUserXRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.user_x_service.UpsertUserXRequest)
                return object;
            var message = new $root.user_x_service.UpsertUserXRequest();
            if (object.userX != null) {
                if (typeof object.userX !== "object")
                    throw TypeError(".user_x_service.UpsertUserXRequest.userX: object expected");
                message.userX = $root.user_x_service.FullUserXDetails.fromObject(object.userX);
            }
            if (object.currentPassword != null)
                message.currentPassword = String(object.currentPassword);
            if (object.newPassword != null)
                message.newPassword = String(object.newPassword);
            if (object.verifyPassword != null)
                message.verifyPassword = String(object.verifyPassword);
            return message;
        };

        /**
         * Creates a plain object from an UpsertUserXRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user_x_service.UpsertUserXRequest
         * @static
         * @param {user_x_service.UpsertUserXRequest} message UpsertUserXRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpsertUserXRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.userX != null && message.hasOwnProperty("userX")) {
                object.userX = $root.user_x_service.FullUserXDetails.toObject(message.userX, options);
                if (options.oneofs)
                    object._userX = "userX";
            }
            if (message.currentPassword != null && message.hasOwnProperty("currentPassword")) {
                object.currentPassword = message.currentPassword;
                if (options.oneofs)
                    object._currentPassword = "currentPassword";
            }
            if (message.newPassword != null && message.hasOwnProperty("newPassword")) {
                object.newPassword = message.newPassword;
                if (options.oneofs)
                    object._newPassword = "newPassword";
            }
            if (message.verifyPassword != null && message.hasOwnProperty("verifyPassword")) {
                object.verifyPassword = message.verifyPassword;
                if (options.oneofs)
                    object._verifyPassword = "verifyPassword";
            }
            return object;
        };

        /**
         * Converts this UpsertUserXRequest to JSON.
         * @function toJSON
         * @memberof user_x_service.UpsertUserXRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpsertUserXRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpsertUserXRequest
         * @function getTypeUrl
         * @memberof user_x_service.UpsertUserXRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpsertUserXRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user_x_service.UpsertUserXRequest";
        };

        return UpsertUserXRequest;
    })();

    user_x_service.UpsertUserXResponse = (function() {

        /**
         * Properties of an UpsertUserXResponse.
         * @memberof user_x_service
         * @interface IUpsertUserXResponse
         * @property {user_x_service.IFullUserXDetails|null} [userX] UpsertUserXResponse userX
         * @property {string|null} [error] UpsertUserXResponse error
         */

        /**
         * Constructs a new UpsertUserXResponse.
         * @memberof user_x_service
         * @classdesc Represents an UpsertUserXResponse.
         * @implements IUpsertUserXResponse
         * @constructor
         * @param {user_x_service.IUpsertUserXResponse=} [properties] Properties to set
         */
        function UpsertUserXResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertUserXResponse userX.
         * @member {user_x_service.IFullUserXDetails|null|undefined} userX
         * @memberof user_x_service.UpsertUserXResponse
         * @instance
         */
        UpsertUserXResponse.prototype.userX = null;

        /**
         * UpsertUserXResponse error.
         * @member {string|null|undefined} error
         * @memberof user_x_service.UpsertUserXResponse
         * @instance
         */
        UpsertUserXResponse.prototype.error = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UpsertUserXResponse _userX.
         * @member {"userX"|undefined} _userX
         * @memberof user_x_service.UpsertUserXResponse
         * @instance
         */
        Object.defineProperty(UpsertUserXResponse.prototype, "_userX", {
            get: $util.oneOfGetter($oneOfFields = ["userX"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UpsertUserXResponse _error.
         * @member {"error"|undefined} _error
         * @memberof user_x_service.UpsertUserXResponse
         * @instance
         */
        Object.defineProperty(UpsertUserXResponse.prototype, "_error", {
            get: $util.oneOfGetter($oneOfFields = ["error"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UpsertUserXResponse instance using the specified properties.
         * @function create
         * @memberof user_x_service.UpsertUserXResponse
         * @static
         * @param {user_x_service.IUpsertUserXResponse=} [properties] Properties to set
         * @returns {user_x_service.UpsertUserXResponse} UpsertUserXResponse instance
         */
        UpsertUserXResponse.create = function create(properties) {
            return new UpsertUserXResponse(properties);
        };

        /**
         * Encodes the specified UpsertUserXResponse message. Does not implicitly {@link user_x_service.UpsertUserXResponse.verify|verify} messages.
         * @function encode
         * @memberof user_x_service.UpsertUserXResponse
         * @static
         * @param {user_x_service.IUpsertUserXResponse} message UpsertUserXResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertUserXResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userX != null && Object.hasOwnProperty.call(message, "userX"))
                $root.user_x_service.FullUserXDetails.encode(message.userX, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.error);
            return writer;
        };

        /**
         * Encodes the specified UpsertUserXResponse message, length delimited. Does not implicitly {@link user_x_service.UpsertUserXResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user_x_service.UpsertUserXResponse
         * @static
         * @param {user_x_service.IUpsertUserXResponse} message UpsertUserXResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertUserXResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpsertUserXResponse message from the specified reader or buffer.
         * @function decode
         * @memberof user_x_service.UpsertUserXResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user_x_service.UpsertUserXResponse} UpsertUserXResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertUserXResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user_x_service.UpsertUserXResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.userX = $root.user_x_service.FullUserXDetails.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.error = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpsertUserXResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user_x_service.UpsertUserXResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user_x_service.UpsertUserXResponse} UpsertUserXResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertUserXResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpsertUserXResponse message.
         * @function verify
         * @memberof user_x_service.UpsertUserXResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpsertUserXResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.userX != null && message.hasOwnProperty("userX")) {
                properties._userX = 1;
                {
                    var error = $root.user_x_service.FullUserXDetails.verify(message.userX);
                    if (error)
                        return "userX." + error;
                }
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                properties._error = 1;
                if (!$util.isString(message.error))
                    return "error: string expected";
            }
            return null;
        };

        /**
         * Creates an UpsertUserXResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user_x_service.UpsertUserXResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user_x_service.UpsertUserXResponse} UpsertUserXResponse
         */
        UpsertUserXResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.user_x_service.UpsertUserXResponse)
                return object;
            var message = new $root.user_x_service.UpsertUserXResponse();
            if (object.userX != null) {
                if (typeof object.userX !== "object")
                    throw TypeError(".user_x_service.UpsertUserXResponse.userX: object expected");
                message.userX = $root.user_x_service.FullUserXDetails.fromObject(object.userX);
            }
            if (object.error != null)
                message.error = String(object.error);
            return message;
        };

        /**
         * Creates a plain object from an UpsertUserXResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user_x_service.UpsertUserXResponse
         * @static
         * @param {user_x_service.UpsertUserXResponse} message UpsertUserXResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpsertUserXResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.userX != null && message.hasOwnProperty("userX")) {
                object.userX = $root.user_x_service.FullUserXDetails.toObject(message.userX, options);
                if (options.oneofs)
                    object._userX = "userX";
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                object.error = message.error;
                if (options.oneofs)
                    object._error = "error";
            }
            return object;
        };

        /**
         * Converts this UpsertUserXResponse to JSON.
         * @function toJSON
         * @memberof user_x_service.UpsertUserXResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpsertUserXResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpsertUserXResponse
         * @function getTypeUrl
         * @memberof user_x_service.UpsertUserXResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpsertUserXResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user_x_service.UpsertUserXResponse";
        };

        return UpsertUserXResponse;
    })();

    user_x_service.RegisterUserXRequest = (function() {

        /**
         * Properties of a RegisterUserXRequest.
         * @memberof user_x_service
         * @interface IRegisterUserXRequest
         * @property {string|null} [firstName] RegisterUserXRequest firstName
         * @property {string|null} [lastName] RegisterUserXRequest lastName
         * @property {string|null} [emailAddress] RegisterUserXRequest emailAddress
         * @property {string|null} [password] RegisterUserXRequest password
         * @property {string|null} [verifyPassword] RegisterUserXRequest verifyPassword
         * @property {string|null} [profession] RegisterUserXRequest profession
         * @property {string|null} [reasonForInterest] RegisterUserXRequest reasonForInterest
         * @property {string|null} [districtName] RegisterUserXRequest districtName
         * @property {string|null} [schoolName] RegisterUserXRequest schoolName
         * @property {string|null} [addressLine_1] RegisterUserXRequest addressLine_1
         * @property {string|null} [addressLine_2] RegisterUserXRequest addressLine_2
         * @property {string|null} [city] RegisterUserXRequest city
         * @property {string|null} [state] RegisterUserXRequest state
         * @property {string|null} [zipCode] RegisterUserXRequest zipCode
         * @property {number|null} [numTeachers] RegisterUserXRequest numTeachers
         * @property {number|null} [numStudents] RegisterUserXRequest numStudents
         */

        /**
         * Constructs a new RegisterUserXRequest.
         * @memberof user_x_service
         * @classdesc Represents a RegisterUserXRequest.
         * @implements IRegisterUserXRequest
         * @constructor
         * @param {user_x_service.IRegisterUserXRequest=} [properties] Properties to set
         */
        function RegisterUserXRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RegisterUserXRequest firstName.
         * @member {string|null|undefined} firstName
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        RegisterUserXRequest.prototype.firstName = null;

        /**
         * RegisterUserXRequest lastName.
         * @member {string|null|undefined} lastName
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        RegisterUserXRequest.prototype.lastName = null;

        /**
         * RegisterUserXRequest emailAddress.
         * @member {string|null|undefined} emailAddress
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        RegisterUserXRequest.prototype.emailAddress = null;

        /**
         * RegisterUserXRequest password.
         * @member {string|null|undefined} password
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        RegisterUserXRequest.prototype.password = null;

        /**
         * RegisterUserXRequest verifyPassword.
         * @member {string|null|undefined} verifyPassword
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        RegisterUserXRequest.prototype.verifyPassword = null;

        /**
         * RegisterUserXRequest profession.
         * @member {string|null|undefined} profession
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        RegisterUserXRequest.prototype.profession = null;

        /**
         * RegisterUserXRequest reasonForInterest.
         * @member {string|null|undefined} reasonForInterest
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        RegisterUserXRequest.prototype.reasonForInterest = null;

        /**
         * RegisterUserXRequest districtName.
         * @member {string|null|undefined} districtName
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        RegisterUserXRequest.prototype.districtName = null;

        /**
         * RegisterUserXRequest schoolName.
         * @member {string|null|undefined} schoolName
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        RegisterUserXRequest.prototype.schoolName = null;

        /**
         * RegisterUserXRequest addressLine_1.
         * @member {string|null|undefined} addressLine_1
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        RegisterUserXRequest.prototype.addressLine_1 = null;

        /**
         * RegisterUserXRequest addressLine_2.
         * @member {string|null|undefined} addressLine_2
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        RegisterUserXRequest.prototype.addressLine_2 = null;

        /**
         * RegisterUserXRequest city.
         * @member {string|null|undefined} city
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        RegisterUserXRequest.prototype.city = null;

        /**
         * RegisterUserXRequest state.
         * @member {string|null|undefined} state
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        RegisterUserXRequest.prototype.state = null;

        /**
         * RegisterUserXRequest zipCode.
         * @member {string|null|undefined} zipCode
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        RegisterUserXRequest.prototype.zipCode = null;

        /**
         * RegisterUserXRequest numTeachers.
         * @member {number|null|undefined} numTeachers
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        RegisterUserXRequest.prototype.numTeachers = null;

        /**
         * RegisterUserXRequest numStudents.
         * @member {number|null|undefined} numStudents
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        RegisterUserXRequest.prototype.numStudents = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * RegisterUserXRequest _firstName.
         * @member {"firstName"|undefined} _firstName
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        Object.defineProperty(RegisterUserXRequest.prototype, "_firstName", {
            get: $util.oneOfGetter($oneOfFields = ["firstName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * RegisterUserXRequest _lastName.
         * @member {"lastName"|undefined} _lastName
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        Object.defineProperty(RegisterUserXRequest.prototype, "_lastName", {
            get: $util.oneOfGetter($oneOfFields = ["lastName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * RegisterUserXRequest _emailAddress.
         * @member {"emailAddress"|undefined} _emailAddress
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        Object.defineProperty(RegisterUserXRequest.prototype, "_emailAddress", {
            get: $util.oneOfGetter($oneOfFields = ["emailAddress"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * RegisterUserXRequest _password.
         * @member {"password"|undefined} _password
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        Object.defineProperty(RegisterUserXRequest.prototype, "_password", {
            get: $util.oneOfGetter($oneOfFields = ["password"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * RegisterUserXRequest _verifyPassword.
         * @member {"verifyPassword"|undefined} _verifyPassword
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        Object.defineProperty(RegisterUserXRequest.prototype, "_verifyPassword", {
            get: $util.oneOfGetter($oneOfFields = ["verifyPassword"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * RegisterUserXRequest _profession.
         * @member {"profession"|undefined} _profession
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        Object.defineProperty(RegisterUserXRequest.prototype, "_profession", {
            get: $util.oneOfGetter($oneOfFields = ["profession"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * RegisterUserXRequest _reasonForInterest.
         * @member {"reasonForInterest"|undefined} _reasonForInterest
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        Object.defineProperty(RegisterUserXRequest.prototype, "_reasonForInterest", {
            get: $util.oneOfGetter($oneOfFields = ["reasonForInterest"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * RegisterUserXRequest _districtName.
         * @member {"districtName"|undefined} _districtName
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        Object.defineProperty(RegisterUserXRequest.prototype, "_districtName", {
            get: $util.oneOfGetter($oneOfFields = ["districtName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * RegisterUserXRequest _schoolName.
         * @member {"schoolName"|undefined} _schoolName
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        Object.defineProperty(RegisterUserXRequest.prototype, "_schoolName", {
            get: $util.oneOfGetter($oneOfFields = ["schoolName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * RegisterUserXRequest _addressLine_1.
         * @member {"addressLine_1"|undefined} _addressLine_1
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        Object.defineProperty(RegisterUserXRequest.prototype, "_addressLine_1", {
            get: $util.oneOfGetter($oneOfFields = ["addressLine_1"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * RegisterUserXRequest _addressLine_2.
         * @member {"addressLine_2"|undefined} _addressLine_2
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        Object.defineProperty(RegisterUserXRequest.prototype, "_addressLine_2", {
            get: $util.oneOfGetter($oneOfFields = ["addressLine_2"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * RegisterUserXRequest _city.
         * @member {"city"|undefined} _city
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        Object.defineProperty(RegisterUserXRequest.prototype, "_city", {
            get: $util.oneOfGetter($oneOfFields = ["city"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * RegisterUserXRequest _state.
         * @member {"state"|undefined} _state
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        Object.defineProperty(RegisterUserXRequest.prototype, "_state", {
            get: $util.oneOfGetter($oneOfFields = ["state"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * RegisterUserXRequest _zipCode.
         * @member {"zipCode"|undefined} _zipCode
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        Object.defineProperty(RegisterUserXRequest.prototype, "_zipCode", {
            get: $util.oneOfGetter($oneOfFields = ["zipCode"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * RegisterUserXRequest _numTeachers.
         * @member {"numTeachers"|undefined} _numTeachers
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        Object.defineProperty(RegisterUserXRequest.prototype, "_numTeachers", {
            get: $util.oneOfGetter($oneOfFields = ["numTeachers"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * RegisterUserXRequest _numStudents.
         * @member {"numStudents"|undefined} _numStudents
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         */
        Object.defineProperty(RegisterUserXRequest.prototype, "_numStudents", {
            get: $util.oneOfGetter($oneOfFields = ["numStudents"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new RegisterUserXRequest instance using the specified properties.
         * @function create
         * @memberof user_x_service.RegisterUserXRequest
         * @static
         * @param {user_x_service.IRegisterUserXRequest=} [properties] Properties to set
         * @returns {user_x_service.RegisterUserXRequest} RegisterUserXRequest instance
         */
        RegisterUserXRequest.create = function create(properties) {
            return new RegisterUserXRequest(properties);
        };

        /**
         * Encodes the specified RegisterUserXRequest message. Does not implicitly {@link user_x_service.RegisterUserXRequest.verify|verify} messages.
         * @function encode
         * @memberof user_x_service.RegisterUserXRequest
         * @static
         * @param {user_x_service.IRegisterUserXRequest} message RegisterUserXRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegisterUserXRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastName);
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.emailAddress);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.password);
            if (message.verifyPassword != null && Object.hasOwnProperty.call(message, "verifyPassword"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.verifyPassword);
            if (message.profession != null && Object.hasOwnProperty.call(message, "profession"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.profession);
            if (message.reasonForInterest != null && Object.hasOwnProperty.call(message, "reasonForInterest"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.reasonForInterest);
            if (message.districtName != null && Object.hasOwnProperty.call(message, "districtName"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.districtName);
            if (message.schoolName != null && Object.hasOwnProperty.call(message, "schoolName"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.schoolName);
            if (message.addressLine_1 != null && Object.hasOwnProperty.call(message, "addressLine_1"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.addressLine_1);
            if (message.addressLine_2 != null && Object.hasOwnProperty.call(message, "addressLine_2"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.addressLine_2);
            if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.city);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.state);
            if (message.zipCode != null && Object.hasOwnProperty.call(message, "zipCode"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.zipCode);
            if (message.numTeachers != null && Object.hasOwnProperty.call(message, "numTeachers"))
                writer.uint32(/* id 16, wireType 0 =*/128).int32(message.numTeachers);
            if (message.numStudents != null && Object.hasOwnProperty.call(message, "numStudents"))
                writer.uint32(/* id 17, wireType 0 =*/136).int32(message.numStudents);
            return writer;
        };

        /**
         * Encodes the specified RegisterUserXRequest message, length delimited. Does not implicitly {@link user_x_service.RegisterUserXRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user_x_service.RegisterUserXRequest
         * @static
         * @param {user_x_service.IRegisterUserXRequest} message RegisterUserXRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegisterUserXRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RegisterUserXRequest message from the specified reader or buffer.
         * @function decode
         * @memberof user_x_service.RegisterUserXRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user_x_service.RegisterUserXRequest} RegisterUserXRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegisterUserXRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user_x_service.RegisterUserXRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.firstName = reader.string();
                        break;
                    }
                case 3: {
                        message.lastName = reader.string();
                        break;
                    }
                case 4: {
                        message.emailAddress = reader.string();
                        break;
                    }
                case 5: {
                        message.password = reader.string();
                        break;
                    }
                case 6: {
                        message.verifyPassword = reader.string();
                        break;
                    }
                case 7: {
                        message.profession = reader.string();
                        break;
                    }
                case 8: {
                        message.reasonForInterest = reader.string();
                        break;
                    }
                case 9: {
                        message.districtName = reader.string();
                        break;
                    }
                case 10: {
                        message.schoolName = reader.string();
                        break;
                    }
                case 11: {
                        message.addressLine_1 = reader.string();
                        break;
                    }
                case 12: {
                        message.addressLine_2 = reader.string();
                        break;
                    }
                case 13: {
                        message.city = reader.string();
                        break;
                    }
                case 14: {
                        message.state = reader.string();
                        break;
                    }
                case 15: {
                        message.zipCode = reader.string();
                        break;
                    }
                case 16: {
                        message.numTeachers = reader.int32();
                        break;
                    }
                case 17: {
                        message.numStudents = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RegisterUserXRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user_x_service.RegisterUserXRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user_x_service.RegisterUserXRequest} RegisterUserXRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegisterUserXRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RegisterUserXRequest message.
         * @function verify
         * @memberof user_x_service.RegisterUserXRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RegisterUserXRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.firstName != null && message.hasOwnProperty("firstName")) {
                properties._firstName = 1;
                if (!$util.isString(message.firstName))
                    return "firstName: string expected";
            }
            if (message.lastName != null && message.hasOwnProperty("lastName")) {
                properties._lastName = 1;
                if (!$util.isString(message.lastName))
                    return "lastName: string expected";
            }
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress")) {
                properties._emailAddress = 1;
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            }
            if (message.password != null && message.hasOwnProperty("password")) {
                properties._password = 1;
                if (!$util.isString(message.password))
                    return "password: string expected";
            }
            if (message.verifyPassword != null && message.hasOwnProperty("verifyPassword")) {
                properties._verifyPassword = 1;
                if (!$util.isString(message.verifyPassword))
                    return "verifyPassword: string expected";
            }
            if (message.profession != null && message.hasOwnProperty("profession")) {
                properties._profession = 1;
                if (!$util.isString(message.profession))
                    return "profession: string expected";
            }
            if (message.reasonForInterest != null && message.hasOwnProperty("reasonForInterest")) {
                properties._reasonForInterest = 1;
                if (!$util.isString(message.reasonForInterest))
                    return "reasonForInterest: string expected";
            }
            if (message.districtName != null && message.hasOwnProperty("districtName")) {
                properties._districtName = 1;
                if (!$util.isString(message.districtName))
                    return "districtName: string expected";
            }
            if (message.schoolName != null && message.hasOwnProperty("schoolName")) {
                properties._schoolName = 1;
                if (!$util.isString(message.schoolName))
                    return "schoolName: string expected";
            }
            if (message.addressLine_1 != null && message.hasOwnProperty("addressLine_1")) {
                properties._addressLine_1 = 1;
                if (!$util.isString(message.addressLine_1))
                    return "addressLine_1: string expected";
            }
            if (message.addressLine_2 != null && message.hasOwnProperty("addressLine_2")) {
                properties._addressLine_2 = 1;
                if (!$util.isString(message.addressLine_2))
                    return "addressLine_2: string expected";
            }
            if (message.city != null && message.hasOwnProperty("city")) {
                properties._city = 1;
                if (!$util.isString(message.city))
                    return "city: string expected";
            }
            if (message.state != null && message.hasOwnProperty("state")) {
                properties._state = 1;
                if (!$util.isString(message.state))
                    return "state: string expected";
            }
            if (message.zipCode != null && message.hasOwnProperty("zipCode")) {
                properties._zipCode = 1;
                if (!$util.isString(message.zipCode))
                    return "zipCode: string expected";
            }
            if (message.numTeachers != null && message.hasOwnProperty("numTeachers")) {
                properties._numTeachers = 1;
                if (!$util.isInteger(message.numTeachers))
                    return "numTeachers: integer expected";
            }
            if (message.numStudents != null && message.hasOwnProperty("numStudents")) {
                properties._numStudents = 1;
                if (!$util.isInteger(message.numStudents))
                    return "numStudents: integer expected";
            }
            return null;
        };

        /**
         * Creates a RegisterUserXRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user_x_service.RegisterUserXRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user_x_service.RegisterUserXRequest} RegisterUserXRequest
         */
        RegisterUserXRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.user_x_service.RegisterUserXRequest)
                return object;
            var message = new $root.user_x_service.RegisterUserXRequest();
            if (object.firstName != null)
                message.firstName = String(object.firstName);
            if (object.lastName != null)
                message.lastName = String(object.lastName);
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            if (object.password != null)
                message.password = String(object.password);
            if (object.verifyPassword != null)
                message.verifyPassword = String(object.verifyPassword);
            if (object.profession != null)
                message.profession = String(object.profession);
            if (object.reasonForInterest != null)
                message.reasonForInterest = String(object.reasonForInterest);
            if (object.districtName != null)
                message.districtName = String(object.districtName);
            if (object.schoolName != null)
                message.schoolName = String(object.schoolName);
            if (object.addressLine_1 != null)
                message.addressLine_1 = String(object.addressLine_1);
            if (object.addressLine_2 != null)
                message.addressLine_2 = String(object.addressLine_2);
            if (object.city != null)
                message.city = String(object.city);
            if (object.state != null)
                message.state = String(object.state);
            if (object.zipCode != null)
                message.zipCode = String(object.zipCode);
            if (object.numTeachers != null)
                message.numTeachers = object.numTeachers | 0;
            if (object.numStudents != null)
                message.numStudents = object.numStudents | 0;
            return message;
        };

        /**
         * Creates a plain object from a RegisterUserXRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user_x_service.RegisterUserXRequest
         * @static
         * @param {user_x_service.RegisterUserXRequest} message RegisterUserXRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RegisterUserXRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.firstName != null && message.hasOwnProperty("firstName")) {
                object.firstName = message.firstName;
                if (options.oneofs)
                    object._firstName = "firstName";
            }
            if (message.lastName != null && message.hasOwnProperty("lastName")) {
                object.lastName = message.lastName;
                if (options.oneofs)
                    object._lastName = "lastName";
            }
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress")) {
                object.emailAddress = message.emailAddress;
                if (options.oneofs)
                    object._emailAddress = "emailAddress";
            }
            if (message.password != null && message.hasOwnProperty("password")) {
                object.password = message.password;
                if (options.oneofs)
                    object._password = "password";
            }
            if (message.verifyPassword != null && message.hasOwnProperty("verifyPassword")) {
                object.verifyPassword = message.verifyPassword;
                if (options.oneofs)
                    object._verifyPassword = "verifyPassword";
            }
            if (message.profession != null && message.hasOwnProperty("profession")) {
                object.profession = message.profession;
                if (options.oneofs)
                    object._profession = "profession";
            }
            if (message.reasonForInterest != null && message.hasOwnProperty("reasonForInterest")) {
                object.reasonForInterest = message.reasonForInterest;
                if (options.oneofs)
                    object._reasonForInterest = "reasonForInterest";
            }
            if (message.districtName != null && message.hasOwnProperty("districtName")) {
                object.districtName = message.districtName;
                if (options.oneofs)
                    object._districtName = "districtName";
            }
            if (message.schoolName != null && message.hasOwnProperty("schoolName")) {
                object.schoolName = message.schoolName;
                if (options.oneofs)
                    object._schoolName = "schoolName";
            }
            if (message.addressLine_1 != null && message.hasOwnProperty("addressLine_1")) {
                object.addressLine_1 = message.addressLine_1;
                if (options.oneofs)
                    object._addressLine_1 = "addressLine_1";
            }
            if (message.addressLine_2 != null && message.hasOwnProperty("addressLine_2")) {
                object.addressLine_2 = message.addressLine_2;
                if (options.oneofs)
                    object._addressLine_2 = "addressLine_2";
            }
            if (message.city != null && message.hasOwnProperty("city")) {
                object.city = message.city;
                if (options.oneofs)
                    object._city = "city";
            }
            if (message.state != null && message.hasOwnProperty("state")) {
                object.state = message.state;
                if (options.oneofs)
                    object._state = "state";
            }
            if (message.zipCode != null && message.hasOwnProperty("zipCode")) {
                object.zipCode = message.zipCode;
                if (options.oneofs)
                    object._zipCode = "zipCode";
            }
            if (message.numTeachers != null && message.hasOwnProperty("numTeachers")) {
                object.numTeachers = message.numTeachers;
                if (options.oneofs)
                    object._numTeachers = "numTeachers";
            }
            if (message.numStudents != null && message.hasOwnProperty("numStudents")) {
                object.numStudents = message.numStudents;
                if (options.oneofs)
                    object._numStudents = "numStudents";
            }
            return object;
        };

        /**
         * Converts this RegisterUserXRequest to JSON.
         * @function toJSON
         * @memberof user_x_service.RegisterUserXRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RegisterUserXRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RegisterUserXRequest
         * @function getTypeUrl
         * @memberof user_x_service.RegisterUserXRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RegisterUserXRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user_x_service.RegisterUserXRequest";
        };

        return RegisterUserXRequest;
    })();

    user_x_service.RegisterUserXResponse = (function() {

        /**
         * Properties of a RegisterUserXResponse.
         * @memberof user_x_service
         * @interface IRegisterUserXResponse
         * @property {boolean|null} [accountAlreadyExists] RegisterUserXResponse accountAlreadyExists
         * @property {string|null} [error] RegisterUserXResponse error
         */

        /**
         * Constructs a new RegisterUserXResponse.
         * @memberof user_x_service
         * @classdesc Represents a RegisterUserXResponse.
         * @implements IRegisterUserXResponse
         * @constructor
         * @param {user_x_service.IRegisterUserXResponse=} [properties] Properties to set
         */
        function RegisterUserXResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RegisterUserXResponse accountAlreadyExists.
         * @member {boolean|null|undefined} accountAlreadyExists
         * @memberof user_x_service.RegisterUserXResponse
         * @instance
         */
        RegisterUserXResponse.prototype.accountAlreadyExists = null;

        /**
         * RegisterUserXResponse error.
         * @member {string|null|undefined} error
         * @memberof user_x_service.RegisterUserXResponse
         * @instance
         */
        RegisterUserXResponse.prototype.error = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * RegisterUserXResponse _accountAlreadyExists.
         * @member {"accountAlreadyExists"|undefined} _accountAlreadyExists
         * @memberof user_x_service.RegisterUserXResponse
         * @instance
         */
        Object.defineProperty(RegisterUserXResponse.prototype, "_accountAlreadyExists", {
            get: $util.oneOfGetter($oneOfFields = ["accountAlreadyExists"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * RegisterUserXResponse _error.
         * @member {"error"|undefined} _error
         * @memberof user_x_service.RegisterUserXResponse
         * @instance
         */
        Object.defineProperty(RegisterUserXResponse.prototype, "_error", {
            get: $util.oneOfGetter($oneOfFields = ["error"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new RegisterUserXResponse instance using the specified properties.
         * @function create
         * @memberof user_x_service.RegisterUserXResponse
         * @static
         * @param {user_x_service.IRegisterUserXResponse=} [properties] Properties to set
         * @returns {user_x_service.RegisterUserXResponse} RegisterUserXResponse instance
         */
        RegisterUserXResponse.create = function create(properties) {
            return new RegisterUserXResponse(properties);
        };

        /**
         * Encodes the specified RegisterUserXResponse message. Does not implicitly {@link user_x_service.RegisterUserXResponse.verify|verify} messages.
         * @function encode
         * @memberof user_x_service.RegisterUserXResponse
         * @static
         * @param {user_x_service.IRegisterUserXResponse} message RegisterUserXResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegisterUserXResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.accountAlreadyExists != null && Object.hasOwnProperty.call(message, "accountAlreadyExists"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.accountAlreadyExists);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
            return writer;
        };

        /**
         * Encodes the specified RegisterUserXResponse message, length delimited. Does not implicitly {@link user_x_service.RegisterUserXResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user_x_service.RegisterUserXResponse
         * @static
         * @param {user_x_service.IRegisterUserXResponse} message RegisterUserXResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegisterUserXResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RegisterUserXResponse message from the specified reader or buffer.
         * @function decode
         * @memberof user_x_service.RegisterUserXResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user_x_service.RegisterUserXResponse} RegisterUserXResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegisterUserXResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user_x_service.RegisterUserXResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.accountAlreadyExists = reader.bool();
                        break;
                    }
                case 2: {
                        message.error = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RegisterUserXResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user_x_service.RegisterUserXResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user_x_service.RegisterUserXResponse} RegisterUserXResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegisterUserXResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RegisterUserXResponse message.
         * @function verify
         * @memberof user_x_service.RegisterUserXResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RegisterUserXResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.accountAlreadyExists != null && message.hasOwnProperty("accountAlreadyExists")) {
                properties._accountAlreadyExists = 1;
                if (typeof message.accountAlreadyExists !== "boolean")
                    return "accountAlreadyExists: boolean expected";
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                properties._error = 1;
                if (!$util.isString(message.error))
                    return "error: string expected";
            }
            return null;
        };

        /**
         * Creates a RegisterUserXResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user_x_service.RegisterUserXResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user_x_service.RegisterUserXResponse} RegisterUserXResponse
         */
        RegisterUserXResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.user_x_service.RegisterUserXResponse)
                return object;
            var message = new $root.user_x_service.RegisterUserXResponse();
            if (object.accountAlreadyExists != null)
                message.accountAlreadyExists = Boolean(object.accountAlreadyExists);
            if (object.error != null)
                message.error = String(object.error);
            return message;
        };

        /**
         * Creates a plain object from a RegisterUserXResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user_x_service.RegisterUserXResponse
         * @static
         * @param {user_x_service.RegisterUserXResponse} message RegisterUserXResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RegisterUserXResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.accountAlreadyExists != null && message.hasOwnProperty("accountAlreadyExists")) {
                object.accountAlreadyExists = message.accountAlreadyExists;
                if (options.oneofs)
                    object._accountAlreadyExists = "accountAlreadyExists";
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                object.error = message.error;
                if (options.oneofs)
                    object._error = "error";
            }
            return object;
        };

        /**
         * Converts this RegisterUserXResponse to JSON.
         * @function toJSON
         * @memberof user_x_service.RegisterUserXResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RegisterUserXResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RegisterUserXResponse
         * @function getTypeUrl
         * @memberof user_x_service.RegisterUserXResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RegisterUserXResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user_x_service.RegisterUserXResponse";
        };

        return RegisterUserXResponse;
    })();

    user_x_service.RemoveUserXRequest = (function() {

        /**
         * Properties of a RemoveUserXRequest.
         * @memberof user_x_service
         * @interface IRemoveUserXRequest
         * @property {number|null} [userXId] RemoveUserXRequest userXId
         */

        /**
         * Constructs a new RemoveUserXRequest.
         * @memberof user_x_service
         * @classdesc Represents a RemoveUserXRequest.
         * @implements IRemoveUserXRequest
         * @constructor
         * @param {user_x_service.IRemoveUserXRequest=} [properties] Properties to set
         */
        function RemoveUserXRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RemoveUserXRequest userXId.
         * @member {number|null|undefined} userXId
         * @memberof user_x_service.RemoveUserXRequest
         * @instance
         */
        RemoveUserXRequest.prototype.userXId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * RemoveUserXRequest _userXId.
         * @member {"userXId"|undefined} _userXId
         * @memberof user_x_service.RemoveUserXRequest
         * @instance
         */
        Object.defineProperty(RemoveUserXRequest.prototype, "_userXId", {
            get: $util.oneOfGetter($oneOfFields = ["userXId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new RemoveUserXRequest instance using the specified properties.
         * @function create
         * @memberof user_x_service.RemoveUserXRequest
         * @static
         * @param {user_x_service.IRemoveUserXRequest=} [properties] Properties to set
         * @returns {user_x_service.RemoveUserXRequest} RemoveUserXRequest instance
         */
        RemoveUserXRequest.create = function create(properties) {
            return new RemoveUserXRequest(properties);
        };

        /**
         * Encodes the specified RemoveUserXRequest message. Does not implicitly {@link user_x_service.RemoveUserXRequest.verify|verify} messages.
         * @function encode
         * @memberof user_x_service.RemoveUserXRequest
         * @static
         * @param {user_x_service.IRemoveUserXRequest} message RemoveUserXRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveUserXRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userXId != null && Object.hasOwnProperty.call(message, "userXId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.userXId);
            return writer;
        };

        /**
         * Encodes the specified RemoveUserXRequest message, length delimited. Does not implicitly {@link user_x_service.RemoveUserXRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user_x_service.RemoveUserXRequest
         * @static
         * @param {user_x_service.IRemoveUserXRequest} message RemoveUserXRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveUserXRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RemoveUserXRequest message from the specified reader or buffer.
         * @function decode
         * @memberof user_x_service.RemoveUserXRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user_x_service.RemoveUserXRequest} RemoveUserXRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveUserXRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user_x_service.RemoveUserXRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.userXId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RemoveUserXRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user_x_service.RemoveUserXRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user_x_service.RemoveUserXRequest} RemoveUserXRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveUserXRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RemoveUserXRequest message.
         * @function verify
         * @memberof user_x_service.RemoveUserXRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RemoveUserXRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.userXId != null && message.hasOwnProperty("userXId")) {
                properties._userXId = 1;
                if (!$util.isInteger(message.userXId))
                    return "userXId: integer expected";
            }
            return null;
        };

        /**
         * Creates a RemoveUserXRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user_x_service.RemoveUserXRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user_x_service.RemoveUserXRequest} RemoveUserXRequest
         */
        RemoveUserXRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.user_x_service.RemoveUserXRequest)
                return object;
            var message = new $root.user_x_service.RemoveUserXRequest();
            if (object.userXId != null)
                message.userXId = object.userXId | 0;
            return message;
        };

        /**
         * Creates a plain object from a RemoveUserXRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user_x_service.RemoveUserXRequest
         * @static
         * @param {user_x_service.RemoveUserXRequest} message RemoveUserXRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RemoveUserXRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.userXId != null && message.hasOwnProperty("userXId")) {
                object.userXId = message.userXId;
                if (options.oneofs)
                    object._userXId = "userXId";
            }
            return object;
        };

        /**
         * Converts this RemoveUserXRequest to JSON.
         * @function toJSON
         * @memberof user_x_service.RemoveUserXRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RemoveUserXRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RemoveUserXRequest
         * @function getTypeUrl
         * @memberof user_x_service.RemoveUserXRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RemoveUserXRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user_x_service.RemoveUserXRequest";
        };

        return RemoveUserXRequest;
    })();

    user_x_service.RemoveUserXResponse = (function() {

        /**
         * Properties of a RemoveUserXResponse.
         * @memberof user_x_service
         * @interface IRemoveUserXResponse
         */

        /**
         * Constructs a new RemoveUserXResponse.
         * @memberof user_x_service
         * @classdesc Represents a RemoveUserXResponse.
         * @implements IRemoveUserXResponse
         * @constructor
         * @param {user_x_service.IRemoveUserXResponse=} [properties] Properties to set
         */
        function RemoveUserXResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RemoveUserXResponse instance using the specified properties.
         * @function create
         * @memberof user_x_service.RemoveUserXResponse
         * @static
         * @param {user_x_service.IRemoveUserXResponse=} [properties] Properties to set
         * @returns {user_x_service.RemoveUserXResponse} RemoveUserXResponse instance
         */
        RemoveUserXResponse.create = function create(properties) {
            return new RemoveUserXResponse(properties);
        };

        /**
         * Encodes the specified RemoveUserXResponse message. Does not implicitly {@link user_x_service.RemoveUserXResponse.verify|verify} messages.
         * @function encode
         * @memberof user_x_service.RemoveUserXResponse
         * @static
         * @param {user_x_service.IRemoveUserXResponse} message RemoveUserXResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveUserXResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RemoveUserXResponse message, length delimited. Does not implicitly {@link user_x_service.RemoveUserXResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user_x_service.RemoveUserXResponse
         * @static
         * @param {user_x_service.IRemoveUserXResponse} message RemoveUserXResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveUserXResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RemoveUserXResponse message from the specified reader or buffer.
         * @function decode
         * @memberof user_x_service.RemoveUserXResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user_x_service.RemoveUserXResponse} RemoveUserXResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveUserXResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user_x_service.RemoveUserXResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RemoveUserXResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user_x_service.RemoveUserXResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user_x_service.RemoveUserXResponse} RemoveUserXResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveUserXResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RemoveUserXResponse message.
         * @function verify
         * @memberof user_x_service.RemoveUserXResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RemoveUserXResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RemoveUserXResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user_x_service.RemoveUserXResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user_x_service.RemoveUserXResponse} RemoveUserXResponse
         */
        RemoveUserXResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.user_x_service.RemoveUserXResponse)
                return object;
            return new $root.user_x_service.RemoveUserXResponse();
        };

        /**
         * Creates a plain object from a RemoveUserXResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user_x_service.RemoveUserXResponse
         * @static
         * @param {user_x_service.RemoveUserXResponse} message RemoveUserXResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RemoveUserXResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RemoveUserXResponse to JSON.
         * @function toJSON
         * @memberof user_x_service.RemoveUserXResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RemoveUserXResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RemoveUserXResponse
         * @function getTypeUrl
         * @memberof user_x_service.RemoveUserXResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RemoveUserXResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user_x_service.RemoveUserXResponse";
        };

        return RemoveUserXResponse;
    })();

    user_x_service.GetUserXsRequest = (function() {

        /**
         * Properties of a GetUserXsRequest.
         * @memberof user_x_service
         * @interface IGetUserXsRequest
         * @property {boolean|null} [includeSchools] GetUserXsRequest includeSchools
         * @property {boolean|null} [includeClassXs] GetUserXsRequest includeClassXs
         * @property {boolean|null} [ofSelf] GetUserXsRequest ofSelf
         * @property {Array.<number>|null} [inDistrictIds] GetUserXsRequest inDistrictIds
         * @property {Array.<number>|null} [inUserXIds] GetUserXsRequest inUserXIds
         * @property {Array.<number>|null} [inSchoolIds] GetUserXsRequest inSchoolIds
         * @property {Array.<number>|null} [inClassXIds] GetUserXsRequest inClassXIds
         * @property {string|null} [hasEmailAddress] GetUserXsRequest hasEmailAddress
         * @property {boolean|null} [adminXsOnly] GetUserXsRequest adminXsOnly
         * @property {boolean|null} [teachersOnly] GetUserXsRequest teachersOnly
         * @property {boolean|null} [studentsOnly] GetUserXsRequest studentsOnly
         * @property {string|null} [firstLastEmailSearchText] GetUserXsRequest firstLastEmailSearchText
         * @property {number|null} [page] GetUserXsRequest page
         * @property {number|null} [pageSize] GetUserXsRequest pageSize
         */

        /**
         * Constructs a new GetUserXsRequest.
         * @memberof user_x_service
         * @classdesc Represents a GetUserXsRequest.
         * @implements IGetUserXsRequest
         * @constructor
         * @param {user_x_service.IGetUserXsRequest=} [properties] Properties to set
         */
        function GetUserXsRequest(properties) {
            this.inDistrictIds = [];
            this.inUserXIds = [];
            this.inSchoolIds = [];
            this.inClassXIds = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetUserXsRequest includeSchools.
         * @member {boolean|null|undefined} includeSchools
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        GetUserXsRequest.prototype.includeSchools = null;

        /**
         * GetUserXsRequest includeClassXs.
         * @member {boolean|null|undefined} includeClassXs
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        GetUserXsRequest.prototype.includeClassXs = null;

        /**
         * GetUserXsRequest ofSelf.
         * @member {boolean|null|undefined} ofSelf
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        GetUserXsRequest.prototype.ofSelf = null;

        /**
         * GetUserXsRequest inDistrictIds.
         * @member {Array.<number>} inDistrictIds
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        GetUserXsRequest.prototype.inDistrictIds = $util.emptyArray;

        /**
         * GetUserXsRequest inUserXIds.
         * @member {Array.<number>} inUserXIds
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        GetUserXsRequest.prototype.inUserXIds = $util.emptyArray;

        /**
         * GetUserXsRequest inSchoolIds.
         * @member {Array.<number>} inSchoolIds
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        GetUserXsRequest.prototype.inSchoolIds = $util.emptyArray;

        /**
         * GetUserXsRequest inClassXIds.
         * @member {Array.<number>} inClassXIds
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        GetUserXsRequest.prototype.inClassXIds = $util.emptyArray;

        /**
         * GetUserXsRequest hasEmailAddress.
         * @member {string|null|undefined} hasEmailAddress
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        GetUserXsRequest.prototype.hasEmailAddress = null;

        /**
         * GetUserXsRequest adminXsOnly.
         * @member {boolean|null|undefined} adminXsOnly
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        GetUserXsRequest.prototype.adminXsOnly = null;

        /**
         * GetUserXsRequest teachersOnly.
         * @member {boolean|null|undefined} teachersOnly
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        GetUserXsRequest.prototype.teachersOnly = null;

        /**
         * GetUserXsRequest studentsOnly.
         * @member {boolean|null|undefined} studentsOnly
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        GetUserXsRequest.prototype.studentsOnly = null;

        /**
         * GetUserXsRequest firstLastEmailSearchText.
         * @member {string|null|undefined} firstLastEmailSearchText
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        GetUserXsRequest.prototype.firstLastEmailSearchText = null;

        /**
         * GetUserXsRequest page.
         * @member {number|null|undefined} page
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        GetUserXsRequest.prototype.page = null;

        /**
         * GetUserXsRequest pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        GetUserXsRequest.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GetUserXsRequest _includeSchools.
         * @member {"includeSchools"|undefined} _includeSchools
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        Object.defineProperty(GetUserXsRequest.prototype, "_includeSchools", {
            get: $util.oneOfGetter($oneOfFields = ["includeSchools"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetUserXsRequest _includeClassXs.
         * @member {"includeClassXs"|undefined} _includeClassXs
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        Object.defineProperty(GetUserXsRequest.prototype, "_includeClassXs", {
            get: $util.oneOfGetter($oneOfFields = ["includeClassXs"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetUserXsRequest _ofSelf.
         * @member {"ofSelf"|undefined} _ofSelf
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        Object.defineProperty(GetUserXsRequest.prototype, "_ofSelf", {
            get: $util.oneOfGetter($oneOfFields = ["ofSelf"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetUserXsRequest _hasEmailAddress.
         * @member {"hasEmailAddress"|undefined} _hasEmailAddress
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        Object.defineProperty(GetUserXsRequest.prototype, "_hasEmailAddress", {
            get: $util.oneOfGetter($oneOfFields = ["hasEmailAddress"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetUserXsRequest _adminXsOnly.
         * @member {"adminXsOnly"|undefined} _adminXsOnly
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        Object.defineProperty(GetUserXsRequest.prototype, "_adminXsOnly", {
            get: $util.oneOfGetter($oneOfFields = ["adminXsOnly"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetUserXsRequest _teachersOnly.
         * @member {"teachersOnly"|undefined} _teachersOnly
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        Object.defineProperty(GetUserXsRequest.prototype, "_teachersOnly", {
            get: $util.oneOfGetter($oneOfFields = ["teachersOnly"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetUserXsRequest _studentsOnly.
         * @member {"studentsOnly"|undefined} _studentsOnly
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        Object.defineProperty(GetUserXsRequest.prototype, "_studentsOnly", {
            get: $util.oneOfGetter($oneOfFields = ["studentsOnly"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetUserXsRequest _firstLastEmailSearchText.
         * @member {"firstLastEmailSearchText"|undefined} _firstLastEmailSearchText
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        Object.defineProperty(GetUserXsRequest.prototype, "_firstLastEmailSearchText", {
            get: $util.oneOfGetter($oneOfFields = ["firstLastEmailSearchText"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetUserXsRequest _page.
         * @member {"page"|undefined} _page
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        Object.defineProperty(GetUserXsRequest.prototype, "_page", {
            get: $util.oneOfGetter($oneOfFields = ["page"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GetUserXsRequest _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         */
        Object.defineProperty(GetUserXsRequest.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetUserXsRequest instance using the specified properties.
         * @function create
         * @memberof user_x_service.GetUserXsRequest
         * @static
         * @param {user_x_service.IGetUserXsRequest=} [properties] Properties to set
         * @returns {user_x_service.GetUserXsRequest} GetUserXsRequest instance
         */
        GetUserXsRequest.create = function create(properties) {
            return new GetUserXsRequest(properties);
        };

        /**
         * Encodes the specified GetUserXsRequest message. Does not implicitly {@link user_x_service.GetUserXsRequest.verify|verify} messages.
         * @function encode
         * @memberof user_x_service.GetUserXsRequest
         * @static
         * @param {user_x_service.IGetUserXsRequest} message GetUserXsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserXsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.adminXsOnly != null && Object.hasOwnProperty.call(message, "adminXsOnly"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.adminXsOnly);
            if (message.teachersOnly != null && Object.hasOwnProperty.call(message, "teachersOnly"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.teachersOnly);
            if (message.studentsOnly != null && Object.hasOwnProperty.call(message, "studentsOnly"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.studentsOnly);
            if (message.inSchoolIds != null && message.inSchoolIds.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (var i = 0; i < message.inSchoolIds.length; ++i)
                    writer.int32(message.inSchoolIds[i]);
                writer.ldelim();
            }
            if (message.inClassXIds != null && message.inClassXIds.length) {
                writer.uint32(/* id 5, wireType 2 =*/42).fork();
                for (var i = 0; i < message.inClassXIds.length; ++i)
                    writer.int32(message.inClassXIds[i]);
                writer.ldelim();
            }
            if (message.firstLastEmailSearchText != null && Object.hasOwnProperty.call(message, "firstLastEmailSearchText"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.firstLastEmailSearchText);
            if (message.includeSchools != null && Object.hasOwnProperty.call(message, "includeSchools"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.includeSchools);
            if (message.includeClassXs != null && Object.hasOwnProperty.call(message, "includeClassXs"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.includeClassXs);
            if (message.inDistrictIds != null && message.inDistrictIds.length) {
                writer.uint32(/* id 9, wireType 2 =*/74).fork();
                for (var i = 0; i < message.inDistrictIds.length; ++i)
                    writer.int32(message.inDistrictIds[i]);
                writer.ldelim();
            }
            if (message.inUserXIds != null && message.inUserXIds.length) {
                writer.uint32(/* id 10, wireType 2 =*/82).fork();
                for (var i = 0; i < message.inUserXIds.length; ++i)
                    writer.int32(message.inUserXIds[i]);
                writer.ldelim();
            }
            if (message.hasEmailAddress != null && Object.hasOwnProperty.call(message, "hasEmailAddress"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.hasEmailAddress);
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.page);
            if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                writer.uint32(/* id 13, wireType 0 =*/104).int32(message.pageSize);
            if (message.ofSelf != null && Object.hasOwnProperty.call(message, "ofSelf"))
                writer.uint32(/* id 14, wireType 0 =*/112).bool(message.ofSelf);
            return writer;
        };

        /**
         * Encodes the specified GetUserXsRequest message, length delimited. Does not implicitly {@link user_x_service.GetUserXsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user_x_service.GetUserXsRequest
         * @static
         * @param {user_x_service.IGetUserXsRequest} message GetUserXsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserXsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetUserXsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof user_x_service.GetUserXsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user_x_service.GetUserXsRequest} GetUserXsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserXsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user_x_service.GetUserXsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 7: {
                        message.includeSchools = reader.bool();
                        break;
                    }
                case 8: {
                        message.includeClassXs = reader.bool();
                        break;
                    }
                case 14: {
                        message.ofSelf = reader.bool();
                        break;
                    }
                case 9: {
                        if (!(message.inDistrictIds && message.inDistrictIds.length))
                            message.inDistrictIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.inDistrictIds.push(reader.int32());
                        } else
                            message.inDistrictIds.push(reader.int32());
                        break;
                    }
                case 10: {
                        if (!(message.inUserXIds && message.inUserXIds.length))
                            message.inUserXIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.inUserXIds.push(reader.int32());
                        } else
                            message.inUserXIds.push(reader.int32());
                        break;
                    }
                case 4: {
                        if (!(message.inSchoolIds && message.inSchoolIds.length))
                            message.inSchoolIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.inSchoolIds.push(reader.int32());
                        } else
                            message.inSchoolIds.push(reader.int32());
                        break;
                    }
                case 5: {
                        if (!(message.inClassXIds && message.inClassXIds.length))
                            message.inClassXIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.inClassXIds.push(reader.int32());
                        } else
                            message.inClassXIds.push(reader.int32());
                        break;
                    }
                case 11: {
                        message.hasEmailAddress = reader.string();
                        break;
                    }
                case 1: {
                        message.adminXsOnly = reader.bool();
                        break;
                    }
                case 2: {
                        message.teachersOnly = reader.bool();
                        break;
                    }
                case 3: {
                        message.studentsOnly = reader.bool();
                        break;
                    }
                case 6: {
                        message.firstLastEmailSearchText = reader.string();
                        break;
                    }
                case 12: {
                        message.page = reader.int32();
                        break;
                    }
                case 13: {
                        message.pageSize = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetUserXsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user_x_service.GetUserXsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user_x_service.GetUserXsRequest} GetUserXsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserXsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetUserXsRequest message.
         * @function verify
         * @memberof user_x_service.GetUserXsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetUserXsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.includeSchools != null && message.hasOwnProperty("includeSchools")) {
                properties._includeSchools = 1;
                if (typeof message.includeSchools !== "boolean")
                    return "includeSchools: boolean expected";
            }
            if (message.includeClassXs != null && message.hasOwnProperty("includeClassXs")) {
                properties._includeClassXs = 1;
                if (typeof message.includeClassXs !== "boolean")
                    return "includeClassXs: boolean expected";
            }
            if (message.ofSelf != null && message.hasOwnProperty("ofSelf")) {
                properties._ofSelf = 1;
                if (typeof message.ofSelf !== "boolean")
                    return "ofSelf: boolean expected";
            }
            if (message.inDistrictIds != null && message.hasOwnProperty("inDistrictIds")) {
                if (!Array.isArray(message.inDistrictIds))
                    return "inDistrictIds: array expected";
                for (var i = 0; i < message.inDistrictIds.length; ++i)
                    if (!$util.isInteger(message.inDistrictIds[i]))
                        return "inDistrictIds: integer[] expected";
            }
            if (message.inUserXIds != null && message.hasOwnProperty("inUserXIds")) {
                if (!Array.isArray(message.inUserXIds))
                    return "inUserXIds: array expected";
                for (var i = 0; i < message.inUserXIds.length; ++i)
                    if (!$util.isInteger(message.inUserXIds[i]))
                        return "inUserXIds: integer[] expected";
            }
            if (message.inSchoolIds != null && message.hasOwnProperty("inSchoolIds")) {
                if (!Array.isArray(message.inSchoolIds))
                    return "inSchoolIds: array expected";
                for (var i = 0; i < message.inSchoolIds.length; ++i)
                    if (!$util.isInteger(message.inSchoolIds[i]))
                        return "inSchoolIds: integer[] expected";
            }
            if (message.inClassXIds != null && message.hasOwnProperty("inClassXIds")) {
                if (!Array.isArray(message.inClassXIds))
                    return "inClassXIds: array expected";
                for (var i = 0; i < message.inClassXIds.length; ++i)
                    if (!$util.isInteger(message.inClassXIds[i]))
                        return "inClassXIds: integer[] expected";
            }
            if (message.hasEmailAddress != null && message.hasOwnProperty("hasEmailAddress")) {
                properties._hasEmailAddress = 1;
                if (!$util.isString(message.hasEmailAddress))
                    return "hasEmailAddress: string expected";
            }
            if (message.adminXsOnly != null && message.hasOwnProperty("adminXsOnly")) {
                properties._adminXsOnly = 1;
                if (typeof message.adminXsOnly !== "boolean")
                    return "adminXsOnly: boolean expected";
            }
            if (message.teachersOnly != null && message.hasOwnProperty("teachersOnly")) {
                properties._teachersOnly = 1;
                if (typeof message.teachersOnly !== "boolean")
                    return "teachersOnly: boolean expected";
            }
            if (message.studentsOnly != null && message.hasOwnProperty("studentsOnly")) {
                properties._studentsOnly = 1;
                if (typeof message.studentsOnly !== "boolean")
                    return "studentsOnly: boolean expected";
            }
            if (message.firstLastEmailSearchText != null && message.hasOwnProperty("firstLastEmailSearchText")) {
                properties._firstLastEmailSearchText = 1;
                if (!$util.isString(message.firstLastEmailSearchText))
                    return "firstLastEmailSearchText: string expected";
            }
            if (message.page != null && message.hasOwnProperty("page")) {
                properties._page = 1;
                if (!$util.isInteger(message.page))
                    return "page: integer expected";
            }
            if (message.pageSize != null && message.hasOwnProperty("pageSize")) {
                properties._pageSize = 1;
                if (!$util.isInteger(message.pageSize))
                    return "pageSize: integer expected";
            }
            return null;
        };

        /**
         * Creates a GetUserXsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user_x_service.GetUserXsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user_x_service.GetUserXsRequest} GetUserXsRequest
         */
        GetUserXsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.user_x_service.GetUserXsRequest)
                return object;
            var message = new $root.user_x_service.GetUserXsRequest();
            if (object.includeSchools != null)
                message.includeSchools = Boolean(object.includeSchools);
            if (object.includeClassXs != null)
                message.includeClassXs = Boolean(object.includeClassXs);
            if (object.ofSelf != null)
                message.ofSelf = Boolean(object.ofSelf);
            if (object.inDistrictIds) {
                if (!Array.isArray(object.inDistrictIds))
                    throw TypeError(".user_x_service.GetUserXsRequest.inDistrictIds: array expected");
                message.inDistrictIds = [];
                for (var i = 0; i < object.inDistrictIds.length; ++i)
                    message.inDistrictIds[i] = object.inDistrictIds[i] | 0;
            }
            if (object.inUserXIds) {
                if (!Array.isArray(object.inUserXIds))
                    throw TypeError(".user_x_service.GetUserXsRequest.inUserXIds: array expected");
                message.inUserXIds = [];
                for (var i = 0; i < object.inUserXIds.length; ++i)
                    message.inUserXIds[i] = object.inUserXIds[i] | 0;
            }
            if (object.inSchoolIds) {
                if (!Array.isArray(object.inSchoolIds))
                    throw TypeError(".user_x_service.GetUserXsRequest.inSchoolIds: array expected");
                message.inSchoolIds = [];
                for (var i = 0; i < object.inSchoolIds.length; ++i)
                    message.inSchoolIds[i] = object.inSchoolIds[i] | 0;
            }
            if (object.inClassXIds) {
                if (!Array.isArray(object.inClassXIds))
                    throw TypeError(".user_x_service.GetUserXsRequest.inClassXIds: array expected");
                message.inClassXIds = [];
                for (var i = 0; i < object.inClassXIds.length; ++i)
                    message.inClassXIds[i] = object.inClassXIds[i] | 0;
            }
            if (object.hasEmailAddress != null)
                message.hasEmailAddress = String(object.hasEmailAddress);
            if (object.adminXsOnly != null)
                message.adminXsOnly = Boolean(object.adminXsOnly);
            if (object.teachersOnly != null)
                message.teachersOnly = Boolean(object.teachersOnly);
            if (object.studentsOnly != null)
                message.studentsOnly = Boolean(object.studentsOnly);
            if (object.firstLastEmailSearchText != null)
                message.firstLastEmailSearchText = String(object.firstLastEmailSearchText);
            if (object.page != null)
                message.page = object.page | 0;
            if (object.pageSize != null)
                message.pageSize = object.pageSize | 0;
            return message;
        };

        /**
         * Creates a plain object from a GetUserXsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user_x_service.GetUserXsRequest
         * @static
         * @param {user_x_service.GetUserXsRequest} message GetUserXsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetUserXsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.inSchoolIds = [];
                object.inClassXIds = [];
                object.inDistrictIds = [];
                object.inUserXIds = [];
            }
            if (message.adminXsOnly != null && message.hasOwnProperty("adminXsOnly")) {
                object.adminXsOnly = message.adminXsOnly;
                if (options.oneofs)
                    object._adminXsOnly = "adminXsOnly";
            }
            if (message.teachersOnly != null && message.hasOwnProperty("teachersOnly")) {
                object.teachersOnly = message.teachersOnly;
                if (options.oneofs)
                    object._teachersOnly = "teachersOnly";
            }
            if (message.studentsOnly != null && message.hasOwnProperty("studentsOnly")) {
                object.studentsOnly = message.studentsOnly;
                if (options.oneofs)
                    object._studentsOnly = "studentsOnly";
            }
            if (message.inSchoolIds && message.inSchoolIds.length) {
                object.inSchoolIds = [];
                for (var j = 0; j < message.inSchoolIds.length; ++j)
                    object.inSchoolIds[j] = message.inSchoolIds[j];
            }
            if (message.inClassXIds && message.inClassXIds.length) {
                object.inClassXIds = [];
                for (var j = 0; j < message.inClassXIds.length; ++j)
                    object.inClassXIds[j] = message.inClassXIds[j];
            }
            if (message.firstLastEmailSearchText != null && message.hasOwnProperty("firstLastEmailSearchText")) {
                object.firstLastEmailSearchText = message.firstLastEmailSearchText;
                if (options.oneofs)
                    object._firstLastEmailSearchText = "firstLastEmailSearchText";
            }
            if (message.includeSchools != null && message.hasOwnProperty("includeSchools")) {
                object.includeSchools = message.includeSchools;
                if (options.oneofs)
                    object._includeSchools = "includeSchools";
            }
            if (message.includeClassXs != null && message.hasOwnProperty("includeClassXs")) {
                object.includeClassXs = message.includeClassXs;
                if (options.oneofs)
                    object._includeClassXs = "includeClassXs";
            }
            if (message.inDistrictIds && message.inDistrictIds.length) {
                object.inDistrictIds = [];
                for (var j = 0; j < message.inDistrictIds.length; ++j)
                    object.inDistrictIds[j] = message.inDistrictIds[j];
            }
            if (message.inUserXIds && message.inUserXIds.length) {
                object.inUserXIds = [];
                for (var j = 0; j < message.inUserXIds.length; ++j)
                    object.inUserXIds[j] = message.inUserXIds[j];
            }
            if (message.hasEmailAddress != null && message.hasOwnProperty("hasEmailAddress")) {
                object.hasEmailAddress = message.hasEmailAddress;
                if (options.oneofs)
                    object._hasEmailAddress = "hasEmailAddress";
            }
            if (message.page != null && message.hasOwnProperty("page")) {
                object.page = message.page;
                if (options.oneofs)
                    object._page = "page";
            }
            if (message.pageSize != null && message.hasOwnProperty("pageSize")) {
                object.pageSize = message.pageSize;
                if (options.oneofs)
                    object._pageSize = "pageSize";
            }
            if (message.ofSelf != null && message.hasOwnProperty("ofSelf")) {
                object.ofSelf = message.ofSelf;
                if (options.oneofs)
                    object._ofSelf = "ofSelf";
            }
            return object;
        };

        /**
         * Converts this GetUserXsRequest to JSON.
         * @function toJSON
         * @memberof user_x_service.GetUserXsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetUserXsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetUserXsRequest
         * @function getTypeUrl
         * @memberof user_x_service.GetUserXsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetUserXsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user_x_service.GetUserXsRequest";
        };

        return GetUserXsRequest;
    })();

    user_x_service.GetUserXsResponse = (function() {

        /**
         * Properties of a GetUserXsResponse.
         * @memberof user_x_service
         * @interface IGetUserXsResponse
         * @property {Array.<user_x_service.IFullUserXDetails>|null} [userXs] GetUserXsResponse userXs
         * @property {Long|null} [totalUserXs] GetUserXsResponse totalUserXs
         */

        /**
         * Constructs a new GetUserXsResponse.
         * @memberof user_x_service
         * @classdesc Represents a GetUserXsResponse.
         * @implements IGetUserXsResponse
         * @constructor
         * @param {user_x_service.IGetUserXsResponse=} [properties] Properties to set
         */
        function GetUserXsResponse(properties) {
            this.userXs = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetUserXsResponse userXs.
         * @member {Array.<user_x_service.IFullUserXDetails>} userXs
         * @memberof user_x_service.GetUserXsResponse
         * @instance
         */
        GetUserXsResponse.prototype.userXs = $util.emptyArray;

        /**
         * GetUserXsResponse totalUserXs.
         * @member {Long|null|undefined} totalUserXs
         * @memberof user_x_service.GetUserXsResponse
         * @instance
         */
        GetUserXsResponse.prototype.totalUserXs = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GetUserXsResponse _totalUserXs.
         * @member {"totalUserXs"|undefined} _totalUserXs
         * @memberof user_x_service.GetUserXsResponse
         * @instance
         */
        Object.defineProperty(GetUserXsResponse.prototype, "_totalUserXs", {
            get: $util.oneOfGetter($oneOfFields = ["totalUserXs"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetUserXsResponse instance using the specified properties.
         * @function create
         * @memberof user_x_service.GetUserXsResponse
         * @static
         * @param {user_x_service.IGetUserXsResponse=} [properties] Properties to set
         * @returns {user_x_service.GetUserXsResponse} GetUserXsResponse instance
         */
        GetUserXsResponse.create = function create(properties) {
            return new GetUserXsResponse(properties);
        };

        /**
         * Encodes the specified GetUserXsResponse message. Does not implicitly {@link user_x_service.GetUserXsResponse.verify|verify} messages.
         * @function encode
         * @memberof user_x_service.GetUserXsResponse
         * @static
         * @param {user_x_service.IGetUserXsResponse} message GetUserXsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserXsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userXs != null && message.userXs.length)
                for (var i = 0; i < message.userXs.length; ++i)
                    $root.user_x_service.FullUserXDetails.encode(message.userXs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.totalUserXs != null && Object.hasOwnProperty.call(message, "totalUserXs"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalUserXs);
            return writer;
        };

        /**
         * Encodes the specified GetUserXsResponse message, length delimited. Does not implicitly {@link user_x_service.GetUserXsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user_x_service.GetUserXsResponse
         * @static
         * @param {user_x_service.IGetUserXsResponse} message GetUserXsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserXsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetUserXsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof user_x_service.GetUserXsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user_x_service.GetUserXsResponse} GetUserXsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserXsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.user_x_service.GetUserXsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.userXs && message.userXs.length))
                            message.userXs = [];
                        message.userXs.push($root.user_x_service.FullUserXDetails.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.totalUserXs = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetUserXsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user_x_service.GetUserXsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user_x_service.GetUserXsResponse} GetUserXsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserXsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetUserXsResponse message.
         * @function verify
         * @memberof user_x_service.GetUserXsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetUserXsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.userXs != null && message.hasOwnProperty("userXs")) {
                if (!Array.isArray(message.userXs))
                    return "userXs: array expected";
                for (var i = 0; i < message.userXs.length; ++i) {
                    var error = $root.user_x_service.FullUserXDetails.verify(message.userXs[i]);
                    if (error)
                        return "userXs." + error;
                }
            }
            if (message.totalUserXs != null && message.hasOwnProperty("totalUserXs")) {
                properties._totalUserXs = 1;
                if (!$util.isInteger(message.totalUserXs) && !(message.totalUserXs && $util.isInteger(message.totalUserXs.low) && $util.isInteger(message.totalUserXs.high)))
                    return "totalUserXs: integer|Long expected";
            }
            return null;
        };

        /**
         * Creates a GetUserXsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user_x_service.GetUserXsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user_x_service.GetUserXsResponse} GetUserXsResponse
         */
        GetUserXsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.user_x_service.GetUserXsResponse)
                return object;
            var message = new $root.user_x_service.GetUserXsResponse();
            if (object.userXs) {
                if (!Array.isArray(object.userXs))
                    throw TypeError(".user_x_service.GetUserXsResponse.userXs: array expected");
                message.userXs = [];
                for (var i = 0; i < object.userXs.length; ++i) {
                    if (typeof object.userXs[i] !== "object")
                        throw TypeError(".user_x_service.GetUserXsResponse.userXs: object expected");
                    message.userXs[i] = $root.user_x_service.FullUserXDetails.fromObject(object.userXs[i]);
                }
            }
            if (object.totalUserXs != null)
                if ($util.Long)
                    (message.totalUserXs = $util.Long.fromValue(object.totalUserXs)).unsigned = false;
                else if (typeof object.totalUserXs === "string")
                    message.totalUserXs = parseInt(object.totalUserXs, 10);
                else if (typeof object.totalUserXs === "number")
                    message.totalUserXs = object.totalUserXs;
                else if (typeof object.totalUserXs === "object")
                    message.totalUserXs = new $util.LongBits(object.totalUserXs.low >>> 0, object.totalUserXs.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a GetUserXsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user_x_service.GetUserXsResponse
         * @static
         * @param {user_x_service.GetUserXsResponse} message GetUserXsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetUserXsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.userXs = [];
            if (message.userXs && message.userXs.length) {
                object.userXs = [];
                for (var j = 0; j < message.userXs.length; ++j)
                    object.userXs[j] = $root.user_x_service.FullUserXDetails.toObject(message.userXs[j], options);
            }
            if (message.totalUserXs != null && message.hasOwnProperty("totalUserXs")) {
                if (typeof message.totalUserXs === "number")
                    object.totalUserXs = options.longs === String ? String(message.totalUserXs) : message.totalUserXs;
                else
                    object.totalUserXs = options.longs === String ? $util.Long.prototype.toString.call(message.totalUserXs) : options.longs === Number ? new $util.LongBits(message.totalUserXs.low >>> 0, message.totalUserXs.high >>> 0).toNumber() : message.totalUserXs;
                if (options.oneofs)
                    object._totalUserXs = "totalUserXs";
            }
            return object;
        };

        /**
         * Converts this GetUserXsResponse to JSON.
         * @function toJSON
         * @memberof user_x_service.GetUserXsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetUserXsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetUserXsResponse
         * @function getTypeUrl
         * @memberof user_x_service.GetUserXsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetUserXsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user_x_service.GetUserXsResponse";
        };

        return GetUserXsResponse;
    })();

    return user_x_service;
})();

module.exports = $root;
