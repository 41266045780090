import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  PropsWithChildren,
} from 'react';
import {Snackbar, Alert} from '@mui/material';

interface ToastContextType {
  showToast: (
    message: string,
    severity: 'success' | 'info' | 'warning' | 'error'
  ) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

type ToastProviderProps = PropsWithChildren<{}>;

export function ToastProvider({children}: ToastProviderProps) {
  const [toast, setToast] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error';
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  }>({
    open: false,
    message: '',
    severity: 'info',
    vertical: 'top',
    horizontal: 'right',
  });

  const showToast = useCallback(
    (
      message: string,
      severity: 'success' | 'info' | 'warning' | 'error' = 'info'
    ) => {
      setToast({
        open: true,
        message,
        severity,
        vertical: 'top',
        horizontal: 'right',
      });
    },
    []
  );

  function handleClose() {
    setToast({...toast, open: false});
  }

  return (
    <ToastContext.Provider value={{showToast}}>
      {children}
      <Snackbar
        anchorOrigin={{vertical: toast.vertical, horizontal: toast.horizontal}}
        open={toast.open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={toast.severity}
          sx={{width: '100%'}}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
}

export function useToast(): ToastContextType {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
}
