import {AiInfo} from '../AiInfo/AiInfo';
import React from 'react';
import {DeepReadOnly} from '../misc';
import {pl_types} from '../../generated/protobuf-js';
import IProject = pl_types.IProject;
import IProjectDefinition = pl_types.IProjectDefinition;

export function ProjectInputCardHeader(
  props: DeepReadOnly<{
    projectInput: IProjectDefinition;
    updateProjectInput?: (project: DeepReadOnly<IProject>) => void;
  }>
) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        textWrap: 'nowrap',
        gap: '0.5rem',
        paddingBottom: '1rem',
      }}
    >
      <div
        style={{
          fontWeight: 'bold',
          fontSize: '1.1rem',
          textWrap: 'balance',
          flex: '1 1 auto',
        }}
      >
        Project Input Status
      </div>
      <AiInfo aiInfo={props.projectInput} />
    </div>
  );
}
