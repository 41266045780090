import './TeacherDashboard.scss';
import {useEffect, useRef, useState} from 'react';
import {DeepReadOnly, toLong} from '../../../libs/misc';
import {EducationFilters} from '../../../libs/EducationFilter/EducationFilters';
import {Chip, Grid} from '@mui/material';
import {assignment_service, pl_types} from '../../../generated/protobuf-js';
import {createService} from '../../../libs/protos';
import {FormField} from '../../../libs/form_utils/forms';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {USER_X_STATUS_SORTER} from '../../../libs/sorters';
import {UserXAvatar} from '../../../libs/UserXAvatar/UserXAvatar';
import Long from 'long';
import {ProjectDetailsCardModal} from '../../../libs/ProjectCard/ProjectDetailsCardModal';
import IAssignment = pl_types.IAssignment;
import AssignmentService = assignment_service.AssignmentService;
import IUserXStatus = assignment_service.GetClassXAssignmentStatusResponse.IUserXStatus;
import {useNotification} from '../../../shared/hooks/Notification';

function toLastPostTimeLabel(msToNow: number) {
  if (msToNow < 24 * 60 * 60 * 1000) {
    const hours = Math.floor(msToNow / (60 * 60 * 1000));
    return hours === 0
      ? 'just now'
      : `${hours + 1} hour${hours === 0 ? '' : 's'} ago`;
  }
  if (msToNow < 7 * 24 * 60 * 60 * 1000) {
    const days = Math.floor(msToNow / (24 * 60 * 60 * 1000));
    return `${days + 1} day${days === 0 ? '' : 's'} ago`;
  }
  const weeks = Math.floor(msToNow / (7 * 24 * 60 * 60 * 1000));
  return `${weeks + 1} week${weeks === 0 ? '' : 's'} ago`;
}

function toLastPostTimeBackgroundColor(msToNow: number) {
  let hue = 0;
  if (msToNow < 2 * 24 * 60 * 60 * 1000) {
    hue = 1;
  } else if (msToNow < 7 * 24 * 60 * 60 * 1000) {
    hue = 1 - msToNow / (7 * 24 * 60 * 60 * 1000);
  }
  return `hsl(${Math.max(0, Math.min(128, hue * 128))}, 100%, 90%)`;
}

export function AssignmentsTab(
  props: DeepReadOnly<{
    educationFilters: EducationFilters;
    assignmentsFilter?: FormField<DeepReadOnly<IAssignment>, true>;
  }>
) {
  const [assignment, setAssignment] = useState<DeepReadOnly<IAssignment>>();
  const [userXStatuses, setUserXStatuses] = useState<
    DeepReadOnly<IUserXStatus[]>
  >([]);
  const [projectIdForDetails, setProjectIdForDetails] = useState<
    number | null | undefined
  >();

  const updatingCount = useRef(0);
  const [loading, setLoading] = useState(false);
  const notification = useNotification();

  useEffect(() => {
    const currentUpdateCount = ++updatingCount.current;
    const assignments =
      props.assignmentsFilter?.getValue?.() ??
      props.educationFilters.assignmentsFilter?.getValue?.() ??
      [];
    if (assignments.length === 1) {
      setAssignment(undefined);
      setUserXStatuses([]);
      setLoading(true);
      createService(AssignmentService, 'AssignmentService')
        .getClassXAssignmentStatus({assignmentId: assignments[0].id})
        .then(response => {
          if (currentUpdateCount !== updatingCount.current) {
            return;
          }
          setAssignment(response.assignment!);
          setUserXStatuses(response.userXStatuses.sort(USER_X_STATUS_SORTER));
        })
        .catch(
          notification.handleError('Failed to get class assignment status')
        )
        .finally(() => {
          if (currentUpdateCount !== updatingCount.current) {
            return;
          }
          setLoading(false);
        });
    } else {
      setAssignment(undefined);
      setUserXStatuses([]);
    }
  }, [
    props.assignmentsFilter?.getValue?.(),
    props.educationFilters.assignmentsFilter?.getValue?.(),
  ]);

  return (
    <>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={3}>
          <EducationFilters
            label="Filter Posts"
            highlightLabel="Highlight Posts"
            educationFilters={props.educationFilters}
          />
        </Grid>
        <Grid item xs={9}>
          {!assignment && !loading ? (
            <div
              className="global-flex-column"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              Please select a single assignment on the left.
            </div>
          ) : (
            <>
              <DataGridPro
                rows={userXStatuses}
                getRowId={row => row.project?.id ?? 0}
                autosizeOptions={{expand: true, includeHeaders: true}}
                autosizeOnMount={true}
                loading={loading}
                columns={[
                  {
                    renderCell: ({row}) => <UserXAvatar userX={row.userX} />,
                    field: 'userXAvatar',
                    headerName: 'Avatar',
                    align: 'center',
                    sortable: false,
                    width: 75,
                  },
                  {
                    valueGetter: (_, row) => row.userX?.firstName ?? '',
                    sortable: true,
                    field: 'userXId.firstName',
                    headerName: 'First Name',
                    width: 150,
                  },
                  {
                    valueGetter: (_, row) => row.userX?.lastName ?? '',
                    sortable: true,
                    field: 'userXId.lastName',
                    headerName: 'Last Name',
                    width: 150,
                  },
                  {
                    renderCell: ({row}) => (
                      <span
                        style={{cursor: 'pointer'}}
                        onClick={() => setProjectIdForDetails(row.project?.id)}
                      >
                        {row.project?.name ?? 'Unnamed Project'}
                      </span>
                    ),
                    valueGetter: (_, row) =>
                      row.project?.name ?? 'Unnamed Project',
                    sortable: true,
                    field: 'project.name',
                    headerName: 'Project',
                    width: 400,
                  },
                  {
                    renderCell: ({row}) => (
                      <span
                        style={{
                          color: row.isAwaitingFeedback ? 'red' : undefined,
                        }}
                      >
                        {row.isAwaitingFeedback === true ? 'Yes' : 'No'}
                      </span>
                    ),
                    valueGetter: (_, row) =>
                      row.isAwaitingFeedback === true ? 'Yes' : 'No',
                    field: 'isAwaitingFeedback',
                    headerName: 'Awaiting Feedback',
                    width: 150,
                  },
                  {
                    renderCell: ({row}) => (
                      <Chip
                        label={
                          toLong(row.lastPostTimeMs).eq(Long.ZERO)
                            ? 'never'
                            : toLastPostTimeLabel(
                                toLong(new Date().getTime())
                                  .subtract(toLong(row.lastPostTimeMs))
                                  .toNumber()
                              )
                        }
                        style={{
                          backgroundColor: toLastPostTimeBackgroundColor(
                            toLong(new Date().getTime())
                              .subtract(toLong(row.lastPostTimeMs))
                              .toNumber()
                          ),
                        }}
                      />
                    ),
                    valueGetter: (_, row) => row.lastPostTimeMs,
                    field: 'lastPostTimeMs',
                    headerName: 'Last Post',
                    width: 150,
                  },
                ]}
              />
            </>
          )}
        </Grid>
      </Grid>
      <ProjectDetailsCardModal
        projectId={projectIdForDetails}
        onClose={() => setProjectIdForDetails(undefined)}
      />
    </>
  );
}
