import './MechanicalEngineering.scss';

export function MechanicalEngineering() {
  return (
    <div className="mechanical-engineering">
      <h1>
        This header is modified by the CSS styles in MechanicalEngineering.scss.
      </h1>
    </div>
  );
}
