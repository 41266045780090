import {ThemeProvider, createTheme, ThemeOptions} from '@mui/material/styles';
import React, {
  PropsWithChildren,
  useMemo,
  useState,
  createContext,
} from 'react';
import {color as ThemeColors} from './index';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../store/store';
import {setTheme as setThemeInSlice} from '../features/theme/themeSlice';
import {ThemeEnum} from './theme.enum';

export const ThemeContext = createContext({
  toggleColorMode: () => {},
  shuffleColorTheme: () => {},
  mode: ThemeEnum.LIGHT,
});
type MyThemeProviderProps = PropsWithChildren<{}>;
export default function MyThemeProvider(props: MyThemeProviderProps) {
  const dispatch: AppDispatch = useDispatch();
  const mode = useSelector((state: RootState) => state.theme.theme);
  const [theme, setTheme] = useState<0>(0);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        dispatch(
          setThemeInSlice(
            mode === ThemeEnum.LIGHT ? ThemeEnum.DARK : ThemeEnum.LIGHT
          )
        );
      },
      shuffleColorTheme: () => {
        setTheme(0);
      },
      mode: mode,
    }),
    [mode]
  );
  // 2. create theme object, pick theme from the mega theme object we exported earlier
  // based on our theme and mode values
  const _theme = useMemo(
    () =>
      createTheme({
        typography: {
          button: {
            textTransform: 'none',
            fontWeight: 600,
          },

          fontFamily: 'Hind',
          h2: {
            fontSize: '2em',
            fontWeight: 700,
          },
        },

        ...(ThemeColors[theme][mode] as ThemeOptions),
        components: {
          MuiButtonBase: {
            defaultProps: {
              disableRipple: true,
            },
          },
        },
      }),
    [mode, theme]
  );
  return (
    <ThemeContext.Provider value={colorMode}>
      {/* 3. supply it to MUI ThemeProvider */}
      <ThemeProvider theme={_theme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  );
}
