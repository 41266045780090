export function SchoolsIndex() {
  return (
    <>
      <p>
        This is the page that would point to different schools?
        <a href="/schools/dvs/mechanical-engineering.html">
          Here's a link to DVS mechanical-engineering.
        </a>
      </p>
    </>
  );
}
