import {configureStore} from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import themeReducer from '../features/theme/themeSlice';
import selectionReducer from '../features/selection/selectionSlice';
import {DeepReadOnly, DeepWritable} from '../libs/misc';

export function writableForState<T>(value: T | DeepReadOnly<T>) {
  return value as DeepWritable<T>;
}

export const store = configureStore({
  reducer: {
    auth: authReducer,
    selection: selectionReducer,
    theme: themeReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['auth/login/fulfilled'],
        ignoredActionPaths: ['meta.arg', 'payload'],
        ignoredPaths: ['auth.user'],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
