import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type ConfirmModalProps = {
  open: boolean;
  title: string;
  description: string;
  handleClose: (isConfirmed: boolean) => void;
};
export function ConfirmModal(props: ConfirmModalProps) {
  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={() => props.handleClose(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleClose(false)}>cancel</Button>
          <Button onClick={() => props.handleClose(true)}>confirm</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
