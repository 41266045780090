import {PropsWithChildren} from 'react';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router-dom';
import {RootState} from '../../store/store';
import {pl_types} from '../../generated/protobuf-js';
import IUserX = pl_types.IUserX;

interface AuthorizedRouteProps extends PropsWithChildren {
  isUserXAuthorized?: (userX: IUserX) => boolean | null | undefined;
}

export function AuthorizedRoute({
  children,
  isUserXAuthorized,
}: AuthorizedRouteProps) {
  const userX = useSelector((state: RootState) => state.auth.user);

  if (!userX) {
    return <Navigate to="/" />;
  }

  if (isUserXAuthorized && isUserXAuthorized(userX)) {
    return <>{children}</>;
  }

  // Redirect to unauthorized page.
  return <Navigate to="/unauthorized" />;
}
