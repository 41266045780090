import '../../ProjectPage/ProjectPage.scss';
import {Button} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import type {UniqueIdentifier} from '@dnd-kit/core';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {SortableContext, useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {SortableItem} from './SortableItem';
import {pl_types} from '../../../generated/protobuf-js';
import {EditablePlainText} from '../../EditablePlainText';
import {DragIcon} from '../../common';
import {Box, Tooltip} from '@mui/material';
import {StepWithSortIndex} from './ProjectDetailCardMilestone';
import {useState} from 'react';
import IMilestone = pl_types.Project.IMilestone;

type Props = {
  onAddNewStep: (index: number, newName: string) => void;
  removeStep: (stepId: number, milestoneIndex: number) => void;
  removeMilestone: (index: number) => void;
  milestone: IMilestone;
  steps: StepWithSortIndex[];
  updateMilestone: (newName: string, milestoneIndex: number) => void;
  updateStep: (
    newName: string,
    milestoneIndex: number,
    stepIndex: number
  ) => void;
  milestoneId: string;
  stepItems: UniqueIdentifier[];
};

export const MilestoneContainer = ({
  steps,
  milestone,
  removeStep,
  updateMilestone,
  removeMilestone,
  milestoneId,
  updateStep,
  onAddNewStep,
  stepItems,
}: Props) => {
  const [isAddingNewStep, setIsAddingNewStep] = useState(false);

  const {setNodeRef, attributes, listeners, transform, transition, isDragging} =
    useSortable({
      id: milestoneId as UniqueIdentifier,
      data: {
        type: 'Milestone',
        milestone,
        milestoneId,
      },
    });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
    zIndex: isDragging ? 1000 : 'auto',
  };

  return (
    <div
      className="milestone-content"
      ref={setNodeRef}
      style={{...style, position: 'relative'}}
    >
      <SortableContext items={stepItems}>
        <Box>
          <div className="global-flexbetween milestone_header width-100">
            <div className="items-center display-flex width-100">
              <div style={{width: '120px', fontWeight: 700}}>
                Milestone {JSON.parse(milestoneId).milestoneIndex + 1}:
              </div>
              <EditablePlainText
                style={{
                  fontWeight: 700,
                }}
                value={milestone?.name ?? ''}
                onChange={(value: string) =>
                  updateMilestone(value, JSON.parse(milestoneId).milestoneIndex)
                }
              />
            </div>
            <div className="milestone-action">
              <Tooltip title="Delete Milestone">
                <DeleteOutlineIcon
                  sx={{marginRight: '1rem'}}
                  onClick={() =>
                    removeMilestone(JSON.parse(milestoneId).milestoneIndex)
                  }
                  color="error"
                />
              </Tooltip>
              <div
                style={{
                  cursor: 'grab',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                {...attributes}
                {...listeners}
              >
                <DragIcon />
              </div>
            </div>
          </div>
          <div className="milestone_body">
            {steps?.map((step: StepWithSortIndex, stepIndex: number) => (
              <SortableItem
                key={JSON.stringify({stepId: step.id, stepIndex})}
                step={step}
                stepId={step.id as UniqueIdentifier}
                removeStep={removeStep}
              >
                <div className="items-center display-flex width-100">
                  <div style={{width: '65px', fontSize: '15px'}}>
                    Step {stepIndex + 1}:
                  </div>
                  <EditablePlainText
                    value={step.name ?? 'None.'}
                    style={{
                      fontSize: '15px',
                    }}
                    onChange={(newName: string) =>
                      updateStep(
                        newName,
                        JSON.parse(milestoneId).milestoneIndex,
                        stepIndex
                      )
                    }
                  />
                </div>
              </SortableItem>
            ))}
            <div className="add-step">
              {isAddingNewStep && (
                <div style={{width: '65px', fontSize: '15px'}}>
                  Step {steps.length + 1}:
                </div>
              )}
              <EditablePlainText
                value={''}
                style={{
                  fontSize: '15px',
                  color: '#FF9800',
                  cursor: 'pointer',
                }}
                getCurrentMode={(isEditing: boolean) =>
                  setIsAddingNewStep(isEditing)
                }
                viewingPlaceholder={
                  <Button
                    color="primary"
                    size="small"
                    startIcon={<AddBoxIcon />}
                  >
                    Add New Step
                  </Button>
                }
                onChange={(newName: string) => {
                  if (newName.length)
                    onAddNewStep(
                      JSON.parse(milestoneId).milestoneIndex,
                      newName
                    );
                }}
              />
            </div>
          </div>
        </Box>
      </SortableContext>
    </div>
  );
};
