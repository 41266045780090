import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ThemeEnum} from '../../theme/theme.enum';

export interface ThemeState {
  theme: ThemeEnum;
}

const initialState: ThemeState = {
  theme: (sessionStorage.getItem('theme') as ThemeEnum) || ThemeEnum.LIGHT,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<ThemeEnum>) => {
      state.theme = action.payload;
      sessionStorage.setItem('theme', action.payload);
    },
  },
});

export const {setTheme} = themeSlice.actions;

export default themeSlice.reducer;
