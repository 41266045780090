import {styled} from 'styled-components';
import {useNavigate} from 'react-router';
import {Button, Grid} from '@mui/material';
import './Root.scss';
import {useEffect, useState} from 'react';
import {LandingPageLayout} from '../components/Layouts/LandingPageLayout';
import {useSelector} from 'react-redux';
import {RootState} from '../store/store';

const HeroSubheader = styled.div`
  font-weight: bold;
  ${props => props.theme.breakpoints.up('sm')} {
    font-size: 24px;
  }
`;

const HeroButton = styled(Button)`
  ${props => props.theme.breakpoints.up('lg')} {
    font-size: 1.3rem;
  }
`;

const BodySection = styled.section`
  display: flex;
  flex-direction: column;
  place-items: center;
  gap: ${props => props.theme.spacing(10)};
  margin-bottom: ${props => props.theme.spacing(16)};

  img {
    width: 440px;
    max-width: 100%;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    flex-direction: row;
  }
`;

const BodySectionHeader = styled.h2`
  ${props => props.theme.breakpoints.up('sm')} {
    font-size: 48px;
    margin-bottom: ${props => props.theme.spacing(4)};
  }
`;

const BodySectionContent = styled.section`
  p {
    margin: 0;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    p {
      font-size: 20px;
    }
  }
`;

export function Root() {
  const user = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();
  const [currentCount, setCurrentCount] = useState(0);
  const [divider, setDivider] = useState(3);
  const testimonialsData = [
    {
      name: 'Tom Vander Ark',
      title:
        'CEO of Getting Smart and former Executive Director for Education at the Bill & Melinda Gates Foundation',
      message: `The application of AI in learning that I’m most excited about is in career-connected learning,
       and the best example of that in the world is here …. with Project Leo… I love this application of AI because
       it engages young people in co-constructing work that matters… I’m not more excited about anything else than Project Leo.`,
    },
    {
      name: 'Wendy',
      title: '10th Grade English Teacher',
      message: `Project Leo helps students and teachers meet at a common ground.
       The teachers learn about the students’ passions and the students understand
       how they can apply what they’ve been learning in class. Instead of doing projects
       I think are interesting, Project Leo helps identify what projects the students want to do,
       all while making sure they’re demonstrating growth in essential skills covered in class.`,
    },
    {
      name: 'Henry',
      title: '11th Grade Student',
      message: `Honestly, my Project Leo experience was eye opening. By
                    learning these skills I realized how there is nothing
                    stopping me from pursuing something I am passionate about.
                    The projects done in class showed me that I can literally
                    make anything in the world that I want to at any time.`,
    },
    {
      name: 'Julie',
      title: '10th Grade History teacher',
      message: `I wasn't really sure what I expected when I introduced Project Leo to my World History students,
       but I certainly didn't expect the level of enthusiasm and engagement that it ignited.
       Witnessing their genuine excitement was truly inspiring….Project Leo addresses a critical missing piece in
       traditional school projects – empowering students with agency and ownership over their learning.`,
    },
    {
      name: 'Andrew',
      title: '12th Grade Student',
      message:
        "I've liked the idea of learning through a project I actually find interest in rather than just being told to retain something.",
    },
    {
      name: 'Josh',
      title: '11th Grade Student',
      message:
        'My Project Leo experience felt like the start of a new way of learning in the education system.',
    },
  ];
  const [totalChunks, setTotalChunks] = useState(
    1 + Number((testimonialsData.length / divider).toFixed(0))
  );

  // setTimeout(() => {
  //   setCurrentCount((currentCount + 1) % totalChunks);
  // }, 10000);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1300 && window.innerWidth >= 900) {
        setDivider(2);
        const chunks = Number((testimonialsData.length / 2).toFixed(0));
        setTotalChunks(chunks);
      } else if (window.innerWidth <= 900) {
        setDivider(1);
        const chunks = Number(testimonialsData.length.toFixed(0));
        setTotalChunks(chunks);
      } else {
        setDivider(3);
        const chunks = Number((testimonialsData.length / 3).toFixed(0));
        setTotalChunks(chunks);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <LandingPageLayout>
      <div className="first-section">
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} className="grid-section">
            <h1>
              Empowering Teachers,
              <br />
              Engaging Students
            </h1>
            <HeroSubheader>
              Unlock Your Classroom's Full Potential With Project Leo
            </HeroSubheader>
            <p className="main-body-text text-description">
              {`Project Leo is tailored for teachers and students, making
                  differentiation effortless and letting students explore inspiring projects. It's a shift from traditional schooling to a
                  purposeful, engaging learning journey.`}
            </p>
            <div>
              <HeroButton
                onClick={() => navigate('/demos/project-builder.html')}
                variant="contained"
                size="large"
              >
                Try Project Leo
              </HeroButton>
              &nbsp;&nbsp;&nbsp;
              <HeroButton
                onClick={() => navigate('/users/login.html')}
                variant="outlined"
                size="large"
              >
                {user ? 'Go to Dashboard' : 'Log In / Sign Up'}
              </HeroButton>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="image">
            <img
              style={{width: '45vw', height: '45vw'}}
              className="img-equal-widthheight"
              src="/images/landing/Ellipse 69.svg"
            />
            <img
              style={{
                position: 'absolute',
                display: 'flex',
                width: '34vw',
                height: '34vw',
              }}
              className="img-equal-widthheight"
              src="/images/landing/boys.jpg"
            />
          </Grid>
        </Grid>
      </div>
      <div className="center-section">
        <Grid container spacing={6} className="conatiner-row">
          <Grid item xs={12} md={6} className="column-section">
            <BodySectionContent>
              <BodySectionHeader>What is Project Leo?</BodySectionHeader>
              <p>
                Project Leo is an innovative educational tool designed to
                inspire both teachers and students. With Project Leo, teachers
                can easily incorporate differentiation into their classes,
                helping students find their own unique paths to success. By
                removing the rigidity of traditional classrooms, Project Leo
                gives students the freedom to pursue their passions and explore
                their own interests. By engaging students and empowering them to
                take control of their own learning, Project Leo helps to give
                school a purpose and prepares them for their future careers.
              </p>
            </BodySectionContent>
          </Grid>
          <Grid item xs={12} md={6} className="column-section">
            <img
              className="image-alignment"
              style={{maxWidth: '600px'}}
              src="/images/landing/group.png"
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6} className="column-section">
            <BodySectionContent>
              <BodySectionHeader>Why Project Leo?</BodySectionHeader>
              <p>
                Project Leo not only provides project ideas that align with
                school learning objectives but also helps students develop
                career skills. By incorporating the students' interests, market
                demands, and skills, Project Leo offers a unique opportunity for
                students to explore their passions while preparing for their
                future careers. This approach empowers students to take
                ownership of their learning and enables them to develop a clear
                understanding of how their ducation can contribute to their
                professional goals.
              </p>
            </BodySectionContent>
          </Grid>
          <Grid item xs={12} md={6} className="column-section">
            <img
              className="image-alignment"
              src="/images/landing/projects_daigram1.png"
              style={{maxWidth: '600px'}}
            />
          </Grid>
        </Grid>
        <BodySection>
          <div></div>
        </BodySection>
        <div className="section">
          <BodySectionHeader>Discover the key features</BodySectionHeader>
          <Grid container spacing={6}>
            <div>
              <div className="card-section-bgimg">
                <div></div>
              </div>
            </div>
            <Grid item xs={12} md={6}>
              <div className="card">
                <img src="/images/landing/blub-icon.png"></img>
                <div className="card-header">Unique Project Generation</div>
                <div className="card-body">
                  Project Leo uses cutting-edge AI technology to tailor projects
                  that match students' interests, ensuring an exciting and
                  relevant learning journey.
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="card">
                <img src="/images/landing/home-icon.png"></img>
                <div className="card-header">Seamless Curriculum Alignment</div>
                <div className="card-body">
                  Our software seamlessly integrates students' passions with
                  academic objectives and future career paths, making learning
                  meaningful and purpose-driven.
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="card">
                <img src="/images/landing/notebook-icon.png"></img>
                <div className="card-header">Inspiring Student Updates</div>
                <div className="card-body">
                  Students can share their project updates with ease,
                  celebrating their accomplishments and inspiring peers with
                  their creativity and dedication.
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="card">
                <img src="/images/landing/teacherboard-icon.png"></img>
                <div className="card-header">Effortless Teacher Monitoring</div>
                <div className="card-body">
                  Teachers can easily monitor student progress, provide timely
                  feedback, and guide them on their educational journey with
                  just a few clicks.
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="testimonial-section">
        <Grid container spacing={4} className="slider-wrapper">
          {testimonialsData.map((item, index) => (
            <Grid
              item
              sx={{
                display:
                  Math.floor(index / divider) === currentCount
                    ? 'block'
                    : 'none',
              }}
              xs={12 / divider}
              key={JSON.stringify(item)}
            >
              <div className="card-section">
                <div className="card">
                  <div className="card-content">
                    <div className="quotes-icon">“</div>
                    <div className="card-body">{item.message}</div>
                  </div>
                </div>
                <div className="card-arrow">
                  <img src="/images/landing/card-down-arrow.svg" alt="" />
                </div>
                <div className="user-info">
                  <div className="user-name">{item.name}</div>
                  <div className="user-title">{item.title}</div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
        <div className="slide-buttons">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="205"
            height="19"
            viewBox="0 0 205 19"
            fill="none"
          >
            {Array.from({length: totalChunks}, (_, index) => (
              <circle
                key={index}
                cx={9.5 + 37 * index + ((6 - totalChunks) * 37) / 2}
                cy="9.5"
                r="9.5"
                fill={index === currentCount ? '#FFB74D' : 'white'}
                onClick={() => setCurrentCount(index)}
              />
            ))}
          </svg>
        </div>
      </div>
    </LandingPageLayout>
  );
}
