import {Card} from '@mui/material';
import React, {CSSProperties} from 'react';
import {DeepReadOnly} from '../misc';
import {HtmlEditor} from '../HtmlEditor/HtmlEditor';
import {ProjectCardHeader} from './ProjectCardHeader';
import {pl_types} from '../../generated/protobuf-js';
import IProject = pl_types.IProject;
import ThumbsState = pl_types.Project.ThumbsState;
import {ThumbReasonText} from './ThumbReasonText';

export const ProjectSummaryCard = React.forwardRef<
  HTMLDivElement,
  DeepReadOnly<{
    project: IProject;
    hideActive?: boolean;
    hideThumbsState?: boolean;
    updateProject?: (project: DeepReadOnly<IProject>) => void;
    onShowProjectDetails?: (project: DeepReadOnly<IProject>) => void;
    style?: CSSProperties;
  }>
>((props, ref) => {
  return (
    <>
      <Card
        id={props.project.id?.toString?.() ?? ''}
        ref={ref}
        elevation={6}
        sx={{
          height: '100%',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
        }}
        style={props.style}
      >
        <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
          <ProjectCardHeader
            project={props.project}
            hideActive={props.hideActive}
            hideThumbsState={props.hideThumbsState}
            style={{paddingBottom: '1rem'}}
            updateProject={props.updateProject}
            isTitleNotEditable={true}
          />
          {!props.hideThumbsState &&
            (props?.project?.thumbsState === ThumbsState.THUMBS_UP ||
              props?.project?.thumbsState === ThumbsState.THUMBS_DOWN) && (
              <div style={{marginBottom: '2vh'}}>
                <ThumbReasonText
                  inputText={props.project.thumbsStateReason}
                  onChangeDebounced={(text: string) =>
                    props.updateProject?.({
                      ...props.project,
                      thumbsStateReason: text,
                    })
                  }
                  thumbsState={props.project.thumbsState}
                />
              </div>
            )}

          <div
            onClick={() => props.onShowProjectDetails?.(props.project)}
            style={{
              cursor: 'pointer',
              flex: '1 1 auto',
              overflowY: 'auto',
            }}
          >
            <div style={{fontStyle: 'italic', marginBottom: '1rem'}}>
              <HtmlEditor
                readOnly={true}
                value={props.project?.shortDescr ?? 'None.'}
                onChange={value =>
                  props.updateProject?.({...props.project, shortDescr: value})
                }
              />
            </div>
            <HtmlEditor
              readOnly={true}
              value={props.project?.longDescrHtml ?? ''}
            />
          </div>
        </div>
      </Card>
    </>
  );
});
