import {DefaultPage} from '../../../libs/DefaultPage/DefaultPage';
import {TabbedPanel} from '../../../libs/TabbedPanel/TabbedPanel';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/store';
import {ClassesTab} from './ClassesTab';

enum Tabs {
  CLASSES,
}

export function MainDashboard() {
  const userX = useSelector((state: RootState) => state.auth.user);

  return (
    <DefaultPage title={(userX?.isAdminX ? 'Teacher ' : '') + 'Classes'}>
      <div style={{height: '100%'}}>
        <TabbedPanel
          tabKeyEnum={Tabs}
          defaultTabKey={Tabs.CLASSES}
          tabs={[
            {
              key: Tabs.CLASSES,
              label: 'Classes',
              content: <ClassesTab />,
            },
          ]}
        />
      </div>
    </DefaultPage>
  );
}
