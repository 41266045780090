import {Fragment, useEffect, useState} from 'react';
import {Card, Grid} from '@mui/material';
import {ProjectSummaryCard} from '../../../libs/ProjectCard/ProjectSummaryCard';
import {
  pl_types,
  post_service,
  project_service,
} from '../../../generated/protobuf-js';
import {
  DeepReadOnly,
  objPropertyDeepMerge,
  replaceInDeepReadOnlyArray,
  writableForProto,
} from '../../../libs/misc';
import {createService} from '../../../libs/protos';
import {HtmlEditor} from '../../../libs/HtmlEditor/HtmlEditor';
import {Post} from '../../../libs/Post/Post';
import ProjectService = project_service.ProjectService;
import IProject = pl_types.IProject;
import PostService = post_service.PostService;
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/store';
import {useNotification} from '../../../shared/hooks/Notification';

export function PortfolioTab() {
  const userX = useSelector((state: RootState) => state.auth.user);

  const [projects, setProjects] = useState<DeepReadOnly<IProject[]>>([]);
  const [project, setProject] =
    useState<DeepReadOnly<IProject | null | undefined>>();
  const notification = useNotification();

  function setOrClearProject(
    _project: DeepReadOnly<IProject | null | undefined>
  ) {
    if (project && _project?.id === project?.id) {
      setProject(undefined);
    } else {
      setProject(_project);
    }
  }

  useEffect(() => {
    if (!userX) {
      setProjects([]);
      setProject(undefined);
    } else {
      createService(ProjectService, 'ProjectService')
        .getProjects({
          userXIds: [userX?.id ?? 0],
          includePortfolioPosts: true,
        })
        .then(response => {
          setProjects(response.projects);
          if (response.projects.length > 0) {
            setProject(response.projects[0]);
          } else {
            setProject(undefined);
          }
        })
        .catch(notification.handleError('Failed to get projects'));
    }
  }, [userX]);

  function updatePortfolioNote(
    post: DeepReadOnly<pl_types.IProjectPost>,
    value: string
  ) {
    const newPost = objPropertyDeepMerge(post, {
      portfolioNoteHtml: value,
    });
    const newProject = objPropertyDeepMerge(project, {
      projectPosts: replaceInDeepReadOnlyArray(
        project?.projectPosts ?? [],
        pp => pp.id,
        newPost
      ),
    });
    setProject(newProject);

    createService(PostService, 'PostService')
      .upsertProjectPost({
        projectPost: writableForProto(newPost),
      })
      .catch(notification.handleError('Failed to update project post'));
  }

  return (
    <>
      {projects.length === 0 && (
        <div
          style={{
            fontSize: '1.2rem',
            paddingTop: '1rem',
          }}
        >
          To see projects here, select posts and mark them as part of a
          portfolio.
        </div>
      )}
      <Grid container spacing={2} paddingTop={2}>
        {projects
          .filter(p => p.active === true)
          .map(p => (
            <Grid item key={p.id!} xs={6} md={4} xl={3}>
              <ProjectSummaryCard
                project={p}
                onShowProjectDetails={setOrClearProject}
                style={{
                  backgroundColor: p.id === project?.id ? '#f0fff0' : '#ffffff',
                }}
                hideActive={true}
                hideThumbsState={true}
              />
            </Grid>
          ))}
      </Grid>
      {projects.length > 0 && !project && (
        <div
          style={{
            fontSize: '1.2rem',
            paddingTop: '1rem',
          }}
        >
          To see portfolio entries, select a project above.
        </div>
      )}
      <Grid container spacing={2} paddingTop={2} paddingBottom={2}>
        {project &&
          project.projectPosts?.map((pp, i) => (
            <Fragment key={pp.id!}>
              {i % 2 === 0 ? (
                <>
                  <Grid item xs={4}>
                    <Card
                      elevation={5}
                      style={{
                        padding: '1rem',
                        height: '100%',
                        backgroundColor: '#f0f0ff',
                      }}
                    >
                      <div
                        style={{
                          textAlign: 'center',
                          paddingBottom: '1rem',
                          fontWeight: 'bold',
                          fontSize: '1.2rem',
                          fontStyle: 'italic',
                        }}
                      >
                        Reflection
                      </div>
                      <HtmlEditor
                        value={pp.portfolioNoteHtml}
                        onChange={value => updatePortfolioNote(pp, value)}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={8}>
                    <Post
                      post={pp}
                      postUpdated={() => {}}
                      hideRatings={true}
                      hideComments={true}
                      hidePortfolioIcon={true}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={8}>
                    <Post
                      post={pp}
                      postUpdated={() => {}}
                      hideRatings={true}
                      hideComments={true}
                      hidePortfolioIcon={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Card
                      elevation={5}
                      style={{
                        padding: '1rem',
                        height: '100%',
                        backgroundColor: '#f0f0ff',
                      }}
                    >
                      <div
                        style={{
                          textAlign: 'center',
                          paddingBottom: '1rem',
                          fontWeight: 'bold',
                          fontSize: '1.2rem',
                          fontStyle: 'italic',
                        }}
                      >
                        Reflection
                      </div>
                      <HtmlEditor
                        value={pp.portfolioNoteHtml}
                        onChange={value => updatePortfolioNote(pp, value)}
                      />
                    </Card>
                  </Grid>
                </>
              )}
            </Fragment>
          ))}
      </Grid>
    </>
  );
}
