import {Modal} from '@mui/material';
import {ProjectDetailsCard} from './ProjectDetailsCard';
import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import {DeepReadOnly} from '../misc';
import {pl_types, project_service} from '../../generated/protobuf-js';
import {createService} from '../protos';
import IProject = pl_types.IProject;
import ProjectService = project_service.ProjectService;
import {useNotification} from '../../shared/hooks/Notification';

export function ProjectDetailsCardModal(
  props: DeepReadOnly<{
    project?: IProject | null | undefined;
    projectId?: number | null | undefined;
    showActive?: boolean;
    showThumbsState?: boolean;
    updateProject?: (project: DeepReadOnly<IProject>) => void;
    style?: CSSProperties;
    onClose: () => void;
  }>
) {
  const [project, setProject] =
    useState<DeepReadOnly<IProject | null | undefined>>();
  const updatingCount = useRef(0);
  const [loading, setLoading] = useState(false);
  const notification = useNotification();

  useEffect(() => {
    const currentUpdateCount = ++updatingCount.current;
    if (props.project) {
      setProject(props.project);
    } else if (props.projectId != null) {
      setProject(undefined);
      setLoading(true);
      createService(ProjectService, 'ProjectService')
        .getProjects({
          projectIds: [props.projectId],
          includeInactive: true,
          includeAssignment: true,
          includeInputs: true,
          includeInputOptions: true,
          includeMilestones: true,
          includeTags: true,
        })
        .then(response => {
          if (currentUpdateCount !== updatingCount.current) {
            return;
          }
          if (response.projects?.length > 0) {
            setProject(response.projects?.[0]);
          } else {
            setProject(undefined);
          }
        })
        .catch(notification.handleError('Failed to get projects'))
        .finally(() => {
          if (currentUpdateCount !== updatingCount.current) {
            return;
          }
          setLoading(false);
        });
    } else {
      setProject(null);
    }
  }, [props.project, props.projectId]);

  return project != null || loading ? (
    <Modal open={true}>
      {loading ? (
        <div>Loading...</div>
      ) : project != null ? (
        <ProjectDetailsCard
          {...{
            style: {
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '86%',
              height: '86%',
              transform: 'translate(-50%, -50%)',
            },
            ...props,
            loading: loading,
            project: project,
          }}
          updateProject={props.updateProject}
          onClose={props.onClose}
        />
      ) : (
        <></>
      )}
    </Modal>
  ) : (
    <></>
  );
}
